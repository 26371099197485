import React, {Component} from 'react';

import {withRouter} from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import Markdown from '../../../../components/Markdown';
class CommunityRules extends Component {
    constructor(props) {
      super(props);
    }



    render(){
        return <Modal size="xl" isOpen={ this.props.show} toggle={this.props.toggle} >
                            <ModalHeader toggle={this.props.toggle}>Our Community</ModalHeader>

                        <ModalBody>
                            <Markdown source={this.props.data.data.text}/>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" onClick={()=>{this.props.accept();}}>
                                Accept
                            </Button>{' '}
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                </Modal>
    }

}

export default withRouter(CommunityRules);
