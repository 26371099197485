/* eslint-disable max-len */

export const orderFormMessages = {
  0: {},
  // Braintree codes 2000 to 2003
  100: {type: 'payment', text: 'Your card has been declined.  Please enter another card.'},
  // Braintree code 2004
  110: {type: 'payment', text: 'Your card has expired.  Please enter another card.'},
  // Braintree codes 2005 to 2009
  130: {type: 'payment', text: 'Your card has expired.  Please enter another card.'},
  // Braintree code 2010
  140: {type: 'payment', text: 'You have entered an incorrect CVV.  Please enter a valid CVV for this card.'},
  // Braintree code 2011 to 2099
  150: {
    type: 'payment',
    text: 'We have encountered an error with this card.  Please contact the card issuer for more information.'
  },
  // Braintree code 3000
  160: {
    type: 'payment',
    text:
      'We have encountered a network error with your payment provider.  Please contact the card issuer for more information.'
  },
  // Braintree code 4001
  170: {
    type: 'payment',
    text: 'We apologize.  This transaction has been rejected, please try again with a different payment method.'
  },
  // Braintree code 4003
  180: {
    type: 'payment',
    text: 'Duplicate transaction.  Your order has already been completed, please check your email for more information.'
  },
  // Braintree code 4003
  190: {type: 'payment', text: 'Error: This transaction has already been refunded.'},
  // Billing address invalid (not yet implemented)
  200: {type: 'billingAddress', text: 'The billing address you entered is incorrect.  Please enter a valid address.'},
  // Shipping address invalid (not yet implemented)
  210: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  220: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  230: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  240: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  250: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  260: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  270: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  280: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  285: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  290: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  300: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  310: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  320: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  330: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  340: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  350: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  360: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  370: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  380: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  390: {
    type: 'payment',
    text: 'We apologize there is an issue with your order, please try a different payment method.'
  },
  600: {type: 'payment', text: 'We encountered an error processing your request. Please try again.'},
  700: {type: 'payment', text: 'Order cancelled.'},
  // API errors
  999: {
    type: 'api',
    text: 'We apologize, we encountered a problem placing your order. Please try again with a different payment method.'
  }
};

export const refundMessages = {
  351: {type: 'refund', text: 'Only sale transactions can be refunded'},
  352: {type: 'refund', text: 'Transaction status must be settled to refund it'},
  353: {type: 'refund', text: 'Transaction has already been completely refunded'},
  354: {
    type: 'refund',
    text: 'Refund amount is too large. You cannot refund more than the amount submitted for settlement'
  },
  355: {type: 'refund', text: 'Cannot refund a transaction with a suspended merchant account'},
  356: {
    type: 'refund',
    text:
      'Refund Cannot refund a transaction transaction in settling status on this merchant account. Try again after the transaction has settled'
  },
  357: {type: 'refund', text: 'Too many concurrent attempts to refund this transaction. Try again later'}
};

export default orderFormMessages;

/* eslint-enable max-len */
