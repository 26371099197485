'use strict';
Object.defineProperty(exports, '__esModule', {value: true});
var prefix = 'frn';
var iconName = 'WLC';
var width = 70.995;
var height = 70.462;
var ligatures = [];
var unicode = '';
var svgPathData =
  'M.323 36.53A2.88 2.88 0 0 1 2.883 35h13a2.88 2.88 0 0 1 2.38 1.2l14.37 20.46a2.89 2.89 0 0 1 0 3.32l-6.47 9.26a2.89 2.89 0 0 1-4.72 0L.523 39.52a2.9 2.9 0 0 1-.2-2.99zm70.35 0a2.88 2.88 0 0 0-2.51-1.53h-13a2.88 2.88 0 0 0-2.36 1.23l-14.44 20.43a2.89 2.89 0 0 0 0 3.32l6.51 9.26a2.89 2.89 0 0 0 4.72 0l20.88-29.72a2.9 2.9 0 0 0 .2-2.99z M35.323 16.45a15 15 0 0 1 2.24.17c1.33-4.7 2.77-10.12 2.77-11.62a5 5 0 0 0-3.65-4.81 5.08 5.08 0 0 0-1.36-.19 5.22 5.22 0 0 0-1.38.19A5 5 0 0 0 30.303 5c0 1.48 1.44 6.9 2.78 11.6a14.84 14.84 0 0 1 2.24-.15z M46.453 28.11c-3.58-11-20-10.72-22.57 1.13-1.11 5.08 2.28 9.52 4.78 13.12 1 1.37 2 2.67 3 4 .83 1 1.78 2.57 3 2.94s2.09-.37 2.9-1.33q1.6-1.97 3.17-3.97c3.19-4.14 7.7-9.79 5.72-15.89z M29.893 17.53c-1-2.91-3.71-8.84-4.5-9.63a4.41 4.41 0 0 0-5.29-.73 4 4 0 0 0-1 .73 4.8 4.8 0 0 0-.74 1 4.44 4.44 0 0 0 .74 5.3c.73.73 5.92 3.14 9 4.32a13.54 13.54 0 0 1 1.79-.99zm22.35-8.66a4.8 4.8 0 0 0-.74-1 4 4 0 0 0-1-.73 4.41 4.41 0 0 0-5.29.73c-.79.79-3.47 6.72-4.5 9.63a13.54 13.54 0 0 1 1.81 1c3-1.18 8.22-3.59 9-4.32a4.44 4.44 0 0 0 .72-5.31z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData]
};

exports.frnWLC = exports.definition;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
