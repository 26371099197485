import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './components/ListItem';
import styles from 'styles/index.scss';

const {collectionContainer, listTitle, list} = styles;

const Collection = props => (
  <div className={collectionContainer}>
    <h1 className={listTitle}>{props.customTitle || props.title}</h1>
    <div className={list}>
      {props.Children.map(item => (
        <ListItem key={item.title} {...item} />
      ))}
    </div>
  </div>
);

Collection.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Collection;
