import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import ProductSidebar from 'components/ProductSidebar';
import CategoryList from './Components/CategoryList';
import {flattenDeep} from 'lodash';
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

//component to present all the single categories in the UHC product
//This is basically the 'home page' of the UHC product.
//It uses the the full UHC product data to create that home page
class CategoryWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null, //data for entire product
      categories: props.item.Children,
      item: this.props.item,
      nav: 'loading',
      mappedCategories: null, //all the categories of this product
      productTitle: this.props.productTitle,
      navItems: [{name: 'Collection', typeOfComponent: null, slug: null, icon: faHome}] //used to show what nav items are present in top};
    };
    this.handleNav = this.handleNav.bind(this);
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
    this.thisCategoryInPath = thisPath[3];
  }
  componentDidMount() {
    this.setState({product: this.props}, () => {
      //for each category i need the name, title, bio {title, data: {img:"", description: " "}
      //category.name, category.title
      //category.Children.Children.Children.bio  for bios
      let categories = this.state.categories;

      let mappedCategories = categories.map(category => {
        let bios = category.Children.map(categoryOrInterviewContent => {
          // categoryOrInterviewContent is either:
          //   - an array of InterviewCategory
          //   - an array of InterviewCollectionItem
          if (categoryOrInterviewContent.type === 'InterviewCategory') {
            // go an extra layer
            return categoryOrInterviewContent.Children.map(iCContent => {
              return iCContent.Children.map(audioInterview => {
                return audioInterview.Children.filter(leaf => {
                  //leaf  of tree: type download, type bio, etc
                  return leaf.type === 'bio';
                });
              });
            });
          } else {
            return categoryOrInterviewContent.Children.map(audioInterview => {
              return audioInterview.Children.filter(leaf => {
                //leaf  of tree: type download, type bio, etc
                return leaf.type === 'bio';
              });
            });
          }
        });

        let display = {
          name: category.name,
          title: category.title,
          id: category.id, //used for navigation
          slug: category.slug,
          bios: flattenDeep(bios).reduce(
            (accum, current) => (accum.map(e => e.slug).includes(current.slug) ? accum : [...accum, current]),
            []
          )
        };
        return display;
      });

      this.setState({mappedCategories, nav: 'home'});
    });
  } //end component did mount

  handleNav = slug => {
    const categorySlugRegex = new RegExp(`^${this.thisProductInPath}-`, 'g');
    const groupSlugRegex = new RegExp(`^${this.thisCategoryInPath}-`, 'g');
    if (slug === '') {
      this.props.history.push(`/products/${this.thisProductInPath}/${slug.replace(categorySlugRegex, '')}`);
    } else {
      this.thisCategoryInPath
        ? this.props.history.push(
            `/products/${this.thisProductInPath}/${this.thisCategoryInPath.replace(
              categorySlugRegex,
              ''
            )}/${slug.replace(categorySlugRegex, '').replace(groupSlugRegex, '')}`
          )
        : this.props.history.push(`/products/${this.thisProductInPath}/${slug.replace(categorySlugRegex, '')}`);
    }

    window.scrollTo(0, 0);
  };
  render() {
    const {navItems, productTitle, categoryTitle, item} = this.state;
    return (
      <ProductSidebar
        navItems={navItems}
        handleNav={() => {
          this.handleNav('');
        }}
      >
        <Container className="container pt-5">
          <BreadCrumbs>
            {productTitle ? (
              <Link to={`/products/${this.thisProductInPath}`} className="pr-1">
                {productTitle}
              </Link>
            ) : null}
            {productTitle ? ' /' : null}
            {item.title}
          </BreadCrumbs>
          <div className="mt-5">
            <div className="container">
              <div className="mt-2 mb-4" role="main">
                <h4>{this.state.product ? this.state.product.title : null}</h4>
                <p>{this.state.product ? this.state.product.data.description : null}</p>
              </div>
              {this.state.mappedCategories ? (
                <div className="row h-100" role="list">
                  {CategoryList(this.state.mappedCategories, this.handleNav)}
                </div>
              ) : null}
            </div>
          </div>
        </Container>
      </ProductSidebar>
    );
  }
}
//CategoryWrapper receives the whole UHC product as props
CategoryWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  mappedCategories: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CategoryWrapper;
