import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCheckSquare} from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import {faSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';

const FancyCheckbox = ({checked, variant = 'circle', ...props}) => {
  const [icon, iconChecked] = variant === 'square' ? [faSquare, faCheckSquare] : [faCircle, faCheckCircle];
  return (
    <FontAwesomeIcon
      icon={checked ? iconChecked : icon}
      size="lg"
      style={{color: checked ? '#289B18' : '#898989'}}
      {...props}
    />
  );
};

export default FancyCheckbox;
