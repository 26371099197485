import React from 'react';
import {Container, Row, Col} from 'reactstrap';
const BreadCrumbs = props => {
  return (
    <Container className="">
      <Row className="py-3">
        <Col xs="12" md="10">
          <Row className="breadcrumbs">{props.children}</Row>
        </Col>
      </Row>
    </Container>
  );
};
export default BreadCrumbs;
