/* eslint-disable no-console */

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_MODAL = 'OPEN_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const VIEW_STEP = 'VIEW_STEP';

// ------------------------------------
// Actions
// ------------------------------------
const openModal = (modalType, step = 1, animation = true, opts = {}) => ({
  type: OPEN_MODAL,
  modalType,
  step,
  animation,
  opts
});

const hideModal = () => ({
  type: HIDE_MODAL
});

const viewStep = step => ({
  type: VIEW_STEP,
  step
});

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actions = {
  openModal,
  hideModal,
  viewStep
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  showModal: false,
  step: 1,
  modalType: '',
  animation: true,
  opts: null
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: action.modalType,
        step: action.step,
        animation: action.animation,
        opts: action.opts
      };
    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        props: null
      };
    case VIEW_STEP:
      return {
        ...state,
        step: action.step
      };
    default:
      return state;
  }
}

/* eslint-enable no-console */
