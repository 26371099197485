/*
    {
    "free": false,
    "id": 21108,
    "name": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof",
    "title": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof",
    "slug": "hhc-caldwell-esselstyn-md",
    "type": "InterviewCollectionItem",
    "data": {
        "description": "How To Be Heart-Attack Proof"
    },
    "published": true,
    "publishDate": null,
    "updatedAt": "2021-02-25T23:20:13.107Z",
    "createdAt": "2021-02-25T23:20:13.107Z"
}

module to interviewCollectionItem
{
    "id": 42006,
    "ParentId": 21107,
    "ChildId": 21108,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:25:14.082Z",
    "createdAt": "2021-02-25T23:25:14.082Z"
}
*/

/*
{
    "published": true,
    "free": false,
    "id": 21109,
    "name": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof",
    "title": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof",
    "slug": "caldwell-esselstyn-md-hhc-audio-interview",
    "type": "audioInterview",
    "data": {
        "url": "https://s3-us-west-1.amazonaws.com/uhc17/audio/Caldwell+Esselstyn%2C+MD+-+How+You+Can+Be+Heart-Attack+Proof+(Food+Revolution+Summit+2014).mp3?_=2",
        "linkto": {
            "url": "http://www.dresselstyn.com/site/"
        },
        "topics": " Heart disease, sociological study, how to be heart attack proof",
        "duration": "37 minutes",
        "interviewedBy": "John Robbins"
    },
    "ContentTags": [],
    "updatedAt": "2021-02-25T23:32:44.728Z",
    "createdAt": "2021-02-25T23:32:44.728Z"
}

interviewCollectionItem to audio
{
    "id": 42007,
    "ParentId": 21108,
    "ChildId": 21109,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:36:33.051Z",
    "createdAt": "2021-02-25T23:36:33.051Z"
}

audio to bio
{
    "id": 42008,
    "ParentId": 21109,
    "ChildId": 2777,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:41:21.210Z",
    "createdAt": "2021-02-25T23:41:21.210Z"
}
*/

/*
{
    "published": true,
    "free": false,
    "id": 21110,
    "name": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Downloads List",
    "title": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Downloads List",
    "slug": "hhc-caldwell-esseltyn-download-list",
    "type": "downloadsList",
    "data": null,
    "ContentTags": [],
    "Children": [],
    "updatedAt": "2021-02-25T23:46:22.925Z",
    "createdAt": "2021-02-25T23:46:22.925Z"
}
interviewCollection item to downloadList
{
    "id": 42009,
    "ParentId": 21108,
    "ChildId": 21110,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:49:57.944Z",
    "createdAt": "2021-02-25T23:49:57.944Z"
}
*/

const downloadMp = {
	id: 21111,
	name: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Interview Audio Download',
	title: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Interview Audio Download',
	slug: 'uhc-2018-caldwell-esseltyn-interview-audio-download',
	type: 'download',
	published: true,
	free: false,
	data: {
		url:
			'https://s3-us-west-1.amazonaws.com/uhc17/audio/Caldwell+Esselstyn%2C+MD+-+How+You+Can+Be+Heart-Attack+Proof+(Food+Revolution+Summit+2014).mp3',
		text: 'Interview Audio',
		type: 'MP3'
	},
	ContentTags: [],
	Children: [],
	updatedAt: '2021-02-25T23:55:25.297Z',
	createdAt: '2021-02-25T23:55:25.297Z'
};
const downloadPdf = {
	id: 21112,
	name: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Transcript',
	title: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof — Transcript',
	slug: 'hhc-caldwell-esselstyn-md-transcript',
	type: 'download',
	published: true,
	free: false,
	data: {
		url:
			'https://s3-us-west-1.amazonaws.com/uhc17/transcripts/UHC-Caldwell-Esselstyn-MD-How-To-Be-Heart-Attack-Proof.pdf',
		text: 'Transcript',
		type: 'PDF'
	},
	ContentTags: [],
	Children: [],
	updatedAt: '2021-02-25T23:58:30.164Z',
	createdAt: '2021-02-25T23:58:30.164Z'
};
const downloadList = {
	published: true,
	free: false,
	id: 21110,
	name: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Downloads List',
	title: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Downloads List',
	slug: 'hhc-caldwell-esseltyn-download-list',
	type: 'downloadsList',
	data: null,
	ContentTags: [],
	Children: [],
	updatedAt: '2021-02-25T23:46:22.925Z',
	createdAt: '2021-02-25T23:46:22.925Z',
	Children: [ downloadMp, downloadPdf ]
};

/*
{
    "id": 21111,
    "name": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Interview Audio Download",
    "title": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Interview Audio Download",
    "slug": "uhc-2018-caldwell-esseltyn-interview-audio-download",
    "type": "download",
    "published": true,
    "free": false,
    "data": {
        "url": "https://s3-us-west-1.amazonaws.com/uhc17/audio/Caldwell+Esselstyn%2C+MD+-+How+You+Can+Be+Heart-Attack+Proof+(Food+Revolution+Summit+2014).mp3",
        "text": "Interview Audio",
        "type": "MP3"
    },
    "ContentTags": [],
    "Children": [],
    "updatedAt": "2021-02-25T23:55:25.297Z",
    "createdAt": "2021-02-25T23:55:25.297Z"
}

downloadList to download mp3
{
    "id": 42010,
    "ParentId": 21110,
    "ChildId": 21111,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:57:08.439Z",
    "createdAt": "2021-02-25T23:57:08.439Z"
}

{
    "id": 21112,
    "name": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof - Transcript",
    "title": "HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof — Transcript",
    "slug": "hhc-caldwell-esselstyn-md-transcript",
    "type": "download",
    "data": {
        "url": "https://cdn.foodrevolution.org/members/pdf/Caldwell%20Esselsytn%2C%20MD%20-%20How%20You%20Can%20Be%20Heart%20Attack%20Proof",
        "text": "Transcript",
        "type": "PDF"
    },
    "published": true,
    "publishDate": null,
    "free": false,
    "createdAt": "2021-02-25T23:58:30.000Z",
    "updatedAt": "2021-03-16T16:06:32.354Z"
}
downloadList to downloadPdf
{
    "id": 42011,
    "ParentId": 21110,
    "ChildId": 21112,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:59:13.752Z",
    "createdAt": "2021-02-25T23:59:13.752Z"
}

*/

const bio = {
	id: 2777,
	name: 'Caldwell Esselstyn, MD',
	title: 'Caldwell Esselstyn, MD',
	slug: 'caldwell-esselstyn-md',
	type: 'bio',
	published: true,
	free: false,
	createdAt: '2019-02-07T19:32:42.000Z',
	data: {
		img: 'https://cdn.foodrevolution.org/members/assets/speakers/caldwell-esselstyn-md.jpg',
		description:
			'Dr. Caldwell Esselstyn is a surgeon and author of the bestselling book _Prevent and Reverse Heart Disease_. He has produced more than 150 scientific publications, and conducted a series of groundbreaking studies that have rocked the nutritional world. Caldwell has served as President of the American Association of Endocrine Surgeons, and as President of the Staff and as a member of the Board of Governors at the Cleveland Clinic. He explains, with irrefutable scientific evidence, how you can become bullet-proof to heart disease by changing what you eat.'
	},
	Children: [],
	ContentTags: []
};
const audioInterview = {
	published: true,
	free: false,
	id: 21109,
	name: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof',
	title: 'HHC - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof',
	slug: 'caldwell-esselstyn-md-hhc-audio-interview',
	type: 'audioInterview',
	data: {
		url:
			'https://s3-us-west-1.amazonaws.com/uhc17/audio/Caldwell+Esselstyn%2C+MD+-+How+You+Can+Be+Heart-Attack+Proof+(Food+Revolution+Summit+2014).mp3?_=2',
		linkto: {
			url: 'http://www.dresselstyn.com/site/'
		},
		topics: ' Heart disease, sociological study, how to be heart attack proof',
		duration: '37 minutes',
		interviewedBy: 'John Robbins'
	},
	ContentTags: [],
	updatedAt: '2021-02-25T23:32:44.728Z',
	createdAt: '2021-02-25T23:32:44.728Z',
	Children: [ bio ]
};

const interview = {
	id: 21108,
	name: 'UHC18 - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof',
	title: 'UHC18 - Caldwell Esselstyn, MD - How To Be Heart-Attack Proof',
	slug: 'uhc18-caldwell-esselstyn-md',
	type: 'InterviewCollectionItem',
	data: {
		description: 'How To Be Heart-Attack Proof'
	},
	published: true,
	publishDate: null,
	free: false,
	createdAt: '2019-02-07T19:32:41.000Z',
	updatedAt: '2019-02-07T19:32:41.000Z',
	Children: [ audioInterview, downloadList ]
};
export default interview;
