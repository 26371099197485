import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {actions} from 'modules/application';
import {actions as userActions} from 'modules/user';
import Landing from 'routes/Products/components/Landing';
import MyProducts from 'routes/Products/containers/MyProducts';
// import ProductsOnboard from 'routes/Products/containers/ProductsOnboard';
import Bonuses from './products/Bonuses';
import PPT from './products/PPTv2';
import PBCC from './products/PBCC';
import TNTG from './products/TNTG';
import WLC from './products/WLC';
import Download from './products/Download';
import InterviewCollectionV2 from './products/InterviewCollectionV2';
import InterviewCollection from './products/InterviewCollection';
import ZoomWebinar from './products/ZoomWebinar';
import Api from '../../lib/api.js';
import EmpowermentPackage from './products/EmpowermentPackage';
import VideoResource from './products/VideoResource';
import AppWrapperV2 from 'components/AppWrapperV2';
import ModuleProduct from '../Products/products/ModuleProduct';
import MarkdownProduct from '../Products/products/MarkdownProduct';
import EmpowermentPackageV2 from './products/EmpowermentPackageV2';
import ListProduct from './products/ListProduct';
// This container loads product data,
// Then matches it to a product class component by content type
// And updates if user moves to new product
// OR simply displays MyProducts if there is no slug or product not found

// There are several states:
// Loading - on mount and on load new product
// isProductsHome - '/products/' no product slug (or location IF product not found)
// Product class exists that matches contentItem returned from API

//keys here are product types received from the /products/:slug/content endpoint
const productClasses = {
  bonuses: Bonuses,
  download: Download,
  ppt: PPT,
  pbcc: PBCC,
  tntg: TNTG,
  wlc: WLC,
  InterviewCollection: InterviewCollection,
  InterviewCollectionV2: InterviewCollectionV2,
  ZoomWebinar: ZoomWebinar,
  EmpowermentPackage: EmpowermentPackage,
  EmpowermentPackageV2: EmpowermentPackageV2,
  videoResource: VideoResource,
  hbc: PPT,
  ModuleProduct: ModuleProduct,
  MarkdownProduct: MarkdownProduct,
  ListProduct: ListProduct,
  html: () => <div>This is an as yet unsupported user product of type: 'html' </div>
};

class Products extends PureComponent {
  constructor(props) {
    super(props);
    const {
      isExact,
      params: {slug}
    } = props.match;
    this.state = {
      Component: null,
      d: null,
      loading: false,
      hasRefreshedToken: false,
      isProductsHome: isExact,
      url: `/products/${slug}/content`,
      slug
    };
    // this.url = `/content/${1}`;
  }

  handleNav = (name, typeOfComponent, slug) => {
    this.props.history.push(`/products/${this.thisProductInPath}/${slug}`);
    window.scrollTo(0, 0);
  };
  componentDidMount() {
    const {getUserProducts, products} = this.props;
    window.scrollTo(0, 0);
    // Don't look for a product if this is products/ ie MyProducts
    // (MyProducts will instead fetch all user products)
    // if (!this.state.isProductsHome && this.state.url.length > 18) {

    this.loadProduct();

    // this might need to be moved higher, ie app level
    if (!products.length) {
      this.props.getUserProducts();
    }
    // }
  }

  componentWillReceiveProps(newProps, oldProps) {
    const oldProduct = this.props.location.pathname.split('/')[2];
    const newProduct = newProps.location.pathname.split('/')[2];
    if (!newProduct) {
      this.setState({isProductsHome: true});
    } else if (oldProduct !== newProduct) {
      this.loadProduct(newProduct);
    }
  }

  navigateToMyProducts = () => {
    this.props.history.push('/products/');
  };

  loadProduct(productSlug) {
    const {hasRefreshedToken} = this.state;
    // On navigate to new product slug,
    // Update component to that product class
    // Or, if it doesn't exist, move back to /products/
    this.setState({loading: true, Component: null});
    const slug = productSlug || this.props.location.pathname.split('/')[2];
    const loadUnpublishedContent = location && location.search.includes('ignore');
    const {lastProductVisited, updateLastProductVisited} = this.props;
    let url = `/products/${slug}/content${loadUnpublishedContent ? '?ignore=1' : ''}`;
    this.setState({slugTest: slug});

    if (slug) {
      if (slug === 'wlc') {
        if (lastProductVisited !== slug) updateLastProductVisited('wlc');
        //WLC will have its own routing and API calls

        this.setState({renderWLC: true});
        return;
      }

      // else if(slug==='pbcc'){
      //   this.setState({
      //     renderWLC: false,
      //     d: null,
      //     isProductsHome: false,
      //     loading: false,
      //     Component: productClasses['pbcc']
      //   });
      //   return ;
      // }
      Api.get(url, this.props.authToken)
        .then(d => {
          if (hasRefreshedToken) {
            if (d.LandingPageItem || !d.ContentItem) {
              // This means the UI tried updating the token once already
              // and is still getting landing ie unpurchased user: render landing page
              this.setState({d: d.LandingPageItem, loading: false, Component: Landing});
            } else {
              const {type} = d.ContentItem;
              this.setState({contentItem: d.ContentItem});
              if (!productClasses[type]) {
                this.navigateToMyProducts();
              } else {
                // Success: loaded content for a product
                // Remember the user last visited THIS product - the slug is saved in localStorage
                if (slug !== lastProductVisited) {
                  updateLastProductVisited(slug);
                }

                // Put content into the right product container/ ie Component
                this.setState({
                  renderWLC: false,
                  d: d.ContentItem,
                  isProductsHome: false,
                  loading: false,
                  Component: productClasses[type]
                });
              }
            }
          } else {
            // refresh the token for sanity
            this.refreshTokenAndTryAgain();
          }
        })
        .catch(err => {
          // FIX error handling or navigate somewhere else
          this.setState({loading: false, Component: null, isProductsHome: true});
          this.navigateToMyProducts();
        });
    }
  }

  refreshTokenAndTryAgain = async () => {
    const hasRefreshedToken = await this.props.refreshToken();
    if (hasRefreshedToken) {
      const product = this.props.location.pathname.split('/')[2];
      this.setState({hasRefreshedToken});
      this.loadProduct(product);
    }
  };

  render() {
    const {
      state: {Component, isProductsHome, d, loading}
    } = this;
    const {history} = this.props;
    //WLC is so big it handles all of its own routes and rendering
    if (this.state.renderWLC) {
      return <WLC />;
    }
    if (isProductsHome || !history.location.pathname || !history.location.pathname.split('/')[2]) {
      return <MyProducts />;
    }

    if (!Component || loading) {
      return <div>Loading...</div>;
    }

    if (!Component) {
      return <div>Page not found</div>;
    }
    return <Component {...d} />;
  }
}

Products.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  products: state.user.products,
  lastProductVisited: state.application.lastProductVisited
});

const mapDispatchToProps = dispatch => ({
  getUserProducts: callback => {
    dispatch(userActions.getUserProducts(callback));
  },
  updateLastProductVisited: slug => {
    dispatch(actions.updateLastProductVisited(slug));
  },
  refreshToken: () => dispatch(userActions.refreshToken())
});

export default compose(AppWrapperV2, withRouter, connect(mapStateToProps, mapDispatchToProps))(Products);
