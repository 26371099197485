import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import ReactPlayer from 'react-player';
import Footer from '../Footer';
import IconList, {IconListItem} from '../IconList';
import Section, {SectionImage} from '../Section';
import TestimonialCircleItem from '../TestimonialCircleItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';

import SpeakersGroupByCollection from '../UHC/components/UhcSpeaker';
import UpsellSales from '../UpsellSales';
import UHCLogo from 'images/SVG/frn-uhc-logo.svg';
import testimonials from './testimonials.json';

const CSS_URL = process.env.FRS_STYLESHEET_URL;

function UhcUpsell(props) {
  return (
    <UpsellSales
      className="offer-uhc"
      heading="One-Time Offer — Save 97%!"
      text={
        <React.Fragment>
          Clicking “Yes” above will add the <strong>Ultimate Health Collection</strong> to your purchase now, for an
          additional $47.
        </React.Fragment>
      }
      imgSrc="https://cdn.foodrevolution.org/uhc/uhc21-product-image.png"
      imgWidth="1000"
      imgHeight="416"
      imgAlt="Ultimate Health Collection product image"
      {...props}
    />
  );
}

function TestimonialCircle({id, ...props}) {
  const testimonial = testimonials.find(e => e.id === id);
  return testimonial ? <TestimonialCircleItem {...testimonial} {...props} /> : null;
}

class UHC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
  }

  componentDidMount() {
    this.linkTag = document.createElement('link');
    const head = document.getElementsByTagName('head')[0];
    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    head.appendChild(this.linkTag);
    const css = 'html { font-size: unset; }';
    this.style = document.createElement('style');

    head.appendChild(this.style);

    this.style.type = 'text/css';
    if (this.style.styleSheet) {
      // This is required for IE8 and below.
      this.style.styleSheet.cssText = css;
    } else {
      this.style.appendChild(document.createTextNode(css));
    }
    document.getElementsByTagName('html')[0].classList.add('frn-html');
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.style && this.style.remove();
    document.getElementsByTagName('html')[0].classList.remove('frn-html');
  }

  render() {
    const {
      props: {
        videoUrl = '',
        speakerCollections = [],
        headline = 'Thank you for your purchase!',
        slug = '',
        ...props
      } = {}
    } = this;
    const IS_PPT = slug.substr(0, 3) === 'ppt';
    const IS_HBC = slug.substr(0, 3) === 'hbc';
    const IS_HHC = slug.substr(0, 3) === 'hhc';
    const IS_TDFR = slug.substr(0, 4) === 'tdfr';
    const IS_TT2 = slug.substr(0, 3) === 'tt2';
    const IS_FRS = slug.substr(0, 3) === 'frs';
    const IS_TNTG = slug.substr(0, 4) === 'tntg';
    const IS_FF = slug.substr(0, 3) === 'ff-';
    const IS_FTF = slug.substr(0, 3) === 'ftf';
    const IS_PPP = slug.substr(0, 3) === 'ppp';
    const purchasedProduct = IS_PPT ? (
      'Plant-Powered & Thriving'
    ) : IS_TDFR ? (
      <em>31-Day Food Revolution</em>
    ) : IS_HBC ? (
      'Healthy Brain'
    ) : IS_HHC ? (
      'Healthy Heart'
    ) : IS_TT2 ? (
      'Tackling Type 2'
    ) : IS_TNTG || IS_FF ? (
      'Impact Kit'
    ) : IS_PPP ? (
      'Plant-Powered Playbook'
    ) : (
      'The Food Revolution Summit'
    );
    const customerNoun =
      IS_PPT || IS_HBC || IS_HHC || IS_TT2 ? (
        <React.Fragment>
          <em>{purchasedProduct}</em> participants
        </React.Fragment>
      ) : (
        `${purchasedProduct} community`
      );
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="page page-nosocial">
        <div className="page-content">
          <Section className="section-uhc-header" innerClass="text-white text-center pt-4" color="black">
            <Container>
              <Row className="mb-4">
                <Col>
                  <h3 className="section-heading mb-2">
                    {IS_TT2 || IS_PPP ? (
                      <React.Fragment>
                        Before you dive into <em>{purchasedProduct}</em>, <br />
                        check out this video.
                      </React.Fragment>
                    ) : (
                      headline
                    )}
                  </h3>
                  <h5 className="text-400 m-0">
                    {IS_TT2 || IS_PPP ? (
                      <strong>(You won’t see this special offer again!)</strong>
                    ) : (
                      <em>
                        One quick thing before you go. <br />
                        You’ll never see this opportunity again.
                      </em>
                    )}
                  </h5>
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                <Col lg="10" className="px-0 px-sm-3">
                  <div className="text-center bg-danger py-3 text-uppercase text-small">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    &nbsp;&nbsp;You will only see this page once — press play below.
                  </div>
                  <div className="video-wrapper">
                    <ReactPlayer className="video-player" width="100%" height="100%" url={videoUrl} />
                  </div>
                </Col>
              </Row>
              {IS_FF || IS_TNTG || IS_FTF ? (
                <Row>
                  <Col>
                    <h4 className="text-center mt-4">
                      If one of your goals in getting the {IS_FTF ? 'Immersion' : 'Impact'} Kit{' '}
                      <br className="d-none d-xl-block" />
                      is improving your own health, this is for you.
                    </h4>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Section>

          {IS_TNTG || IS_FF || IS_FTF ? (
            <Section id="section-uhc-frn" color="white" innerClass="text-center">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="10">
                    <img
                      className="mb-4"
                      src="https://cdn.foodrevolution.org/global/frn-logo.svg"
                      style={{maxHeight: '100px'}}
                    />
                    <h4 className="section-heading">
                      <i>Who is Food Revolution Network?</i>
                    </h4>
                    <p>
                      For over a decade, we’ve been at the forefront of the healthy living movement. We and our 700,000+
                      members are committed to healthy, ethical, and sustainable food for all. We’ve partnered with{' '}
                      {IS_FF || IS_TNTG ? ` ${IS_FF ? 'Louie' : 'Rob'} and ` : ''}
                      the <i>{IS_FTF ? 'From Food to Freedom' : IS_FF ? 'Fantastic Fungi' : 'The Need to Grow'}</i> team
                      to bring this film into the world and to create and share the {IS_FTF ? 'Immersion' : 'Impact'}{' '}
                      Kit.
                    </p>
                    <p>
                      We want to make sure you know how to eat to prevent and reverse disease — and transform how you
                      look and feel each day.
                    </p>
                    <p>Keep reading for a special offer that’s only available from this page!</p>
                  </Col>
                </Row>
              </Container>
            </Section>
          ) : null}

          <SectionImage
            id="section-uhc-lifespan-2"
            imgPosition="left"
            imgUrl="https://cdn.foodrevolution.org/hhc/1241736799.jpg"
            color="light-gray"
          >
            <h3 className="section-heading">What do people with the longest lifespan have in common?</h3>
            <h5 className="mt-0">
              The people with the <i>longest lifespans</i> know how to live and what to eat to:
            </h5>
            <IconList>
              <IconListItem>
                Live an exceptionally healthy life without the common diseases that afflict masses of people in other
                areas of the world.
              </IconListItem>
              <IconListItem>
                Avoid the stress of crushing medical debt, insurance headaches, and a calendar full of nonstop doctor’s
                appointments.
              </IconListItem>
              <IconListItem>
                Say goodbye to sickness and hello to health — without an armload of expensive supplements.
              </IconListItem>
              <IconListItem>Stay at their ideal weight for life, without even thinking about it.</IconListItem>
              <IconListItem>
                Enjoy energy all day without depending on caffeine, sugar, or other stimulants. (And fall asleep easily
                at night!)
              </IconListItem>
            </IconList>
          </SectionImage>
          {IS_FF || IS_HHC || IS_TT2 ? (
            <Section id="section-uhc-info" className="section-uhc-info" innerClass="text-center" color="white">
              <Container>
                <Row>
                  <Col>
                    {IS_FF ? (
                      <React.Fragment>
                        <h5 className="section-heading">
                          Food Revolution Network’s co-founder, 2-million-copy <br className="d-none d-xl-block" />
                          bestselling author John Robbins, has interviewed more than 100{' '}
                          <br className="d-none d-xl-block" />
                          of the top food experts on the planet and delivered their cutting-edge{' '}
                          <br className="d-none d-xl-block" />
                          wisdom to millions in the world-famous Food Revolution Summit.
                        </h5>
                        <p>
                          <b>
                            Now’s your chance to get 20 of our best and most popular{' '}
                            <br className="d-none d-xl-block" />
                            health-boosting interviews in the Ultimate Health Collection.
                          </b>
                        </p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h2 className="section-heading mb-3">
                          A Special, One-Time Offer <br className="d-none d-xl-block" />
                          For {customerNoun}
                        </h2>
                        <p>
                          <i>This opportunity won’t be available again at this price after you close this page.</i>
                        </p>
                        <h4 className="mb-3">
                          For over a decade, Food Revolution Network has worked <br className="d-none d-xl-block" />
                          with the world’s foremost experts in healthy living.
                        </h4>
                        <h4 className="mt-0 mb-3">
                          We’ve compiled 20 of our best health-centric interviews <br className="d-none d-xl-block" />
                          in the Ultimate Health Collection.
                        </h4>
                      </React.Fragment>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md="10" lg="8" xl="6">
                    <img src={UHCLogo} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <h4 className="text-400 mt-3">Add the Ultimate Health Collection to your cart now for $47</h4>
                    {IS_HHC ? <p>Get the best health of your life.</p> : null}
                  </Col>
                </Row>
              </Container>
            </Section>
          ) : null}

          <Section id="section-uhc-offer-1" color="gradient-indigo-purple-right">
            <Container>
              <Row>
                <Col>
                  <UhcUpsell {...props} />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="section-uhc-about" color="light-gray">
            <Container>
              <Row className="justify-content-center">
                <Col>
                  <h2 className="section-heading text-center">
                    About the Ultimate <br className="d-none d-sm-block d-lg-none" />
                    Health Collection
                  </h2>
                  <h4 className="text-center mt-0">
                    Ready to get the top insights for lasting health? <br className="d-none d-lg-block" />
                    Make it happen with the help of John Robbins <br className="d-none d-lg-block" />
                    and his unmatched interviews with the <br className="d-none d-lg-block" />
                    world’s top natural health experts.
                  </h4>

                  <div className="background-white rounded box-shadow-black-25 p-4 p-lg-5 my-5">
                    <Row className="align-items-center justify-content-center">
                      <Col lg="4" className="text-center order-lg-2 mb-4 mb-lg-0">
                        <img
                          className="rounded-circle"
                          src="https://cdn.foodrevolution.org/checkout/johnrobbins-headshot.jpg"
                          style={{maxHeight: '250px'}}
                        />
                      </Col>
                      <Col lg="8" className="order-lg-1">
                        <p>Hi, I’m John Robbins.</p>
                        <p>
                          For over 30 years, I’ve been helping people get the best health of their life. Between my
                          multi-million bestselling books like <i>Diet For A New America,</i> co-founding Food
                          Revolution Network, exposing the truth about the industrial food complex, and conducting
                          hundreds of interviews with world-renowned health revolutionaries, I know what it takes to
                          create lasting health.
                        </p>
                      </Col>
                    </Row>
                    <p>
                      The Ultimate Health Collection includes 20 interviews, each with downloadable MP3s and full
                      written transcripts. Soak up wisdom in this curated collection from some of{' '}
                      <b>the world’s top health experts,</b> including multiple <i>New York Times</i> bestselling
                      authors like Tony Robbins and Michael Pollan.
                    </p>
                    <p>
                      <b>Do you need the Utlimate Health Collection to get and stay healthy?</b>
                    </p>
                    <p>
                      No, only if you want to do so in the quickest, least-expensive, and most enjoyable way possible —
                      while avoiding the mistakes and pitfalls that happen when you try to figure it out by yourself.
                    </p>
                    <p>
                      <b>Most people can get the basics down: drink water, don’t smoke, don’t eat fast food.</b>
                    </p>
                    <p>
                      <b>
                        But how do you get <i>extraordinary</i> health?
                      </b>{' '}
                      What’s it take to avoid disease, increase energy, live longer, improve immunity, and stay at a
                      healthy weight? What are the secrets of people who live in the world’s Blue Zones with the longest
                      lifespans?
                    </p>
                    <p>
                      That’s why for over a decade, I’ve interviewed the{' '}
                      <b>top visionaries who are leading the charge towards a healthier future for all of us.</b> We’ve
                      curated the special Ultimate Health Collection to bring you the very best of those interviews.
                    </p>
                    <p>
                      The only other way to buy this beloved collection would be to purchase all of our past Food
                      Revolution Summits and to spend more than $1,500 in the process. But right now (and now only), you
                      can get the Ultimate Health Collection for a mere fraction of that cost — just $47!
                    </p>
                  </div>
                  <UhcUpsell {...props} variant="mini" className="text-center" excludeNoButton />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="section-uhc-testimonials" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">
                    What People Are Saying About <br className="d-none d-xl-block" />
                    Food Revolution Network Interviews
                  </h2>
                  <TestimonialCircle id="uhc-rajiv-b" />
                  <hr className="my-5" />
                  <TestimonialCircle id="uhc-ginny-o" imgPosition="right" />
                  <hr className="my-5" />
                  <TestimonialCircle id="uhc-ann-s" />
                  <p className="text-center text-16 mt-5 mb-0">
                    <em>These testimonials reflect people’s unique experience. Your specific experience will vary.</em>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="section-uhc-imagine-1"
            imgPosition="right"
            imgUrl="https://cdn.foodrevolution.org/hhc/920522262.jpg"
            color="light-gray"
          >
            <Container>
              <Row>
                <Col>
                  <h5 className="section-heading">Imagine being able to:</h5>
                  <IconList>
                    <IconListItem>Live longer</IconListItem>
                    <IconListItem>Live better</IconListItem>
                    <IconListItem>Beat cancer</IconListItem>
                    <IconListItem>Achieve peak performance</IconListItem>
                    <IconListItem>Prevent Alzheimer’s</IconListItem>
                    <IconListItem>Master the psychology of eating</IconListItem>
                    <IconListItem>Use food to improve your immunity</IconListItem>
                    <IconListItem>Shed excess pounds</IconListItem>
                  </IconList>
                </Col>
              </Row>
            </Container>
          </SectionImage>

          <SectionImage
            id="section-uhc-imagine-2"
            imgPosition="left"
            imgUrl="https://cdn.foodrevolution.org/hhc/1288965935.jpg"
            color="light-gray"
          >
            <Container>
              <Row>
                <Col>
                  <IconList className="mt-0">
                    <IconListItem>Feel better</IconListItem>
                    <IconListItem>Sleep deeply</IconListItem>
                    <IconListItem>Nourish yourself</IconListItem>
                    <IconListItem>End inflammation</IconListItem>
                    <IconListItem>Quit sugar</IconListItem>
                    <IconListItem>Love your body exactly as it is</IconListItem>
                    <IconListItem>Enjoy incredible digestion</IconListItem>
                    <IconListItem>Share what you learn with your loved ones</IconListItem>
                  </IconList>
                </Col>
              </Row>
            </Container>
          </SectionImage>

          <Section id="section-uhc-speakers" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">Learn From These Top Health Experts</h2>
                </Col>
              </Row>
              <Row>
                <SpeakersGroupByCollection collections={speakerCollections} />
              </Row>
            </Container>
          </Section>

          <Section id="section-uhc-testimonials-2" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <TestimonialCircle id="uhc-myra-a" />
                  <hr className="my-5" />
                  <TestimonialCircle id="uhc-molly-s" imgPosition="right" />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section
            id="section-uhc-offer-2"
            color="gradient-indigo-purple-right"
            className="section-angle-last"
            angle="none"
          >
            <Container>
              <Row>
                <Col>
                  <UhcUpsell {...props} />
                </Col>
              </Row>
            </Container>
          </Section>
        </div>

        <Footer />
      </div>
    );
  }
}
export default UHC;
