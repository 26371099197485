import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import './Footer.scss';

export default function Footer(props) {
  return (
    <div className={classNames('footer wrapper', props.className)}>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs="12" lg="5" className="text-center text-lg-left mb-4 mb-lg-0">
            <div className="logos">
              <img
                className="img-fluid logo-footer logo-ecf"
                src="https://cdn.foodrevolution.org/checkout/upsells/impactkit/logo-ecf.png"
              />
              <img
                className="img-fluid logo-footer logo-frn"
                src="https://cdn.foodrevolution.org/checkout/upsells/impactkit/frn-logo.png"
              />
            </div>
          </Col>
          <Col xs="12" lg="7" className="text-center text-lg-right">
            <div className="mb-3"></div>
            <nav className="nav-footer mb-3">
              <ul className="list-inline m-0">
                <li className="list-inline-item">
                  <a className="link-white" target="_blank" href="https://support.foodrevolution.org/">
                    FAQs
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="link-white" target="_blank" href="https://foodrevolution.org/terms-and-conditions">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="link-white" target="_blank" href="https://foodrevolution.org/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </nav>
            © {new Date().getFullYear()} - Earth Conscious Films & Food Revolution Network
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Footer.defaultProps = {
  className: 'background-black text-white'
};
