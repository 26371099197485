import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, Container, Col, Row} from 'reactstrap';

const FullWidthPage = props => {
  const {profileSections} = props;

  return (
    <Container style={{maxWidth: '900px'}}>
      <Row>
        <Col xs={12}>
          {profileSections.map(section => (
            <Container>
              <Row>
                <Col xs={12} style={{paddingLeft: '0'}}>
                  <div style={{marginBottom: '56px'}}>
                    <div style={{marginBottom: '8px', textAlign: 'center'}}>{section.title}</div>
                    <div style={{margin: '16px 0 32px 0'}}>
                      {section.sections.map(renderComponent => renderComponent())}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

FullWidthPage.propTypes = {
  profileSections: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default FullWidthPage;
