//const {title, subtitle, image, description} = item.displayProps;

export const contentTracksStep = [
  {
    displayProps: {
      title: 'Brain Health',
      subtitle: 'Unlock the full potential of your mind with our top-notch articles and videos.',
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/blueberry.png'},
      description:
        'Discover science-backed strategies to enhance cognitive function, improve memory, and boost mental clarity.'
    }
  },
  {
    displayProps: {
      title: 'Heart Health',
      subtitle: 'Keep your heart in tip-top shape with our insightful articles and videos.',
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/tomato.png'},
      description:
        'Learn actionable tips to lower cholesterol, reduce blood pressure, and maintain a healthy cardiovascular system.'
    }
  },
  {
    displayProps: {
      title: 'Blood Sugar Balance',
      subtitle: 'Tackle diabetes head-on with our expert advice, articles, and videos.',
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/leafygreens.png'},
      description:
        'Get comprehensive guidance on managing blood sugar levels, making smarter food choices, and improving your overall health.'
    }
  },
  {
    displayProps: {
      title: 'Longevity',
      subtitle: 'Live longer, live better with our best articles and videos on longevity.',
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/orangev2.png'},
      description:
        'Explore the keys to a long, healthy life through balanced nutrition, exercise, and mental well-being.'
    }
  }
];

export const socialProof = [
  {
    displayProps: {
      title: 'Anne O',
      subtitle: `"I've loved John Robbins and his family for years and believe in the value of whole food, plant-based eating. In joining WHOLE Life Club, I wanted to gain more knowledge and join a community of mutual encouragement and learning. I have experienced a kind of loneliness due to how I eat and live — but with WHOLE Life Club I found a community of people who desire wellness through natural living and eating. What I’ve learned here (including the delicious, not-too-hard recipes) has helped me overcome leaky gut and severe gut inflammation issues. I am thankful. There are so many specifics, like certain beneficial herbs, seeds, and spices, that I never knew before. "`,
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/social-proof-1.png'
      }
    }
  },
  {
    displayProps: {
      title: 'Barbara A',
      subtitle: `"I've been a member of WHOLE Life Club from the start. I look forward to each email, with the videos, articles, recipes, and opportunities to comment. I first joined because I knew very few people who ate WFPB and I wanted to learn more about the lifestyle. I was also hoping to meet others who had totally eliminated animal products from their diet. I immediately noticed a positive shift in my life when I began to understand the extent of the causative disease factors associated with the Western diet. I value the clarity of the information that's shared. Since joining, I feel more confident in my WFPB diet. When folks ask questions like, Where do you get protein? — or calcium, or whatever — I can either answer them or refer them to WHOLE Life Club.
        "`,
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/social-proof-2.png'
      }
    }
  },
  {
    displayProps: {
      title: 'Dean W',
      subtitle: `WHOLE Life Club provides such vital and functional information, in an era filled with confusion and misdirection around what we eat. A major illness kindled in me a serious interest in what I put into my body. WLC helped (and helps) me succeed in a consistently healthy diet that includes primarily organic plants and whole foods by providing me with information about plant-based foods’ benefits and how to make them delicious. WLC seems to have — by far — more specific knowledge about food and health than anyone else. I am in awe of the vast array of information and knowledge the Club has access to, and the way the Club is able to disseminate this vastly important knowledge. 
      `,
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/social-proof-5.png'}
    }
  },
  {
    displayProps: {
      title: 'Diane M.',
      subtitle: `With WHOLE Life Club I sought support and information to help me on my journey to a fully low-fat, whole-food, plant-based diet. It has done that and a whole lot more! I have lost over 120 pounds since September 2022. My A1C dropped from 10.4 to 4.5, my triglycerides are now in a normal range, my LDL Cholesterol has dropped tremendously, and my HDL Cholesterol is in a healthy range and creeping higher. I feel so much better, with more energy, motivation, and a healthier mindset. Thank you to WLC and all its members! I had been watching the FRN Summits for years and wanted to be a part of a community of like-minded individuals and to learn more to support my journey. I enjoy how positive everyone is, and the non-judgemental way the leaders include everyone as they teach, no matter what phase students are in on their journey. I noticed a positive shift in myself when sharing with other WLC members during one of our live monthly Community Calls about just how far I’ve come and how much I have learned about clean eating. WLC reassures me that I am going in the right direction and that I will be fine in getting enough protein from a wide variety of foods. Since becoming a Whole Life Club Member, I am enjoying and appreciating my garden more than ever, and also the tips, tricks, and recipes from WLC to make living this way easier.
        `,
      image: {url: 'https://cdn.foodrevolution.org/wlc/social/social-proof-3.png'}
    }
  }
];

/* onboarding content item


{
    "displayProps": {
        "title": "Onboarding",
        "image": {
            "alt": "Onboarding"
        }
    },
    "id": 39700,
    "name": "Onboarding",
    "title": "Onboarding",
    "slug": "onboarding-general",
    "type": "journey",
    "published": true,
    "free": false,
    "data": {
        "journeyName": "general-onboarding"
    },
    "updatedAt": "2023-08-31T18:27:52.720Z",
    "createdAt": "2023-08-31T18:27:52.720Z"
}

/*/

export const sampleStaffPicks = [
  {
    id: 4,
    staffName: 'Tracy, Program Manager, Plant-Based Coaching Certification',
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-UA2FLLV5M-66852a272f43-512',
    image: '	https://cdn.foodrevolution.org/members/assets/recipes/breakfast-cauliflower-lentil-kitchari.jpg',
    kind: 'Favorite Breakfast',
    title: 'Cauliflower Lentil Kitchari',
    description:
      'This breakfast kitchari is yummy warm or cold and actually gets more flavorful the next day. Make it the night before and enjoy it in the morning for a breakfast that will set a positive mood for the rest of the day with its fragrant spices, fiber-filled grains, protein-packed lentils and healing cauliflower.',
    staffReview: `It’s flavorful, filling, and stores and reheats really well. In fact, it's just a little more yummy each time I reheat it, making it the perfect dish to batch cook for the week ahead. `,
    link: '/products/wlc/breakfast-cauliflower-lentil-kitchari'
  },
  {
    id: 1,
    staffName: 'Rebecca, Ads Manager',
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-URA789SMT-ff2882e90eb6-512',
    image: 'https://cdn.foodrevolution.org/members/assets/recipes/moroccan-baked-chickpeas.jpg',
    kind: 'Favorite Snack',
    title: 'Moroccan Baked Chickpeas',
    description:
      'They satisfy the desire for crunch and great flavor in a small package, plus chickpeas are packed with plant-powered protein and fiber.',
    staffReview: `These have literally become my #1 snack. They are so easy to make in bulk and even easier to munch on because they are so delicious! 
    `,
    link: '/products/wlc/moroccan-baked-chickpeas'
  }, //extra review: I feel so good about myself when I eat them because I know I'm getting healthy plant-based protein and all-powerful curcumin from the turmeric. Any of the chickpea recipes from FRN are amazing and so versatile!
  {
    id: 3,
    staffName: 'Max, Backend Developer',
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-U010NPDDP2N-d05a7fcc79d4-512',
    image: 'https://cdn.foodrevolution.org/members/assets/recipes/general-tsos-chickpeas.jpg',
    kind: 'Favorite Dinner',
    title: 'General Tso’s Chickpeas',
    description:
      'This classic Chinese-American dish brings together bold flavors in a spicy-sweet sauce. Ginger, garlic, and red chili will help fire up your dinner table!',
    staffReview: `A big nourishing meal that I can adapt to whatever I have on hand or am in the mood for.`,
    link: '/products/wlc/general-tsos-chickpeas'
  },
  {
    id: 2,
    staffName: `Meryl, Project Coordinator/Growth Team
    `,
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-U042SEY7A14-46a172ee8708-512',
    image: 'https://cdn.foodrevolution.org/members/assets/recipes/green-goddess-dressing.jpg',
    kind: 'Favorite Sauce',
    title: 'Green Goddess Dressing',
    description:
      'Tahini is the magical ingredient that makes this dressing rich and creamy. With the addition of garlic, lemon, and cilantro, it’s also bursting with flavor and is so versatile!',
    staffReview: `It's SO simple to make and I usually have all the ingredients on hand. And it's a flavor bomb. I usually double it to use throughout the week.
    `,
    link: '/products/wlc/green-goddess-dressing'
  },
  // {
  //   id: 5,
  //   staffName: `Mark, Head of Technology
  //   `,
  //   staffPic: 'https://ca.slack-edge.com/T0DMKQH60-U3BJYCU2Z-197647ebb2b3-512',
  //   image: '	https://cdn.foodrevolution.org/members/assets/recipes/anti-inflammatory-green-smoothie.jpg',
  //   kind: 'Favorite Beverage/Other',
  //   title: 'Anti-inflammatory Green Smoothie',
  //   description:
  //     'With the combination of greens, lemon, pineapple, flax, and ginger, this smoothie is an anti-inflammatory win! Don’t worry, the flavors are balanced, so it also tastes really good!',
  //   staffReview: `The Anti-Inflammatory Green Smoothie has transformed how I start my day. Packed with lively tropical fruits, zesty citrus, and an abundance of greens, it's not just delicious—it's my daily dose of revitalization.
  //   `,
  //   link: '/products/wlc/green-goddess-dressing'
  // },

  {
    id: 6,
    staffName: `Liana, Content Strategist
    `,
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-U8T6ZMEAU-284676f97d4a-512',
    image: 'https://cdn.foodrevolution.org/members/assets/recipes/shepherds-pie.jpg',
    kind: 'Favorite Holiday Dish',
    title: 'Shepard’s Pie',
    description:
      'Layers of high-fiber and high-flavor plant-based ingredients might make this Shepherd’s Pie more satisfying than any other version you’ve tried.',
    staffReview: `This vegan lentil and walnut pie with a potato and butternut squash topping is a comforting and hearty dish, perfect for adding a wholesome and festive touch to your holiday table. 
    `,
    link: '/products/wlc/shepherds-pie'
  },

  {
    id: 6,
    staffName: `Zack, Developer 
    `,
    staffPic: 'https://ca.slack-edge.com/T0DMKQH60-UCG70LK8D-d7c31a7047f1-512',
    image: 'https://cdn.foodrevolution.org/members/assets/recipes/rainbow-smoothie.jpg',
    kind: 'Favorite Snack',
    title: 'Rainbow Smoothie',
    description:
      'With the combination of greens, lemon, pineapple, flax, and ginger, this smoothie is an anti-inflammatory win! Don’t worry, the flavors are balanced, so it also tastes really good!',
    staffReview: `Starting our day with the Rainbow Smoothie has transformed our mornings into a vibrant and fun routine that my daughter and I absolutely adore!`,
    link: '/products/wlc/rainbow-smoothie'
  }

  // Add more staff picks here...
];
//description old: Unlock the full potential of conversation spaces, recipes, and resources awaiting you!
const videoOne = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'Dive into our video walkthrough to master navigating the WHOLE Life Club Member Center',
  data: {
    url: 'https://www.youtube.com/watch?v=UI0dF3xH9O8',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const welcomeVideo = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'Dive into our video walkthrough to master navigating the WHOLE Life Club Member Center',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/c5cdbafdc57b31eb91e33ea9f7efc622/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

const connectedVideoOne = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'Get connected with Wil!',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/2b642986baf274c6f80ed3beecd5e356/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const connectedVideoTwo = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'Get connected with Rosie!',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/b6f4313555c5fc093aede92c55f15640/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const walkthroughActionHour = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Action Hour walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/6b9a947b558b503030b8321c0c6310a4/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const walkthroughForum = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Forum walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/520146a72cf6465ddc61aa7a49a358f3/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const walkthroughRecipesPartTwo = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Recipe walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/28be28eed0193ff133f116ad70dfbe65/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};
const walkthroughRecipesPartOne = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Recipes library walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/56c301bab4750cc09f1802768daffa4b/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

const walkthroughResources = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Resources walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/31fd1279c7c92b029ee5fd4f8d33e59d/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

const walkthroughWeeklyGuide = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'WLC - Onboarding Modules - Video - Member center walk through - Weekly Guide walkthrough',
  data: {
    url: 'https://customer-ros8xhsmbkksflna.cloudflarestream.com/add01ae8c36df2750517d0d4ec6cd7a8/watch',
    alt: 'A great video really just the best',
    description: '',
    thumb: {
      // url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

export const walkthroughVideos = [
  {
    displayProps: {
      title: `Weekly Guide
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Weekly Guide
      `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughWeeklyGuide]
  },
  {
    displayProps: {
      title: `Recipes Library
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Recipe Library
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughRecipesPartOne]
  },
  {
    displayProps: {
      title: `Recipes
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Recipes
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughRecipesPartTwo]
  },
  {
    displayProps: {
      title: `Forum
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Forum 
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughForum]
  },
  {
    displayProps: {
      title: `Action Hours
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Action Hours
    `,
    data: {
      time: 1506037155000,
      thumb: {
        // url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughActionHour]
  },
  {
    displayProps: {
      title: `Resources
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: `Resources
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [walkthroughResources]
  }
];

//old title: Dive into our video walkthrough to master navigating the WHOLE Life Club Member Center.
//Unlock the full potential of conversation spaces, recipes, and resources awaiting you!
export const videoResourceNoRecipe = {
  displayProps: {
    title: `
    `,
    subtitle: ``,
    //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: 'test alt'
    }
  },
  type: 'videoResource',
  name: 'Video Resource One',
  slug: 'video-resource-one',
  title: ` 
  `,
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    // bioOne, //Bio only needed for action hours
    // bioTwo,
    videoOne
    // downloadTwo,

    // linkedInterviews
  ]
};

export const tutorials = [
  {
    displayProps: {
      title: `
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: ` 
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [
      // bioOne, //Bio only needed for action hours
      // bioTwo,
      videoOne
      // downloadTwo,

      // linkedInterviews
    ]
  }
];
export const welcomeVideos = [
  {
    displayProps: {
      title: `
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: ` 
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [welcomeVideo]
  }
];

export const connectedVideos = [
  {
    displayProps: {
      title: `
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: ` 
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [connectedVideoOne]
  },
  {
    displayProps: {
      title: `
      `,
      subtitle: ``,
      //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
      image: {
        url:
          'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
        alt: 'test alt'
      }
    },
    type: 'videoResource',
    name: 'Video Resource One',
    slug: 'video-resource-one',
    title: ` 
    `,
    data: {
      time: 1506037155000,
      thumb: {
        url: 'http://via.placeholder.com/350x150',
        alt: 'Video Resource One',
        time: 1506037155000,
        //
        text:
          'Join our friendly tour guide and learn how to connect with fellow members and the Food Revolution Team.  We’re here to help!'
      },
      // Used for the 'header' image on the page
      img: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One'
    },
    Children: [connectedVideoTwo]
  }
];

export const celebrations = [
  {
    displayProps: {
      date: '2020-04-01T00:00:00.000Z',
      duration: '1:00:00',
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/celebration_1'
      },
      subtitle: `Look at you—you now know your way around the club. Let's move on to the next step.`,
      title: [`Terrific!`, `You're on your way to a healthier you!`]
    }
  },
  {
    displayProps: {
      date: '2020-04-01T00:00:00.000Z',
      duration: '1:00:00',
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/celebration_1'
      },
      subtitle: `
      Thank you for being a part of our community. Here's to your health!`,
      title: [`Keep an eye on your inbox!`, `We'll send tailored content based on your choice.`]
    }
  },

  {
    displayProps: {
      date: '2020-04-01T00:00:00.000Z',
      duration: '1:00:00',
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/celebration_1'
      },
      subtitle: `Let's keep exploring.`,
      title: [`Yay!`, `You're connected to the community!`]
    }
  },
  {
    displayProps: {
      date: '2020-04-01T00:00:00.000Z',
      duration: '1:00:00',
      image: {
        url: 'https://cdn.foodrevolution.org/wlc/social/celebration_1'
      },
      subtitle: `You're ready to jump into the club and start exploring. If you need help, reach out to our team at any time. `,
      title: [`Congratulations!`, ` You've completed orientation and are ready to go! `]
    }
  }
];
