import React, {Component, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import ReactPlayer from 'react-player';
import CloudflareStreamPlayer from './lib/CloudflareStream/CloudflareStreamPlayer';
import styles from 'styles/index.scss';
import {faPlay} from '@fortawesome/pro-regular-svg-icons/faPlay';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CardTitle, CardImgOverlay} from 'reactstrap';
import {track} from '../../../../lib/track';
const {videoWrapper} = styles;

ReactPlayer.addCustomPlayer(CloudflareStreamPlayer);

class Video extends Component {
  constructor(props) {
    super(props);

    this.src = this.props.videoDirectUrl
      ? this.props.videoDirectUrl
      : this.props.data && this.props.data.url
      ? this.props.data.url
      : null;
    this.id = this.props.id;

    // set save on component if you want to save
    this.save = props.save;
    this.state = {currentTime: null, complete: false};
    this.preload = this.preload.bind(this);
    this.seekToCurrentTimeAndPlay = this.seekToCurrentTimeAndPlay.bind(this);
    //for rudderstack
    this._onPlay = this._onPlay.bind(this);
    this._onEnd = this._onEnd.bind(this);
    this._onPause = this._onPause.bind(this);
    this._onProgress = this._onProgress.bind(this);

    this.isOnboard = !!this.props.handleComplete;
    //state
    this.state = {
      lastHeartBeat: 0
    };
  }

  async componentDidMount() {
    // do a saved user data look up early, on componentMount
    if (this.save && this.id) {
      window.addEventListener('beforeunload', this.saveProgress);
      this.preload();
    }
  }

  async preload() {
    const savedData = await this.props.get(this.id);
    if (savedData && savedData.currentTime) {
      this.setState({...savedData});
    }

    if (savedData && savedData.complete && this.isOnboard) {
      this.props.handleComplete(this.id);
    }
  }

  async seekToCurrentTimeAndPlay() {
    // seek to currentTime and play if saved in state, otherwise look up
    const savedData = await this.props.get(this.id);
    if (this.save) {
      if (this.state.currentTime) {
        this.player.seekTo(this.state.currentTime);
      } else if (savedData && savedData.currentTime) {
        this.setState({currentTime: savedData.currentTime});
        this.player.seekTo(savedData.currentTime);
      }
    }

    // optional - only if parent needs update on complete
    if (savedData && savedData.complete && this.props.handleComplete) {
      this.props.handleComplete(this.id);
    }
  }

  saveProgress = () => {
    if (this.save && this.player && this.id) {
      const currentTime = this.player.getCurrentTime();
      const duration = this.player.getDuration();
      const newState = {...this.state, currentTime};

      // The following is optional
      const timeRemaining = duration - currentTime;

      if (timeRemaining < 30) {
        const trackingData = {
          label: this.props.label ? {...this.props.label, ...this.props.userAnalytics} : this.src,
          ...(this.props.userAnalytics || {})
        };

        track('VIDEO_PLAYBACK_COMPLETED', {
          label: this.props.label ? {...this.props.label, ...this.props.userAnalytics} : this.src,
          ...(this.props.userAnalytics || {})
        });
      }
      // handleComplete updates parent (ie onboard) meta state
      if (timeRemaining < 10 && this.props.handleComplete) {
        this.props.handleComplete(this.props.id);
        newState.complete = true;
      }

      this.props.post(this.id, newState);
      this.updateParent(currentTime, duration);
    }
  };
  //let any listeners to this video know of the progress
  updateParent(currentTime, duration) {
    const percentage = (currentTime / duration).toFixed(2) * 100;

    if (this.props.videoProgressReport) this.props.videoProgressReport(percentage);
  }

  reset = () => {
    this.player.seekTo(0);
    this.saveProgress();
  };
  _onPlay = (...args) => {
    const trackingData = {
      label: this.props.label ? {...this.props.label, ...this.props.userAnalytics} : this.src,
      ...(this.props.userAnalytics || {})
    };

    track('Video Playback Started', {
      label: this.props.label ? {...this.props.label, ...this.props.userAnalytics} : this.src,
      ...(this.props.userAnalytics || {})
    });

    // thisonPlay.apply(this, args);
  };
  _onPause = (...args) => {
    const {onPause = () => {}, pauseThumbnail} = this.props;
    // if (pauseThumbnail || defaultThumbnail) setOverlay(pauseThumbnail || defaultThumbnail);
    // setPlaying(false);
    track('Video Playback Paused', {
      label: this.props.label ? this.props.label : this.src,
      ...(this.props.userAnalytics || {})
    });
    // onPause.apply(this, args);
  };
  _onProgress = (e, ...args) => {
    const {onProgress = () => {}} = props;
    const {played, playedSeconds} = e;
    if (lastHeartbeat < playedSeconds - 60) {
      //set state for lastHeartbeat
      this.setState({lastHeartBeat: playedSeconds});
      track('Video Content Playing', {
        position: Math.floor(playedSeconds),
        label: this.props.label ? this.props.label : this.src,
        ...(this.props.userAnalytics || {})
      });
    }
    onProgress.apply(this, [e, args]);
  };
  _onEnd = (e, ...args) => {
    //using saveProgress to update completion
  };

  render() {
    const {autoplay, data: {thumb: {url: poster = ''} = {}} = {}} = this.props;
    const config = {youtube: {}};

    if (autoplay) {
      config.youtube.playerVars = {autoplay: 1};
    }
    return (
      <div className={this.props.className}>
        <div className={videoWrapper}>
          {this.src ? (
            <ReactPlayer
              style={{background: 'transparent'}}
              config={config}
              controls={this.controls === undefined || this.controls}
              preload
              onPause={() => {
                this.saveProgress();
                this._onPause();
              }}
              onEnded={() => {
                this.saveProgress();
                this._onEnd();
              }}
              onProgress={this.props.onProgress}
              playing={this.props.playing}
              playsinline={true}
              onPlay={this._onPlay}
              poster={poster}
              url={this.src}
              ref={ref => {
                this.player = ref;
              }}
            />
          ) : (
            <div className="row align-items-center h-100 loadingVideo">
              <CardImgOverlay className="d-flex align-items-center justify-content-center text-center text-white">
                <CardTitle tag="h4">
                  <FontAwesomeIcon className="w-100" size="2x" icon={faPlay} />
                  <br />
                  Video Loading...
                </CardTitle>
              </CardImgOverlay>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Video.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.number,
  post: PropTypes.func.isRequired,
  save: PropTypes.bool
};

Video.displayname = 'Video';

Video.defaultProps = {
  id: 88888888,
  save: false,
  playing: false
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

const ConnectedVideo = connect(null, mapDispatchToProps)(Video);

export default ConnectedVideo;
