import React, {useEffect, useCallback, useState} from 'react';
import ReactPlayer from 'react-player';
import {Container, Row, Col, Button, ModalBody} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCirclePlay} from '@fortawesome/pro-regular-svg-icons/faCirclePlay';
import {faCirclePause} from '@fortawesome/pro-regular-svg-icons/faCirclePause';
import {faVolumeSlash} from '@fortawesome/pro-regular-svg-icons/faVolumeSlash';
import {faVolume} from '@fortawesome/pro-regular-svg-icons/faVolume';
import {faExpandWide} from '@fortawesome/pro-regular-svg-icons/faExpandWide';
import BackNav from 'routes/Products/components/BackNav';
const backNavWrapperStyle = {
  marginTop: 65,
  height: 20,
  width: 50,
  position: 'fixed',
  top: '10px',
  left: '10px',
  zIndex: 500
};
const HeroHeader = ({
  logo,
  background,
  children,
  title,
  enableBackNav = false,
  previewVideoUrl,
  disablePreviewVideo = false,
  hideContentOnVideoPlay = false,
  muted: _muted = true,
  videoFit = 'cover',
  comments = true
}) => {
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  const [forcePause, setForcePause] = useState(false);
  const [muted, setMuted] = useState(_muted);
  const [fullScreen, setFullScreen] = useState(false);

  //Reset state if disablePreviewVideo changed
  useEffect(() => {
    setShowPreviewVideo(false);
    setForcePause(false);
    setMuted(true);
    setFullScreen(false);
  }, [disablePreviewVideo]);
  useEffect(() => {
    if (previewVideoUrl) {
      setTimeout(() => setShowPreviewVideo(true), 30000);
    }
  }, [previewVideoUrl]);
  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        setFullScreen(false);
      }
    },
    [setFullScreen]
  );

  useEffect(() => {
    if (fullScreen) {
      document.addEventListener('keydown', escFunction, false);
      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    } else {
      document.removeEventListener('keydown', escFunction, false);
    }
  }, [fullScreen]);
  return (
    <div
      className={`heroHeader${fullScreen ? ' hasFullScreenVideo' : ''}${
        showPreviewVideo && hideContentOnVideoPlay && !forcePause ? ' hideOverlays' : ''
      }`}
    >
      {enableBackNav ? (
        <div style={backNavWrapperStyle}>
          <BackNav darkMode />
        </div>
      ) : (
        ''
      )}
      {logo || title ? (
        <div className="heroHeaderLogo">
          {logo ? (
            <h1>
              <picture>
                <img alt={logo.alt} src={logo.src} />
              </picture>
              <span style={{display: 'none'}}>{logo.alt}</span>
            </h1>
          ) : null}
          {title ? <h2>{title}</h2> : null}
        </div>
      ) : null}
      {!disablePreviewVideo && previewVideoUrl ? (
        <ReactPlayer
          className={`heroHeaderBackgroundVideo${fullScreen ? 'FullScreen' : ''}${
            videoFit === 'contain' ? ' fit-contain' : ''
          }`}
          url={previewVideoUrl}
          playing={showPreviewVideo && !forcePause}
          muted={muted}
          width="100%"
          height="100%"
          controls={0}
          onEnded={() => setShowPreviewVideo(false)}
          onPause={() => {
            setForcePause(true);
          }}
          onPlay={() => {
            setForcePause(false);
          }}
          style={{...(!showPreviewVideo ? {display: 'none'} : '')}}
        />
      ) : null}
      {background ? (
        <picture style={{opacity: showPreviewVideo ? 0 : 1}}>
          <source srcSet={background.src} type={background.type} />
          <img
            width="3024"
            height="1701"
            src="/assets/artwork/1x1.gif"
            alt={background.alt}
            className="heroHeaderBackground"
          />
        </picture>
      ) : null}
      {!disablePreviewVideo && showPreviewVideo ? (
        <div className="heroHeaderBackgroundVideoControls">
          <Button onClick={() => setForcePause(v => !v)} color="link-light">
            <FontAwesomeIcon icon={forcePause ? faCirclePlay : faCirclePause} size="1x" />
          </Button>
          <Button onClick={() => setMuted(v => !v)} color="link-light">
            <FontAwesomeIcon icon={muted ? faVolumeSlash : faVolume} size="1x" />
          </Button>
          <Button onClick={() => setFullScreen(true)} color="link-light">
            <FontAwesomeIcon icon={faExpandWide} size="1x" />
          </Button>
        </div>
      ) : null}
      <div className="heroHeaderContent">
        <div className="heroHeaderContentBackground" />
        <Container>
          <Row className="pt-4 mx-0" style={{width: '100%', margin: 0, padding: 0, alignItems: 'flex-end'}}>
            {children}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export const HeroHeaderBody = ({children}) => <Col className="heroHeaderBody">{children}</Col>;

export const HeroHeaderLeft = ({children, ...props}) => (
  <Col xs={12} sm={5} {...props} className="px-lg-5 heroHeaderAside">
    {children}
  </Col>
);

export const HeroHeaderRight = ({children, ...props}) => (
  <Col xs={12} sm={3} {...props} className="px-lg-5 heroHeaderAside">
    {children}
  </Col>
);

export default HeroHeader;
