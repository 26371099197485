import {routerReducer} from 'react-router-redux';
import adminReducer from './admin';
import applicationReducer from './application';
import userReducer from './user';
import modalReducer from './modal';
import checkoutReducer from './checkout';
import ordersReducer from './orders';

export default {
  admin: adminReducer,
  application: applicationReducer,
  router: routerReducer,
  user: userReducer,
  modal: modalReducer,
  checkout: checkoutReducer,
  orders: ordersReducer
};
