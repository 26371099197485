import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Util} from 'reactstrap';

const propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.object
};

const defaultProps = {
  tag: 'div'
};

const CardImgBg = props => {
  const {className, cssModule, top, bottom, tag: Tag, src, ...attributes} = props;

  let CardImgBgClassName = 'card-img-bg';
  if (top) {
    CardImgBgClassName = 'card-img-bg-top';
  }
  if (bottom) {
    CardImgBgClassName = 'card-img-bg-bottom';
  }

  const classes = classNames(className, CardImgBgClassName);

  const styles = {
    backgroundImage: `url(${src})`
  };

  return <Tag {...attributes} className={classes} style={styles} />;
};

CardImgBg.propTypes = propTypes;
CardImgBg.defaultProps = defaultProps;

export default CardImgBg;
