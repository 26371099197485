import React, {PureComponent} from 'react';
import {Container} from 'reactstrap';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import ListPage from '../../../PBCC/pages/ListPage';

class FavoritesPage extends PureComponent {
  constructor(props) {
    super(props);
  }

  state = {lists: []};

  componentDidMount() {
    this.getUserData({contentItemId: process.env.FAVORITES_ID});
  }

  getUserData({contentItemId}) {
    const data = this.props.userData(contentItemId);
    data.then(userData => {
      console.log('res favs', userData);
      if (!userData || !userData.favs) {
        return;
      } else {
        const lists = this.categorizeItems(userData.favs);
        this.setState({lists});
      }
    });
  }

  categorizeItems(items) {
    const recipes = {type: 'list', title: 'Your Recipes', Children: []};
    const weeks = {type: 'list', title: 'Your Weekly Guides', Children: []};
    const others = {type: 'list', Children: []};

    items.forEach(item => {
      if (item.type === 'recipe') {
        recipes.Children.push(item);
      } else if (item.type === 'week') {
        weeks.Children.push(item);
      } else {
        others.Children.push(item);
      }
    });
    const favCategories = [recipes, weeks, others].filter(list => list.Children.length);
    return favCategories;
  }

  render() {
    const {lists} = this.state;
    return (
      <Container style={{minHeight: '100vh'}}>
        {lists.length ? (
          <ListPage {...this.props} title="Your Favorites Library" Children={lists} />
        ) : (
          <em>No Favorites Available</em>
        )}
      </Container>
    );
  }
}

export default connect(
  state => ({
    user: state.user
  }),
  {
    userData: actions.getUserData,
    postUserData: actions.postUserData,
    patchUserData: actions.patchUserData
  }
)(FavoritesPage);
