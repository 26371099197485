import React from 'react';
import ReactPlayer from 'react-player';
import {Container, Col, Row} from 'reactstrap';
import './Video.scss';

export default function Video(props) {
  return (
    <div className="video-wrapper">
      <ReactPlayer className="video-player" width="100%" height="100%" {...props} controls={true} />
    </div>
  );
}
