import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {Button} from 'reactstrap';
import {useUpdateCheck} from 'react-update-notification';

const UpdateNotificationToast = () => {
  const [toastFired, setToastFired] = React.useState(false);
  const {status, reloadPage} = useUpdateCheck({
    type: 'interval',
    interval: 60000
  });
  useEffect(() => {
    if (status === 'checking' || status === 'current') {
      return;
    }
    if (toastFired) return;
    setToastFired(true);
    toast(
      <div className="text-center">
        A new version of the app is available
        <Button color="info" size="sm" onClick={reloadPage}>
          Update
        </Button>
      </div>,
      {
        duration: Infinity,
        position: 'bottom-center'
      }
    );
  }, [status]);

  return <></>;
};
export default UpdateNotificationToast;
