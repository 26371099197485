var prefix = 'frn';

import {frnSpoonForkCrossed} from './frnSpoonForkCrossed';
import {frnWLC} from './frnWLC';
var _iconsCache = {
  frnSpoonForkCrossed: frnSpoonForkCrossed,
  frnWLC: frnWLC
};

export {_iconsCache as frn, prefix, frnSpoonForkCrossed, frnWLC};
