import React, {Component} from 'react';

import {BreadcrumbItem, Container, Row, Col} from 'reactstrap';

import CoursebookCard from '../../components/CoursebookCard';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

import ResourcesListTwo from 'routes/Products/components/ResourcesListTwo';
/* todo remove:  this is the hhc extra resources resource list
{
    "displayProps": {
        "title": "Healthy Heart Course - Extra Resources",
        "subtitle": "Healthy Heart Course - Extra Resources",
        "image": {
            "url": "",
            "alt": ""
        },
        "description": "",
        "excerpt": ""
    },
    "data": null,
    "id": 25466,
    "name": "Healthy Heart Course - Resources",
    "title": "Healthy Heart Course - Extra Resources",
    "slug": "healthy-heart-course-extra-resources-resourceslist",
    "type": "resourcesList",
    "published": true,
    "free": false,
    "updatedAt": "2021-08-24T18:15:24.956Z",
    "createdAt": "2021-08-24T18:15:24.956Z"
}

//top level resource
{
    "displayProps": {
        "title": "Masterclass Workbook PDF",
        "subtitle": "Masterclass Workbook PDF",
        "image": {
            "url": "",
            "alt": ""
        }
    },
    "id": 25465,
    "name": "Download - Masterclass Workbook PDF",
    "title": "Masterclass Workbook PDF",
    "slug": "masterclass-workbook-pdf-download",
    "type": "download",
    "published": true,
    "free": false,
    "createdAt": "2019-10-23T21:03:34.000Z",
    "data": {
        "url": "https://cdn.foodrevolution.org/members/pdf/hhc/masterclass-workbook-pdf.pdf",
        "text": "Masterclass Workbook PDF",
        "type": "PDF",
        "thumb": {
            "alt": "Masterclass Workbook PDF",
            "url": "https://cdn.foodrevolution.org/members/assets/thumbnails/masterclass-workbook-pdf-download.png"
        }
    },
    "updatedAt": "2021-08-24T18:02:11.586Z"
}
*/
const CourseBookPage = props => {
  const {bonuses, product, resources} = props;
  const productInPath = location.pathname.split('/')[2];
  const customResourcesTitle = 'Below you will find the extra downloadable resources for easy access.';

  //we want the 'download' button to show.  add this option for each ContentCard
  resources.Children = resources.Children.filter(item => {
    const title = item.title.toLowerCase();
    return title !== 'coursebook' && title !== 'playbook';
  }).map(item => {
    item.showButton = true;
    return item;
  });
  return (
    <div>
      {/* <BackNav /> */}
      <Container>
        <BreadCrumbs>
          <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
            {product.title}
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Extra Resources
          </BreadcrumbItem>
        </BreadCrumbs>
        <h1>Resources</h1>
      </Container>

      {/* the course book is attached to bonuses (since it is a bonus component) */}
      {bonuses ? (
        <Container>
          {bonuses.map(contentItem => (
            <Row>
              <Col xs="12" className="mb-5">
                <CoursebookCard contentItem={contentItem} />
              </Col>
            </Row>
          ))}
        </Container>
      ) : null}
      <Container>
        {resources ? (
          <ResourcesListTwo
            customTitle={customResourcesTitle}
            disableViewAll
            {...resources}
            colSizes={{xs: 12, md: 4}}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default CourseBookPage;
