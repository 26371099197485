import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {actions} from 'modules/user';
import PrivateProfile from './PrivateProfile';
import PublicProfile from './PublicProfile';
import Summary from '../../Profile/components/Summary';
//why not use this only, over chunk? q for mark
//this chooses whether or not to render public or private profile
//1. checks for userID in the URL
//2. if userID matches myUserID (which is pulled from store, then it loads private)
//3. if not, it loads public profile
@connect(state => ({myUserId: state.user.userId}), {
  loadPublicProfile: actions.loadPublicProfile,
  loadProfile: actions.loadProfile,
  loadOrderHistory: actions.loadOrderHistory
})
class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    //console.log('in profile container this.props.publicProfileUserId', this.props.publicProfileUserId);
    this.state = {
      publicProfileUserId: this.props.publicProfileUserId,
      publicProfileUser: null,
      loading: true,
      error: false
    };
  }

  componentDidMount() {
    if (!this.props.showSummary) window.scrollTo(0, 0);
    const {myUserId} = this.props;

    //1. check if we have userid in props(used in user profile modal)
    //2. if not grab from url
    const publicProfileUserId = this.state.publicProfileUserId || this.props.location.pathname.split('/')[2];
    const isMe = !publicProfileUserId || publicProfileUserId == myUserId;

    // No user data needed; show 'my' private profile
    if (isMe) {
      this.setState({loading: false, error: false});
      this.props.loadProfile();
      this.props.loadOrderHistory();
    } else {
      this.loadPublicProfileData(publicProfileUserId);
    }
  }

  // componentDidUpdate
  componentWillReceiveProps = nextProps => {};

  reloadUserData = nextProps => {
    const props = nextProps || this.props;
    const publicProfileUserId = props.location.pathname.split('/')[2];
    if (this.state.publicProfileUser && !publicProfileUserId) {
      this.setState({publicProfileUser: null, error: false, loading: false});
    } else {
      this.loadPublicProfileData(publicProfileUserId);
    }
  };

  loadPublicProfileData = id => {
    this.props
      .loadPublicProfile(id) //from the Store
      .then(data => {
        if (data) {
          this.setState({publicProfileUser: data, error: false, loading: false});
        } else {
          console.log('error in retrieving profile');
          this.setState({error: true});
        }
      })
      .catch(err => {
        console.log('err in loading public profile ', err);
        this.setState({publicProfileUser: null, error: true, loading: false});
      });
  };

  // For testing only:
  // moveToPublic = () => {
  //   this.props.history.push(`/profile/1`);
  // }

  // goToProfileHome = () => {
  //   this.props.history.push(`/profile/`);
  // }

  render() {
    const {error, loading, publicProfileUser} = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>User not found</div>;
    }

    if (this.props.showSummary) {
      //used in the modal user profile.
      return (
        <Summary
          publicProfileUser={publicProfileUser}
          reloadUserData={this.reloadUserData}
          openCPModal={this.props.openCPModal}
          allowsDMs={this.props.allowsDMs}
          navigateToScreen={this.props.navigateToScreen}
        />
      );
    }
    if (!publicProfileUser) {
      return <PrivateProfile />;
    }
    return <PublicProfile publicProfileUser={publicProfileUser} reloadUserData={this.reloadUserData} />;
  }
}

export default withRouter(ProfileContainer);
