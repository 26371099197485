import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import styles from 'styles/index.scss';

const {
  SurveyPagedResultsAnswers,
  SurveyPagedResultsHeadline,
  SurveyPagedResultsQuestion,
  SurveyPagedResultsQuestionText
} = styles;

const SurveyPagedResults = props => (
  <div>
    <Row noGutters className={SurveyPagedResultsHeadline}>
      <h3 className={SurveyPagedResultsHeadline}>Tracking your growth</h3>
    </Row>

    {props.data.SurveyPagedQuestions.sort((a, b) => a.data.order - b.data.order).map(question => {
      if (question.data.track) {
        return (
          <Row noGutters className={SurveyPagedResultsQuestion}>
            <Col xs={12}>
              <div className={SurveyPagedResultsQuestionText}>
                <span>{question.data.text}</span>
              </div>
            </Col>
            <Col xs={6} className={SurveyPagedResultsAnswers}>
              <span>Then</span>
              <span>{props.start.values[question.name] || 5}</span>
            </Col>
            <Col xs={6} className={SurveyPagedResultsAnswers}>
              <span>and now . . .</span>
              <span>{props.end.values[question.name] || 5}</span>
            </Col>
          </Row>
        );
      }
      return null;
    })}
  </div>
);

SurveyPagedResults.propTypes = {
  data: PropTypes.shape({
    SurveyPagedQuestions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }).isRequired
};

// Page level; this gets data
export default SurveyPagedResults;
