import {videoResourceNoRecipe} from '../../../Products/components/VideoResource/testData.js';
import {recipeOne} from '../../components/Recipe/testData';
//import {videoResourceNoRecipe} from '../../components/VideoResource/testData';
export const product = {
  type: 'listProduct',
  slug: 'wlc-challenge',
  title: 'WLC Challenge',
  Children: [
    {type: 'list', Children: [videoResourceNoRecipe, recipeOne, recipeOne]},
    {type: 'list', Children: [videoResourceNoRecipe, recipeOne, recipeOne]},
    {type: 'list', Children: [videoResourceNoRecipe, recipeOne, recipeOne]},
    {type: 'list', Children: [videoResourceNoRecipe, recipeOne, recipeOne]}
  ]
};
