import React from 'react';

import TextArea from '../../../../../../../../components/Forms/Textarea';

const Feedback = ({questions, onChange, onSubmit}) => {
  const handleChange = ({name, value}) => {
    onChange({name, value});
  };

  return (
    <div className="welcome-container">
      <h5>We'd love your feedback!</h5>
      {questions &&
        questions.length &&
        questions.map((question, index) => (
          <TextArea key={index + 1} name={`Feedback ${index + 1}`} data={{text: question}} onChange={handleChange} />
        ))}

      <div className="mb-5"></div>
    </div>
  );
};

export default Feedback;
