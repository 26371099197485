import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Guidelines from 'routes/Products/components/Guidelines';
import {Col, Container, Row} from 'reactstrap';

class CommunityGuidelinesPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const slug = 'wlc-community-guidelines';

    return (
      <div>
        <Guidelines {...this.props} />
      </div>
    );
  }
}

CommunityGuidelinesPage.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default CommunityGuidelinesPage;
