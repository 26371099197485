import React from 'react';
import {Col, Row} from 'reactstrap';
import UHC from '../FRSCommon/UHC';
import UHCLogo from 'images/SVG/frn-uhc-logo.svg';

import {collections} from './collections.json';

class UHC21 extends React.Component {
  render() {
    const {props} = this;
    const {offerSlug = ''} = props;
    const overrides = {};
    if (offerSlug.substr(0, 3) === 'hhc') {
      overrides.headline = (
        <React.Fragment>
          Thank you for joining <em>Healthy Heart!</em>
        </React.Fragment>
      );
      overrides.videoUrl = 'https://youtu.be/QEuhg6Wz_cQ';
    }
    if (offerSlug.substr(0, 3) === 'ff-') {
      overrides.headline = (
        <React.Fragment>
          A Special, One-Time Offer For Our
          <br />
          <em>Fantastic Fungi</em> Impact Kit Community
        </React.Fragment>
      );
      overrides.videoUrl = 'https://youtu.be/2VJRwmU_cVA';
    }
    if (offerSlug.substr(0, 6) === 'hf-ftf') {
      overrides.headline = (
        <React.Fragment>
          A Special, One-Time Offer For Our
          <br />
          <em>From Food to Freedom Immersion Kit</em> Immersion Kit Community
        </React.Fragment>
      );
      overrides.videoUrl = 'https://youtu.be/Vr05iWbCkWQ';
    }
    if (offerSlug.substr(0, 3) === 'tt2') {
      overrides.videoUrl = 'https://youtu.be/E1q5N5ulWHM';
    }
    if (offerSlug.substr(0, 3) === 'ppp') {
      overrides.videoUrl = 'https://youtu.be/yASjLQng890';
    }
    return (
      <UHC
        speakerCollections={collections}
        videoUrl="https://www.youtube.com/watch?v=_os89QMeg24"
        slug={offerSlug}
        sku="uhc21"
        {...overrides}
        {...props}
      />
    );
  }
}
export default UHC21;
