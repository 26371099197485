/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {getWeekNameDate} from 'lib/time';
import ResourcesListTwo from '../ResourcesListTwo';

const Weeks = props => {
  const {sortedWeeksList, thisWeek} = props;
  // const allWeeks = sortedWeeksList.filter(week => week.id !== thisWeek.id);
  // allWeeks.unshift(thisWeek);
  const allWeeksWithDates = sortedWeeksList.map(week => {
    const {
      title,
      slug,
      data: {startTime, endTime}
    } = week;
    const _title = slug === 'getting-started' ? title : `${title} (${getWeekNameDate(startTime, endTime)})`;
    return {...week, title: _title};
  });

  return (
    <Container>
      <ResourcesListTwo
        customTitle={'Weekly Guides'}
        customList={allWeeksWithDates}
        isPageView={true}
        colSizes={{xs: 6, sm: 4, md: 3}}
      />
    </Container>
  );
};

Weeks.propTypes = {
  sortedWeeksList: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

Weeks.displayname = 'Weeks';

export default Weeks;
