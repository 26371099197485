import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import './BackgroundImageTextSection.scss';

export default function BackgroundImageTextSection(props) {
  const overlay = `background-section-image-${props.overlayColor}`;

  let styles = {
    backgroundImage: `url('${props.imgSrc}')`
  };

  let row = `d-flex justify-content-${props.imgPosition === 'left' ? 'end' : 'start'}`;
  let background = classNames(
    `background-section-image background-section-image-${props.imgPosition} wrapper`,
    props.className
  );

  return (
    <div className={classNames(background, overlay)} style={styles}>
      <Container>
        <Row className={row}>
          <Col className={props.colClass}>{props.children}</Col>
        </Row>
      </Container>
    </div>
  );
}

BackgroundImageTextSection.defaultProps = {
  overlayColor: 'white',
  colClass: 'col-12 col-lg-7'
};
