/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interuserActions */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/checkout';
import {actions as userActions} from 'modules/user';
import {Col, Container, FormGroup, Input, Label, Row, ListGroup, ListGroupItem} from 'reactstrap';
import FancyCheckbox from 'routes/Checkout/components/FancyCheckbox';
import Amex from 'images/SVG/Amex';
import Discover from 'images/SVG/Discover';
import MasterCard from 'images/SVG/MasterCard';
import PayPal from 'images/SVG/PayPal';
import PayPalBug from 'images/SVG/PayPalBug';
import Visa from 'images/SVG/Visa';

@connect(
  state => ({
    user: state.user,
    UserPaymentMethodId: state.checkout.orderRequest.UserPaymentMethodId
  }),
  {
    getPaymentMethods: userActions.getPaymentMethods,
    setSelectedUserPaymentId: actions.setSelectedUserPaymentId
  }
)
class StoredPaymentMethods extends Component {
  //props
  constructor(props) {
    super(props);
  }
  render() {
    const {UserPaymentMethods} = this.props.user;

    if (!UserPaymentMethods) {
      return (
        <div style={{marginBottom: '8px'}}>
          <span>No payment methods saved</span>
        </div>
      );
    }

    return (
      <ListGroup>
        {UserPaymentMethods.map(paymentMethod => {
          const {brand, expMonth, expYear, id, last4} = paymentMethod;
          return (
            <ListGroupItem
              onClick={() => this.props.setSelectedUserPaymentId(id)}
              key={id}
              className="d-flex align-items-center"
            >
              <FancyCheckbox checked={id === this.props.UserPaymentMethodId} className="mr-3" />
              {
                {
                  Visa: <Visa width="43" height="28" className="mr-3" />,
                  MasterCard: <MasterCard width="43" height="28" className="mr-3" />,
                  'American Express': <Amex width="43" height="28" className="mr-3" />,
                  Discover: <Discover width="43" height="28" className="mr-3" />,
                  paypal: <PayPalBug height="28" style={{padding: '0 11px'}} className="mr-3" />
                }[brand]
              }
              <strong>{brand === 'paypal' ? 'PayPal Billing Agreement' : brand}</strong>
              {'\u00a0'}
              {last4 ? ` ending in ${last4}` : ''}
              {expMonth && expYear ? (
                <span>
                  {'\u00a0'}
                  (Expires {expMonth}/{String(expYear).slice(2)})
                </span>
              ) : null}
            </ListGroupItem>
          );
        })}
        <ListGroupItem
          onClick={() => this.props.setSelectedUserPaymentId(null)}
          className={this.props.showStoredOnly ? 'd-none' : ''}
        >
          <FancyCheckbox checked={null === this.props.UserPaymentMethodId} className="mr-3" />
          <span>Use a different payment method</span>
        </ListGroupItem>
      </ListGroup>
    );
  }
}

StoredPaymentMethods.propTypes = {
  user: PropTypes.shape({
    UserPaymentMethods: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }).isRequired
};
StoredPaymentMethods.defaultProps = {
  user: {
    UserPaymentMethods: []
  }
};

export default StoredPaymentMethods;
