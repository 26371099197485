import React from 'react';

import {ForumWrapper} from 'components/ForumWrapper';
const WelcomePrompt = props => {
  const {currentStep, handleIntroPost} = props;
  return (
    <div className="welcome-container">
      <p>🍅 Remember, the goal is progress, not perfection!🍅 </p>

      <h5> What are the hardest parts of your plant-based journey? Where would you most like support right now?</h5>
      <p>Write your answer below, and click the “Post” button to post it in the Community Forum!</p>

      <div className="mb-5">
        <ForumWrapper
          className="onboardingPost"
          user={props.user}
          product="wlc"
          revokeDiscourseAPIKey={props.revokeDiscourseAPIKey}
          refreshToken={props.refreshToken}
          forumType="post"
          selectedCategory="Introductions"
          postCallback={handleIntroPost}
        />
      </div>
    </div>
  );
};

export default WelcomePrompt;
