import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentsAlt} from '@fortawesome/pro-regular-svg-icons/faCommentsAlt';
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {Container} from 'reactstrap';
import Seperator from 'components/Seperator';
import Icon from 'components/Icon';
function ModulePageHeader(props) {
  const {chevronNav, toggleModal, isUnstructured, scrollToContent, module} = props;
  const seperatorProps = {backgroundColor: 'grey', height: '30px', marginTop: '0px'};
  return (
    <div
      className=" border-bottom sticky-top bg-white zIndex100  modulePageHeader"
      style={{boxShadow: '0px 10px 10px -17px #111', top: '73px'}}
    >
      <div className="h-100 container">
        <div className=" d-flex justify-content-between h-100  align-items-center ">
          <div className="d-flex justify-content-left">
            {/* <div className="seperator bg-dark float-right d-lg-none "> </div> */}

            <div
              className="pointerGlobal justify-content-left"
              style={{fontSize: '20px'}}
              onClick={() => chevronNav('back')}
            >
              <Icon directPassIcon={['far', 'chevron-left']} />
              <div className="pointerGlobal d-none d-lg-inline">Previous </div>
            </div>
            <div className={'d-lg-none'}>
              <Seperator {...seperatorProps} />
            </div>
          </div>

          {isUnstructured ? null : (
            <div
              className={`text-success pointerGlobal`}
              style={{fontSize: '20px'}}
              onClick={() => {
                scrollToContent(true);
              }}
            >
              <FontAwesomeIcon color="#28a745" icon={faCommentsAlt} />
              <span className="pl-3">Discuss this {module.data.type ? module.data.type : 'Module'}</span>
            </div>
          )}
          <div className="d-flex justify-content-right">
            <div className={'d-lg-none'}>
              <Seperator {...seperatorProps} />
            </div>
            <div className="pointerGlobal" style={{fontSize: '20px'}} onClick={() => chevronNav('forward')}>
              <div className=" float-left pointerGlobal d-none d-lg-inline ">Next </div>
              <Icon directPassIcon={['far', 'chevron-right']} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModulePageHeader;
