import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getWeekNameDate} from 'lib/time';
import {Col, Container, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Row} from 'reactstrap';
import styles from 'styles/index.scss';
import getContent from 'lib/getContent';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
const {wlcWeekNavContainer, wlcWeekNaveMakeMeSmall, wlcWeekNavSelect, wlcWeekOf, textLarge} = styles;
function ReloadWrapper(props) {
  return <div className={props.class}>{props.children}</div>;
}
@connect(state => ({authToken: state.user.authToken}), {})
class WeekNav extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false, sortedWeeksList: null, thisWeek: null, contentItem: null};
    this.getWeeks = this.getWeeks.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    const {slug} = this.props.match.params;
    this.urlSlugForThisWeek = slug;
  }
  componentDidMount() {
    this.getWeeks();
  }

  toggleOpen() {
    this.setState({open: !this.state.open});
  }
  getWeeks() {
    const {product, slug} = this.props;

    getContent(slug, product, this.props.authToken)
      .then(res => {
        let thisWeek;

        if (!this.urlSlugForThisWeek) {
          thisWeek = res.Children[res.Children.length - 1];
        } else {
          thisWeek = res.Children.find(item => item.slug === this.urlSlugForThisWeek);
        }
        let sortedWeeksList = res.Children.map(week => {
          // const {title = '', slug = '', data: {startTime, endTime} = {}} = week ? week : {};
          const title = week ? week.title : '';
          const slug = week ? week.slug : '';
          const data = week ? week.data : {};
          const {startTime, endTime} = data;
          const _title = slug === 'getting-started' ? title : `${title} (${getWeekNameDate(startTime, endTime)})`;
          return {...week, title: _title};
        });
        this.setState({contentItem: res, thisWeek, sortedWeeksList: sortedWeeksList.reverse()});
      })
      .catch(e => {
        console.log('error getting Weeks in WeekNav', e.message);
      });
  }

  render() {
    // const {hideNav} = this.props;
    const {open, sortedWeeksList, thisWeek} = this.state;

    const path = location.pathname.split('/');
    const thisProductInPath = path[2];

    if (sortedWeeksList && sortedWeeksList.length) {
      // const {
      //   title,
      //   data: {startTime, endTime, isOnboard}
      // } = thisWeek;
      const title = thisWeek ? thisWeek.title : '';
      const data = thisWeek ? thisWeek.data : {};
      const {startTime, endTime, isOnboard} = data;

      const weekNameDate = startTime && endTime ? getWeekNameDate(startTime, endTime) : '';

      return (
        <ReloadWrapper key={sortedWeeksList[0].id} onClick={this.toggleOpen}>
          <Dropdown isOpen={this.state.open} toggle={this.toggleOpen}>
            <DropdownToggle caret tag="span">
              Recent Weekly Guides
            </DropdownToggle>
            <DropdownMenu right>
              {sortedWeeksList.map((week, i) => {
                const {
                  slug,
                  title,
                  data: {startTime, endTime}
                } = week;
                if (i < 5)
                  return (
                    <DropdownItem tag={Link} to={`/products/${thisProductInPath}/${slug}`}>
                      {title}{' '}
                      {slug === 'getting-started' ? null : <small>{`(${getWeekNameDate(startTime, endTime)})`}</small>}
                    </DropdownItem>
                  );
              })}
              <DropdownItem tag={Link} to={`/products/${thisProductInPath}/weeks`}>
                View all ...
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ReloadWrapper>
      );
    } else {
      console.log('sorted weeks list ', sortedWeeksList);
      return (
        <ReloadWrapper key="loading-weeks-nav">
          <Row>
            <Col className="pagetitle">
              {/* <h5>Weekly Guide </h5>
              <h1 style={{display: 'inline'}}></h1> */}
              {typeof sortedWeeksList !== 'undefined' && sortedWeeksList && !sortedWeeksList.length
                ? ''
                : 'Loading Guide...'}
            </Col>
          </Row>
        </ReloadWrapper>
      );
    }
  }
}

export default withRouter(WeekNav);
