import React from 'react';
import {Col, Row} from 'reactstrap';
import UHC from '../FRSCommon/UHC';
import UHCLogo from 'images/SVG/frn-uhc-logo.svg';

import {collections} from './collections.json';

class UHC20 extends React.Component {
  render() {
    const {props} = this;
    const IS_TNTG = props.offerSlug.substr(0, 4) === 'tntg';
    return (
      <UHC
        speakerCollections={collections}
        videoUrl={
          IS_TNTG
            ? 'https://player.vimeo.com/video/363686999?color=fff&byline=0&portrait=0'
            : 'https://www.youtube.com/watch?v=IUngxNsou08'
        }
        slug={props.offerSlug}
        sku="uhc20"
        {...props}
      >
        {IS_TNTG ? (
          <React.Fragment>
            <Row className="d-flex justify-content-center">
              <Col sm="10" md="11" lg="9" xl="10">
                <h5 className="section-only-heading text-center">
                  If your primary motivation in getting the Impact Kit is to improve your health, then this special
                  offer is for you!
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md="10" lg="8" xl="6">
                <img src={UHCLogo} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col sm="10" md="9" lg="11" xl="12" className="text-center">
                <p>
                  The Food Revolution Summit is an annual convergence of several hundred thousand people. It’s hosted by{' '}
                  <em>The Need To GROW’s</em> launch partner, Food Revolution Network. And now, for this one time only,
                  you can have special access.
                </p>
                <p>
                  <em>This opportunity won’t be available again at this price after you close this page.</em>
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col sm="10" md="9" lg="11" xl="12" className="text-center">
                <h5 className="section-only-heading text-center">
                  In each Food Revolution Summit, bestselling author John Robbins interviews 24 of the top food experts
                  on the planet. There have been more than 200 interviews to date, and we’ve hand-selected 20 of the
                  most powerful health-related interviews from these Summits to create this Ultimate Health Collection:
                </h5>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row className="d-flex justify-content-center">
              <Col sm="10" md="11" lg="9" xl="10">
                <h5 className="section-only-heading text-center">
                  Introducing The Food Revolution Ultimate Health Collection — If your primary motivation is to improve
                  your health, then this special offer is for you!
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md="10" lg="8" xl="6">
                <img src={UHCLogo} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col sm="10" md="9" lg="11" xl="12" className="text-center">
                <p>
                  There have been more than 175 interviews in past Food Revolution Summits. We’ve hand-selected 20 of
                  the most powerful health-related interviews from our previous Summits to create this Ultimate Health
                  Collection.
                </p>
                <p>
                  <em>This opportunity won’t be available again at this price after you close this page.</em>
                </p>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </UHC>
    );
  }
}
export default UHC20;

/* eslint-disable */
/*
import React, {PureComponent} from 'react';
import {Button, Col, Container, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import TNTG from './components/TNTG';
import FRS19 from './components/FRS19';
const CSS_URL = process.env.FRS_STYLESHEET_URL;

class UHC19 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
  }

  componentDidMount() {
    this.linkTag = document.createElement('link');

    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    document.getElementsByTagName('head')[0].appendChild(this.linkTag);
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
  }

  render() {
    const {props} = this;
    const IS_TNTG = props.offerSlug.substr(0, 4) === 'tntg';
    return <UHC>
      {IS_TNTG ? <TNTG {...props} /> : <FRS19 {...props} />}
    </UHC>
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="UpSellUHC19">
        
        <footer className="site-footer">
          <Container className="padding-top-1 padding-bottom-1 footer-padding-bottom-0">
            <Row>
              <Col xs="12" lg="6" className="text-white">
                © 2019 - Food Revolution Network
              </Col>
              <Col xs="12" lg="6">
                <nav className="nav-footer">
                  <ul className="inline-nav list-inline margin-0">
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/faq/">
                        FAQs
                      </a>
                    </li>
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/terms-and-conditions">
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}
export default UHC19;
*/
