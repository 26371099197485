import React from 'react';
import {Col, Row} from 'reactstrap';
import ReactMarkdown from 'lib/react-markdown';
import classNames from 'classnames';

export default function TestimonialBubbleItem({
  id,
  text,
  cite,
  image,
  textColor = 'black',
  bgColor = 'light-gray',
  textAlign = 'center',
  ...props
}) {
  return (
    <div className={classNames(`testimonial-bubble text-${textColor}`, props.className)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="12">
          <div className={classNames(`testimonial-content`)}>
            <ReactMarkdown
              className={classNames(
                `testimonial-copy testimonial-bubble-${bgColor} testimonial-bubble-${bgColor} text-${textColor}`
              )}
              source={text}
            />
            <div className="testimonial-cite">
              {image ? (
                <div className="d-flex align-items-center">
                  <div className="testimonial-image">
                    <img className="rounded-circle" src={image} />
                  </div>
                  <div className="testimonial-name">
                    <ReactMarkdown source={cite} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="testimonial-name">
                    <ReactMarkdown source={cite} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
