import React from 'react';
import {Container, Col, Row} from 'reactstrap';
import ReactMarkdown from 'lib/react-markdown';
import {speakers} from './data.json';
import './TntgSpeaker.scss';

function TntgItem({id, title, description, image, ...props}) {
  return (
    <div className="tntg-speaker py-3">
      <Container className="p-0" fluid>
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs="8" sm="6" lg="3" xl="2" className="mb-4 mb-lg-0">
            <img className="tntg-speaker-img img-fluid rounded-sm mx-auto" src={image} />
          </Col>
          <Col xs="12" lg="9" xl="10">
            <ReactMarkdown
              className="tntg-speaker-name text-condensed text-h3 text-800 text-center text-lg-left mb-2"
              source={title}
            />
            <ReactMarkdown className="tntg-speaker-description" source={description} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

TntgItem.defaultProps = {};

export default function TntgSpeakerList({id, ...props}) {
  return speakers.map(speaker => <TntgItem {...speaker} key={speaker.id} />);
}
