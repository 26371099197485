const videoOne = {
  type: 'video',
  slug: 'video-slug-one',
  name: 'Video One',
  title: 'Video One Title',
  data: {
    url: 'https://www.youtube.com/watch?v=UI0dF3xH9O8',
    alt: 'A great video really just the best',
    description:
      'This is text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random text lots of ipsum text lorem random',
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Video One Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

export const upcomingBroadcast = {
  type: 'video',
  slug: 'video-broadcast-one',
  name: 'Broadcast One',
  title: 'Broadcast One Title',
  data: {
    url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1',
    alt: 'A great video really just the best',
    startTime: 1537313252108,
    endTime: 1537316852108,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Broadcast One Thumb Text'
    }
  }
};

export const replayBroadcast = {
  type: 'video',
  slug: 'video-broadcast-two',
  name: 'Broadcast Two',
  title: 'Broadcast Two Title',
  data: {
    url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1',
    alt: 'A great video really just the best',
    startTime: 1526945672899,
    endTime: 1526949272899,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Broadcast Two Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

// 'Current' when the data is created
export const currentBroadcast = {
  type: 'video',
  slug: 'video-broadcast-three',
  name: 'Broadcast Three',
  title: 'Broadcast Three Title',
  data: {
    url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1',
    alt: 'A great video really just the best',
    startTime: Date.now(),
    endTime: Date.now() + 3600000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'One excellent video',
      text: 'Test Broadcast Three Thumb Text',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  }
};

export default videoOne;
