import React from 'react';
import PropTypes from 'prop-types';

const StaffPicks = ({staffPicks, currentStep}) => {
  goToLink = ({url}) => {
    window.open(url, '_blank');
  };

  return (
    <div className="container">
      <h3 className="text-center mb-4">Staff Picks</h3>
      <div className="row staff-picks">
        {staffPicks.map(pick => (
          <div
            key={pick.id}
            className="col-md-6  mb-4"
            onClick={() => {
              goToLink({url: pick.link});
            }}
            role="button"
          >
            <div className="card h-100">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mt-2">
                  <img src={pick.staffPic} className="rounded-circle profile-pic" alt={pick.staffName} />
                </div>
                <div
                  className="mt-0 text-center d-flex align-items-center justify-content-center"
                  style={{
                    height: '50px',
                    maxHeight: '50px',
                    overflowY: 'auto',
                    lineHeight: '1'
                  }}
                >
                  {pick.staffName}
                </div>
              </div>
              <h4 className=" mb-1 text-center mt-0">{pick.kind}</h4>
              <h5 className="card-title text-center">{pick.title}</h5>
              <p
                className="font-italic text-muted text-justify px-3 lh-1 pt-1 mt-0  d-flex align-items-center justify-content-center"
                style={{height: '160px', maxHeight: '160px', overflowY: 'auto'}}
              >
                "{pick.staffReview}"
              </p>
              <div class="aspect-ratio-16by9">
                <img
                  src={pick.image}
                  className=" img-fluid w-100 h-100"
                  style={{objectFit: 'cover'}}
                  alt={pick.title}
                />
              </div>
              <div className="card-body">
                {/* <h5 className="card-title">{pick.title}</h5> */}
                {/* <p className="card-text">{pick.description}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

//localhost:8000/products/wlc/velvety-asparagus-soup

http: StaffPicks.propTypes = {
  staffPicks: PropTypes.array.isRequired
};

export default StaffPicks;
