import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import RecurringPaymentCard from '../RecurringPaymentCard';
@connect(
  state => ({
    user: state.user
  }),
  {
    loadOrderHistory: actions.loadOrderHistory
  }
)
class UserInfoContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {orders: null};
    this.updateOrders = this.updateOrders.bind(this);
  }

  componentDidMount() {
    // this gets called by profile container
    this.setState({orders: this.props.user.orders});
  }

  updateOrders() {
    return this.props.loadOrderHistory().then(orders => {
      this.setState({orders});
    });
  }

  render() {
    const orders = this.state.orders && this.state.orders.length ? this.state.orders : this.props.user.orders;

    const subscriptions = (orders || []).filter(
      order =>
        !!order.subscription &&
        !!order.Subscription &&
        !order.Subscription.canceled &&
        order.Subscription.FirstOrderId === order.id
    );
    return (
      <div>
        {subscriptions.length ? (
          subscriptions.map(subscription => (
            <RecurringPaymentCard
              order={subscription}
              key={subscription.id}
              updateOrders={this.updateOrders}
              justShowBanner={this.props.justShowBanner}
            />
          ))
        ) : (
          <h5>
            <em>None</em>
          </h5>
        )}
      </div>
    );
  }
}

UserInfoContainer.propTypes = {};
//   updateProfile: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     firstName: PropTypes.string.isRequired,
//     lastName: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired
//   }).isRequired
// };

export default UserInfoContainer;
