import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard} from '@fortawesome/pro-light-svg-icons/faCreditCard';
import hostedFields from 'braintree-web/hosted-fields';
import {
  Alert,
  Button,
  Col,
  Container,
  Label,
  Row,
  FormFeedback,
  FormGroup,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody
} from 'reactstrap';

import Amex from 'images/SVG/Amex';
import Discover from 'images/SVG/Discover';
import MasterCard from 'images/SVG/MasterCard';
import SecureCheckout from 'images/SVG/SecureCheckout';
import Visa from 'images/SVG/Visa';

const ERROR_MESSAGES = {
  HOSTED_FIELDS_FIELDS_EMPTY: 'All fields are empty! Please fill out the form.',
  HOSTED_FIELDS_FIELDS_INVALID: 'One or more fields are invalid, please check your entries.',
  HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE:
    'This payment method has already been saved to your account, please select the saved card.',
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED: 'The card security code did not pass verification',
  HOSTED_FIELDS_FAILED_TOKENIZATION: 'We were are unable to verify your card, is the card valid?',
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR: 'Network error occurred when verifying your card.'
};

const CreditCardHostedField = ({name, status, label, invalidMessage}) => (
  <Col>
    <FormGroup>
      <Label for={`bt-hosted-${name}`}>{label}</Label>
      <div
        className={`form-control${!status || status.isEmpty || status.isValid ? '' : ' is-invalid'}`}
        id={`bt-hosted-${name}`}
        style={{height: 'calc(2.25rem + 9.5px)'}}
      />
      {!status || status.isEmpty || status.isValid ? null : <FormFeedback>{invalidMessage}</FormFeedback>}
    </FormGroup>
  </Col>
);

export class CreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cards: [], error: null, fields: {}, processing: false, showModal: false};
    this.hidePaymentSheet = this.hidePaymentSheet.bind(this);
    this.onHostedFieldChange = this.onHostedFieldChange.bind(this);
    this.setupHostedFields = this.setupHostedFields.bind(this);
    this.showPaymentSheet = this.showPaymentSheet.bind(this);
    this.tokenizeCard = this.tokenizeCard.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setupHostedFields() {
    hostedFields.create(
      {
        client: this.props.client,
        styles: {
          html: {
            'font-size': '10px'
          },
          input: {
            'font-size': '1.6rem',
            'line-height': '1.5',
            color: '#495057',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
          }
        },
        fields: {
          number: {
            selector: '#bt-hosted-number',
            placeholder: '•••• •••• •••• ••••'
          },
          expirationDate: {
            selector: '#bt-hosted-expirationDate',
            placeholder: 'MM / YY'
          },
          cvv: {
            selector: '#bt-hosted-cvv',
            placeholder: '•••'
          }
        }
      },
      (err, instance) => {
        if (err) {
          this.setState({error: err});
        }
        this.hostedFieldsInstance = instance;
        this.props.addBraintreeClientInstance(this.props.id, instance);
        this.hostedFieldsInstance.on('validityChange', this.onHostedFieldChange);
        this.hostedFieldsInstance.on('blur', this.onHostedFieldChange);
        this.onHostedFieldChange(this.hostedFieldsInstance.getState());
      }
    );
  }

  onHostedFieldChange(e) {
    if (e.fields) {
      const newState = {fields: e.fields};
      if (e.cards) newState.cards = e.cards;
      this.setState(newState);
    }
  }

  fireUpdateValidity() {
    const {
      props: {updateValidity},
      state: {fields}
    } = this;
    const result = fields && Object.keys(fields).every(key => fields[key].isValid);
    typeof updateValidity === 'function' && updateValidity(result);
    return result;
  }

  showPaymentSheet(e) {
    e.preventDefault();
    this.setState({showModal: true});
  }

  hidePaymentSheet() {
    this.setState({showModal: false});
  }

  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  tokenizeCard() {
    const {
      hostedFieldsInstance,
      state: {fields}
    } = this;
    if (Object.keys(fields).some(key => !fields[key].isValid)) return;
    this.setState({processing: true});
    hostedFieldsInstance.tokenize((err, payload) => {
      this.setState({processing: false});
      if (err) {
        if (ERROR_MESSAGES[err]) {
          this.setState({error: ERROR_MESSAGES[err]});
        } else {
          console.log(err);
          this.setState({error: `There was a problem verifying your credit card. (Code: ${err})`});
        }
      } else {
        this.props.setPaymentToken({gateway: 'braintree', token: payload});
      }
    });
  }

  render() {
    const {error, fields, cards, processing} = this.state;
    const Tag = this.props.tag || 'div';
    return (
      <div style={{height: '50px'}}>
        <Tag className="d-flex align-items-center">
          <span style={{width: '100%', maxWidth: '280px'}} className="m-auto text-center">
            <Button
              onClick={this.showPaymentSheet}
              style={{
                width: '100%',
                height: '45px',
                lineHeight: '1.1'
              }}
              className="d-flex justify-content-center align-items-center"
              color="dark"
            >
              <span className="pr-3">Buy with Card</span>
              <Visa />
              <MasterCard />
              <Amex />
              <Discover />
            </Button>
          </span>
          <Modal isOpen={this.state.showModal} toggle={this.toggleModal} onOpened={this.setupHostedFields} centered>
            <div className="modal-header d-flex justify-content-between align-items-center">
              <h5>Pay with Card</h5>
              <span>
                <Visa />
                <MasterCard />
                <Amex />
                <Discover />
              </span>
            </div>
            <ModalBody className={!Object.keys(fields).length ? 'skeleton' : ''}>
              {error ? <Alert>{error}</Alert> : null}
              <Container fluid>
                <Row>
                  <CreditCardHostedField
                    name="number"
                    status={fields.number}
                    label={'Card number'}
                    invalidMessage={'Card number is invalid.'}
                  />
                </Row>
                <Row>
                  <CreditCardHostedField
                    name="expirationDate"
                    status={fields.expirationDate}
                    invalidMessage={'Card expiration date is invalid.'}
                    label={'Exp. date'}
                  />
                  <CreditCardHostedField
                    name="cvv"
                    status={fields.cvv}
                    label={`CVV (${cards.length === 1 ? cards[0].code.size : 3} digits)`}
                    invalidMessage={'Card security code is invalid.'}
                  />
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter className="justify-content-between align-items-center">
              <SecureCheckout style={{height: '40px'}} />
              <span>
                <Button
                  color="light"
                  className="mr-3"
                  onClick={this.hidePaymentSheet}
                  disabled={processing}
                  tabIndex="-1"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={
                    !Object.keys(fields).length || Object.keys(fields).some(key => !fields[key].isValid) || processing
                  }
                  onClick={this.tokenizeCard}
                >
                  {processing ? 'Validating Card...' : 'Continue'}
                </Button>
              </span>
            </ModalFooter>
          </Modal>
        </Tag>
      </div>
    );
  }
}

CreditCard.defaultProps = {
  id: 'creditCard'
};

export default CreditCard;
