//create a class component
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Button, Container, Card, CardBody, CardHeader} from 'reactstrap';
import SavedAddresses from '../../../../../Checkout/components/SavedAddresses';
import checkoutReducer, {actions} from 'modules/checkout';
import {actions as userActions} from 'modules/user';
import {actions as modalActions} from 'modules/modal';
import {
  orderRequiresPayment,
  orderRequiresShipping,
  getCurrentOfferPaymentOption,
  getCurrentTotal
} from 'modules/checkout/selectors';
import OrderSummary from '../../../../../Checkout/components/OrderSummary';
import PaymentMethods from '../../../../../../routes/Checkout/components/PaymentMethods';
import {StoredPaymentMethods} from '../../../../../Checkout/components/PaymentMethods/components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserInfo from '../../../../../Checkout/components/UserInfo';
import BillingInfo from '../../../../../Checkout/components/BillingInfo';
import PurchaseButton from 'routes/Checkout/components/PurchaseButton';

const {RECAPTCHA_KEY} = process.env;

const {loadTotals, setNewAddress, setSelectedBillingAddress, setSaveAddress} = actions;

/*
1. get offer slug from props
2. get offer using the store method loadOffer(offerSlug)
3. load offer grabs the defaults shipping/billing address, and default payment method, and total.  These are updated in the store.
4. In the store
    a. orderRequest.UserPaymentMethodId: action.id, //default payment
    b. orderRequest.BillingAddress: {id: action.id}, //default shipping
*/
@connect(
  state => ({
    addresses: state.user.addresses,
    currentUpsellIdx: state.checkout.currentUpsellIdx,
    currentOfferPaymentOption: getCurrentOfferPaymentOption(state),
    isAuthenticated: state.user.isAuthenticated,
    location: state.router.location,
    modalOpen: state.modal.showModal,
    offer: state.checkout.offer,
    orderFormMessage: state.checkout.orderFormMessage,
    orderRequest: state.checkout.orderRequest,
    orderRequiresPayment: orderRequiresPayment(state),
    orderRequiresShipping: orderRequiresShipping(state),
    selectedBillingAddressId: state.checkout.orderRequest.BillingAddress
      ? state.checkout.orderRequest.BillingAddress.id
      : null,
    submitted: state.checkout.submitted,
    total: getCurrentTotal(state),
    totals: state.checkout.totals,
    useSameShippingAddress: state.checkout.useSameShippingAddress,
    user: state.user,
    UserPaymentMethodId: state.checkout.orderRequest.UserPaymentMethodId,
    userProducts: state.user.products,
    upsellAckd: state.checkout.upsellAckd
  }),
  {
    ackUpsell: actions.ackUpsell,
    clickBumpOffer: actions.clickBumpOffer,
    hideModal: modalActions.hideModal,
    loadOffer: actions.loadOffer,
    loadTotals: actions.loadTotals,
    loadUserFromState: actions.loadUserFromState,
    openModal: modalActions.openModal,
    processUpgrade: actions.processUpgrade,
    processUpSell: actions.processUpSell,
    receiveOffer: actions.receiveOffer,
    reset: actions.reset,
    resetOnLeavePage: actions.resetOnLeavePage,
    saveNewUserInfo: userActions.receiveUpdateProfile,
    setNewAddress: actions.setNewAddress,
    setOrderFormMessage: actions.setOrderFormMessage,
    setRecaptchaToken: actions.setRecaptchaToken,
    getUserProducts: userActions.getUserProducts,
    setPaymentMethod: actions.setPaymentMethod,
    setReceiptMessage: actions.setReceiptMessage,
    setSelectedBillingAddress: actions.setSelectedBillingAddress,
    setSelectedPaymentOption: actions.setSelectedPaymentOption,
    setSelectedShippingAddress: actions.setSelectedShippingAddress,
    setSelectedUserPaymentId: actions.setSelectedUserPaymentId,
    setPaymentToken: actions.setPaymentToken
  }
)
class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
    this.successMessage = this.successMessage.bind(this);
  }
  componentDidMount() {
    this.props.reset();
    this.props.loadOffer(this.props.slug);
  }
  //check if user products changes, and then close out modal
  componentDidUpdate(prevProps) {
    if (this.props.user.products !== prevProps.user.products) {
      this.setState({success: true});
    }
  }
  successMessage() {
    return (
      <div className="mx-5 my-5">
        {' '}
        <h3>Congratulations!</h3> <h5>You’ve just unlocked the Advanced Empowerment Package.</h5>
        <p>
          We've sent you an email with your receipt and details about some of the bonuses you unlocked. In the mean time
          enjoy watching all 45 Extended Interviews right away!
        </p>
        <div className="text-center mt-5">
          <Button
            onClick={() => {
              location.reload();
            }}
            size="lg"
          >
            Take me back to the Empowerment Package
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {addresses, offer, selectedBillingAddressId, title} = this.props;
    const {success} = this.state;
    return (
      <Container>
        {/* <CardHeader>Upgrade {title ? title : ''}</CardHeader> */}
        {success ? (
          <div>{this.successMessage()}</div>
        ) : (
          <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_KEY}
            useEnterprise={true}
            container={{
              element: 'recaptchaBadge'
            }}
          >
            {/* just for testing */}
            {false && offer ? JSON.stringify(offer) : ''}
            {false && this.props.orderRequest ? JSON.stringify(this.props.orderRequest) : ''}
            {false && this.props.total ? JSON.stringify(this.props.total) : ''}
            <h4>Order Summary</h4>
            <OrderSummary {...this.props} />
            <h4>Saved Payment Option</h4>
            {/* <StoredPaymentMethods /> */}
            <PaymentMethods />
            {/* <CardBody>{addresses ? JSON.stringify(addresses) : 'no offer'}</CardBody> */}
            <h4>Billing Address</h4>
            <BillingInfo orderRequiresShipping={false} />
            {false ? (
              <SavedAddresses
                showDefaultOnly
                {...this.props}
                selectedAddressId={selectedBillingAddressId}
                // selectAddress={setSelectedBillingAddress}
              />
            ) : (
              ''
            )}
            <PurchaseButton processOrder={this.props.processUpgrade} setRecaptchaToken={this.props.setRecaptchaToken} />
            <div id="recaptchaBadge" className="mt-3"></div>
          </GoogleReCaptchaProvider>
        )}
      </Container>
    );
  }
}

export default withRouter(Upgrade);
