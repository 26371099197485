import React, {useEffect} from 'react';
import {Col, Row} from 'reactstrap';
import ContentCard from 'routes/Products/components/ContentCard';
import styles from 'styles/index.scss';
import {FormGroup, Input} from 'reactstrap';
const {resourcesListTwoResources} = styles;

const Grid = ({Children, colSizes, disableViewAll, includeSearch, isPageView, list, placeholder, title}) => {
  const grid = list || Children;
  const path = location.pathname.split('/');
  const [searchText, setSearchText] = React.useState('');

  useEffect(() => {
    if (isPageView) {
      window.scrollTo(0, 0);
    }
  }, [isPageView]);
  return (
    <div className="resourcesListTwoContainer">
      {title ? (
        <div>
          <Row className="align-items-end">
            <Col xs="auto">
              <h4>{title ? title : ''}</h4>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {includeSearch ? (
        <Row className="align-items-end">
          <Col xs="auto">
            {/* input form for searchText */}
            <FormGroup>
              <Input
                type="text"
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                placeholder={placeholder ? placeholder : 'search'}
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row className={resourcesListTwoResources}>
        {grid
          ? searchText
            ? grid
                .filter(item => {
                  return item.title && item.title.toLowerCase().includes(searchText.toLowerCase());
                })
                .map((item, i) => (
                  <Col xs="12" sm="4">
                    <ContentCard key={item ? item.id : i} {...item} className="h-100 card-resource" />
                  </Col>
                ))
            : grid.map((item, i) => (
                <Col xs="12" sm="4">
                  <ContentCard key={item ? item.id : i} {...item} className="h-100 card-resource" />
                </Col>
              ))
          : null}
      </Row>
    </div>
  );
};

export default Grid;
