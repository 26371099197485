import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {Card, CardBody, CardImg, CardTitle, Col, Container, Row} from 'reactstrap';
import {actions} from 'modules/user';
import styles from 'styles/index.scss';

const {USER_CONTENT_URL} = process.env;
const {myProductsContainer, myProductsListContainer, myProductsTitle} = styles;

class MyProducts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {loading: true};
  }

  componentDidMount() {
    // getUserProducts is unique - it accepts a callback param
    // which is called in either success or failure case for load user products
    this.props.getUserProducts(this.setStateAfterGetUserProducts);
  }

  setStateAfterGetUserProducts = () => {
    this.setState({loading: false});
  };

  renderNoProducts = () => {
    return <div>No products to display</div>;
  };

  navigateToProduct = slug => () => {
    this.props.history.push(`/products/${slug}`);
  };

  renderProductsList = () => {
    return (
      <div>
        <div className={myProductsTitle}>
          <span>My Products</span>
        </div>
      </div>
    );
  };

  render() {
    const {
      user: {products}
    } = this.props;
    const {loading} = this.state;

    if (loading) {
      return <div>Loading . . . </div>;
    }

    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <h4>My Products</h4>
          </Col>
        </Row>
        <Row>
          {this.props.user.products
            .filter(e => e.ContentItemId && e.slug.search(/bonuses|resource-kit|-advanced|-complete/i) === -1)
            .map(product => {
              return (
                <Col key={product.slug} xs="6" md="4" className="mb-4 mt-4">
                  <Card className="h-100 card-button" onClick={this.navigateToProduct(product.slug)}>
                    <CardImg
                      top
                      width="100%"
                      src={product.img || `${USER_CONTENT_URL}product-placeholder.png`}
                      alt={product.name}
                    />
                    <CardBody>
                      <CardTitle>{product.name.replace(' - Basic', '')}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Container>
    );
  }
}

MyProducts.propTypes = {
  user: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  getUserProducts: callback => {
    dispatch(actions.getUserProducts(callback));
  }
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MyProducts);
