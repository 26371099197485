import API from './api';

function getContent(contentSlug, productSlug, authToken, query) {
  //need to get the slug from the URL
  let url = `/content/${contentSlug}/${productSlug}`;
  if (!productSlug) url = `/content/${contentSlug}`;

  console.log('url to hit', url);
  if (query) {
    //eg depth=1
    url = url + query;
  }

  return API.get(url, authToken);
}

export default getContent;
