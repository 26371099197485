import React, {PureComponent} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {capitalizeFirstLetter} from '../../../../../../lib/characters';
import ResourcesListTwo from '../../../../components/ResourcesListTwo';
import {page} from '../../../../../../lib/track';
// content card page creates a page from a content card
function ResourceListPage({contentItem, customTitle}) {
  page('resources');
  return (
    <Container>
      <Row>
        <Col xs="auto">
          <h4>{customTitle ? customTitle : `${capitalizeFirstLetter(contentItem.type)}: ${contentItem.title}`}</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <ResourcesListTwo isPageView disableViewAll Children={[contentItem]} customTitle={` `} />
        </Col>
      </Row>
    </Container>
  );
}

export default ResourceListPage;
