import React, {Component} from 'react';
import getContent from 'lib/getContent';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import Article from 'routes/Products/products/WLC/pages/Article';
import Bonuses from 'routes/Products/products/WLC/pages/Bonuses';
import Collection from 'routes/Products/products/WLC/pages/Collection';
import CollectionsList from 'routes/Products/products/WLC/pages/CollectionsList';
import Recipe from 'routes/Products/products/WLC/pages/Recipe';
import RecipesList from 'routes/Products/components/RecipesList';
import SpecialReports from 'routes/Products/products/WLC/pages/SpecialReports'; //'//../../pages/SpecialReports';
import Tools from 'routes/Products/products/WLC/pages/Tools';
import VideoResource from 'routes/Products/components/VideoResource'; //'../../routes/Products/products/WLC/pages/Video';
import Video from 'routes/Products/components/Video'; //'../../routes/Products/products/WLC/pages/VideoResource';
import WeekPage from 'routes/Products/products/WLC/pages/WeekPage';
import ZoomMeeting from 'routes/Products/components/ZoomMeeting';

import ResourcesListTwo from '../../routes/Products/components/ResourcesListTwo/index';
import List from '../../components/List';
import ContentCard from '../../routes/Products/components/ContentCard';
import {resetWarningCache} from 'prop-types';
import ContentCardPage from '../../routes/Products/products/WLC/pages/ContentCardPage';
import ResourceListPage from '../../routes/Products/products/WLC/pages/ResourceListPage';
const _Link = ({contentItem}) => {
  return (
    <Container>
      <Row>
        <Col>
          <ResourcesListTwo
            isPageView
            disableViewAll
            Children={[contentItem]}
            customTitle={`Resource ${contentItem.type} `}
          />
        </Col>
      </Row>
    </Container>
  );
};
const _DownloadV1 = ({contentItem}) => {
  return <List contentItems={[contentItem]} />;
};

const _Download = ({contentItem}) => {
  return <ContentCard defaultValues={{img: DEFAULT_DOWNLOAD_IMG}} {...contentItem} />;
};
// this container automatically returns the component based on the URL slug
//makes its API own  api call
@connect(state => ({authToken: state.user.authToken}), {})
class GeneralContentContainerApiComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {contentItem: null, Component: null};
    //based off the type returned from the api call with URL slug, this renders one of the component below
    this.componentDictionary = {
      recipe: Recipe,
      videoResource: VideoResource,
      article: Article,
      bonuses: Bonuses,
      collection: Collection,
      collectionsList: CollectionsList,
      recipesList: RecipesList,
      specialReports: SpecialReports, //
      tools: Tools,
      videoResource: VideoResource,
      video: Video,
      week: WeekPage,
      link: ResourceListPage,
      download: ContentCardPage,
      zoomMeeting: ZoomMeeting
    };
  }
  componentDidMount() {
    const thisPath = location.pathname.split('/');
    let productSlug = thisPath[2];

    let contentSlug;
    if (this.props.overrideSlug) {
      contentSlug = this.props.overrideSlug;
    } else {
      let {slug} = this.props.match.params;
      contentSlug = slug;
    }

    //get content from API
    //`/content/${contentSlug}/${productSlug}`
    if (contentSlug) {
      getContent(contentSlug, productSlug, this.props.authToken)
        .then(res => {
          if (res) {
            console.log('res ', res, 'component ', this.componentDictionary[res.type]);
            if (this.props.dataMassager) {
              const finalContentItem = this.props.dataMassager(res);
              this.setState({contentItem: finalContentItem});
            } else {
              this.setState({contentItem: res, Component: this.componentDictionary[res.type]});
            }
          }
        })
        .catch(err => {
          console.log('error in General Container GET', err);
        });
    }
  }
  render() {
    const {contentItem, Component} = this.state;
    const {componentProps} = this.props;
    //for GlobalSearch
    // contentItem.Children is for legacy   //product is just for recipes/GlobalSearch right now, will update when not needed
    if (contentItem && Component) {
      return (
        <div>
          <Component
            {...contentItem}
            {...componentProps}
            key={contentItem.id}
            contentItem={contentItem}
            Children={contentItem ? contentItem.Children : null}
            product={contentItem}
          />
        </div>
      );
    } else {
      return <div className="text-center ">Loading content...</div>;
    }
  }
}

export default withRouter(GeneralContentContainerApiComponent);
