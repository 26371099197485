import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import GenericAddressForm from 'components/Forms/Address';

const fieldLayout = [
  {key: 'name', fields: ['name']},
  {key: 'address1', fields: ['address1']},
  {key: 'address2', fields: ['address2']},
  {key: 'city-state', fields: ['city', 'state', 'postalCode']},
  {key: 'country-postal', fields: ['country']}
];

class AddressModal extends PureComponent {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {address: props.address};
  }

  handleAddressFieldChange = updatedValues => {
    this.setState((state, props) => ({address: {...state.address, ...updatedValues}}));
  };

  handleAddressValidity = allFieldsValid => {
    this.setState({validAddress: allFieldsValid});
  };

  save = () => {
    this.props.addOrUpdateAddress(this.state.address, this.props.toggle);
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.address !== prevProps.address || this.props.address.id !== prevProps.address.id) {
      this.setState({address: this.props.address});
    }
  }

  render() {
    const {
      props: {toggle, isOpen},
      state: {validAddress, saving}
    } = this;
    return isOpen ? (
      <Modal centered isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>{this.props.address.id ? 'Edit' : 'Add'} Address</ModalHeader>
        <ModalBody>
          <GenericAddressForm
            {...this.props}
            from="billing"
            disabledFields={['email', 'phone', 'firstName', 'lastName']}
            fullWidth
            contigentRow="country-postal"
            disabledContigentFields={['email', 'city', 'state', 'phone', 'address1', 'address2']}
            fieldLayout={fieldLayout}
            values={this.state.address}
            setValues={this.handleAddressFieldChange}
            checkValidity={this.handleAddressValidity}
          />
          <Button disabled={!validAddress && !saving} onClick={this.save} className="mr-2">
            Save
          </Button>
        </ModalBody>
      </Modal>
    ) : null;
  }
}

AddressModal.propTypes = {
  address: PropTypes.shape({}).isRequired,
  toggle: PropTypes.func.isRequired,
  addOrUpdateAddress: PropTypes.func.isRequired
};

export default AddressModal;
