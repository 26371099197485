/* eslint-disable */
import React, {PureComponent} from 'react';
import {Button, Col, Container, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import Link from 'react-router-dom';
const USER_CONTENT_URL = process.env.USER_CONTENT_URL;
const CSS_URL = process.env.FRS_STYLESHEET_URL;

class UHCSpeaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalOpen: false};
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(e) {
    e.preventDefault();
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  }

  render() {
    const {description, img, name, presentation} = this.props;
    return (
      <Col xs="6" lg="3" className="speaker-list-item">
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs="12" className="text-center">
            <a className="speaker-list-item-link" onClick={this.toggleModal}>
              <img
                className="speaker-headshot img-responsive img-speaker-shadow center-block rounded-circle mb-3"
                src={`${USER_CONTENT_URL}${img}`}
              />
            </a>
            <h6 className="speaker-name mb-0">{name}</h6>
            <p className="speaker-presentation text-small mb-0">{presentation}</p>
          </Col>
        </Row>
        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal} />
          <ModalBody>
            <Row className="d-flex align-items-center justify-content-center">
              <Col xs="8" lg="4" className="mb-3 mb-md-0">
                <img
                  className="speaker-headshot img-responsive center-block rounded-circle"
                  src={`${USER_CONTENT_URL}${img}`}
                  onClick={this.toggleModal}
                />
              </Col>
              <Col xs="12" lg="8">
                <h2 className="speaker-name mb-1">{name}</h2>
                <h4 className="speaker-presentation text-400">{presentation}</h4>
                <p className="mb-0" dangerouslySetInnerHTML={{__html: description}} />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Col>
    );
  }
}

class UHC18 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
  }

  componentDidMount() {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = CSS_URL;
    link.onload = () => this.setState({stylesLoaded: true});
    document.getElementsByTagName('head')[0].appendChild(link);
    this.linkTag = link;

    var trial = document.createElement('script');
    trial.type = 'text/javascript';
    trial.async = true;
    trial.src =
      'https://easy.myfonts.net/v2/js?sid=210860(font-family=Avenir+Next+Pro+Bold)&sid=217165(font-family=Avenir+Next+Pro+Regular)&sid=217166(font-family=Avenir+Next+Pro+Medium)&sid=255228(font-family=Avenir+Next+Pro+Light)&key=He6a8LY7fL';
    document.getElementsByTagName('head')[0].appendChild(trial);
    this.fontTag = trial;
  }

  componentWillUnmount() {
    this.linkTag.remove();
    this.fontTag.remove();
  }

  render() {
    const {props} = this;
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="UpSellUHC18">
        <section
          id="wlc-header-banner"
          className="background-image background-image-center background-image-cover background-image-fixed background-image-tropical-foods background-overlay background-overlay-gradient-purple-blue-green-diagonal text-white wrapper pt-0"
        >
          <header
            id="wlc-header"
            className="wlc-header background-black-50 text-white arrow-bottom arrow-bottom-black-50 py-4"
          >
            <Container>
              <Row className="d-flex align-items-center justify-content-center text-center">
                <Col xs="12">
                  <h3 className="text-600">Thank you for your purchase!</h3>
                  <p className="mb-0">Here's one more opportunity for you to consider.</p>
                </Col>
              </Row>
            </Container>
          </header>

          <Container className="pt-5">
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="10">
                <div id="yt-wlc-video" style={{position: 'relative', height: 0, paddingBottom: '56.25%'}}>
                  <iframe
                    src="https://www.youtube.com/embed/?ecver=2&amp;wmode=transparent&amp;showinfo=0&amp;modestbranding=1&amp;vq=&amp;rel=0&amp;cc_load_policy=1&amp;theme=light&amp;fs=0&amp;color=white&amp;playsinline=1&amp;enablejsapi=1&amp;autoplay=1&amp;mute=0&amp;cc_load_policy=1"
                    width="640"
                    height="360"
                    frameborder="0"
                    allowfullscreen=""
                    style={{
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      width: '100%'
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div id="wlc-introducing" className="background-green wrapper text-white text-center">
          <Container>
            <Row className="d-flex justify-content-center mb-3">
              <Col xs="12" sm="10" lg="12">
                <h3 className="text-600 mb-0">
                  The Food Revolution Ultimate Health Collection — <br className="d-none d-md-block" />A Great Way to
                  Keep Improving Your Healthy Lifestyle
                </h3>
              </Col>
            </Row>
            <Row className="flex justify-content-center">
              <Col xs="12" sm="10" lg="12">
                <h6 className="text-400 mb-3">
                  If your motivation is to improve your health and the health of your loved ones, then this special
                  offer is for you!
                </h6>
                <h6 className="text-400 mb-0">
                  <em>This opportunity won’t be available again at this price after you close this page.</em>
                </h6>
              </Col>
            </Row>
          </Container>
        </div>
        <section id="uhc-speakers" className="uhc-speakers">
          <div className="wrapper pb-0">
            <Container>
              <Row>
                <Col xs="12">
                  <h4 className="text-center text-uppercase mb-0">
                    The Ultimate Health Collection includes <br className="d-none d-sm-block" />
                    John Robbins interviewing:
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
          {COLLECTIONS.map((collection, i) => (
            <div className={`wrapper${i % 2 ? ' background-mint' : ''}`} key={i}>
              <Container>
                <Row className="d-flex justify-content-center">
                  <Col xs="12" lg="10">
                    <h2 className="text-600 text-center mb-3">{collection.title}</h2>
                    <Row>
                      {collection.presentations.map((presentation, j) => (
                        <UHCSpeaker {...presentation} />
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </section>
        <div className="offer">
          <div className="arrow-bottom arrow-bottom-green background-green py-5 text-white text-center">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <h1 className="text-600 mb-0">One Time Offer Only!</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-copy wrapper pt-5 pb-0">
            <Container>
              <Row>
                <Col xs="12" className="text-center">
                  <h4 className="text-600 mb-3">
                    Click the button below, and this premium collection will be yours, instantly.
                  </h4>
                  <p>
                    <Button
                      color="orange"
                      className="text-700 text-h6 text-uppercase btn-upsell-yes"
                      onClick={e => {
                        this.setState({processing: true});
                        props.handleClickUpsell(e);
                      }}
                      disabled={this.state.processing}
                    >
                      Yes, add this collection to my order for only ${props.amount}
                    </Button>
                  </p>
                  <p className="mb-4">
                    <Button
                      color="medium-gray"
                      className="text-600 text-small btn-upsell-no"
                      onClick={e => {
                        this.setState({processing: true});
                        props.handleDeclineUpsell(e);
                      }}
                      disabled={this.state.processing}
                    >
                      No, thanks
                    </Button>
                  </p>
                  <p>
                    Clicking "Yes" above will add The Ultimate Health Collection to your purchase now, for an additional
                    ${props.amount}.
                  </p>
                  <p>
                    <strong>Your purchase comes with a risk-free, 60-day, money-back guarantee.</strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="welcome-offer-trees background-image background-image-center background-image-trees background-overlay background-trees-overlay wrapper">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col xs="12" lg="10">
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col xs="12" sm="9" lg="8" className="mb-3 mb-sm-0 text-center text-sm-left">
                      <h4 className="text-400">
                        Want a world where <strong>everyone</strong> has safe and healthy food? We do, too!
                      </h4>
                      <p className="mb-0">
                        For <strong>every</strong> Empowerment Package purchased, we make a donation to Trees for the
                        Future, enabling them to <strong>plant another organic fruit or nut tree</strong> in a
                        low-income community.
                      </p>
                    </Col>
                    <Col xs="12" sm="3" lg="4">
                      <img
                        className="img-responsive img-logo-trees mx-auto"
                        src={`${USER_CONTENT_URL}assets/images/logo-trees-for-future-green.png`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <footer className="site-footer">
          <Container className="padding-top-1 padding-bottom-1 footer-padding-bottom-0">
            <Row>
              <Col xs="12" lg="6" className="text-white">
                © 2019 - Food Revolution Network
              </Col>
              <Col xs="12" lg="6">
                <nav className="nav-footer">
                  <ul className="inline-nav list-inline margin-0">
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/faq/">
                        FAQs
                      </a>
                    </li>
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/terms-and-conditions">
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li className="menu-item">
                      <a target="_blank" href="https://foodrevolution.org/privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}
export default UHC18;
const COLLECTIONS = [
  {
    title: 'Leading Edge Science',
    presentations: [
      {
        name: 'Marion Nestle, PhD',
        presentation: 'Taking On Big Soda (and Winning!)',
        img: 'upsells/uhc18/uhc-marion-n.jpg',
        description:
          'Dr. Marion Nestle is the Paulette Goddard Professor of Nutrition, Food Studies, and Public Health at New York University and the author of many books, including <em>Food Politics</em>, and her most recent release, <em>Soda Politics: Taking on Big Soda (And Winning)</em>. She has served as senior nutrition policy advisor in the Department of Health and Human Services, as a member of the Dietary Guidelines Advisory Committee, and as a member of the FDA Food Advisory Committee and Science Board. Marion will expose how the food industry exerts its influence over what we eat, and how we can save millions of lives by putting what we know into action.'
      },
      {
        name: 'Caldwell Esselstyn, MD',
        presentation: 'How To Be Heart-Attack Proof',
        img: 'upsells/uhc18/uhc-caldwell-e.jpg',
        description:
          'Dr. Caldwell Esselstyn is a surgeon and author of the bestselling book <em>Prevent and Reverse Heart Disease</em>. He has produced more than 150 scientific publications, and conducted a series of groundbreaking studies that have rocked the nutritional world. Caldwell has served as President of the American Association of Endocrine Surgeons, and as President of the Staff and as a member of the Board of Governors at the Cleveland Clinic. He explains, with irrefutable scientific evidence, how you can become bullet-proof to heart disease by changing what you eat.'
      },
      {
        name: 'T. Colin Campbell, PhD',
        presentation: 'The Science Of Food And Health',
        img: 'upsells/uhc18/uhc-colin-c.jpg',
        description:
          'Dr. T. Colin Campbell has been at the forefront of nutrition research for more than 40 years. He served as director of the China Project, which is widely considered the most comprehensive study of health and nutrition ever conducted, and is a Professor Emeritus of Nutritional Biochemistry at Cornell University. Colin has seventy grant- years of peer-reviewed research funding and has authored 300 research papers. He is co-author of the bestselling books, <em>The China Study</em> and <em>Whole: Rethinking the Science of Nutrition</em>, and he starred in the hit film &ldquo;Forks Over Knives&rdquo;. Find out what research is telling us about animal protein, carbs, supplements, and nutritional science.'
      },
      {
        name: 'Michael Klaper, MD',
        presentation: 'Let Food Be Thy Medicine',
        img: 'upsells/uhc18/uhc-michael-k.jpg',
        description:
          'Forty years ago, Dr. Michael Klaper was giving patients anesthesia for surgeries when he started wondering why they were getting sick in the first place. He discovered that much illness and premature death could be prevented with good nutrition. For 4 decades since, Michael has had the joy of helping patients thrive. He serves as the Director of the non-profit Institute of Nutrition Education and Research, as a member of the Nutrition Task Force of the American Medical Student Association, and on the medical staff for True North Health Center. The author of several books and a popular speaker, Michael&rsquo;s passion and insight will inspire, touch, and galvanize you on the path to strong nutrition and vibrant health..'
      }
    ]
  },
  {
    title: 'Food Wisdom',
    presentations: [
      {
        name: 'Michael Pollan',
        presentation: 'In Defense of Food',
        img: 'upsells/uhc18/uhc-michael-p.jpg',
        description:
          'Michael Pollan is one of the most influential leaders in the food movement today. He&rsquo;s the author of five <em>New York Times</em> bestsellers, including his 2006 classNameic, <em>The Omnivore&rsquo;s Dilemma</em>, which was named one of the ten best books of the year by both the <em>New York Times</em> and the <em>Washington Post</em>. In addition to publishing regularly in the <em>New York Times</em> magazine, his award-winning articles have appeared in <em>Harper&rsquo;s</em>, <em>National Geographic</em>, <em>Mother Jones</em>, <em>The Nation</em>, <em>Vogue</em>, and <em>Gourmet</em>. Get his latest penetrating insights on how you can make your food choices into an expression of your values.'
      },
      {
        name: 'Mark Bittman',
        presentation: 'The Power Of Your Plate',
        img: 'upsells/uhc18/uhc-mark-b.jpg',
        description:
          'Mark Bittman has been a popular <em>New York Times</em> columnist for more than 13 years. His blockbuster 1998 bestseller <em>How To Cook Everything</em> helped make cooking more accessible and delicious for millions of people. After turning his attention to the impact of our food choices -not just on our palates, but also on our health and world — Mark wrote the bestseller <em>Food Matters</em>, and became one of the leading advocates for a transformation in our food systems.'
      },
      {
        name: 'Marc David, MA',
        presentation: 'The Psychology Of Eating',
        img: 'upsells/uhc18/uhc-marc-d.jpg',
        description:
          'Marc David is the founder of the Institute for the Psychology of Eating, and the author of two bestselling books: <em>The Slow Down Diet: Eating for Pleasure, Energy, and Weight Loss</em> and <em>Nourishing Wisdom: A Mind-Body Approach to Nutrition and Well Being</em>. Marc&rsquo;s humorous, engaging, and informative style appeals to a wide audience of eaters who are looking for positive, inspiring, and innovative messages about food and metabolism. In his insightful and non-dogmatic style, Marc will show you how to employ the psychology of eating to support your physical, emotional and spiritual health.'
      },
      {
        name: 'Rory Freedman',
        presentation: 'Stop Eating Crap & Start Looking Fabulous',
        img: 'upsells/uhc18/uhc-rory-f.jpg',
        description:
          '<em>Beg: A Radical New Way of Regarding Animals</em>, the new book by Rory Freedman, coauthor of the #1 <em>New York Times</em> mega-seller <em>Skinny Bitch</em>, is a call-to-arms to all animal lovers (and definitely not your tyimgal animal book). Rory Freedman says so many of us call ourselves animal lovers and worship our dogs and cats—but we could be using that love as a force for helping all animals. Beg is a battle cry on their behalf, as well as an inspirational, empowering guide to what we can do to help them. With the same no-nonsense tone that made <em>Skinny Bitch</em> a multi-million copy success, Beg galvanizes us to change our choices and actions, and to love animals in a radical new way.'
      }
    ]
  },
  {
    title: 'Eat to Thrive',
    presentations: [
      {
        name: 'William Li, MD',
        presentation: 'How to Eat to Beat Cancer',
        img: 'upsells/uhc18/uhc-william-l.jpg',
        description:
          'Dr. William Li is an internationally recognized medical expert taking action to conquer disease through angiogenesis, the process the body uses to grow new blood vessels. As president and founder of the Angiogenesis Foundation, Dr. Li helps people prevent and control diseases from cancer to diabetes to vision loss through angiogenesis-based diet, lifestyle, and treatment. He founded the &ldquo;Eat to Beat&rdquo; initiative, a community of 45,000 people passionate about using diet to fight cancer. His wildly popular TED Talk &ldquo;Can We Eat to Starve Cancer&rdquo; has been viewed by more than 9 million people. You&rsquo;ll be informed and inspired by Dr. Li&rsquo;s breakthrough research and what it means for your health and your life.'
      },
      {
        name: 'Tony Robbins',
        presentation: 'Keys To An Extraordinary Life',
        img: 'upsells/uhc18/uhc-tonny-r.jpg',
        description:
          'Tony Robbins is a bestselling author, entrepreneur, and philanthropist. For more than 37 years, millions of people have enjoyed the warmth, humor, and the transformational power of Tony&rsquo;s business and personal development events. He is the nation&rsquo;s #1 life and business strategist. He&rsquo;s called upon to consult and coach with some of the world&rsquo;s finest athletes, entertainers, Fortune 500 CEOs, and even presidents of nations. Through the Anthony Robbins Foundation and his matching funds, Tony feeds 4 million people per year in 56 countries. He has also initiated programs in more than 1,500 schools, 700 prisons, and 50,000 service organizations and shelters. How will Tony help YOU apply healthy eating principles that stick?'
      },
      {
        name: 'Rip Esselstyn',
        presentation: 'Goodbye Sickness, Hello Health',
        img: 'upsells/uhc18/uhc-rip-e.jpg',
        description:
          'In 2003, when a co-worker in his Texas fire team was diagnosed with high cholesterol, Rip Esselstyn led the members of Engine 2 on a plant-strong diet and watched them get extraordinary results. Now Rip is teaching millions of people how to align what they put in their mouths with what they want for their weight and health. Rip is author of the <em>NY Times bestseller</em>, <em>The Engine 2 Diet: The Texas Firefighter&rsquo;s 28-Day Save-Your-Life Plan that Lowers Cholesterol and Burns Away the Pounds</em>. As a firefighter, Rip says: &ldquo;We&rsquo;re here to save lives, in whatever ways we can.&rdquo;'
      },
      {
        name: 'Dan Buettner',
        presentation: 'Blue Zones: Lessons for Living Longer',
        img: 'upsells/uhc18/uhc-dan-b.jpg',
        description:
          'Dan Buettner has discovered and researched the 5 places in the world — which he calls blue zones — where people live the longest and healthiest lives. He is a National Geographic fellow and a New York Times bestselling author of many books, including his most recent, The Blue Zones Solution. In 2009, working in partnership with AARP, Dan led the application of principles of The Blue Zones to the town of Albert Lea, Minnesota, and successfully raised the quality of life while lowering health care costs by 40%. His program is now being implemented by governments and insurance companies in many cities, and has improved the health of more than 5 million people. You&rsquo;ll learn how you can implement &ldquo;blue zone&rdquo; principles to transform the quality and the quantity of your life.'
      }
    ]
  },
  {
    title: 'Keys to Vibrant Health',
    presentations: [
      {
        name: 'Kim Williams, MD',
        presentation: 'Proven Steps to Prevent & Reverse Heart Disease',
        img: 'upsells/uhc18/uhc-kim-w.jpg',
        description:
          'Dr. Kim Williams is one of the most impeccably credentialed cardiologists in the world today. He is board-certified in internal medicine, cardiovascular diseases, nuclear medicine, nuclear cardiology, and cardiovascular computed tomography. Dr. Williams is a fellow of the American College of Cardiology, and served as its president from 2015 to 2016. He has dedicated much of his life to expanding healthcare access to low income and inner city communities, and to his leadership in bringing nutritional awareness into cardiology practice. If you or anyone you love wants to prevent or reverse heart disease, Dr. William&rsquo;s powerful and deeply informative message is for you.'
      },
      {
        name: 'David Wolfe',
        presentation: 'Radiant Health Now',
        img: 'upsells/uhc18/uhc-david-w.jpg',
        description:
          'Today is the best day ever. David &ldquo;Avocado&rdquo; Wolfe is the rock star and Indiana Jones of the superfoods and longevity universe. The world&rsquo;s top CEOs, ambassadors, celebrities, athletes, artists, and the real superheroes of this planet—Moms—all look to David for expert advice in health, beauty, herbalism, nutrition, and chocolate! David is the celebrity spokesperson for America&rsquo;s #1 selling kitchen appliance, the NUTRiBULLETTM and for www.LongevityWarehouse.com. He is the co-founder of TheBestDayEver.com online health magazine and is the visionary founder and president of the non-profit The Fruit Tree Planting Foundation charity (www.ftpf.org) with a mission to plant 18 billion fruit, nut, and medicinal trees on planet Earth.'
      },
      {
        name: 'JJ Virgin',
        presentation: 'Lose the Weight (finally!) and Feel Better Fast',
        img: 'upsells/uhc18/uhc-jj-v.jpg',
        description:
          'JJ Virgin is a celebrity nutritionist, fitness expert, and author of the <em>New York Times</em> bestseller, <em>The Virgin Diet: Drop 7 Foods, Lose 7 Pounds, Just 7 Days</em>. She&rsquo;s an expert in helping people overcome years of weight loss struggles by eliminating foods that are causing them to gain weight. Learn how to clear up your skin, have better energy and mental focus, eliminate joint pain and drop unwanted fat off your waist.'
      },
      {
        name: 'Jeffrey Smith',
        presentation: 'Why GMOs Matter to YOU',
        img: 'upsells/uhc18/uhc-jeffrey-s.jpg',
        description:
          'Jeffrey Smith&rsquo;s meticulous research has made him a world authority on the dangers of genetically modified organisms (GMOs). He documents how biotech companies continue to mislead legislators and safety officials to put our health at risk, and the environment in peril. Jeffrey is founder of the Institute for Responsible Technology, author of the bestselling book, <em>Seeds of Deception</em>, and producer of the award-winning film, &ldquo;Genetic Roulette – The Gamble of Our Lives&rdquo;. Behind all the politics and special interest dollars, what&rsquo;s the real scoop on GMOs? Find out what&rsquo;s at stake, and how to protect yourself and your family.'
      }
    ]
  },
  {
    title: 'Healthy & Whole',
    presentations: [
      {
        name: 'Marianne Williamson',
        presentation: 'Food, Body and Divine Perfection',
        img: 'upsells/uhc18/uhc-marianne-w.jpg',
        description:
          'Marianne Williamson is an internationally acclaimed spiritual author and lecturer. Marianne has been a popular guest on television programs such as <em>Oprah</em>, <em>Larry King Live</em>, <em>Good Morning America</em>, <em>Charlie Rose</em> & <em>Bill Maher</em>. Six of her eleven published books have been <em>New York Times</em> Best Sellers. Four of these have been #1. The mega best seller <em>A Return to Love</em> is considered a must-read of The New Spirituality. A paragraph from that book, beginning &ldquo;Our deepest fear is not that we are inadequate. Our deepest fear is that we are powerful beyond measure…&rdquo; is considered an anthem for a contemporary generation of seekers.'
      },
      {
        name: 'Geneen Roth',
        presentation: 'Feeding the Heart and Soul',
        img: 'upsells/uhc18/uhc-geneen-r.jpg',
        description:
          'Geneen Roth&rsquo;s pioneering books were among the first to link compulsive eating and perpetual dieting with deeply personal and spiritual issues that go far beyond food, weight and body image. She believes that we eat the way we live, and that our relationship to food, money, and love is an exact reflection of our deepest held beliefs about ourselves and the amount of joy, abundance, pain, and scarcity we believe we have (or are allowed) to have in our lives.'
      },
      {
        name: 'Rachel Abrams, MD',
        presentation: 'How to Listen to Your Body&rsquo;s Intelligence',
        img: 'upsells/uhc18/uhc-rachel-a.jpg',
        description:
          'Dr. Rachel Abrams is a family practice physician who is board-certified in family medicine and integrative medicine. She runs an award-winning integrative medicine clinic in Santa Cruz, CA, where she helps her patients to explore the important connections between health and vitality, relationships, lifestyle choices, work, and environment. She is the author of several books, including <em>BodyWise: Discovering Your Body&rsquo;s Intelligence for Lifelong Health and Healing</em>. You&rsquo;ll get Dr. Abram&rsquo;s top insights on how to heal through listening to your own body intelligence.'
      },
      {
        name: 'John Robbins',
        presentation: 'Special Hour-Long Presentation',
        img: 'upsells/uhc18/uhc-john-r.jpg',
        description:
          'John Robbins is the author of nine bestsellers that have collectively sold more than 3 million copies and been translated into 31 languages. These include <em>Diet for a New America</em>, <em>The Food Revolution</em>, <em>Healthy at 100</em>, and (with Ocean Robbins) <em>Voices of the Food Revolution</em>. John is Founder and Board Chair Emeritus of Earth Save International, and co-founder and President of the 250,000+ member Food Revolution Network. He is a recipient of the Rachel Carson Award, the Albert Schweitzer Humanitarian Award, the Peace Abbey&rsquo;s Courage of Conscience Award, Green America&rsquo;s Lifetime Achievement Award, and many other accolades. He lives with his wife of 49 years, Deo, in California.'
      }
    ]
  }
];
