/* eslint-disable no-console */
/* eslint-disable no-use-before-define */

import Api, {putToS3} from '../../lib/api';
import jwt_decode from 'jwt-decode';
import url from 'url';
import PromiseResolve from 'es-abstract/2018/PromiseResolve';
import queryString from 'query-string';
import {identify} from '../../lib/track';

// ------------------------------------
// Constants
// ------------------------------------
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
// const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

const LOGOUT_USER = 'LOGOUT_USER';

// const REQUEST_MAGIC_LINK = 'REQUEST_MAGIC_LINK';

const USER_ADDRESS_REQUEST = 'USER_ADDRESS_REQUEST';
const USER_ADDRESS_SUCCESS = 'USER_ADDRESS_SUCCESS';

const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST';
const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';

const RECEIVE_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';

const SET_DEFAULT_ADDRESS_REQUEST = 'SET_DEFAULT_ADDRESS_REQUEST';
const SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS = 'SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS';
const SET_DEFAULT_BILLING_ADDRESS_SUCCESS = 'SET_DEFAULT_BILLING_ADDRESS_SUCCESS';

const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';

const ADD_ADDRESS_REQEUST = 'ADD_ADDRESS_REQEUST';
const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';

const ORDER_HISTORY_REQUEST = 'ORDER_HISTORY_REQUEST';
const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';

const ORDER_REQUEST = 'ORDER_REQUEST';
const ORDER_SUCCESS = 'ORDER_SUCCESS';

const RECEIVE_USER_PRODUCTS = 'RECEIVE_USER_PRODUCTS';
const RECEIVE_USER_EVENT_LOGS = 'RECEIVE_USER_EVENT_LOGS';

const SET_COMMUNICATION_PREFERENCES = 'SET_COMMUNICATION_PREFERENCES';

// ------------------------------------
// Actions
// ------------------------------------
const requestLogin = creds => ({
  type: LOGIN_REQUEST,
  isAuthenticated: false,
  creds
});

const receiveLogin = user => {
  user.isAuthenticated = true;

  //
  localStorage.setItem('user', JSON.stringify(user));
  identify(user.UUID, {
    id: user.UUID,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    platformUserId: user.id
  });

  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const requestCreateUser = user => ({
  type: CREATE_USER_REQUEST,
  user
});

// const receiveCreateUser = user => ({
//   type: CREATE_USER_SUCCESS,
//   user
// });

const requestVerifyEmail = email => ({
  type: VERIFY_EMAIL_REQUEST,
  email
});

const requestPasswordReset = email => ({
  type: RESET_PASSWORD_REQUEST,
  email
});

const logout = () => ({
  type: LOGOUT_USER
});

// const requestMagicLink = email => ({
//   type: REQUEST_MAGIC_LINK,
//   email
// });

const receiveAddresses = addresses => ({
  type: USER_ADDRESS_SUCCESS,
  addresses
});

const receiveUpdateProfile = profile => {
  // localStorage.setItem('user', JSON.stringify(profile)); //NO!!!
  return {
    type: UPDATE_PROFILE_SUCCESS,
    profile
  };
};

const receiveLoadProfile = profile => {
  // localStorage.setItem('user', JSON.stringify(profile)); //Maybe
  return {
    type: LOAD_PROFILE_SUCCESS,
    profile
  };
};

const setDefaultShippingAddress = address => ({
  type: SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
  address
});

const setDefaultBillingAddress = address => ({
  type: SET_DEFAULT_BILLING_ADDRESS_SUCCESS,
  address
});

const receiveUpdateAddress = address => ({
  type: UPDATE_ADDRESS_SUCCESS,
  address
});

const receiveAddAddress = address => ({
  type: ADD_ADDRESS_SUCCESS,
  address
});

const requestOrderHistory = () => ({
  type: ORDER_HISTORY_REQUEST
});

const receiveOrderHistory = orders => ({
  type: ORDER_HISTORY_SUCCESS,
  orders
});

const requestOrder = orderId => ({
  type: ORDER_REQUEST,
  orderId
});

const receiveOrder = order => ({
  type: ORDER_SUCCESS,
  order
});

const receivePaymentMethods = paymentMethods => ({
  type: RECEIVE_PAYMENT_METHODS,
  UserPaymentMethods: paymentMethods
});

const receiveSubscriptions = subscriptions => ({
  type: RECEIVE_SUBSCRIPTIONS,
  subscriptions
});

const receiveUserProducts = products => ({
  type: RECEIVE_USER_PRODUCTS,
  products
});

const receiveUserEventLogs = userEventLogs => {
  return {
    type: RECEIVE_USER_EVENT_LOGS,
    userEventLogs
  };
};

const setCommunicationPreferences = (prefCategory, payload) => {
  return {
    type: SET_COMMUNICATION_PREFERENCES,
    communicationPreferences: {[prefCategory]: payload}
  };
};

// ------------------------------------
// Helpers
// ------------------------------------

const logoutUser = navigateHandledLocally => dispatch => {
  localStorage.removeItem('user');
  // Let withRouter-connected components navigate on logout (usually to '/')

  // This is 'deprecated, should be eliminated'
  // if (!navigateHandledLocally) {
  //   location.replace('/');
  // }
  dispatch(logout());
};

const createCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

const loginUser = (creds, fullProfile = true) => dispatch => {
  dispatch(requestLogin(creds));
  return Api.post('/users/login', creds)
    .then(resp => {
      if (resp.authToken !== undefined) {
        dispatch(receiveLogin(resp));
        // Set cookies
        createCookie('_frn_authToken', resp.authToken, 365);

        if (fullProfile) {
          dispatch(loadAddresses());
          dispatch(loadProfile(resp.authToken));
        }

        return resp.fullAuth;
      }
      return false;
    })
    .catch(err => {
      console.log(err);
    });
};

const loginUserDiscourse = (userInfo, queryParams, parentComponent, fallbackURL) => dispatch => {
  return Api.post('/users/discourseLogOn', {userData: userInfo, discourseNonceData: queryParams})
    .then(resp => {
      if (resp && (!resp.status || resp.status === 200)) {
        return resp;
      }
      return false;
    })
    .then(discourseParams => {
      if (discourseParams) {
        let dParams = queryString.stringify(discourseParams);
        let discourseURL = `${process.env.FORUM_BASE_URL}/session/sso_login?${dParams}`;

        window.location.href = discourseURL;
      } else {
        // not authorized, just bail
        parentComponent.props.history.push(
          queryParams.redirect ? decodeURIComponent(queryParams.redirect) : fallbackURL
        );
      }
    })
    .catch(err => {
      console.log('Error logging into Discourse.');
    });
};

// This is used for muut/forum
const refreshToken = () => (dispatch, getState) => {
  const {authToken} = getState().user;
  const {user} = getState();
  // dispatch(requestLogin(creds));

  return Api.post('/users/refreshToken', user, authToken)
    .then(resp => {
      if (resp.authToken !== undefined) {
        dispatch(receiveLogin(resp));
        return resp.fullAuth;
      }
      //if this fails, we logout the user
      localStorage.removeItem('user');
      dispatch(logout());
      return false;
    })
    .catch(err => {
      console.log(err);
    });
};

const revokeDiscourseAPIKey = () => async (dispatch, getState) => {
  const {authToken} = getState().user;
  const {user} = getState();
  const body = {discourseAPIKey: user.discourseKey, userID: user.userId};
  const response = await Api.post('/users/current/revokeDiscourseAPIKey', body, authToken)
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log(err);
      return;
    });

  return response;
};

const loadAddresses = () => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  dispatch({type: USER_ADDRESS_REQUEST});
  return Api.get(`/users/${userId}/addresses`, authToken)
    .then(resp => {
      if (Array.isArray(resp)) {
        dispatch(receiveAddresses(resp));
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const createUser = user => dispatch => {
  dispatch(requestCreateUser(user));

  return Api.post('/users', user)
    .then(resp => {
      let userCreated = false;
      if (resp.id !== undefined) {
        const respUser = {
          email: resp.email,
          firstName: resp.firstName,
          lastName: resp.lastName,
          authToken: resp.authToken,
          fullAuth: true,
          userId: resp.id
        };
        dispatch(receiveLogin(respUser));
        userCreated = true;
      }

      return userCreated;
    })
    .catch(err => {
      console.log(err);
    });
};

const verifyEmail = email => dispatch => {
  dispatch(requestVerifyEmail(email));

  return Api.post('/users/verify', {email})
    .then(resp => {
      let verifiedEmail = false;
      if (resp.found !== undefined) {
        dispatch({type: VERIFY_EMAIL_SUCCESS});
        verifiedEmail = true;
      }

      return verifiedEmail;
    })
    .catch(err => {
      console.log(err);
    });
};

const resetPassword = email => dispatch => {
  dispatch(requestPasswordReset(email));

  // This would be an API call and then determine success based on response
  return new Promise(resolve => {
    setTimeout(() => {
      dispatch({type: RESET_PASSWORD_SUCCESS});

      resolve();
    }, 300);
  });
};

const sendMagicLink = email => dispatch =>
  Api.post('/users/sendMagicLink', {email})
    .then(resp => {
      if (resp.success || resp.ok) return true;
      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });

const updateProfile = (profile, targetUser) => (dispatch, getState) => {
  const {user} = getState();
  const {authToken} = user;
  dispatch({type: UPDATE_PROFILE_REQUEST});
  return Api.patch(`/users/${targetUser ? targetUser.id : 'current'}`, profile, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        // we got a json object

        const updatedUser = {...user, ...resp};
        if (!targetUser) dispatch(receiveUpdateProfile(updatedUser));
        //Don't save payment methods in localStorage
        if (!targetUser) updatedUser.UserPaymentMethods = null;
        return true;
      }
      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

// This is MY profile
const loadProfile = () => (dispatch, getState) => {
  const {authToken} = getState().user;
  dispatch({type: LOAD_PROFILE_REQUEST});
  return Api.get('/users/current/profile', authToken)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receiveLoadProfile(resp));
        return true;
      }

      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

// Others' / public profile
const loadPublicProfile = id => (dispatch, getState) => {
  const {authToken, userId} = getState().user;
  return Api.get(`/users/${id}/profile`, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        return resp;
      }
      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

const setDefaultAddress = (address, type) => (dispatch, getState) => {
  const token = getState().user.authToken;
  dispatch({type: SET_DEFAULT_ADDRESS_REQUEST});
  return Api.put(`/users/current/addresses/${address.id}/makeDefault/${type}`, null, token)
    .then(resp => {
      if (resp.status === 204) {
        type === 'shipping'
          ? dispatch(setDefaultShippingAddress(address))
          : dispatch(setDefaultBillingAddress(address));
      } else {
        return null;
      }
    })
    .catch(error => {
      //console.log(error);
      return null;
    });
};

const updateAddress = address => (dispatch, getState) => {
  const token = getState().user.authToken;
  dispatch({type: UPDATE_ADDRESS_REQUEST});
  return Api.patch(`/users/current/addresses/${address.id}`, address, token)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receiveUpdateAddress(resp));
        return true;
      }

      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

const deleteAddress = address => (dispatch, getState) => {
  const {user} = getState();
  const {authToken, isAuthenticated} = user;

  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  dispatch({type: UPDATE_ADDRESS_REQUEST});
  return Api.delete(`/users/${userId}/addresses/${address.id}`, address, authToken)
    .then(resp => {
      if (resp.ok) {
        return true;
      }

      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

const addAddress = address => (dispatch, getState) => {
  const {user} = getState();
  const {authToken, isAuthenticated} = user;
  dispatch({type: ADD_ADDRESS_REQEUST});

  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  return Api.post(`/users/${userId}/addresses`, address, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receiveAddAddress(resp));
        return true;
      }
      return true;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
};

const loadOrderHistory = () => (dispatch, getState) => {
  const token = getState().user.authToken;
  dispatch(requestOrderHistory());

  return Api.get('/users/current/orders', token)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receiveOrderHistory(resp));
        const subscriptions = resp.filter(
          order => !!order.subscription && !!order.Subscription && !order.Subscription.canceled
        );
        dispatch(receiveSubscriptions(subscriptions));
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const loadOrder = orderId => (dispatch, getState) => {
  const token = getState().user.authToken;
  dispatch(requestOrder(orderId));

  return Api.get(`/users/current/orders/${orderId}`, token)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receiveOrder(resp));
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const getUserData = contentId => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/content/${contentId}`;

  return Api.get(url, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        const values = resp && resp[0] ? resp[0].data : null;
        return values;
      }
      // Danger Will Robinson
      console.log('error: ', resp);
      return null;
    })
    .catch(error => {
      // Danger Will Robinson
      console.log(error);
      return null;
    });
};

//Updates state for a specific content item for a user
//i.e. current state of a checklist
const postUserData = (contentId, data) => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;
  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }
  const url = `/users/${userId}/content/${contentId}`;

  return Api.post(url, data, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        // console.log('resp post user data from store (status===undefined): ', resp, 'url ', url);
        return resp;
      }
      // console.log('resp post user data from store, status: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log('err with url ', url, 'data: ', data, 'authtoken ', authToken);
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const patchUserData = (contentId, data) => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;
  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }
  const url = `/users/${userId}/content/${contentId}`;

  return Api.patch(url, data, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        return resp;
      }
      return null;
    })
    .catch(error => {
      console.log('err with url ', url, 'data: ', data, 'authtoken ', authToken);
      console.log(error);
      return null;
    });
};

const updateUserProfilePic = imageData => async (dispatch, getState) => {
  const {authToken, userId} = getState().user;
  const signedUrlLoc = '/s3/getSignedUrl';
  const signedUrlParams = {type: 'avatars', contentType: 'image/png', id: userId};
  let signedUrl;

  const signedUrlData = await Api.post(signedUrlLoc, signedUrlParams, authToken);

  if (!signedUrlData || !signedUrlData.signedUrl) {
    return null;
  }

  signedUrl = signedUrlData.signedUrl;
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'image/png'
    },
    body: imageData
  };

  const AWSSucess = await putToS3(signedUrl, options);

  if (AWSSucess) {
    return signedUrlData.avatarUrl;
  }
};

const getPaymentMethods = () => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/paymentMethods`;
  return Api.get(url, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(receivePaymentMethods(resp));
        return true;
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const getSubscriptions = () => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/orders`;
  return Api.get(url, authToken)
    .then(resp => {
      let subscriptions;
      if (resp.status === undefined) {
        //  if (resp.rows) {
        //  subscriptions = resp.rows.filter(
        //    (order) => !!order.subscription && !!order.Subscription && !order.Subscription.canceled
        //  );
        // } else {
        subscriptions = resp.filter(
          order => !!order.subscription && !!order.Subscription && !order.Subscription.canceled
        );
        // }
        console.log('subs ', subscriptions);
        dispatch(receiveSubscriptions(subscriptions));
        return true;
      }
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const cancelSubscription = id => (dispatch, getState) => {
  // console.log('CANCELING SUBSCRIPTION: ', id);
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/orders/${id}/subscriptionCancel`;
  return Api.post(url, null, authToken)
    .then(resp => {
      if (resp.cancelDate) {
        return true;
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const upgradeSubscription = (id, price) => (dispatch, getState) => {
  let OFFERID, OFFERPAYMENTOPTIONID;
  if (price === 197) {
    (OFFERID = parseInt(process.env.UPGRADE_197_OFFER_ID)), //433 in staging
      (OFFERPAYMENTOPTIONID = parseInt(process.env.UPGRADE_197_OFFER_PAYMENT_OPTION_ID)); //569 in staging
  } else if (price === 99) {
    (OFFERID = parseInt(process.env.UPGRADE_99_OFFER_ID)), //63 in staging
      (OFFERPAYMENTOPTIONID = parseInt(process.env.UPGRADE_99_OFFER_PAYMENT_OPTION_ID)); //83 in staging
  } else {
    (OFFERID = parseInt(process.env.UPGRADE_127_OFFER_ID)), //147 in staging
      (OFFERPAYMENTOPTIONID = parseInt(process.env.UPGRADE_127_OFFER_PAYMENT_OPTION_ID)); //191 in staging
  }

  const {authToken, isAuthenticated} = getState().user;
  let userId, email;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
    email = jwt_decode(authToken).email;
  }

  const url = `/orders/${id}/subscriptionUpgrade`;

  return Api.post(url, {User: {id: userId}, OfferId: OFFERID, OfferPaymentOptionId: OFFERPAYMENTOPTIONID}, authToken)
    .then(resp => {
      if (resp.Subscription) {
        return true;
      }

      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const setPreferredPaymentMethodForASubscription = (orderId, paymentMethodId) => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/setPaymentMethod/${paymentMethodId}/${orderId}`;
  return Api.post(url, null, authToken)
    .then(resp => {
      if (resp.Order && resp.Order.id) {
        const orders = [...getState().user.orders];
        const idx = orders.findIndex(order => order.id === resp.Order.id);
        if (idx >= 0) {
          orders[idx] = resp.Order;
        } else {
          orders.push(resp.Order);
        }
        return dispatch(receiveOrderHistory(orders));
      } else if (resp.message) {
        return {message: resp.message};
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const updatePaymentMethod = (nonce, paymentMethodId, orderId) => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/paymentMethods/${paymentMethodId}`;
  let params = {
    expirationMonth: nonce.details.expirationMonth,
    expirationYear: nonce.details.expirationYear,
    nonce: nonce.nonce
  };
  if (orderId) params.orderId = orderId;
  return Api.patch(url, params, authToken)
    .then(resp => {
      if (resp.UpdateStatus) {
        if (resp.UpdateStatus === 'success') {
          return true;
        } else if (resp.UpdateStatus === 'unchanged') {
          return 'Provided expiration month/year unchanged';
        } else if (resp.UpdateStatus === 'rejected') {
          return 'Your card details could not be verified with your bank. (Perhaps the CVV and/or card number have changed as well?) Please create a new payment method.';
        } else if (resp.UpdateStatus === 'error') {
          console.log('error: ', resp);
          return resp.message ? {message: resp.message} : null;
        }
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

// Used in profile
// orderId is optional - if given this method calls
// setPreferredPaymentMethodForASubscription
// otherwise returns true

const createPaymentMethod = (nonce, orderId) => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const body = {};
  body.PaymentMethod = {gateway: 'braintree', gatewayToken: {id: nonce.nonce}};

  const url = `/users/${userId}/createPaymentMethod`;
  return Api.post(url, body, authToken)
    .then(resp => {
      if (resp.createdAt) {
        let created = resp.created;
        const paymentMethodId = resp.id;
        if (!orderId) {
          return true;
        }
        return created
          ? dispatch(setPreferredPaymentMethodForASubscription(orderId, paymentMethodId))
          : {message: 'Payment method already exists for your account.'};
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

const deletePaymentMethod = id => (dispatch, getState) => {
  const {authToken, isAuthenticated} = getState().user;
  let userId;

  if (isAuthenticated && authToken) {
    userId = jwt_decode(authToken).userId;
  }

  const url = `/users/${userId}/paymentMethods/${id}`;
  return Api.delete(url, null, authToken)
    .then(resp => {
      if (resp.ok) {
        // dispatch(getPaymentMethods());
        return {success: true};
      } else if (resp.message) {
        return {success: false, message: resp.message};
      }
      console.log('error: ', resp);
      // Danger Will Robinson
      return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson
      return null;
    });
};

// This is probably the best way to simply handle state updates on user data loads
// Consider refactor in this direction for other components?
const getUserProducts = componentStateCallback => (dispatch, getState) => {
  const {authToken, userId} = getState().user;
  const url = `/users/${userId}/products`;

  return Api.get(url, authToken)
    .then(resp => {
      if (Array.isArray(resp)) {
        // console.log('RESP on getUserProducts: ', resp);
        dispatch(receiveUserProducts(resp));
        if (componentStateCallback) {
          componentStateCallback(resp);
        }
      }

      // Danger Will Robinson
      // return null;
    })
    .catch(error => {
      console.log(error);
      // Danger Will Robinson

      /* This will fail if componentStateCallback is not defined,
       * but adding in this if statement (the "correct" thing to do
       * breaks the external upsell flow. So... */
      //if (componentStateCallback) {
      componentStateCallback();
      //} else {
      //  return [];
      //}
    });
};

const getUserEventLogs = (page, passedUserId) => (dispatch, getState) => {
  const state = getState();
  var {authToken, userId, userEventLogs} = state.user;
  const decodedToken = jwt_decode(authToken);
  const {router} = state;
  if (!userEventLogs) userEventLogs = {query: {page: page}, data: {}};
  var {query, data} = userEventLogs;

  if (passedUserId) {
    // use passed value if explicitly set
    userId = passedUserId;
  } else {
    /* otherwise, grab userId from the url if it is there, we use that
     * else use userId in token i.e. logged in user */
    userId =
      router.location &&
      router.location.pathname &&
      router.location.pathname.split('/').length > 1 &&
      router.location.pathname.split('/')[2]
        ? router.location.pathname.split('/')[2]
        : userId;
  }

  // Only cs/admin have access
  if (userId != decodedToken.id && 'user' == decodedToken.role) {
    userId = decodedToken.id;
    dispatch(receiveUserEventLogs({query: {page: 0}, data: {}}));
    return;
  }

  query.page = page;
  const queryString = '?page=' + page;

  const url = `/users/${userId}/eventLogs${queryString}`;

  return Api.get(url, authToken)
    .then(resp => {
      if (resp) {
        const results = {query, data: resp};
        // RECEIVE_USER_EVENT_LOGS
        dispatch(receiveUserEventLogs(results));
      } else {
        dispatch(receiveUserEventLogs({query: {page: 0}, data: {}}));
      }

      // Danger Will Robinson
      // return null;
    })
    .catch(error => {
      console.log(error);
    });
};

const dispatchCommunicationPreferences = (type, payload) => (dispatch, getState) => {
  dispatch(setCommunicationPreferences(type, payload));
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actions = {
  addAddress,
  cancelSubscription,
  createPaymentMethod,
  createUser,
  deleteAddress,
  deletePaymentMethod,
  dispatchCommunicationPreferences,
  getPaymentMethods,
  getSubscriptions,
  getUserData,
  getUserProducts,
  getUserEventLogs,
  loadPublicProfile,
  loginUser,
  loginUserDiscourse,
  logoutUser,
  loadAddresses,
  loadOrder,
  loadOrderHistory,
  loadProfile,
  patchUserData,
  postUserData,
  receiveUpdateProfile,
  receiveLogin,
  resetPassword,
  refreshToken,
  revokeDiscourseAPIKey,
  sendMagicLink,
  setDefaultAddress,
  setPreferredPaymentMethodForASubscription,
  updatePaymentMethod,
  updateUserProfilePic,
  updateAddress,
  updateProfile,
  upgradeSubscription,
  verifyEmail
};

// ------------------------------------
// Reducer
// ------------------------------------

let initialState = {
  isAuthenticated: false,
  id: null,
  email: '',
  authToken: '',
  firstName: '',
  lastName: '',
  UUID: '',
  fullAuth: false,
  DefaultShippingAddress: {id: 0},
  DefaultBillingAddress: {id: 0},
  UserPaymentMethods: [],
  addresses: [],
  orders: [],
  products: [],
  subscriptions: [],
  communicationPreferences: {},
  selectedOrder: null
};

export const initialStateUser = initialState;

try {
  const preParseStoredUser = !!localStorage && localStorage.getItem('user');
  if (preParseStoredUser) {
    const storedUser = JSON.parse(preParseStoredUser);
    initialState = {
      ...initialState,
      ...storedUser
    };
  }
} catch (err) {
  console.log('localStorage lookup user error: ', err);
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_SUCCESS:
      return {
        ...state,
        selectedOrder: action.order
      };
    case ORDER_REQUEST:
      return {
        ...state,
        selectedOrder: {
          id: action.orderId
        }
      };
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orders: action.orders
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: [...state.addresses, action.address]
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.map(address => (address.id === action.address.id ? action.address : address))
      };
    case SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        DefaultShippingAddress: action.address
      };
    case SET_DEFAULT_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        DefaultBillingAddress: action.address
      };
    case LOAD_PROFILE_SUCCESS:
      const loadProfileState = {...state, ...action.profile};
      localStorage.setItem('user', JSON.stringify(loadProfileState));
      return loadProfileState;
    case RECEIVE_PAYMENT_METHODS:
      return {
        ...state,
        UserPaymentMethods: action.UserPaymentMethods
      };
    case RECEIVE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.subscriptions
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        email: action.profile.email,
        firstName: action.profile.firstName,
        lastName: action.profile.lastName,
        public: action.profile.public || {}
      };
    case USER_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.addresses
      };

    case RECEIVE_USER_PRODUCTS:
      return {
        ...state,
        products: action.products
      };

    case RECEIVE_USER_EVENT_LOGS:
      return {
        ...state,
        userEventLogs: action.userEventLogs
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        email: action.creds.email
      };

    case LOGIN_SUCCESS:
      const newState = {...state, ...action.user};
      return newState;

    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        email: action.email
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        email: action.email
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetEmailSent: true
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        email: '',
        authToken: '',
        firstName: '',
        lastName: '',
        UUID: '',
        id: null,
        communicationPreferences: {},
        fullAuth: false,
        form: {}
      };
    case SET_COMMUNICATION_PREFERENCES:
      return {
        ...state,
        communicationPreferences: {...state.communicationPreferences, ...action.communicationPreferences}
      };
    default:
      return state;
  }
}

/* eslint-enable no-console */
/* eslint-enable no-use-before-define */
