import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusLarge} from '@fortawesome/pro-solid-svg-icons/faPlusLarge';
import './styles.css'; // Import the CSS file for styling
import Quote from './components/Quote';

const InternalAd = ({
  img,
  openModal = () => {
    console.log('modal clicked');
  }
}) => {
  const openLinkInNewTab = () => {
    const url =
      process.env.ROOT_URL && process.env.ROOT_URL.includes('staging')
        ? 'https://realsuperfoods.frnstaging.org/whole/'
        : 'https://realsuperfoods.foodrevolution.org/whole/';
    window.open(url, '_blank');
  };
  return (
    <div>
      <Row
        className="rounded mx-0 p-5 pt-1 text-white internal-ad-container d-none d-lg-flex"
        style={{
          position: 'relative',
          height: 450,
          maxHeight: 450
        }}
      >
        {/* THIS IS FOR OVERLAPPING TEXT.  KEEPING JUST IN CASE WE NEED IT */}
        {/* <div style={{position: 'absolute', top: '0', left: '0', zIndex: '1', paddingTop: '50px', paddingLeft: 40}}>
          <h1 className="mt-0 mb-0 text-dark">Join WHOLE Life Club for just $1</h1>
          <h2 className="mt-0 text-dark font-italic font-weight-lighter">
            Motivation & Inspiration for Your Healthiest Life
          </h2>
        </div> */}

        <Col xs={5} className="content-container d-flex justify-content-center align-items-center">
          <h1 className="my-0  text-dark text-center">Join WHOLE Life Club for just $1</h1>
          <h3 className="mt-0 text-dark font-italic font-weight-lighter text-center">
            Motivation & Inspiration for Your Healthiest Life
          </h3>
          <Quote
            quote="“Joining WHOLE Life Club was one of the best investments I have ever made.”"
            author="— Jean, Lincoln Park, NJ
"
          />

          <p className="text-center mt-4 font-weight-bold">Start your 14-day trial for just $1</p>
          <Button color="secondary" size="lg" onClick={openLinkInNewTab} style={{width: 'fit-content'}}>
            Upgrade Now!
          </Button>
          <p className="text-center mt-3 font-italic" style={{fontSize: '1rem', color: 'grey'}}>
            Offer ends November 12th, 2023
          </p>
        </Col>
        <Col xs={7} className="image-container"></Col>
      </Row>

      {/* mobile mobile mobile mobile mobile mobile mobile mobile mobile  */}

      <Row className="rounded mx-0  text-white d-lg-none">
        {/* Mobile Image Container */}
        <Col xs={12} className=" mb-3 text-center">
          <div className="mobile-image-container">
            <div className="gradient-overlay" />
            <img src={img} alt="Image" className="image" />
          </div>
        </Col>

        {/* Content Container */}
        <Col
          xs={12}
          className="content-container d-flex justify-content-center align-items-center mt-0"
          style={{whiteSpace: 'normal'}}
        >
          <h1 className="my-0  text-dark text-center">Join WHOLE Life Club for just $1</h1>
          <h3 className="mt-0 text-dark font-italic font-weight-lighter text-center">
            Motivation & Inspiration for Your Healthiest Life
          </h3>

          <div>
            <Quote
              mobile
              quote="“Joining WHOLE Life Club was one of the best investments I have ever made.”"
              author="— Jean, Lincoln Park, NJ"
            />
          </div>

          <p className="text-center mt-3 font-weight-bold">Start your 14-day trial for just $1</p>

          <Button style={{width: 'fit-content'}} color="secondary" size="lg" onClick={openLinkInNewTab}>
            Upgrade Now!
          </Button>

          <p className="text-center mt-3 font-italic" style={{fontSize: '1rem', color: 'grey'}}>
            Offer ends June 5th, 2023
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default InternalAd;
