/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import isEqual from 'lodash/isEqual';
import Data from 'containers/Data';
import {Switch, Route, Redirect} from 'react-router-dom';
import WLCNav from './components/WLCNav';
import ActionHours from './pages/ActionHours';
import Article from './pages/Article';
import Bonuses from './pages/Bonuses';
import Collection from './pages/Collection';
import CollectionsList from './pages/CollectionsList';
import CurrentWeek from './pages/CurrentWeek';
import Forum from './pages/Forum';
import CommunityGuidelines from './pages/CommunityGuidelines';
import Dashboard from './pages/Dashboard';
import GettingStarted from './pages/GettingStarted';
import RecipesList from 'routes/Products/components/RecipesList';
import SpecialReports from './pages/SpecialReports';
import Tools from './pages/Tools';
import VideoResource from '../../components/VideoResource';
import WeeksPage from './pages/WeeksPage';
import RecipesPage from './pages/RecipesPage';
import Video from '../../components/Video';
import {actions} from 'modules/user';
import ProductSidebar from 'components/ProductSidebar';
import MedicalDisclaimer from 'components/MedicalDisclaimer';
import GeneralContentContainer from 'containers/GeneralContentContainer';
import AbsoluteRedirect from 'components/AbsoluteRedirect';
import {faUtensils} from '@fortawesome/pro-regular-svg-icons/faUtensils';
import {faUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import {faBook} from '@fortawesome/pro-regular-svg-icons/faBook';
import {faMap} from '@fortawesome/pro-regular-svg-icons/faMap';
import {faAddressCard} from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons/faCalendar';
import GeneralContentContainerApiComponents from '../../../../containers/GeneralContentContainerApiComponents';
import SearchPage from './pages/SearchPage';
import Calendar from './pages/Calendar';
import ListPage from '../PBCC/pages/ListPage';
import FavoritesPage from './pages/FavoritesPage';

const WEEKS_LIST_SLUG = process.env.WEEKS_LIST_SLUG; //'wlc-18-weeks-list'; //the weeks list slug, needs to be updated depending on env
const ONBOARD_CONTENT_ID = process.env.ONBOARD_CONTENT_ID; //5; //onbaord content for this product
const ACTION_HOURS_ID = process.env.ACTION_HOURS_ID; //358; //all actions for this product

@connect(() => null, {
  refreshToken: actions.refreshToken,
  revokeDiscourseApiKey: actions.revokeDiscourseAPIKey,
  get: actions.getUserData,
  post: actions.postUserData
})
class WLC extends Component {
  constructor(props) {
    super(props);

    this.id = 1;
    this.slugsCache = {};
    this.dynamicComponents = {
      article: Article,
      bonuses: Bonuses,
      collection: Collection,
      collectionsList: CollectionsList,
      recipesList: RecipesList,
      specialReports: SpecialReports,
      tools: Tools,
      videoResource: VideoResource,
      video: Video
    };
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];

    this.state = {
      thisWeek: {id: null, Children: []},
      sortedWeeksList: [],

      loadingState: true,
      canShowOnboard: false,
      toursCompleted: {},
      navItems: [
        {name: 'Orientation', typeOfComponent: 'Dashboard', slug: '', icon: faAddressCard},
        {name: 'Weekly Guide', typeOfComponent: null, slug: 'current-week', icon: faMap},
        {name: 'Recipes', typeOfComponent: 'RecipesGrid', slug: 'recipes', icon: faUtensils},
        {name: 'Forum', typeOfComponent: null, slug: 'community', icon: faUsers},
        {name: 'Action Hours', typeOfComponent: null, slug: 'action-hours', icon: faVideo},
        {name: 'Resources', typeOfComponent: null, slug: 'resources', icon: faBook},
        {name: 'Favorites Library', typeOfComponent: null, slug: 'favorites-library', icon: faBook},
        {name: 'Calendar', typeOfComponent: null, slug: 'calendar', icon: faCalendar},
        {name: 'Live Event Replays', typeOfComponent: null, slug: 'live-calls', icon: faVideo}
      ], //used to show what nav items are present in top};
      gettingStartedCompleted: false
    };

    this.PREVENT_NAV_TO_GETTING_STARTED = window.PREVENT_NAV_TO_GETTING_STARTED;
    window.REVENT_NAV_TO_GETTING_STARTED = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !(
        isEqual(prevState.toursCompleted, this.state.toursCompleted) &&
        isEqual(prevState.gettingStartedCompleted, this.state.gettingStartedCompleted)
      )
    ) {
      const {gettingStartedCompleted, toursCompleted} = this.state;
      const userData = this.props.get(this.id);
      userData.then(data => {
        const newState = {
          ...data,
          ...{gettingStartedCompleted, toursCompleted}
        };

        this.props.post(this.id, newState);
      });
    }
  }

  componentDidMount() {
    this.getState();
    this.props.refreshToken();
  }

  handleNav = (name, typeOfComponent, slug) => {
    this.props.history.push(`/products/${this.thisProductInPath}/${slug}`);
    window.scrollTo(0, 0);
  };
  gettingStartedCheck = () => {
    if (!this.state.gettingStartedCompleted) {
      this.setState({gettingStartedCompleted: true});
      this.handleNav(null, null, 'getting-started');
    }
  };

  async getState() {
    const savedState = await this.props.get(this.id);
    this.setState({...savedState, loadingState: false}, this.gettingStartedCheck);
  }

  getCurrentWeek = weeksList => {
    return weeksList[0];
  };

  getComponent = type => {
    const component = this.dynamicComponents[type];
    return component;
  };

  updateTourStatus = (tour, status) => {
    return this.setState({toursCompleted: {...this.state.toursCompleted, [tour]: status}});
  };

  render() {
    const {Children} = this;
    const path = location.pathname.split('/');
    const thisProductInPath = path[2];
    const contentItemSlug = path[3];
    // let productSlug = path[path.length - 2];

    // iow we are on recipe
    const renderWithoutContainer =
      contentItemSlug && (contentItemSlug.includes('recipe') || contentItemSlug.includes('article'));

    const weeksList = this.weeksListContentItem;

    const {loading, sortedWeeksList, thisWeek, loadingState, toursCompleted} = this.state;

    const {navItems} = this.state;

    return (
      <div>
        <ProductSidebar navItems={navItems} handleNav={this.handleNav}>
          <div className="wlcContainer">
            <WLCNav
              allWeekResourcesLists={this.allWeekResourcesLists}
              allWeekRecipesLists={this.allWeekRecipesLists}
              sortedWeeksList={sortedWeeksList}
              thisWeek={thisWeek}
            />

            <Switch>
              <Route
                exact
                path={`/products/${thisProductInPath}/favorites-library`}
                component={props => {
                  return <FavoritesPage {...props} />;
                }}
              />
              <Route
                exact
                path={`/products/${thisProductInPath}/live-calls`}
                component={props => {
                  return (
                    <GeneralContentContainer
                      overrideSlug={`${thisProductInPath}-live-calls-2024`}
                      Component={ListPage}
                      dataMassager={null} //used if data transformation is needed from the contentItem
                      {...props}
                      sortBy="data.time"
                    />
                  );
                }}
              />
              <Route
                exact
                path={`/products/${thisProductInPath}/search`}
                render={() => {
                  let {authToken} = JSON.parse(localStorage.getItem('user'));
                  const token = this.props.authToken || authToken;
                  return <SearchPage authToken={token} />;
                }}
              />
              {/* WLC doesn't have access to HOST/products from its router, so we use an abosolute redirect component which uses vanilla js to redirect */}

              <Route
                exact
                path={`/products`}
                render={() => {
                  console.log('products route');
                  return <AbsoluteRedirect to={'/products/'} />;
                }}
              />
              {/* getting started page in now the dashboard checking the userdata */}
              <Route
                exact
                path={`/products/${thisProductInPath}/getting-started`}
                render={() => <Redirect to="/products/wlc" />}
              />
              {/* all action hours page */}
              <Route
                exact
                path={`/products/${thisProductInPath}/action-hours`}
                component={props => {
                  return (
                    <GeneralContentContainer
                      overrideSlug={'action-hours'}
                      Component={ActionHours}
                      dataMassager={null} //used if data transformation is needed from the contentItem
                    />
                  );
                }}
              />
              {/* dashboard  **/}
              <Route
                exact
                path={`/products/${thisProductInPath}/`}
                render={() => (
                  <Dashboard
                    //all weeks for this product
                    weeksListSlug={WEEKS_LIST_SLUG}
                    //onbaord content for this product
                    onBoardContentId={ONBOARD_CONTENT_ID}
                    //all actions for this product
                    actionHoursId={ACTION_HOURS_ID}
                    eventSlug={'wlc-calendar-of-events'}
                    {...this.state.contentItem}
                    //JoyRide stuff
                    toursCompleted={toursCompleted}
                    toursLoaded={!loadingState}
                    updateTourStatus={this.updateTourStatus}
                  />
                )}
              />
              {/* resources page */}
              <Route
                exact
                path={`/products/${thisProductInPath}/resources`}
                component={props => {
                  let {authToken} = JSON.parse(localStorage.getItem('user'));
                  const token = this.props.authToken || authToken;
                  return <SearchPage authToken={token} />;
                }}
              />
              {/* weeks page */}
              <Route
                exact
                path={`/products/${thisProductInPath}/weeks`}
                component={() => <WeeksPage product="wlc" slug={WEEKS_LIST_SLUG} />}
              />
              {/* current week, this puts the get weeks call outside of wlc index */}
              <Route
                exact
                path={`/products/${thisProductInPath}/current-week`}
                // render={() => <Redirect to={`/products/${thisProductInPath}/${this.thisWeek.slug}`} />}
                render={props => (
                  <CurrentWeek
                    toursCompleted={toursCompleted} //for Week only
                    toursLoaded={!loadingState} //for Week only
                    updateTourStatus={this.updateTourStatus} //for week only
                  />
                )}
              />
              {/* recipes page */}
              <Route
                exact
                path={`/products/${thisProductInPath}/recipes`}
                component={() => {
                  let {authToken} = JSON.parse(localStorage.getItem('user'));
                  const token = this.props.authToken || authToken;
                  //this is a dataMassager for WLC content item to return all recipes and then pass into GlobalSearch
                  const returnAllRecipesForGlobalSearch = contentItem => {
                    const allWeeksList = contentItem.Children.filter(child => child.type === 'weeksList')[0].Children;
                    const allRecipes = [];
                    const allWeekRecipesLists = [];
                    //getting all the recipesLists in WLC..will remove once there is an API call
                    allWeeksList.map(week => {
                      const recipesList = week.Children.filter(child => child.type === 'recipesList')[0];
                      allWeekRecipesLists.push(recipesList);
                      if (recipesList) {
                        recipesList.Children.map(recipe => allRecipes.push(recipe));
                      }
                    });

                    return {id: contentItem.id, Children: allRecipes};
                  };

                  return <RecipesPage authToken={token} />; //<GlobalSearchV2 types={['recipe']} authToken={token} />;
                }}
              />
              <Route
                exact
                path={`/products/${thisProductInPath}/community`}
                render={props => (
                  <Forum
                    {...props}
                    isCommunityForum
                    product="wlc"
                    toursCompleted={toursCompleted}
                    toursLoaded={!loadingState}
                    updateTourStatus={this.updateTourStatus}
                  />
                )}
              />
              <Route
                path={`/products/${thisProductInPath}/community/*`}
                render={props => (
                  <Forum
                    {...props}
                    isCommunityForum
                    {...{
                      product: 'wlc',
                      sortedWeeksList,
                      toursCompleted,
                      toursLoaded: !loadingState,
                      updateTourStatus: this.updateTourStatus
                    }}
                  />
                )}
              />

              {/* Calendar  */}
              <Route path={`/products/${thisProductInPath}/calendar`} render={props => <Calendar />} />

              {/* wildcard routes for week, recipe, actionhour, resource */}
              <Route
                key={'wildcard'}
                exact
                path={`*/:slug/`}
                component={props => {
                  console.log('wildcard being loaded', props);
                  return (
                    <GeneralContentContainerApiComponents
                      dataMassager={null} //used if data transformation is needed from the contentItem
                      Component={null}
                      componentProps={{
                        toursCompleted, //for Week only
                        toursLoaded: !loadingState, //for Week only
                        updateTourStatus: this.updateTourStatus //for week only
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`/products/${thisProductInPath}/communityguidelines`}
                component={Data(CommunityGuidelines, `/content/wlc-community-guidelines/WLC`)}
              />
              <Redirect from={`/products/${thisProductInPath}/*`} to={`/products/${thisProductInPath}/`} />
            </Switch>
          </div>
          <MedicalDisclaimer />
        </ProductSidebar>
      </div>
    );
  }
}

WLC.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({})
};

WLC.defaultProps = {
  data: {}
};

WLC.displayname = 'WLC';

export default withRouter(WLC);
