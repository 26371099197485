import {videoResourceForPPT} from '../../../components/VideoResource/testData.js';
//import DownloadList from './DownloadList/testData.js';
export const LessonOne = {
  id: 888888884,
  name: 'Lesson One',
  title: 'The Basics',
  slug: 'ppt-m1-l1',
  type: 'lesson',
  data: {
    description: 'Lesson one of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonTwo = {
  id: 888888885,
  name: 'Lesson Two',
  title: 'The Basics',
  slug: 'ppt-m1-l2',
  type: 'lesson',
  data: {
    description: 'Lesson one of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonThree = {
  id: 888888886,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonFour = {
  id: 888888887,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonFive = {
  id: 888888888,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonSix = {
  id: 888888889,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonSeven = {
  id: 888888890,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};
export const LessonEight = {
  id: 888888891,
  name: 'Lesson Three',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};

export const LessonNine = {
  id: 888888893,
  name: 'Lesson Three 9',
  title: 'The Basics',
  slug: 'ppt-m1-l3',
  type: 'lesson',
  data: {
    description: 'Lesson three of the first module of PPT.'
  },
  published: true,
  Children: [videoResourceForPPT]
};
