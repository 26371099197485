import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {actions} from 'modules/user';
import {Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Col} from 'reactstrap';
import 'whatwg-fetch';
import queryString from 'query-string';
import {PulseLoader} from 'react-spinners';
import {baseEnvVars} from 'components/ForumWrapper';

// library imports
import {forumInterfaceFunctions} from '@frn/frn-react-native-discourse/lib/module/components/hooks/forumInterfaceFunctions';

const loadingProps = {size: 40, sizeUnit: 'px', color: '#212529'};

const MarketingCloudFieldMapping = {
  Weekly: 'Weekly',
  Member: 'Member (3-4 times per week)',
  Entrepreneurship: 'Entrepreneurship',
  PersonalDevelopment: 'Personal Development',
  UnsubAll: 'Unsubscribe me from all future mailings'
};

const ForumFieldMapping = {
  email_digests: 'Receive Email Digests?',
  email_level: 'Send me emails about notifications',
  email_messages_level: 'Send me Emails about Private Messages',
  digest_after_minutes: 'Send me a digest of new conversations after',
  allow_private_messages: 'Allow private messages?',
  topic_muted: 'Mute all notifications about this conversation'
};

const ForumEmailLevelSelectorMapping = {
  0: 'Always',
  1: 'Only When Away',
  2: 'Never'
};

const {SF_API_URL} = process.env;

const CommunicationPreferences = props => {
  const [MarketingCloud, setMarketingCloud] = useState({});
  const [MarketingCloudINITIAL, setInitialMarketingCloud] = useState({});
  const [Forum, setForum] = useState({});
  const [ForumINITIAL, setInitialForum] = useState({});
  const [ForumUnsubscribe, setForumUnsubscribe] = useState({});
  const [ForumUnsubscribeINITIAL, setInitialForumUnsubscribe] = useState({});
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(props.user);

  const [UUID, setUUID] = useState(user.UUID);

  const dispatch = useDispatch();

  const setCPinState = 'communicationPreferences' in user;

  const baseUserInfo = () => {
    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      FRNPlatformUserId: user.id,
      // not technically accurate, but as of right now, this is the attribute
      // store-and-forward keys off of to know to go to MarketingCloud
      isNewRecord: true
    };
  };

  useEffect(() => {
    if (user) {
      if (!setCPinState || !user.communicationPreferences.MarketingCloud) {
        if (UUID) {
          const url = `${SF_API_URL}/user/lookup/${UUID}`;

          fetch(url, {method: 'GET'}).then(resp => {
            return resp
              .json()
              .then(j => {
                // let's pluck only certain values from this
                let prefsOnly = {};
                Object.keys(MarketingCloudFieldMapping).forEach(k => {
                  prefsOnly[k] = ['true', 'True', true].includes(j[k]);
                });
                setCPinState && dispatch(actions.dispatchCommunicationPreferences('MarketingCloud', prefsOnly));
                setMarketingCloud(prefsOnly);
                setInitialMarketingCloud(prefsOnly);
              })
              .catch(err => {
                console.log('Error retrieving CRM communication preferences');
              });
          });
        }
      } else {
        setMarketingCloud(user.communicationPreferences.MarketingCloud);
        setInitialMarketingCloud(user.communicationPreferences.MarketingCloud);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (!setCPinState || !user.communicationPreferences.Forum) {
        const {setAuthContextFromUser, getUserUnsubscribeOptions} = forumInterfaceFunctions({
          user: user,
          ...(setCPinState && {dispatchForumCommunicationPreferences: actions.dispatchCommunicationPreferences})
        });

        setAuthContextFromUser(
          {...user, discourseKey: props.callingUser.discourseKey, userId: user.id},
          {
            discourse_api_username_prefix: baseEnvVars.FORUM_DISCOURSE_API_USERNAME_PREFIX,
            base_url: baseEnvVars.FORUM_BASE_URL,
            setForum,
            setInitialForum
          }
        );

        if (props.unsubscribeKey) {
          getUserUnsubscribeOptions(user, {
            base_url: baseEnvVars.FORUM_BASE_URL,
            unsubscribeKey: props.unsubscribeKey
          }).then(unsubResp => {
            if (unsubResp.unsubscribe_options) {
              setForumUnsubscribe(unsubResp.unsubscribe_options);
              setInitialForumUnsubscribe(unsubResp.unsubscribe_options);
            }
            return Promise.resolve(true);
          });
        }
      } else {
        setForum(user.communicationPreferences.Forum);
        setInitialForum(user.communicationPreferences.Forum);
      }
    }
  }, [user]);

  const optInUser = () => {
    // the user has no UUID, so we will have to create one and opt them in
    fetch(`${SF_API_URL}/user`, {
      method: 'POST',
      body: JSON.stringify({
        ...baseUserInfo(),
        entrySource: 'Opt-in from platform',
        newRegistrant: 3 // to allow user to get added to mailing lists
      })
    }).then(resp => {
      /* edge-case: UUID was null in the platform, but a record exists in MarketingCloud, possibly with different data than defaults
       * we wouldn't be able to capture that at this time. */
      if (resp.ok) {
        // set default values here? Everything false except for Member being true
        let prefsOnly = {};
        Object.keys(MarketingCloudFieldMapping).forEach(k => {
          prefsOnly[k] = k == 'Member' ? true : false; // default values in MarketingCloud
        });
        setCPinState && dispatch(actions.dispatchCommunicationPreferences('MarketingCloud', prefsOnly));
        setMarketingCloud(prefsOnly);
        setInitialMarketingCloud(prefsOnly);

        let respJSON = resp.json().catch(err => ({success: 'true'}));
        return respJSON.then(json => {
          setUUID(json.uuid);
        });
      } else {
        // an error for whatever reason
        setError(true);
        setMessage('There was an error opting you into our mailing list. Please contact support for assistance.');
      }
    });
  };

  const toggleCheck = (type, preferencesToToggle) => {
    if (!Array.isArray(preferencesToToggle)) preferencesToToggle = [preferencesToToggle];
    if (type === 'MarketingCloud') {
      let updatedPrefs = preferencesToToggle.reduce((o, k) => {
        o[k] = !MarketingCloud[k];
        return o;
      }, {});
      /* if multiple keys get toggled and ALL keys get set to true, set only the first one to true
       * this looks and feels over-engineered, but it's short and I know handling radio buttons
       * is annoying to being with */
      if (preferencesToToggle.length > 1 && Object.keys(updatedPrefs).every(k => updatedPrefs[k])) {
        preferencesToToggle.slice(1).forEach(k => {
          updatedPrefs[k] = !updatedPrefs[k];
        });
      }
      setMarketingCloud(prevState => ({
        ...prevState,
        ...updatedPrefs
      }));
    } else if (type === 'Forum') {
      setForum(prevState => ({
        ...prevState,
        ...preferencesToToggle.reduce((o, k) => {
          o[k] = !prevState[k];
          return o;
        }, {})
      }));
    } else if (type === 'ForumUnsubscribe') {
      setForumUnsubscribe(prevState => ({
        ...prevState,
        ...preferencesToToggle.reduce((o, k) => {
          o[k] = !prevState[k];
          return o;
        }, {})
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const url = `${SF_API_URL}/user`;

    // check if there are any actually updates here that require posting
    let performMarketingCloudRequest, performForumRequest, performForumUnsubscribeRequest;

    performMarketingCloudRequest = Object.keys(MarketingCloud).some(k => {
      return MarketingCloud[k] !== MarketingCloudINITIAL[k];
    });

    performForumRequest = Object.keys(Forum).some(k => {
      return Forum[k] !== ForumINITIAL[k];
    });

    performForumUnsubscribeRequest = Object.keys(ForumUnsubscribe).some(k => {
      return ForumUnsubscribe[k] !== ForumUnsubscribeINITIAL[k];
    });

    return new Promise((resolve, reject) => {
      if (!performMarketingCloudRequest) return resolve(true); //nothing to do
      let MarketingCloudForSubmission = {
        ...MarketingCloud,
        ...baseUserInfo(),
        frequency: MarketingCloud.Member ? 'Member' : MarketingCloud.Weekly ? 'Weekly' : null
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(MarketingCloudForSubmission)
      })
        .then(resp => {
          if (resp) {
            setCPinState && dispatch(actions.dispatchCommunicationPreferences('MarketingCloud', MarketingCloud));
            setInitialMarketingCloud(MarketingCloud);
            setError(false);
            props.toggle();
          }
        })
        .catch(err => {
          // uh-oh
          setError(true);
          setMessage('There was an error updating your communication preferrences.');
          resolve(false);
        });
    })
      .then(() => {
        return new Promise((resolve, reject) => {
          if (!performForumRequest) return resolve(true); //nothing to do
          // update preferences in Discourse
          const {setUserPreferences} = forumInterfaceFunctions({
            user: user
          });

          return Promise.resolve(
            setUserPreferences(
              {...user, discourseKey: props.callingUser.discourseKey, userId: user.id},
              {
                discourse_api_username_prefix: baseEnvVars.FORUM_DISCOURSE_API_USERNAME_PREFIX,
                base_url: baseEnvVars.FORUM_BASE_URL
              },
              Forum
            )
          ).then(success => {
            if (success) {
              setCPinState && dispatch(actions.dispatchCommunicationPreferences('Forum', Forum));
              setInitialForum(Forum);
              setError(false);
              props.toggle();
              return true;
            } else {
              setError(true);
              setMessage('There was an error updating your communication preferrences.');
              return false;
            }
          });
        });
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          if (!performForumUnsubscribeRequest) return resolve(true); //nothing to do
          // update preferences in Discourse for this unsubscribe key
          const {setUserUnsubscribeOptions} = forumInterfaceFunctions({
            user: user
          });

          // map to preferences needed for Discourse
          let ForumUnsubscribeForSubmission = {
            mute_topic: ForumUnsubscribe.topic_muted ? true : false
          };

          return Promise.resolve(
            setUserUnsubscribeOptions(
              user,
              {
                base_url: baseEnvVars.FORUM_BASE_URL,
                unsubscribeKey: props.unsubscribeKey
              },
              ForumUnsubscribeForSubmission
            )
          ).then(success => {
            if (success) {
              setInitialForumUnsubscribe(ForumUnsubscribe);
              setError(false);
              props.toggle();
              return true;
            } else {
              setError(true);
              setMessage('There was an error updating your communication preferrences.');
              return false;
            }
          });
        });
      });
  };

  const CommunicationPreferencesFormFieldBase = (
    mapping,
    sourceObject,
    fieldKey,
    formFieldType,
    formFieldName,
    toggledType,
    toggledKeys = null
  ) => {
    return (
      <Row>
        <Col>
          <Label for={fieldKey}>{mapping[fieldKey]}</Label>
        </Col>
        <Col xs={2}>
          <Input
            type={formFieldType}
            name={formFieldName}
            id={fieldKey}
            checked={sourceObject[fieldKey]}
            onChange={() => {
              return [toggleCheck(toggledType, toggledKeys ? toggledKeys : [fieldKey])];
            }}
          />
        </Col>
      </Row>
    );
  };

  const CommunicationPreferencesFormFieldSelect = (mapping, selectorOptions, sourceObject, fieldKey) => {
    return (
      <Row>
        <Col>
          <Label for={fieldKey}>{mapping[fieldKey]}</Label>
        </Col>
        <Col xs={4}>
          <Input
            type="select"
            id={fieldKey}
            value={sourceObject[fieldKey]}
            style={{marginLeft: '-3rem'}} // empirically chosen for styling
            onChange={e => {
              const {value} = e.target;
              setForum(prevState => ({
                ...prevState,
                [fieldKey]: parseInt(value)
              }));
            }}
          >
            {Object.keys(selectorOptions).map(n => {
              return (
                <option id={`${fieldKey}-${n}`} key={`${fieldKey}-${n}`} value={n}>
                  {selectorOptions[n]}
                </option>
              );
            })}
          </Input>
        </Col>
      </Row>
    );
  };

  const userForumGroups = Forum.userProductGroups;

  return (
    <Modal centered isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Communication Preferences</ModalHeader>
      <ModalBody>
        {error ? (
          message ? (
            <Alert color="danger">{message}</Alert>
          ) : (
            <Alert color="danger">There was an error updating your communication preferences.</Alert>
          )
        ) : null}
        <Form>
          {!UUID ? (
            <>
              <Row style={{justifyContent: 'center', fontSize: '2rem'}}>
                <b>You aren't opted-into our mailing list</b>
              </Row>
              <Row style={{justifyContent: 'center'}}>
                <Button onClick={e => optInUser()}>Opt-In here</Button>
              </Row>
              <Row style={{fontSize: '1.5rem', 'font-style': 'italic', padding: '0rem 1rem'}}>
                <p>
                  By entering your information here, you are agreeing to receive messages from Food Revolution Network.
                  Your email address will NEVER be shared or sold. You are always free to easily unsubscribe at any
                  time. See our <a href="https://foodrevolution.org/privacy-policy/">Privacy Policy</a>
                  {' for more info.'}
                </p>
              </Row>
            </>
          ) : Object.keys(MarketingCloud).length ? (
            <>
              {!MarketingCloud['UnsubAll'] && (
                <>
                  <FormGroup>
                    {' '}
                    <Row className="ml-1" style={{fontSize: '2rem'}}>
                      <b>General Mailing List</b>
                    </Row>{' '}
                    {CommunicationPreferencesFormFieldBase(
                      MarketingCloudFieldMapping,
                      MarketingCloud,
                      'Member',
                      'radio',
                      'frequency',
                      'MarketingCloud',
                      ['Member', 'Weekly']
                    )}
                    {CommunicationPreferencesFormFieldBase(
                      MarketingCloudFieldMapping,
                      MarketingCloud,
                      'Weekly',
                      'radio',
                      'frequency',
                      'MarketingCloud',
                      ['Weekly', 'Member']
                    )}
                  </FormGroup>
                  <FormGroup>
                    {' '}
                    <Row className="ml-1" style={{fontSize: '2rem'}}>
                      <b>Special Mailing Lists</b>
                    </Row>{' '}
                    {CommunicationPreferencesFormFieldBase(
                      MarketingCloudFieldMapping,
                      MarketingCloud,
                      'Entrepreneurship',
                      'checkbox',
                      'entrepreneurship',
                      'MarketingCloud'
                    )}
                    {CommunicationPreferencesFormFieldBase(
                      MarketingCloudFieldMapping,
                      MarketingCloud,
                      'PersonalDevelopment',
                      'checkbox',
                      'personal-development',
                      'MarketingCloud'
                    )}
                  </FormGroup>
                </>
              )}
              <FormGroup>
                {' '}
                <Row className="ml-1" style={{fontSize: '2rem'}}>
                  <b>Unsubscribe</b>
                </Row>{' '}
                {CommunicationPreferencesFormFieldBase(
                  MarketingCloudFieldMapping,
                  MarketingCloud,
                  'UnsubAll',
                  'checkbox',
                  'unsubAll',
                  'MarketingCloud'
                )}
              </FormGroup>
            </>
          ) : (
            <PulseLoader {...loadingProps} />
          )}
          {Object.keys(Forum).length && userForumGroups.length > 0 ? (
            <>
              <FormGroup>
                {' '}
                <Row className="ml-1" style={{fontSize: '2rem'}}>
                  <b>Community Preferences</b>
                </Row>{' '}
                {/*CommunicationPreferencesFormFieldBase(
                  ForumFieldMapping,
                  Forum,
                  'email_digests',
                  'checkbox',
                  'digests',
                  'Forum'
                )
                */}
                {userForumGroups &&
                  process.env.DMABLE_PRODUCTS.split(',').some(p => userForumGroups.map(up => up.name).includes(p)) &&
                  CommunicationPreferencesFormFieldBase(
                    ForumFieldMapping,
                    Forum,
                    'allow_private_messages',
                    'checkbox',
                    'allow_private_messages',
                    'Forum'
                  )}
                {CommunicationPreferencesFormFieldSelect(
                  ForumFieldMapping,
                  ForumEmailLevelSelectorMapping,
                  Forum,
                  'email_level'
                )}
                {/*CommunicationPreferencesFormFieldSelect(ForumFieldMapping, ForumEmailLevelSelectorMapping, Forum, 'email_messages_level')*/}
                {/*CommunicationPreferencesFormFieldSelect(ForumFieldMapping, [some numbers go here], Forum, 'digest_after_minutes')*/}
                {props.unsubscribeKey ? (
                  Object.keys(ForumUnsubscribe).length ? (
                    CommunicationPreferencesFormFieldBase(
                      ForumFieldMapping,
                      ForumUnsubscribe,
                      'topic_muted',
                      'checkbox',
                      'topic_muted',
                      'ForumUnsubscribe'
                    )
                  ) : (
                    <PulseLoader {...loadingProps} />
                  )
                ) : null}
              </FormGroup>
            </>
          ) : userForumGroups && userForumGroups.length === 0 ? null : (
            <>
              <PulseLoader {...loadingProps} />
              {props.isCS && (
                <p style={{fontSize: '1.2rem'}}>
                  If you are trying to edit another user's preferences and this never loads, it means you might not have
                  access. Please check with the FRN team!
                </p>
              )}
            </>
          )}
        </Form>
        <Button onClick={e => handleSubmit(e)}>Save</Button>
      </ModalBody>
    </Modal>
  );
};

export {CommunicationPreferences};
