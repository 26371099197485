import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Upgrade from '../Upgrade';
//create a functional component that returns a bootstrap modal

const UpgradeModal = props => {
  const {isOpen, toggle, title, slug} = props;

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} centered className="d-print-none">
      {title ? <ModalHeader>{title}</ModalHeader> : ''}
      <ModalBody style={{wordWrap: 'break-word'}}>
        <Upgrade slug={slug} toggle={toggle} />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button onClick={toggle} color="light" className="mr-3">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpgradeModal;
