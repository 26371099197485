import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';
import {faTimesSquare} from '@fortawesome/pro-regular-svg-icons/faTimesSquare';

//greyScale - option to keep checkbox grey on check
const TagXBox = ({checked, greyScale, ...props}) => (
  <FontAwesomeIcon
    icon={checked ? faTimesSquare : faSquare}
    style={{color: checked && !greyScale ? '#ED3535' : '#898989'}}
    {...props}
  />
);

export default TagXBox;
