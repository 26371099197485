import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {PulseLoader} from 'react-spinners';
import {Badge, Table} from 'reactstrap';
import {actions} from 'modules/checkout';
import {getCurrentOfferPaymentOption, getCurrentTotal, orderRequiresShipping} from 'modules/checkout/selectors';
import formatCurrency from 'lib/formatCurrency';

const OrderSummaryProduct = ({img, name, qty, price, isPaymentPlan, Discount, ...rest}) => (
  <React.Fragment>
    <tr {...rest}>
      <td style={{position: 'relative'}}>
        <img src={`${process.env.USER_CONTENT_URL}${img}`} style={{maxWidth: '50px'}} />
        {qty > 1 ? (
          <Badge color="dark" style={{position: 'absolute', top: 0, right: 0}}>
            {qty}
          </Badge>
        ) : null}
      </td>
      <td>{name}</td>
      <td className="text-right">{formatCurrency.format(Number(price))}</td>
    </tr>
    {Discount && !isPaymentPlan ? (
      <React.Fragment>
        <tr {...rest}>
          <td></td>
          <td colSpan={2} className="text-right">
            <strong>(with {formatCurrency.format(Number(Discount))} deposit applied)</strong>
          </td>
        </tr>
      </React.Fragment>
    ) : null}
  </React.Fragment>
);

const OrderSummary = props => {
  const {
    offer,
    orderRequest,
    orderRequiresShipping,
    total,
    totals: {pending}
  } = props;

  const bumpOffers = props.currentOfferPaymentOption ? props.currentOfferPaymentOption.BumpOffers : [];
  const {trialCount, trialInterval, interval} = props.currentOfferPaymentOption || {};
  const orderProductTotal = total && total.orderProducts.filter(p => p.ProductId === offer.Product.id)[0];
  const loadingProps = {size: 8, sizeUnit: 'px', color: '#212529'};
  const isPaymentPlan = total && total.order.numInstallments > 1;
  return total ? (
    <Table borderless>
      <tbody>
        <OrderSummaryProduct
          img={offer.image}
          name={offer.Product.name}
          qty={orderProductTotal.qtyOrdered}
          price={orderProductTotal.price}
          isPaymentPlan={isPaymentPlan}
          Discount={offer.Discount}
        />
        {bumpOffers
          .filter(op => !op.Product.slug.match(/gmo/i) && !offer.Product.slug.match(/pbcc/i))
          .map(bo => {
            const orderProductTotal = total.orderProducts.filter(p => p.BumpOfferId === bo.id)[0];
            return orderProductTotal ? (
              <OrderSummaryProduct
                img={bo.image}
                name={bo.name}
                qty={orderProductTotal.qtyOrdered}
                price={orderProductTotal.price}
                className={Number(orderProductTotal.price) === 0 ? 'free-bumpOffer' : null}
              />
            ) : null;
          })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2} style={{borderTop: '1px solid'}}>
            Subtotal
          </td>
          <td style={{borderTop: '1px solid'}} className="text-right">
            {formatCurrency.format(Number(total.order.subtotal))}
          </td>
        </tr>
        {orderRequiresShipping || (isNaN(total.order.shipping) && Number(total.order.shipping) > 0) ? (
          <tr>
            <td colSpan={2}>Shipping</td>
            <td className={`text-right`}>
              {pending ? <PulseLoader {...loadingProps} /> : `\$${total.order.shipping}`}
            </td>
          </tr>
        ) : null}
        <tr>
          <td colSpan={2}>Sales tax</td>
          <td className={`text-right`}>{pending ? <PulseLoader {...loadingProps} /> : `\$${total.order.tax}`}</td>
        </tr>
        <tr>
          <td colSpan={2} style={{borderTop: '1px solid'}}>
            <span style={!isPaymentPlan ? {fontWeight: 'bolder'} : {}}>Order total</span>
          </td>
          <td style={{borderTop: '1px solid'}} className={`text-right`}>
            <span style={!isPaymentPlan ? {fontWeight: 'bolder'} : {}}>
              {pending ? (
                <PulseLoader {...loadingProps} />
              ) : (
                `${formatCurrency.format(Number(total.order.subtotal) + Number(total.order.tax))}`
              )}
            </span>
          </td>
        </tr>
        {isPaymentPlan ? (
          <React.Fragment>
            <tr>
              <td colSpan={2} style={{borderTop: '1px solid'}}>
                <strong>Today's Payment</strong>
              </td>
              <td style={{borderTop: '1px solid'}} className={`text-right`}>
                <strong>
                  {pending ? (
                    <PulseLoader {...loadingProps} />
                  ) : (
                    `${formatCurrency.format(Number(total.order.firstPaymentAmount))}`
                  )}
                </strong>
              </td>
            </tr>
            {offer.Discount ? (
              <tr>
                <td colSpan={3}>
                  <strong>(with {formatCurrency.format(Number(offer.Discount))} deposit applied)</strong>
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        ) : null}
        {trialCount ? (
          <React.Fragment>
            <tr>
              <td colSpan={3}>
                * After your {trialCount}-{trialInterval} trial, you will be charged the following every {interval}:
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{borderTop: '1px solid'}}>
                Subtotal
              </td>
              <td style={{borderTop: '1px solid'}} className="text-right">
                {formatCurrency.format(Number(total.order.firstPaymentAmount) - Number(total.order.firstPaymentTax))}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Sales tax</td>
              <td className={`text-right`}>
                {pending ? <PulseLoader {...loadingProps} /> : `\$${total.order.firstPaymentTax}`}
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{borderTop: '1px solid'}}>
                <strong>Total</strong>
              </td>
              <td style={{borderTop: '1px solid'}} className={`text-right`}>
                <strong>
                  {pending ? (
                    <PulseLoader {...loadingProps} />
                  ) : (
                    `${formatCurrency.format(Number(total.order.firstPaymentAmount))}`
                  )}
                </strong>
              </td>
            </tr>
          </React.Fragment>
        ) : null}
      </tfoot>
    </Table>
  ) : null;
};

OrderSummary.propTypes = {
  offer: PropTypes.shape({}).isRequired,
  orderRequest: PropTypes.shape({}).isRequired,
  totals: PropTypes.shape({}).isRequired
};

const mapStateToProps = state => ({
  currentOfferPaymentOption: getCurrentOfferPaymentOption(state),
  orderRequest: state.checkout.orderRequest,
  orderRequiresShipping: orderRequiresShipping(state),
  offer: state.checkout.offer,
  total: getCurrentTotal(state),
  totals: state.checkout.totals
});

export default connect(mapStateToProps, null)(OrderSummary);
