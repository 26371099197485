import React, {useState, useEffect} from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

const ImageWithText = ({image, title, subtitle}) => {
  //it title is an array of strings, show them in separate h5 tags

  const imageStyle = {
    display: 'block',
    margin: '0 auto',
    width: '100%'
  };

  const titleStyle = {
    color: '#048D22',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center'
  };

  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '40%',
    backgroundColor: 'white',
    textAlign: 'center',
    color: '#048D22',
    borderRadius: '20px',
    paddingLeft: 10,
    paddingRight: 10
  };
  hStyle = {
    color: '#048D22',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    marginTop: 0
  };

  const wrapperStyle = {
    position: 'relative',
    width: 500,
    height: 500
  };

  return (
    <div style={wrapperStyle}>
      <img src={image} style={imageStyle} />
      <div style={textStyle}>
        {Array.isArray(title) ? (
          <div style={titleStyle}>
            {title.map((t, index) => (
              <h5 style={hStyle} key={index}>
                {t}
              </h5>
            ))}
          </div>
        ) : (
          <h5 style={titleStyle}>{title}</h5>
        )}
      </div>
    </div>
  );
};

const Celebration = ({image, title, subtitle, currentStep, waitForStep}) => {
  const [isExploding, setIsExploding] = useState(false);
  const [hasExploded, setHasExploded] = useState(false);

  useEffect(() => {
    if (hasExploded[currentStep]) {
      return;
    }
    if (currentStep == waitForStep || waitForStep == null) {
      setTimeout(() => {
        setIsExploding(true);
        setHasExploded({[currentStep]: true});
      }, 800);
    }
  }, [currentStep]);
  return (
    <div>
      <div className="my-5 mx-2 d-flex justify-content-center flex-column align-items-center ">
        {isExploding && (
          <ConfettiExplosion
            onComplete={() => {
              setIsExploding(false);
            }}
          />
        )}
        <div>
          <ImageWithText image={image ? image.url : ''} title={title} subtitle={subtitle} />
        </div>

        <div style={{width: '100%'}} className="text-center">
          <h5 className="text-muted">{subtitle}</h5>
        </div>
      </div>
    </div>
  );
};

export default Celebration;
