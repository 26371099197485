import React, {Component} from 'react';
import Video from '../../../../components/Video';
import {Container, Row, Col} from 'reactstrap';
import {withRouter} from 'react-router';
import DashboardHeader from '../../../PPTv2/pages/Dashboard/components/DashboardHeader';
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentsAlt} from '@fortawesome/pro-regular-svg-icons/faCommentsAlt';
import ModuleWrapper from '../../../PPTv2/pages/Dashboard/components/ModuleWrapper';
import ModulesHeader from '../../../PPTv2/pages/Dashboard/components/ModulesHeader';

class PbccDashboard extends Component {
  constructor(props) {
    super(props);
    console.log('this.props.product ', this.props.product);
    this.state = {
      userData: {},
      product: this.props.product,

      progress: 0,
      canShowOnbaord: false,
      runTour: true
    };

    this.productInPath = location.pathname.split('/')[2];
    this.product = this.props.product;

    this.modules = this.product.Children.filter(item => item.type === 'module');
    this.downloadList = this.product.Children.find(item => item.type === 'list')?.Children || [];
    this.faqPage = this.product.Children.find(item => item.slug.endsWith('faqs'));
    console.log('modules in dashboard ', this.modules);
    this.videoResources = this.product.Children.filter(item => item.type === 'videoResource');
    this.bonuses = this.product.Children.filter(item => item.type === 'bonuses');

    this.assessments = this.product ? this.product.Children.filter(item => item.type === 'assessment') : null;

    this.tourSteps = [
      {
        target: 'body',
        placement: 'center',
        title: 'PBCC Welcome video',
        content:
          'Watch the Plant-Based Coaching Certification Program welcome video from Ocean to get a bit of insight into what to expect!'
      },
      {
        target: '.float-discalimer',
        placement: 'auto',
        title: 'Community Guidelines',
        content:
          'Please read and agree to our Community Guidelines as they provide some direction for how we’ll interact together in the program.'
      },
      {
        target: '.forumn',
        placement: 'auto',
        title: 'Visit the Forum',
        content: ' Start connecting with your fellow peers and get faculty support in the Forum.'
      }
    ];
  }

  handleNav = slug => {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
  };

  handleJoyrideCallback = data => {
    const {status, type} = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('tour status', status);
      this.setState({runTour: false});
      this.props.updateTourStatus(status);
    }
  };

  componentDidMount() {}

  render() {
    const {
      removeWelcome,
      moduleStates,
      isUnstructured,
      videoDirectUrl,
      floatingButton,
      floatingButtonText
    } = this.props;
    const {description = ''} = this.product.data;

    return (
      <div>
        <ReactJoyride
          continuous
          callback={this.handleJoyrideCallback}
          run={this.state.runTour && !this.props.toursCompleted}
          steps={this.tourSteps}
          scrollToFirstStep
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000
            }
          }}
        />

        <Container>
          <div className="d-flex justify-content-between align-items-center">
            {removeWelcome ? null : <DashboardHeader isUnstructured={isUnstructured} handleNav={this.handleNav} />}
            {floatingButton && (
              <span onClick={this.props.floatingButtonAction} className="float-discalimer">
                {floatingButtonText}
              </span>
            )}
          </div>

          {description ? <p>{description}</p> : null}
          {removeWelcome ? null : videoDirectUrl || (this.product.data.video && this.product.data.video.url) ? (
            <Row>
              <Col>
                <Video data={this.product.data.video} videoDirectUrl={videoDirectUrl} className="xs-full-width" />
              </Col>
            </Row>
          ) : null}

          {/*<ModulesHeader
            moduleHeaderTitle={moduleHeaderTitle}
            moduleStates={moduleStates ? moduleStates : []}
            calculateModuleProgress={this.calculateModuleProgress}
            isUnstructured={isUnstructured}
          />*/}
          {/* This will render a Card with the Module title in the CardHeader and the lessons in the CardBody.  
         It will also display the progress of the module and lessons*/}
          {this.modules ? (
            <Row>
              {this.downloadList?.length &&
                this.downloadList.map((item, index) => {
                  return (
                    <Col key={item.id} xs="12">
                      <ModuleWrapper
                        determineColor={this.determineColor}
                        handleNav={this.handleNav}
                        module={item}
                        moduleNumber={item.data.num}
                        moduleState={moduleStates ? moduleStates.find(mod => mod.id === item.id) : null}
                        isUnstructured={true}
                        isDownloadLink={item.type === 'download'}
                        downloadLink={item.data.url}
                      />
                    </Col>
                  );
                })}
                {this.faqPage &&
                <Col key={this.faqPage.id} xs="12">
                <ModuleWrapper
                  determineColor={this.determineColor}
                  handleNav={this.handleNav}
                  module={{...this.faqPage, slug: 'faqs'}}
                  moduleNumber={0}
                  moduleState={null}
                  isUnstructured={true}
                />
              </Col>}
            </Row>
          ) : null}
          <div
            className={`text-success pointerGlobal`}
            style={{fontSize: '20px'}}
            onClick={() => this.handleNav('community')}
          >
            <FontAwesomeIcon color="#28a745" icon={faCommentsAlt} />
            <span className="pl-1 dashboardHeader">Join the discussion</span>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(PbccDashboard);
