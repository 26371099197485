import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions } from 'modules/modal';
import {actions as userActions} from 'modules/user';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import styles from 'styles/index.scss';

const { landingPageBG } = styles;

@connect((state) => ({ user: state.user, lastProductVisited: state.application.lastProductVisited }), {
  openModal: actions.openModal,
  loginDiscourse: userActions.loginUserDiscourse
})
class LandingPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    const { lastProductVisited, user: { isAuthenticated }, location } = this.props;
    const lastProductVisitedUrl = `/products/${lastProductVisited || ''}`;

    if (isAuthenticated && !this.props.history.location.pathname.split('/')[1]) {
      const qvs = queryString.parse(location.search);
      // check for discourse query parameters
      if (qvs.sso && qvs.sig) {
        // ok, let's call the Discourse endpoint
        this.props.loginDiscourse(this.props.user, qvs, this, lastProductVisitedUrl);
      } else {
        this.props.history.push(qvs.redirect ? decodeURIComponent(qvs.redirect) : lastProductVisitedUrl);
      }
    } else {
      //landing component is used for '/' and '/users/password/:instruction
      //on instruction=='reset' we open up the reset password modal
      this.props.match.params.instruction && this.props.match.params.instruction == 'reset'
        ? this.props.openModal('reset', 1, false)
        : this.props.openModal('login', 1, false, this.props.location);
    }
  }

  handleClickLogin = () => {
    this.props.openModal('login');
  };

  handleClickRegister = () => {
    this.props.openModal('register');
  };

  render() {
    const { user: { isAuthenticated } } = this.props;

    if (isAuthenticated) return null;

    return <div className={landingPageBG} />;
  }
}

LandingPage.propTypes = {
  openModal: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};

export default LandingPage;
