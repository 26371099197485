import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import BackNav from 'routes/Products/components/BackNav';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'routes/Products/products/EmpowermentPackageV2/components/BreadCrumbs';
import Bonuses from 'routes/Products/components/Bonuses';
import epStyles from 'routes/Products/products/EmpowermentPackageV2/styles.js';
import _ from 'lodash';
const WrapperBonuses = props => {
  const {bonuses, showAllBonuses} = props;

  let allBonuses = [];
  if (bonuses.Children && showAllBonuses) {
    //merge the arrays together
    allBonuses = _.flattenDeep(bonuses.Children.map(item => item.Children));
  }
  return (
    <React.Fragment>
      <Container className="mb-0">
        <div style={epStyles.backNavWrapperStyle}>
          <BackNav />
        </div>
        <BreadCrumbs>
          {/* <Link to={`/products/${props.productPath}`} className="pr-1">
            {props.product.title}
          </Link>{' '}
          / {props.name ? props.name : 'Bonuses'} */}
        </BreadCrumbs>
      </Container>
      {allBonuses && showAllBonuses ? (
        <Bonuses {...bonuses} Children={allBonuses} />
      ) : bonuses ? (
        <Bonuses {...bonuses} />
      ) : (
        <div>No bonuses here.</div>
      )}
    </React.Fragment>
  );
};

export default WrapperBonuses;
