import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare} from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';

const TagCheckbox = ({checked, ...props}) => (
  <FontAwesomeIcon
    icon={checked ? faCheckSquare : faSquare}
    style={{color: checked ? '#289B18' : '#898989'}}
    {...props}
  />
);

export default TagCheckbox;
