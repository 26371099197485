import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
import styles from 'styles/index.scss';

const {scrollableMarkdown} = styles;

class ScrollableMarkdown extends Component {
  handleScroll = e => {
    const bottom = e.target.clientHeight;
    const scroll = e.target.scrollHeight - e.target.scrollTop;
    if (Math.abs(scroll - bottom) < 50 && typeof this.props.onScrollBottom === 'function') {
      this.props.onScrollBottom();
    }
  };
  render() {
    const {props} = this;
    const {source, onScrollBottom, renderers, style, ...restProps} = props;
    const _style = {...style, overflow: 'auto'};
    const classes = classNames('scrollableMarkdown', this.props.className);
    return (
      <div className={classes} onScroll={this.handleScroll} style={_style} {...restProps}>
        <ReactMarkdown source={props.source} renderers={renderers} />
      </div>
    );
  }
}

ScrollableMarkdown.propTypes = {
  source: PropTypes.string.isRequired
};

export default ScrollableMarkdown;
