import React from 'react';
import {Col, Row} from 'reactstrap';
import './ImgBullet.scss';

export default function ImgBullet(props) {
  return (
    <Row className="d-flex justify-content-center">
      <Col xs="12" sm="10" md="8" lg="12">
        <div className="imgBullet box-shadow-black-50 rounded mb-4">
          <Row className="d-flex align-items-center m-0 px-0">
            <Col xs="12" lg="3" className="px-0">
              <img className="imgBullet-img img-fluid" src={props.img} />
            </Col>
            <Col xs="12" lg="9" className="text-center text-lg-left py-3 pl-lg-4">
              <h2 className="text-condensed mb-2">{props.title}</h2>
              {props.children}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
