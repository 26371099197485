/* eslint-disable */
import React from 'react';
import { generateClient } from 'aws-amplify/api';
import {
  CognitoIdentityProviderClient,
  AdminAddUserToGroupCommand,
  AdminRemoveUserFromGroupCommand,
} from '@aws-sdk/client-cognito-identity-provider'; // ES Modules import
import axios from 'axios';

import { useEnvState } from './context/env';
import { Button } from 'reactstrap';

import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';

import { retrieveUserCredentials } from './utils';

export default function ModeratorFeatures(props: any) {
  const client = generateClient();

  const envState = useEnvState();

  const { message } = props;

  async function toggleBlockUser(userId: string) {
    const userCreds = await retrieveUserCredentials();

    // add user to the "Blocked" group
    const cognitoClient = new CognitoIdentityProviderClient({
      ...envState,
      region: 'us-west-2',
      credentials: userCreds,
    });
    const input = {
      // AdminAddUserToGroupRequest
      UserPoolId: envState.aws_user_pools_id, // required
      Username: userId, // required
      GroupName: 'Blocked',
    };
    let command;
    if (message.userBlocked) {
      command = new AdminRemoveUserFromGroupCommand(input);
    } else {
      command = new AdminAddUserToGroupCommand(input);
    }
    //const response = await cognitoClient.send(command);
    await cognitoClient.send(command);

    // get all comments by this user and set hidden to TRUE
    const userComments = await client.graphql({
      query: queries.messagesByUser,
      variables: {
        userID: message.userID,
      },
    });
    // create a mutation to update all of these - blockUser?
    let blockQueryArguments = 'mutation MyMutation(';
    let blockQueryFuncs = '';
    let blockVariables: any = {};
    const baseCallBlock = function (ind: number) {
      return `um${ind}: updateMessage(input: $input${ind}, condition: $condition) {\n    id\n    raw\n    timestamp\n    ParentCommentId\n    userToNotifyID\n    readByParentCommentUser\n    hidden\n    userBlocked\n    userName\n    userID\n    moderatorPost\n    picture\n    category\n    createdAt\n    updatedAt\n    __typename\n  }\n`;
    };

    userComments.data?.messagesByUser?.items.forEach((m, i) => {
      blockQueryArguments += `$input${i}: UpdateMessageInput!, `;
      blockQueryFuncs += baseCallBlock(i);
      blockVariables[`input${i}`] = { id: m.id, userBlocked: !message.userBlocked };
      return true;
    });

    const blockQuery = `${blockQueryArguments} $condition: ModelMessageConditionInput) {\n  ${blockQueryFuncs}}\n`;

    return axios.post(
      envState.aws_appsync_graphqlEndpoint,
      { query: blockQuery, variables: blockVariables },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': envState.aws_appsync_apiKey,
        },
      },
    );
  }

  return (
    <>
      {message.hidden ? (
        <Button
          className="me-2"
          color="mod-comment"
          onClick={async () => {
            await client.graphql({
              query: mutations.updateMessage,
              variables: {
                input: {
                  id: message.id,
                  hidden: false,
                },
              },
            });
          }}
        >
          Unhide Comment (ignored if user is also blocked)
        </Button>
      ) : (
        <Button
          className="me-2"
          color="mod-comment"
          onClick={async () => {
            await client.graphql({
              query: mutations.updateMessage,
              variables: {
                input: {
                  id: message.id,
                  hidden: true,
                },
              },
            });
          }}
        >
          Hide Comment
        </Button>
      )}
      {
        <Button
          className=""
          color="mod-user"
          onClick={() => {
            toggleBlockUser(message.userID);
          }}
        >
          {message.userBlocked ? 'Unblock User' : 'Block User (also hides all comments)'}
        </Button>
      }
    </>
  );
}
