import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import './TreesFutureRow.scss';

export default function TreesFutureRow(props) {
  return (
    <Row className={classNames('d-flex align-items-center justify-content-center trees-for-future', props.className)}>
      <Col xs="12" md="10">
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs="12" lg="8" xl="7" className="mb-3 mb-lg-0">
            <h3 className="text-center text-lg-left text-condensed text-800 mb-2">
              Want a world where everyone has safe and healthy food? We do, too!
            </h3>
            <p className="mb-0">
              For every Impact Kit purchased, we make a donation to Trees for the Future, enabling them to plant another
              organic fruit or nut tree in a low-income community. So you can support health for you, your family, and
              your planet, help us reach more people with this urgent message, AND invest in healthy food for low-income
              communities, all at the same time!
            </p>
          </Col>
          <Col xs="12" lg="4" className="text-center">
            <img
              className="logo-trees img-fluid"
              src="https://cdn.foodrevolution.org/checkout/upsells/impactkit/logo-trees-for-future-green.png"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
