import {
	LessonOne,
	LessonTwo,
	LessonThree,
	LessonFour,
	LessonFive,
	LessonSix,
	LessonSeven,
	LessonEight,
	LessonNine
} from './LessonOne';
import ResourcesList from '../../../components/ResourcesListTwo/testData.js';
import RecipesList from '../../../components/RecipesList/testData.js';
import { WLCChecklist } from '../../../components/Checklist/testData.js';
import { InterviewCollectionItem } from '../../../components/AudioPlayerMobile/testData.js';
import { videoResourceForPPT } from '../../../components/VideoResource/testData.js';
import { VideoResource } from 'routes/Products/components/VideoResource';

export const ModuleOne = {
	id: 888888881,
	name: 'Module One',
	title: 'Module One Title',
	slug: 'module-one',
	type: 'module',
	data: {
		description: 'This is the Plant Powered & Thriving class'
	},
	published: true,
	Children: [
		LessonOne,
		LessonTwo,
		LessonNine,
		ResourcesList,
		RecipesList,
		WLCChecklist,
		InterviewCollectionItem,
		videoResourceForPPT
	]
};

export const ModuleTwo = {
	id: 888888882,
	name: 'Module Two',
	title: 'Module Two Title',
	slug: 'module-two',
	type: 'module',
	data: {
		description: 'This is the Plant Powered & Thriving Module 2',
		premieresAt: 18714302895001111111 //in the future
	},
	published: true,
	Children: [ LessonFour, LessonFive, ResourcesList, RecipesList, WLCChecklist ]
};

export const ModuleThree = {
	id: 888888883,
	name: 'Module Three',
	title: 'Module Three Title',
	slug: 'module-three',
	type: 'module',
	data: {
		description: 'This is the Plant Powered & Thriving Module 2'
	},
	published: true,
	Children: [ LessonSix, LessonSeven, LessonEight, ResourcesList, RecipesList, WLCChecklist ]
};

export const ModuleHHC = {
	id: 888888883,
	name: 'Module Three',
	title: 'Module Three Title',
	slug: 'module-three',
	type: 'module',
	data: {
		description: 'This is the Plant Powered & Thriving Module 2'
	},
	published: true,
	Children: [ VideoResource, VideoResource, InterviewCollectionItem, ResourcesList, RecipesList ]
};
