import React, {Component} from 'react';
// import { actions as applicationActions } from 'modules/application';
import {connect} from 'react-redux';
import Summary from '../../components/Summary';
import Addresses from '../../components/Addresses';
import PaymentPlans from '../../components/PaymentPlans';
import Subscriptions from '../../components/Subscriptions';
import PaymentMethods from '../../components/PaymentMethods';
import AccordionPage from './containers/AccordionPage';
import FullWidthPage from './containers/FullWidthPage';
import {actions as applicationActions} from 'modules/application';
import {Card, CardBody, Container, Col, Row} from 'reactstrap';
import styles from 'styles/index.scss';

const {profileBig, profileSmall, profileNav, profileSummary} = styles;

@connect(
  state => ({navigateToLocation: state.application.navigateTo}),
  {navigateTo: applicationActions.navigateTo}
)
class PrivateProfile extends Component {
  // Reset navigateTo setting if it was set (ie by SideBar)
  componentDidMount() {
    if (this.props.navigateToLocation) {
      this.props.navigateTo('');
    }
  }

  render() {
    return [
      <Container className={profileSummary}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Summary key="1" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>,
      <Container>
        <Row>
          <Col>
            <Subscriptions justShowBanner="true" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Saved Addresses</h4>
            <Addresses />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Saved Payment Methods</h4>
            <PaymentMethods />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Payment Plans</h4>
            <PaymentPlans />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Subscriptions</h4>
            <Subscriptions />
          </Col>
        </Row>
      </Container>
    ];
  }
}

export default PrivateProfile;
