import React from 'react';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const initialState = {
  id: null,
  name: null,
  email: null,
  picture: null,
  moderator: false,
  blocked: false,
  isCustomer: false,
};

function UserReducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, ...action.payload };
    }
    case 'RESET_USER': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(UserReducer, initialState);
  React.useEffect(() => {
    typeof window !== 'undefined' && localStorage.setItem('aws_cognito_user', JSON.stringify(state));
  }, [state]);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch };
