import React from 'react';

function ListItem({imageSrc, title, description}) {
  return (
    <div className="o-list-item">
      <div className="image">
        <img src={imageSrc} alt="Item" />
      </div>
      <div className="o-content pt-3">
        <div style={{minHeight: 50}}>
          <h2 className="o-title">{title}</h2>
          <div className="o-description">{description}</div>
        </div>
      </div>
    </div>
  );
}

function List({handleSelection, list, selected}) {
  return (
    <div style={{width: '100%', textAlign: 'center'}}>
      <div className="onboarding">
        <div className="list">
          {list.map((item, index) => {
            const {title, description, image} = item.displayProps;
            return (
              <div
                onClick={() => handleSelection(title, selected.includes(title))}
                className={selected && selected.includes(title) ? 'o-selected' : 'o-not-selected'}
                role="button"
              >
                <ListItem key={index} imageSrc={image ? image.url : ''} title={title} description={description} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default List;
