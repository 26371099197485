import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import jwt_decode from 'jwt-decode';
import {Button, Card, CardBody, CardFooter, Col, Container, Row} from 'reactstrap';
import queryString from 'query-string';
import EditPicModal from '../EditPicModal';
import EditUserInfoModal from '../EditUserInfoModal';
import ChangePasswordModal from '../ChangePasswordModal';
import EmailLogModal from '../EmailLogModal';
import AdminResetPassword from '../AdminResetPassword';
import {CommunicationPreferences} from '../CommunicationPreferences';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from 'styles/index.scss';
import Badge from '../Badge';
import {actions as userActions} from 'modules/user';
import {withRouter} from 'react-router';
const {
  profileLocation,
  profileName,
  profileSummary,
  profileSummaryButtons,
  profileSummaryGoal,
  profileSummarySocial,
  summaryImgBox,
  summaryImgInfo,
  summaryInfo,
  summaryLine,
  userAvatar
} = styles;

@connect(state => ({user: state.user}), {
  logoutUser: userActions.logoutUser
})
class Summary extends PureComponent {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.search);
    const user = props.publicProfileUser || props.user;
    // changes are 'for' this user - override store.user with the public profile user
    // used for cs
    this.state = {
      showCommunicationPreferencesModal: query.openPreferencesModal || props.openCPModal ? true : false,
      showDMbutton: props.allowsDMs,
      unsubscribeKey: query.unsubscribeKey || null,
      showEditPicModal: false,
      showUserInfoModal: false,
      showChangePasswordModal: false,
      showEmailLogModal: false,
      forUser: user,
      imgUrl: user.avatarUrl ? user.avatarUrl : ''
    };

    const {authToken, isAuthenticated} = props.user;
    if (isAuthenticated && authToken) {
      const decodedToken = jwt_decode(authToken);
      if (decodedToken.role === 'cs' || decodedToken.role === 'admin') {
        this.isCS = true;
      }
    }
    this.toggleCommunicationPreferencesModal = this.toggleCommunicationPreferencesModal.bind(this);
    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.toggleEmailLogModal = this.toggleEmailLogModal.bind(this);
    this.handleSeePostByUser = this.handleSeePostByUser.bind(this);
    this.productInPath = location.pathname.split('/')[2];
  }

  componentDidMount() {
    document.addEventListener('update-profile-image', e => {
      const {imgUrl} = e.detail;
      this.setState({imgUrl: imgUrl + '?akey=' + new Date().getTime()});
    });
  }

  toggleCommunicationPreferencesModal = () => {
    const {showCommunicationPreferencesModal} = this.state;
    this.setState({showCommunicationPreferencesModal: !showCommunicationPreferencesModal});
  };

  toggleEditPicModal = () => {
    const {showEditPicModal} = this.state;
    this.setState({showEditPicModal: !showEditPicModal});
  };

  toggleUserInfoModal = () => {
    const {showUserInfoModal} = this.state;
    this.setState({showUserInfoModal: !showUserInfoModal});
  };

  toggleChangePasswordModal = () => {
    const {showChangePasswordModal} = this.state;
    this.setState({showChangePasswordModal: !showChangePasswordModal});
  };
  toggleEmailLogModal = () => {
    const {showEmailLogModal} = this.state;
    this.setState({showEmailLogModal: !showEmailLogModal});
  };

  handleLogout = () => {
    this.props.logoutUser();
  };

  handleSeePostByUser() {
    // 2022-12-06 MQM: what does this do??
    const user = this.props.publicProfileUser || this.props.user;
    this.props.history.push(
      `/products/${this.productInPath}/community?getPostFrom=${user.id}&username=${user.firstName}`
    );
  }

  render() {
    // publicUser is explicitly passed via PublicProfile
    const {
      isCS,
      state: {
        showCommunicationPreferencesModal,
        showDMbutton,
        unsubscribeKey,
        showEditPicModal,
        showUserInfoModal,
        showChangePasswordModal,
        showEmailLogModal,
        imgUrl
      }
    } = this;

    const user = this.props.publicProfileUser || this.props.user;
    const {facebook, twitter, city, state, country, goal} = user.public || {};
    const {address, email, UserBadges, firstName, lastName, isFRNStaff} = user;
    const isMe = user.id === this.props.user.id;

    const locationString = [city, state, country].filter(Boolean).join(', ');

    return (
      <Container fluid className="my-5">
        <Row className={`${summaryImgInfo} align-items-center`}>
          <Col xs="12" lg="4" className={summaryImgBox} key={'2' + new Date().getTime()}>
            <div className={userAvatar} onClick={isMe ? this.toggleEditPicModal : null}>
              {imgUrl ? <img src={imgUrl} /> : null}
              {isMe ? <span>Change</span> : null}
            </div>
          </Col>
          <Col xs="12" lg="6" className={`${summaryInfo} text-center text-lg-left`} key={'3' + new Date().getTime()}>
            <h4 className={`${summaryLine} ${profileName}`}>
              {firstName} {isMe || isCS ? lastName : `${lastName.slice(0, 1)}.`}
            </h4>
            {locationString ? (
              <div className={`${summaryLine} ${profileLocation}`}>
                <FontAwesomeIcon icon={['far', 'map-marker-alt']} /> {locationString}
              </div>
            ) : null}
            {isCS && !!email ? (
              <div className={`${summaryLine}`}>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            ) : null}
            {facebook || twitter ? (
              <div className={profileSummarySocial}>
                {facebook ? (
                  <a href={`https://www.facebook.com/${facebook}`} target="_blank">
                    <FontAwesomeIcon icon={['fab', 'facebook-square']} color="#3B5998" size="2x" />
                  </a>
                ) : null}
                {twitter ? (
                  <a href={`https://twitter.com/${twitter}`} target="_blank">
                    <FontAwesomeIcon icon={['fab', 'twitter-square']} color="#1DA1F2" size="2x" />
                  </a>
                ) : null}
              </div>
            ) : null}
          </Col>
          {null && (
            <Col xs="12" sm="2" key={'4' + new Date().getTime()}>
              {UserBadges &&
                UserBadges.map(badge => {
                  return <Badge name={badge.BadgeName} url="https://via.placeholder.com/150x150" />;
                })}
            </Col>
          )}
        </Row>
        {goal ? (
          <Row>
            <Col xs="12" lg={{size: 8, offset: 4}} className={`${profileSummaryGoal}`} key={'5' + new Date().getTime()}>
              <small className="text-muted">#1 biggest goal in joining WHOLE Life Club</small>
              <p>{goal}</p>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col
            xs="12"
            lg={{size: 8, offset: 4}}
            className={`${profileSummaryButtons} align-items-center`}
            key={'5' + new Date().getTime()}
          >
            {!isMe && !isFRNStaff && showDMbutton && (
              <Button
                className="my-3"
                onClick={() => {
                  this.props.navigateToScreen(user.id);
                }}
              >
                Send direct message
              </Button>
            )}
            {isMe || isCS ? (
              <>
                <Button className="my-3" onClick={this.toggleCommunicationPreferencesModal}>
                  Update Communication Preferences
                </Button>
                <Button className="my-3" onClick={this.toggleUserInfoModal}>
                  Update Profile
                </Button>
                {isMe && <Button onClick={this.toggleChangePasswordModal}>Change Password</Button>}
                {!isMe && isCS && <AdminResetPassword userId={user.id} />}
                {(!isMe || isCS) && <Button onClick={this.toggleEmailLogModal}>Show Email Log</Button>}
                {isMe ? <Button onClick={this.handleLogout}>Sign Out</Button> : null}
                {null ? ( //to be revived
                  <Button onClick={this.handleSeePostByUser}>See All Posts By {firstName}</Button>
                ) : null}
              </>
            ) : null}
          </Col>
        </Row>

        {isCS && !isMe && (
          <div>
            {showUserInfoModal && (
              <EditUserInfoModal
                targetUser={this.state.forUser}
                isOpen={showUserInfoModal}
                toggle={this.toggleUserInfoModal}
                user={user}
                onSuccess={this.props.reloadUserData}
              />
            )}
          </div>
        )}
        {(isCS || isMe) && (
          <div>
            {showCommunicationPreferencesModal && (
              <CommunicationPreferences
                isOpen={showCommunicationPreferencesModal}
                toggle={this.toggleCommunicationPreferencesModal}
                user={user}
                callingUser={this.props.user}
                isCS={isCS}
                unsubscribeKey={unsubscribeKey}
              />
            )}
          </div>
        )}
        {isMe && (
          <div>
            {showUserInfoModal && (
              <EditUserInfoModal isOpen={showUserInfoModal} toggle={this.toggleUserInfoModal} user={user} />
            )}
            {showEditPicModal && (
              <EditPicModal isOpen={showEditPicModal} toggle={this.toggleEditPicModal} user={user} />
            )}
            {showChangePasswordModal && (
              <ChangePasswordModal
                isOpen={showChangePasswordModal}
                toggle={this.toggleChangePasswordModal}
                user={user}
              />
            )}
          </div>
        )}

        {isCS && showEmailLogModal && (
          <EmailLogModal
            targetUser={this.state.forUser}
            isOpen={showEmailLogModal}
            toggle={this.toggleEmailLogModal}
            user={user}
          />
        )}
      </Container>
    );
  }
}

Summary.propTypes = {};
//todo put back in
//   user: PropTypes.shape({
//     firstName: PropTypes.string.isRequired,
//     lastName: PropTypes.string.isRequired,
//     img: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired
//   }).isRequired,
//   publicUser: PropTypes.shape({
//     firstName: PropTypes.string,
//     lastName: PropTypes.string,
//     img: PropTypes.string
//   })
// };

Summary.defaultProps = {
  publicUser: null
};

export default withRouter(Summary);
