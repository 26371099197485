import React from 'react';
import {CardBody, Row, Col, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import VideoResource from 'routes/Products/components/VideoResource';

function Image(props) {
  return <img {...props} style={{maxWidth: '100%'}} />;
}

function LessonBody(props) {
  const {
    lesson,
    updateModuleStates,
    lessonID,
    moduleID,
    videoProgressReport,
    scrollToNextLesson,
    insideCard,
    isUnstructured
  } = props;
  const Tag = insideCard ? CardBody : React.Fragment;
  return (
    <React.Fragment>
      {lesson ? (
        <CardBody>
          {lesson.data.text || console.log('LESSON DATA', lesson) ? (
            <ReactMarkdown source={lesson.data.text} renderers={{image: Image}} />
          ) : null}
          {lesson.Children
            ? lesson.Children.map(contentItem => {
                switch (contentItem.type) {
                  case 'videoResource':
                    //   TODO remove border on bottom on video resource?
                    return (
                      <VideoResource
                        hideTitle={isUnstructured || contentItem.slug.includes('ftf-')}
                        {...contentItem}
                        showComments={false}
                        videoProgressReport={videoProgressReport}
                        key={contentItem.id}
                        WrapperTag="div"
                      />
                    );
                    break;
                  case 'markdown':
                    return <ReactMarkdown source={contentItem.data.text} renderers={{image: Image}} />;
                }
              })
            : null}
        </CardBody>
      ) : null}
      {isUnstructured ? null : (
        <Tag>
          <Row>
            <Col className="text-center">
              <Button
                color="success"
                className="py-4 pointerGlobal"
                onClick={() => {
                  updateModuleStates(moduleID, lessonID);
                  scrollToNextLesson();
                }}
              >
                MARK LESSON COMPLETE{' '}
              </Button>
            </Col>
          </Row>
        </Tag>
      )}
    </React.Fragment>
  );
}
export default LessonBody;
