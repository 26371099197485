import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'reactstrap';
import EditPic from 'routes/User/Profile/components/EditPicModal/components/EditPic';
import {actions} from 'modules/user';
import styles from 'styles/index.scss';

const {userAvatar} = styles;

@connect(state => ({user: state.user}))
class OnboardUserAvatar extends Component {
  constructor(props) {
    super(props);
    // const { user } = props;
    this.state = {
      showEditPic: false
    };
  }

  toggleEditPic = () => {
    this.setState({showEditPic: true});
  };

  render() {
    const {handleComplete, user} = this.props;
    const {showEditPic} = this.state;
    const avatar = user.avatarUrl ? user.avatarUrl + '?akey=' + new Date().getTime() : null;
    // set onboardcallback only called by wlc onboard
    return (
      <div>
        <h4>Set your Profile Picture</h4>
        <p className="text-muted">
          This picture will be displayed on posts made on the community forum and in your public profile.
        </p>
        {avatar && !showEditPic ? (
          <div>
            <h5 className="text-center">Your current profile picture:</h5>
            <div className={userAvatar} onClick={this.toggleEditPic}>
              <img src={avatar} />
            </div>
            <Row className="justify-content-center">
              <Button onClick={this.toggleEditPic} className="m-2" color="primary">
                Change it
              </Button>
              <Button onClick={() => this.props.handleComplete()} className="m-2">
                Keep it the same
              </Button>
            </Row>
          </div>
        ) : (
          <EditPic user={user} onSuccess={this.props.handleComplete} />
        )}
      </div>
    );
  }
}

OnboardUserAvatar.propTypes = {};

OnboardUserAvatar.displayname = 'OnboardUserAvatar';

export default OnboardUserAvatar;
