import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import ScrollableMarkdown from 'routes/Products/components/ScrollableMarkdown';
import styles from 'styles/index.scss';

const {wlcOnboardCommunityGuidelines} = styles;

class CommunityGuidelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAcceptButton: true
    };
  }

  toggleAcceptButton = e => {
    this.setState({
      disableAcceptButton: false
    });
  };

  render() {
    const {disableAcceptButton} = this.state;
    return (
      <div className={wlcOnboardCommunityGuidelines}>
        <ScrollableMarkdown
          style={{height: '300px'}}
          onScrollBottom={this.toggleAcceptButton}
          source={this.props.data.text}
        />
        <p className="text-center">
          <Button disabled={disableAcceptButton} onClick={() => this.props.handleComplete(this.props.id)}>
            I Agree
          </Button>
        </p>
        <p className="text-center">
          <small className="text-muted">Scroll to the bottom of the guidelines before continuing.</small>
        </p>
      </div>
    );
  }
}

CommunityGuidelines.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

CommunityGuidelines.displayname = 'CommunityGuidelines';

export default CommunityGuidelines;
