import React from 'react';
import VideoResource from '../../../../../../components/VideoResource';
import './styles.css';
// Function to determine the class for each step
const getStepClass = (stepNumber, currentStep) => {
  let returnedCSSClass = '';
  if (currentStep === stepNumber) {
    returnedCSSClass = 'active';
  }
  if (parseInt(currentStep - 1) == parseInt(stepNumber)) {
    returnedCSSClass = 'out-forward';
  }
  if (currentStep + 1 == stepNumber) {
    returnedCSSClass = 'out-backward';
  }

  return returnedCSSClass;
};
const NavButtons = ({handleNext, handlePrev, nextText, prevText, specialCase, layout, isForum}) => {
  return (
    <div
      className={`o-nav-buttons ${isForum ? 'forum-layout' : ''}`}
      style={{flexDirection: 'row', alignItems: 'center', justifyContent: layout ? layout : 'center'}}
    >
      {handlePrev && !specialCase ? (
        <div className="mx-2 px-3">
          <button className="btn btn-primary  rounded-pill px-5" onClick={handlePrev}>
            {prevText ? prevText : 'Previous'}
          </button>
        </div>
      ) : (
        ''
      )}

      {handleNext && !specialCase ? (
        <div className="mx-2 px-3">
          <button className="btn btn-primary  rounded-pill px-5" onClick={handleNext}>
            {nextText ? nextText : 'Next'}
          </button>
        </div>
      ) : (
        ''
      )}
      {specialCase == 'content-track' ? (
        <React.Fragment>
          <div className="mx-2 px-3">
            <button className="btn btn-primary w-100 rounded-pill px-5" onClick={handlePrev}>
              {prevText ? prevText : 'Previous'}
            </button>
          </div>
          <div className="mx-2 px-3">
            <button
              className="btn btn-primary w-100 rounded-pill px-5"
              onClick={() => handleNext({module: `general-onboarding-1`})}
            >
              Submit
            </button>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};
const Step = props => {
  const {
    additionalComponents,
    children,
    currentStep,
    footerComponent,
    handleNext,
    handlePrev,
    hideTitle,
    nextText,
    prevText,
    layout,
    isForum,
    specialCase,
    thisStep,
    videos,
    title
  } = props;

  return (
    <div className={`step ${getStepClass(thisStep, currentStep)}`}>
      {title && !hideTitle && <h5 className="my-5 mx-4">{title}</h5>}

      {currentStep === thisStep && videos && videos.length > 0
        ? videos.map((item, index) => (
            <div key={index}>
              <VideoResource {...item} showComments={false} />
            </div>
          ))
        : ''}
      {additionalComponents && additionalComponents({...props})}
      {(handleNext || handlePrev) && (
        <div className={`mx-2 d-flex justify-content-${layout ? layout : 'center'} `}>
          <NavButtons
            handleNext={handleNext}
            handlePrev={handlePrev}
            nextText={nextText}
            prevText={prevText}
            layout={layout}
            isForum={isForum}
            specialCase={specialCase}
          />
        </div>
      )}
      {children && <div>{children}</div>}
      <div className="o-divider"></div>
      {footerComponent && footerComponent({...props})}
    </div>
  );
};

export default Step;

export const //factory to create each step, binds values to the step
  createStep = function(stepProps) {
    return {
      ...stepProps,
      ...stepProps.otherProps
    };
  };
