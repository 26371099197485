export function isAlphaNumeric(inputtxt) {
  var letterNumber = /^[0-9a-zA-Z]+$/;
  if (inputtxt.match(letterNumber)) {
    return true;
  } else {
    return false;
  }
}
export function returnFirstAlphaNumeric(string) {
  let letter = null;
  for (let i = 0; i < string.length; i++) {
    if (isAlphaNumeric(string[i])) {
      letter = string[i];
      i = string.length;
    }
  }
  return letter;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
