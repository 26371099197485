/* eslint-disable */
/*LIST PRODUCT created to handle the WLC Challenge.
root.Children will be an array of lists.  Each list will represent a day.
It has a AppSync integration so it knows what list to show on a given day.
It could be augmented to create any product that is simply a list of Content Items*/
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {Switch, Route} from 'react-router-dom';
import {checkBreakpoint} from '../../../../lib/getResponsive';
import {actions} from 'modules/modal';
import {actions as userActions} from 'modules/user';
import RecipePage from '../../products/WLC/pages/Recipe';
import {withPhaseProvider} from 'lib/scheduleContext';
//import useAppSync from '../../../../lib/useAppSync';
import {product} from './data.js';
import {Container, Row, Col, Carousel} from 'reactstrap';
import VideoResource from '../../components/VideoResource';
import RecipesList from 'routes/Products/components/RecipesList';
import {ForumWrapper} from 'components/ForumWrapper';
import InternalAd from './components/InternalAd';
import RecipesPage from './pages/RecipesPage';
import CarouselV3 from '../../components/CarouselV3';

const HomePage = ({
  recipeCards,
  handleNav,
  highlightedComment,
  lists,
  productSlug,
  revokeDiscourseAPIKey,
  refreshToken,
  scrollToContent,
  slug,
  title,
  user,
  videoResourceIndex = 0
}) => {
  const currentList = lists[0];
  const videoCards = currentList.Children.filter(item => item.type === 'videoResource');
  const currentVideoResource = videoCards[videoResourceIndex];
  //const currentRecipes = currentList.Children.filter(item => item.type === 'recipe');
  console.log(currentList, videoCards, currentVideoResource);

  return (
    <Container>
      <Row>
        <Col>
          <VideoResource {...currentVideoResource} showComments={false} />
          {/*<RecipesList Children={currentRecipes} disableViewAll />*/}
        </Col>
      </Row>

      <CarouselV3
        cards={videoCards}
        darkText
        linkTo={`/products/${productSlug}/`}
        handleNav={handleNav}
        title={'Live Challenge Videos'}
        variant="light"
      />

      <CarouselV3
        cards={recipeCards}
        darkText
        linkTo={`/products/${productSlug}/recipes`}
        handleNav={handleNav}
        title={'Live Challenge Recipes'}
        variant="light"
      />
      {user && user.products && user.products.find(item => item.slug === 'wlc') ? null : (
        <InternalAd img={'https://cdn.foodrevolution.org/members/assets/img/iStock-1315353793.jpg'} />
      )}
      {productSlug !== 'wlc-lc' && (
        <div className="d-print-none mt-5 mx-5 forumWrapper">
          <Row>
            <Col>
              <ForumWrapper
                product={productSlug}
                scrollToMe={scrollToContent}
                slug={slug}
                pageTitle={title}
                passedReactRef={highlightedComment}
                user={user}
                refreshToken={refreshToken}
                revokeDiscourseAPIKey={revokeDiscourseAPIKey}
                forumType="ContentItem"
              />
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

/*const AppSyncHandler = ({scheduleUrl, subscribeKey, channel}) => {
  useAppSync();
  return <></>;
};*/

//THE HIGH LEVEL PRODUCT COMPONENTS BASICALLY BECOME ROUTE PRODUCERS.  ALL THE ROUTES ARE RENDERED HERE
class ListProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props, //importProductJson,
      today: Date.now(),
      disableRouter: false,
      currentContent: null,
      navItems: [{name: 'Home', typeOfComponent: null, slug: null, icon: faHome}], //used to show what nav items are present in top};
      carouselWidth: 1020
    };

    this.handleNav = this.handleNav.bind(this);
    this.setPages = this.setPages.bind(this);
    this.scrollToContent = this.scrollToContent.bind(this);
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
    this.highlightedComment = React.createRef();
  }

  scrollToContent() {
    setTimeout(() => {
      const offSet =
        this.highlightedComment.current && this.highlightedComment.current.scrollIntoView(false, {behavior: 'smooth'});
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  }

  componentDidMount() {
    // this.props.getUserProducts();
    console.log('this.props.user ', this.props.user);
    window.scrollTo(0, 0);
    window.onpopstate = this.history;
    this.setPages();
  }

  setPages() {
    const {product} = this.state;
    //get the lists for each day.  Pre challenge = index 0, first day = index 1
    //list = [videoResource, recipe, recipe]
    const lists = product.Children.filter(item => item.type === 'list');
    const videoResources = lists[0].Children.filter(item => item.type === 'videoResource');
    //get all recipes to create routes
    let allRecipes = lists.map(list => list.Children.filter(item => item.type === 'recipe'));
    allRecipes = _.flattenDeep(allRecipes);
    let recipeList = lists.find(list => list.Children.find(item => item.type === 'recipe'));
    this.setState({
      allRecipes,
      recipeList,
      lists,
      videoResources
    });
    return;
  }

  handleNav = (name, component, slug, slugTwo) => {
    //for navigating to a single recipe
    if (slug && slugTwo) {
      const path = `/products/${this.thisProductInPath}/${slug}/${slugTwo}`;
      this.props.history.push(path);
    }
    //navigating to a day or recipes page
    if (slug && !slugTwo) {
      this.props.history.push(`/products/${this.thisProductInPath}/${slug}`);
    }
    //navigate home
    if (!slug && !slugTwo) {
      const path = `/products/${this.thisProductInPath}`;
      this.props.history.push(path);
    }
    window.scrollTo(0, 0);
  };

  render() {
    const {
      allRecipes,
      carouselWidth,
      lists,
      navItems,
      product: {slug},
      recipeList,
      today,
      videoResources
    } = this.state;

    const {phase = 'before', day = 0} = this.props.phaseState.phases?.broadcast ?? {};
    console.log('MR_RENDER', videoResources);
    return (
      <div>
        {/* todo update for WLC challenge pubnub */}
        <Switch>
          {/* Individual Recipes  */}
          {allRecipes && allRecipes.length
            ? allRecipes.map(recipe => {
                return (
                  <Route
                    key={recipe.slug}
                    exact
                    path={`/products/${this.thisProductInPath}/${recipe.slug}`}
                    render={routeProps => (
                      <RecipePage
                        {...routeProps}
                        {...recipe}
                        navItems={navItems}
                        handleNav={this.handleNav}
                        recipes={recipeList.Children}
                        product={this.state.product}
                        currentContent={recipe}
                        singleRecipeInUrl={recipe.slug}
                        productPath={this.thisProductInPath}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* all recipes */}
          {recipeList && recipeList.Children && recipeList.Children.length ? (
            <Route
              key={'live-challenge-recipes'}
              exact
              path={`/products/${this.thisProductInPath}/recipes`}
              render={routeProps => (
                <RecipesPage
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  recipes={recipeList.Children}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {videoResources && videoResources.length
            ? videoResources.map((videoResource, idx) => {
                console.log(`/products/${this.thisProductInPath}/${videoResource.slug}`);
                return (
                  <Route
                    key={`live-challenge-${this.thisProductInPath}-${videoResource.slug}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${videoResource.slug}`}
                    render={routeProps => (
                      <HomePage
                        user={this.props.user}
                        product={product}
                        lists={lists}
                        title={'Live Challenge'}
                        slug={slug}
                        handleNav={this.handleNav}
                        today={today}
                        carouselWidth={carouselWidth}
                        productSlug={this.thisProductInPath}
                        refreshToken={this.props.refreshToken}
                        highlightedComment={this.highlightedComment}
                        scrollToContent={this.scrollToContent}
                        revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                        recipeCards={recipeList.Children}
                        videoCards={lists[0]}
                        videoResourceIndex={idx}
                      />
                    )}
                  />
                );
              })
            : null}
          {lists && lists.length ? (
            <Route
              key={`live-challenge-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}`}
              render={routeProps => (
                <HomePage
                  user={this.props.user}
                  product={product}
                  lists={lists}
                  title={'Live Challenge'}
                  slug={slug}
                  handleNav={this.handleNav}
                  today={today}
                  carouselWidth={carouselWidth}
                  productSlug={this.thisProductInPath}
                  refreshToken={this.props.refreshToken}
                  highlightedComment={this.highlightedComment}
                  scrollToContent={this.scrollToContent}
                  revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                  recipeCards={recipeList.Children}
                  videoCards={lists[0]}
                />
              )}
            />
          ) : (
            ''
          )}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data)),
  getUserProducts: callback => {
    dispatch(userActions.getUserProducts(callback));
  },
  refreshToken: userActions.refreshToken,
  revokeDiscourseAPIKey: userActions.revokeDiscourseAPIKey
});

const ConnectedListProduct = connect(mapStateToProps, mapDispatchToProps)(ListProduct);
const EnhancedListProduct = withPhaseProvider(withRouter(ConnectedListProduct));

export default EnhancedListProduct;
