/* eslint-disable */
import React, {Component} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import {SectionTriangle} from '../FRSCommon/Section';
import {ImgCard} from '../FRSCommon/ImgBullet';
import {FaqCard} from './components/FaqList';
import {ListIcon} from '../FRSCommon/IconList';
import SalesDisclaimer from './components/SalesDisclaimer';
import Footer from '../FRSCommon/Footer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import data from './data.json';

const {immersionKit} = data;

const CSS_URL = process.env.FTF_STYLESHEET_URL;

function ProductImage({
  className = '',
  img = 'https://cdn.foodrevolution.org/fftf/ftf-product-image-20230301.png',
  width = '1500',
  height = '625',
  altText = 'From Food to Freedom Immmersion Kit product image',
  maxHeight = null,
  ...props
}) {
  let styles = {
    maxHeight: `${maxHeight}px`,
    width: 'auto'
  };
  return (
    <>
      <img
        className={className}
        src={img}
        width={width}
        height={height}
        alt={altText}
        style={maxHeight ? styles : null}
      />
    </>
  );
}

function SectionSales({
  id,
  imgUrl = 'https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/immersion-bg-001.jpg',
  type = 'bottom',
  className = '',
  trees = false,
  lastSection = false,
  noTitle = false,
  noList = false,
  noImage = false,
  offerShort = false,
  processing = false,
  handleSuccess,
  handleDecline,
  ...props
}) {
  return (
    <>
      <SectionTriangle
        id={id}
        color="black"
        imgUrl={imgUrl}
        className="background-overlay-black-80 text-center"
        offset="bottom"
        type="none"
      >
        <Container>
          <Row>
            <Col>
              <div className="background-white p-4 p-lg-5 rounded box-shadow-black-50">
                {noTitle ? null : (
                  <h3 className="section-heading">
                    The <i>Food to Freedom</i> Immersion Kit
                  </h3>
                )}
                {noImage ? null : <ProductImage />}
                {noList ? null : (
                  <ListIcon>
                    <>
                      <i>From Food to Freedom</i> film: unlimited viewing
                    </>
                    <>Access to the 10-Day Immersion</>
                    <>Immersion Guidebook</>
                    <>40 Cook-Along Videos (with grocery lists and recipes) hosted by Kim Campbell</>
                    <>
                      Edited Full Interviews of the Experts <i>From From Food to Freedom</i> and <i>PlantPure Nation</i>
                    </>
                    <>A Professionally Produced 9 Lecture Series with Dr. T. Colin Campbell</>
                    <>60-Day 100% Money-Back Guarantee</>
                  </ListIcon>
                )}

                {offerShort ? (
                  <>
                    <h4 className="section-heading">
                      <i>One-time offer — 69% savings!</i>
                    </h4>
                    <h5 className="mt-0">Support Your Health and Our Mission</h5>
                    <h5 className="mt-0">And Get Immediate Unlimited Access to the Immersion Kit Today</h5>
                  </>
                ) : (
                  <>
                    <h5 className="section-heading">One-Time Offer</h5>
                    <p>*Offer Expires Once You Exit This Page*</p>
                    <h4 className="mt-0">By taking action NOW, you’ll receive over 69% off the regular price</h4>
                    <h3 className="section-heading mt-5 mb-3">
                      Normal: <del>$297</del>
                    </h3>
                    <h2 className="mt-0">
                      Add to your order now for just <span className="text-green">$90!</span>
                    </h2>
                    <h4 className="mt-0">
                      You’ve decided to own <i>From Food to Freedom</i>
                    </h4>
                    <p className="balance-text">
                      Once you close this page, you’ll miss this exclusive, majorly discounted offer for the{' '}
                      <i>Food to Freedom</i> Immersion Kit.
                    </p>
                  </>
                )}
                <Button
                  color="cta-ff"
                  onClick={handleSuccess}
                  disabled={processing}
                  className="offer-box-button offer-box-button-upsell text-base mb-5 btn btn-cta btn-lg"
                >
                  {processing ? (
                    'Loading...'
                  ) : (
                    <>
                      <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
                    </>
                  )}
                </Button>
                <p>Clicking "Yes" above will add The Immersion Kit to your purchase now for an additional $90.</p>
                <p>Your purchase comes with a risk-free, 60-day, total money-back guarantee.</p>
                <Button className="btn-upsell-gray mb-3" onClick={handleDecline}>
                  No, thanks.
                </Button>
                <p>Clicking the "No" button above will take you to the products already in your cart.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionTriangle>
      {trees ? (
        <SectionTriangle id={id + '-trees'} color="white" type={lastSection ? 'top' : 'both'}>
          <Container>
            <Row className="align-items-center">
              <Col lg="4" className="text-center order-lg-2 mb-4 mb-lg-0">
                <img
                  src="https://cdn.foodrevolution.org/global/trees-logo-color.png"
                  alt="Trees for the Future logo"
                  style={{maxHeight: '150px'}}
                />
              </Col>
              <Col lg="8" className="text-center text-lg-left order-lg-1">
                <h5 className="section-heading mb-2">Want a better world? We do, too!</h5>
                <p className="balance-text">
                  For every Immersion Kit purchased, we make a donation to Trees for the Future, enabling them to plant
                  another organic fruit or nut tree in a low-income community.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      ) : null}
    </>
  );
}

function ImmersionKit() {
  return (
    <>
      {immersionKit.map((item, i) => (
        <div key={'immersionKitItem-' + i} className="immersionKitItem mb-4">
          <Row className="align-items-center justify-content-center mb-4">
            <Col xs="12" lg="2">
              <img className="immersionKitItem-image" src={item.image} alt={item.imageAlt} />
            </Col>
            <Col xs="12" lg="10">
              <ReactMarkdown className="immersionKitItem-name" source={item.name} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactMarkdown className="immersionKitItem-text" source={item.text} />
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}

class FTFImpactKit extends Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
    this.handleDecline.bind(this);
    this.handleSuccess.bind(this);
  }

  handleDecline = e => {
    this.setState({processing: true});
    this.props.handleDeclineUpsell(e);
  };
  handleSuccess = e => {
    this.setState({processing: true});
    this.props.handleClickUpsell(e);
  };

  componentDidMount() {
    this.linkTag = document.createElement('link');

    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    document.getElementsByTagName('head')[0].appendChild(this.linkTag);
    this.styleTag = document.createElement('style');
    var css = 'html {font-size: unset !important}';
    this.styleTag.setAttribute('type', 'text/css');

    if ('textContent' in this.styleTag) {
      this.styleTag.textContent = css;
    } else {
      this.styleTag.styleSheet.cssText = css;
    }

    document.getElementsByTagName('head')[0].appendChild(this.styleTag);
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.styleTag && this.styleTag.remove();
  }

  render() {
    const {processing} = this.state;
    const {props} = this;
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className={classNames(`page`, `page-nosocial`, `d-flex`, `flex-column`)}>
        <div className="page-content">
          <SectionTriangle id="" color="white" type="bottom" className="section-triangle-first text-center">
            <Container>
              <Row>
                <Col>
                  <h4 className="section-heading">
                    Thank you for choosing to own <i>From Food to Freedom!</i>
                  </h4>
                  <p className="balance-text">
                    <b>We invite you to watch this short video</b> to see how you can <b>take the next step</b> for an
                    unbeatable price you’ll never see again.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="10" className="video-col px-0 px-sm-3">
                  <div className="video-wrapper">
                    <ReactPlayer
                      className="video-player"
                      width="100%"
                      height="100%"
                      controls={true}
                      url="https://youtu.be/BG_mSOF-f28"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="light-gray" type="bottom" className="text-center">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading">
                    Break the Shackles of Disease, Lackluster Health, and Disappointing Medical Treatments
                  </h3>
                  <h5 className="mt-0">With the Food to Freedom Immersion Kit</h5>
                  <ProductImage />
                  <p className="balance-text mt-4">
                    <b>
                      We spent years pouring our hearts and souls into telling the important story captured in{' '}
                      <i>From Food to Freedom.</i> We hope the film inspired you.
                    </b>
                  </p>
                  <p className="balance-text">
                    Now you can put it into action in your life — with the <i>Food to Freedom</i> Immersion Kit. Brought
                    to you by the non-profit PlantPure Communities, in partnership with Food Revolution Network
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionSales
            id="cta-1"
            noTitle
            noImage
            noList
            offerShort
            processing={processing}
            handleSuccess={this.handleSuccess}
            handleDecline={this.handleDecline}
          />

          <SectionTriangle id="" color="light-gray" type="bottom" className="text-center">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading">The conventional medical model is failing us.</h3>
                  <p>It’s no secret that modern healthcare is more like sick care.</p>
                  <p className="balance-text">
                    If it truly worked, we wouldn’t have billions of people dependent on medications that often only
                    treat the symptoms of disease — medications that can sometimes cause side effects that are worse
                    than the disease itself.
                  </p>
                  <p className="balance-text">
                    Yes, it’s true that in some cases, these medications can save lives. But they’re expensive, and
                    since they rarely cure the underlying conditions, it’s usually only a matter of time before disease
                    crops back up.
                  </p>
                  <p>
                    Just ask the average senior in the United States, who’s on four medications just to get through the
                    day.
                  </p>
                  <h4 className="mt-0">
                    What if there was a way to reverse the trend, and prevent or even sometimes reverse disease — 100%
                    naturally?
                  </h4>
                  <h5 className="mt-0">Even if…</h5>
                  <p>
                    …you don’t know where to start.
                    <br />
                    …you already have a diagnosis or two.
                    <br />
                    …disease runs in the family.
                  </p>
                  <p className="balance-text">
                    And yes, <i>especially</i> if you want to cease needing medications, lose weight, watch labs
                    improve, and age vibrantly instead of being beholden to increasing doctors’ appointments, surgeries,
                    expensive procedures, pain, discomfort, and health insurance hassles.
                  </p>
                  <h4 className="mt-0">
                    It’s time to rise above this tired old narrative and create REAL health that lasts.
                  </h4>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="white" className="text-center" type="bottom">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading mb-3">Introducing</h3>
                  <h2 className="mt-0 mb-3">
                    The <i>Food to Freedom</i> Immersion Kit
                  </h2>
                  <p className="balance-text">
                    The <i>Food to Freedom</i> Immersion Kit gives you the blueprint you need for transforming your
                    health, naturally.
                  </p>
                  <div className="d-flex justify-content-center mb-4">
                    <ListIcon className="text-left m-0">
                      <>
                        Easy instructions for <b>your own 10-day immersion.</b>
                      </>
                      <>
                        The exact foods to eat and how to prepare them <br className="d-none d-xl-block" />
                        so you’re <b>thrilled about mealtimes.</b>
                      </>
                      <>
                        <b>Hands-on</b> tactics.
                      </>
                      <>
                        Complete <b>shopping lists.</b>
                      </>
                      <>
                        Educational videos that make it simple <br className="d-none d-xl-block" />
                        to adopt <b>new healthy habits.</b>
                      </>
                      <>
                        Strategies for <b>making it STICK.</b>
                      </>
                    </ListIcon>
                  </div>
                  <p>
                    This isn’t just about giving you information. It’s about getting you <i>results.</i>
                  </p>
                  <Button
                    color="cta-ff"
                    onClick={this.handleSuccess}
                    disabled={processing}
                    className="offer-box-button offer-box-button-upsell text-base mb-5 btn btn-cta btn-lg"
                  >
                    {processing ? 'Loading...' : 'Buy Now'}
                  </Button>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id=""
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/immersion-bg-002.jpg"
            className="section-triangle-style-image-only"
            type="none"
          />

          <SectionTriangle id="" color="white" type="both">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center">
                    RESULTS FROM THE 10-DAY IMMERSION IN <i>From Food to Freedom</i>
                  </h3>
                  <p className="text-center">
                    In just 10 days, the 6 stars of the film experienced incredible results:
                  </p>
                  <div className="d-flex justify-content-center mt-4">
                    <ListIcon className="m-0">
                      <>Lost a total of 54 pounds</>
                      <>
                        Dropped LDL cholesterol (the kind that causes plaque <br className="d-none d-xl-block" />
                        to build in your arteries) by an average of 24%
                      </>
                      <>One was able to go off all 3 of their hypertension medications</>
                      <>
                        Four were able to stop diabetes medication completely <br className="d-none d-xl-block" />
                        (and the others drastically reduced their dosage)
                      </>
                      <>Normal blood sugar and blood pressure returned</>
                      <>One person stopped a total of 9 medicines</>
                      <>Multiple participants said they “Have a new lease on life”</>
                      <>One said “The Immersion opened up a new chapter of my life”</>
                    </ListIcon>
                  </div>
                  <p class="balance-text text-center mt-4">
                    <i>
                      You’re unique, and your results may differ, of
                      <br data-owner="balance-text" aria-hidden="true" />
                      course. What transformation might you experience?
                    </i>
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="immersion-truth" color="light-gray" type="bottom" className="text-center">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading">The Truth Is…</h3>
                  <p>You don’t have to spend decades figuring out what to eat for rapid healing.</p>
                  <p>You don’t have to spend thousands of dollars attending an in-person 10-day immersion.</p>
                  <p>You don’t have to gamble with your health.</p>
                  <p>
                    And you don’t have to take the word of someone with their hands in the pocket of the pharmaceutical
                    industry.
                  </p>
                  <p>We’ve distilled everything down to an efficient program that’s ready for you now.</p>
                  <p>
                    An immersion like we saw in the film costs a couple thousand dollars per person. With travel,
                    lodging, staffing, and taking time off work, it’s out of reach for many people. We wanted to make a
                    version you could do on your own (in conjunction with your medical advisor), for a fraction of the
                    price.
                  </p>
                  <p>We wanted to give you tools to stay on track with your healing long after ten days have passed.</p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id=""
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/immersion-bg-003.jpg"
            className="section-triangle-style-image-only"
            type="none"
          />

          <SectionTriangle id="" color="white" type="both">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">The Food to Freedom Immersion Kit Includes:</h2>
                  <ImmersionKit />
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionSales
            id="cta-2"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/immersion-bg-004.jpg"
            trees
            processing={processing}
            handleSuccess={this.handleSuccess}
            handleDecline={this.handleDecline}
          />

          <SectionTriangle id="" color="light-gray" type="bottom">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center">Frequently Asked Questions</h2>
                  <FaqCard />
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="black" type="bottom">
            <Container>
              <Row>
                <Col>
                  <div
                    className={classNames(
                      `guarantee-box rounded background-white box-shadow-black-50`,
                      props.className
                    )}
                  >
                    <div className="section-heading guarantee-heading text-center text-sm-left">
                      <h3 className="section-heading">60-Day Total Risk-Free Money-Back Guarantee</h3>
                    </div>
                    <p>
                      Take up to 60 days to soak up the entire Immersion Kit. We guarantee that you and your family will
                      love what you learn.
                    </p>
                    <p>
                      Your satisfaction is important to us, so if for any reason you aren’t 100% happy with your
                      purchase, you have 60 days to request a prompt and total refund.
                    </p>
                    <p>
                      Just email us at <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a> or
                      call us at (831) 824-4779.
                    </p>
                    <p>
                      If you request a refund for any reason, anything that you've already downloaded from the Impact
                      Kit is yours to keep.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionSales
            id="cta-final"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/immersion-bg-004.jpg"
            lastSection
            trees
            processing={processing}
            handleSuccess={this.handleSuccess}
            handleDecline={this.handleDecline}
          />
        </div>

        <div className="footer-content mt-auto">
          <Footer partner />
        </div>
      </div>
    );
  }
}

export default FTFImpactKit;
