/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {Switch, Route} from 'react-router-dom';
import CarouselV3 from '../../components/CarouselV3';

import FullPageLayout from '../../components/FullPageLayout';
import {checkBreakpoint} from '../../../../lib/getResponsive';
import FRNModal from '../../../Modal';
import {actions} from 'modules/modal';
import {actions as navActions} from 'modules/application';
import VideoResourcePage from '../../components/VideoResourcePage';
import UpgradeModal from './components/UpgradeModal';
import ViewAllPage from './components/ViewAllPage';
import Header from './components/Heading';
import WrapperBonuses from './components/BonusPage';
import RecipePage from './components/RecipePage';
import RecipesPage from './components/RecipesPage';
import ProductNavigation from 'routes/Products/components/ProductNavigation';
import AdvancedPackageCTA from './components/AdvancedPackageCTA';
import LiveVideoContainer from '../../components/VideoResourcePage/components/LiveVideoContainer';
import AmplifyCommentsSlideOut from '../../components/AmplifyCommentsSlideOut';


import {withPhaseProvider} from 'lib/scheduleContext';
import {useFunnelSchedule} from '../../../../lib/useAppSync';
import {usePhaseState} from '../../../../lib/scheduleContext';

// import HomePage from './components/HomePage';
const UPGRADE_SLUG = 'frs23-advanced-upgrade';
const HomePage = ({
  bonuses,
  cookingDemos,
  episodeToPlay,
  handleNav,
  product,
  productSlug,
  handleUpgradeModal,
  ownedProducts = [],
  upgradeSlug,
  videoResourcePages, //L1
  videoResourcesBroadcastReplays, //L1 replays
  videoResourcePagesL2,
  recipes,
  logoImg
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const phaseState = usePhaseState();
  const {day = 0, phase = 'before'} = phaseState.phases?.broadcast ?? {};
  const currentContentItem = videoResourcesBroadcastReplays.Children[day];

  return (
    <FullPageLayout>
      {/* reactstrap modal with methods */}
      <FRNModal />
      <UpgradeModal isOpen={isOpen} toggle={toggle} title={'Empowerment Package Title'} slug={'frs23-advanced'} />
      <AmplifyCommentsSlideOut />
      {['before', 'during'].includes(phase) ? (
        <LiveVideoContainer
          videoUrl={currentContentItem.Children.find(c => c.type === 'video' && c.slug.includes('replay')).data.url}
          commentFeed={{url: 'https://summit.foodrevolution.org/broadcasts/#frs24'}}
          title={currentContentItem.displayProps.title}
          subtitle={currentContentItem.displayProps.subtitle}
          description={currentContentItem.displayProps.description}
        />
      ) : (
        <Header
          {...product.displayProps}
          playSlug={episodeToPlay ? episodeToPlay : videoResourcePages.Children[0].slug}
          handleNav={handleNav}
          previewVideoUrl={product?.data?.previewVideoLink}
          hidePlayEpisode
          logoImg={logoImg}
        />
      )}

      {videoResourcesBroadcastReplays &&
      videoResourcesBroadcastReplays.Children &&
      videoResourcesBroadcastReplays.Children.length > 0 ? (
        <CarouselV3
          title={videoResourcesBroadcastReplays.title}
          linkTo={`/products/${productSlug}/view-all/broadcast-replays`}
          cards={videoResourcesBroadcastReplays.Children}
          handleNav={handleNav}
          variant="hero"
          live={['before', 'during'].includes(phase) ? [day] : []}
        />
      ) : null}

      {/* L1  L1  L1  L1  L1  L1  */}
      <CarouselV3
        title="Full Episodes"
        linkTo={`/products/${productSlug}/view-all/episodes`}
        cards={videoResourcePages.Children}
        handleNav={handleNav}
        variant={
          videoResourcesBroadcastReplays &&
          videoResourcesBroadcastReplays.Children &&
          videoResourcesBroadcastReplays.Children.length > 0
            ? ''
            : 'hero'
        }
        noDescription={
          videoResourcesBroadcastReplays &&
          videoResourcesBroadcastReplays.Children &&
          videoResourcesBroadcastReplays.Children.length > 0
        }
        live={[]}
      />

      {/* L2  */}
      <CarouselV3
        title="Extended Interviews"
        linkTo={`/products/${productSlug}/view-all/extended-interviews`}
        cards={videoResourcePagesL2.Children}
        handleNav={handleNav}
        noDescription
      />
      {productSlug === 'frs23' && !ownedProducts['frs23-advanced'] ? (
        <AdvancedPackageCTA openModal={() => handleUpgradeModal({upgradeSlug})} />
      ) : null}

      {cookingDemos && cookingDemos.Children && cookingDemos.Children.length > 0 ? (
        <CarouselV3
          title="Cooking Demos"
          linkTo={`/products/${productSlug}/view-all/cooking-demos`}
          cards={cookingDemos.Children}
          handleNav={handleNav}
          noDescription
          noMetadata
        />
      ) : null}

      {/* L1 broadcast replays */}
      {recipes && recipes.Children && recipes.Children.length > 0 ? (
        <CarouselV3
          title="Recipes"
          linkTo={`/products/${productSlug}/recipes`}
          cards={recipes.Children}
          handleNav={handleNav}
          noDescription
          noMetadata
        />
      ) : null}
      {bonuses && bonuses.Children && bonuses.Children.length > 0 ? (
        <CarouselV3
          title="Bonuses"
          linkTo={`/products/${productSlug}/bonuses`}
          cards={bonuses.Children}
          handleNav={handleNav}
          noDescription
          noMetadata
        />
      ) : null}
    </FullPageLayout>
  );
};

const AppSyncHandler = () => {
  useFunnelSchedule();
  return <></>;
};

@connect(state => ({user: state.user, application: state.application}), {
  openModal: actions.openModal,
  toggleProductNavigation: navActions.toggleProductNavigation
})

//THE HIGH LEVEL PRODUCT COMPONENTS BASICALLY BECOME ROUTE PRODUCERS.  ALL THE ROUTES ARE RENDERED HERE
class EmpowermentPackageV2 extends Component {
  constructor(props) {
    super(props);
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
    this.state = {
      product: props, //importProductJson, //props, //testAPIJson.ContentItem, //testData, //testAPIJson, ,
      slug: this.thisProductInPath,
      today: Date.now(),
      // + 10000000000000, //for testing
      disableRouter: false,
      currentContent: null,
      singleRecipeInUrl: null,
      modalIsOpen: false,
      navItems: [{name: 'Home', typeOfComponent: null, slug: null, icon: faHome}], //used to show what nav items are present in top};
      carouselWidth: 1020,
      upgradeModalIsOpen: false,
      upgradeSlug: this.thisProductInPath === 'frs23' ? UPGRADE_SLUG : this.thisProductInPath
    };
    console.log('EmpowermentPackageV2 this.state', this.state);

    this.handleNav = this.handleNav.bind(this);
    this.setPages = this.setPages.bind(this);
    this.updateState = this.updateState.bind(this);
    this.updateProductFromPurchase = this.updateProductFromPurchase.bind(this);
    this.getProductContent = this.getProductContent.bind(this);
    this.handleUpgradeModal = this.handleUpgradeModal.bind(this);

    this.navItems = [
      {
        name: 'Home',
        icon: 'faHome',
        path: `${this.thisProductInPath}`
      },
      {name: 'Episodes', icon: '', path: `${this.thisProductInPath}/view-all/episodes`},
      {name: 'Extended Interviews', icon: '', path: `${this.thisProductInPath}/view-all/extended-interviews`},
      // {name: 'Broadcast Replays', icon: '', path: `${this.thisProductInPath}/view-all/broadcast-replays`},
      {name: 'Recipes', icon: '', path: `${this.thisProductInPath}/recipes`},
      {name: 'Bonuses', icon: '', path: `${this.thisProductInPath}/bonuses`},
      {name: 'My Products', icon: '', path: ``}
    ];
  }
  componentDidMount() {
    const screen = checkBreakpoint();
    window.scrollTo(0, 0);
    window.onpopstate = this.history;
    this.getProductContent()
      .then(res => {
        //get the basic in data.ContentItem
        let {ContentItem, unlockableContentItems} = res;

        this.setState(
          {product: ContentItem, unlockableContentItems: unlockableContentItems ? unlockableContentItems : []},
          () => {
            this.setPages();
          }
        );
      })
      .catch(e => console.log('error getting product content', e));
  }
  updateState({property, value}) {
    this.setState({[property]: value});
  }

  setPages() {
    const {product, unlockableContentItems} = this.state;
    //We did some bad things for FRS23, so there are numerous checks to work around some "fixes"
    const isFRS23 = this.thisProductInPath === 'frs23';

    //v2 stuff
    //determine what this user owns, this will be used to pass in a flag for each video
    //to determine if it is locked or not
    let userProducts = this.props.user.products; //for clarity
    //TODO pull this out and read from the API
    let owned = {};
    if (isFRS23) {
      owned = {'frs-2024-ep': true, frs23: true, 'frs23-advanced': false, 'frs23-complete': false};
      //search through owned keys and determine if the user owns this product
      Object.keys(owned).find(key => {
        if (userProducts.find(userProduct => userProduct.slug === key)) {
          owned[key] = true;
        }
      });
    } else {
      owned = {[this.thisProductInPath]: true};
    }

    const listsInProduct =
      product.Children && product.Children.length
        ? product.Children.filter(item => {
            return item.type === 'list';
          })
        : [];

    //l1
    let videoResourcePages = listsInProduct.find(ci => ci.slug.endsWith('-episodes')); //order, first one is the produced videos

    //L1 replays (Fall Broadcasts)
    let videoResourcesBroadcastReplays = listsInProduct.find(ci => ci.slug.endsWith('-replays')) || [];

    let bonuses = listsInProduct.find(item => {
      return item.slug.includes('bonuses');
    });
    let cookingDemos = listsInProduct.find(item => {
      return item.slug.includes('cooking-demos');
    });
    let videoResourcePagesL2;
    if (isFRS23) {
      let l2EmpowermentPackage =
        unlockableContentItems && unlockableContentItems.length > 0
          ? unlockableContentItems.find(item => item && item.type === 'EmpowermentPackageV2')
          : null;

      videoResourcePagesL2 =
        l2EmpowermentPackage && l2EmpowermentPackage.Children
          ? l2EmpowermentPackage.Children.find(item => item.type === 'list')
          : [];
    } else {
      videoResourcePagesL2 = listsInProduct.find(ci => ci.slug.endsWith('-extended-interviews')) || [];
    }

    const RecipesGrid =
      product.Children &&
      product.Children.find(item => {
        return item.type === 'recipesList';
      })
        ? product.Children.find(item => {
            return item.type === 'recipesList';
          })
        : [];

    this.setState({
      RecipesGrid,
      bonuses,
      owned,
      cookingDemos,
      videoResourcePages,
      videoResourcesBroadcastReplays,
      videoResourcePagesL2,
      nav: 'Home'
    });
    console.log('setPages', {
      RecipesGrid,
      bonuses,
      owned,
      videoResourcePages,
      videoResourcesBroadcastReplays,
      videoResourcePagesL2,
      nav: 'Home'
    });
    return;
  }
  componentDidUpdate(prevProps) {
    if (this.props.user.products !== prevProps.user.products) {
      //product has been upgraded
      this.updateProductFromPurchase(); //reloads the whole product using the content id
    }
    return;
  }

  handleNav = (name, component, slug, slugTwo) => {
    //for navigating to a single recipe
    if (slug && slugTwo) {
      const path = `/products/${this.thisProductInPath}/${slug}/${slugTwo}`;
      this.props.history.push(path);
    }
    //navigating to a day or recipes page
    if (slug && !slugTwo) {
      this.props.history.push(`/products/${this.thisProductInPath}/${slug}`);
    }
    //navigate home
    if (!slug && !slugTwo) {
      const path = `/products/${this.thisProductInPath}`;
      this.props.history.push(path);
    }
    window.scrollTo(0, 0);
  };
  getBonusesContent = bonusSlug => {
    const {authToken} = JSON.parse(localStorage.getItem('user'));
    return Api.get(`/products/${bonusSlug}/content`, authToken);
  };
  getProductContent = () => {
    let {slug} = this.state; //get content id for this produc
    const {authToken} = JSON.parse(localStorage.getItem('user'));
    if (!slug) slug = this.state.slug; //fall back to frs23
    // /products/frs23-basic/content
    return Api.get(`/products/${slug}/content`, authToken);
  };

  /*New for FRS23
   Needed because uses can upgrade their product within this page
  */
  updateProductFromPurchase = () => {
    this.getProductContent()
      .then(res => {
        //get the basic in data.ContentItem
        let {ContentItem, unlockableContentItems} = res;

        this.setState(
          {product: ContentItem, unlockableContentItems: unlockableContentItems ? unlockableContentItems : []},
          () => {
            this.setPages();
          }
        );
      })
      .catch(e => console.log('error getting product content', e));
  };
  handleUpgradeModal = ({upgradeSlug}) => {
    const slug = upgradeSlug ? upgradeSlug : UPGRADE_SLUG; //fallback to the default upgrade slug
    this.setState(prev => {
      return {showUpgradeModal: !prev.showUpgradeModal, upgradeSlug: slug};
    });
  };

  render() {
    const {
      bonuses,
      carouselWidth,
      cookingDemos,
      product,
      navItems,
      today,
      RecipesGrid,
      videoResourcePages, //L1
      videoResourcesBroadcastReplays, //L1
      videoResourcePagesL2,
      upgradeSlug
    } = this.state;

    const {phase = 'before', day = 0} = this.props.phaseState.phases?.broadcast ?? {};
    const isFRS23 = this.thisProductInPath === 'frs23';
    const logoImg = `https://cdn.foodrevolution.org/${this.thisProductInPath}/${this.thisProductInPath}-docuseries-logo-white.png`;

    console.log('PHASE STATE', this.props.phaseState, phase, day);

    return (
      <div>
        <AppSyncHandler />
        <ProductNavigation
          isOpen={this.props.application.productNavigationIsOpen}
          toggleMenu={this.props.toggleProductNavigation}
          navItems={this.navItems}
        />
        <UpgradeModal isOpen={this.state.showUpgradeModal} toggle={this.handleUpgradeModal} slug={upgradeSlug} />
        <Switch>
          {bonuses && bonuses.Children && bonuses.Children.length ? (
            <Route
              key={`bonuses-${this.thisProductInPath}-empowerment-package-2023-bonuses-bonuses`}
              exact
              path={`/products/${this.thisProductInPath}/empowerment-package-2023-bonuses-bonuses`}
              render={routeProps => (
                <WrapperBonuses
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  bonuses={bonuses}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                  showAllBonuses
                />
              )}
            />
          ) : null}
          {product && videoResourcePages && RecipesGrid ? (
            <Route
              key={`EmpowermentPackage-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}`}
              render={routeProps => (
                <HomePage
                  product={product}
                  handleNav={this.handleNav}
                  handleUpgradeModal={this.handleUpgradeModal}
                  upgradeSlug={UPGRADE_SLUG}
                  ownedProducts={this.state.owned}
                  today={today}
                  cookingDemos={cookingDemos}
                  videoResourcePages={videoResourcePages}
                  videoResourcesBroadcastReplays={videoResourcesBroadcastReplays}
                  videoResourcePagesL2={videoResourcePagesL2 ? videoResourcePagesL2 : []}
                  carouselWidth={carouselWidth}
                  bonuses={bonuses}
                  recipes={RecipesGrid}
                  productSlug={this.thisProductInPath}
                  logoImg={logoImg}
                />
              )}
            />
          ) : (
            ''
          )}

          {/* Routes single episode, VideoResourcePages in L1 L1 L1 L1 L1 */}
          {videoResourcePages
            ? videoResourcePages.Children.map((item, index) => {
                return (
                  <Route
                    key={`${item.title}-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${item.slug}`}
                    render={routeProps => (
                      <VideoResourcePage
                        {...routeProps}
                        navItems={navItems ? navItems : []}
                        handleNav={this.handleNav}
                        {...item}
                        productPath={this.thisProductInPath}
                        upgradeSlug={UPGRADE_SLUG}
                        handleUpgradeModal={this.handleUpgradeModal}
                        owned={!isFRS23 || this.state.owned['frs23']}
                        list={videoResourcePages.Children}
                        logoImg={logoImg}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* Routes single episode, Broadcast Replays in L1 L1 L1 L1 L1 */}
          {videoResourcesBroadcastReplays &&
          videoResourcesBroadcastReplays.Children &&
          videoResourcesBroadcastReplays.Children.length
            ? videoResourcesBroadcastReplays.Children.map((item, index) => {
                return (
                  <Route
                    key={`${item.id}-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${item.slug}`}
                    render={routeProps => (
                      <VideoResourcePage
                        {...routeProps}
                        navItems={navItems ? navItems : []}
                        handleNav={this.handleNav}
                        {...item}
                        productPath={this.thisProductInPath}
                        upgradeSlug={UPGRADE_SLUG}
                        handleUpgradeModal={this.handleUpgradeModal}
                        owned={!isFRS23 || this.state.owned['frs23']}
                        list={videoResourcePages.Children}
                        isLive={day === index && ['before', 'during'].includes(phase)}
                        live={['before', 'during'].includes(phase) ? [day] : []}
                        logoImg={logoImg}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* View all episodes  L1 basic */}
          {videoResourcePages && videoResourcePages.Children && videoResourcePages.Children.length ? (
            <Route
              key={`view-all-episodes-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/view-all/episodes`}
              render={routeProps => (
                <ViewAllPage
                  title={'All Episodes'}
                  {...routeProps}
                  navItems={navItems ? navItems : []}
                  handleNav={this.handleNav}
                  list={videoResourcePages.Children} //
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {/* View all episodes  L1 broadcast replays */}
          {videoResourcesBroadcastReplays &&
          videoResourcesBroadcastReplays.Children &&
          videoResourcesBroadcastReplays.Children.length ? (
            <Route
              key={`view-all-episodes-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/view-all/broadcast-replays`}
              render={routeProps => (
                <ViewAllPage
                  title={'All Broadcast Replays'}
                  {...routeProps}
                  navItems={navItems ? navItems : []}
                  handleNav={this.handleNav}
                  list={videoResourcesBroadcastReplays.Children} //
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {/* VIEW ALL L2 L2 L2 L2  L2 L2 L2 L2 L2 L2 L2 L2 videos */}
          {videoResourcePagesL2 && videoResourcePagesL2.Children ? (
            <Route
              key={`view-all-episodes-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/view-all/extended-interviews`}
              render={routeProps => {
                return (
                  <ViewAllPage
                    title={'All Extended Interviews'}
                    {...routeProps}
                    navItems={navItems ? navItems : []}
                    handleNav={this.handleNav}
                    list={videoResourcePagesL2.Children}
                    productPath={this.thisProductInPath}
                  />
                );
              }}
            />
          ) : null}

          {/* L2 L2 L2 L2 L2 L2 L2 Single Episode */}
          {videoResourcePagesL2 && videoResourcePagesL2.Children
            ? videoResourcePagesL2.Children.map((item, index) => {
                return (
                  <Route
                    key={`${item.id}-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${item.slug}`}
                    render={routeProps => (
                      <VideoResourcePage
                        {...routeProps}
                        navItems={navItems ? navItems : []}
                        handleNav={this.handleNav}
                        {...item}
                        productPath={this.thisProductInPath}
                        // TODO figure out where this lives in the api content, also causes a problem if the videoresourcepage wanted to hit the api itself
                        upgradeSlug={UPGRADE_SLUG}
                        handleUpgradeModal={this.handleUpgradeModal}
                        owned={!isFRS23 || this.state.owned['frs23-advanced']}
                        customPlayButton="Play Interview"
                        customPreviewButton="Preview This Interview"
                        expandBios={true}
                        unlockableProductName="Advanced Empowerment Package"
                        logoImg={logoImg}
                      />
                    )}
                  />
                );
              })
            : null}
          {cookingDemos && cookingDemos.Children && cookingDemos.Children.length ? (
            <Route
              key={`view-all-cooking-demos-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/view-all/cooking-demos`}
              render={routeProps => (
                <ViewAllPage
                  title={'All Cooking Demos'}
                  {...routeProps}
                  navItems={navItems ? navItems : []}
                  handleNav={this.handleNav}
                  list={cookingDemos.Children} //
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {cookingDemos && cookingDemos.Children
            ? cookingDemos.Children.map((item, index) => {
                return (
                  <Route
                    key={`${item.id}-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${item.slug}`}
                    render={routeProps => (
                      <VideoResourcePage
                        {...routeProps}
                        navItems={navItems ? navItems : []}
                        handleNav={this.handleNav}
                        {...item}
                        productPath={this.thisProductInPath}
                        upgradeSlug={UPGRADE_SLUG}
                        handleUpgradeModal={this.handleUpgradeModal}
                        owned={!isFRS23 || this.state.owned['frs23-advanced']}
                        customPlayButton="Play Cooking Demo"
                        expandBios={true}
                        logoImg={logoImg}
                        showVideoModal
                      />
                    )}
                  />
                );
              })
            : null}
          {/* RECIPES GRID */}
          {RecipesGrid ? (
            <Route
              key={`recipes-grid-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/recipes`}
              render={routeProps => (
                <RecipesPage
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  {...RecipesGrid}
                  product={this.state.product}
                  currentContent={RecipesGrid}
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {/* SINGLE RECIPE ROUTES */}
          {RecipesGrid && RecipesGrid.Children
            ? RecipesGrid.Children.map((recipe, index) => {
                return (
                  <Route
                    key={recipe.id}
                    exact
                    path={`/products/${this.thisProductInPath}/${recipe.slug}`}
                    render={routeProps => (
                      <RecipePage
                        {...routeProps}
                        navItems={navItems}
                        handleNav={this.handleNav}
                        recipes={RecipesGrid.Children}
                        product={this.state.product}
                        currentContent={recipe}
                        singleRecipeInUrl={recipe.slug}
                        productPath={this.thisProductInPath}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* BONUSES ROUTE */}
          {bonuses && bonuses.Children && bonuses.Children.length ? (
            <Route
              key={`bonuses-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/bonuses`}
              render={routeProps => (
                <WrapperBonuses
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  bonuses={bonuses}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                  showAllBonuses
                />
              )}
            />
          ) : null}

          {/* individual bonuses all go to to the bonuses page */}
          {bonuses && bonuses.Children && bonuses.Children.length
            ? bonuses.Children.map((item, index) => {
                return (
                  <Route
                    key={`bonuses-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/${item.slug}`}
                    render={routeProps => (
                      <>
                        {item.type === 'videoResource' ? (
                          <VideoResourcePage
                            {...routeProps}
                            navItems={navItems ? navItems : []}
                            handleNav={this.handleNav}
                            {...item}
                            productPath={this.thisProductInPath}
                            owned={!isFRS23 || this.state.owned['frs23']}
                            hideTimer={false}
                            logoImg={logoImg}
                            showComments={item.slug.includes('ask-the-experts')}
                            isLive={item.slug.includes('ask-the-experts') && this.thisProductInPath === 'frs-2024-ep'}
                          />
                        ) : item.type === 'bonuses' ? (
                          <WrapperBonuses bonuses={item} />
                        ) : null}
                      </>
                    )}
                  />
                );
              })
            : null}
        </Switch>
      </div>
    );
  }
}
EmpowermentPackageV2.displayname = 'EmpowermentPackage';

export default withPhaseProvider(withRouter(EmpowermentPackageV2));
