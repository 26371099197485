import React from 'react';
import pure from 'recompose/pure';

let TNTG = (white, ...props) => (
	<svg
		{...props}
		width="50"
		height="40"
		viewBox="-50 0 600 600"
		dangerouslySetInnerHTML={{
			__html: `<path d="M20.547 74.012H2.523v-15.34h52.559v15.34H37.145v53.066H20.547M117.11 58.672v68.406h-16.598v-26.68H77.879v26.68H61.285V58.672H77.88v25.7h22.633v-25.7M175.953 112.125v14.953h-47.105V58.672h46.015v14.95h-29.59v11.53h26.07v14.465h-26.07v12.508M265.39 58.672v68.406h-13.66l-25.902-36.45v36.45h-16.262V58.672h13.664l25.899 36.45v-36.45M324.234 112.125v14.953H277.13V58.672h46.016v14.95h-29.586v11.53h26.07v14.465h-26.07v12.508M380.563 112.125v14.953H333.46V58.672h46.016v14.95h-29.59v11.53h26.066v14.465h-26.066v12.508M416.863 111.637c9.973 0 16.676-6.938 16.676-18.766 0-11.82-6.703-18.762-16.676-18.762h-10.48v37.528zM389.79 58.672h27.742c19.446 0 32.774 13.191 32.774 34.2 0 21.015-13.328 34.206-32.774 34.206H389.79M495.906 74.012h-18.02v-15.34h52.551v15.34h-17.933v53.066h-16.598M581.234 92.871c0-11.82-6.957-19.543-15.84-19.543-8.886 0-15.843 7.723-15.843 19.543 0 11.828 6.957 19.547 15.844 19.547 8.882 0 15.84-7.719 15.84-19.547m-48.446 0c0-20.52 13.828-35.57 32.606-35.57C584.168 57.3 598 72.35 598 92.87c0 20.524-13.832 35.574-32.605 35.574-18.778 0-32.606-15.05-32.606-35.574" fill="#FFF"></path>
      <path d="M79.094 525.234C75.727 535.445 62.598 542.7 47.78 542.7 11.761 542.7 2 514.49 2 407.555V282.89C2 174.887 13.781 147.75 59.566 147.75c40.399 0 53.192 29.285 50.832 111.23l-44.097 2.149c2.02-72.274-1.348-75.766-6.735-75.766-7.742 0-9.09 3.492-9.09 101.02v117.68c0 97.527 1.348 101.023 9.09 101.023 7.07 0 8.754-3.496 8.754-78.992v-28.211h-9.425v-36.54h57.902v178.669H79.766v-14.778M192.879 329.371c6.398 0 10.773-10.746 10.773-61.258v-22.031c0-50.508-4.375-58.031-10.773-58.031h-7.406v141.32zm69.351 210.64h-48.476L198.94 397.349c-2.02-21.496-5.386-28.75-13.129-28.75h-.34v171.414h-48.476V150.438h54.535c40.399 0 60.598 18.804 60.598 89.734v32.777c0 41.645-7.066 67.168-20.871 81.41v.27c8.754 8.328 12.793 20.957 14.812 42.45M333.938 404.063v-117.68c0-97.528-1.348-101.02-9.09-101.02-7.743 0-9.09 3.492-9.09 101.02v117.68c0 97.527 1.347 101.019 9.09 101.019 7.742 0 9.09-3.492 9.09-101.02m-66.657 3.493V282.89c0-108.004 11.781-135.141 57.567-135.141 45.785 0 57.566 27.137 57.566 135.14v124.665c0 108.007-11.781 135.144-57.566 135.144-45.786 0-57.567-27.136-57.567-135.144M495.527 341.46c-.336-4.565-1.007-14.772-1.007-21.222h-1.348c0 6.45-.672 16.657-1.008 21.223l-16.836 198.55H426.18l-32.657-389.573h48.48l8.75 188.605c.337 8.062 1.013 26.602 1.013 38.422h.671c0-11.82 1.348-30.36 2.36-38.422l21.543-188.606h35.012l21.543 188.606c1.011 8.062 2.359 26.602 2.359 38.422h.672c0-11.82.672-30.36 1.011-38.422l8.75-188.606h48.477l-32.652 389.575h-49.153" fill="#FFF"></path>
      
      `
		}}
	/>
);

TNTG = pure(TNTG);

export default TNTG;
