import React from 'react';
import pure from 'recompose/pure';

let WLC = props => {
  const {white} = props;
  return (
    <svg
      {...props}
      width={props.width || '144.67'}
      height={props.height || '146.37'}
      viewBox="0 45 150 24.87"
      dangerouslySetInnerHTML={{
        __html: `<path d="M32.14 123.15h-6.85L20.42 105l-5 18.13H8.58L0 96.93h7.12l5.13 19.29 5-19.29h6.52l5.13 19.29L34 96.93h6.81zm35.21 0h-6.67V113H49.82v10.19h-6.67V96.93h6.67v10.79h10.86V96.93h6.67zM85 96.71a14.25 14.25 0 0 1 10.15 3.8 12.48 12.48 0 0 1 4 9.5 12.66 12.66 0 0 1-4.06 9.58A14.12 14.12 0 0 1 85 123.45a14.12 14.12 0 0 1-10.11-3.84 12.68 12.68 0 0 1-4-9.6 12.46 12.46 0 0 1 4-9.52A14.25 14.25 0 0 1 85 96.71zm.08 5.58a7 7 0 0 0-5.24 2.23 7.58 7.58 0 0 0-2.15 5.48 7.69 7.69 0 0 0 2.19 5.56 7 7 0 0 0 5.23 2.27 6.76 6.76 0 0 0 5.15-2.27 7.84 7.84 0 0 0 2.12-5.56 7.69 7.69 0 0 0-2.12-5.52 6.82 6.82 0 0 0-5.15-2.19zm24.34 15.28h11.12v5.58h-17.79V96.93h6.67zm34.84-15.39H130.4v5.2h12.51v5.25H130.4v5.28h14.27v5.24h-20.94V96.93h20.53zM27.13 144.51h5v1.78h-7v-11.61h1.95zm10.52 1.78h-2v-11.61h2zm12.49-9.85h-5.82v3.35h5.27v1.74h-5.27v4.76h-2v-11.61h7.8zm11.71 0h-6.33v3.13h5.67v1.76h-5.67v3.2h6.53v1.76h-8.49v-11.61h8.29zm15.33-1.81a6.17 6.17 0 0 1 2.39.47 5.8 5.8 0 0 1 2 1.32l-1.14 1.42a4.73 4.73 0 0 0-1.5-1.07 4.16 4.16 0 0 0-4.68.78 4.09 4.09 0 0 0 0 5.81 4 4 0 0 0 2.94 1.19 4.55 4.55 0 0 0 3.24-1.38l1.16 1.29a7 7 0 0 1-2.09 1.4 6.1 6.1 0 0 1-2.41.51 6 6 0 0 1-4.3-1.7 5.63 5.63 0 0 1-1.74-4.2 5.51 5.51 0 0 1 1.77-4.16 6.06 6.06 0 0 1 4.36-1.68zm10.03 9.88h5v1.78h-7v-11.61h1.95zm18.43-9.83v6.8a4.75 4.75 0 0 1-1.34 3.58 5.89 5.89 0 0 1-7.41 0 4.76 4.76 0 0 1-1.35-3.58v-6.8h2v6.8a2.89 2.89 0 0 0 3.12 3.13 3 3 0 0 0 2.24-.83 3.12 3.12 0 0 0 .83-2.3v-6.8zm4.47 0h5a4.68 4.68 0 0 1 2.85.77 2.49 2.49 0 0 1 1 2.13 2.64 2.64 0 0 1-.55 1.67 2.68 2.68 0 0 1-1.49.92 2.81 2.81 0 0 1 2.48 2.93 2.75 2.75 0 0 1-1.12 2.34 5.06 5.06 0 0 1-3.11.85h-5.15zm2 1.74v3.07h3a2.3 2.3 0 0 0 1.46-.42 1.37 1.37 0 0 0 .53-1.14 1.3 1.3 0 0 0-.52-1.12 2.42 2.42 0 0 0-1.47-.39zm0 4.79v3.34h3a3.19 3.19 0 0 0 1.8-.44 1.46 1.46 0 0 0 .64-1.26 1.39 1.39 0 0 0-.65-1.2 3.18 3.18 0 0 0-1.79-.44z" fill="${
          white ? '#FFF' : '#00263e'
        }"/>
        <path d="M37.16 36.53A2.88 2.88 0 0 1 39.72 35h13a2.88 2.88 0 0 1 2.38 1.2l14.37 20.46a2.89 2.89 0 0 1 0 3.32L63 69.24a2.89 2.89 0 0 1-4.72 0L37.36 39.52a2.9 2.9 0 0 1-.2-2.99zm70.35 0A2.88 2.88 0 0 0 105 35H92a2.88 2.88 0 0 0-2.36 1.23L75.2 56.66a2.89 2.89 0 0 0 0 3.32l6.51 9.26a2.89 2.89 0 0 0 4.72 0l20.88-29.72a2.9 2.9 0 0 0 .2-2.99z" fill="${
          white ? '#FFF' : '#e87200'
        }/>
        <path  d="M72.16 16.45a15 15 0 0 1 2.24.17C75.73 11.92 77.17 6.5 77.17 5A5 5 0 0 0 73.52.19 5.08 5.08 0 0 0 72.16 0a5.22 5.22 0 0 0-1.38.19A5 5 0 0 0 67.14 5c0 1.48 1.44 6.9 2.78 11.6a14.84 14.84 0 0 1 2.24-.15z" fill="${
          white ? '#FFF' : '#4c9c2e'
        }"/>
        <path d="M83.29 28.11c-3.58-11-20-10.72-22.57 1.13-1.11 5.08 2.28 9.52 4.78 13.12 1 1.37 2 2.67 3 4 .83 1 1.78 2.57 3 2.94s2.09-.37 2.9-1.33Q76 46 77.57 44c3.19-4.14 7.7-9.79 5.72-15.89z" fill="${
          white ? '#FFF' : '#9a004f'
        }"/>
        <path  d="M66.73 17.53c-1-2.91-3.71-8.84-4.5-9.63a4.41 4.41 0 0 0-5.29-.73 4 4 0 0 0-1 .73 4.8 4.8 0 0 0-.74 1 4.44 4.44 0 0 0 .74 5.3c.73.73 5.92 3.14 9 4.32a13.54 13.54 0 0 1 1.79-.99zm22.35-8.66a4.8 4.8 0 0 0-.74-1 4 4 0 0 0-1-.73 4.41 4.41 0 0 0-5.29.73c-.79.79-3.47 6.72-4.5 9.63a13.54 13.54 0 0 1 1.81 1c3-1.18 8.22-3.59 9-4.32a4.44 4.44 0 0 0 .72-5.31z" fill="${
          white ? '#FFF' : '#4c9c2e'
        }" />`
      }}
    />
  );
};

WLC = pure(WLC);

export default WLC;
