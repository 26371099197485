import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/modal';
import {actions as userActions} from 'modules/user';
import LoginModal from './Login';
import OrderConfirmModal from './OrderConfirm';
import ResetPasswordModal from './Reset';
import RegisterModal from './Register';
import SpinnerModal from './Spinner';
import UpsellModal from './Upsell';

@connect(
  state => ({
    animation: state.modal.animation,
    email: state.user.email,
    modalType: state.modal.modalType,
    opts: state.modal.opts,
    showModal: state.modal.showModal,
    step: state.modal.step
  }),
  {
    createUser: userActions.createUser,
    hideModal: actions.hideModal,
    loginUser: userActions.loginUser,
    openModal: actions.openModal,
    sendMagicLink: userActions.sendMagicLink,
    verifyEmail: userActions.verifyEmail,
    viewStep: actions.viewStep
  }
)
class ModalRoutes extends Component {
  static propTypes = {
    showModal: PropTypes.bool,
    step: PropTypes.number,
    modalType: PropTypes.string,
    hideModal: PropTypes.func
  };

  renderModalBody(type) {
    switch (type) {
      case 'login':
        return <LoginModal {...this.props} />;
      case 'orderConfirm':
        return <OrderConfirmModal />;
      case 'register':
        return <RegisterModal {...this.props} />;
      case 'reset':
        return <ResetPasswordModal {...this.props} />;
      case 'spinner':
        return <SpinnerModal {...this.props} />;
      case 'upsell':
        return <UpsellModal {...this.props} />;
      default:
        return null;
    }
  }

  render() {
    return this.renderModalBody(this.props.modalType);
  }
}

export default ModalRoutes;
