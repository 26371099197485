/* eslint-disable quotes */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {actions} from 'modules/admin';

@connect(
  () => ({}),
  {resetPassword: actions.resetUserPassword}
)
class AdminResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.userId = props.userId;
    this.defaultState = {
      disableButton: false,
      cancelButton: 'No',
      confirmButton: 'Yes',
      message: `Are you sure you want to reset this user's password?`
    };
    this.state = {
      isOpen: false,
      ...this.defaultState
    };
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen, ...this.defaultState});
  };

  resetPassword = async () => {
    this.setState({disableButton: true});
    const passwordIsReset = await this.props.resetPassword(this.userId);
    // const passwordIsReset = false;

    if (passwordIsReset) {
      this.setState({
        disableButton: false,
        confirmButton: '',
        cancelButton: 'Ok',
        message: "Success! The user's password has been reset and sent to their email address."
      });
    } else {
      this.setState({
        disableButton: false,
        confirmButton: '',
        cancelButton: 'Ok',
        message: `We're sorry! We were unable to process this request.`
      });
    }
  };

  renderModal = () => {
    const {isOpen, cancelButton, confirmButton, disableButton, message} = this.state;

    return (
      <Modal centered isOpen={isOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Reset Password</ModalHeader>
        <ModalBody>
          <div>{message}</div>
        </ModalBody>
        <ModalFooter>
          {cancelButton && (
            <Button onClick={this.toggle} color="light">
              {cancelButton}
            </Button>
          )}
          {confirmButton && (
            <Button onClick={this.resetPassword} disabled={disableButton} color="primary">
              {confirmButton}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return [<Button onClick={this.toggle}>Reset User Password</Button>, this.renderModal()];
  }
}

AdminResetPassword.propTypes = {
  userId: PropTypes.number.isRequired
};

export default AdminResetPassword;
