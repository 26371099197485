import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const ToggleIcons = ({checked, icons, color, ...props}) => {
  const defensiveColor = color && !Array.isArray(color) ? [color, color] : color;
  let determinedColor;
  if (defensiveColor) {
    determinedColor = checked ? defensiveColor[0] : defensiveColor[1];
  }
  return (
    <FontAwesomeIcon
      icon={checked ? icons[0] : icons[1]}
      size="lg"
      style={{color: determinedColor ? determinedColor : 'black'}}
      {...props}
    />
  );
};

export default ToggleIcons;
