import { InterviewCollectionItem } from '../../../../components/AudioPlayerMobile/testData';
import interviewCollectionOne from './interviewCollection';
import recipesList from './recipesList';
import resourcesList from './resourcesList';
const hhcModule = {
	published: true,
	free: false,
	id: 21107,
	name: 'Heart Health Collection Content',
	title: 'Content',
	slug: 'hhc-module-one',
	type: 'module',
	data: {
		thumb: {
			alt: 'The Food Revolution Heart Health Collection Logo',
			url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/tntg-impactkit-film.jpg'
		},
		description: ''
	},
	ContentTags: [],
	updatedAt: '2021-02-25T23:03:58.413Z',
	createdAt: '2021-02-25T23:03:58.413Z',
	Children: [ interviewCollectionOne, recipesList, resourcesList ]
};
export default hhcModule;
/* staging
{
    "published": true,
    "free": false,
    "id": 21107,
    "name": "Heart Health Collection Content",
    "title": "Content",
    "slug": "hhc-module-one",
    "type": "module",
    "data": {
        "thumb": {
            "alt": "The Food Revolution Heart Health Collection Logo",
            "url": "https://cdn.foodrevolution.org/members/assets/thumbnails/tntg-impactkit-film.jpg"
        },
        "description": ""
    },
    "ContentTags": [],
    "updatedAt": "2021-02-25T23:03:58.413Z",
    "createdAt": "2021-02-25T23:03:58.413Z"
}

root content to module
{
    "id": 42005,
    "ParentId": 21106,
    "ChildId": 21107,
    "sortOrder": 1,
    "updatedAt": "2021-02-25T23:24:31.669Z",
    "createdAt": "2021-02-25T23:24:31.669Z"
}

module to resourcesList
{
    "id": 42013,
    "ParentId": 21107,
    "ChildId": 21114,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T19:10:57.242Z",
    "createdAt": "2021-03-01T19:10:57.242Z"
}
*/
