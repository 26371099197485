import videoOne from '../Video/testData';
import {downloadOne, downloadTwo, specialReportsDownload} from '../Downloads/testData';
import {bioOne, bioTwo} from '../Bio/testData';
import {recipeOne} from '../Recipe/testData';

// LINKED Video Resource
export const videoResourceLinked = {
  displayProps: {
    title: 'Video 2: The Gut Health Revolution',
    subtitle:
      'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.',
    //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: 'test alt'
    }
  },
  type: 'videoResource',
  name: 'Video Resource One',
  slug: 'video-resource-two',
  title: 'Video Resource Two',
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: []
};
const linkedInterviews = {
  displayProps: {
    title: 'Linked Interviews',
    subtitle: 'In-depth interviews with the world’s leading experts on health, nutrition, and the environment.',
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: 'video'
    }
  },
  type: 'list',
  name: 'Linked Interviews',
  slug: 'linked-video-ressource-one',
  title: 'Linked Interviews of Video Resource One',
  Children: [videoResourceLinked, videoResourceLinked]
};

export const videoResourceOne = {
  displayProps: {
    title: 'Video 1: The Gut Health Revolution',
    subtitle:
      'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.',
    //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: 'test alt'
    }
  },
  type: 'videoResource',
  name: 'Video Resource One',
  slug: 'video-resource-one',
  title: 'Video Resource One',
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    bioOne, //Bio only needed for action hours
    bioTwo,
    videoOne,
    downloadTwo,
    recipeOne,
    linkedInterviews
  ]
};

//this is the data for the video resource preview
//it has no video or download children
export const videoResourceOnePreview = {
  displayProps: {
    title: 'Video 1: The Gut Health Revolution',
    subtitle:
      'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.',
    //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: ''
    }
  },
  type: 'videoResource',
  name: 'Video Resource One Preview test',
  slug: 'video-resource-one-preview',
  title: 'Video Resource One',
  data: {
    previewVideoLink: 'https://www.youtube.com/watch?v=6u2bMupKrog',
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    bioOne, //Bio only needed for action hours
    bioTwo
  ]
};

export const videoResourceForPPT = {
  type: 'videoResource',
  name: 'Video Resource One',
  slug: 'video-resource-one',
  title: 'Cynthia Louise',
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    // bioOne, //Bio only needed for action hours, not in PPT
    // videoOne,
    // downloadTwo,
    // specialReportsDownload
    // recipeOne  not needed in PPT
  ]
};
export const videoResourcesForFRS23 = [
  {
    title: 'Wellness: How Food Can Help You Heal',
    description:
      'Find out about the specific foods that can protect us from viral and bacterial infections, as well as from the most serious chronic illnesses of our time. This is critical information — in a pandemic, and beyond!',
    image: 'https://cdn.foodrevolution.org/frs23/frs23-placeholder-thumbnail-4.jpg',
    slug: 'ep1-wellness-how-food-can-help-you-heal'
  },
  {
    title: 'Microbiome: How Healing Your Gut Can Transform Your Life',
    description:
      'Optimizing the gut microbiome is key to sharp immunity, low cholesterol, and steady weight loss. The key is adding the right kinds of fiber, in the right way, to avoid gas, bloating, and discomfort — and for maximum healthy results.',
    image: 'https://cdn.foodrevolution.org/frs23/frs23-placeholder-thumbnail-3.jpg',
    slug: 'ep2-microbiome-how-healing-your-gut-can-transform-your-life'
  },
  {
    title: 'Mental Health: How Food Shapes Your Mood',
    description:
      'Stunning breakthroughs in the field of nutritional psychiatry highlight specific foods that could fight depression, anxiety, PTSD, ADHD, OCD, and other mental health challenges.',
    image: 'https://cdn.foodrevolution.org/frs23/frs23-placeholder-thumbnail.jpg',
    slug: 'ep3-mental-health-how-food-shapes-your-mood'
  }
].map(({title, description, slug, image}) => ({
  displayProps: {
    title,
    subtitle: description,
    image: {
      url: image,
      alt: title
    }
  },
  type: 'videoResource',
  name: title,
  slug,
  title,
  data: {
    time: 1506037155000,
    thumb: {
      url: image,
      alt: title,
      time: 1506037155000,
      text: description
    },
    // Used for the 'header' image on the page
    img: image,
    alt: title
  },
  Children: [
    bioOne, //Bio only needed for action hours
    bioTwo,
    videoOne,
    downloadTwo,
    recipeOne,
    linkedInterviews
  ]
}));

export const videoResourceForTNTG = {
  type: 'videoResource',
  name: 'Video Outtakes from The Need To Grow',
  slug: 'video-resource-one',
  title: 'Video Outtakes from The Need To Grow',
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Video Outtakes from The Need To Grow licious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    // bioOne, //Bio only needed for action hours, not in PPT
    videoOne,
    downloadTwo,
    downloadOne,
    specialReportsDownload
    // recipeOne  not needed in PPT
  ]
};

export const videoResourceNoRecipe = {
  displayProps: {
    title: 'Video 1: The Gut Health Revolution',
    subtitle:
      'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.',
    //image: {url: 'https://img.youtube.com/vi/6u2bMupKrog/hqdefault.jpg', alt: 'video'}
    image: {
      url:
        'https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6',
      alt: 'test alt'
    }
  },
  type: 'videoResource',
  name: 'Video Resource One',
  slug: 'video-resource-one',
  title: 'Video Resource One',
  data: {
    time: 1506037155000,
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Resource One',
      time: 1506037155000,
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    alt: 'Video Resource One'
  },
  Children: [
    bioOne, //Bio only needed for action hours
    bioTwo,
    videoOne,
    downloadTwo,

    linkedInterviews
  ]
};
