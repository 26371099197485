import GlobalSearchV2 from '../../../../../../components/GlobalSearchV2';
import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {track, page} from '../../../../../../lib/track';
function SearchPage(props) {
  const {authToken, title, description} = props;
  const userAnalytics = {
    service: 'resources', //the kind of service, like recipe, weekly guide, etcs
    title: 'resource search page',
    uuid: props.user && props.user.UUID
  };
  page('resources', this.userAnalytics);
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs="12" md="8">
            <h4>{title ? title : 'Search WHOLE Life Club'}</h4>
            <p>{description ? description : 'Search through our recipes, weekly guides, videos, and more.'}</p>
          </Col>
        </Row>
      </Container>
      <GlobalSearchV2
        userAnalytics={userAnalytics}
        hideFilterBy
        authToken={authToken}
        types={['week', 'videoresource', 'article', 'download', 'link']}
      />
    </React.Fragment>
  );
}
export default SearchPage;
