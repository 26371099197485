import React, {useCallback} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const PurchaseButton = ({setRecaptchaToken, processOrder, disabled}) => {
  const {executeRecaptcha} = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('PURCHASE');
    setRecaptchaToken(token);
    processOrder();
  }, [executeRecaptcha]);

  return (
    <Button onClick={handleReCaptchaVerify} disabled={disabled} color="orange" size="lg" block>
      <FontAwesomeIcon icon="lock-alt" className="mr-3" /> Place Order Securely
    </Button>
  );
};

export default PurchaseButton;
