import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import RecurringPaymentCard from '../RecurringPaymentCard';
@connect(state => ({
  user: state.user
}))
class PaymentPlans extends PureComponent {
  render() {
    const {orders} = this.props.user;
    const subscriptions = orders.filter(order => order.type === 'installments' && order.Subscription);
    return (
      <div>
        {subscriptions.length ? (
          subscriptions.map(subscription => <RecurringPaymentCard order={subscription} key={subscription.id} />)
        ) : (
          <h6>
            <em>None</em>
          </h6>
        )}
      </div>
    );
  }
}

PaymentPlans.propTypes = {};
//   updateProfile: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     firstName: PropTypes.string.isRequired,
//     lastName: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired
//   }).isRequired
// };

export default PaymentPlans;
