import React from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import {FacebookProvider, Comments} from 'react-facebook';
import classNames from 'classnames';

export const FacebookCommentFeed = ({
  url,
  numPosts = 10,
  orderBy = 'reverse_time',
  commentsKey,
  className,
  containerClass,
  appId = '731824260184014'
}) => {
  return (
    <div className={classNames(`facebook-comments-feed`, className)}>
      <FacebookProvider appId={appId}>
        <Comments href={url} width="100%" numPosts={numPosts} orderBy={orderBy} key={commentsKey} />
      </FacebookProvider>
    </div>
  );
};

export const CommentBox = ({commentFeed, commentsKey, refreshComments, variant, style}) => (
  <div
    className={classNames(
      'comment-box',
      `background-white`,
      `box-shadow-black-50`,
      `rounded`,
      variant === 'small' ? styles['comment-box-small'] : ''
    )}
    style={style}
  >
    <Row className={classNames(`align-items-end`, `text-center`, `mb-4`)}>
      <Col xs="12">
        <h5>Share Your Comments, Highlights, & Celebrations</h5>
      </Col>
      <Col xs="12">
        <Button
          className={classNames(
            styles['btn-refresh'],
            `btn`,
            variant === 'small' ? 'mt-3' : '',
            `btn-lg`,
            `text-uppercase`
          )}
          onClick={refreshComments}
        >
          Get The Latest Comments
        </Button>
      </Col>
    </Row>
    <Row id="comment">
      <Col>
        <FacebookCommentFeed commentsKey={commentsKey} {...commentFeed} />
      </Col>
    </Row>
  </div>
);

export default CommentBox;
