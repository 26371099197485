const b64toBlob = require('atob');
const atob = require('atob');

const convertFromBase64 = base64string => {
  const base64Data = base64string.replace(/^data:image\/png;base64,/, '');
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: 'image/jpeg'});
  return blob;
};

export default convertFromBase64;
