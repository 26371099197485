import React, {PureComponent} from 'react';
import Week from 'routes/Products/components/Week';

import {connect} from 'react-redux';
import getContent from 'lib/getContent';
import {page} from 'lib/track';
@connect(state => ({authToken: state.user.authToken}), {})
class CurrentWeek extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentWeek: null
    };
  }
  componentDidMount() {
    this.getCurrentWeek();
    page('weekly guide');
  }
  getCurrentWeek() {
    const weeksListSlug = 'wlc-18-weeks-list'; //clarity
    getContent(weeksListSlug, 'wlc', this.props.authToken, '?depth=2')
      .then(res => {
        const allWeeksList = res.Children; //clarity

        const currentWeekIndex = allWeeksList.length - 1; //clarity

        this.setState({currentWeek: allWeeksList[currentWeekIndex]}); //api is 1 week ahead, so -2
      })
      .catch(e => {
        console.log('error getting weeklist list');
      });
  }
  render() {
    const {currentWeek} = this.state;
    if (currentWeek) {
      return <Week key={currentWeek.id} overrideSlug={currentWeek.slug} {...this.props} />;
    } else {
      return <div>...</div>;
    }
  }
}

export default CurrentWeek;
