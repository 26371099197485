/* eslint-disable max-len */

import React, {PureComponent} from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import upgradeStyles from './styles.scss';

@connect(state => ({
  user: state.user
}))
class UpgradeModal extends PureComponent {
  constructor(props) {
    super(props);
    const currentTime = new Date();
    this.state = {
      specialMessage: null //full modal message, currently used when user decides to stay
    };
    this.messageRedirect = this.props.messageRedirect || '/products/wlc/current-week'; //redirect after special message is seen

    this.handleRevert = this.handleRevert.bind(this);
    this.handleUpgrade = this.handleUpgrade.bind(this);
  }

  //handles when users changes their mind to not upgrade
  handleRevert() {
    this.setState({
      specialMessage: {
        heading: 'Thanks for considering upgrading.',
        body:
          'We’re so glad that you’re part of our community of WHOLE Lifers! Please let us know if there is something we can do to make WLC even better!',
        button: {
          text: 'Go to the Weekly Guide',
          href: this.messageRedirect
        }
      }
    });
  }

  handleUpgrade() {
    const {currentOrderId, annualPrice, upgradeSubscription} = this.props;
    this.setState({upgradePending: true});
    upgradeSubscription(currentOrderId, annualPrice);
  }

  render() {
    const {isOpen, annualPrice, showTrialUpgradeCopy, upgradeCopy, upgradeFail, upgradeSuccess} = this.props;
    const {specialMessage, upgradePending} = this.state;

    //construct the subscription new start date
    const d = new Date();
    const currentMonth = d.getMonth() + 1; //getMonth returns 0-11
    let currentYear = d.getFullYear();
    let startMonth = currentMonth + 1;
    if (startMonth === 13) {
      startMonth = 1;
      currentYear = currentYear + 1;
    }

    if (specialMessage) {
      return (
        <Modal isOpen={isOpen} centered>
          <ModalHeader className="px-5" id="upgradeModalHeader">
            {specialMessage.heading}
          </ModalHeader>
          <ModalBody className="p-5">{specialMessage.body}</ModalBody>
          <ModalFooter>
            <Button
              color="light"
              onClick={() => {
                this.props.toggle('upgradeOpen');
                this.setState({specialMessage: null});
                this.props.refreshSubscriptions();
              }}
              className="mr-3"
            >
              Close
            </Button>
            <Button color="primary" href={specialMessage.button.href} onClick={specialMessage.button.onClick}>
              {specialMessage.button.text}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader className="px-5" id="upgradeModalHeader">
          {upgradeFail ? 'Whoops!' : 'Complete your WLC membership upgrade...'}
        </ModalHeader>
        <ModalBody className="p-5">
          {upgradeSuccess && !upgradeFail ? <span>Your upgrade was successful.</span> : null}
          {upgradeFail && !upgradeSuccess ? (
            <span>
              We weren't able to upgrade this subscription for you. For immediate help, please contact customer service
              at support@foodrevolution.org.
            </span>
          ) : null}
          {!upgradeSuccess && !upgradeFail ? (
            <div>
              {showTrialUpgradeCopy
                ? `Save money when you upgrade your subscription to annual! You’ll pay $${annualPrice}/year, for annual savings of $${upgradeCopy.savings}. You'll be billed at the end of your current free trial and can cancel at any time.`
                : `Save money when you upgrade your subscription to annual! Instead of $${upgradeCopy.currentMonthlyAmount}/month, you'll pay $${annualPrice}/year, for annual savings of $${upgradeCopy.savings}. You'll be billed starting at the end of your current subscription and can cancel at any time.`}
              <br />
              <br />
              <strong>
                {`You'll be automatically billed $${annualPrice}/year starting on ${upgradeCopy.copyBillingDateString}`}
              </strong>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <div>
            <Button color="light" onClick={this.handleRevert} style={{cursor: 'pointer'}} disabled={!!upgradePending}>
              Close
            </Button>
            <Button
              color="primary"
              onClick={this.handleUpgrade}
              style={{cursor: 'pointer'}}
              disabled={!!upgradePending}
            >
              {upgradePending ? 'Processing...' : 'Complete Upgrade'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(UpgradeModal);
