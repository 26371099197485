import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Api from '../../lib/api.js';

@connect(
  state => ({authToken: state.user.authToken}),
  {}
)
class Data extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {d: null};
    this.Component = props.component;
    // TODO: Add second parameter for spec content
    if (props.url) {
      this.url = props.url;
    }
  }

  componentDidMount() {
    let url;
    if (this.props.url.length > 1) {
      url = this.props.url;
    } else {
      const path = location.pathname.split('/');
      let contentSlug = path[path.length - 1];
      let productSlug = path[path.length - 2];
      url = `/content/${slug}/${productSlug}`;
    }

    if (window.urlsCache[url]) {
      this.setState({d: window.urlsCache[url]});
    } else {
      Api.get(url, this.props.authToken).then(d => {
        this.setState({d});
        window.urlsCache[url] = d;
      });
    }

    //TODO handle err, refetching if fail
  }

  render() {
    const {
      state: {d},
      Component,
      props: {optionalProps}
    } = this;

    if (!d) return <p className="px-5 py-5">Loading...</p>;
    //console.log('data in Dat() ', d);
    return <Component {...d} {...optionalProps} />;
  }
}

// Takes a component and a Url and returns that component
// AFTER giving it the data/ contentItem at that url
// optionalProps also available (used to pass weeksList to week for instance)

Data.propTypes = {
  component: PropTypes.func.isRequired,
  url: PropTypes.string,
  optionalProps: PropTypes.shape({})
};

Data.defaultProps = {
  url: '/',
  optionalProps: {}
};

export {Data};

export default (component, url, optionalProps) => () => (
  <Data component={component} url={url} optionalProps={optionalProps} />
);
