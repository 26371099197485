import React, {useState} from 'react';

const SocialProof = ({image, title, subtitle}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the text expansion.
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // You may want to slice the subtitle to show a preview when not expanded.
  const previewLength = 100; // number of characters to show in the preview
  const previewText =
    subtitle && subtitle.length > previewLength && !isExpanded ? `${subtitle.slice(0, previewLength)}...` : subtitle;
  return (
    <div className="social-proof-wrapper">
      <h4 className="text-center">What members are saying</h4>
      <div className="social-proof">
        <img src={image && image.url} alt="User Testimonial" />
        <blockquote>
          <p>{previewText}</p>
          <div role="button" onClick={toggleExpansion} className="see-more">
            {isExpanded ? 'See Less' : 'See More'}
          </div>
          <footer>- {title}</footer>
        </blockquote>
      </div>
    </div>
  );
};

export default SocialProof;
