import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown} from '@fortawesome/pro-light-svg-icons/faChevronCircleDown';
import {faChevronCircleUp} from '@fortawesome/pro-light-svg-icons/faChevronCircleUp';
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import classNames from 'classnames';
import {faq} from '../../faq.json';

const remarkSubSuper = require('remark-sub-super');

export default function FaqList({faqlist = faq, borderColor = 'black-15', slice = 0, ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    key => () => setActiveCards(state => (state.includes(key) ? state.filter(e => e !== key) : [...state, key])),
    []
  );
  return faqlist.slice(slice).map((faq, index) => {
    return (
      <div className={classNames(`faq-item faq-item-border-${borderColor}`)} key={index}>
        <div className="faq-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="faq-item-header-text">
            <ReactMarkdown className="faq-item-header-title" source={faq.question || 'faq question'} />
          </div>
          <div className="faq-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="faq-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="faq-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="faq-item-content">
            <ReactMarkdown
              source={faq.answer || 'faq answer'}
              plugins={[remarkSubSuper]}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
          </div>
        </Collapse>
      </div>
    );
  });
}

export function FaqCard({faqlist = faq, slice = 0, ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    key => () => setActiveCards(state => (state.includes(key) ? state.filter(e => e !== key) : [...state, key])),
    []
  );
  return faqlist.slice(slice).map((faq, index) => {
    return (
      <div className={classNames(`faq-card box-shadow-black-25`)} key={index}>
        <div
          className={classNames(
            `faq-card-header faq-card-header-${
              activeCards.includes(index) ? 'open' : 'closed'
            } d-flex align-items-center justify-content-between`
          )}
          onClick={onClick(index)}
        >
          <div className="faq-card-header-text">
            <ReactMarkdown className="faq-card-header-title" source={faq.question || 'faq question'} />
          </div>
          <div className="faq-card-header-icon">
            <FontAwesomeIcon className="faq-card-icon" icon={activeCards.includes(index) ? faMinus : faPlus} />
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="faq-card-content">
            <ReactMarkdown
              source={faq.answer || 'faq answer'}
              plugins={[remarkSubSuper]}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
          </div>
        </Collapse>
      </div>
    );
  });
}
