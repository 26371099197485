export const orderOne = {
  id: 11,
  subtotal: '10.00',
  taxableSubtotal: '0.00',
  tax: '0.00',
  taxRate: '0.00000',
  shipping: '0.00',
  total: '10.00',
  UserId: 1012,
  aff: null,
  opid: null,
  numInstallments: 1,
  paymentsMade: 1,
  refunded: null,
  paymentAmount: '10.00',
  currentTotal: '10.00',
  gatewayPlanId: '',
  gift: null,
  GiftId: null,
  createdAt: '2018-05-14T22:10:26.000Z',
  updatedAt: '2018-05-14T22:10:26.000Z',
  OfferId: 1,
  ShippingAddress: {
    id: 16,
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    type: 'shipping',
    country: null,
    OrderId: 11,
    createdAt: '2018-05-14T22:10:26.000Z',
    updatedAt: '2018-05-14T22:10:26.000Z'
  },
  BillingAddress: {
    id: 15,
    name: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    postalCode: '94577',
    type: 'billing',
    country: null,
    OrderId: 11,
    createdAt: '2018-05-14T22:10:26.000Z',
    updatedAt: '2018-05-14T22:10:26.000Z'
  },
  OrderTransactions: [
    {
      id: 11,
      amount: '10.00',
      gateway: 'braintree',
      gatewayDate: '2018-05-14T22:10:26.000Z',
      gatewayPaymentMethodId: 'hrrdg3',
      gatewayTransactionId: '77xd6396',
      gatewaySubscriptionId: null,
      gatewayPaymentInstrumentType: 'credit_card',
      type: 'charge',
      OrderId: 11,
      refundedId: null,
      refundedAmount: '0.00',
      createdAt: '2018-05-14T22:10:26.000Z',
      updatedAt: '2018-05-14T22:10:26.000Z',
      UserPaymentMethodId: 14,
      OfferPaymentOptionId: 1,
      TransactionProducts: [
        {
          id: 15,
          amount: '10.00',
          price: '10.00',
          tax: '0.00',
          qtyOrdered: 1,
          shipping: '0.00',
          OrderTransactionId: 11,
          ProductId: 1,
          createdAt: '2018-05-14T22:10:26.000Z',
          updatedAt: '2018-05-14T22:10:26.000Z'
        }
      ],
      OfferPaymentOption: {
        id: 1,
        amount: '10.00',
        interval: null,
        intervalCount: null,
        numInstallments: 1,
        name: 'Single Pay',
        OfferId: 1,
        createdAt: '2018-05-04T17:30:15.000Z',
        updatedAt: '2018-05-04T17:30:15.000Z',
        PlanId: null
      }
    }
  ],
  OrderProducts: [
    {
      id: 15,
      price: '10.00',
      taxable: false,
      shipping: '0.00',
      tax: '0.00',
      taxableAmount: '0.00',
      subtotal: '10.00',
      qtyOrdered: 1,
      qtyRefunded: 0,
      OrderId: 11,
      ProductId: 1,
      UpSellId: null,
      BumpOfferId: null,
      createdAt: '2018-05-14T22:10:26.000Z',
      updatedAt: '2018-05-14T22:10:26.000Z',
      Product: {
        id: 1,
        name: 'Product 1',
        requiresShippingAddress: false,
        slug: 'product-1',
        ContentItemId: 1,
        taxable: false,
        shipRates: null,
        quantifiable: false
      }
    }
  ],
  Offer: {id: 1, slug: 'offer-1', name: 'Offer 1'},
  User: {
    id: 1012,
    email: 'test@g098765456789mail.com',
    firstName: 'Joe',
    lastName: 'Towers'
  }
};
export const giftOrder = {
  id: 8,
  subtotal: '0.00',
  taxableSubtotal: '0.00',
  tax: '0.00',
  taxRate: '0.00000',
  shipping: '0.00',
  total: '0.00',
  UserId: 1011,
  aff: null,
  opid: null,
  numInstallments: 0,
  paymentsMade: 0,
  refunded: null,
  paymentAmount: '0.00',
  currentTotal: '0.00',
  gatewayPlanId: null,
  gift: 'recipient',
  GiftId: 7,
  createdAt: '2018-05-07T16:53:33.000Z',
  updatedAt: '2018-05-07T16:53:34.000Z',
  OfferId: 1,
  ShippingAddress: null,
  BillingAddress: null,
  OrderTransactions: [
    {
      id: 8,
      amount: '0.00',
      gateway: 'none',
      gatewayDate: '2018-05-07T16:53:33.000Z',
      gatewayPaymentMethodId: 'gift',
      gatewayTransactionId: null,
      gatewaySubscriptionId: null,
      gatewayPaymentInstrumentType: 'gift',
      type: 'gift',
      OrderId: 8,
      refundedId: null,
      refundedAmount: '0.00',
      createdAt: '2018-05-07T16:53:33.000Z',
      updatedAt: '2018-05-07T16:53:34.000Z',
      UserPaymentMethodId: 11,
      OfferPaymentOptionId: 1,
      TransactionProducts: [
        {
          id: 11,
          amount: '0.00',
          price: '0.00',
          tax: '0.00',
          qtyOrdered: 1,
          shipping: '0.00',
          OrderTransactionId: 8,
          ProductId: 2,
          createdAt: '2018-05-07T16:53:33.000Z',
          updatedAt: '2018-05-07T16:53:34.000Z'
        },
        {
          id: 12,
          amount: '0.00',
          price: '0.00',
          tax: '0.00',
          qtyOrdered: 1,
          shipping: '0.00',
          OrderTransactionId: 8,
          ProductId: 1,
          createdAt: '2018-05-07T16:53:33.000Z',
          updatedAt: '2018-05-07T16:53:34.000Z'
        }
      ],
      OfferPaymentOption: {
        id: 1,
        amount: '10.00',
        interval: null,
        intervalCount: null,
        numInstallments: 1,
        name: 'Single Pay',
        OfferId: 1,
        createdAt: '2018-05-04T17:30:15.000Z',
        updatedAt: '2018-05-04T17:30:15.000Z',
        PlanId: null
      }
    }
  ],
  OrderProducts: [
    {
      id: 11,
      price: '0.00',
      taxable: false,
      shipping: '0.00',
      tax: '0.00',
      taxableAmount: '0.00',
      subtotal: '0.00',
      qtyOrdered: 1,
      qtyRefunded: 0,
      OrderId: 8,
      ProductId: 2,
      UpSellId: null,
      BumpOfferId: 1,
      createdAt: '2018-05-07T16:53:33.000Z',
      updatedAt: '2018-05-07T16:53:34.000Z',
      Product: {
        id: 2,
        name: 'Product 2',
        requiresShippingAddress: false,
        slug: 'product-2',
        ContentItemId: 1,
        taxable: false,
        shipRates: null,
        quantifiable: false
      }
    },
    {
      id: 12,
      price: '0.00',
      taxable: false,
      shipping: '0.00',
      tax: '0.00',
      taxableAmount: '0.00',
      subtotal: '0.00',
      qtyOrdered: 1,
      qtyRefunded: 0,
      OrderId: 8,
      ProductId: 1,
      UpSellId: null,
      BumpOfferId: null,
      createdAt: '2018-05-07T16:53:33.000Z',
      updatedAt: '2018-05-07T16:53:34.000Z',
      Product: {
        id: 1,
        name: 'Product 1',
        requiresShippingAddress: false,
        slug: 'product-1',
        ContentItemId: 1,
        taxable: false,
        shipRates: null,
        quantifiable: false
      }
    }
  ],
  Offer: {id: 1, slug: 'offer-1', name: 'Offer 1'},
  User: {
    id: 1011,
    email: 'someemail@e45678987654345mail.com',
    firstName: 'Joe',
    lastName: 'Powers'
  }
};
