import React from 'react';
import PropTypes from 'prop-types';
import {Container, Col, Row} from 'reactstrap';
import styles from 'styles/index.scss';
import ExpirationDateField from './components/ExpirationDate';
import CVVField from './components/CVV';

const {profileBraintreeFormContainer, profileBraintreeFormWarning, profileBraintreeFormField} = styles;

const BraintreeForm = props => {
  const {
    numberBlurred,
    numberValid,
    cvvBlurred,
    cvvValid,
    expirationDateBlurred,
    expirationDateValid
  } = props.braintreeValidity;

  const isExisting = props.isExisting;

  let idPrefix = isExisting ? "existing" : "new";

  return (
    <Container fluid className={profileBraintreeFormContainer}>
      <Row>
        <Col style={{padding: 0}}>
          <div
            id={idPrefix+"-card-number"}
            style={{marginBottom: numberBlurred && !numberValid ? '0' : '32px'}}
            className={profileBraintreeFormField}
          />
          {numberBlurred && !numberValid ? (
            <div className={profileBraintreeFormWarning}>
              <span>Invalid Credit Card Number</span>
            </div>
          ) : null}
        </Col>
      </Row>

      <Row>
        <ExpirationDateField expirationDateValidity={props.braintreeValidity} isExisting={isExisting}/>
        <CVVField cvvValidity={props.braintreeValidity} isExisting={isExisting}/>

      </Row>
    </Container>
  );
};

BraintreeForm.propTypes = {
  braintreeValidity: PropTypes.shape({
    numberBlurred: PropTypes.bool.isRequired,
    cvvBlurred: PropTypes.bool.isRequired,
    expirationDateBlurred: PropTypes.bool.isRequired,
    numberValid: PropTypes.bool.isRequired,
    cvvValid: PropTypes.bool.isRequired,
    expirationDateValid: PropTypes.bool.isRequired
  }).isRequired
};

// const mapStateToProps = state => ({
//   braintreeValidity: state.checkout.braintreeValidity,
// });

export default BraintreeForm;
