import React, {Component} from 'react';
import Video from '../../../../components/Video';
import {Container, Row, Col} from 'reactstrap';
import {withRouter} from 'react-router';
import ModuleWrapper from './components/ModuleWrapper';
import ModuleProgress from './components/ModuleProgress';
import DashboardHeader from './components/DashboardHeader';
import ModulesHeader from './components/ModulesHeader';
import CoursebookCard from '../../components/CoursebookCard';
import ReactMarkdown from 'lib/react-markdown';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    console.log('this.props.product ', this.props.product);
    this.state = {
      userData: {},
      product: this.props.product
    };
    this.determineColor = this.determineColor.bind(this);
    this.isThereProgressInModule = this.isThereProgressInModule.bind(this);
    this.calculateModuleProgress = this.calculateModuleProgress.bind(this);

    this.productInPath = location.pathname.split('/')[2];
    this.product = this.props.product;

    this.modules = this.product.Children.filter(item => item.type === 'module');
    console.log('modules in dashboard ', this.modules);
    this.videoResources = this.product.Children.filter(item => item.type === 'videoResource');
    this.bonuses = this.product.Children.filter(item => item.type === 'bonuses');
  }

  handleNav = slug => {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
  };

  //determines the background color based on module progress (not started, in progress, completed)
  //currently not using this
  determineColor(moduleState) {
    if (moduleState.completed) {
      return 'bg-success';
    }
    if (this.isThereProgressInModule(moduleState)) {
      return 'moduleProgress'; //custom class
    } else {
      return 'bg-dark'; //no started
    }
  }

  //boolean
  //determins if any lesson has been completed in a module
  isThereProgressInModule(moduleState) {
    if (!moduleState) return false;

    return (
      typeof moduleState.lessons.find(lesson => {
        return lesson.completed;
      }) !== 'undefined'
    );
  }
  //determines how many Modules have been completed
  calculateModuleProgress(moduleStates) {
    const totalModulesCompleted = moduleStates
      ? moduleStates.filter(item => {
          return item.completed;
        }).length
      : 0;

    //find the first one completed
    let currentModuleIndex = moduleStates
      ? moduleStates.findIndex(item => {
          return !item.completed;
        })
      : 0;
    //replacing -1 with 'finished' for clarity
    currentModuleIndex = currentModuleIndex === -1 ? 'finished' : currentModuleIndex;

    let currentModuleNumber = currentModuleIndex === 'finished' ? moduleStates.length : currentModuleIndex; //+ 1; //current module the user is on

    const useString = currentModuleIndex === 0 ? this.modules[0].title : false;
    //returning the next module index to complete, and the label number of that module
    return {currentModuleIndex, currentModuleNumber, totalModulesCompleted, useString};
  }
  render() {
    const {
      removeWelcome,
      moduleStates,
      moduleHeaderTitle,
      isUnstructured,
      videoDirectUrl,
      coursebooks,
      extraNavItems
    } = this.props;
    const {description = ''} = this.product.data;
    return (
      <div>
        {/* ModulePrgess show "1/7 Completed    Cont. to Module 2" */}
        {isUnstructured ? null : (
          <div className="moduleProgressWrapper sticky-top zIndex100">
            <ModuleProgress
              calculateModuleProgress={this.calculateModuleProgress}
              moduleStates={moduleStates ? moduleStates : []}
              modules={this.modules}
              product={this.product}
              handleNav={this.handleNav}
            />
          </div>
        )}

        <Container>
          {removeWelcome ? null : <DashboardHeader isUnstructured={isUnstructured} handleNav={this.handleNav} />}
          {description ? <ReactMarkdown source={description} /> : null}

          {coursebooks
            ? coursebooks.map(contentItem => (
                <Row>
                  <Col xs="12" className="mb-5">
                    <CoursebookCard contentItem={contentItem} />
                  </Col>
                </Row>
              ))
            : null}

          {removeWelcome ? null : videoDirectUrl || (this.product.data.video && this.product.data.video.url) ? (
            <Video data={this.product.data.video} videoDirectUrl={videoDirectUrl} className="xs-full-width" />
          ) : null}

          <ModulesHeader
            moduleHeaderTitle={moduleHeaderTitle}
            moduleStates={moduleStates ? moduleStates : []}
            calculateModuleProgress={this.calculateModuleProgress}
            isUnstructured={isUnstructured}
          />
          {/* This will render a Card with the Module title in the CardHeader and the lessons in the CardBody.
         It will also display the progress of the module and lessons*/}
          {this.modules ? (
            <Row>
              {this.modules.map((item, index) => {
                return (
                  <Col key={item.id} xs="12">
                    <ModuleWrapper
                      determineColor={this.determineColor}
                      handleNav={this.handleNav}
                      module={item}
                      moduleNumber={item.data.num}
                      moduleState={moduleStates ? moduleStates.find(mod => mod.id === item.id) : null}
                      isUnstructured={isUnstructured}
                      calculateModuleProgress={this.calculateModuleProgress}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : null}
          {/* This will render a CardHeader that will link to a page for the Bonuses.  IsUnstructed is passed b/c we don't haver any lessons associated with this resource.   */}
          {this.bonuses
            ? this.bonuses.map((item, index) => {
                return (
                  <Row className="pb-1 ">
                    <Col>
                      <ModuleWrapper
                        determineColor={this.determineColor}
                        handleNav={this.handleNav}
                        module={{...item, slug: 'bonuses'}}
                        isUnstructured
                      />
                    </Col>
                  </Row>
                );
              })
            : null}

          {/* This will render a CardHeader that will link to a page for the VideoResource. IsUnstructed is passed b/c we don't haver any lessons associated with this resource.  */}
          {this.videoResources
            ? this.videoResources.map((item, index) => {
                return (
                  <Row className="pb-1 ">
                    <Col>
                      {/* <Bonuses {...item} /> */}
                      <ModuleWrapper
                        determineColor={this.determineColor}
                        handleNav={this.handleNav}
                        module={{...item}}
                        isUnstructured
                      />
                    </Col>
                  </Row>
                );
              })
            : null}

          {extraNavItems
            ? extraNavItems.map((item, index) => {
                return (
                  <Row className="pb-1 ">
                    <Col>
                      <ModuleWrapper
                        determineColor={this.determineColor}
                        handleNav={this.handleNav}
                        module={{...item, title: item.name}}
                        isUnstructured
                      />
                    </Col>
                  </Row>
                );
              })
            : null}
        </Container>
      </div>
    );
  }
}

export default withRouter(Dashboard);
