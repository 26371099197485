import React, {useState} from 'react';
import {faXmark} from '@fortawesome/pro-solid-svg-icons/faXmark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import {Button} from 'reactstrap';

const SlidingMenu = ({history, isOpen = true, toggleMenu, navItems}) => {
  function handleNav(path) {
    toggleMenu();
    history.push(`/products/${path}`);
  }
  return (
    <>
      <div className={`product-nav ${isOpen ? 'product-nav-open' : ''}`}>
        <div className="product-nav-menu">
          <ul className="product-nav-menu-items" role="button">
            <li>
              <a role="button" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faXmark} />
              </a>
            </li>
            {navItems.map((item, index) => {
              return (
                <li
                  key={item.path}
                  onClick={() => {
                    handleNav(item.path);
                  }}
                >
                  <a role="button">{item.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="product-nav-fill" onClick={toggleMenu} />
      </div>
    </>
  );
};

export default withRouter(SlidingMenu);
