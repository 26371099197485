import {createSelector} from 'reselect';

const getSelectedBumpOfferIds = state => state.checkout.orderRequest.BumpOfferIds;
const getCurrentOfferPaymentOptionId = state => state.checkout.orderRequest.OfferPaymentOptionId;
const getOfferPaymentOptions = state => state.checkout.offer.OfferPaymentOptions;
const getParamEmailAddress = state => state.checkout.paramUserInfo.email;
const getOfferProduct = state => state.checkout.offer.Product;
const getThirdPartyEmailAddress = state => state.checkout.thirdPartyUserInfo.email;
const getTotals = state => state.checkout.totals;

export const getCurrentOfferPaymentOption = createSelector(
  [getCurrentOfferPaymentOptionId, getOfferPaymentOptions],
  (currentOfferPaymentOptionId, offerPaymentOptions) =>
    offerPaymentOptions.filter(o => o.id === currentOfferPaymentOptionId)[0] || offerPaymentOptions[0]
);

export const getCurrentTotal = createSelector(
  [getCurrentOfferPaymentOptionId, getTotals],
  (offerPaymentOptionId, totals) => totals[offerPaymentOptionId]
);

export const getPassedEmailAddresses = createSelector(
  [getThirdPartyEmailAddress, getParamEmailAddress],
  (thirdPartyEmailAddress, paramEmailAddress) =>
    [thirdPartyEmailAddress, paramEmailAddress].filter((e, i, self) => e && self.indexOf(e) === i)
);

export const orderRequiresPayment = createSelector(
  [getCurrentTotal, getOfferPaymentOptions],
  (currentTotal, OfferPaymentOptions) => {
    let notComped = OfferPaymentOptions.map(OPO => OPO.amount).some(amount => amount > 0.0);
    return !currentTotal || !currentTotal.order || Number(currentTotal.order.total) > 0 || notComped;
  }
);

export const orderRequiresShipping = createSelector(
  [getCurrentOfferPaymentOption, getOfferProduct, getSelectedBumpOfferIds],
  (currentOfferPaymentOption, offerProduct, bumpOfferIds) =>
    Boolean(
      offerProduct.requiresShippingAddress ||
        (currentOfferPaymentOption &&
          currentOfferPaymentOption.BumpOffers.some(
            bo => bumpOfferIds.includes(bo.id) && bo.Product.requiresShippingAddress
          ))
    )
);

export default {getCurrentTotal, getPassedEmailAddresses, orderRequiresPayment, orderRequiresShipping};
