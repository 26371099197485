/* eslint-disable */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Data from 'containers/Data';
import WLCOnboardV2 from './components/WLCV2';

const onboardComponents = {
  'wlc-onboard': WLCOnboardV2
};

class Onboard extends Component {
  constructor(props) {
    super(props);
    //where do props come from?  -> ProductsOnboard
    //ProductsOnboard decides which product is being onboarded (i guess)
    //ProductsOnboard is rendered in WLC/index or whatever product
    //So the idea is to have a general onboarding Component that can be placed in any Product Component
    //props in the onboarding content item for WLC
    const {id, slug} = props.onboard;
    console.log('props in Onboard ', props.onboard);
    const path = location.pathname.split('/');
    const thisProductInPath = path[2];

    this.id = id;

    // Wrap it in a Data call to get the contentItem
    this.Component = Data(onboardComponents[slug], `/content/${id}/${thisProductInPath}`);
  }

  render() {
    console.log('Onboard/index render');
    const {Component} = this;
    console.log('Component in WLCOnboard ', Component);
    // return (<div>WLConboard here:<Component /></div>);
    return <Component />;
  }
}

Onboard.displayname = 'Onboard';

Onboard.propTypes = {
  children: PropTypes.shape({}).isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default Onboard;
