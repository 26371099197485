import React from 'react';

const EnvStateContext = React.createContext();
const EnvDispatchContext = React.createContext();

const initialState = {};

function EnvReducer(state, action) {
  switch (action.type) {
    case 'SET_ENV': {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function EnvProvider({ children }) {
  const [state, dispatch] = React.useReducer(EnvReducer, initialState);
  React.useEffect(() => {
    typeof window !== 'undefined' && localStorage.setItem('aws_cognito_user', JSON.stringify(state));
  }, [state]);
  return (
    <EnvStateContext.Provider value={state}>
      <EnvDispatchContext.Provider value={dispatch}>{children}</EnvDispatchContext.Provider>
    </EnvStateContext.Provider>
  );
}

function useEnvState() {
  const context = React.useContext(EnvStateContext);
  if (context === undefined) {
    throw new Error('useEnvState must be used within a EnvProvider');
  }
  return context;
}

function useEnvDispatch() {
  const context = React.useContext(EnvDispatchContext);
  if (context === undefined) {
    throw new Error('useEnvDispatch must be used within a EnvProvider');
  }
  return context;
}

export { EnvProvider, useEnvState, useEnvDispatch };
