import React, {useCallback, useState} from 'react';
import {Button} from 'reactstrap';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HeroHeader, {HeroHeaderLeft, HeroHeaderRight, HeroHeaderBody} from 'routes/Products/components/HeroHeader';
import VideoModal from '../../../../components/VideoModal';

const Header = ({description, hidePlayEpisode, handleNav, image = {}, playSlug, previewVideoUrl, title, logoImg}) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const toggleVideoModal = useCallback(() => {
    setShowVideoModal(s => !s);
  }, []);

  return (
    <HeroHeader
      logo={{
        src: logoImg,
        alt: title,
        type: 'image/png'
      }}
      background={{
        src: image.url ?? '',
        alt: image.alt ?? '',
        type: 'image/jpeg'
      }}
      previewVideoUrl={previewVideoUrl}
      disablePreviewVideo={showVideoModal}
      muted={true}
    >
      <HeroHeaderLeft>
        {previewVideoUrl ? (
          <Button size="lg" block color="light" onClick={toggleVideoModal}>
            <FontAwesomeIcon icon={faPlay} className="mr-2" /> Play Welcome Video
          </Button>
        ) : null}
        {!hidePlayEpisode ? (
          <Button size="lg" block color="light" onClick={() => handleNav(null, null, playSlug, null)}>
            <FontAwesomeIcon icon={faPlay} className="mr-2" /> Play Episode One
          </Button>
        ) : null}
      </HeroHeaderLeft>
      <HeroHeaderBody>
        <p>{description}</p>
      </HeroHeaderBody>
      {previewVideoUrl ? (
        <VideoModal videoUrl={previewVideoUrl} showModal={showVideoModal} toggleModal={toggleVideoModal} />
      ) : null}
    </HeroHeader>
  );
};

export default Header;
