/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Card} from 'reactstrap';
import styles from 'styles/index.scss';

const {center1, center2, hidden, listItem} = styles;

const materialsTitles = {
  tools: 'Tools',
  article: 'Article',
  specialReports: 'Special Reports'
};

const ListItem = props => (
  <Link to={`/products/${location.pathname.split('/')[2]}/${props.slug}`}>
    <Card className={listItem}>
      <div className={center1}>
        <h5>{materialsTitles[props.type]}</h5>
      </div>
      <img src={props.data.thumb.url} alt={props.data.thumb.alt} />
      <div className={center2}>
        <p>{props.title}</p>
      </div>
      <p className={hidden}>{props.data.thumb.text}</p>
      <div className={hidden}>
        <span>
          <span>&#9759;</span>LEARN MORE
        </span>
      </div>
      <div className={hidden}>
        <span>
          <span className="spacer">&#9759;</span>DOWNLOAD BONUS
        </span>
        <span>VALUE: {props.data.value || '$88.00'}</span>
      </div>
    </Card>
  </Link>
);

ListItem.propTypes = {
  data: PropTypes.shape({
    thumb: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    value: PropTypes.string
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default ListItem;
