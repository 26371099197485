import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { generateClient } from 'aws-amplify/api';
import * as mutations from './graphql/mutations';
import { useUserDispatch, useUserState } from './context/user';
import { useMessageDispatch, useMessageState } from './context/message';
import { useEnvState } from './context/env';

import { getUserGroups } from './utils';

export default function CreateMessageBox(props: any) {
  const client = generateClient();
  const userState = useUserState();
  const { category } = useMessageState();
  const messageDispatch = useMessageDispatch();
  const userDispatch = useUserDispatch();

  const envState = useEnvState();

  const [name, setName] = React.useState(userState.name);
  const [id, setId] = React.useState(userState.id);
  const [blocked, setBlocked] = React.useState(userState.blocked);
  const [moderator, setModerator] = React.useState(userState.moderator);
  const [picture, setPicture] = React.useState(userState.picture);
  const [disableSubmitButton, setDisableSubmitButton] = React.useState(false);

  React.useEffect(() => {
    setName(userState.name);
    setId(userState.id);
    setBlocked(userState.blocked);
    setModerator(userState.moderator);
    setPicture(userState.picture);
  }, [userState.name, userState.id, userState.blocked, userState.picture]);

  const [messageText, setMessageText] = useState('');
  async function submitMessage() {
    /* Let's check if my user has been added to the blocked group
     *  (I don't think there is a webhook so this is the way I'm going to implement */
    if(disableSubmitButton) return;
    setDisableSubmitButton(true);
    if (messageText.trim()) {
      const userGroups = await getUserGroups(id, envState);
      if (userGroups.includes('Blocked')) {
        window.alert('Sorry, you have been blocked');
        userDispatch({ type: 'SET_USER', payload: { blocked: true } });
      } else {
        const newMessageObject = (await client.graphql({
          query: mutations.createMessage,
          variables: {
            input: {
              raw: messageText,
              timestamp: new Date().getTime(),
              category,
              userID: id,
              userName: name,
              moderatorPost: moderator,
              picture,
              ParentCommentId: props.parentId,
              userToNotifyID: props.parentMessageUserId,
              readByParentCommentUser: false,
              hidden: false
            }
          }
        })) as { data: any };
        setMessageText('');
        setDisableSubmitButton(false);
        // Scroll automatically to this message since I'm the one who created it
        messageDispatch({
          type: 'SET_CURRENT_MESSAGE_ANCHOR',
          payload: `message-${newMessageObject.data.createMessage.id}`
        });
      }
    }
  }

  return (
    <Col className={classNames(`message-box-wrap`)}>
      {blocked ? null : (
        <>
          <textarea
            value={messageText}
            className="form-control"
            name={`text-box-${props.parentId || 'new-post'}`}
            id={`text-box-${props.parentId || 'new-post'}`}
            placeholder={props.placeholderText ? props.placeholderText : 'Add a comment...'}
            onKeyDown={async e => {
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault();
                submitMessage();
              }
            }}
            onChange={e => {
              setMessageText(e.target.value);
            }}
          />
          <div className="d-flex justify-content-between mt-1">
            <code className="text-medium-gray text-small">Press Shift + Enter to Comment</code>
            <Button color="post" className="text-small px-4" onClick={submitMessage} disabled={disableSubmitButton}>
              Post
            </Button>
          </div>
        </>
      )}
    </Col>
  );
}
