import React from 'react';

function GridWrapper({grid, size = 12}) {
  return (
    <div className="container">
      <div className="row">
        {grid.map((item, i) => (
          <div key={`${item.title} ${i}`} className={`col-sm-${size}`}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GridWrapper;
