import React, {PureComponent, Component} from 'react';
import PropTypes from 'prop-types';
import Guidelines from 'routes/Products/components/Guidelines';
import {Button, Card, CardHeader, CardBody, CardFooter, Col, Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {connect} from 'react-redux';
import ProductsOnboard from 'routes/Products/containers/ProductsOnboard';
import VideoResource from 'routes/Products/components/VideoResource';
import ChecklistOnboarding from 'routes/Products/components/ChecklistOnboarding';
import Video from 'routes/Products/components/Video';
import getContent from 'lib/getContent';

import {faArrowCircleRight} from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

@connect(state => ({authToken: state.user.authToken}), {})
class GettingStarted extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canShowOnbaord: true,
      runTour: true,
      currentWeek: null
    };
    const path = location.pathname.split('/');
    this.ProductInPath = path[2];

    //get inaugural-event from getting-started week data (from WLC/index)

    // this.inauguralEvent = this.props.gettingStartedWeek.Children.find(
    //   item => item.slug === 'wlc-getting-started-resources'
    // ).Children.find(item => item.slug.includes('welcoming-event'));

    //this.onBoardVideo = props.gettingStartedWeek.Children.find(item => item.type === 'video');

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.getWeek = this.getWeek.bind(this);
    this.tourSteps = [
      {
        target: 'body',
        placement: 'center',
        title: 'Welcome to WHOLE Life Club',
        content:
          'Welcome to WLC’s Getting Started page! Spend time on this page before exploring other parts of the Club.'
      },
      {
        target: '.checklistContainer',
        title: 'Orientation check list',
        content:
          'Here is your WHOLE Life Club orientation check list.  Finish each item to learn how to use WLC and become connected to the community.',
        placement: 'auto'
      },
      {
        target: '.post-form',
        title: 'Join the conversation',
        content: 'On many WLC pages you will be able to post your thoughts along with the other members.',
        placement: 'auto'
      }
    ];
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    //responsive js stuff
    this.updateWindowDimensions();

    this.getOnboardList();
    this.getWeek();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  getOnboardList() {
    //slug wlc-onboard-list
    const contentSlug = 'wlc-onboard-list',
      productSlug = 'wlc';
    getContent(contentSlug, productSlug, this.props.authToken)
      .then(res => {
        const onboardList = res.Children; //for clarity

        this.setState({
          onboardList
        });
      })
      .catch(e => {
        console.log('err in getOnboardList ', e.message);
      });
  }
  getWeek() {
    //wlc-18-weeks-list/wlc?depth=2
    //this.sortedWeeksList[this.sortedWeeksList.length - 1]
    const contentSlug = 'wlc-18-weeks-list',
      productSlug = 'wlc'; //for clarity
    getContent(contentSlug, productSlug, this.props.authToken)
      .then(res => {
        const currentWeek = res.Children[0];

        this.setState({
          currentWeek: currentWeek,
          onBoardVideo: currentWeek.Children.find(item => item.type === 'video'),
          inauguralEvent: currentWeek.Children.find(
            item => item.slug === 'wlc-getting-started-resources'
          ).Children.find(item => item.slug.includes('welcoming-event'))
        });
      })
      .catch(e => {
        console.log('err in getting-started ', e.message);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
    //updating the order of the tour steps based on screen width (break at ipad)
    if (window.innerWidth < 769) {
      //do nothing, keep original order
      const responsiveTourSteps = [this.tourSteps[0], this.tourSteps[1], this.tourSteps[2]];
      this.setState({
        tourSteps: responsiveTourSteps
      });
    } else {
      const responsiveTourSteps = [this.tourSteps[0], this.tourSteps[1], this.tourSteps[2], this.tourSteps[3]];
      this.setState({
        tourSteps: responsiveTourSteps
      });
    }
  }
  //click on weeksList and action hour, takes them there
  //
  handleNav = slug => {
    this.props.history.push(`/products/${this.ProductInPath}/${slug}`);
  };

  handleJoyrideCallback = data => {
    const {status, type} = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({runTour: false});
      this.props.updateTourStatus('gettingStarted', true);
    }
  };

  render() {
    const {toursCompleted, toursLoaded} = this.props;
    const showTour = !!toursLoaded && !toursCompleted.gettingStarted;
    const {currentWeek, inauguralEvent, onboardList, onBoardVideo} = this.state;

    console.log('onboardList in GettingStarted', onboardList);
    return (
      <Container className="py-5">
        <ReactJoyride
          continuous
          callback={this.handleJoyrideCallback}
          run={this.state.runTour && showTour}
          steps={this.state.tourSteps}
          scrollToFirstStep
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000
            }
          }}
        />
        <Row className="pb-5">
          <Col xs="12">
            <h1>Orientation</h1>
            <p>
              Whether you’re new to WHOLE Life Club, or simply want a refresher on how to{' '}
              <strong>get the most out of your membership,</strong> this page will help you get started.
            </p>
          </Col>
          <Col xs="12" lg="4">
            <Button color="dark" block onClick={() => this.handleNav('current-week')}>
              Go to latest weekly guide <FontAwesomeIcon icon={faArrowCircleRight} />
            </Button>
          </Col>
        </Row>
        <Row className="pb-1">
          <Col xs="12" lg="6" className="pb-3">
            <div className="h-100 gettingStartedVideoContainer">
              <h5 style={{paddingBottom: '10px', marginBottom: '40px', borderBottom: '1px solid #BACAD5'}}>
                Getting Started video
              </h5>
              {onBoardVideo ? <Video {...onBoardVideo} /> : <Video key="loading-video" />}
              {this.props.detailText ? (
                <p>{this.props.detailText}</p>
              ) : (
                <div>
                  <p>Watch the video, and use the checklist, to get rolling on the right foot (and food!).</p>
                  <p>
                    This is the beginning of a meaningful journey we’ll be on together. Enjoy the content at your own
                    pace, engage with other community members, ask for support from our community management staff and,
                    most importantly, have fun!
                  </p>
                </div>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6" className="pb-3">
            {onboardList ? (
              <ChecklistOnboarding onboardList={onboardList} {...this.props} />
            ) : (
              <div>Loading checklist...</div>
            )}
          </Col>
        </Row>

        <Row className="py-5 px-0 mx-0 inauguralEvent">
          <Col className="px-0 mx-0 ">
            {inauguralEvent ? <VideoResource {...inauguralEvent} /> : <Video key="loading-video" />}
          </Col>
        </Row>
      </Container>
    );
  }
}

// GettingStarted.propTypes = {
//   weeksList: PropTypes.shape({}).isRequired,
//   actionHour: PropTypes.shape({}).isRequired
// };

export default withRouter(GettingStarted);
