//in staging
const downloadPdf = {
	id: 21113,
	name: 'HHC - Guidebook - Heart Food: 15 Superfoods Your Heart Will Love',
	title: 'Guidebook - Heart Food: 15 Superfoods Your Heart Will Love',
	slug: 'guidebook-heart-food-15-superfoods-your-heart-will-love-download',
	type: 'download',
	published: true,
	free: false,
	data: {
		url:
			'https://cdn.foodrevolution.org/members/pdf/hhc/guidebook-heart-food-15-superfoods-your-heart-will-love.pdf',
		text: 'Guidebook',
		type: 'PDF',
		thumb: {
			url:
				'https://cdn.foodrevolution.org/members/assets/thumbnails/guidebook-heart-food-15-superfoods-your-heart-will-love.png'
		}
	},
	ContentTags: [],
	Children: [],
	updatedAt: '2021-03-01T18:59:17.852Z',
	createdAt: '2021-03-01T18:59:17.852Z'
};
/*
{
    "id": 21129,
    "name": "HHC - Summary Workbook",
    "title": "HHC Summary Workbook",
    "slug": "hhc-summary-workbook-download",
    "type": "download",
    "published": true,
    "free": false,
    "data": {
        "url": "https://cdn.foodrevolution.org/members/pdf/hhc/guidebook-heart-food-15-superfoods-your-heart-will-love.pdf",
        "text": "Workbook",
        "type": "PDF",
        "thumb": {
            "url": "https://cdn.foodrevolution.org/members/assets/thumbnails/guidebook-heart-food-15-superfoods-your-heart-will-love.png"
        }
    },
    "Children": [],
    "ContentTags": [],
    "updatedAt": "2021-03-01T23:21:47.925Z",
    "createdAt": "2021-03-01T23:21:47.925Z"
}
*/

const resourcesList = {
	id: 21114,
	name: 'HHC Resources',
	title: 'HHC - Resources',
	slug: 'hhc-resources',
	type: 'resourcesList',
	data: {},
	published: true,
	publishDate: null,
	free: false,
	Children: [ downloadPdf ]
};

export default resourcesList;
/*
{
    "id": 21114,
    "name": "HHC Resources",
    "title": "HHC - Resources",
    "slug": "hhc-resources",
    "type": "resourcesList",
    "data": {},
    "published": true,
    "publishDate": null,
    "free": false,
    "updatedAt": "2021-03-01T19:08:45.455Z",
    "createdAt": "2021-03-01T19:08:45.455Z"
}

resourcesLIst to downloadPDF
{
    "id": 42012,
    "ParentId": 21114,
    "ChildId": 21113,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T19:09:53.382Z",
    "createdAt": "2021-03-01T19:09:53.382Z"
}

resourcesList to download (workbook)
{
    "id": 42036,
    "ParentId": 21114,
    "ChildId": 21129,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T23:22:30.704Z",
    "createdAt": "2021-03-01T23:22:30.704Z"
}

module to resourcesList
{
    "id": 42013,
    "ParentId": 21107,
    "ChildId": 21114,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T19:10:57.242Z",
    "createdAt": "2021-03-01T19:10:57.242Z"
}
*/
