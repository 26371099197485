import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import Markdown from '../Markdown';
import {withRouter} from 'react-router';
import {FormGroup, Input} from 'reactstrap';
import {json} from 'body-parser';
const ListWithImage = ({data, description, list, history, includeSearch, placeholder, title}) => {
  const downloadAnchor = React.createRef();
  const [searchText, setSearchText] = React.useState('');
  navigateToContent = (e, slug, type) => {
    e.preventDefault();
    const path = location.pathname.split('/');
    const productInPath = path[2];
    if (['download', 'link'].includes(type)) {
      downloadAnchor.current.click();
    } else {
      history.push(`/products/${productInPath}/${slug}`);
    }
  };
  return (
    <Container className="pt-5 mb-5">
      {title || description ? (
        <Row>
          <Col>
            <h4 className="moduleTitle">{title ? title : ''}</h4>
            {description ? <Markdown source={description} /> : null}
          </Col>
        </Row>
      ) : (
        '  '
      )}
      {includeSearch ? (
        <Row className="align-items-end">
          {/* input form for searchText */}
          <FormGroup>
            <Input
              type="text"
              onChange={e => setSearchText(e.target.value)}
              value={searchText}
              placeholder={placeholder ? placeholder : 'search'}
            />
          </FormGroup>
        </Row>
      ) : null}
      {list
        ? searchText
          ? list
              .filter(item => {
                return item.title && item.title.toLowerCase().includes(searchText.toLowerCase());
              })
              .map((item, i) => {
                const {title, subtitle, image, description} = item.displayProps;
                return (
                  <Row className="border" onClick={e => navigateToContent(e, slug, type)}>
                    <Col xs="4" sm="4" className="d-flex align-items-center justify-content-center">
                      {image ? <img style={{width: '100%'}} src={image.url} alt={image.alt} /> : null}
                    </Col>
                    <Col xs="8" sm="8">
                      {title ? <h5> {title}</h5> : null}
                      {subtitle ? <h6>{subtitle}</h6> : null}
                      {description ? <Markdown source={description} /> : null}
                    </Col>
                  </Row>
                );
              })
          : list.map(item => {
              if (!item.displayProps) return null;
              const {title, subtitle, image, description} = item.displayProps;
              const {slug, type} = item;
              return (
                <Row className="border" onClick={e => navigateToContent(e, slug, type)}>
                  <Col xs="4" sm="4" className="d-flex align-items-center justify-content-center">
                    {image ? <img style={{width: '100%'}} src={image.url} alt={image.alt} /> : null}
                  </Col>
                  <Col xs="8" sm="8">
                    {title ? <h5> {title}</h5> : null}
                    {subtitle ? <h6>{subtitle}</h6> : null}
                    {description ? <Markdown source={description} /> : null}
                  </Col>
                </Row>
              );
            })
        : null}
    </Container>
  );
};

export default withRouter(ListWithImage);
