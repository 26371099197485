import React from 'react';
import PropTypes from 'prop-types';
import {Container, Col, Row} from 'reactstrap';
import styles from 'styles/index.scss';

const {profileBraintreeFormWarning, profileBraintreeFormField} = styles;

const CVVField = props => {
  const {
    cvvBlurred,
    cvvValid,
    cvvBlurredExisting,
    cvvValidExisting
  } = props.cvvValidity;

  const isExisting = props.isExisting;

  let idPrefix = isExisting ? "existing" : "new";

  return (
        <Col xs={6} style={{padding: 0}}>
          {isExisting ?
          <React.Fragment>
          <div
            id={idPrefix+"-cvv"}
            className={profileBraintreeFormField}
            style={{marginBottom: cvvBlurredExisting && !cvvValidExisting ? '0' : '32px'}}
          />
          {cvvBlurredExisting && !cvvValidExisting ? (
            <div className={profileBraintreeFormWarning}>
              <span>Invalid CVV</span>
            </div>
          ) : null}
          </React.Fragment>
          :
          <React.Fragment>
          <div
            id={idPrefix+"-cvv"}
            className={profileBraintreeFormField}
            style={{marginBottom: cvvBlurred && !cvvValid ? '0' : '32px'}}
          />
          {cvvBlurred && !cvvValid ? (
            <div className={profileBraintreeFormWarning}>
              <span>Invalid CVV</span>
            </div>
          ) : null}
          </React.Fragment>
          }
        </Col>

  );
};

CVVField.propTypes = {
  cvvValidity: PropTypes.shape({
    cvvBlurred: PropTypes.bool.isRequired,
    cvvValid: PropTypes.bool.isRequired,
    cvvBlurredExisting: PropTypes.bool.isRequired,
    cvvValidExisting: PropTypes.bool.isRequired
  }).isRequired
};

// const mapStateToProps = state => ({
//   braintreeValidity: state.checkout.braintreeValidity,
// });

export default CVVField;
