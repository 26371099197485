import React, {PureComponent} from 'react';
import {Button, Container, Col, Row, Modal, ModalHeader} from 'reactstrap';
import ReactMarkdown from 'lib/react-markdown';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons/faInfoCircle';

class Speaker extends PureComponent {
  state = {isOpen: false};
  setIsOpen = isOpen => this.setState({isOpen});
  close = () => this.setIsOpen(false);
  onClick = () => this.setIsOpen(true);
  toggle = () => this.setState(({isOpen}) => ({isOpen: !isOpen}));
  render() {
    const {cols, modalLink, name, presentation, description, image} = this.props;
    const {isOpen} = this.state;
    const {onClick, toggle} = this;
    return (
      <Col className={classNames(`speaker-info`, cols)}>
        <Row className="speaker-info-row">
          <Col xs="12" className="speaker-info-col">
            <div className="speaker-info-wrap">
              <div className="speaker-info-image mb-md-2">
                {!modalLink ? (
                  <a className="speaker-list-item-link" onClick={onClick}>
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </a>
                ) : (
                  <div className="speaker-list-item-image">
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </div>
                )}
              </div>
              <div className="speaker-info-details my-auto">
                <p className="speaker-name text-700 mb-0">{name}</p>
                <p className="speaker-presentation text-small mb-0">{presentation}</p>
              </div>
              {!modalLink ? (
                <div className="speaker-info-more">
                  <div className="mt-md-3 mb-0">
                    <div className="speaker-info-btn" onClick={onClick}>
                      <FontAwesomeIcon className="speaker-info-btn-icon d-md-none" icon={faInfoCircle} />
                      <span className="d-none d-md-inline">Learn&nbsp;</span>
                      More
                    </div>
                  </div>

                  <Modal isOpen={isOpen} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} />
                    <Container className="px-sm-4 pt-0 pb-5" fluid>
                      <Row className="d-flex align-items-center justify-content-center mb-4 mx-0">
                        <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                          <img
                            className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                            src={image}
                          />
                        </Col>
                        <Col xs="12" lg="8">
                          <div className="speaker-details">
                            <h3 className="speaker-name section-heading mb-2">{name}</h3>
                            <h5 className="speaker-presentation m-0">{presentation}</h5>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mx-0">
                        <Col xs="12">
                          <div className="speaker-bio-info">
                            <ReactMarkdown source={description || ''} />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}
Speaker.defaultProps = {
  cols: 'col-12 col-md-4 col-lg-3'
};

export function SpeakerGrid({collection, speakers, cols}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers.map((speaker, i) => <Speaker cols={cols} {...speaker} key={`${collection}-${i}`} />);
}

export default function SpeakersGroupByCollection({collections}) {
  return collections.map(({name, speakers}) => (
    <Col xs="12" className="d-flex align-items-stretch flex-column my-4">
      <h3 className="section-heading text-center mb-5">{name}</h3>
      <Row className="d-flex align-items-stretch justify-content-center h-100">
        <SpeakerGrid cols="col-12 col-md-3" collection={name} speakers={speakers} />
      </Row>
    </Col>
  ));
}
