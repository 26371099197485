/* eslint-disable max-len */

import React, { PureComponent } from 'react';

import Api from 'lib/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import styles from 'styles/index.scss';

const { cancelModalContainer, cancelModalButtons, cancelModalPrompt } = styles;
@connect(state => ({
  user: state.user
}))

//Zapier Web Hook
//https://hooks.zapier.com/hooks/catch/5025520/vonafl/
class CancelModal extends PureComponent {
  constructor(props) {
    super(props);
    const currentTime = new Date();
    this.state = {
      specialMessage: null, //full modal message, currently used when user decides to stay
      date: currentTime.getTime(),
      firstName: this.props.user.firstName || 'na',
      lastName: this.props.user.lastName || 'na',
      email: this.props.user.email || 'na',
      type: 'WLC Cancellation',
      q1: null,
      q2: null,
      q3: 'confirmed',
      supplement: null //one question can have a supplement answer to it in text format
    };
    this.zapUrl = process.env.CANCELLATION_ZAP;
    this.messageLength = this.props.messageLength || 10000; //miliseconds of special message seen
    this.messageRedirect = this.props.messageRedirect || '/products/wlc/current-week'; //redirect after special message is seen
    this.suplemented = 'q1';
    this.handleChange = this.handleChange.bind(this);
    this.handleCancelation = this.handleCancelation.bind(this);
    this.handleRevert = this.handleRevert.bind(this);
  }

  handleChange(e) {
    if (e.target.type == 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  //handles the full cancelation of subscription
  handleCancelation() {
    const opts = { removeHeaders: true, mode: 'no-cors' };
    this.setState({
      specialMessage: {
        heading: 'We are sorry to see you go! ',
        body: 'Our team will follow up via email with a confirmation.',
        button: {
          text: 'Close',
          onClick: this.props.toggleCancel
        }
      },
      hideSpecialButton: true
    });

    if (this.state.supplement) {
      //'https://hooks.zapier.com/hooks/catch/5025520/vonafl/
      //if the question has a supplement input, then use it as the answer for the question
      this.setState({ [this.suplemented]: this.state.supplement }, () => {
        Api.post(this.zapUrl, this.state, null, opts);
      });
    } else {
      Api.post(this.zapUrl, this.state, null, opts);
    }

    //call this store method for cancellation in backend
    this.props.cancelSubscription();
  }
  //handles when users changes his mind to not cancel
  handleRevert() {
    this.setState({
      specialMessage: {
        heading: 'Thanks for staying with WHOLE Life Club.',
        body:
          'We’re so glad that you’re part of our community of WHOLE Lifers! You will be redirected to this week’s Weekly Guide in a few seconds. Please let us know if there is something we can do to make WLC even better!',
        button: {
          text: 'Go to the Weekly Guide',
          href: this.messageRedirect
        }
      }
    });

    // Wait for 10 seconds before redirecting
    setTimeout(() => {
      window.location.href = this.messageRedirect;
    }, 10000); // 10000 milliseconds = 10 seconds
  }

  render() {
    const {
      isOpen,
      toggleCancel,
      cancelingProduct,
      cancelSuccess,
      cancelingNumber,
      cancelSubscription,
      cancelFail,
      fullPriceMonthly,
      fullPriceAnnual
    } = this.props;
    const { hideSpecialButton, showMessage, specialMessage } = this.state;
    const formComplete = !!this.state.q1; //q3 is the confirmation question

    if (specialMessage) {
      return (
        <Modal isOpen={isOpen} centered>
          <ModalHeader>{specialMessage.heading}</ModalHeader>
          <ModalBody>{specialMessage.body}</ModalBody>
          <ModalFooter>
            <Container>
              <Row className="justify-content-end">
                <Button color="light" onClick={this.props.toggleCancel} className="mr-3">
                  Close
                </Button>
                {hideSpecialButton ? (
                  ''
                ) : (
                  <Button color="primary" href={specialMessage.button.href} onClick={specialMessage.button.onClick}>
                    {specialMessage.button.text}
                  </Button>
                )}
              </Row>
            </Container>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader>
          {cancelFail ? 'Whoops!' : 'To cancel your WHOLE Life Club membership, please follow these steps.'}
        </ModalHeader>

        <ModalBody className={showMessage ? 'd-none ' : ''}>
          {cancelFail ? (
            <span>
              We weren't able to cancel this subscription for you. For immediate help, please call customer service.
            </span>
          ) : (
            <div>
              <Form>
                <FormGroup>
                  <Label for="q1">What is the primary reason you are canceling your WHOLE Life Club membership?</Label>
                  <Input onChange={e => this.handleChange(e)} type="select" name="q1" value={this.state.q1}>
                    <option value="" disabled={this.state.q1 !== null}>
                      -- Please select a reason --
                    </option>
                    <option value="goalAchieved">I achieved my goal and am ready to continue on my own</option>
                    <option value="noValue">I didn't find value in the resources provided</option>
                    <option value="notConnected">I didn’t feel connected to the community</option>
                    <option value="contentOverwhelming">I'm struggling to keep up with the content</option>
                    <option value="noProgress">
                      I'm not making any progress in my health or healthy eating journey
                    </option>
                    <option value="noInterest">I’m no longer interested in the whole food plant-based lifestyle</option>
                    <option value="technicalIssues">I’m having technical issues with the membership</option>
                    <option value="betterAlternative">I found a better alternative</option>
                    <option value="other">Other (please specify)</option>
                  </Input>
                </FormGroup>
                <FormGroup className={this.state.q1 === 'Other' ? '' : 'd-none'}>
                  <Label for="supplement">
                    If you could make one change to WHOLE Life Club that would make staying an absolute "YES" for you,
                    what would that change be?
                  </Label>
                  <Input
                    onChange={e => this.handleChange(e)}
                    type="textarea"
                    name="supplement"
                    value={this.state.supplement}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="q2">
                    If you could make one change to WHOLE Life Club that would make staying an absolute "YES" for you,
                    what would that change be?{' '}
                  </Label>
                  <Input onChange={e => this.handleChange(e)} type="textarea" name="q2" value={this.state.q2} />
                </FormGroup>
              </Form>
              <p className="mt-4">
                By canceling, you’ll lose all of your access to the delicious recipes, action checklists, action of the
                week videos, action hours, and the amazing community. Plus, you’ll lose any special pricing you may have
                received when you initially joined. We’ll miss you terribly if you go - and we support you in doing
                what’s best for you.
              </p>
              <p>Are you really, really sure? Or will you give us one more chance to work things out?</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row className="justify-content-end">
              <Col xs="12" md="auto" className="order-md-2">
                <Button color="blue-gray" onClick={this.handleCancelation}>
                  Yes, please cancel.
                </Button>
              </Col>
              <Col xs="12" md="auto" className="order-md-1 mt-3 mt-md-0">
                <Button color="primary" onClick={this.handleRevert} style={{ cursor: 'pointer' }}>
                  Nevermind, keep my subscription.
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    );
  }
}

CancelModal.propTypes = {};

export default withRouter(CancelModal);
