/*default filter categories for recipes page
 */
export const filterCategories = [
  {
    categoryName: 'Geographical Region',
    categoryType: 'defaultTag',
    open: false,
    tags: [
      {name: 'Africa', active: false, excluded: false},
      {name: 'American Region', active: false, excluded: false},
      {name: 'British Isles', active: false, excluded: false},
      {name: 'Caribbean', active: false, excluded: false},
      {name: 'China', active: false, excluded: false},
      {name: 'Eastern Europe', active: false, excluded: false},
      {
        name: 'France',
        active: false,
        excluded: false
      },
      {name: 'India & South Asia', active: false, excluded: false},
      {name: 'Italy', active: false, excluded: false},
      {name: 'Japan', active: false, excluded: false},
      {name: 'Korea', active: false, excluded: false},
      {name: 'Mediterranean', active: false, excluded: false},
      {name: 'Mexico & Latin America', active: false, excluded: false},
      {name: 'Middle East', active: false, excluded: false},
      {name: 'Southeast Asia', active: false, excluded: false},
      {name: 'Spain', active: false, excluded: false},
      {name: 'Western Europe', active: false, excluded: false}
    ]
  },
  {
    categoryName: 'Type of Recipe',
    categoryType: 'defaultTag',
    open: false,
    tags: [
      {name: 'Appetizers', active: false, excluded: false},
      {name: 'Beverages', active: false, excluded: false},
      {name: 'Bowls & Smoothies', active: false, excluded: false},
      {name: 'Breakfast', active: false, excluded: false},
      {name: 'Desserts', active: false, excluded: false},
      {name: 'Holidays', active: false, excluded: false},
      {name: 'Mains', active: false, excluded: false},
      {name: 'Pasta & Noodles', active: false, excluded: false},
      {name: 'Salads', active: false, excluded: false},
      {
        name: 'Sandwiches & Wraps',
        active: false,
        excluded: false
      },
      {name: 'Sauces, Spreads & Dips', active: false, excluded: false},
      {name: 'Sides', active: false, excluded: false},
      {name: 'Snacks', active: false, excluded: false},
      {name: 'Soups & Stews', active: false, excluded: false}
    ]
  },
  {
    categoryName: 'Common Ingredients',
    categoryType: 'ingredient',
    open: false,
    tags: [
      {type: 'ingredient', name: 'beans', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'berries', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'carrots', active: false, excluded: false, andOnly: true},
      {
        type: 'ingredient',
        name: 'flaxseeds',
        active: false,
        excluded: false,
        andOnly: true
      },
      {type: 'ingredient', name: 'garlic', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'ginger', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'kale', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'lentils', active: false, excluded: false, andOnly: true},

      {type: 'ingredient', name: 'mushrooms', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'nutritional yeast', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'nuts', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'onions', active: false, excluded: false, andOnly: true},
      {type: 'ingredient', name: 'quinoa', active: false, excluded: false, andOnly: true},
      {
        type: 'ingredient',
        name: 'red bell peppers',
        active: false,
        excluded: false,
        andOnly: true
      },
      {type: 'ingredient', name: 'turmeric', active: false, excluded: false, andOnly: true}
    ]
  }
]; ///andOnly tags work like &&, if there are 2, both must be present //whether or not the dropdown is open or not
