import React from 'react';
import {withRouter} from 'react-router';
import {Container} from 'reactstrap';
// the extra container is not good, but it fits with legacy alignment
const BackNav = props => {
  const {darkMode} = props;
  return (
    <Container>
      <div className="row">
        <div
          onClick={() => props.history.goBack()}
          className="py-3"
          style={darkMode ? {cursor: 'pointer', color: 'white'} : {cursor: 'pointer'}}
        >
          {' '}
          &lt; Back
        </div>
      </div>
    </Container>
  );
};

export default withRouter(BackNav);
