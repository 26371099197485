import React from 'react';

//Displays the module progress on the Dashboard
//i.e.  "1/7 Completed     cont. to M2"
function ModuleProgress(props) {
  const {modules, moduleStates, product, handleNav, calculateModuleProgress} = props;
  //calculateModuleProgress definted in Dashboard
  const progress = calculateModuleProgress(moduleStates); //find users module

  return (
    <div className="container  d-flex justify-content-between h-100  align-items-center text-white sticky-top zIndex100 moduleProgress">
      <div className="  test-left  align-middle">
        {moduleStates && progress ? `${progress.totalModulesCompleted}/${modules.length} Completed ` : null}
      </div>

      {/*only show button if this module isn't complete*/}
      {moduleStates && progress && progress.currentModuleNumber !== moduleStates.length ? (
        <div className="text-right">
          <button
            type="button"
            className="btn btn-success rounded-4 pointerGlobal"
            onClick={() => {
              // finding the slug of the current module to be done and passing in the slug to navigate to
              handleNav(product.Children.find(item => item.id === moduleStates[progress.currentModuleIndex].id).slug);
            }}
          >
            {progress.useString ? `Continue to  ${progress.useString}` : `Continue to M${progress.currentModuleNumber}`}{' '}
            >
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default ModuleProgress;
