import React from 'react';
import {Alert, BreadcrumbItem, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';
import AssessmentWrapper from '../ModulePage/components/AssessmentWrapper';

const AssessmentsPage = ({
  product,
  assessments: _assessments,
  assessmentStates = [],
  productInPath,
  examsAccessible
}) => {
  const assessments = _assessments.filter(assessment => assessment.slug.includes('-exam'));
  return (
    <Container>
      <BreadCrumbs>
        <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
          {product.title}
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Exams
        </BreadcrumbItem>
      </BreadCrumbs>
      <h1 className="mt-0">Exams</h1>
      {assessments.length ? (
        assessments.map((assessment, i) => {
          const assessmentState = assessmentStates.find(item => assessment.id === item.id);
          isOpen = true; //open the first one for unstructured
          // Wraps a single lesson, 'lesson one'
          return (
            <AssessmentWrapper
              isOpen={isOpen}
              assessment={assessment}
              assessmentState={assessmentState}
              assessmentId={assessment.id}
              isUnstructured={false}
              examsAccessible={examsAccessible}
            />
          );
        })
      ) : (
        <Alert>No exams available.</Alert>
      )}
    </Container>
  );
};

export default AssessmentsPage;
