import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, Label} from 'reactstrap';

class Textarea extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {pristine: true, value: (props.data && props.data.placeholder) || ''};
  }

  handleInput = ({target: {value}}) => {
    this.setState({...this.state, value});
    this.updateParent();
  };
  //update parent component with the value
  updateParent = () => {
    this.props.onChange({name: this.props.name, value: this.state.value});
  };

  render() {
    const {
      id,
      name,
      data: {text}
    } = this.props;
    return (
      <FormGroup>
        <Label for={name}>{text}</Label>
        <Input
          onChange={this.handleInput}
          onBlur={this.handleBlur}
          type="textarea"
          name={name}
          id={name}
          value={this.state.value}
        />
      </FormGroup>
    );
  }
}

Textarea.propTypes = {
  // TODO
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired
};

export default Textarea;
