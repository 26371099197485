 

import React, {Component} from 'react';

import {BreadcrumbItem, Container, Row, Col} from 'reactstrap';

import Bonuses from '../../../../components/Bonuses';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

import ResourcesListTwo from 'routes/Products/components/ResourcesListTwo';
import AudioPlayerMobile from '../../../../components/AudioPlayerMobile/index';

  
const InterviewsPage = props => {
  const {interviews, bonuses, product, resources} = props;
  const productInPath = location.pathname.split('/')[2];
  return (
    <div>
      {/* <BackNav /> */}
      <Container>
        <BreadCrumbs>
          <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
            {product.title}
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Expert Interviews
          </BreadcrumbItem>
        </BreadCrumbs>
        <h1>Expert Interviews</h1>
      </Container>

      {/* the course book is attached to bonuses (since it is a bonus component) */}
      {bonuses ? <Bonuses Children={bonuses} data={{description: ''}} /> : null}
      <Container>
        {interviews
            ? interviews.map(item => {
                return (
                  <div>
                    <h4>
                      {item.Children
                        ? item.Children.find(child => child.type === 'audioInterview').Children
                          ? item.Children.find(child => child.type === 'audioInterview').Children.find(
                              child => child.type === 'bio'
                            ).title
                          : ''
                        : ''}
                    </h4>
                    <AudioPlayerMobile {...item} colSizes={{xs: 12, md: 6}} />
                  </div>
                );
              })
            : null}
      </Container>
    </div>
  );
};

export default InterviewsPage;
