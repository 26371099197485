import React from 'react';
import pluralize from 'pluralize';
function LessonsHeader(props) {
  const { moduleState } = props;

  return (
    <div className="d-flex align-items-center py-3 lessonsHeader">
      <div className="py-1">
        <span>Lessons:</span>
      </div>
      <div className="ml-auto  align-items-center">
        {moduleState && moduleState.checklist && moduleState.numPossible ? (
          <div className="float-right pl-2 pr-0 align-middle py-1">
            {`${moduleState.checklist.numCompleted}/${moduleState.checklist.numPossible} checklist`}
          </div>
        ) : null}
        {moduleState && moduleState.checklist && moduleState.numPossible && moduleState.lessons.length ? (
          <div className="float-right seperator bg-dark py-1"> </div>
        ) : null}
        {moduleState && moduleState.lessons ? (
          <div className="float-right px-2 py-1">
            {moduleState.lessons.filter(item => item.completed).length}/{moduleState.lessons.length}{' '}
            completed
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LessonsHeader;
