import React from 'react';
import {Button, Container, Row, Col} from 'reactstrap';
import CountdownTimer from 'routes/Products/components/CountdownTimer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {faVolumeHigh} from '@fortawesome/free-solid-svg-icons/faVolumeHigh';
import List from 'components/List';
import Markdown from 'routes/Products/components/Markdown';
import HeroHeader, {HeroHeaderLeft, HeroHeaderRight, HeroHeaderBody} from 'routes/Products/components/HeroHeader';
import BackNav from 'routes/Products/components/BackNav';
const Header = ({
  backgroundImg,
  bios,
  customPlayButton,
  customPreviewButton,
  date,
  description,
  disablePreviewVideo,
  downloads,
  enableBackNav,
  hasAudio,
  hasVideo,
  hasVideoVariation,
  hideTimer,
  logoImg,
  previewVideoLink,
  title,
  subtitle,
  showModal,
  showAudioModal,
  showVideoVariationModal,
  speakersString,
  upgradeSlug,
  handleUpgradeModal,
  owned,
  unlockableProductName
}) => {
  console.log('owned in header', owned, 'has video', hasVideo);
  return (
    <HeroHeader
      logo={{
        src: logoImg,
        alt: title,
        type: 'image/png'
      }}
      background={{
        src: backgroundImg,
        alt: title,
        type: 'image/jpeg'
      }}
      enableBackNav={enableBackNav}
      previewVideoUrl={previewVideoLink}
      date={date}
      disablePreviewVideo={disablePreviewVideo}
    >
      {date || previewVideoLink || hasVideo || hasAudio ? (
        <HeroHeaderLeft>
          {date && new Date(date) > new Date() && !hideTimer ? (
            <div className="mb-5">
              <h4 className="text-center text-light">Premiering in</h4>
              <CountdownTimer time={date} className="text-light" color="white" size="sm" />
            </div>
          ) : null}
          {!owned && unlockableProductName ? (
            <p className="text-center">Available with {unlockableProductName}</p>
          ) : null}
          {previewVideoLink && (!owned || !hasVideo) ? (
            <Button size="lg" block color="light" onClick={showModal}>
              <FontAwesomeIcon icon={faPlay} className="mr-2" />
              {customPreviewButton ? customPreviewButton : 'Preview This Episode'}
            </Button>
          ) : hasVideo ? (
            <Button size="lg" block color="light" onClick={showModal}>
              <FontAwesomeIcon icon={faPlay} className="mr-2" />
              {customPlayButton ? customPlayButton : 'Play This Episode'}
            </Button>
          ) : null}
          {/* video variation */}
          {owned && hasVideoVariation ? (
            <Button size="lg" block color="light" onClick={showVideoVariationModal}>
              <FontAwesomeIcon icon={faPlay} className="mr-2" />
              {'Play Broadcast Version'}
            </Button>
          ) : null}
          {owned && hasAudio ? (
            <Button size="lg" block color="light" onClick={showAudioModal}>
              <FontAwesomeIcon icon={faVolumeHigh} className="mr-2" /> Play Audio Only
            </Button>
          ) : (
            ''
          )}
          {upgradeSlug && !owned ? (
            <Button size="lg" block color="light" onClick={() => handleUpgradeModal({upgradeSlug})}>
              Upgrade Now
            </Button>
          ) : (
            ''
          )}
        </HeroHeaderLeft>
      ) : null}
      <HeroHeaderBody>
        <h2>{title}</h2>
        {/* <p>{subtitle ? subtitle : ''}</p> */}
        <p style={{maxHeight: 80, overflowY: 'scroll'}}>{description ? description : subtitle ? subtitle : ''}</p>
      </HeroHeaderBody>
      {downloads.length ? (
        <HeroHeaderRight>
          <h5>Downloads</h5>
          <List contentItems={downloads} color="white" />
        </HeroHeaderRight>
      ) : null}
    </HeroHeader>
  );
};

export default Header;
