import {ModuleOne, ModuleTwo, ModuleThree} from './ModuleOne';

const testData = {
  id: 7146, //actual id in staging of contentitem
  name: 'Plant Powered & Thriving',
  title: 'Plant Powered & Thriving',
  slug: 'ppt19',
  type: 'ppt',
  data: {
    description: 'This is the Plant Powered & Thriving class',

    url: 'https://youtu.be/LC-EdlHK6kk',
    title: 'PPT Intro Video'
  },
  published: true,
  Children: [ModuleOne, ModuleTwo, ModuleThree]
};

export default testData;
