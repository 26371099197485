import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {AddToCalendarButton} from 'add-to-calendar-button-react';
const TIMEZONE = process.env.TIMEZONE;
// Helper function to transform your event format to the required format
function transformEventForDownload(event) {
  const {originalDate, date, title, description, timezone, imageURL: url, location} = event;

  // if originalDate is not provided, use date
  const dateFormatted = moment.tz((originalDate && originalDate._i) || (date && date._i), TIMEZONE);

  // Extract start date and time
  const startDate = dateFormatted.format('YYYY-MM-DD');
  const startTime = dateFormatted.format('HH:mm');

  const endDate = dateFormatted
    .clone()
    .add(1, 'hour')
    .format('YYYY-MM-DD');
  //add an hour
  const endTime = dateFormatted
    .clone()
    .add(1, 'hour')
    .format('HH:mm');

  return {
    title,
    description,
    startTime,
    endTime,
    startDate,
    endDate,
    location: location || 'Online Event',
    url,
    filename: title.replace(/\s+/g, '_')
  };
}

const CalendarEventDownload = ({event}) => {
  if (!event || !event.title || !event.date) return null;
  const formattedEvent = transformEventForDownload(event);

  return (
    <div>
      <AddToCalendarButton
        id="custom-add-to-calendar-button"
        name={formattedEvent.title}
        description={formattedEvent.description}
        startDate={formattedEvent.startDate}
        startTime={formattedEvent.startTime}
        endDate={formattedEvent.endDate}
        endTime={formattedEvent.endTime}
        location={formattedEvent.location}
        options={['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo']}
        timeZone={TIMEZONE}
      />
    </div>
  );
};

// Define the expected props and their types
CalendarEventDownload.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }).isRequired
};

export default CalendarEventDownload;
