/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'reactstrap';
import Markdown from '../Markdown';
import VerticalSpacer from 'components/VerticalSpacer';
import {faBookmark} from '@fortawesome/pro-regular-svg-icons/faBookmark';
import {faHeart} from '@fortawesome/pro-regular-svg-icons/faHeart';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import {faBookmark as faBookmarkSolid} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {actions} from 'modules/user';
import styles from 'styles/index.scss';

const {
  articleContainer,
  articleCopy,
  articleHeaderImage,
  articleTitleContainer,
  articleAuthorContainer,
  articleAuthorImage,
  makeMeBlue
} = styles;

class ArticleContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.id = props.id;

    this.state = {
      bookmarked: false,
      liked: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.test) {
      this.getState();
    }
  }

  async getState() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({...this.state, ...savedData});
    } else {
      // No saved values - do something?
    }
  }

  postState = newState => {
    this.props.post(this.id, newState);
  };

  saveState = stateItem => {
    const newState = {...this.state, ...stateItem};
    this.props.post(this.id, newState);
    this.setState({...newState});
  };

  toggleLiked = () => this.saveState({liked: !this.state.liked});

  toggleBookmarked = () => this.saveState({bookmarked: !this.state.bookmarked});

  renderAuthorContainer = () => {
    const {
      name,
      data: {img, description}
    } = this.props.Children.find(child => child.type === 'bio');

    return (
      <div className={articleAuthorContainer}>
        <div>
          <div className={articleAuthorImage} style={{backgroundImage: `url(${img})`, content: `url(${img})`}} />
        </div>
        <div style={{minWidth: '130px'}}>
          <div>
            <span>{name}</span>
          </div>
        </div>
      </div>
    );
  };

  renderMarkDown = () => {
    return <Markdown source={this.props.data.markdown} />;
  };

  renderWide = () => {
    const {bookmarked, liked} = this.state;

    return (
      <Col sm={4}>
        {this.renderAuthorContainer()}
        <div style={{margin: '36px 0', paddingLeft: '12px'}}>
          <div onClick={this.toggleLiked} style={{cursor: 'pointer', minWidth: '190px'}}>
            <FontAwesomeIcon style={{color: '#F5515F', marginRight: '8px'}} icon={liked ? faHeartSolid : faHeart} />
            <span>Bookmark this page</span>
          </div>
          <div onClick={this.toggleBookmarked} style={{cursor: 'pointer', minWidth: '190px'}}>
            <FontAwesomeIcon
              style={{marginLeft: '3px', marginRight: '9px'}}
              icon={bookmarked ? faBookmarkSolid : faBookmark}
            />
            <span>Favorite this content</span>
          </div>
        </div>
      </Col>
    );
  };

  render() {
    const {
      title,
      data: {img},
      refreshToken,
      user
    } = this.props;

    const {bookmarked, liked} = this.state;

    return (
      <div id="recipe" className={articleContainer}>
        <div className={articleHeaderImage} style={{backgroundImage: `url(${img})`}} />
        <Container>
          <Row>
            <Col xs={12} sm={8}>
              <div className={articleTitleContainer}>
                <div>
                  <h4>{title}</h4>
                </div>
                <div className="d-block d-sm-none">
                  <FontAwesomeIcon
                    style={{color: '#F5515F', marginRight: '6px', cursor: 'pointer'}}
                    icon={liked ? faHeartSolid : faHeart}
                    onClick={this.toggleLiked}
                  />
                  <FontAwesomeIcon
                    style={{cursor: 'pointer'}}
                    icon={bookmarked ? faBookmarkSolid : faBookmark}
                    onClick={this.toggleBookmarked}
                  />
                </div>
              </div>
              <div className={articleCopy}>{this.renderMarkDown()}</div>
            </Col>
            <Col className="d-none d-sm-block" sm={4} style={{paddingLeft: '0'}}>
              {this.renderWide()}
            </Col>
          </Row>

          <Row className="d-block d-sm-none">
            <Col>{this.renderAuthorContainer()}</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ArticleContainer.propTypes = {
  data: PropTypes.shape({
    markdown: PropTypes.string.isRequired
  }).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  post: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data)),
  refreshToken: actions.refreshToken
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleContainer);
