import React from 'react';
import {Col, Row} from 'reactstrap';
import UHC from '../FRSCommon/UHC';
import UHCLogo from 'images/SVG/frn-uhc-logo.svg';

import {collections} from './collections.json';

class UHC20 extends React.Component {
  render() {
    const {props} = this;
    return (
      <UHC
        speakerCollections={collections}
        videoUrl="https://www.youtube.com/watch?v=IUngxNsou08"
        slug="frs20-uhc20"
        sku="uhc20"
        {...props}
      >
        <Row className="d-flex justify-content-center">
          <Col sm="10" md="11" lg="9" xl="10">
            <h5 className="section-only-heading text-center">
              Introducing The Food Revolution Ultimate Health Collection — If your primary motivation is to improve your
              health, then this special offer is for you!
            </h5>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md="10" lg="8" xl="6">
            <img src={UHCLogo} />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm="10" md="9" lg="11" xl="12" className="text-center">
            <p>
              There have been more than 175 interviews in past Food Revolution Summits. We’ve hand-selected 20 of the
              most powerful health-related interviews from our previous Summits to create this Ultimate Health
              Collection.
            </p>
            <p>
              <em>This opportunity won’t be available again at this price after you close this page.</em>
            </p>
          </Col>
        </Row>
      </UHC>
    );
  }
}
export default UHC20;
