import React from 'react';
import ModalContainer from '../container';
import Reset from './components/Reset';

const RegisterModal = props => {
  return (
    <ModalContainer disableClose {...props}>
      <Reset {...props} />
    </ModalContainer>
  );
};

export default RegisterModal;
