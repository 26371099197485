/* eslint-disable */

export const downloadOne = {
  displayProps: {
    title: 'Lesson Download',
    subtitle: 'Lesson Download',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Lesson Download'
    }
  },
  type: 'download',
  title: 'Lesson Download',
  slug: 'k-freston-mp3',
  name: 'kathy-freston-mp3-01',
  data: {
    text: 'Download the Mp3',
    type: 'MP3',
    url:
      'https://s3-us-west-2.amazonaws.com/members.foodrevolution.org/mp3s/Kathy%2BFreston%2B-%2BHealthy%2BLiving%2C%2BConscious%2BEating.mp3'
  }
};

export const downloadTwo = {
  displayProps: {
    title: 'Video Download',
    subtitle: 'Video Download',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Download'
    }
  },
  type: 'download',
  title: 'Video Download',
  slug: 'download-two',
  name: 'Download Two',
  data: {
    text: 'Download the video',
    type: 'MP4',
    url:
      'https://s3-us-west-2.amazonaws.com/members.foodrevolution.org/mp3s/Kathy%2BFreston%2B-%2BHealthy%2BLiving%2C%2BConscious%2BEating.mp3'
  }
};

export const bonusDownloadOne = {
  displayProps: {
    title: 'Video Download',
    subtitle: 'Video Download',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Download'
    }
  },
  type: 'download',
  title: 'Bonus One',
  slug: 'bonus-1-download',
  name: 'Placeholder Download',
  data: {
    value: '50.00',
    contentCardDescription:
      'The role of a good cook ware in the preparation of a sumptuous meal cannot be overemphasized.  When one consider purchasing . . .',
    text:
      'ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem',
    thumb: {
      text:
        'ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem',
      url: 'http://via.placeholder.com/350x150',
      alt: 'great bonus thumb pic'
    },
    // refactor me into 'thumb' ?
    // details: 'Bonus From: John Robbins',
    type: 'PDF',
    url: 'http://foodrevolution.ontraport.net/d/Kathy+Freston+Interview+-+Plant-Powered+and+Thriving.pdf'
  }
};

export const bonusDownloadTwo = {
  displayProps: {
    title: 'Video Download',
    subtitle: 'Video Download',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Download'
    }
  },
  type: 'download',
  title: 'Bonus Two',
  slug: 'bonus-2-download',
  name: 'Placeholder Download',
  data: {
    contentCardDescription:
      'The role of a good cook ware in the preparation of a sumptuous meal cannot be overemphasized.  When one consider purchasing . . .',
    text:
      'ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem',
    thumb: {
      text:
        'ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem',
      url: 'http://via.placeholder.com/350x150',
      alt: 'great bonus thumb pic'
    },
    // value: '50.00',
    learnMoreLink: 'http://www.theonion.com',
    details: 'Bonus From: John Robbins',
    type: 'PDF',
    url: 'http://foodrevolution.ontraport.net/d/Kathy+Freston+Interview+-+Plant-Powered+and+Thriving.pdf'
  }
};

export const specialReportsDownload = {
  displayProps: {
    title: 'Video Download',
    subtitle: 'Video Download',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Video Download'
    }
  },
  type: 'download',
  title: 'Transcript Download',
  slug: 'k-freston-pdf',
  name: 'kathy-freston-01',
  data: {
    thumb: {
      url: 'http://members.foodrevolution.org/wp-content/uploads/2016/06/Guidebook-Cover-Updated-Draft-2-1-235x300.jpg',
      alt: 'happy healthy food'
    },
    contentTtype: 'transcript',
    description: 'This is the special report one description.',
    text: 'Download the PDF transcript',
    type: 'PDF',
    url: 'http://foodrevolution.ontraport.net/d/Kathy+Freston+Interview+-+Plant-Powered+and+Thriving.pdf'
  }
};

const downloadsListOne = {
  type: 'downloadsList',
  title: 'PPT7 Session 2 - Resource Downloads',
  name: 'PPT7 Session 2 Downloads',
  slug: 'downloadslist-one',
  data: {},
  Children: [
    {
      type: 'download',
      title: 'Lesson Download',
      slug: 'k-freston-mp3',
      name: 'kathy-freston-mp3-01',
      data: {
        text: 'Download the Mp3',
        type: 'MP3',
        url:
          'https://s3-us-west-2.amazonaws.com/members.foodrevolution.org/mp3s/Kathy%2BFreston%2B-%2BHealthy%2BLiving%2C%2BConscious%2BEating.mp3'
      }
    },
    {
      type: 'download',
      title: 'Transcript Download',
      slug: 'k-freston-pdf',
      name: 'kathy-freston-01',
      data: {
        text: 'Download the PDF transcript',
        type: 'PDF',
        url: 'http://foodrevolution.ontraport.net/d/Kathy+Freston+Interview+-+Plant-Powered+and+Thriving.pdf'
      }
    }
  ]
};

export default downloadsListOne;
