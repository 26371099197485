import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import classNames from 'classnames';

export default function SalesDisclaimer({iconColor = 'green', className = null, ...props}) {
  return (
    <div className={classNames(`disclaimer-wrap`, className)}>
      <div className="disclaimer-cards">
        <img
          className="img-fluid img-cc"
          src="https://cdn.foodrevolution.org/checkout/assets/images/acceptance-marks.png"
        />
      </div>
      <div className="disclaimer-copy text-small">
        <p className={props.noGuaranteeText ? 'mb-0' : 'mb-2'}>
          <FontAwesomeIcon className={classNames(`offer-box-security-icon text-${iconColor} mr-2`)} icon={faLock} /> For
          your security, all orders are processed on a secured server.
        </p>
        {props.noGuaranteeText ? null : (
          <p className="mb-0">Your purchase is protected by our 60-day money back guarantee.</p>
        )}
      </div>
    </div>
  );
}
