import React, {Component} from 'react';

import {BreadcrumbItem, Container, Row, Col} from 'reactstrap';

import Bonuses from '../../../../components/Bonuses';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

import ResourcesListTwo from 'routes/Products/components/ResourcesListTwo';
import RecipesList from '../../../../components/RecipesList/index';
const RecipesPage = props => {
  const {product, recipes} = props;
  const productInPath = location.pathname.split('/')[2];
 
  
  return (
    <div>
      {/* <BackNav /> */}
      <Container>
        <BreadCrumbs>
          <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
            {product.title}
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Recipes
          </BreadcrumbItem>
        </BreadCrumbs>
        
      </Container>

     
      <Container>
        {recipes && recipes.length ? (
            <RecipesList colSizes={{xs: 12, md: 4}} customTitle="All Recipes" Children={recipes} disableViewAll />
          ) : null}
      </Container>
    </div>
  );
};

export default RecipesPage;
