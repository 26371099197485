import React, {useEffect, useState} from 'react';

import {Row, Col, Pagination as PaginationContainer, PaginationItem, PaginationLink} from 'reactstrap';
// import colors from '../../styles/theme/colors';

function Pagination({curPage = 1, finalPage, handleParentNav, searchResultsPerPage}) {
  const NUM_OF_PAGES_TO_SHOW = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesToShow, setPagesToShow] = useState([]);

  const scrollToContent = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 50,
        behavior: 'smooth'
      });
    }, 100);
  };
  const handleNav = moveThisAmount => {
    const moveToThisPage = currentPage + moveThisAmount;

    if (moveToThisPage < 1 || moveToThisPage > finalPage) return; //no nothing out of bounds
    setCurrentPage(moveToThisPage);
  };
  useEffect(() => {
    if (curPage == currentPage) return; //no need to update the parent
    handleParentNav(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(curPage);
    scrollToContent();
  }, [curPage]);

  useEffect(() => {
    //determine what page of pages we are on (eg. 1-10, 11-20)

    const currentSetOfPages = Math.floor(currentPage / NUM_OF_PAGES_TO_SHOW);
    let beginingPage = currentSetOfPages * NUM_OF_PAGES_TO_SHOW; //e.g. page 5 would start at 1 is set of pages
    if (!beginingPage) beginingPage = 1;

    let _pagesToShow = [];
    for (let i = beginingPage; i < beginingPage + NUM_OF_PAGES_TO_SHOW && i <= finalPage; i++) {
      _pagesToShow.push(i);
    }

    setPagesToShow(_pagesToShow);
  }, [finalPage, currentPage]);
  return (
    //   <Row space={3} justifyContent="div" alignItems={'div'}>
    <React.Fragment>
      {pagesToShow && pagesToShow.length ? (
        <PaginationContainer>
          <PaginationItem key={'firstPage'} onClick={() => setCurrentPage(1)}>
            <PaginationLink>{`<<`} </PaginationLink>
          </PaginationItem>

          <PaginationItem key={'previous'}>
            <PaginationLink previous onClick={() => handleNav(-1)} />
          </PaginationItem>

          {/* <div h="30px" w="30px" bg={'colors.frn.purple'} rounded="sm" shadow={3}> */}

          {/* <PaginationItem active>
        <PaginationLink> {currentPage}</PaginationLink>
      </PaginationItem> */}
          {pagesToShow && pagesToShow.length
            ? pagesToShow.map(item => {
                return (
                  <PaginationItem key={item} active={currentPage === item} onClick={() => setCurrentPage(item)}>
                    <PaginationLink> {item}</PaginationLink>
                  </PaginationItem>
                );
              })
            : ''}

          <PaginationItem key="nextPage">
            <PaginationLink next onClick={() => handleNav(1)} />
          </PaginationItem>
          <PaginationItem key={'lastPage'} onClick={() => setCurrentPage(finalPage)}>
            <PaginationLink>{` >> `}</PaginationLink>
          </PaginationItem>
        </PaginationContainer>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default Pagination;
