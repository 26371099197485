/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {getSingleDate} from 'lib/time';
import ResourcesListTwo from '../ResourcesListTwo';
import pluralize from 'pluralize';

const ActionHours = props => {
  const {Children = [], noun = 'Action Hour'} = props;
  const nouns = pluralize(noun);

  const actionHoursWithTitles = Children.map(actionHour => {
    const bio = actionHour.Children.filter(child => child.type === 'bio');
    const {
      data: {time}
    } = actionHour;

    //If one of bio OR time is available, we can formulate a meaningful string for title
    //Else just use the original actionHour title
    let title = actionHour.title;
    if (!!bio || !!time) {
      const names = bio.map(e => e.title);
      let nameStr = '';
      if (names) {
        names.push(names.splice(-2).join(' & '));
        nameStr = names.join(', ');
      }
      title = `${noun}${nameStr ? ' with ' + nameStr : ''}${time ? ': ' + getSingleDate(time) : ''}`;
    }

    return {...actionHour, title};
  });
  if (!props.id) {
    return <Container key="loading-action-hours">Loading {nouns}...</Container>;
  }
  return (
    <Container key={props.id}>
      <ResourcesListTwo
        customTitle={nouns}
        customList={actionHoursWithTitles}
        isPageView={true}
        nouns={nouns}
        colSizes={{xs: 6, sm: 4, md: 3}}
      />
    </Container>
  );
};

ActionHours.propTypes = {
  actionHoursList: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

ActionHours.displayname = 'ActionHours';

export default ActionHours;
