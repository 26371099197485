import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import VideoResource from 'routes/Products/components/VideoResource';

class LiveEventPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    //Render a redirect to the welcome page for the course IF purchased
    if (
      this.props.contentItem.Children &&
      this.props.contentItem.Children.length &&
      this.props.contentItem.Children[0].type === 'videoResource'
    ) {
      return <VideoResource {...this.props.contentItem.Children[0]} />;
    }
    return null;
  }
}

LiveEventPage.propTypes = {
  contentItem: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default LiveEventPage;
