import React from 'react';
import ReactMarkdown from 'lib/react-markdown';
import PropTypes from 'prop-types';
import {Card, Container, Col, Row} from 'reactstrap';
import styles from 'styles/index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const {
  recipeIngredient,
  recipeIngredientContainer,
  recipeIngredientAmount,
  recipeIngredientAmountLabel,
  recipeIngredientAmountValue,
  recipeIngredientImg,
  recipeIngredientName,
  recipeIngredientNotes
} = styles;

const IngredientListItem = props => (
  <Card className={recipeIngredient}>
    <Container className={recipeIngredientContainer} fluid>
      <Row>
        {props.url && (
          <Col xs="auto">
            <div className={recipeIngredientImg} style={{backgroundImage: `url(${props.url})`}} />
          </Col>
        )}
        <Col xs="3" className={recipeIngredientAmount}>
          <div className={recipeIngredientAmountValue}>
            <span>{props.number}</span>&nbsp;<span>{props.units}</span>
          </div>
        </Col>
        <Col style={{textAlign: 'right'}}>
          <div className={recipeIngredientName}>{props.name}</div>
          {props.note ? <ReactMarkdown source={props.note} className={recipeIngredientNotes} /> : null}
        </Col>
      </Row>
    </Container>
  </Card>
);

IngredientListItem.propTypes = {
  note: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired
};

IngredientListItem.defaultProps = {
  note: ''
};

export default IngredientListItem;
