import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import applePay from 'braintree-web/apple-pay';
import mergewith from 'lodash.mergewith';
import {Alert, Button, Col, Container, Row, FormGroup} from 'reactstrap';
import {actions} from 'modules/checkout';
import {getCurrentTotal, orderRequiresShipping} from 'modules/checkout/selectors';
import {ApplePay as ApplePayMark} from 'icons/brand-marks';

@connect(
  state => ({
    orderRequiresShipping: orderRequiresShipping(state),
    total: getCurrentTotal(state),
    totals: state.checkout.totals,
    selectedPaymentOption: state.checkout.orderRequest.OfferPaymentOptionId
  }),
  {
    setNewAddress: actions.setNewAddress,
    loadTotals: actions.loadTotals,
    setPaymentToken: actions.setPaymentToken,
    processThirdPartyCheckout: actions.processThirdPartyCheckout
  }
)
export class ApplyPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      supported: global.ApplePaySession && global.ApplePaySession.canMakePayments(),
      loading: true
    };
    if (this.state.supported) {
      applePay.create(
        {
          client: props.client
        },
        (err, instance) => {
          if (err) {
            this.setState({error: err});
          }
          this.applePayInstance = instance;
        }
      );
    }
    this.showPaymentSheet = this.showPaymentSheet.bind(this);
  }

  setAddressFromApplePayPaymentContact(contact, addressType) {
    this.props.setNewAddress(
      {
        address1: contact.addressLines.length > 0 ? contact.addressLines[0] : null,
        address2: contact.addressLines.length > 1 ? contact.addressLines[1] : null,
        city: contact.locality,
        state: contact.administrativeArea,
        postalCode: contact.postalCode,
        country: contact.countryCode
      },
      addressType
    );
  }

  showPaymentSheet() {
    const self = this;
    const {total} = this.props;
    var paymentRequest = this.applePayInstance.createPaymentRequest({
      total: {
        label: 'Food Revolution Network',
        amount: total.order.total,
        type: 'pending'
      },
      lineItems: [
        {label: 'Subtotal', amount: total.order.subtotal, type: 'final'},
        {label: 'Tax', amount: total.order.tax, type: 'pending'}
      ],

      // We recommend collecting billing address information, at minimum
      // billing postal code, and passing that billing postal code with
      // all Apple Pay transactions as a best practice.
      requiredBillingContactFields: ['postalAddress', 'email', 'name']
    });
    if (self.props.orderRequiresShipping) paymentRequest.requiredShippingContactFields = ['postalAddress', 'name'];

    var session = new ApplePaySession(2, paymentRequest);

    session.onvalidatemerchant = function(event) {
      self.applePayInstance
        .performValidation({
          validationURL: event.validationURL,
          displayName: 'Food Revolution Network'
        })
        .then(function(validationData) {
          session.completeMerchantValidation(validationData);
        })
        .catch(function(validationErr) {
          self.setState({error: validationErr});
          session.abort();
        });
    };

    session.onshippingcontactselected = function(event) {
      self.setAddressFromApplePayPaymentContact(event.shippingContact, 'ShippingAddress');
      self.props.loadTotals().then(totals => {
        const total = totals[self.props.selectedPaymentOption];
        session.completeShippingContactSelection({
          total: {
            label: 'Food Revolution Network',
            amount: total.order.total,
            type: 'final'
          },
          additionalDisplayItems: [
            {label: 'Shipping', amount: total.order.shipping},
            {label: 'Tax', amount: total.order.tax}
          ]
        });
      });
    };

    session.onpaymentauthorized = function(event) {
      self.applePayInstance
        .tokenize({
          token: event.payment.token
        })
        .then(function(payload) {
          session.completePayment(global.ApplePaySession.STATUS_SUCCESS);
          payload.rawPaymentData = event.payment;
          const {billingContact, shippingContact} = event.payment;

          if (billingContact && billingContact.postalCode) {
            self.setAddressFromApplePayPaymentContact(billingContact, 'BillingAddress');
          }
          self.props.setPaymentToken({gateway: 'braintree', token: payload});
          self.props.processThirdPartyCheckout({
            firstName: billingContact.firstName,
            lastName: billingContact.lastName,
            email: billingContact.email
          });
        })
        .catch(function(tokenizeErr) {
          self.setState({error: tokenizeErr});
          session.completePayment(global.ApplePaySession.STATUS_FAILURE);
        });
    };

    session.begin();

    return false;
  }

  render() {
    const {error, supported, loading} = this.state;
    const Tag = this.props.tag || 'div';
    return supported ? (
      <div style={{height: '50px'}}>
      <Tag className="d-flex align-items-center">
        <span style={{width: '100%', maxWidth: '280px'}} className="m-auto text-center">
          <button
            onClick={this.showPaymentSheet}
            style={{
              '-webkit-appearance': '-apple-pay-button',
              '-apple-pay-button-type': 'buy',
              width: '100%',
              height: '45px',
              display: 'inline-block'
            }}
          />
        </span>
      </Tag>
      </div>
    ) : null;
  }
}

export default ApplyPay;
