/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {Alert, Button, Card, CardBody, Modal, Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Amex from 'images/SVG/Amex';
import Discover from 'images/SVG/Discover';
import MasterCard from 'images/SVG/MasterCard';
import PayPal from 'images/SVG/PayPal';
import Visa from 'images/SVG/Visa';

import {
  paymentMethodDeleteButton,
  deletePaymentMethodModal,
  deletePaymentMethodModalButtons,
  userPaymentMethod
} from 'styles/index.scss';

@connect(
  state => ({
    user: state.user
  }),
  {
    getPaymentMethods: actions.getPaymentMethods,
    deletePaymentMethod: actions.deletePaymentMethod
  }
)
class PaymentMethodsContainer extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      success: false,
      error: false,
      cancellingId: null,
      processing: false
    };

    this.state = this.defaultState;
  }

  componentDidMount() {
    this.props.getPaymentMethods();
  }

  toggleConfirm = id => () => {
    if (this.state.cancellingId) {
      this.setState(this.defaultState);
    } else {
      this.setState({cancellingId: id});
    }
  };

  close = () => {
    this.setState({cancellingId: null});
    this.props.getPaymentMethods();
  };

  reset = () => {
    this.setState(this.defaultState);
  };

  //DELETE /users/<userId>/paymentMethods/<userPaymentMethodId>
  deletePaymentMethod = id => {
    this.setState({processing: true});

    this.props.deletePaymentMethod(id).then(confirmation => {
      if (confirmation && confirmation.success) {
        this.setState({processing: false, success: true});
      } else if (confirmation && confirmation.message) {
        this.setState({processing: false, error: true, message: confirmation.message});
      } else {
        this.setState({processing: false, error: true});
      }
      //refresh the payments
      this.props.getPaymentMethods();
    });
  };

  renderSuccess = product => {
    return (
      <div className={deletePaymentMethodModal}>
        <div>
          <span>Success! Payment method deleted.</span>
        </div>
        <div style={{textAlign: 'center', marginTop: '24px'}} className={deletePaymentMethodModalButtons}>
          <Button color="primary" onClick={this.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  };

  renderError = message => {
    return (
      <div className={deletePaymentMethodModal}>
        {message ? (
          <div>
            <span>{message}</span>
          </div>
        ) : (
          <div>
            <div>
              <span>Oh no! We were not able to process your request.</span>
            </div>
            <div>
              <span>Please call support to complete your payment update.</span>
            </div>
          </div>
        )}
        <div style={{textAlign: 'right', marginTop: '24px'}} className={deletePaymentMethodModalButtons}>
          <Button color="purple" onClick={this.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  };

  renderConfirm = () => {
    return (
      <div className={deletePaymentMethodModal}>
        <div>
          <span>Please confirm you would like to delete this payment method.</span>
        </div>
        <div style={{textAlign: 'center', marginTop: '24px'}} className={deletePaymentMethodModalButtons}>
          <Button color="purple" onClick={this.deletePaymentMethod}>
            Ok
          </Button>
          <Button color="primary" onClick={this.close}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const {UserPaymentMethods, subscriptions} = this.props.user;
    const {cancellingId, success, error, message} = this.state;

    if (!UserPaymentMethods) {
      return (
        <div style={{marginBottom: '8px'}}>
          <span>No payment methods saved</span>
        </div>
      );
    }

    const inUsePaymentMethods = {};

    subscriptions.map(subscription => {
      const paymentMethodId = subscription.Subscription.UserPaymentMethodId;

      if (!subscription.Subscription.cancelDate) {
        inUsePaymentMethods[paymentMethodId] = subscription.Offer.name;
      }
    });

    return (
      <Container fluid className="p-0">
        {error ? (
          message ? (
            <Alert color="danger">{message}</Alert>
          ) : (
            <Alert color="danger">There was an error deleting this payment. Please try again.</Alert>
          )
        ) : null}
        <Row className="p-0">
          {UserPaymentMethods.map(paymentMethod => {
            const {brand, expMonth, expYear, id, last4} = paymentMethod;
            const cardUsedForProduct = inUsePaymentMethods[id];

            return (
              <Col xs="12" md="6" lg="4" className="mb-3" key={id}>
                <Card className={userPaymentMethod}>
                  <CardBody>
                    <Container fluid className="p-0">
                      <Row className="p-0">
                        <Col xs="auto">
                          {
                            {
                              Visa: <Visa />,
                              MasterCard: <MasterCard />,
                              'American Express': <Amex />,
                              Discover: <Discover />,
                              PayPal: <PayPal />
                            }[brand]
                          }
                        </Col>
                        <Col>
                          <div>
                            <span>
                              {brand === 'paypal' ? 'PayPal Billing Agreement' : `${brand} ending in ${last4}`}
                            </span>
                          </div>
                          {brand !== 'paypal' ? (
                            <div>
                              <span>
                                Expires {expMonth}/{String(expYear).slice(2)}{' '}
                              </span>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="p-0 mt-1">
                        <Col>
                          {cardUsedForProduct ? (
                            <small className="text-muted">
                              This payment method is in use for {cardUsedForProduct}.
                            </small>
                          ) : (
                            <Button
                              onClick={() => {
                                this.deletePaymentMethod(id);
                              }}
                              size="sm"
                            >
                              Remove
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Modal onClosed={this.reset} isOpen={!!cancellingId} centered>
          {error && this.renderError(message)}
          {success && this.renderSuccess()}
          {cancellingId && !success && !error && this.renderConfirm()}
        </Modal>
      </Container>
    );
  }
}

PaymentMethodsContainer.propTypes = {};
//   getPaymentMethods: PropTypes.func.isRequired,
//   deletePaymentMethod: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     UserPaymentMethods: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
//   }).isRequired
// };

export default PaymentMethodsContainer;
