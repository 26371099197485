//import {ModuleOne, ModuleTwo, ModuleThree} from './ModuleOne';

const testData = {
  id: 7146, //actual id in staging of contentitem
  name: 'Plant Base Coaching Certification',
  title: 'Plant Base Coaching Certification',
  slug: 'pbcc',
  type: 'pbcc',
  data: {
    description: 'This is the Plant Base Coaching Certification class',

    url: 'https://youtu.be/LC-EdlHK6kk',
    title: 'PBCC Intro Video'
  },
  published: true,
  Children: [],
  assessments:[
    {
    passing_score_percent:60,
    questions:[
        {
            id:1,
            question_title:"What is the color of banana?",
            hints:"hints example",
            choices:[
                {
                    id:1,
                    title:"Red"
                },
                {
                    id:2,
                    title:"yellow"
                },
                {
                    id:3,
                    title:"green"
                }
            ]
        },
        {
            id:1,
            question_title:"What is the color of pineapple?",
            hints:"hints example",
            choices:[
                {
                    id:1,
                    title:"Red"
                },
                {
                    id:2,
                    title:"yellow"
                },
                {
                    id:3,
                    title:"green"
                }
            ]
        },
        {
            id:2,
            question_title:"What is the color of Apple?",
            hints:"hints example",
            choices:[
                {
                    id:1,
                    title:"Red"
                },
                {
                    id:2,
                    title:"yellow"
                },
                {
                    id:3,
                    title:"green"
                }
            ]
        }
    ],
    answers:[
        {
            questionId:1,
            answer:2
        },
        {
            questionId:2,
            answer:1
        }
    ]
}
]
};

export default testData;
