import React from 'react';
import {FacebookProvider, Like} from 'react-facebook';

export default function FacebookLikeButton(props) {
  return (
    <FacebookProvider appId="731824260184014">
      <Like href={props.url} layout="button_count" action="like" size="large" showFaces="false" share="false" />
    </FacebookProvider>
  );
}

FacebookLikeButton.defaultProps = {
  url: 'https://foodrevolutionsummit.org/'
};
