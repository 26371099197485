import React from 'react';

function Seperator(props) {
  const style = {
    width: props.width || '1px',
    height: props.height || '24px',
    marginLeft: props.margingLeft || '10px',
    marginRight: props.magrinRight || '10px',
    marginTop: props.marginTop || '8px',
    backgroundColor: props.backgroundColor || 'white'
  };
  return <div style={style} className="seperatorHeader"></div>;
}

export default Seperator;
