/* eslint-disable */
// import createSchema from '../../../../lib/createSchema';

const checklistItemOne = {
  type: 'checklistItem',
  title: 'Checklist Title',
  slug: 'ppt-17-session-2-checklist-1',
  name: 'PPT 17 Session 2 Checklist Item 1',
  thumb: {},
  data: {
    text: 'Listen to Session 2'
  }
};

const checklistOne = {
  type: 'checklist',
  slug: 'ppt-17-session-2-checklist',
  title: 'Checklist Title',
  name: 'PPT7 Session 2 Additional Links',
  data: {
    checklistItems: [
      checklistItemOne,
      {
        type: 'checklistItem',
        title: 'PPT 17 Session 2 Checklist Item 2',
        slug: 'ppt-17-session-2-checklist-2',
        name: 'PPT 17 Session 2 Checklist Item 2',
        data: {
          text: 'Make at least 1 of the Recipes below for this session'
        }
      },
      {
        type: 'checklistItem',
        title: 'PPT 17 Session 2 Checklist Item 3',
        slug: 'ppt-17-session-2-checklist-3',
        name: 'PPT 17 Session 2 Checklist Item 3',
        data: {
          text:
            'Where You Shop - Think about where you shop. Are you satisfied? Have you investigated local farmer’s markets or CSAs? Have you checked out Thrive Market to see if it is useful for you? What new options might you want to consider?'
        }
      },
      {
        type: 'checklistItem',
        title: 'PPT 17 Session 2 Checklist Item 4',
        slug: 'ppt-17-session-2-checklist-4',
        name: 'PPT 17 Session 2 Checklist Item 4',
        data: {
          text:
            'Plant-Powered & Thriving Facebook Group - Read through what’s been posted and comment on anything that interests you. If you want to go a step further, post to share one place you struggle or have questions around food. If you want to welcome input or advice, say so. If you don’t want any, then say that.'
        }
      },
      {
        type: 'checklistItem',
        title: 'PPT 17 Session 2 Checklist Item 5',
        slug: 'ppt-17-session-2-checklist-5',
        name: 'PPT 17 Session 2 Checklist Item 5',
        data: {
          text: 'Guest Faculty Interview – Listen to this session’s interview with Kathy Freston, listed below.'
        }
      }
    ]
  }
};

export const wlcOnboardingChecklist = {
  type: 'checklist',
  slug: 'wlc-onboarding-checklist',
  title: 'Welcome to WHOLE Life Club',
  name: 'WLC Onboarding Checklist',
  data: {
    checklistItems: [
      {
        data: {
          text:
            'Watch the onboarding video. This video takes you on a tour through the member center to help you get oriented and comfortable in WHOLE Life Club. '
        },
        name: 'WLC Onboarding Checklist Item 1',
        slug: 'wlc-onboarding-checklist-item-1',
        type: 'checklistItem',
        title: 'WLC Onboarding Item 2'
      },
      {
        data: {
          text:
            'Take the onboarding survey. This survey will help you identify where your strengths and weaknesses are in terms of living a WHOLE life and we’ll ask you to take the survey again in a few months so you can track your progress. '
        },
        name: 'WLC Onboarding Checklist Item 2',
        slug: 'wlc-onboarding-checklist-item-2',
        type: 'checklistItem',
        title: 'WLC Onboarding Item 2'
      },
      {
        data: {
          text:
            'Set up your public profile. This includes entering your first name and the first initial of your last name, uploading your profile picture, adding your social profile accounts if you are open to people contacting you directly, enter your top food and lifestyle goal and enter the geography of where you live.'
        },
        name: 'WLC Onboarding Checklist Item 3',
        slug: 'wlc-onboarding-checklist-item-3',
        type: 'checklistItem',
        title: 'WLC Onboarding Item 3'
      },
      {
        data: {
          text:
            'Read the community forum guidelines. Reading and agreeing to these guidelines will help all of us build a fun, welcoming, and supportive environment together.'
        },
        name: 'WLC Onboarding Checklist Item 4',
        slug: 'wlc-onboarding-checklist-item-4',
        type: 'checklistItem',
        title: 'WLC Onboarding Item 4'
      }
    ]
  }
};

//this is an acutal checklist from WLC in staging, using it to test the checklist states (userdata needs acutal content id to update)
export const WLCChecklist = {
  id: 7014,
  name: 'WLC - Sep. 23 - 29, 2019 - Checklist',
  title: 'Eat More Plants',
  slug: '2019-09-23-checklist',
  type: 'checklist',
  data: {
    checklistItems: [
      {
        data: {
          text:
            '**Watch the Action of the Week Video (Under 5 minutes)**\n\nWhat you eat can either contribute to disease or it can prevent or reverse it. And countless studies have shown the importance of eating plants — namely fruit and vegetables — for good health. Watch this week’s Action of the Week video to find out why eating more plants is so beneficial and strategies for increasing your intake.'
        },
        name: 'WLC - 2019-09-23 - Watch the Action of the Week Video'
      },
      {
        data: {
          text:
            '**Watch the video, [How Many Servings of Vegetables Should You Really Eat Per Day?](/products/wlc/how-much-veggies-per-day) by Healthline**\n\nWe could all stand to eat more fruits and vegetables. In the U.S., just 1 in 10 adults meet the federal fruit or vegetable recommendations. Watch this week’s video from Healthline to find out the optimal amount of fruits and vegetables you should be eating. And find out how to buy your fruits and veggies to get the maximum nutrients.'
        },
        name: 'WLC - 2019-09-23 - Watch the video, How Many Servings of Vegetables Should You Really Eat Per Day?'
      },
      {
        data: {
          text:
            '**Aim for five servings of fruits & vegetables per DAY**\n\nThe World Health Organization (WHO) suggests the need for at least 5 servings of fruits and vegetables per day. As does data from the 2001–2008 Health Surveys for England, which saw a reduction in mortality from up to 7 servings per day. Eating enough fruits and veggies ensures you’re getting enough nutrients like fiber and antioxidants.'
        },
        name: 'WLC - 2019-09-23 - Aim for five servings of fruits & vegetables per DAY'
      },
      {
        data: {
          text:
            '**Include variety in your plant-based eating**\n\nVariety is the spice of life! As a rule, eating a rainbow of fruits and vegetables will help you get adequate nutrition. When upping your intake of plants, include some raw and some cooked, and at least five different forms of fruits and vegetables in your week.'
        },
        name: 'WLC - 2019-09-23 - Include variety in your plant-based eating'
      },
      {
        data: {
          text:
            '**Join the community discussion this week by posting in a new comment box**\n\nAnswer these questions: Are you getting enough fruits and vegetables? How do you like to eat them?'
        },
        name: 'WLC - 2019-09-23 - Join the community discussion this week'
      }
    ]
  },
  published: true,
  free: false,
  Children: [],
  ContentTags: []
};
// export const checklistSchema = createSchema(checklistOne);
// export const checklistItemSchema = createSchema(checklistItemOne);
export default checklistOne;
