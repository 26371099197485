/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {getFullTimeString} from 'lib/time';
import {Container, Row, Card, CardBody, Col} from 'reactstrap';
import BackNav from 'routes/Products/components/BackNav';
import Video from 'routes/Products/components/Video';
import SummitBroadcastDay from './components/SummitBroadcastDay';
import Bonuses from 'routes/Products/components/Bonuses';
import LiveEvent from './components/LiveEvent';
import RecipeGrid from 'routes/Products/components/RecipesGrid';
import Recipe from 'routes/Products/components/Recipe';
import _ from 'lodash';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faUtensils} from '@fortawesome/pro-regular-svg-icons/faUtensils';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import {faBook} from '@fortawesome/pro-regular-svg-icons/faBook';
import ProductSidebar from 'components/ProductSidebar';
import VideoPlayList from 'routes/Products/components/VideoPlayList';
//import importProductJson from '../../../../../scripts/importers/data/AirEPtemp.json';
// import testAPIJson from '../../../../../scripts/importers/data/apiTest.json';
import {Link, Switch, Route, Redirect} from 'react-router-dom';
import Markdown from 'routes/Products/components/Markdown';

const BreadCrumbs = props => {
  return (
    <Container className="">
      <Row className="py-3">
        <Col xs="12" md="10">
          <Row className="breadcrumbs">{props.children}</Row>
        </Col>
      </Row>
    </Container>
  );
};

const WrapperEmpowermentPackage = props => {
  //todo:  thes videos should be children.  for now this is the workaround

  return (
    <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
      <Container>
        <Row>
          <Col>
            <h2>{props.product.title}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Markdown source={props.product.data.description} />
          </Col>
        </Row>
        {props.product.data.video ? (
          <Row>
            <Col>
              <Video data={props.product.data.video} />
            </Col>
          </Row>
        ) : null}

        {/* display each Day */}
        <Row>
          {props.SummitBroadcastDays &&
            props.SummitBroadcastDays.map((item, index) => {
              //shows the coming soon message

              const comingSoonClass =
                item.data.premieresAt > props.today
                  ? 'h-100 d-flex justify-content-center align-items-center comingSoon '
                  : 'd-none';
              //disavble click to content if not yet past premiere

              const onClickHandler =
                item.data.premieresAt > props.today
                  ? null
                  : () => {
                      props.handleNav(item.id, 'SummitBroadcastDays', 'day-' + (index + 1));
                    };
              return (
                <Col xs="12" sm="4" className="px-0 mb-5 px-sm-4" key={`day-${index}`} onClick={onClickHandler}>
                  <Card className="h-100 EP-day">
                    <div className="EP-broadcastTitle d-flex align-items-center justify-content-center w-100 px-md-5 text-center">
                      <div className="EP-broadcastTitleBackground" />
                      <div className="EP-textCenter p-0"> {item.title}</div>
                    </div>
                    <CardBody>
                      <h5>{item.data.description}</h5>
                      {/* Getting Bio Names inside this Day to display on cover */}
                      {item.Children.filter(dayChildren => {
                        return dayChildren.type === 'InterviewCollectionItem';
                      }).map(interviewCollectionItem => {
                        return interviewCollectionItem.Children.filter(item => {
                          return item.type === 'audioInterview';
                        }).map(audioInterview => {
                          return audioInterview.Children.filter(item => {
                            return item.type === 'bio';
                          }).map((bio, index) => {
                            return (
                              <div className="d-flex align-items-center pl-2 py-1">
                                {/* <img src={bio.data.img} alt={bio.name} /> */}
                                <div className="circle-image pr-3" style={{backgroundImage: `url(${bio.data.img})`}}>
                                  {' '}
                                </div>
                                <div className="pl-2 EP-day-seakerName" key={`bio-${index}`}>
                                  {bio.name}
                                </div>
                              </div>
                            );
                          });
                        });
                      })}
                    </CardBody>
                    <div className={comingSoonClass}>
                      <div>
                        <h3 className="text-uppercase">Coming soon.</h3>
                        <div>Available on {getFullTimeString(item.data.premieresAt)}</div>
                      </div>
                    </div>
                  </Card>
                  {/* end card */}
                </Col>
              );
            })}
        </Row>
      </Container>
    </ProductSidebar>
  );
};

const WrapperRecipes = props => {
  return (
    <div>
      {/* Recipes: {this.state.currentContent.title} */}
      <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
        <Container>
          <BackNav />
          <BreadCrumbs>
            <Link to={`/products/${props.productPath}`} className="pr-1">
              {props.product.title}
            </Link>{' '}
            / Recipes
          </BreadCrumbs>
          <div className="row">
            <div className="col-12">
              <h3>Recipe Collection</h3>
            </div>
          </div>
        </Container>
        <Row>
          <RecipeGrid
            singleRecipeInUrl={null}
            disableRouter={false}
            disableViewAll={true}
            colSizes={{xs: 6, sm: 4, md: 4}}
            {...props}
          />
        </Row>
      </ProductSidebar>
    </div>
  );
};

///pass in pros to RecipeGrid singleRecipeInUrl, this is the slug of this single recipe

const WrapperSingleRecipeV2 = props => {
  const recipes = props.recipes;
  const singleRecipeInUrl = recipes.find(r => r.slug === props.singleRecipeInUrl);
  return (
    <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
      <Container>
        <BackNav />
        <BreadCrumbs>
          <Link to={`/products/${props.productPath}`} className="pr-1">
            {props.product.title}
          </Link>{' '}
          /{' '}
          <Link to={`/products/${props.productPath}/recipes`} className="px-1">
            Recipes
          </Link>{' '}
          / {props.currentContent.title}
        </BreadCrumbs>
        <Row>
          <Col>
            <Recipe {...singleRecipeInUrl} />
          </Col>
        </Row>
      </Container>
    </ProductSidebar>
  );
};
const WrapperBroadcastDay = props => {
  return (
    <div>
      <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
        <Container>
          <BackNav />
          <BreadCrumbs>
            <Link to={`/products/${props.productPath}`} className="pr-1">
              {props.product.title}
            </Link>{' '}
            / {props.day.title}
          </BreadCrumbs>
        </Container>
        <SummitBroadcastDay productTitle={props.product.title} day={props.day} />
      </ProductSidebar>
    </div>
  );
};

const WrapperBonuses = props => {
  return (
    <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
      <Container>
        <BackNav />
        <BreadCrumbs>
          <Link to={`/products/${props.productPath}`} className="pr-1">
            {props.product.title}
          </Link>{' '}
          / {props.name ? props.name : 'Bonuses'}
        </BreadCrumbs>
      </Container>
      {/* <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h3>Bonuses Collection</h3>
                  </div>
                </div>
              </div> */}

      {props.Bonuses ? <Bonuses {...props.Bonuses.ContentItem} /> : null}
    </ProductSidebar>
  );
};
const WrapperLiveEvent = props => {
  return (
    <div>
      <ProductSidebar navItems={props.navItems} handleNav={props.handleNav}>
        <Container>
          <BackNav />
          <BreadCrumbs>
            <Link to={`/products/${props.productPath}`} className="pr-1">
              {props.product.title}
            </Link>{' '}
            / {props.liveEvent.title}
          </BreadCrumbs>
        </Container>
        <LiveEvent productTitle={props.product.title} contentItem={props.liveEvent} />
      </ProductSidebar>
    </div>
  );
};

@connect(state => ({user: state.user}), null)

//THE HIGH LEVEL PRODUCT COMPONENTS BASICALLY BECOME ROUTE PRODUCERS.  ALL THE ROUTES ARE RENDERED HERE
class EmpowermentPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: props, //importProductJson, //props, //testAPIJson.ContentItem, //testData, //testAPIJson, ,
      today: Date.now(), // + 10000000000000, //for testing
      disableRouter: false,
      currentContent: null,
      singleRecipeInUrl: null,
      navItems: [
        {name: 'Home', typeOfComponent: null, slug: null, icon: faHome}
        // {name: 'Recipes', typeOfComponent: 'RecipesGrid', slug: null, icon: faUtensils}
        // {name: 'Live Call', typeOfComponent: 'zoomWebinar', slug: null, icon: faVideo},
        //{name: 'Bonuses', typeOfComponent: 'Bonuses', slug: null, icon: faBook}
      ] //used to show what nav items are present in top};
    };

    this.handleNav = this.handleNav.bind(this);
    this.history = this.history.bind(this);
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
  }

  history = event => {
    // console.log('****************** history changed', JSON.stringify(event.state));
    //console.log(window.history);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onpopstate = this.history;
    //get the SummitBroadCastDays
    const SummitBroadcastDays = this.state.product.Children.filter(item => {
      return item.type === 'SummitBroadcastDay' && item.published === true;
    });
    SummitBroadcastDays.sort((a, b) => (a.title > b.title ? 1 : -1));

    const RecipesGrid = this.state.product.Children.find(item => {
      return item.type === 'recipesList';
    });
    const liveEvent = this.state.product.Children.find(item => {
      return item.type === 'EmpowermentPackageLiveEvent';
    });
    //checks for the existense of certain content and adds them to the navItems (left sidebar) if it finds them
    const {navItems} = this.state;
    RecipesGrid
      ? navItems.push({name: 'Recipes', typeOfComponent: 'RecipesGrid', slug: 'recipes', icon: faUtensils})
      : null;

    //zoomeWebinar = Summit Replay
    const zoomWebinar = this.state.product.Children.filter(item => {
      return item.type === 'ZoomWebinar';
    });
    if (liveEvent) {
      navItems.push({name: 'Live Event', typeOfComponent: 'LiveEvent', slug: 'live-event', icon: faVideo});
    }

    //construct from store and api call
    const ProductBonuses = this.state.product.data.bonuses;
    let UserProducts = this.props.user.products; //for clarity
    //bonuses that are both in this product and in the user products
    //bonuse slugs that user has
    //UserProducts = []; for testing
    const IntersectionBonuses =
      ProductBonuses && UserProducts
        ? ProductBonuses.filter(productBonus => {
            return UserProducts.find(userProduct => {
              return userProduct.slug === productBonus;
            });
          })
        : null;

    return new Promise((resolve, reject) => {
      if (IntersectionBonuses && IntersectionBonuses.length > 0) {
        let {navItems} = this.state;

        IntersectionBonuses.sort((a, b) => (a > b ? 1 : -1));
        let promises = IntersectionBonuses.map(ib => this.mapBonus(ib)); //returns object like {navitem:{info to create side bar nav}, obj:{content item}}
        return Promise.all(promises).then(results => {
          navItems = navItems.concat(results.map(r => r.navItem)); //add nav items for each item in the results (e.g. 'bonuses', 'resource-kit')

          //if there are more than one extra content category, reduce to a single object
          let newNavObjs = results.reduce((accum, current) => {
            return {...accum, ...current.obj};
          }, {});
          resolve(this.setState({navItems, ...newNavObjs})); //navitems are passed down to each page within empowerment package
        });
      } else {
        resolve(true);
      }
    }).then(() => {
      this.setState({liveEvent, SummitBroadcastDays, RecipesGrid, zoomWebinar, nav: 'Home'});
    });
  }

  mapBonus(name) {
    let bonusName, bonusSlug;
    if (name.match(/resource-kit/)) {
      bonusName = 'Resource Kit';
      bonusSlug = 'resource-kit';
    } else {
      // standard bonus
      bonusName = 'Bonuses';
      bonusSlug = 'bonuses';
    }
    return this.getBonusesContent(name).then(res => {
      if (res) {
        //creating an object with the navitem (for the left side bar navigation) and the content of the bonus)
        let navItem = {name: bonusName, typeOfComponent: 'Bonuses', slug: bonusSlug, icon: faBook};
        let stateUpdateObject = {navItem, obj: {}};
        stateUpdateObject.obj[`${bonusName.replace(' ', '')}`] = res;
        return stateUpdateObject;
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.products !== prevProps.user.products) {
      //construct from store and api call
      const ProductBonuses = this.state.product.data.bonuses;
      let UserProducts = this.props.user.products; //for clarity
      //bonuses that are both in this product and in the user products
      //bonuse slugs that user has
      //UserProducts = []; for testing
      const IntersectionBonuses =
        ProductBonuses && UserProducts
          ? ProductBonuses.filter(productBonus => {
              return UserProducts.find(userProduct => {
                return userProduct.slug === productBonus;
              });
            })
          : null;
      return new Promise((resolve, reject) => {
        if (IntersectionBonuses && IntersectionBonuses.length > 0) {
          let {navItems} = this.state;
          let newNavItems = navItems.filter(item => !['bonuses', 'resource-kit'].includes(item.slug));

          IntersectionBonuses.sort((a, b) => (a > b ? 1 : -1));
          let promises = IntersectionBonuses.map(ib => this.mapBonus(ib)); //returning the content (in promises) of bonuses
          return Promise.all(promises).then(results => {
            newNavItems = newNavItems.concat(results.map(r => r.navItem));
            let newNavObjs = results.reduce((accum, current) => {
              return {...accum, ...current.obj};
            }, {});

            resolve(this.setState({navItems: newNavItems, ...newNavObjs}));
          });
        } else {
          resolve(true);
        }
      });
    }
  }

  handleNav = (name, component, slug, slugTwo) => {
    //for navigating to a single recipe
    if (slug && slugTwo) {
      const path = `/products/${this.thisProductInPath}/${slug}/${slugTwo}`;
      this.props.history.push(path);
    }
    //navigating to a day or recipes page
    if (slug && !slugTwo) {
      this.props.history.push(`/products/${this.thisProductInPath}/${slug}`);
    }
    //navigate home
    if (!slug && !slugTwo) {
      this.props.history.push(`/products/${this.thisProductInPath}`);
    }

    window.scrollTo(0, 0);
  };

  getBonusesContent = bonusSlug => {
    const {authToken} = JSON.parse(localStorage.getItem('user'));
    return Api.get(`/products/${bonusSlug}/content`, authToken);
  };

  render() {
    const {navItems, SummitBroadcastDays, RecipesGrid, Bonuses, ResourceKit, liveEvent} = this.state;

    return (
      <div>
        <Switch>
          <Route
            key={`EmpowermentPackage-${this.thisProductInPath}`}
            exact
            path={`/products/${this.thisProductInPath}`}
            render={routeProps => (
              <WrapperEmpowermentPackage
                {...routeProps}
                product={this.props}
                SummitBroadcastDays={SummitBroadcastDays}
                RecipesGrid={RecipesGrid}
                navItems={navItems} //navitems determined above
                handleNav={this.handleNav}
                today={this.state.today}
              />
            )}
          />
          {/* routes for all the broadcast days */}
          {SummitBroadcastDays
            ? SummitBroadcastDays.map((day, index) => {
                return (
                  <Route
                    key={`${day.name}-${this.thisProductInPath}`}
                    exact
                    path={`/products/${this.thisProductInPath}/day-${index + 1}`}
                    render={routeProps => (
                      <WrapperBroadcastDay
                        {...routeProps}
                        navItems={navItems}
                        handleNav={this.handleNav}
                        day={day}
                        product={this.state.product}
                        productPath={this.thisProductInPath}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* RECIPES GRID */}
          {RecipesGrid ? (
            <Route
              key={`recipes-grid-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/recipes`}
              render={routeProps => (
                <WrapperRecipes
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  {...RecipesGrid}
                  product={this.state.product}
                  currentContent={RecipesGrid}
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {/* SINGLE RECIPE ROUTES */}
          {RecipesGrid
            ? RecipesGrid.Children.map((recipe, index) => {
                return (
                  <Route
                    key={recipe.slug}
                    exact
                    path={`/products/${this.thisProductInPath}/${recipe.slug}`}
                    render={routeProps => (
                      <WrapperSingleRecipeV2
                        {...routeProps}
                        navItems={navItems}
                        handleNav={this.handleNav}
                        recipes={RecipesGrid.Children}
                        product={this.state.product}
                        currentContent={recipe}
                        singleRecipeInUrl={recipe.slug}
                        productPath={this.thisProductInPath}
                      />
                    )}
                  />
                );
              })
            : null}
          {/* BONUSES ROUTE */}
          {Bonuses ? (
            <Route
              key={`bonuses-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/bonuses`}
              render={routeProps => (
                <WrapperBonuses
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  Bonuses={Bonuses}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
          {/* RESOURCE KIT ROUTE */}
          {ResourceKit ? (
            <Route
              key={`resource-kit-${this.thisProductInPath}`}
              exact
              path={`/products/${this.thisProductInPath}/resource-kit`}
              render={routeProps => (
                <WrapperBonuses
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  Bonuses={ResourceKit}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                  name={'Resource Kit'}
                />
              )}
            />
          ) : null}
          {liveEvent ? (
            <Route
              key={`live-event`}
              exact
              path={`/products/${this.thisProductInPath}/live-event`}
              render={routeProps => (
                <WrapperLiveEvent
                  {...routeProps}
                  navItems={navItems}
                  handleNav={this.handleNav}
                  liveEvent={liveEvent}
                  product={this.state.product}
                  productPath={this.thisProductInPath}
                />
              )}
            />
          ) : null}
        </Switch>
      </div>
    );
  }
}

EmpowermentPackage.propTypes = {
  id: PropTypes.number.isRequired
};

EmpowermentPackage.displayname = 'EmpowermentPackage';

export default withRouter(EmpowermentPackage);

/*

Recipe List for the empowerment package
{
    "published": true,
    "free": false,
    "id": 3324,
    "type": "recipesList",
    "name": "EP18 Recipes",
    "title": "Empowerment Package Recipes Collection",
    "slug": "ep18-recipes",
    "data": {
        "thumb": {
            "url": "https://members.foodrevolution.org/wp-content/uploads/2014/10/Recipes-HealthyKitchen.jpg",
            "alt": "Empowerment Package 2018 Recipes",
            "text": "Recipe Collection"
        },
        "description": "Recipes Collection from the 2018 Empowerment Package."
    },
    "Children": [],
    "updatedAt": "2019-03-13T03:52:00.985Z",
    "createdAt": "2019-03-13T03:52:00.985Z"
}


*/
