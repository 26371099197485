import React, {Component} from 'react';

import {Collapse} from 'reactstrap';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faLeaf} from '@fortawesome/pro-regular-svg-icons/faLeaf';
import {faChevronUp} from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import {getFullTimeString} from 'lib/time';
import Icon from 'components/Icon';

import LessonBody from './components/LessonBody';
import LessonHeaderTracker from './components/LessonHeaderTracker';
import {Card, CardBody, CardHeader} from 'reactstrap';

//wraps one Lesson in Module Page
//has a header for progress
//has a the content of the lesson (the Children)
class LessonWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen || false,
      prevPropsIsOpen: this.props.isOpen
    };

    this.toggle = this.toggle.bind(this);
    this.videoProgressReport = this.videoProgressReport.bind(this);
    //TOO buggy for now
    //this.scrollToContent = this.scrollToContent.bind(this);
    this.scrollToNextLesson = this.scrollToNextLesson.bind(this);

    this.completionPercentage = 90; //percent of video needed to be considered complete

    this.lessonToScroll = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollToContent();
    }, 2000);
  }

  //What lesson is current (the next lesson that is not completed) is determined ModulePage and passed down as props
  //when that happens, we need to override the our local state.
  //Otherwise, we want the user to be able to close the lesson, we we also update isOpen in the local state through local methods (this.toggle)
  static getDerivedStateFromProps(props, prevState) {
    if (props.isOpen !== prevState.prevPropsIsOpen) {
      return {
        isOpen: props.isOpen, //this is manipulated by component state as well,
        prevPropsIsOpen: props.isOpen //keeping track of the last motion from props, if it is ever different, we overrid the isOpen state
      };
    }
    return null;
  }

  //if a video is fully watched in the lesson, then mark lesson complete
  videoProgressReport(percentage) {
    const {updateModuleStates, moduleID, lessonID} = this.props;

    if (percentage >= this.completionPercentage) {
      updateModuleStates(moduleID, lessonID, null, true);
    }
    //this will call updateModuleState
  }
  toggle() {
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  }

  //scroll to a lesson if it is open
  //TODO figure out why we need a constant
  scrollToContent(offsetBump = 160) {
    return;
    /*
    //if this lesson is open, then scroll to it
    if (!this.props.isUnstructured && this.state.isOpen && !this.props.lessonState.completed) {
      //timout used to ensure the reference exists when it is called by child
      setTimeout(() => {
        const offSet = this.lessonToScroll.current.offsetTop;

        window.scrollTo({
          top: offSet + offsetBump, //TODO ref offset is not quite correct, using a constant
          behavior: 'smooth'
        });
      }, 1000);
    }
    */
  }
  //scrolls to the next lesson, called when a lesson is marked as complete
  scrollToNextLesson() {
    // this.scrollToContent(0);
    const offSet = this.lessonToScroll.current.offsetTop;

    setTimeout(() => {
      window.scrollTo({
        top: offSet, //TODO ref offset is not quite correct, using a constant
        behavior: 'smooth'
      }),
        900;
    });
  }
  render() {
    const {lessonState, lesson, overrideLockedContent} = this.props;
    const {isOpen} = this.state;
    const data = lesson && lesson.data ? lesson.data : null;

    let today = Date.now(); // + 10000000000000, //for testing
    const premieresAt = data && data.premieresAt ? data.premieresAt : 1; //if it doesn't exist assume it premiered in 1970
    const lessonLocked = !overrideLockedContent && today && premieresAt && parseInt(premieresAt) > parseInt(today);

    const headerColor = lessonState && lessonState.completed ? 'bg-light' : lessonLocked ? 'bg-dark' : 'bg-light'; // keeping this in for now just in case we want to revert back'bg-success' : 'bg-light';
    const shadowClass = isOpen ? 'rounded-top border-bottom-0' : 'shadow rounded border';

    return (
      <div ref={this.lessonToScroll}>
        <Card className={lessonLocked ? 'lessonLocked' : ''}>
          {/* HEADER  */}
          <CardHeader className={headerColor} onClick={this.toggle} role={!lessonLocked ? 'button' : ''}>
            <LessonHeaderTracker
              {...this.props}
              icons={[faCheckCircle, faCircle]}
              iconsAccordian={[faChevronUp, faChevronDown]}
              isOpen={isOpen}
              lessonLocked={lessonLocked}
              premieresAt={premieresAt}
            />
          </CardHeader>
          {/* BODY OF THE LESSON  */}
          {lessonLocked ? null : (
            <Collapse isOpen={isOpen}>
              <LessonBody
                {...this.props}
                videoProgressReport={this.videoProgressReport}
                scrollToNextLesson={this.scrollToNextLesson}
                insideCard
              />
            </Collapse>
          )}
        </Card>
      </div>
    );
  }
}

export default LessonWrapper;
