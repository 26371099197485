import React, {PureComponent} from 'react';
import {ForumWrapper} from 'components/ForumWrapper';
import {actions} from 'modules/user';
import {connect} from 'react-redux';
import {page} from '../../../../../../lib/track';

const urlToCategory = () => {
  const urlParts = location.hash.split('/');
  if (urlParts[1] === 'category' && urlParts[2]) {
    return urlParts[2];
  }
  return null;
};

@connect(state => ({user: state.user}), {
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey,
  refreshToken: actions.refreshToken
})
class ForumPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    page('forum');
  }

  render() {
    //Render a redirect to the welcome page for the course IF purchased
    return <ForumWrapper selectedCategory={urlToCategory()} forumType="Forum" {...this.props} />;
  }
}

export default ForumPage;
