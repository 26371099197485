/* eslint-disable */
import {CollectionOne, CollectionTwo} from '../Collection/testData';
import collectionsListOne from '../CollectionsList/testData';
import recipesListOne from '../RecipesList/testData';
import video from '../Video/testData';
import videoResource from '../VideoResource/testData';
import article from '../Article/testData';
import bonuses from '../Bonuses/testData';
import specialReportsOne from '../SpecialReports/testData';

// import materials from '../../pages/Materials/testData';

const resources = {
  type: 'resourcesList',
  name: 'Resources',
  slug: 'wlc-course-resources',
  title: 'Recources',
  data: {
    //thumb is for a link on a different page
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Recipes and Spoon',
      text: 'A collection of collections - page level.'
    }
  },

  Children: [article, collectionsListOne, video, CollectionOne, CollectionTwo, bonuses, specialReportsOne]
};

export default resources;
