/* eslint-disable max-len */

import {specialReportsDownload} from '../Downloads/testData';
// import { specialReportOne, specialReportTwo } from './components/SpecialReportsListItem/testData';

export const specialReportsOne = {
  type: 'specialReports',
  name: 'Special Reports One',
  slug: 'ppt-17-special-reports',
  title: 'Plant-Powered & Thriving - Special Reports',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Special Reports One',
      text:
        'Ipsum lorem SPECIAL REPORTS testData data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    },
    value: '88.00',
    description: 'On this page you will find special reports provided by the Food Revolution Network.'
  },
  Children: [specialReportsDownload, specialReportsDownload]
};

export default specialReportsOne;
