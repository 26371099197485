import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col} from 'reactstrap';
import Recipe from '../../../../components/Recipe';
import BreadCrumbs from '../BreadCrumbs';
import BackNav from 'routes/Products/components/BackNav';
import epStyles from 'routes/Products/products/EmpowermentPackageV2/styles.js';
const WrapperSingleRecipeV2 = props => {
  const recipes = props.recipes;
  const singleRecipeInUrl = recipes ? recipes.find(r => r.slug === props.singleRecipeInUrl) : '';
  return (
    <Container>
      <div style={epStyles.backNavWrapperStyle}>
        <BackNav />
      </div>
      <div style={{marginTop: '20px'}}>
        <BreadCrumbs>
          <Link to={`/products/${props.productPath}`} className="pr-1">
            {props.product.title}
          </Link>{' '}
          /{' '}
          <Link to={`/products/${props.productPath}/recipes`} className="px-1">
            Recipes
          </Link>{' '}
          / {props.currentContent.title}
        </BreadCrumbs>
      </div>
      <Row>
        <Col>
          <Recipe {...singleRecipeInUrl} />
        </Col>
      </Row>
    </Container>
  );
};

export default WrapperSingleRecipeV2;
