import React from 'react';
// When using npm, import Sentry
//import * as Sentry from '@sentry/browser';
//Sentry.init({dsn: 'https://3c2919e6a75e4fa5858bc9b2ef7fc08d@sentry.io/1084578'});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
    //Sentry.captureException(new Error('Testing CDN Something broke'));
    console.log('window ', window);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    Sentry.captureException(new Error('Something broke'));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
