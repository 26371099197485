/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {text} from '@fortawesome/fontawesome-svg-core';
import React, {PureComponent} from 'react';
import Api from 'lib/api.js';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Markdown from '../../../../components/Markdown';

class Disclaimer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: DEFAULT_TEXT
    };
  }

  componentDidMount() {
    const {contentId: id, productInPath} = this.props;
    const url = `/content/${id}/${productInPath}`;
    if (!id || !productInPath) {
      this.setState({loading: false});
    }
    Api.get(url, this.props.authToken).then(d => {
      if (!d || !d.data || !d.data.text) {
        this.setState({text: 'An error occurred, please contact support.'});
      }
      this.setState({
        text: d.data.text,
        loading: false
      });
    });
  }

  render() {
    const {
      state: {loading, text}
    } = this;

    return <Markdown source={loading ? 'Loading...' : text} />;
  }
}

const DEFAULT_TEXT = `# FOOD REVOLUTION NETWORK
# HEALTHY BRAIN

## TERMS OF ENROLLMENT

The following Terms of Enrollment govern your participation in the Program presented by **Food Revolution Network, Inc.** ("Company"). Please read these Terms of Enrollment carefully. By visiting and using the Program Portal/Membership Site you agree that your use of our Site, participation in our Program, and use of Program materials is governed by the following terms and conditions, together with our Terms of Service and Privacy Policy.

**Healthy Brain: Eat, Move, Sleep, Think and Play for Lasting Brain Health** ("Program") focuses on the implementation of lifestyle choices that have been demonstrated to contribute to improved memory and other aspects of brain function. The Program is provided as an online course consisting of 6 modules. Each module includes 7 video lessons that are 2-20 minutes in length. The Program is intended to last 6 weeks, but you can complete the modules at your own pace.

We are committed to providing all participants with a positive experience. Thus, COMPANY may, at its sole discretion, limit, suspend, or terminate your participation in any of its programs, live, recorded, social media-based or digital without refund or forgiveness of remaining payments if:

-   you become disruptive or difficult to work with;
-   you fail to follow the program guidelines; or,
-   you impair the participation of our instructors or participants in our program(s).

You hereby acknowledge and agree that:

-   The Program is not intended as a substitute for, or in lieu of, other health and wellness guidance, training, certification, accreditation and/or licensing.

-   Your participation in this Program does not independently authorize you to render care, prescribe regimens or prescribe medications or supplements.

**1\. Content:**

-   Program education and information is intended for a general audience and does not purport to be, nor should it be construed as, specific advice, tailored to any individual.

-   All materials, procedures, policies, and standards, all teaching manuals, all teaching aids, all supplements and the like that have been or will be made available Company or its designated facilitators, or any other source, oral or written, are for personal use in or in conjunction with this training program only. 

-   Program content is for personal use only, and may not be sold, recorded, videotaped, shared, taught, given away, or otherwise divulged without the express written consent of Company, or its designated agent.

-   The information contained in program material is strictly for educational purposes. Therefore, if you wish to apply ideas contained in this material, you are taking full responsibility for your actions.

-   We assume no responsibility for errors or omissions that may appear in any program materials.

-   User names and passwords may not be shared with any third-parties.

-   Any violation of Company's policies regarding content usage shall result in the immediate termination of your enrollment without refund.

**2\. Privacy and Confidentiality:**

We respect your privacy and must insist that you respect the privacy of fellow Program participants.

We respect your confidential and proprietary information, ideas, plans and trade secrets (collectively, "Confidential Information") and must insist that you respect the same rights of fellow Program participants and of the Company.

Thus, you agree:

- not to infringe any Program- participants or the Company's copyright, patent, trademark, trade secret or other intellectual property rights;

- that any Confidential Information shared by Program participants or any representative of the Company is confidential and Proprietary, and belongs solely and exclusively to the Participant who discloses it or the Company;

- not to disclose such information to any other person or use it in any manner other than in discussion with other Program participants during Program sessions;

- that all materials and information provided to you by the Company are its confidential and proprietary intellectual property belong solely and exclusively to the Company, and may only be used by you as authorized by the Company;

- the reproduction, distribution and sale of these materials by anyone but the Company is strictly prohibited;

- that if you violate, or display any likelihood of violating, any of your agreements contained in this paragraph the Company and/or the other Program participant(s) will be entitled to injunctive relief to prohibit any such violations to protect against the harm of such violations.

While you are free to discuss your personal results from our programs and training, you must keep the experiences and statements, oral or written, of all other participants in the strictest of confidence

**3\. Fees:**

Your enrollment fee for the Program was documented on the receipt emailed to you at the time of purchase. Our price varies depending upon which promotion we are running when you sign up.

Unless otherwise stated, we offer full refunds for all digital product purchases made on the Foodrevolution.org website, within 60 days of date of purchase. If customers are not satisfied with a digital product purchase, they must contact us within 60 days by emailing to support@foodrevolution.org. Please put "Refund" in the subject and give us the email address you used with your purchase, for a full refund.

**4\. Limitation of Liability:**

UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE, OUR SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE PROGRAM, PROGRAM MATERIALS AND SITE, INCLUDING OUR MESSAGING, BLOGS, COMMENTS OF OTHERS, BOOKS, VIDEOS, EMAILS, PRODUCTS, OR SERVICES, OR THIRD-PARTY MATERIALS, PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THE PROGRAM, PROGRAM MATERIALS AND SITE OR BY US IN ANY WAY, EVEN IF WE ARE ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY AND THE LIABILITY OF OUR SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.) YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER. IF YOU ARE DISSATISFIED WITH THE PROGRAM, PROGRAM MATERIALS AND SITE, ANY MATERIALS, PRODUCTS, OR SERVICES ON THE SITE, OR WITH ANY OF THE SITE'S TERMS AND CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE PROGRAM, PROGRAM MATERIALS AND SITE AND THE PRODUCTS, SERVICES AND/OR MATERIALS.

THE PROGRAM, PROGRAM MATERIALS AND SITE IS CONTINUALLY UNDER DEVELOPMENT AND COMPANY MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE.

THE PROGRAM, PROGRAM MATERIALS AND SITE OFFER HEALTH, WELLNESS, FITNESS AND NUTRITIONAL INFORMATION WHICH ARE DESIGNED FOR EDUCATIONAL PURPOSES ONLY. YOU SHOULD NOT RELY ON THIS INFORMATION AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU SHOULD ALWAYS CONSULT WITH A PHYSICIAN OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE FROM YOUR HEALTH-CARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY HAVE READ ON THE PROGRAM, PROGRAM MATERIALS AND SITE. THE USE OF ANY INFORMATION PROVIDED ON THE PROGRAM, PROGRAM MATERIALS AND SITE IS SOLELY AT YOUR OWN RISK.

NOTHING STATED OR POSTED ON THE PROGRAM, PROGRAM MATERIALS AND SITE OR AVAILABLE THROUGH ANY SERVICES ARE INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICAL OR COUNSELING CARE. FOR PURPOSES OF THIS AGREEMENT, THE PRACTICE OF MEDICINE AND COUNSELING INCLUDES, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY, PSYCHOTHERAPY, OR PROVIDING HEALTH CARE TREATMENT, INSTRUCTIONS, DIAGNOSIS, PROGNOSIS OR ADVICE.

**5\. License:**

Your use of, and access to the Program Materials is on a licensed basis. In that regard, Company hereby grants to you, and you hereby accept, the non-exclusive right, license and privilege to use (but not sublicense) the Program Materials on an indefinite basis.

You agree to:

(a) constantly use your best efforts in the use of the Program Materials in a way to protect the good name and goodwill associated with the Program Material and Company. 

(b) not to attack the title of Company in and to the Program Material nor attack the validity of the license granted hereunder;

(c) not harm, misuse or bring into disrepute the Program Material and Company, but to the contrary, will maintain the value and reputation thereof to the best of your ability;

(d) at all times comply with all applicable government laws and regulations, and all voluntary industry standards relating or pertaining to the use of the Programs and Materials, and shall maintain appropriate customary high-quality standards. You shall also abide by Company's suggestions and specifications regarding quality control over the use of the Program Material.

The license granted herein does not grant you any right, title or interest, at law or in equity, in or to any of Company's trademarks, service marks, copyrights, copyrighted material, or any derivative uses thereof, or the name, likeness or image of Ocean Robbins, Drs. Dean and Ayesha Sherzai,  or any other company or program representative, nor trade secrets or other rights or intellectual property of any kind, except as provided by said license.  Further, such license applies only to those Program Materials designated herein. You shall not represent to others, or conduct yourself in any manner that might indicate to others, that you possess any other legal or equitable rights in our Program Material, Trademarks, copyrighted materials, trade secrets or other rights or intellectual property of any kind other than by virtue of the license granted hereunder.

Company shall have the right to approve all uses of the Program Material or derivative uses thereof.

Company shall have and hereby reserves all rights and remedies which it has, or which are granted to it by operation of law, to enjoin the unlawful or unauthorized use of the Program Material (any of which injunctive relief may be sought in the courts, and also may be sought prior to or in lieu of termination), and to be compensated for damages for breach of this Agreement.

**6\. Dispute Resolution:**

All disputes arising under or concerning this Agreement are to be submitted to binding arbitration, inSanta Cruz, California, to be resolved in accordance with the laws of the state of California.

You may only resolve disputes with us on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. Class arbitrations, class actions, private attorney general actions, and consolidation with other arbitrations aren't allowed.

The arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a class or representative proceeding or claims (such as a class action, consolidated action or private attorney general action) unless all relevant parties specifically agree to do so following initiation of the arbitration.
`;

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  products: state.user.products
});

export default compose(connect(mapStateToProps))(Disclaimer);
