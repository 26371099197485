import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Weeks from 'routes/Products/components/Weeks';
import getContent from 'lib/getContent';
import {connect} from 'react-redux';
import {Container} from 'reactstrap';

@connect(state => ({authToken: state.user.authToken}), {})
class WeeksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentItem: null,
      thisWeek: null
    };
    this.getWeeks = this.getWeeks.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    //<Weeks> expects sortedWeeksList, thisWeek
    this.getWeeks();
  }
  getWeeks() {
    const {product, slug} = this.props;

    getContent(slug, product, this.props.authToken, '?depth=2')
      .then(res => {
        let sortedWeeksList = res.Children; //clarity

        sortedWeeksList.reverse();
        const thisWeek = sortedWeeksList[res.Children.length - 1]; //for clarity
        this.setState({contentItem: res, thisWeek, sortedWeeksList});
      })
      .catch(e => {
        console.log('error getting Weeks in Weeks Page');
      });
  }

  render() {
    const {contentItem, thisWeek, sortedWeeksList} = this.state;
    if (contentItem && thisWeek) {
      return (
        <div>
          <Weeks key={contentItem.id} sortedWeeksList={sortedWeeksList} thisWeek={thisWeek} />
        </div>
      );
    } else {
      //Render a redirect to the welcome page for the course IF purchased
      return (
        <div key="loading-weeks">
          <Container>Loading weeks...</Container>
        </div>
      );
    }
  }
}

WeeksPage.propTypes = {
  sortedWeeksList: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default WeeksPage;
