/* staging
import Children from '../../../../Create/containers/Children/index';
{
    "id": 21115,
    "name": "HHC - Recipes",
    "title": "HHC - Recipes",
    "slug": "hhc-recipes",
    "type": "recipesList",
    "data": {
        "description": ""
    },
    "published": true,
    "publishDate": null,
    "free": false,
    "updatedAt": "2021-03-01T19:14:59.081Z",
    "createdAt": "2021-03-01T19:14:59.081Z"
}
*/

const recipeOne = {
	id: 2430,
	name: 'Recipe - Cocoa Truffles',
	title: 'Cocoa Truffles',
	slug: 'cocoa-truffles',
	type: 'recipe',
	data: {
		image: {
			alt: 'Cocoa Truffles',
			url: 'https://cdn.foodrevolution.org/members/assets/recipes/cocoa-truffles.jpg'
		},
		thumb: {
			alt: 'Cocoa Truffles',
			url: 'https://cdn.foodrevolution.org/members/assets/recipes/cocoa-truffles.jpg'
		},
		cookTime: '0',
		prepTime: '15',
		totalTime: '15',
		directions: [
			{
				directionsList: [
					'Place the dates in a food processor fitted with a metal blade and pulse several times until you have a paste.',
					'Add ½ cup cocoa powder, 2 tablespoons warm water, vanilla, and salt. Pulse until mixture is smooth and forms a ball. If the mixture needs a little more moisture to come together, add 1-2 more teaspoons of water.',
					'Carefully remove the blade from the food processor.',
					'Place ¼ cup cocoa powder in a shallow bowl. ',
					'Portion 1 tablespoon of mixture per truffle, and roll the mixture between the palms of your hands to shape it into a ball. Roll the ball in the cocoa powder. Place on a plate and repeat with remaining mixture. ',
					'Cover and refrigerate the truffles until you want to serve them.'
				]
			}
		],
		attribution: {
			url: 'https://www.ornish.com/sample-menu/',
			name: 'Ornish Kitchen',
			text: 'Recipe and photo from'
		},
		description:
			'Fudgy, moist, and full of decadent flavor, these treats will satisfy a chocolate craving in a healthy way.',
		recipeNotes: [
			{
				text: 'Substitute raw cacao powder for the dark cocoa powder.',
				title: 'Raw Version'
			},
			{
				text: 'Add ½ teaspoon cinnamon and ⅛ teaspoon of cayenne in step 2 above.',
				title: 'Mexican Variation'
			},
			{
				text:
					'These freeze incredibly well. Make a batch and freeze for up to 1 month. Storing them in the freezer can also help with portion control. Thaw a few minutes before eating.',
				title: 'Freezer-Friendly'
			},
			{
				text:
					'For best results, use soft, moist dates for this recipe. If you can only find firm, dry ones, soak them in hot water to cover for 15 minutes. Drain before proceeding with the recipe.',
				title: 'Softened Dates'
			}
		],
		servingSize: '2 truffles',
		yieldString: '8'
	},
	published: true,
	publishDate: null,
	free: false,
	createdAt: '2019-01-24T21:43:59.000Z',
	updatedAt: '2019-01-24T21:43:59.000Z'
};
const recipeTwo = {
	id: 2471,
	name: 'Recipe - Baked Falafel Cakes',
	title: 'Baked Falafel Cakes',
	slug: 'baked-falafel-cakes',
	type: 'recipe',
	data: {
		image: {
			alt: 'Baked Falafel Cakes',
			url: 'https://cdn.foodrevolution.org/members/assets/recipes/baked-falafel-cakes.jpg'
		},
		thumb: {
			alt: 'Baked Falafel Cakes',
			url: 'https://cdn.foodrevolution.org/members/assets/recipes/baked-falafel-cakes.jpg'
		},
		cookTime: '20',
		prepTime: '5',
		totalTime: '25',
		directions: [
			{
				directionsList: [
					'Preheat the oven to 350°F and line a baking sheet with parchment paper or a silpat baking mat.',
					'In a food processor, combine the chickpeas, parsley, garlic, coriander, cumin, paprika, cayenne (if using), salt, baking soda, and brown rice flour. Puree, scraping down the sides of the bowl as needed.',
					"The falafel mixture should hold together. If it crumbles, it is too dry — drizzle in a tablespoon or two of water. If it's too wet, add another tablespoon or two of brown rice flour. Taste to adjust seasoning.",
					'Spread the gluten-free breadcrumbs or cornmeal on your lined baking sheet. ',
					'Portion a couple of tablespoons for each falafel and use a spoon to shape into a small disk, then place the falafel on the baking sheet.  ',
					'Flip the falafel so they have breadcrumbs on both sides. ',
					'Bake until crispy on the outside and warm in the center, about 15 minutes.'
				]
			}
		],
		attribution: {
			url: 'https://www.plants-rule.com/baked-falafel-cakes/?rq=falafel',
			name: 'Plants-Rule',
			text: 'Recipe adapted from'
		},
		description:
			'Middle Eastern spices, including cumin and coriander, flavor these tasty snacks. Fresh parsley adds a beautiful color and a bright flavor.',
		recipeNotes: [
			{
				text:
					'If you prefer to cook your own beans, use 1¾ cups cooked beans for every 1 (15-ounce) can of beans.',
				title: 'Cooked Beans'
			},
			{
				text: 'Omit the salt.',
				title: 'Salt-Free'
			},
			{
				text:
					'The breadcrumbs help give the falafel some crunch, imitating the texture they’d have if they were fried. You can omit for a less calorie-dense option.',
				title: 'Breadcrumb Coating'
			}
		],
		servingSize: '3 falafel cakes',
		yieldString: '4'
	},
	published: true,
	publishDate: null,
	free: false,
	createdAt: '2019-01-31T17:17:48.000Z',
	updatedAt: '2019-01-31T17:17:48.000Z'
};

const recipesList = {
	id: 21115,
	name: 'HHC - Recipes',
	title: 'HHC - Recipes',
	slug: 'hhc-recipes',
	type: 'recipesList',
	data: {
		description: ''
	},
	published: true,
	publishDate: null,
	free: false,
	updatedAt: '2021-03-01T19:14:59.081Z',
	createdAt: '2021-03-01T19:14:59.081Z',
	Children: [ recipeOne, recipeTwo ]
};
export default recipesList;
//module to recipes list
/*{
    "id": 42014,
    "ParentId": 21107,
    "ChildId": 21115,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T19:21:15.521Z",
    "createdAt": "2021-03-01T19:21:15.521Z"
}

recipesLIst to cocoa
{
    "id": 42015,
    "ParentId": 21115,
    "ChildId": 2430,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:14:26.907Z",
    "createdAt": "2021-03-01T20:14:26.907Z"
}

recipeList to orange-balsamic
{
    "id": 42016,
    "ParentId": 21115,
    "ChildId": 10881,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:15:25.522Z",
    "createdAt": "2021-03-01T20:15:25.522Z"
}

recipesList to morrocan
{
    "id": 42017,
    "ParentId": 21115,
    "ChildId": 11565,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:18:05.447Z",
    "createdAt": "2021-03-01T20:18:05.447Z"
}
recipeslist to smokey
{
    "id": 42018,
    "ParentId": 21115,
    "ChildId": 2845,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:18:48.323Z",
    "createdAt": "2021-03-01T20:18:48.323Z"
}
recipelist to white bean guac
{
    "id": 42019,
    "ParentId": 21115,
    "ChildId": 11051,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:20:51.150Z",
    "createdAt": "2021-03-01T20:20:51.150Z"
}
recipeslist to savory 
{
    "id": 42020,
    "ParentId": 21115,
    "ChildId": 10813,
    "sortOrder": 1,
    "updatedAt": "2021-03-01T20:21:26.093Z",
    "createdAt": "2021-03-01T20:21:26.093Z"
}
*/
