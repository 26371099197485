/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';

const DownloadProduct = props => (
  <Container className="pt-5">
    <h4>{props.title}</h4>
    <Card>
      <CardBody>
        <Container fluid>
          <Row>
            <Col xs="12" md="9">
              <p>{props.data.description}</p>
              <Button href={props.data.url} target="_blank" block style={{whiteSpace: 'normal'}} size="lg">
                Download {props.data.text}
              </Button>
            </Col>
            <Col xs="12" md="3" className="mt-4 mt-md-0 order-md-first">
              <img src={props.data.thumb.url} alt={props.data.thumb.alt} style={{width: '100%'}} />
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  </Container>
);

DownloadProduct.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    thumb: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    url: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired
};

DownloadProduct.defaultProps = {
  data: {
    description: '',
    text: '',
    thumb: {
      alt: '',
      url: ''
    },
    url: ''
  },
  title: ''
};

DownloadProduct.displayname = 'DownloadProduct';

export default DownloadProduct;
