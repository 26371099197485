/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Progress} from 'reactstrap';
import styles from 'styles/index.scss';
import {secondsToTime} from 'lib/time';

const {progressBarContainer} = styles;

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.el = React.createRef();
  }

  handleClick = e => {
    e.preventDefault();
    const {player} = this.props;
    // console.log('player: ', player)
    // const { currentTime } = player.current;
    // console.log('currentTime: ', currentTime)
    const {offsetX} = e.nativeEvent;
    const totalWidth = this.el.current.clientWidth;
    const percent = offsetX / totalWidth;
    const newTime = percent * player.current.duration;
    this.props.seekTo(newTime);
  };

  render() {
    const {currentTime, timeRemaining} = this.props;

    return (
      <div className={progressBarContainer}>
        <div>{currentTime ? secondsToTime(Math.ceil(currentTime)) : '0:00'}</div>
        <div className="flex-grow-1 pl-3 pr-3" ref={this.el}>
          <Progress onClick={this.handleClick} id="progress" color="secondary" value={this.props.progress} />
        </div>
        <div>{timeRemaining ? `-${secondsToTime(Math.ceil(timeRemaining))}` : ''}</div>
      </div>
    );
  }
}

Progress.propTypes = {
  currentTime: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  timeRemaining: PropTypes.number.isRequired
};

export default ProgressBar;
