/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, {PureComponent} from 'react';
import {Tooltip} from 'reactstrap';
import styles from 'styles/index.scss';
const {userBadge, userBadgeImg} = styles;

export default class Badge extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
    this.badge = React.createRef();
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    let size = this.props.size || 30;
    size = isNaN(size) ? `${size}px` : size;
    return (
      <div className={userBadge}>
        <div
          style={{backgroundImage: `url("${this.props.url}")`, width: size, height: size}}
          ref={this.badge}
          className={userBadgeImg}
        />
        <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={() => this.badge.current} toggle={this.toggle}>
          {this.props.name}
        </Tooltip>
      </div>
    );
  }
}
