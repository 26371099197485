/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
// import { connect } from 'react-redux';
import {actions} from 'modules/user';
import Controls from './components/Controls';
import Header from './components/Header';
import Text from './components/Text';
import ProgressBar from './components/ProgressBar';
import DownloadsList from './components/DownloadsList';
import styles from 'styles/index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const {audioContainer, apmPictureV2, apmPictureContainer, apmDownloadsListWide} = styles;

class AudioPlayerMobile extends PureComponent {
  constructor(props) {
    super(props);
    // set save on component if you want to save
    this.save = props.save;
    const audio = props.Children.find(child => child.type === 'audioInterview');
    this.audioInterview = audio;
    this.id = audio.id;
    this.src = audio.data.url;
    this.bio = audio.Children.find(child => child.type === 'bio');
    this.downloadsList = props.Children.find(child => child.type === 'downloadsList');

    this.state = {progress: 0, liked: false, currentTime: 0, isPlaying: false};
    // Starts polling on init
    this.poll = setInterval(this.polling, 250);
    this.player = React.createRef();
  }

  componentDidMount() {
    this.duration = this.player.current.duration;
    this.getProgress();
    window.onbeforeunload = this.saveProgress;
  }

  componentWillUnmount() {
    clearInterval(this.poll);
  }

  polling = () => {
    if (this.player && this.player.current && this.player.current.duration) {
      window.player = this.player; // delete me
      const {currentTime, duration} = this.player.current;
      if (!this.duration) this.duration = duration;
      const progress = (currentTime / duration) * 100;
      const timeRemaining = duration - currentTime;
      this.setState({progress, isPlaying: !this.player.current.paused, currentTime, timeRemaining});
    }
  };

  toggleLiked = () => this.setState({liked: !this.state.liked});

  toggleBookmarked = () => this.setState({bookmarked: !this.state.bookmarked});

  handleClickPauseOrPlay = () => {
    if (this.state.isPlaying) {
      this.player.current.pause();
      this.setState({isPlaying: false});
    } else {
      this.player.current.play();
      this.setState({isPlaying: true});
    }
  };

  seekTo = newTimeInSeconds => {
    const {duration} = this.player.current;
    const {isPlaying} = this.state;
    this.player.current.currentTime = newTimeInSeconds;
    const progress = (newTimeInSeconds / duration) * 100;
    const timeRemaining = duration - newTimeInSeconds;
    if (!isPlaying) {
      this.player.current.play();
    }
    this.setState({progress, currentTime: newTimeInSeconds, timeRemaining, isPlaying: true});
  };

  async getProgress() {
    if (this.save) {
      const savedData = await this.props.get(this.id);
      if (savedData) {
        this.setState({...savedData});
        if (savedData.currentTime) {
          this.player.current.currentTime = savedData.currentTime;
          // Comment in for auto play:
          // this.player.current.play();
        }
      }
    }
  }

  saveProgress = () => {
    if (this.save && this.player && this.player.current) {
      const {currentTime} = this.player.current;
      // isPlaying: false makes sure no auto play on getProgress();
      const newState = {...this.state, currentTime, isPlaying: false};
      this.props.post(this.id, newState);
    }
  };

  renderPlayerUI = isWide => {
    const {
      toggleLiked,
      toggleBookmarked,
      seekTo,
      state: {currentTime, isPlaying, liked, bookmarked, progress, timeRemaining}
    } = this;

    return (
      <Card className="audio-container">
        <CardBody>
          <Header
            liked={liked}
            toggleLiked={toggleLiked}
            toggleBookmarked={toggleBookmarked}
            bookmarked={bookmarked}
            isWide={isWide}
            {...this.audioInterview}
          />

          {isWide /* put progress bar on top for large display */ ? (
            <ProgressBar
              progress={progress}
              currentTime={currentTime}
              timeRemaining={timeRemaining}
              seekTo={seekTo}
              player={this.player}
              isWide={isWide}
            />
          ) : null}
          <Controls
            handleClickPauseOrPlay={this.handleClickPauseOrPlay}
            isPlaying={isPlaying}
            seekTo={seekTo}
            player={this.player}
          />
          {isWide ? null : (
            <ProgressBar
              progress={progress}
              currentTime={currentTime}
              timeRemaining={timeRemaining}
              seekTo={seekTo}
              player={this.player}
              isWide={isWide}
            />
          )}

          <React.Fragment>
            {/*<Text isWide={isWide} {...this.audioInterview} />*/}
            <DownloadsList {...this.props} downloadsList={this.downloadsList} />
          </React.Fragment>
        </CardBody>
      </Card>
    );
  };

  render() {
    const {img} = this.bio.data; // img

    return (
      <div className="py-2">
        <audio
          id="audio"
          ref={this.player}
          src={this.src}
          style={{width: '100%', display: this.props.test ? null : 'none'}}
          controls
        >
          <track srcLang="en" src={this.url} />
        </audio>
        {this.props.test ? <div>audio element ^ only displayed in test/storybook environment</div> : null}
        <div className="d-sm-none">{this.renderPlayerUI()}</div>
        <Container className="d-none d-sm-block">
          <Row className="no-gutters p-0">
            <Col className={apmPictureContainer}>
              <div className={`${apmPictureV2}  mb-3`}>
                <img src={img} />
              </div>
              <div className={apmDownloadsListWide}>
                {this.downloadList
                  ? this.downloadsList.Children.map(download => {
                      return (
                        <div style={{cursor: 'pointer', textAlign: 'left', marginBottom: '8px'}}>
                          <a href={download.data.url} download>
                            <div>
                              <FontAwesomeIcon
                                icon={['far', download.data.type === 'MP3' ? 'file-audio' : 'file-pdf']}
                              />
                              <span>&nbsp;{download.data.text}</span>
                            </div>
                          </a>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </Col>
            <Col>{this.renderPlayerUI(true)}</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AudioPlayerMobile.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.number,
  post: PropTypes.func.isRequired,
  save: PropTypes.bool
};

AudioPlayerMobile.defaultProps = {
  id: 88888888,
  save: false
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export default connect(null, mapDispatchToProps)(AudioPlayerMobile);
