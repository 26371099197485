import React from 'react';
import Card from 'components/Card';
import CalendarSaveV2 from 'components/CalendarSaveV2';
import moment from 'moment-timezone';
const TIME_ZONE = process.env.TIMEZONE;

function massageData(originalData) {
  // Extracting the necessary data from the original object
  const {
    date, // this is the start date and time
    time,
    timezone,
    title,
    subtitle, // assuming this remains the same if it's not empty
    imageURL,
    description // not used in the new object based on your structure
  } = originalData;

  const duration = '1:00:00'; // Assumed duration of 1 hour

  // Building the new object
  const newData = {
    displayProps: {
      date: `${date}  ${time}  ${TIME_ZONE}`,
      duration: duration,
      image: {
        url: imageURL // Changed key to 'url' from 'imageURL' and nested it inside 'image'
      },
      originalDate: date, // Keeping the original date used for children calendar events
      subtitle: description || 'Live Broadcast', // Using the original subtitle, or 'Live Broadcast' if it's empty
      title: title, // this remains the same
      description: description // description is not included in the new structure
    }
  };
  return newData;
}
function getNextEvent(events) {
  const now = new Date();
  // Find the next event
  const nextEvent = events
    .map(event => ({...event, parsedDate: new Date(event.date)}))
    .filter(event => event.parsedDate > now)
    .sort((a, b) => a.parsedDate - b.parsedDate)[0];

  return nextEvent;
}
const CommunityLive = ({currentStep, event}) => {
  const nextEvent = getNextEvent(event);
  const cards = nextEvent ? [massageData(nextEvent)] : []; // massageData is a function that takes the original event object and returns the new object with the required structure

  goToLink = ({url, currentStep}) => {
    window.open(url, '_blank');
  };
  return (
    <div className="mt-5">
      {cards && cards.length ? (
        cards.map((card, index) => {
          return <Card {...card.displayProps} />;
        })
      ) : (
        <div className="mx-4">There are no upcoming events. Check the WLC calendar often.</div>
      )}
      <div className="my-5 mx-2 d-flex justify-content-center o-nav-buttons">
        {nextEvent ? <CalendarSaveV2 event={nextEvent} /> : ''}
      </div>
    </div>
  );
};

export default CommunityLive;
