import React from 'react';
export default props => (
  <svg viewBox="0 0 165.521 105.965" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M150.698 0H14.823c-.566 0-1.133 0-1.698.003-.477.004-.953.009-1.43.022-1.039.028-2.087.09-3.113.274a10.51 10.51 0 0 0-2.958.975 9.932 9.932 0 0 0-4.35 4.35 10.463 10.463 0 0 0-.975 2.96C.113 9.611.052 10.658.024 11.696a70.22 70.22 0 0 0-.022 1.43C0 13.69 0 14.256 0 14.823v76.318c0 .567 0 1.132.002 1.699.003.476.009.953.022 1.43.028 1.036.09 2.084.275 3.11a10.46 10.46 0 0 0 .974 2.96 9.897 9.897 0 0 0 1.83 2.52 9.874 9.874 0 0 0 2.52 1.83c.947.483 1.917.79 2.96.977 1.025.183 2.073.245 3.112.273.477.011.953.017 1.43.02.565.004 1.132.004 1.698.004h135.875c.565 0 1.132 0 1.697-.004.476-.002.952-.009 1.431-.02 1.037-.028 2.085-.09 3.113-.273a10.478 10.478 0 0 0 2.958-.977 9.955 9.955 0 0 0 4.35-4.35c.483-.947.789-1.917.974-2.96.186-1.026.246-2.074.274-3.11.013-.477.02-.954.022-1.43.004-.567.004-1.132.004-1.699V14.824c0-.567 0-1.133-.004-1.699a63.067 63.067 0 0 0-.022-1.429c-.028-1.038-.088-2.085-.274-3.112a10.4 10.4 0 0 0-.974-2.96 9.94 9.94 0 0 0-4.35-4.35A10.52 10.52 0 0 0 156.939.3c-1.028-.185-2.076-.246-3.113-.274a71.417 71.417 0 0 0-1.431-.022C151.83 0 151.263 0 150.698 0z"
      fill="#3C4043"
    />
    <path
      d="M150.698 3.532l1.672.003c.452.003.905.008 1.36.02.793.022 1.719.065 2.583.22.75.135 1.38.34 1.984.648a6.392 6.392 0 0 1 2.804 2.807c.306.6.51 1.226.645 1.983.154.854.197 1.783.218 2.58.013.45.019.9.02 1.36.005.557.005 1.113.005 1.671v76.318c0 .558 0 1.114-.004 1.682-.002.45-.008.9-.02 1.35-.022.796-.065 1.725-.221 2.589a6.855 6.855 0 0 1-.645 1.975 6.397 6.397 0 0 1-2.808 2.807c-.6.306-1.228.511-1.971.645-.881.157-1.847.2-2.574.22-.457.01-.912.017-1.379.019-.555.004-1.113.004-1.669.004H14.801c-.55 0-1.1 0-1.66-.004a74.993 74.993 0 0 1-1.35-.018c-.744-.02-1.71-.064-2.584-.22a6.938 6.938 0 0 1-1.986-.65 6.337 6.337 0 0 1-1.622-1.18 6.355 6.355 0 0 1-1.178-1.623 6.935 6.935 0 0 1-.646-1.985c-.156-.863-.2-1.788-.22-2.578a66.088 66.088 0 0 1-.02-1.355l-.003-1.327V14.474l.002-1.325a66.7 66.7 0 0 1 .02-1.357c.022-.792.065-1.717.222-2.587a6.924 6.924 0 0 1 .646-1.981 6.36 6.36 0 0 1 1.18-1.623 6.386 6.386 0 0 1 1.624-1.18 6.96 6.96 0 0 1 1.98-.646c.865-.155 1.792-.198 2.586-.22a68.26 68.26 0 0 1 1.354-.02l1.677-.003h135.875"
      fill="#FFF"
    />
    <path
      d="M94.828 72.767c-.279 1.833-1.68 1.833-3.034 1.833h-.77l.54-3.423a.426.426 0 0 1 .42-.36h.354c.922 0 1.793 0 2.242.526.269.314.35.78.248 1.424zm-.589-4.784h-5.108a.71.71 0 0 0-.701.599L86.364 81.68a.426.426 0 0 0 .42.492h2.622a.497.497 0 0 0 .49-.42l.586-3.713a.71.71 0 0 1 .701-.599H92.8c3.364 0 5.306-1.628 5.813-4.855.229-1.412.01-2.52-.651-3.297-.727-.854-2.014-1.305-3.723-1.305"
      fill="#459adb"
    />
    <path
      d="M58.396 72.767c-.28 1.833-1.68 1.833-3.034 1.833h-.771l.54-3.423a.426.426 0 0 1 .421-.36h.353c.922 0 1.793 0 2.242.526.269.314.35.78.249 1.424zm-.59-4.784h-5.108a.71.71 0 0 0-.701.599L49.931 81.68a.426.426 0 0 0 .421.492h2.439a.71.71 0 0 0 .701-.6l.557-3.533a.71.71 0 0 1 .701-.599h1.617c3.364 0 5.306-1.628 5.814-4.855.228-1.412.009-2.521-.652-3.297-.727-.854-2.014-1.305-3.723-1.305M69.665 77.469c-.237 1.397-1.346 2.336-2.761 2.336-.71 0-1.277-.229-1.643-.66-.361-.43-.498-1.04-.383-1.719.22-1.385 1.348-2.353 2.741-2.353.695 0 1.259.23 1.631.666.375.44.522 1.054.415 1.73zm3.408-4.761h-2.445a.426.426 0 0 0-.421.36l-.108.683-.17-.247c-.53-.77-1.71-1.026-2.89-1.026-2.702 0-5.01 2.048-5.46 4.92-.233 1.433.099 2.802.911 3.758.747.877 1.812 1.243 3.08 1.243 2.179 0 3.387-1.4 3.387-1.4l-.11.68a.426.426 0 0 0 .421.493h2.203a.71.71 0 0 0 .7-.599l1.323-8.373a.426.426 0 0 0-.42-.492"
      fill="#253b7d"
    />
    <path
      d="M106.097 77.469c-.236 1.397-1.345 2.336-2.76 2.336-.71 0-1.278-.229-1.643-.66-.362-.43-.498-1.04-.383-1.719.22-1.385 1.347-2.353 2.741-2.353.695 0 1.258.23 1.63.666.375.44.523 1.054.415 1.73zm3.41-4.761h-2.447a.426.426 0 0 0-.42.36l-.108.683-.17-.247c-.53-.77-1.711-1.026-2.89-1.026-2.702 0-5.01 2.048-5.46 4.92-.234 1.433.098 2.802.91 3.758.747.877 1.812 1.243 3.081 1.243 2.178 0 3.386-1.4 3.386-1.4l-.109.68a.426.426 0 0 0 .42.493h2.204a.71.71 0 0 0 .7-.599l1.323-8.373a.426.426 0 0 0-.42-.492"
      fill="#459adb"
    />
    <path
      d="M86.1 72.708h-2.458a.71.71 0 0 0-.587.311l-3.391 4.995-1.437-4.8a.71.71 0 0 0-.68-.506H75.13a.426.426 0 0 0-.403.563l2.707 7.946-2.546 3.593c-.2.282.002.672.347.672h2.456a.71.71 0 0 0 .584-.305l8.176-11.8a.426.426 0 0 0-.35-.669"
      fill="#253b7d"
    />
    <path
      d="M112.39 68.343l-2.096 13.337c-.04.258.159.492.42.492h2.109a.71.71 0 0 0 .7-.6l2.068-13.097a.426.426 0 0 0-.42-.492h-2.36a.426.426 0 0 0-.42.36M98.687 31.25c.517-3.298-.004-5.54-1.787-7.573-1.963-2.237-5.51-3.195-10.048-3.195H73.679c-.927 0-1.717.674-1.861 1.59l-5.485 34.783a1.13 1.13 0 0 0 1.116 1.306h8.132l-.56 3.56a.99.99 0 0 0 .976 1.143h6.855c.811 0 1.502-.59 1.628-1.392l.067-.348 1.292-8.188.083-.453a1.648 1.648 0 0 1 1.628-1.391h1.026c6.64 0 11.84-2.698 13.36-10.5.634-3.26.306-5.982-1.373-7.895-.508-.579-1.14-1.057-1.876-1.448"
      fill="#459adb"
    />
    <path
      d="M98.687 31.25c.517-3.298-.004-5.54-1.787-7.573-1.963-2.237-5.51-3.195-10.048-3.195H73.679c-.927 0-1.717.674-1.861 1.59l-5.485 34.783a1.13 1.13 0 0 0 1.116 1.306h8.132l2.043-12.953-.063.406a1.878 1.878 0 0 1 1.855-1.59h3.865c7.59 0 13.534-3.084 15.27-12.003.052-.264.096-.52.136-.772"
      fill="#232d62"
    />
    <path
      d="M79.82 31.294a1.648 1.648 0 0 1 1.628-1.391h10.326c1.223 0 2.363.08 3.406.248.298.048.588.103.87.165a12.795 12.795 0 0 1 1.21.332c.512.17.989.37 1.428.603.517-3.297-.004-5.54-1.787-7.572-1.963-2.238-5.51-3.196-10.048-3.196H73.68c-.927 0-1.717.675-1.861 1.591l-5.485 34.782a1.13 1.13 0 0 0 1.116 1.307h8.132l2.043-12.953 2.195-13.916"
      fill="#253b7d"
    />
  </svg>
);
