import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from 'reactstrap';
import CardImgBg from 'components/CardImgBg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBookmark} from '@fortawesome/pro-regular-svg-icons/faBookmark';
import {faHeart} from '@fortawesome/pro-regular-svg-icons/faHeart';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import {faBookmark as faBookmarkSolid} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {actions} from 'modules/user';
import Markdown from '../Markdown';
import classNames from 'classnames';
const DEFAULT_CONTENT_IMG = process.env.DEFAULT_CONTENT_IMG; //this is ugly but it is going to be ugly in the backend or frontend
class ContentCard extends PureComponent {
  constructor(props) {
    super(props);
    const {id} = props;
    this.id = id;
    this.state = {liked: false, bookmarked: false, type: this.props.type};
    this.downloadAnchor = React.createRef();
  }

  componentDidMount() {
    if (!this.props.test) {
      this.getState();
    }
  }

  async getState() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({...this.state, ...savedData});
    } else {
      // No saved values - do something?
    }
  }

  postState = newState => {
    this.props.post(this.id, newState);
  };

  toggleLiked = () => {
    const newState = {...this.state, liked: !this.state.liked};
    this.setState(newState);
    this.postState(newState);
  };

  toggleBookmarked = () => {
    const newState = {...this.state, bookmarked: !this.state.bookmarked};
    this.setState(newState);
    this.postState(newState);
  };

  navigateToContent = e => {
    e.preventDefault();
    const path = location.pathname.split('/');
    const thisProductInPath = path[2];
    if (['download', 'link'].includes(this.state.type)) {
      this.downloadAnchor.current.click();
    } else {
      this.props.history.push(`/products/${thisProductInPath}/${this.props.slug}`);
    }
  };

  render() {
    const {
      displayProps,
      title,
      type,
      data: {text, description, thumb, overrideStyles},
      tags,
      className
    } = this.props;
    let imageFound = displayProps.image.url;
    if (!imageFound) imageFound = DEFAULT_CONTENT_IMG;
    const {liked, bookmarked} = this.state;
    const classes = classNames(className, 'card-content');
    return (
      <Card styles={overrideStyles} className={classes}>
        <div className={this.props.horizontal ? 'card-horizontal' : ''}>
          {imageFound ? (
            <div
              className={`card-img-bg-${this.props.horizontal ? 'left' : 'top'}`}
              style={{backgroundImage: `url("${imageFound}")`}}
              onClick={this.navigateToContent}
            />
          ) : (
            ''
          )}
          <CardBody className="card-content-body">
            {['download', 'link'].includes(type) ? (
              <CardTitle>
                <a href={this.props.data.url} ref={this.downloadAnchor} target="_blank">
                  {title}
                </a>
              </CardTitle>
            ) : (
              <CardTitle onClick={this.navigateToContent}>{title}</CardTitle>
            )}
            {this.props.showDescription && this.props.data.description ? (
              <Markdown source={this.props.data.description} />
            ) : null}
            {this.props.showButton ? (
              <Button onClick={this.navigateToContent}>{type === 'download' ? 'Download' : 'Learn More'}</Button>
            ) : null}
          </CardBody>
        </div>
      </Card>
    );
  }
}

ContentCard.propTypes = {
  title: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

const ConnectedContentCard = connect(null, mapDispatchToProps)(ContentCard);

export default withRouter(ConnectedContentCard);
