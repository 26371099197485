//for Hooks only
// import {useMediaQuery} from 'react-responsive';
// function getResponsive() {
//   const isMobile = useMediaQuery({maxWidth: 767});
//   const isTablet = useMediaQuery({minWidth: 768, maxWidth: 991});
//   const isDesktop = useMediaQuery({minWidth: 992});
//   return {isMobile, isTablet, isDesktop};
// }

const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200
};

export const checkBreakpoint = () => {
  const width = window.innerWidth;
  if (width < breakpoints.small) {
    // do something for small screens
    return 'mobile'; //treating them as mobile for now
  } else if (width < breakpoints.medium) {
    return 'mobile';
    // do something for medium screens
  } else if (width < breakpoints.large) {
    return 'tablet';
    // do something for large screens
  } else if (width < breakpoints.xlarge) {
    return 'desktop';
    // do something for extra large screens
  } else {
    // do something
    return 'desktop'; //for screens larger than extra large
  }
};

window.addEventListener('resize', checkBreakpoint);
