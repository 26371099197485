import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row} from 'reactstrap';
import RecipeGrid from 'routes/Products/components/RecipesGrid';
import BreadCrumbs from '../BreadCrumbs';
import BackNav from 'routes/Products/components/BackNav';
import epStyles from 'routes/Products/products/EmpowermentPackageV2/styles.js';
const WrapperRecipes = props => {
  return (
    <div>
      <Container>
        <div style={epStyles.backNavWrapperStyle}>
          <BackNav />
        </div>
        {/* <BreadCrumbs>
          <Link to={`/products/${props.productPath}`} className="pr-1">
            {props.product.title}
          </Link>{' '}
          / Recipes
        </BreadCrumbs> */}
        <div className="row">
          <div className="col-12">
            <h3>Recipe Collection</h3>
          </div>
        </div>
      </Container>
      <Row>
        <RecipeGrid
          singleRecipeInUrl={null}
          disableRouter={false}
          disableViewAll={true}
          colSizes={{xs: 6, sm: 4, md: 4}}
          {...props}
        />
      </Row>
      <div style={{width: '100%  ', height: 500}}></div>
    </div>
  );
};

export default WrapperRecipes;
