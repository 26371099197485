import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Container} from 'reactstrap';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {STATUS} from 'react-joyride';
import getContent from 'lib/getContent';
import Onboarding from '../../components/Onboarding';
import moment from 'moment-timezone';
import {calendarImageMap} from '../Calendar/data.js';
const TIME_ZONE = process.env.TIMEZONE;
@connect(state => ({authToken: state.user.authToken, user: state.user}), {
  refreshToken: actions.refreshToken,
  getUserData: actions.getUserData,
  post: actions.postUserData
})
class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      canShowOnbaord: false,
      runTour: true
    };

    const path = location.pathname.split('/');
    this.ProductInPath = path[2];

    this.path = location.pathname.split('/');
    this.productSlug = this.path[2];
    this.getCurrentWeek = this.getCurrentWeek.bind(this);
    this.getCurrentActionHour = this.getCurrentActionHour.bind(this);
    this.getAddEventCalender = this.getAddEventCalender.bind(this);
    this.getOnboard = this.getOnboard.bind(this);

    this.tourSteps = [
      {
        target: 'body',
        placement: 'center',
        title: 'Welcome to your WLC dashboard',
        content: 'Welcome to the WLC dashboard.  This is your home base when navigating through the Club.'
      },
      {
        target: '.orientation-status',
        placement: 'auto',
        title: 'Orientation Progress',
        content:
          'This is your progress towards your WLC orientation. Click on it to finish the next item on your list. '
      },
      {
        target: '.productSideBarContainer',
        placement: 'auto',
        title: 'Navigation Bar',
        content: 'Use this section to move around the WLC community. '
      },
      {
        target: '.headerUserInfoV2ProductContainer',
        placement: 'auto',
        title: 'My Products',
        content: 'Click here to see all your Food Revolution Network products.'
      }
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //get WeeksList, depth=2, this is just getting the latest week //todo should we have a query param for this instead?
    this.getCurrentWeek();
    this.getCurrentActionHour();
    this.getAddEventCalender();
    this.getOnboard();
    /*
    this.userAnalytics = {
      service: 'dashboard', //the kind of service, like recipe, weekly guide, etcs
      slug: props.slug ? props.slug : '',
      title: props.pageTitle ? props.pageTitle : 'dashboard',
      uuid: props.user && props.user.UUID
    };
    page('dashboard', userAnalytics);*/
  }
  //JOYRIDE Stuff
  handleJoyrideCallback = data => {
    const {status, type} = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({runTour: false});
      this.props.updateTourStatus('dashboard', true);
    }
  };

  //get the current weekly guide by getting the weekslist depth=2 and return the last one
  getCurrentWeek() {
    const {weeksListSlug} = this.props;

    getContent(weeksListSlug, this.productSlug, this.props.authToken, '?depth=2')
      .then(res => {
        const allWeeksList = res.Children; //for clarity
        this.setState({currentWeek: allWeeksList[allWeeksList.length - 1]}); //api is 1 week ahead, so -2
      })
      .catch(e => {
        console.log('error getting weeklist list');
      });
  }
  //action-hours
  getCurrentActionHour() {
    const {actionHoursId} = this.props;
    getContent(actionHoursId, this.productSlug, this.props.authToken, '?depth=2')
      .then(res => {
        const allActionHours = res.Children; //for clarity
        this.setState({currentActionHour: allActionHours[0]});
      })
      .catch(e => {
        console.log('error getting Actions Hours list');
      });
  }
  //events

  getAddEventCalender() {
    //getting calendar from lambda
    const options = {
      method: 'GET'
    };
    //hit lambda function to get calendar
    fetch(process.env.ADDEVENT_ENDPOINT, options)
      .then(response => response.json())
      .then(response => {
        if (!response || !response.events || !response.events.length || !response.events[0].datetime_start) {
          this.setState({events: []});
          return; //ensure one event is returned
        }
        const originalArray = response.events;

        const convertToNewFormat = original => {
          const date = moment.tz(original.datetime_start, TIME_ZONE); //mooment is needed to bypass the timezone issue caused by browser using system time

          // Specify the options for formatting the date and time
          const options = {
            timeZone: TIME_ZONE, // Set this to 'America/Los_Angeles' explicitly
            hour12: true
          };
          return {
            originalDate: date,
            id: original.id,
            day: new Intl.DateTimeFormat('en-US', {...options, day: 'numeric'}).format(date),
            date: new Intl.DateTimeFormat('en-US', {
              ...options,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(date),
            weekday: new Intl.DateTimeFormat('en-US', {...options, weekday: 'long'}).format(date),
            month: new Intl.DateTimeFormat('en-US', {...options, month: 'long'}).format(date),
            time: new Intl.DateTimeFormat('en-US', {...options, hour: 'numeric', minute: '2-digit'}).format(date),
            timezone: 'PT', // You can leave this as 'PT' if you're consistently working with Pacific Time
            title: original.title,
            subtitle: '',
            imageURL: calendarImageMap[original.title]
              ? calendarImageMap[original.title].img
              : calendarImageMap['default'].img,
            description: calendarImageMap[original.title]
              ? calendarImageMap[original.title].description
              : calendarImageMap['default'].description,
            location: original.location
          };
        };

        this.setState({events: originalArray.map(convertToNewFormat)});
      })
      .catch(err => console.error(err));
  }
  getOnboard() {
    const {onBoardContentId} = this.props;

    getContent(onBoardContentId, this.productSlug, this.props.authToken)
      .then(res => {
        //need onboard video
        //onboarding checklist
        const checklistOnboard = res.Children.find(item => item.type === 'checklist');
        const onboardVideo = res.Children.find(item => item.type === 'video');
        this.setState({checklistOnboard, onboardVideo});

        this.getChecklistResponses(checklistOnboard.id);
      })
      .catch(e => {
        console.log('error getting onboard list', e.message);
      });
  }

  //Getting USER DATA for the checklist, this.id = id for checklist
  //This is used to determine the progress of the onboard.  we get the checklist userdata and determin how many items have been done
  async getChecklistResponses(onboardID) {
    const savedData = await this.props.getUserData(onboardID);

    if (savedData && savedData.start && savedData.start.values) {
      this.calculatePercentageCompleted(savedData.start.values);
    }
  }

  //received an OBJECT of items from saved state on mount
  calculatePercentageCompleted = items => {
    const totalItems = Object.keys(items).length;
    let finished = 0;
    for (let property in items) {
      items[property] ? finished++ : null;
    }

    this.setState({progress: Math.round((finished / totalItems) * 100)});
  };

  handleNav = slug => {
    if (slug) this.props.history.push(`/products/${this.productSlug}/${slug}`);
  };

  render() {
    const {currentActionHour, currentWeek, onboardVideo} = this.state;
    const {toursCompleted, toursLoaded} = this.props;
    const showTour = !!toursLoaded && !toursCompleted.dashboard;

    return (
      <div>
        <Container className="py-5">
          <div className="pagetitle">
            <h1>WHOLE Life Club</h1>
          </div>

          <div>
            <Onboarding
              events={this.state.events}
              {...this.props}
              handleNav={this.handleNav}
              currentActionHour={currentActionHour}
              currentWeek={currentWeek}
            />
          </div>
        </Container>
      </div>
    );
  }
}

Dashboard.propTypes = {
  weeksList: PropTypes.shape({}).isRequired,
  actionHour: PropTypes.shape({}).isRequired
};

export default withRouter(Dashboard);
