/* eslint-disable */
// import createSchema from '../../../../lib/createSchema';
import recipeOne from '../Recipe/testData';

export const CollectionOne = {
  type: 'collection',
  name: 'Recipe Preparation and Basic Cooking Techniques',
  slug: 'collection-one',
  title: 'Collection Title',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Michael Gregor Collection',
      text: 'Michael Gregor Collection'
    },
    description:
      'We’ve assembled a potent collection of a collection so you never have to miss another Michael McGregor moment ever again in your life, ever.'
  },
  Children: [
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger One',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Michael Gregor straightens it out. Collection List Item Text.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger Two',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Michael Gregor on point. Collection List Item Text.'
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger Three',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Michael Gregor speaks the truth. Collection List Item Text.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger Four',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Michael Gregor spells it out. Collection List Item Text.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger Five',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Michael Gregor lays it flat. Collection List Item Text.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    }
  ]
};

export const CollectionTwo = {
  type: 'collection',
  name: 'Another Collection',
  slug: 'collection-two/',
  title: 'Collection Two Title',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Recipe Collection',
      text: 'Recipe Collection',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    },
    description:
      'We’ve assembled a potent collection of educational, instructional and inspirational videos that can get you informed, empowered, and salivating with yummy ideas for your healthy kitchen.'
  },
  Children: [
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger on the health benefits of home cooked meals',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Collection Item.'
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger on the health benefits of home cooked meals',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Collection Item.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger on the health benefits of home cooked meals',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Collection Item.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger on the health benefits of home cooked meals',
      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Collection Item.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    },
    {
      type: 'video',
      slug: 'video-recipe-prep-one',
      name: 'Video 42',
      title: 'Dr. Michael Greger on the health benefits of home cooked meals',

      data: {
        thumb: {
          url: 'http://via.placeholder.com/350x150',
          alt: 'Michael Gregor',
          text: 'Collection Item.',
          contentCardDescription:
            'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
        },
        alt: 'Dr. Michael Greger on the health benefits of home cooked meals',
        url: 'https://www.youtube.com/embed/8O-dYFt5rWw?ecver=1'
      }
    }
  ]
};

// export const RecipesCollection = {
//   type: 'collection',
//   name: 'Another Collection',
//   slug: 'recipe-collection/',
//   title: 'This is a collection of recipes',
//   data: {
//     thumb: {
//       url: 'http://via.placeholder.com/350x150',
//       alt: 'Recipe Collection',
//       text: 'Recipe Collection'
//     },
//     description: 'We’ve assembled a recipes collection, instructional and inspirational videos that can get you informed, empowered, and salivating with yummy ideas for your healthy kitchen.'
//   },

//   Children: [
//     recipeOne,
//     recipeOne,
//     recipeOne
//   ]
// };

export default CollectionOne;
