import React from 'react';
import Checkbox from '../../../../components/Checkbox';
import LessonsHeader from '../LessonsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';

//Shows the lesson titles and completed state (as non-fucntional checkbox) within the CardBody of the ModuleWrapper
/*i.e.
Lessons              3/3 lessons
1: Set Yourself Up for Success
2: Food: An Essential Survival Skill
3: Transitioning Wisdom

*/
function LessonsWrapper(props) {
  const { module, moduleState, handleNav, isUnstructured } = props;
  console.log('LessonsWrapper', isUnstructured);
  let today = Date.now()
  return (
    <div>
      {isUnstructured ? null : <LessonsHeader moduleState={moduleState} />}
      {module.Children.filter(item => item.type === 'lesson').map((item, i) => {
        const data = item && item.data ? item.data : null;
        const premieresAt = data && data.premieresAt ? data.premieresAt : 1; //if it doesn't exist assume it premiered in 1970
        const lessonLocked = today && premieresAt && parseInt(premieresAt) > parseInt(today);
        return (
          <div className={`d-flex align-items-center py-3${!lessonLocked ? " pointerGlobal" : ""}`} onClick={!lessonLocked ? () => handleNav(module.slug) : () => { }}>
            <div className="pr-3 float-left">{item.title}</div>
            {isUnstructured ? null : (

              <div className=" ml-auto text-right">
                {!lessonLocked ?
                  <Checkbox
                    checked={
                      moduleState
                        ? !!moduleState.lessons.find(lessonState => {
                          return lessonState.id === item.id && lessonState.completed;
                        })
                        : false
                    }
                  />
                  : <FontAwesomeIcon icon={faLock} />}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default LessonsWrapper;
