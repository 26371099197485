import React from 'react';
import {ListGroup, ListGroupItem} from 'reactstrap';
import Icon from '../Icon';
import {Link} from 'react-router-dom';
import {track} from '../../lib/track';
const MAX_TEXT_LENGTH = 20;
export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.getText = this.getText.bind(this);
    this.getDownloadText = this.getDownloadText.bind(this);
    this.truncateText = this.truncateText.bind(this);
    this.trackThis = this.trackThis.bind(this);
  }
  getDownloadText(item) {
    if (item.data && item.data.text) {
      return item.data.text; // Add an ellipsis to indicate that the text has been truncated
    } else return 'download';
  }
  truncateText(text) {
    if (text.length > MAX_TEXT_LENGTH) {
      return text.slice(0, MAX_TEXT_LENGTH) + '...'; // Add an ellipsis to indicate that the text has been truncated
    } else {
      return text;
    }
  }
  getText(item) {
    const type = item.type ? item.type : 'unknown';

    switch (type) {
      //TODO are there other list items that don't use item.title for text?
      case 'download':
        return this.getDownloadText(item);
      default:
        return item.title
    }
  }
  trackThis({contentItem}) {
    if (this.props.userAnalytics) {
      track('link_click', {
        ...this.props.userAnalytics,
        link_type: `download-${contentItem.data.type ? contentItem.data.type : 'generic'}`
      });
    } else {
      track('link_click', {link_type: `download-${contentItem.data.type ? contentItem.data.type : 'generic'}`});
    }
  }
  render() {
    const {color, contentItems} = this.props; //array

    return (
      <ListGroup flush className={color ? `list-group-${color}` : ''}>
        {contentItems
          ? contentItems.map(item => {
              const hasLink = item.data && item.data.url;

              return hasLink ? (
                <ListGroupItem
                  onClick={() => {
                    this.trackThis({contentItem: item});
                  }}
                  key={item.title}
                  tag="a"
                  href={item.data.url}
                  cssModule={{backgroundColor: 'transparent'}}
                >
                  <Icon contentItem={item} />
                  <div className="listSpace"></div>
                  <span className="listText">{this.getText(item)}</span>
                </ListGroupItem>
              ) : (
                <ListGroupItem
                  key={item.title}
                  onClick={() => {
                    this.trackThis({contentItem: item});
                  }}
                >
                  <Icon contentItem={item} />
                  <span className="listText">{this.getText(item)}</span>
                </ListGroupItem>
              );
            })
          : null}
      </ListGroup>
    );
  }
}
