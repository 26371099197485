import React from 'react';

//Display text and module completed
//i.e.  "Coures Modules                      2/7 Completed"
function ModulesHeader(props) {
  const {moduleStates, calculateModuleProgress, moduleHeaderTitle, isUnstructured} = props;
  const progress = moduleStates ? calculateModuleProgress(moduleStates) : null; //find users module progress

  return (
    <div className="d-flex align-items-baseline  h-100 justify-content-between  moduleHeader ">
      <div>{moduleHeaderTitle ? moduleHeaderTitle : 'Course Modules'}</div>
      {isUnstructured ? null : (
        <div>{moduleStates ? `${progress.totalModulesCompleted}/${moduleStates.length}` : 0} completed</div>
      )}
    </div>
  );
}

export default ModulesHeader;
