import React from 'react';
import {Breadcrumb, Container} from 'reactstrap';
function BreadCrumbs(props) {
  return (
    <Breadcrumb tag="nav" listTag="div" className="frnBreadCrumbs">
      {props.children}
    </Breadcrumb>
  );
}

export default BreadCrumbs;
