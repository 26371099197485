/* eslint-disable max-len */

export const recipeOne = {
  name: 'Recipe - Blueberry Banana Oatmeal',
  title: 'Blueberry Banana Oatmeal',
  slug: 'blueberry-banana-oatmeal',
  type: 'recipe',
  data: {
    image: {
      alt: 'Blueberry Banana Oatmeal',
      url: 'https://cdn.foodrevolution.org/members/assets/recipes/blueberry-banana-oatmeal.jpg'
    },
    thumb: {
      alt: 'Blueberry Banana Oatmeal',
      url: 'https://cdn.foodrevolution.org/members/assets/recipes/blueberry-banana-oatmeal.jpg'
    },
    cookTime: '5',
    prepTime: '5',
    totalTime: '10',
    directions: [
      {
        directionsList: [
          'In a small saucepan over medium heat add the non-dairy milk, quick oats, chia seeds, and cinnamon; stir until thoroughly combined.',
          'Add in the sliced banana and blueberries and stir.',
          'Allow mixture to simmer over medium heat for 3-5 minutes, or until mixture has thickened to your liking. ',
          'Remove from heat and transfer into two bowls.  Enjoy!'
        ]
      }
    ],
    attribution: {
      url: 'https://www.fitmittenkitchen.com/favorite-blueberry-banana-oatmeal-vegan-gluten-free/',
      name: 'Fit Mitten Kitchen',
      text: 'Recipe adapted from'
    },
    description:
      'Packed with fiber and plant-based protein, this warm bowl of whole grain oatmeal is a great way to start your morning. Fresh fruit adds pops of sweetness, but if you’re craving more, add in a teaspoon of maple syrup or honey until you get used to less sugar.',
    recipeNotes: [
      {
        text:
          'Oats are naturally gluten-free but they are often processed in facilities near wheat.  If gluten-free is a concern, be sure to get “Certified Gluten-Free” oats.',
        title: 'Gluten-Free?'
      },
      {
        text:
          'The sky’s the limit on the different fruits you can use in this recipe. Let the season help offer some different variations. In the fall and winter, diced apples and pears add delicious texture. During the spring and summer, fresh blackberries and sliced peaches add a sweet flavor.',
        title: 'Make it Seasonal'
      }
    ],
    servingSize: '1½ cups of cooked oatmeal',
    yieldString: '2'
  },
  Children: [
    {
      name: 'Blueberry Banana Oatmeal - unsweetened non-dairy milk',
      title: 'Blueberry Banana Oatmeal - unsweetened non-dairy milk',
      slug: 'blueberry-banana-oatmeal-ingredient-unsweetened-non-dairy-milk',
      type: 'recipeIngredient',
      data: {
        note: 'like almond or soy',
        units: 'cup',
        measurement: '1 1/3'
      },
      ContentTags: [],
      Children: [
        {
          name: 'unsweetened non-dairy milk',
          title: 'unsweetened non-dairy milk',
          slug: 'ingredient-unsweetened-non-dairy-milk',
          type: 'ingredient'
        }
      ]
    },
    {
      name: 'Blueberry Banana Oatmeal - quick oats',
      title: 'Blueberry Banana Oatmeal - quick oats',
      slug: 'blueberry-banana-oatmeal-ingredient-quick-oats',
      type: 'recipeIngredient',
      data: {
        units: 'cup',
        measurement: '2/3'
      },
      ContentTags: [],
      Children: [
        {
          name: 'quick oats',
          title: 'quick oats',
          slug: 'ingredient-quick-oats',
          type: 'ingredient'
        }
      ]
    },
    {
      name: 'Blueberry Banana Oatmeal - chia seeds',
      title: 'Blueberry Banana Oatmeal - chia seeds',
      slug: 'blueberry-banana-oatmeal-ingredient-chia-seeds',
      type: 'recipeIngredient',
      data: {
        units: 'tablespoon',
        measurement: '1'
      },
      ContentTags: [],
      Children: [
        {
          name: 'chia seeds',
          title: 'chia seeds',
          slug: 'ingredient-chia-seeds',
          type: 'ingredient'
        }
      ]
    },
    {
      name: 'Blueberry Banana Oatmeal - ground cinnamon',
      title: 'Blueberry Banana Oatmeal - ground cinnamon',
      slug: 'blueberry-banana-oatmeal-ingredient-ground-cinnamon',
      type: 'recipeIngredient',
      data: {
        units: 'tablespoon',
        measurement: '1/4'
      },
      ContentTags: [],
      Children: [
        {
          name: 'ground cinnamon',
          title: 'ground cinnamon',
          slug: 'ingredient-ground-cinnamon',
          type: 'ingredient'
        }
      ]
    },
    {
      name: 'Blueberry Banana Oatmeal - banana',
      title: 'Blueberry Banana Oatmeal - banana',
      slug: 'blueberry-banana-oatmeal-ingredient-banana',
      type: 'recipeIngredient',
      data: {
        note: 'sliced ½-inch thick',
        units: 'small',
        singular: true,
        measurement: '1'
      },
      Children: [
        {
          name: 'banana',
          title: 'banana',
          slug: 'ingredient-banana',
          type: 'ingredient'
        }
      ]
    },
    {
      name: 'Blueberry Banana Oatmeal - blueberries',
      title: 'Blueberry Banana Oatmeal - blueberries',
      slug: 'blueberry-banana-oatmeal-ingredient-blueberries',
      type: 'recipeIngredient',
      data: {
        units: 'cup',
        measurement: '1/2'
      },
      Children: [
        {
          name: 'blueberries',
          title: 'blueberries',
          slug: 'ingredient-blueberries',
          type: 'ingredient'
        }
      ]
    }
  ]
};

export default recipeOne;
