import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../container';
import Upsell from 'routes/Checkout/components/Upsell';

const UpsellModal = props => {
  const {showModal, hideModal} = props;
  const title = 'Limited Time Offer';

  return (
    <ModalContainer {...props} shtitle={title}>
      <Upsell />
    </ModalContainer>
  );
};

export default UpsellModal;
