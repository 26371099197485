import React, {Component} from 'react';

import {BreadcrumbItem, Container, Row, Col} from 'reactstrap';

import Bonuses from '../../../../components/Bonuses';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

const BonusesPage = props => {
  const {bonuses, product} = props;
  const productInPath = location.pathname.split('/')[2];
  return (
    <div>
      {/* <BackNav /> */}
      <Container>
        <BreadCrumbs>
          <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
            {product.title}
          </BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            Bonuses
          </BreadcrumbItem>
        </BreadCrumbs>
      </Container>
      {bonuses ? <Bonuses {...bonuses} /> : null}
    </div>
  );
};

export default BonusesPage;
