import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Recipe from 'routes/Products/components/Recipe';
import {Col, Row} from 'reactstrap';
import {ForumWrapper} from 'components/ForumWrapper';
import {actions} from 'modules/user';

@connect(state => ({user: state.user}), {
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey,
  refreshToken: actions.refreshToken
})
class RecipePage extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollToContent = this.scrollToContent.bind(this);
    this.commentsWrapper = React.createRef();
    this.highlightedComment = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  scrollToContent() {
    setTimeout(() => {
      //const offSet = this.commentsWrapper.current.offsetTop - 100;
      const offSet =
        this.highlightedComment.current && this.highlightedComment.current.scrollIntoView(false, {behavior: 'smooth'});
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  }

  render() {
    return (
      <div>
        <Recipe {...this.props} />

        <div className="container" ref={this.commentsWrapper} style={{marginBottom: '64px'}}>
          <Row>
            <Col>
              <ForumWrapper
                service={'recipe'}
                scrollToMe={this.scrollToContent}
                pageTitle={this.props.title}
                passedReactRef={this.highlightedComment}
                className="recipeComments"
                user={this.props.user}
                product="wlc"
                slug={this.props.slug}
                revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                refreshToken={this.props.refreshToken}
                forumType="ContentItem"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

RecipePage.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default RecipePage;
