import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, Label} from 'reactstrap';

class Text extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {pristine: true, value: props.data ? props.data.placeholder : ''};
  }
  handleInput = ({target: {value}}) => {
    console.log('val ');
    this.setState({...this.state, value});
    this.props.handleChange(value);
  };

  handleBlur = () => {
    // console.log('todo: send data for question id/name: ', this.props.id, '/ ', this.props.name);
    // console.log('todo: send value: ', this.state.value);
  };
  componentDidUpdate(prevProps) {
    if (prevProps.clearSearch !== this.props.clearSearch && this.props.clearSearch) {
      this.setState({value: ''});
      this.props.resetClearSearch();
    }
  }

  render() {
    const {id, name, data: {text} = {}, placeholder} = this.props;
    return (
      <FormGroup>
        {text ? <Label for={name}>{text}</Label> : null}
        <Input
          onChange={this.handleInput}
          onBlur={this.handleBlur}
          type="text"
          name={name}
          id={name}
          value={this.state.value}
          placeholder={placeholder ? placeholder : ''}
        />
      </FormGroup>
    );
  }
}

Text.propTypes = {
  // TODO
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired
};

export default Text;
