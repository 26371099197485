import React from 'react';
import pure from 'recompose/pure';

let PayPalBug = props => (
  <svg
    {...props}
    viewBox="0 0 24 32"
    preserveAspectRatio="xMinYMin meet"
    dangerouslySetInnerHTML={{
      __html:
        '<path fill="#009cde" d="M20.905 9.5c.28-2.1 0-3.5-1.123-4.8-1.218-1.4-3.371-2.1-6.085-2.1H5.739c-.468 0-1.029.5-1.124 1L1.339 25.8c0 .4.281.9.749.9h4.868l-.281 2.2c-.094.4.187.7.561.7h4.12c.469 0 .936-.3 1.03-.8v-.3l.842-5.2v-.2c.094-.5.562-.9 1.03-.9h.563c4.024 0 7.114-1.7 8.05-6.7.468-2.1.282-3.8-.842-5-.281-.4-.75-.7-1.124-1"/><path fill="#012169" d="M20.905 9.5c.28-2.1 0-3.5-1.123-4.8-1.218-1.4-3.371-2.1-6.085-2.1H5.739c-.468 0-1.029.5-1.124 1L1.339 25.8c0 .4.281.9.749.9h4.868l1.311-8.3-.094.3c.094-.6.562-1 1.123-1h2.34c4.588 0 8.146-2 9.269-7.6-.093-.3 0-.4 0-.6"/><path fill="#003087" d="M9.485 9.5c.092-.3.28-.6.561-.8.186 0 .28-.1.467-.1h6.179c.75 0 1.497.1 2.061.2.186 0 .374 0 .561.1.187.1.374.1.468.2h.281c.28.1.561.3.842.4.28-2.1 0-3.5-1.123-4.9-1.124-1.4-3.276-2-5.992-2H5.739c-.468 0-1.029.4-1.124 1L1.339 25.8c0 .4.281.9.749.9h4.868l1.311-8.3 1.218-8.9z"/>'
    }}
  />
);

PayPalBug = pure(PayPalBug);

export default PayPalBug;
