import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import Video from '../Video';
import './HeaderSales.scss';

export default function HeaderSales(props) {
  return (
    <div>
      <div className="background-black triangle-bottom triangle-bottom-black text-white text-center wrapper">
        <div className="offset-header-during">
          <Container>
            <Row className="d-flex justify-content-center mb-3">
              <Col xs="12" sm="10" md="9" lg="12">
                <h2 className="text-condensed text-800 mb-0">
                  Thank you for choosing to own <em>The Need To GROW</em>!
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-3">
              <Col xs="12" sm="11" md="12" lg="10" xl="8">
                <h5 className="text-400 mb-0">
                  Now watch this short video to see how you can take the next step for an unbeatable price you’ll never
                  see again.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="video-box offset-video-box-during">
        <Container>
          <Row>
            <Col className="px-0 px-sm-3">
              <Video url="https://youtu.be/aQIevFIgzNo" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
