import React from 'react';

function ProgressTracker({currentStep, goToStep, totalSteps}) {
  const frnMediumBlueGray = getComputedStyle(document.documentElement)
    .getPropertyValue('--frn-medium-blue-gray')
    .trim();

  return (
    <div style={{width: '100%', textAlign: 'center', color: frnMediumBlueGray}}>
      {/* Progress: {currentStep} of {totalSteps} */}
      <div className="text-primary"> Progress</div>
      <div
        style={{display: 'flex', justifyContent: 'center', gap: '2px', width: '100%', textAlign: 'center'}}
        className="progress-image"
      >
        {Array.from({length: totalSteps}).map((_, index) => (
          <img
            key={index}
            src="https://cdn.foodrevolution.org/wlc/social/carrot.png"
            alt={`Step ${index + 1}`}
            style={{opacity: currentStep >= index + 1 ? 1 : 0.2}}
            onClick={() => goToStep(index + 1)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProgressTracker;
