import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Alert, Button, Col, Container, Row, FormGroup} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import googlePayment from 'braintree-web/google-payment';
import {actions} from 'modules/checkout';
import {getCurrentTotal} from 'modules/checkout/selectors';
import {GooglePay as GooglePayMark} from 'icons/brand-marks';

@connect(
  state => ({
    total: getCurrentTotal(state),
    selectedPaymentOption: state.checkout.orderRequest.OfferPaymentOptionId
  }),
  {
    setNewAddress: actions.setNewAddress,
    loadTotals: actions.loadTotals,
    setPaymentToken: actions.setPaymentToken,
    processThirdPartyCheckout: actions.processThirdPartyCheckout
  }
)
export class GooglePay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      ready: true,
      button: null,
      rendered: false
    };
    this.paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: process.env.ENVIRONMENT || 'TEST'
    });
    googlePayment.create(
      {
        client: props.client,
        googlePayVersion: 2,
        ...(process.env.GOOGLEPAY_MERCHANT_ID && {googleMerchantId: process.env.GOOGLEPAY_MERCHANT_ID})
      },
      (err, instance) => {
        if (err) {
          this.setState({error: err});
        }
        this.googlePayInstance = instance;

        this.allowedPaymentMethods = instance.createPaymentDataRequest().allowedPaymentMethods;

        // Don't allow PAYPAL
        this.allowedPaymentMethods = this.allowedPaymentMethods.filter(apm => apm.type === 'CARD');
        // don't allow methods stored on Android devices
        this.allowedPaymentMethods[0].parameters.allowedAuthMethods = ['PAN_ONLY'];

        this.paymentsClient
          .isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: this.allowedPaymentMethods
            //existingPaymentMethodRequired: true
          })
          .then(resp => {
            if (resp.result) {
              this.setState({ready: true});
            }
          });
      }
    );
    this.showPaymentSheet = this.showPaymentSheet.bind(this);
    this.button = this.button.bind(this);
  }

  componentDidMount() {
    this.setState({
      button: this.paymentsClient.createButton({
        onClick: this.showPaymentSheet
      })
    });
  }

  setAddressFromGooglePayAddress(address, addressType) {
    this.props.setNewAddress(
      {
        address1: address.address1,
        address2: address.address2 + (address.address3 ? `, ${address.address3}` : ''),
        city: address.locality,
        state: address.administrativeArea,
        postalCode: address.postalCode,
        country: address.countryCode
      },
      addressType
    );
  }

  showPaymentSheet() {
    const self = this;
    const {total} = this.props;
    const paymentRequest = this.googlePayInstance.createPaymentDataRequest({
      allowedPaymentMethods: this.allowedPaymentMethods,
      emailRequired: true,
      transactionInfo: {
        currencyCode: 'USD',
        totalPriceStatus: 'ESTIMATED',
        totalPrice: total.order.total // Your amount
      }
    });
    let cardPaymentMethod = paymentRequest.allowedPaymentMethods[0];
    cardPaymentMethod.parameters.billingAddressRequired = true;
    cardPaymentMethod.parameters.billingAddressParameters = {
      format: 'FULL'
    };

    this.paymentsClient
      .loadPaymentData(paymentRequest)
      .then(paymentData => {
        return this.googlePayInstance.parseResponse(paymentData, (err, result) => {
          if (err) {
            this.setState({
              error: err
            });
          }
          const billingAddress = paymentData.paymentMethodData.info.billingAddress || {};
          const shippingAddress = paymentData.paymentMethodData.shippingAddress;
          self.props.setPaymentToken({gateway: 'braintree', token: result});

          if (billingAddress && billingAddress.postalCode) {
            self.setAddressFromGooglePayAddress(billingAddress, 'BillingAddress');
          }

          if (shippingAddress) {
            self.setAddressFromGooglePayAddress(shippingAddress, 'ShippingAddress');
          }
          self.props.processThirdPartyCheckout({
            firstName: billingAddress.name ? billingAddress.name.split(' ')[0] : undefined,
            lastName: billingAddress.name
              ? billingAddress.name
                  .split(' ')
                  .slice(1)
                  .join(' ')
              : undefined,
            email: paymentData.email
          });
        });
      })
      .catch(err => {
        this.setState({
          error: err
        });
      });
    return false;
  }

  button() {
    return this.paymentsClient.createButton({
      buttonSizeMode: 'fill',
      buttonType: 'buy',
      onClick: () => {
        return this.showPaymentSheet();
      },
      allowedPaymentMethods: this.allowedPaymentMethods
    });
  }

  render() {
    const {error, supported, ready, rendered} = this.state;
    const Tag = this.props.tag || 'div';

    const gPayContainer = (
      <div style={{height: '50px'}}>
        <Tag className="d-flex align-items-center">
          <span
            id="gpay-button-container"
            style={{width: '100%', maxWidth: '280px', height: '45px'}}
            className="m-auto text-center"
          ></span>
        </Tag>
      </div>
    );
    const gPayElem = document.getElementById('gpay-button-container');
    if (gPayElem && ready && !rendered) {
      gPayElem.appendChild(this.button());
      this.setState({rendered: true});
    }
    return gPayContainer;
  }
}

export default GooglePay;
