/* eslint-disable */
import React, {Component} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import {SectionTriangle} from '../FRSCommon/Section';
import {ImgCard} from '../FRSCommon/ImgBullet';
import {FaqCard} from './components/FaqList';
import SalesDisclaimer from './components/SalesDisclaimer';
import Footer from '../FRSCommon/Footer';
import {conversations, impactKit, experts, extendedInterviews, faq} from './data.json';

const CSS_URL = process.env.FF_STYLESHEET_URL;

class FFImpactKit extends Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
    this.handleDecline.bind(this);
    this.handleSuccess.bind(this);
  }

  handleDecline = e => {
    this.setState({processing: true});
    this.props.handleDeclineUpsell(e);
  };
  handleSuccess = e => {
    this.setState({processing: true});
    this.props.handleClickUpsell(e);
  };

  componentDidMount() {
    this.linkTag = document.createElement('link');

    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    document.getElementsByTagName('head')[0].appendChild(this.linkTag);
    this.styleTag = document.createElement('style');
    var css = 'html {font-size: unset !important}';
    this.styleTag.setAttribute('type', 'text/css');

    if ('textContent' in this.styleTag) {
      this.styleTag.textContent = css;
    } else {
      this.styleTag.styleSheet.cssText = css;
    }

    document.getElementsByTagName('head')[0].appendChild(this.styleTag);
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.styleTag && this.styleTag.remove();
  }

  render() {
    const {processing} = this.state;
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="page d-flex flex-column">
        <div className="page-content">
          <SectionTriangle
            id="ff-up-header-1"
            color="black"
            className="section-triangle-first"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/ff-saleslanding-image-header-backdrop-landon-parenteau-EEuDMqRYbx0-unsplash.jpg"
            innerClass="p-0"
          />

          <SectionTriangle id="ff-up-header-2" color="white" type="both" className="text-center">
            <Container>
              <div className="offset-box background-white-80 border-ff-gray-10-solid p-3 p-md-4 p-lg-5">
                <Row>
                  <Col>
                    <h3 className="section-heading mb-3">
                      Thank you for choosing to own <i>Fantastic Fungi!</i>
                    </h3>
                    <p className="mb-4">
                      Now watch this short video to see how you can take <br className="d-none d-xl-block" />
                      the next step for an unbeatable price you’ll never see again.
                    </p>
                    <div className="video-wrapper">
                      <ReactPlayer
                        className="video-player"
                        width="100%"
                        height="100%"
                        controls={true}
                        url="https://youtu.be/8qMrptXMmgg"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="offset-box-cta">
                  <Col>
                    <p>
                      I wanted to make sure you didn’t miss this one-time offer <br className="d-none d-xl-block" />
                      to join us today as we change the world together.
                    </p>
                    <h5 className="text-400 m-0">
                      <i>
                        Are you ready to discover more of the mysteries of mycelium,{' '}
                        <br className="d-none d-xl-block" />
                        delve into the world of plant medicine, and enjoy the satisfaction{' '}
                        <br className="d-none d-xl-block" />
                        of building a better future for life on Earth?
                      </i>
                    </h5>
                  </Col>
                </Row>
              </div>
              <h5>
                <i>One-time offer — 69% savings!</i>
              </h5>
              <p>
                <b>
                  Support Your Health and Our Mission <br className="d-none d-xl-block" />
                  And Get Immediate Lifetime Access to the Impact Kit Today
                </b>
              </p>
              <Button color="cta-ff" onClick={this.handleSuccess} disabled={processing} className="mb-3">
                {processing ? 'Loading...' : 'YES, I WANT TO ADD THIS TO MY ORDER'}
              </Button>
              <p>Clicking "Yes" above will add The Impact Kit to your purchase now for an additional $60.</p>
              <p>Your purchase comes with a risk-free, 60-day, total money-back guarantee.</p>
              <Button color="upsell-gray" onClick={this.handleDecline} disabled={processing} className="mb-3">
                {processing ? 'Loading...' : 'No, thanks'}
              </Button>
              <p>Clicking the "No" button above will take you to the products already in your cart.</p>
              <SalesDisclaimer noGuaranteeText />
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id="ff-"
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/ff-saleslanding-image-body-voices-marc-markstein-ibouKITXBFM-unsplash.jpg"
            innerClass="d-flex flex-grow-1"
            offset="bottom"
            className="background-overlay-black-50 text-white text-center text-shadow-dark"
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg="10">
                  <h3>
                    We spent years pouring our hearts and souls into telling the important story captured in{' '}
                    <i>Fantastic Fungi.</i> We hope the film inspired you.
                  </h3>
                  <h4>
                    Now’s the time to dig deeper with the <i>Fantastic Fungi Impact Kit</i> — brought to you by Louie
                    and the <i>Fantastic Fungi</i> team, in partnership with Food Revolution Network
                  </h4>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-cream" type="bottom">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center">
                    Unlock the Power of Mushrooms with <br className="d-none d-xl-block" />
                    the Experts Featured in <i>Fantastic Fungi</i>
                  </h3>
                  <p className="text-center mb-5">
                    The film was just the beginning! Get over 16 hours of new insights into the{' '}
                    <br className="d-none d-xl-block" />
                    mushroom kingdom, plus never-before-released bonus footage from <i>Fantastic Fungi.</i>
                  </p>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/151812309.jpg"
                    className="text-h5 mb-4"
                  >
                    <b>Revitalize</b> your body to fight disease and promote vibrant health with potent nutrient-dense
                    mushrooms.
                  </ImgCard>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/1316793260.jpg"
                    className="text-h5 mb-4"
                  >
                    <b>Discover</b> the latest medical research in the treatment of depression, anxiety addiction, PTSD,
                    end-of-life care, and more.
                  </ImgCard>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/904902642.jpg"
                    className="text-h5 mb-4"
                  >
                    <b>Gain</b> insights into psychedelic uses of mushrooms — knowledge that every adult should have
                    right now!
                  </ImgCard>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/1028900652.jpg"
                    className="text-h5 mb-4"
                  >
                    <b>Deepen</b> connection to the natural world and to the humanity that we all have in common.
                  </ImgCard>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/489150096.jpg"
                    className="text-h5 mb-4"
                  >
                    <b>Demystify</b> the often-misunderstood world of mushrooms{' '}
                  </ImgCard>
                  <ImgCard
                    img="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/1270104024.jpg"
                    className="text-h5"
                  >
                    <b>Protect</b> yourself and your loved ones from a future of environmental destruction.
                  </ImgCard>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-gray" type="bottom" className="text-center text-white">
            <Container>
              <Row>
                <Col>
                  <h4 className="section-heading">Are you ready to join this world-changing revolution?</h4>
                  <h3 className="mt-0">
                    The <i>Fantastic Fungi Impact Kit</i> will show you how.
                  </h3>
                  <p className="text-white">
                    You’ll have 20+ master impact leaders and teachers showing <br className="d-none d-xl-block" />
                    you the simplest ways to be part of the solution on planet Earth.
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-cream" type="bottom">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center">
                    What’s Included In The <i>Fantastic Fungi Impact Kit</i>
                  </h3>
                </Col>
              </Row>
              <Row className="d-flex align-items-stretch">
                {impactKit.map((impactKitItem, i) => (
                  <Col lg="6" className="d-flex flex-column mb-4">
                    <div className="background-white box-shadow-black-25 d-flex flex-column flex-grow-1">
                      <div className="impact-kit-image mb-3">
                        <img src={impactKitItem.image} />
                      </div>
                      <div className="p-4">
                        <ReactMarkdown className="text-h5 text-800" source={impactKitItem.name} />
                        <ReactMarkdown source={impactKitItem.text} />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <h3 className="text-center mt-4">
                    You can’t see all these Masterclasses and extended bonuses anywhere else. You can only get it all
                    here, now!
                  </h3>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-gray" type="bottom">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-only-heading text-center text-white">
                    Deepen Your Learning <br className="d-none d-xl-block" />
                    With <i>Fantastic Fungi’s</i> Experts
                  </h2>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="white" type="bottom">
            <Container>
              <Row className="d-flex align-items-stretch justify-content-center text-center">
                {experts.map((expert, i) => (
                  <Col xs="6" lg="4" className="expert-wrap d-flex flex-column px-3 mb-4">
                    <div className="mb-3">
                      <img className="rounded-circle" src={expert.image} style={{maxHeight: '150px'}} />
                    </div>
                    <div className="my-auto">
                      <h5 className="section-heading mb-2">{expert.name}</h5>
                      <ReactMarkdown className="text-small" source={expert.description} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-gray" type="bottom" className="text-white text-center">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-only-heading">Does any of this sound like you?</h2>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/1283900775.jpg"
            imgPosition="left"
          >
            <h3 className="section-heading">You love health.</h3>
            <p>
              Science is showing us how certain mushrooms are renowned for their health benefits and could provide more
              energy, immune health, relaxation, and great sleep.
            </p>
          </SectionTriangle>

          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none section-triangle-image-lg-none-offset"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/151812309.jpg"
            imgPosition="right"
          >
            <h3 className="section-heading">You want to feel good and free.</h3>
            <p>
              You’ve considered or even tried healing routes like therapy, self-help books, journaling, and possibly
              medication. And you want to know everything you can about how people are successfully using therapeutic
              mushrooms to experience healing breakthroughs. Are mushrooms for everyone? What do you need to know about
              this new and exciting healing modality?
            </p>
          </SectionTriangle>
          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none section-triangle-image-lg-none-offset"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/489150096.jpg"
            imgPosition="left"
          >
            <h3 className="section-heading">
              You want reliable research on how to use “magic” mushrooms with healing intent.
            </h3>
            <p>
              In the last few years, research and major news features are praising psychoactive forms of mushrooms for
              everything from addiction treatment to spiritual connection to microdosing for better brain performance.
              What are the experts saying? And how can we harness the latest scientific research to expand our
              collective consciousness and heal — safely?
            </p>
          </SectionTriangle>
          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none section-triangle-image-lg-none-offset"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/878854774.jpg"
            imgPosition="right"
          >
            <h3 className="section-heading">Climate change matters to you.</h3>
            <p>
              Fungi are being hailed as the greatest natural solution to climate change. They add carbon to the soil,
              filter water, provide nutrients to plants that pump out oxygen, clean up oil spills and pollution, and
              provide 100% compostable sources of packaging. You want to know how mushrooms can help make the world a
              better place.
            </p>
          </SectionTriangle>
          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none section-triangle-image-lg-none-offset"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/mushroom-stew.jpg"
            imgPosition="left"
          >
            <h3 className="section-heading">You’re a bit of a foodie!</h3>

            <p>
              You love wild food, foraging, cooking new dishes, and most of all, eating! Whether it’s adding a few
              supporting mushrooms for a lip-smacking umami experience, or making them the star of the show, you’re
              excited to lean into mushrooms for all their culinary delight.
            </p>
          </SectionTriangle>
          <SectionTriangle
            id=""
            className="section-triangle-image-lg-none-offset"
            color="white"
            type="bottom"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/1301815523.jpg"
            imgPosition="right"
          >
            <h3 className="section-heading">You’re interested in eating a more plant-centered diet.</h3>
            <p>
              Mushrooms are one of the most savory ways to do just that — and you can enjoy the outstanding health
              benefits while contributing to significantly fewer greenhouse gas emissions.
            </p>
          </SectionTriangle>

          <SectionTriangle
            id="ff-up-unlock"
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/ff-saleslanding-image-body-joinrevolution-jesse-dodds-ZjDhntvNzAs-unsplash.jpg"
            innerClass="d-flex flex-grow-1"
            offset="bottom"
            className="background-overlay-black-50 text-center text-white text-shadow-dark"
          >
            <Container>
              <Row>
                <Col>
                  <h2 className="section-only-heading">
                    Unlock the Power of Mushrooms — for your health, for your happiness, and for your planet
                  </h2>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-gray" type="both">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-only-heading text-center text-white">Unreleased & Extended Interviews</h2>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="ff-cream" type="bottom">
            <Container>
              <Row>
                <Col>
                  <p className="text-center mb-5">
                    There’s only one way to get access to these additional interviews with the featured experts,{' '}
                    <br className="d-none d-xl-block" />
                    deleted scenes, and bonus features: The <i>Fantastic Fungi Impact Kit</i>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex align-items-stretch justify-content-center">
                {extendedInterviews.map((interview, i) => (
                  <Col xs="6" lg="3" className="interview-wrap d-flex flex-grow-1 px-3 mb-4 mb-lg-0">
                    <div className="d-flex flex-column background-white box-shadow-black-25">
                      <div className="px-1 pt-1 pb-0">
                        <img src={interview.image} />
                      </div>
                      <div className="p-3 my-auto">
                        <p className="text-small text-800 mb-1">{interview.name}</p>
                        <p className="text-small mb-0">{interview.type}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="white" type="bottom">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col lg="6" className="order-lg-2">
                  <img src="https://cdn.foodrevolution.org/global/trees-logo-green.png" style={{maxHeight: '200px'}} />
                </Col>
                <Col lg="6" className="text-center text-lg-right order-lg-1">
                  <h4 className="section-heading">Want a better world? We do, too!</h4>
                  <p className="text-h5">
                    For every Impact Kit purchased, we make a donation to Trees for the Future, enabling them to plant
                    another <br className="d-none d-xl-block" />
                    organic fruit or nut tree in a <br className="d-none d-xl-block" />
                    low-income community.
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id="ff-need-2"
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/ff-saleslanding-image-body-needyou-karl-anderson-KH-WE85l3kc-unsplash.jpg"
            bgImgPosition="center-bottom"
            offset="bottom"
          />

          <SectionTriangle id="" color="ff-cream" type="both">
            <Container>
              <Row>
                <Col lg="4">
                  <h3 className="section-heading text-center text-lg-right">
                    Frequently <br className="d-none d-xl-block" />
                    Asked Questions
                  </h3>
                </Col>
                <Col lg="8">
                  <FaqCard faqlist={faq} />
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle id="" color="white" type="bottom" className="text-center">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading">
                    You’ve decided to own <i>Fantastic Fungi.</i>
                  </h3>
                  <h4 className="mt-0">
                    Now make the film have <i>real impact</i> <br className="d-none d-xl-block" />
                    by adding the Impact Kit to your order.
                  </h4>

                  <img className="mb-5" src="https://cdn.foodrevolution.org/ff/FF-Product Image-v20220527.png" />
                  <p>
                    Once you close this page, you’ll miss this exclusive, <br className="d-none d-xl-block" />
                    majorly discounted offer for the{' '}
                    <b>
                      <i>Fantastic Fungi Impact Kit.</i>
                    </b>
                  </p>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>

          <SectionTriangle
            id="ff-cta-1"
            color="black"
            imgUrl="https://cdn.foodrevolution.org/checkout/assets/ff-impactkit/backgrounds/ff-saleslanding-image-header-backdrop-landon-parenteau-EEuDMqRYbx0-unsplash.jpg"
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg="10" xl="8">
                  <div className="cta-box border-ff-orange-5-solid text-center">
                    <SectionTriangle id="" color="white" type="bottom" innerClass="pt-4 pt-lg-5">
                      <h5 className="section-only-heading">
                        <i>One-Time Offer Only!</i>
                      </h5>
                    </SectionTriangle>
                    <SectionTriangle id="" color="ff-cream" type="bottom">
                      <Button color="cta-ff" onClick={this.handleSuccess} disabled={processing} className="mb-3">
                        {processing ? 'Loading...' : 'YES, I WANT TO ADD THIS TO MY ORDER'}
                      </Button>
                      <p>
                        Clicking "Yes" above will add The Impact Kit <br className="d-none d-xl-block" />
                        to your purchase now for an additional $60.
                      </p>
                      <p>
                        Your purchase comes with a risk-free, <br className="d-none d-xl-block" />
                        60-day, total money-back guarantee.
                      </p>
                      <Button color="upsell-gray" onClick={this.handleDecline} disabled={processing} className="mb-3">
                        {processing ? 'Loading...' : 'No, thanks'}
                      </Button>
                      <p>
                        Clicking the "No" button above will take you <br className="d-none d-xl-block" />
                        to the products already in your cart.
                      </p>
                    </SectionTriangle>
                    <SectionTriangle id="" color="white">
                      <SalesDisclaimer className="mt-0" noGuaranteeText />
                    </SectionTriangle>
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>
        </div>

        <div className="footer-content mt-auto">
          <Footer partner />
        </div>
      </div>
    );
  }
}

export default FFImpactKit;
