import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container } from 'reactstrap';
//import {Breadcrumbs} from 'components/Breadcrumb';
import { Link } from 'react-router-dom';
function BreadCrumbWrapper(props) {
  /* linkTos = {to: "/uri", title:"bread pudding"}  */
  const { productTitle, productInPath, linkTos } = props;
  return (
    <Container>
      <Breadcrumb tag="nav" listTag="div" className="frnBreadCrumbs no-print">
        <BreadcrumbItem tag={Link} to={`/products/${productInPath}`}>
          {/* product.title */}
          {productTitle ? productTitle : null}
        </BreadcrumbItem>
        {linkTos ? (
          linkTos.map((item, i) => {
            return (
              <BreadcrumbItem
                active={i == linkTos.length - 1 ? true : false}
                tag={i == linkTos.length - 1 ? 'span' : Link}
                to={`/products/${productInPath}/${item.to}`}
              >
                {item.title ? item.title : null}
              </BreadcrumbItem>
            );
          })
        ) : null}
      </Breadcrumb>
      {props.children}
    </Container>
  );
}

export default BreadCrumbWrapper;
