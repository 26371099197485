import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Alert, Col, Container, Row} from 'reactstrap';
import ContentCard from 'routes/Products/components/ContentCard';
import styles from 'styles/index.scss';
import {track} from '../../../../lib/track';
const {resourcesListTwoContainer, resourcesListTwoResources} = styles;

class ResourcesListTwo extends PureComponent {
  componentDidMount() {
    if (this.props.isPageView) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {Children, title, customList, customTitle, isPageView, colSizes, disableViewAll, userAnalytics} = this.props;
    const resourcesList = customList || Children;

    const path = location.pathname.split('/');
    const thisProductInPath = path[2];
    return (
      <div className="resourcesListTwoContainer">
        <Row className="align-items-end">
          <Col xs="auto">
            <h4>{customTitle || 'Resources'}</h4>
          </Col>
          {!isPageView && !disableViewAll && (
            <Col xs="auto" style={{marginBottom: '1.8rem'}}>
              <Link to={`/products/${thisProductInPath}/resources`}>View all ...</Link>
            </Col>
          )}
        </Row>
        <Row className={resourcesListTwoResources}>
          {resourcesList ? (
            resourcesList.map((child, i) => (
              <Col
                {...colSizes}
                onClick={() => {
                  track(
                    'LINK_CLICK',
                    userAnalytics
                      ? {...userAnalytics, ...{link_type: 'resource'}}
                      : {service: 'default', link_type: 'resource'}
                  );
                }}
              >
                <ContentCard key={child ? child.id : i} {...child} className="h-100 card-resource" />
              </Col>
            ))
          ) : (
            <Col>
              <Alert>There are no {nouns} available.</Alert>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

ResourcesListTwo.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPageView: PropTypes.bool.isRequired
};

// This component is used inside of week
// AND as a separate page
// IF in separate page, no 'View all' link is visible

ResourcesListTwo.defaultProps = {
  isPageView: false
};

export default ResourcesListTwo;
