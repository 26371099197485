import React from 'react';
import {Alert, Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPartyHorn} from '@fortawesome/pro-solid-svg-icons/faPartyHorn';

const AssessmentScore = ({score, questions, answers, passed, incorrectAnswers, restartAssessment}) => (
  <>
    <Row className="mb-3">
      <Col>
        <h5 className="mt-0">Results</h5>
        {passed ? (
          <Alert color="success">
            <FontAwesomeIcon icon={faPartyHorn} /> Congratulations you passed!
          </Alert>
        ) : (
          <Alert color="warning">
            You did not score enough to pass the assessment, please review your incorrect responses and retake the
            assessment.
          </Alert>
        )}
        <h6>Your Score: {score}%</h6>
        {incorrectAnswers.length > 0 ? (
          <>
            <h6>Incorrect Answers:</h6>
            {questions.map((question, i) =>
              incorrectAnswers.includes(question.id) ? (
                <div key={question.id} className="py-3">
                  <div>
                    {i + 1}) {question.title}
                  </div>
                  <div style={{textDecoration: 'underline'}}>
                    {question.choices.find(v => v.id === answers[question.id]).title}
                  </div>
                </div>
              ) : null
            )}
          </>
        ) : null}
      </Col>
    </Row>
    <Row>
      <Col>
        <Button color="primary" onClick={restartAssessment}>
          Retake Assessment
        </Button>
      </Col>
    </Row>
  </>
);

export default AssessmentScore;
