import React, {PureComponent} from 'react';
import {Container, Row, Col} from 'reactstrap';
import ContentCard from '../../../../components/ContentCard';
import {capitalizeFirstLetter} from '../../../../../../lib/characters';
const DEFAULT_DOWNLOAD_IMG = process.env.DEFAULT_DOWNLOAD_IMG; //this is ugly but it is going to be ugly in the backend or frontend
// content card page creates a page from a content card
function ContentCardPage({contentItem: ci, customTitle}) {
  let contentItem = ci;

  if (!contentItem.displayProps.image.url) contentItem.displayProps.image.url = DEFAULT_DOWNLOAD_IMG;

  return (
    <Container>
      <Row>
        <Col xs="auto">
          <h4>{customTitle ? customTitle : `${capitalizeFirstLetter(contentItem.type)}:${contentItem.title}`}</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <ContentCard defaultValues={{}} {...contentItem} />
        </Col>
      </Row>
    </Container>
  );
}

export default ContentCardPage;
