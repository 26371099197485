import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

const SortByMenu = props => {
  return (
    <Dropdown key={props.pkey + 'menu'} isOpen={props.sortByIsOpen} toggle={props.toggleSortBy}>
      <DropdownToggle data-toggle={props.pkey + 'dropdown'} key={props.pkey + 'toggle'} tag="div"></DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          active={props.sortType === 'alphabetical'}
          key={props.pkey + '1'}
          onClick={() => {
            props.handleSortByType('alphabetical');
          }}
        >
          Alphabetical
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          active={props.sortType === 'newest'}
          key={props.pkey + '2'}
          onClick={() => {
            props.handleSortByType('newest');
          }}
        >
          Newest
        </DropdownItem>
        <DropdownItem
          active={props.sortType === 'relevant'}
          key={props.pkey + '3'}
          onClick={() => {
            props.handleSortByType('relevant');
          }}
        >
          Relevant
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SortByMenu;
