/* eslint-disable */
import React from 'react';

import { useUserState } from './context/user';
import { useMessageState, useMessageDispatch } from './context/message';

import { Button } from 'reactstrap';

export default function Navigation() {
  const messageDispatch = useMessageDispatch();
  const messageState = useMessageState();
  const userState = useUserState();

  const [unreadNotificationsCount, setUnreadNotificationsCount] = React.useState(0);

  React.useEffect(() => {
    setUnreadNotificationsCount(messageState.unreadNotificationsCount);
  }, [messageState.unreadNotificationsCount]);

  return (
    <div className="chat-navigation text-end">
      {messageState.displayBackButtonToHome && (
        <>
          <Button
            className="text-small"
            color="nav"
            onClick={() => {
              messageDispatch({ type: 'RESET_BACK_BUTTON' });
            }}
          >
            Back
          </Button>
          &nbsp;|&nbsp;
        </>
      )}
      <Button
        className="text-small"
        color="nav"
        onClick={() => {
          messageDispatch({ type: 'SHOW_USER_MESSAGES', payload: userState.id });
        }}
      >
        See My Posts
      </Button>
      &nbsp;|&nbsp;
      <Button
        className="text-small"
        color="nav"
        onClick={() => {
          messageDispatch({ type: 'SHOW_USER_NOTIFICATIONS', payload: userState.id });
        }}
      >
        {`Notifications (${unreadNotificationsCount} unread)`}
      </Button>
    </div>
  );
}
