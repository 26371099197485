import React from 'react';
import {Container, Row} from 'reactstrap';
import RecipeGrid from 'routes/Products/components/RecipesGrid';
import BackNav from 'routes/Products/components/BackNav';

const WrapperRecipes = props => {
  return (
    <div>
      <Container>
        <div style={{marginTop: 65, height: 20, width: 50, position: 'fixed', top: 0, left: '10px', zIndex: 500}}>
          <BackNav />
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Live Challenge Recipe Collection</h3>
          </div>
        </div>
      </Container>
      <Row>
        <RecipeGrid
          singleRecipeInUrl={null}
          disableRouter={false}
          disableViewAll={true}
          colSizes={{xs: 6, sm: 4, md: 4}}
          {...props}
        />
      </Row>
      <div style={{width: '100%  ', height: 500}}></div>
    </div>
  );
};

export default WrapperRecipes;
