/* eslint-disable */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Data} from 'containers/Data';
import Bonuses from 'routes/Products/components/Bonuses';

class BonusesProduct extends Component {
  render() {
    const productSlug = location.pathname.split('/')[2];
    const {id} = this.props;
    return <Data component={Bonuses} url={`/content/${id}/${productSlug}`} />;
  }
}

BonusesProduct.propTypes = {
  id: PropTypes.number.isRequired
};

BonusesProduct.displayname = 'BonusesProduct';

export default withRouter(BonusesProduct);
