//import './App.css';
//import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { UserProvider, useUserDispatch, useUserState } from './context/user';
import { MessageProvider, useMessageDispatch, useMessageState } from './context/message';
import { EnvProvider, useEnvDispatch } from './context/env';
import classNames from 'classnames';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { AuthenticatorProvider } from '@aws-amplify/ui-react-core';
import { fetchUserAttributes, signUp, autoSignIn, signIn, signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { sessionStorage } from 'aws-amplify/utils';

import Navigation from './Navigation';
import Messages from './Messages';
import CreateMessageBox from './CreateMessageBox';
import GetLatestMessagesButton from './GetLatestMessagesButton';

import * as subscriptions from './graphql/subscriptions';

import { getMessageBatch, checkCognitoGroups } from './utils';

interface IChatProps {
  category: string;
  optInName: string;
  optInEmail: string;
  isCustomer: boolean;
  chatConfiguration: any;
  className?: string;
  toggleOptinModal: Function;
}
const dummyPassword = 'frn!F0od';
const avatarUrlPrefix = 'https://cdn.foodrevolution.org/global/avatars/';

function AppWrapper({
  category,
  optInName,
  optInEmail,
  isCustomer,
  chatConfiguration,
  className,
  toggleOptinModal
}: IChatProps) {
  const client = generateClient();
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const messageState = useMessageState();
  const messageDispatch = useMessageDispatch();

  const envDispatch = useEnvDispatch();

  envDispatch({ type: 'SET_ENV', payload: chatConfiguration });
  Amplify.configure({ ...chatConfiguration, storage: sessionStorage });
  cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

  const [userId, setUserId] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [signedIn, setSignedIn] = React.useState(false);
  const idRef = React.useRef(userId);

  React.useEffect(() => {
    if (optInEmail && optInName) handleSignUp(optInEmail, optInName, {});
  }, [optInName, optInEmail]);

  React.useEffect(() => {
    async function fetchMessages() {
      const allMessagesPayload = (await getMessageBatch({
        category: messageState.category,
        sortDirection: 'DESC'
      })) as { data: any };
      const allMessages = allMessagesPayload?.data?.messagesByDate?.items;
      const nextToken = allMessagesPayload?.data?.messagesByDate?.nextToken;
      messageDispatch({ type: 'SET_MESSAGES', payload: allMessages });
      messageDispatch({ type: 'SET_DISPLAYED_MESSAGES', payload: allMessages });
      messageDispatch({ type: 'SET_NEXT_TOKEN', payload: nextToken });

      // set user notifications
      if (allMessages?.length) {
        const userNotifications = allMessages.filter((m: any) => m.userToNotifyID === userId);
        messageDispatch({
          type: 'SET_UNREAD_NOTIFICATIONS_COUNT',
          payload: userNotifications.filter((m: any) => !m.readByParentCommentUser).length
        });
      }
    }
    if (messageState.category) fetchMessages();
  }, [userId, messageState.category]);

  React.useEffect(() => {
    const sub = client.graphql({ query: subscriptions.onCreateMessage }).subscribe({
      next: ({ data }) => {
        messageDispatch({
          type: 'ADD_MESSAGE',
          payload: {
            newMessage: data.onCreateMessage,
            myPost: data.onCreateMessage.userID === idRef.current,
            myUserId: idRef.current
          }
        });
      },
      error: (err: any) => console.log(err)
    });
    return () => sub.unsubscribe();
  }, []);

  React.useEffect(() => {
    const sub = client.graphql({ query: subscriptions.onUpdateMessage }).subscribe({
      next: ({ data }) => {
        messageDispatch({ type: 'UPDATE_MESSAGE', payload: data.onUpdateMessage });
      },
      error: (err: any) => console.log(err)
    });
    return () => sub.unsubscribe();
  }, []);

  React.useEffect(() => {
    if (category) {
      messageDispatch({ type: 'SET_CATEGORY', payload: category });
    }
  }, []);

  React.useEffect(() => {
    if (!userState.userId || !userState.name || !userState.email) {
      fetchAttributes();
    }
  }, [signedIn]);

  async function fetchAttributes() {
    return fetchUserAttributes()
      .then(userAtts => {
        // call the auth session to also check if the user is a moderator
        return checkCognitoGroups().then(userGroups => {
          userDispatch({
            type: 'SET_USER',
            payload: {
              name: userAtts.name || '',
              email: userAtts.email || '',
              id: userAtts.sub || '',
              isCustomer,
              picture: userAtts.picture || '',
              ...userGroups
            }
          });
          setEmail(userAtts.email || '');
          setUserId(userAtts.sub || '');
          idRef.current = userAtts.sub || '';
          return true;
        });
      })
      .catch(err => {
        console.log('Error fetching user atts', err);
        return false;
      });
  }

  // now working, I need to override confirmSignUp now as it asks for a conf code
  async function handleSignUp(email: string, name: string, input: any) {
    // generate avatar string based on user email

    /* shamelessly stolen from a GeeksForGeeks article:
     * https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/ */
    function stringToHash(string: string) {
      return string.split('').reduce((hash: number, char: string) => {
        return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
      }, 0);
    }

    let emailLength = email.length;

    let avatarString = `${String((Math.abs(stringToHash(email.slice(0, emailLength / 2))) % 30) + 1).padStart(
      2,
      '0'
    )}-${String((Math.abs(stringToHash(email.slice(emailLength / 2))) % 10) + 1).padStart(2, '0')}`;

    let avatarUrl = `${avatarUrlPrefix}${avatarString}.png`;

    return signUp({
      username: email,
      password: dummyPassword,
      options: {
        ...input.options,
        userAttributes: {
          ...input.options?.userAttributes,
          email,
          name,
          picture: avatarUrl
        },
        autoSignIn: true
      }
    })
      .then(signUpResp => {
        return autoSignIn()
          .then(() => {
            setSignedIn(true);
            setUserId(signUpResp.userId || '');
            return signUpResp;
          })
          .catch(e => {
            console.log('Error with signUp / autoSignIn flow', e);
            return false;
          });
      })
      .catch(e => {
        if (e.message.match(/User already exists/)) {
          let signInReturn: any;
          let signInParams: any = {
            username: email,
            password: dummyPassword,
            options: {
              ...input.options,
              userAttributes: {
                ...input.options?.userAttributes,
                email,
                name,
                picture: avatarUrl
              }
            }
          };
          return signIn(signInParams)
            .then(signInResp => {
              signInReturn = signInResp;
              setSignedIn(true);
              return signInResp;
            })
            .catch(ee => {
              console.log('Error from sign in??', ee);
              return signInReturn;
            });
        } else {
          console.log('Outer error', e);
          return false;
        }
      });
  }

  return (
    <Container className={classNames(`frn-chat`, `chat-container`, `rounded`, className)} fluid>
      {email && (
        <Row className="justify-content-end">
          <Col xs="auto py-2">
            <button
              type="button"
              className="btn btn-nav text-small"
              onClick={() => {
                return signOut().then(() => {
                  userDispatch({ type: 'RESET_USER' });
                  setEmail('');
                  setUserId('');
                  setSignedIn(false);
                });
              }}
            >
              Sign Out
            </button>
          </Col>
        </Row>
      )}
      {/* Chat app below */}
      {userId && <Navigation />}
      <div className="chat-wrap">
        <Messages />
        <div className="text-center">
          <GetLatestMessagesButton />
          {userId && (
            <Row className="background-light-gray rounded-bottom p-4">
              <CreateMessageBox id={'reply-box'} />
            </Row>
          )}
          {!userId && (
            <>
              <Button
                className="btn-chat-login"
                color="cta"
                onClick={() => {
                  toggleOptinModal();
                }}
              >
                Join the Conversation
              </Button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}

export const Chat = (props: any) => {
  return (
    <ErrorBoundary fallback={<div>Something went wrong. Please refresh to re-activate messaging.</div>}>
      <EnvProvider>
        <UserProvider>
          <MessageProvider>
            <AuthenticatorProvider>
              <AppWrapper {...props} />
            </AuthenticatorProvider>
          </MessageProvider>
        </UserProvider>
      </EnvProvider>
    </ErrorBoundary>
  );
};

export default Chat;
