export const InterviewCollectionItem = {
  id: 145,
  name: "BC18 - Dale Bredesen, MD — The End of Alzheimer's",
  title: "Dale Bredesen, MD — The End of Alzheimer's",
  slug: 'bc18-dale-bredesen',
  type: 'InterviewCollectionItem',
  data: {},
  published: true,
  free: false,
  ContentTags: [],
  Children: [
    {
      id: 148,
      name: "Dale Bredesen, MD — The End of Alzheimer's",
      title: "The End of Alzheimer's",
      slug: 'dale-bredesen-interview-2018',
      type: 'audioInterview',
      data: {
        url: 'https://cdn.foodrevolution.org/members/mp3/Dale_Bredesen_MD_The_End_Of_Alzheimer_s-2018.mp3',
        linkto: {
          url: 'https://www.drbredesen.com/'
        },
        topics:
          'Preventing and reversing Alzheimer’s, APOE, insulin resistance, amyloid as a protective response, inflammation, nutrition, biochemistry, toxic load, homocysteine, optimal lab results, B vitamins, vitamin D,  sleep, air filters, pharmaceuticals, stress, meditation, lasting results, exercise, oral hygiene, coffee, copper zinc ratio, cognoscopy',
        duration: '53 minutes'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 154,
          name: 'Dale Bredesen, MD',
          title: 'Dale Bredesen, MD',
          slug: 'dale-bredsen-md',
          type: 'bio',
          data: {
            img: 'https://cdn.foodrevolution.org/members/assets/speakers/dale-bredesen-md.jpg',
            description:
              'Dale Bredesen, MD, is the author of the wildly popular New York Times bestselling book, The End of Alzheimer’s: The First Program to Prevent and Reverse Cognitive Decline. He has developed a groundbreaking plan to prevent and reverse Alzheimer’s disease that fundamentally changes how we understand cognitive decline. Dr. Bredesen is Professor at UCLA and was founding President and CEO of the Buck Institute for Research on Aging. If you or anyone you love has ever feared dementia, you need to hear what Dr. Bredesen has discovered.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        bio: [0]
      }
    },
    {
      id: 149,
      name: "Dale Bredesen, MD — The End of Alzheimer's - Downloads List",
      title: "Dale Bredesen, MD — The End of Alzheimer's - Downloads List",
      slug: 'downloadslist-one',
      type: 'downloadsList',
      data: null,
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 155,
          name: "Dale Bredesen, MD — The End of Alzheimer's - 2018 - Audio",
          title: "Dale Bredesen, MD — The End of Alzheimer's - Audio",
          slug: 'dale-bredeson-2018-interview',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/mp3/Dale_Bredesen_MD_The_End_Of_Alzheimer_s-2018.mp3',
            text: 'Interview Audio',
            type: 'MP3'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        },
        {
          id: 156,
          name: "BC18 - Dale Bredesen, MD — The End of Alzheimer's - Transcript",
          title: "Dale Bredesen, MD — The End of Alzheimer's - Transcript",
          slug: 'bc18-dale-bredeson-transcript',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/bc18/WLC-brain_power_collection_bredesen_md-20180924.pdf',
            text: 'Transcript',
            type: 'PDF'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        },
        {
          id: 157,
          name: "BC18 - Dale Bredesen, MD — The End of Alzheimer's - Checklist",
          title: "Dale Bredesen, MD — The End of Alzheimer's - Checklist",
          slug: 'bc18-dale-bredeson-checklist',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/bc18/WLC-checklist_alzheimers_bredesen-20180912.pdf',
            text: 'Checklist',
            type: 'PDF'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0, 1, 2]
      }
    }
  ],
  childrenIndexes: {
    audioInterview: [0],
    downloadsList: [1]
  }
};
