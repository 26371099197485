/* eslint-disable max-len */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {actions} from 'modules/user';
import ChecklistItem from './components/ChecklistItem';
import {Container} from 'reactstrap';
import styles from 'styles/index.scss';
import history from 'store/history';

//MODAL stuff
import CommunityGuidelines from 'routes/Products/components/Onboard/components/WLCV2/components/CommunityGuidelines';
import SurveyPaged from 'routes/Products/components/SurveyPaged';
import UserAvatar from 'routes/Products/components/Onboard/components/WLCV2/components/UserAvatar';
import UserInfo from 'routes/Products/components/Onboard/components/WLCV2/components/UserInfo';
import Video from 'routes/Products/components/Video';
// import VerticalSpacer from 'components/VerticalSpacer';
import {Button, Col, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row} from 'reactstrap';

const ROOT_URL = process.env.ROOT_URL;

const {checklistContainer, checklistContainerReadOnly, checklistItemsStyles, checklistTitle} = styles;

//RECEIVES
//checklist content from WLCOnboardContentItem.Children[checklist]
//keep track of internal state
//USER Data
//POST UserData state with this.props.post(this.id)
//GET this.props.get(this.id);  //id in content item of checklist (i think?)

//this updates the checklist state
//updates UserData for this checklist
class Checklist extends PureComponent {
  constructor(props) {
    super(props);

    // const {
    //   data: {checklistItems},
    //   id
    // } = props; //Where?  WLCOnboardContentItem.Children[checklist],  I belive this is attached to the WLC content item

    //GETTING the equivalent of the WLCOnboard content (id = 5)
    // const wlcOnboardListID2 = props.Children
    //   //First get the list of onboard items - originally wlc and lifestyle
    //   .filter(contentItem => contentItem.type === 'onboardList');
    const wlcOnboardListID2 = this.props.onboardList;
    const onboardListID5 = wlcOnboardListID2[0]
      ? wlcOnboardListID2[0].Children.sort(a => a.slug !== 'wlc-onboard')
      : [];
    // const sortedOnboardList = onboardList.sort((a, b) => a.data.order - b.data.order);
    this.onboardList = onboardListID5 || [];

    //No items are active
    this.onboardList.map((onboardItem, i) => {
      onboardItem.active = false;
      onboardItem.queuePosition = i; // same as index value in the array, for convenience below
    });
    ///Passing WLCOnboarding content item so checklist can manage modals
    this.Children = {};
    const initialState = {};

    // TODO, pass in props the check slug or id we want
    const checklistOnboard = this.props.onboardList[0].Children.find(item => item.slug === 'wlc-onboarding-checklist');
    console.log('checkListOnboard in ChecklistOnboarding ', checklistOnboard);
    //id is contentItem id for the WLC.onboardlist.onboard.checklist
    this.id = checklistOnboard.id;

    const initialValues = {};
    //GET initial values of each checklist item, this is just a boolean completed/not completed
    //TODO, why not children instead of data?
    checklistOnboard.data.checklistItems.map(checklistItem => {
      initialValues[checklistItem.name] = false;
    });

    //TODO what to do when all checklist items are finished?

    //originally props.WLCOnboard is:
    //WLC.Children[onboardList].Children[onboard]  that onboard type is the WLConboard
    //Data massaging i think, pulled from WLConboarding
    //props.Children.find(item => item.type === 'onboardlist').Children.find(item => item.type === "onboard")
    //TODO figure out how to remove this index here
    const onBoard = this.props.onboardList[0].Children;
    console.log('onboard 1', onBoard);
    onBoard.map(child => {
      this.Children[`${child.type}`] = child;
      initialState[child.id] = false; //if they are all false, why do we need an initial state?
    });

    //
    //
    this.modalContentIds = [
      this.props.onboardList[0].Children.find(item => item.type === 'surveyPaged').id,
      'user-info',
      'user-avatar',
      this.Children.markdown.id
    ];
    //this.state = {queuePosition: 0, onboardList: this.onboardList};

    this.checklistItems = checklistOnboard.data.checklistItems ? checklistOnboard.data.checklistItems : [];
    // this will be rewritten if there is stored data
    this.state = {
      start: {
        timeCompleted: null,
        values: initialValues
      },
      end: {
        timeCompleted: null,
        values: initialValues
      },
      //all onboard stuff below
      showGeneralModal: false,
      modalContentBodiesIndex: null, //the current modal to be show
      enableVideoNextButton: true
    };
  }

  componentDidMount() {
    if (!this.props.test) {
      this.getChecklistResponses();
    }
  }

  //Getting USER DATA for the checklist, this.id = id for checklist
  async getChecklistResponses() {
    //gets userData for this checklist  TODO, name it more descriptively than '.get'

    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({...savedData, showGeneralModal: false}); //showGenral always starts off as false, must be user iniatiated click
      // optional - only if parent needs updating on complete, ie onboard
      if (savedData.complete && this.props.handleComplete) {
        this.props.handleComplete(this.id);
      }
    } else {
      // No saved values - do something?
    }
  }

  //TODO, question: not sure if this is actually used
  isStartOrEnd = () => {
    if (!this.state.start.timeCompleted) {
      return 'start';
    }
    return 'end';
  };

  //this is how WLConboard handles it, just keeping it here to parallel it now, TODO remove
  //and just use markCompletByIndex
  handleOnboardComponentUpdate = id => {};
  //gets id of modalbody content item, from WLConboarding content item
  //then we translate to the local index of the modalContentBodies array
  //Automatically check completed if called, so modal has to only make the 'finished' after its task is completed
  handleModalFinishedButton = id => {
    const idx = this.modalContentIds.findIndex(item => item === id);
    //update local state
    //update the UserData state for this checklist
    //close modal

    //1.update local state
    // const {
    //   data: {checklistItems}
    // } = this.props;
    const checklistItems = this.checklistItems;
    const name = checklistItems[idx].name;
    const newState = {
      ...this.state,
      [this.isStartOrEnd()]: {
        ...this.state[this.isStartOrEnd()],
        //values: {...this.state[this.isStartOrEnd()].values, [name]: !this.state[this.isStartOrEnd()].values[name]}
        //set this task to true automatically
        values: {...this.state[this.isStartOrEnd()].values, [name]: true}
      }
    };
    //2.update the UserData state for this checklist
    this.setState({...newState, showGeneralModal: false}, () => {
      //TODO this is not working for some reason
      this.props.post(this.id, newState);
    });
  };

  toggleGeneralModal = () => {
    this.setState({showGeneralModal: !this.state.showGeneralModal});
  };

  //This updates the checklist state.
  //if Link, takes user to Link if the checklist item is visit a link
  //if Modal, opens modal.  Then modal calls
  //updates UserData for this checklist
  //
  updateValue = (name, link = null, modalContentBodiesIndex = null, e) => {
    if (e) {
      e.preventDefault();
    }
    //if we have an index for the ModalContentBodies array
    if (Number.isInteger(modalContentBodiesIndex)) {
      //open of the modal
      this.setState({showGeneralModal: true, modalContentBodiesIndex});
      return;
    }
    //TODO figure out if needed
    //if link, go there
    if (e && e.target && e.target.href) {
      window.location = e.target.href;
    }
    //tracking the start and end of time to complete, not sure why TODO figure out
    else {
      //FINALLY : if this was a link checklist item, go to that link
      //check if link, navigat there, TOOD use router
      const newState = {
        ...this.state,
        [this.isStartOrEnd()]: {
          ...this.state[this.isStartOrEnd()],
          values: {...this.state[this.isStartOrEnd()].values, [name]: !this.state[this.isStartOrEnd()].values[name]}
        }
      };
      // optional - if this is an onboard
      //CHECKING IF CHECKLIST IS COMPLETE, by going through each 'value' (todo rename to 'complete' or something)
      if (typeof this.props.handleComplete === 'function') {
        let isComplete = true;
        const fields = newState.start.values;
        //CHECKING IF CHECKLIST IS COMPLETE,
        for (let field in fields) {
          if (!fields[field]) {
            isComplete = false;
            break;
          }
        }
        //if full checklist is complete, then call method to handle that
        if (isComplete) {
          this.props.handleComplete(this.id);
          newState.complete = true;
        }
      }

      this.setState(newState, () => {
        //this is user data post - TODO rename to something like postUserState
        this.props.post(this.id, newState);
        //WE want to automatically update as complete
        //and then navigate to link if there is one
        if (link) {
          window.location = link;
        }
      });
    }
  };

  render() {
    //TODO const
    let {
      title,
      overrideTitle,
      readonly
      // data: {checklistItems}
    } = this.props;
    let checklistItems = this.checklistItems;
    //MODAL info pulled from WLConboarding content item via props
    const {checklist, markdown, surveyPaged, video} = this.Children;

    const {values} = this.state.start ? this.state[this.isStartOrEnd()] : {};
    let classes = [checklistContainer];

    if (readonly) {
      classes.push(checklistContainerReadOnly);
    }

    //PLACING THIS HERE FOR NOW, TODO: refactor

    //Pages:
    //Video - the onboarding video
    //SurveyPage - the assessement of the user's health
    //UserInfo - the edit user profile component
    //UserAvatar - upload of a pic
    //Guidelines - the text of guidelines for using the forum
    const modalContentBodies = [
      {
        title: 'Orientation Survey',
        component: (
          <SurveyPaged
            handleIncomplete={this.props.handleIncomplete}
            {...surveyPaged}
            handleComplete={() => this.handleModalFinishedButton(surveyPaged.id)}
          />
        )
      },
      {
        title: 'User Profile',
        component: (
          <div>
            <UserInfo handleComplete={() => this.handleModalFinishedButton('user-info')} />
          </div>
        )
      },
      {
        title: 'User Profile Picture',
        component: <UserAvatar handleComplete={() => this.handleModalFinishedButton('user-avatar')} />
      },
      {
        title: 'Community Guidelines',
        component: (
          <CommunityGuidelines handleComplete={() => this.handleModalFinishedButton(markdown.id)} {...markdown} />
        )
      }
    ];
    const {showGeneralModal} = this.state;

    return (
      <div>
        {/* show a general modal and then populate it content associated with the clicked checklist item */}
        {this.state.showGeneralModal ? (
          <Modal isOpen={true} size="lg" centered toggle={this.toggleGeneralModal}>
            <ModalHeader>{modalContentBodies[this.state.modalContentBodiesIndex].title}</ModalHeader>
            <ModalBody>
              {/* TODO the index must correspond to correct modal content for this checklist item,
                Right now, no easy way to do this but hard code it in
             */}
              {modalContentBodies ? modalContentBodies[this.state.modalContentBodiesIndex].component : null}
            </ModalBody>
          </Modal>
        ) : null}
        <div className={`Container checklistContainer`}>
          <h5>{overrideTitle || 'Checklist'}</h5>
          <div className={checklistItemsStyles}>
            {/* -NEED to add a trigger to show a modal
            -Or a link to another page
         */}
            {checklistItems && values
              ? checklistItems.map((checklistItem, index) => {
                  checklistItem.data.url = checklistItem.data.url || '/products';

                  return (
                    <ChecklistItem
                      key={checklistItem.name}
                      isLast={index === checklistItems.length - 1}
                      value={values[checklistItem.name] || false}
                      updateValue={!readonly ? this.updateValue : () => null}
                      post={this.postChecklistResponses}
                      key={checklistItem.name}
                      modal={null}
                      modalBodyIndex={index}
                      {...checklistItem}
                      callBack
                    />
                  );
                })
              : 'No Values for checklist TODO'}
          </div>
        </div>
      </div>
    );
  }
}

Checklist.propTypes = {
  data: PropTypes.shape({}).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.number,
  post: PropTypes.func.isRequired,
  test: PropTypes.bool,
  title: PropTypes.string.isRequired
};

Checklist.defaultProps = {
  id: 88888888,
  test: false
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export const ConnectedChecklist = connect(null, mapDispatchToProps, null, {forwardRef: true})(Checklist);

export default ConnectedChecklist;
