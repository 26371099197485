const article = {
  type: 'article',
  name: 'Article Name',
  slug: 'article-1',
  title: 'Article Title',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Article One',
      //
      text:
        'Ipsum lorem data.thumb.text Here you’ll find delicious, nutritious, easy to prepare recipes added for each session of the course.'
    },
    // Used for the 'header' image on the page
    img: 'http://via.placeholder.com/350x150',
    author: 'Will Yamesh Achspear',
    authorImg: 'http://via.placeholder.com/350x150',
    authorUrl: 'https://www.shakespeare.org.uk/',
    alt: 'Will Yamesh Achspear',
    markdown: '# Marked in the browser\n\nRendered by **marked**.'
  }
};

export default article;
