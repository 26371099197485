/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Col, Container, Row, Modal} from 'reactstrap';
import {actions} from 'modules/user';
import {actions as checkoutActions} from 'modules/checkout';
import Address from './components/Address';
import AddressModal from './components/AddressModal';
import GenericAddressForm from 'components/Forms/Address';

import {addressesAddNewButton, addressAddNewButtonBox, addressesContainer} from 'styles/index.scss';

@connect(
  state => ({
    user: state.user
  }),
  {
    addAddress: actions.addAddress,
    deleteAddress: actions.deleteAddress,
    loadAddresses: actions.loadAddresses,
    updateAddress: actions.updateAddress,
    loadProfile: actions.loadProfile,
    setDefaultAddress: actions.setDefaultAddress,
    setNewAddress: checkoutActions.setNewAddress
  }
)
class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: false,
      editAddressIdx: null
    };
  }

  componentDidMount() {
    this.props.loadAddresses();
  }

  handleAddressFieldChange = updatedValues => {
    return this.setState({newAddress: {...this.state.newAddress, ...updatedValues}});
  };

  handleAddressValidity = allFieldsValid => {
    this.setState({validNewAddress: allFieldsValid});
  };

  toggleNewAddressModal = () => {
    this.setState({showAddressModal: !this.state.showAddressModal});
  };

  handleNewAddressButtonClick = () => {
    this.setState({showAddressModal: true, editAddressIdx: null});
  };

  makeAddressDefault = (address, type) => {
    this.props.setDefaultAddress(address, type).then(confirmation => {
      if (confirmation) {
        this.props.loadAddresses();
      }
    });
  };

  deleteAddress = (address, setState) => {
    this.props.deleteAddress(address).then(didUpdate => {
      if (didUpdate) {
        this.props.loadAddresses();
      }
      this.setState({showAddressModal: false});
    });
  };

  addOrUpdateAddress = address => {
    (address.id ? this.props.updateAddress(address) : this.props.addAddress(address)).then(didUpdate => {
      if (didUpdate) {
        this.props.loadAddresses();
        this.setState({showAddressModal: false});
      }
    });
  };

  showAddressModal = (idx = null) => {
    this.setState({editAddressIdx: idx, showAddressModal: true});
  };

  render() {
    const {addresses, DefaultBillingAddress, DefaultShippingAddress} = this.props.user;
    const DefaultShippingAddressId = DefaultShippingAddress ? DefaultShippingAddress.id : -1;
    const DefaultBillingAddressId = DefaultBillingAddress ? DefaultBillingAddress.id : -1;

    let values = {country: 'US'};
    return (
      <Container fluid className="p-0">
        <Row>
          {addresses.length ? null : (
            <div style={{marginBottom: '8px'}}>
              <span>No addresses saved</span>
            </div>
          )}

          {addresses.map((address, index) => {
            const number = index + 1;
            return (
              <Col className="mb-3" xs="12" md="6" lg="4">
                <Address
                  key={`${address.id || number}`}
                  index={index}
                  address={address}
                  number={number}
                  isDefaultBilling={address.id === DefaultBillingAddressId}
                  isDefaultShipping={address.id === DefaultShippingAddressId}
                  setDefaultAddress={this.props.setDefaultAddress}
                  makeAddressDefault={this.makeAddressDefault}
                  loadAddresses={this.props.loadAddresses}
                  deleteAddress={this.deleteAddress}
                  loadProfile={this.props.loadProfile}
                  showAddressModal={this.showAddressModal}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <Button color="purple" onClick={this.handleNewAddressButtonClick}>
              Add a new address
            </Button>
          </Col>
          <AddressModal
            isOpen={this.state.showAddressModal}
            address={
              this.state.editAddressIdx !== null
                ? addresses[this.state.editAddressIdx]
                : {name: `${this.props.user.firstName} ${this.props.user.lastName}`, country: 'US'}
            }
            toggle={this.toggleNewAddressModal}
            addOrUpdateAddress={this.addOrUpdateAddress}
          />
        </Row>
      </Container>
    );
  }
}

Addresses.propTypes = {
  addAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  loadAddresses: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  setDefaultAddress: PropTypes.func.isRequired,
  user: PropTypes.shape({
    addresses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    DefaultShippingAddress: PropTypes.string.isRequired,
    DefaultBillingAddress: PropTypes.string.isRequired
  }).isRequired
};

export default Addresses;
