import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faBookmark as faBookmarkRegular} from '@fortawesome/pro-regular-svg-icons';
import {faBookmark as faBookmarkRegular} from '@fortawesome/pro-solid-svg-icons/faBookmark'; // Solid bookmark
import {faBookmark as faBookmarkLight} from '@fortawesome/pro-light-svg-icons/faBookmark';

import {connect} from 'react-redux';
import {actions} from 'modules/user';
/*
PATCH: Assuming the patchUserData endpoint will take a new item in favs array and concat it
DELETE:  Enabled via patchUserData with additional key/value-{operation:"remove"}.  Every item in the array payload will be removed from userData.favs array
*/

@connect(
  state => ({
    user: state.user
  }),
  {
    userData: actions.getUserData,
    postUserData: actions.postUserData,
    patchUserData: actions.patchUserData
  }
)
class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorited: false
    };

    this.handlePatchFavs = this.handlePatchFavs.bind(this);
    this.handleDeleteUserData = this.handleDeleteUserData.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.patchThis = this.patchThis.bind(this);
    this.FAVORITES_ID = process.env.FAVORITES_ID;
  }

  componentDidMount() {
    this.getUserData({contentItemId: this.props.contentItem.id}); //determine if this content is already favorited
  }
  getUserData({contentItemId}) {
    const data = this.props.userData(contentItemId);
    data.then(userState => {
      if (!userState) {
        return;
      }
      const {isFavorited} = userState;
      this.setState({isFavorited: !!isFavorited});
    });
  }

  toggleBookmark = () => {
    this.setState(
      prevState => ({
        isFavorited: !prevState.isFavorited
      }),
      () => {
        this.props
          .patchUserData(this.props.contentItem.id, {isFavorited: this.state.isFavorited})
          .then(res => {
            console.log('res in patchUserData ', res, 'content item ', this.props.contentItem.id);
          })
          .catch(err => {
            console.log('err ************ ', err);
          });
        if (this.state.isFavorited) {
          this.handlePatchFavs();
        } else {
          //delete the this favorite from the user data (uses PATCH)
          this.handleDeleteUserData();
        }
      }
    );
  };

  handlePatchFavs() {
    const {contentItem, patchUserData} = this.props;
    const patchState = {
      favs: [
        {
          id: contentItem.id,
          slug: contentItem.slug,
          ...contentItem
        }
      ]
    };
    this.patchThis(this.FAVORITES_ID, patchState);
  }

  handleDeleteUserData() {
    const {contentItem} = this.props;
    const patchState = {
      operation: 'remove',
      favs: [
        {
          id: contentItem.id,
          slug: contentItem.slug,
          ...contentItem.displayProps
        }
      ]
    };
    this.patchThis(this.FAVORITES_ID, patchState);
  }
  patchThis(id, patchState) {
    this.props
      .patchUserData(id, patchState)
      .then(res => {
        console.log('res in postUserData ', res, 'content item ', contentItem.id);
      })
      .catch(err => {
        console.log('err ************ ', err);
      });
  }

  render() {
    const {isFavorited} = this.state;
    return (
      <div
        onClick={this.toggleBookmark}
        className="d-flex align-items-center justify-content-start justify-content-lg-end"
        style={{cursor: 'pointer'}}
      >
        <FontAwesomeIcon
          icon={isFavorited ? faBookmarkRegular : faBookmarkLight}
          size="2x"
          style={{color: isFavorited ? '#048D22' : 'gray'}}
        />
        <span style={{marginLeft: '8px', fontSize: '2rem'}}>
          {isFavorited ? 'Saved to Favorites' : 'Add to Favorites'}
        </span>
      </div>
    );
  }
}

export default Favorites;
