import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, Row, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RecipeCard from '../RecipeCard';

import styles from 'styles/index.scss';
import ErrorBoundary from 'components/ErrorBoundary';

const {recipesGridContainer, recipesGridHeader, recipesGridBig, recipesGridSmall} = styles;

class RecipesGrid extends PureComponent {
  constructor(props) {
    super(props);

    // give this component a recipes array or destructure a recipesList into it
    // if destructured, the Children become the recipes array;
    this.recipes = props.recipes || props.Children;
    this.state = {
      search: null,
      filterTerm: null,
      recipes: this.recipes,
      filtered: false,
      nav: 'recipesGrid', //display grid or individual recipe
      currentContent: null, //holds the current single recipe
      disableRouter: props.disableRouter //should the recipe cards navigate with router,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

    this.searchTimeout = null;
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
  }

  componentDidMount() {
    const {recipes} = this;
    //Check if there is being loaded from a URL for a single recipe
    if (this.props.singleRecipeInUrl) {
      //then navigate to a single recipe
      const singleRecipeInUrl = recipes.find(r => r.slug === this.props.singleRecipeInUrl);
      this.setState({nav: 'singleRecipe', currentContent: singleRecipeInUrl});
    }
  }

  filterRecipes() {
    const {recipes} = this;
    const {search} = this.state;
    if (!search || search === '') {
      return this.clearSearch();
    }
    this.setState({
      recipes: recipes.filter(r => r.title.search(new RegExp(search, 'i')) > -1),
      filtered: true,
      filterTerm: search
    });
  }

  handleSearchChange(e) {
    this.setState({search: e.target.value});
    // A little bit of debouncing, to help with performance on slower machines, may not be necessary
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(this.filterRecipes.bind(this), 200);
  }

  clearSearch() {
    this.setState({search: '', filterTerm: '', filtered: false, recipes: this.recipes});
  }

  //allows for navigating to single recipe or back to grid
  handleNav = (e, content, nav) => {
    e.preventDefault();
    this.setState({nav: nav, currentContent: content});
  };

  handleNavRecipesGrid = (name, component, slug) => {
    //for navigating to a single recipe
    if (slug) {
      const path = `/products/${this.thisProductInPath}/${slug}`;

      this.props.history.push(path);
    }
  };

  render() {
    const {recipes, search, filtered, filterTerm} = this.state;
    const title = this.props.customTitle;
    const colSizes = this.props.colSizes || {xs: 12, sm: 6, md: 4};

    return (
      <Container>
        <Col>
          <Row className="mb-5 pt-5">
            <Col xs="auto" md="9">
              <h5>{title}</h5>
            </Col>
            <Col xs="12" md="3">
              <InputGroup>
                <Input
                  type="text"
                  value={search}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchChange}
                  onKeyPress={this.handleSearchChange}
                  placeholder="Search Recipes..."
                  className="border-right-0"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="bg-transparent">
                    <FontAwesomeIcon icon={['far', 'search']} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          {filtered && recipes.length ? (
            <Row className="px-0 mb-3">
              <Col xs="12">{`${recipes.length} recipe${recipes.length === 1 ? '' : 's'} found.`}</Col>
            </Row>
          ) : null}
          <Row style={{padding: 0}} className={recipesGridContainer}>
            {recipes.length ? (
              recipes
                .sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1))
                .map(recipe => (
                  <Col {...colSizes}>
                    <RecipeCard
                      key={recipe.id}
                      disableRouter={this.state.disableRouter}
                      {...recipe}
                      className="h-100"
                    />
                  </Col>
                ))
            ) : (
              <Col>
                <h5>No recipes found{filterTerm ? ` with '${filterTerm}' in the title` : ''}.</h5>
                {filtered ? (
                  <Button color="link" onClick={this.clearSearch} className="px-0">
                    Clear search
                  </Button>
                ) : null}
              </Col>
            )}
          </Row>
        </Col>
      </Container>
    );
  }
}

RecipesGrid.propTypes = {
  customTitle: PropTypes.string.isRequired,
  recipes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default RecipesGrid;
