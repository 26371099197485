/* eslint-disable */
import React, {Component} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';

class CTA extends Component {
  state = {processing: false};
  render() {
    const {amount, handleClickUpsell, handleDeclineUpsell, light} = this.props;
    return (
      <Row className="d-flex justify-content-center">
        <Col xs="12" lg="10" xl="9" className={`text-center${light ? '' : ' text-white'}`}>
          <p>
            <Button
              color="orange"
              className="text-700 text-h6 text-uppercase text-white btn-upsell-yes w-100"
              onClick={e => {
                this.setState({processing: true});
                handleClickUpsell(e);
              }}
              style={{width: '66%'}}
              disabled={this.state.processing}
            >
              Yes, I want to add this to my order!
            </Button>
          </p>
          <p>
            Clicking "Yes" above will add The Impact Kit to your purchase now, for an additional ${parseInt(amount)}.
          </p>
          <p>
            <strong>Your purchase comes with a risk-free, 60-day, money-back guarantee.</strong>
          </p>
          <p className="mb-4">
            <Button
              color="light-gray"
              className="text-600 text-small btn-upsell-no"
              onClick={e => {
                this.setState({processing: true});
                handleDeclineUpsell(e);
              }}
              style={{width: '33%'}}
              disabled={this.state.processing}
            >
              No, thanks.
            </Button>
          </p>
          <p>Clicking the "No" button above will take you to the products already in your cart.</p>
        </Col>
      </Row>
    );
  }
}
export default CTA;
