import React from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import cc from 'images/secure-checkout.png';
import logoTrees from 'images/secure-checkout.png';
import classNames from 'classnames';

class UpsellSales extends React.Component {
  state = {processing: false};
  handleSuccess = e => {
    this.setState({processing: true});
    this.props.handleClickUpsell(e);
  };
  handleDecline = e => {
    this.setState({processing: true});
    this.props.handleDeclineUpsell(e);
  };
  render() {
    const {
      props: {excludeNoButton = false, noButton = null, ...props},
      state: {processing}
    } = this;
    const isMini = props.variant === 'mini';
    const addButtonText = props.addButtonText;
    const offerBox = classNames(
      'offer-box text-center',
      props.offerBoxPadding,
      props.offerBoxBackground,
      props.offerBoxBorder
    );

    return isMini ? (
      <div className={props.className}>
        <Button
          className="offer-box-button offer-box-button-upsell"
          size="lg"
          color="cta"
          onClick={this.handleSuccess}
          disabled={processing}
        >
          {processing ? (
            'Loading...'
          ) : addButtonText ? (
            addButtonText
          ) : (
            <React.Fragment>
              <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
            </React.Fragment>
          )}
        </Button>
        {props.text ? <div className="upsell-box-text">{props.text}</div> : null}

        {!excludeNoButton
          ? noButton || (
              <div className="upsell-box-no">
                <div className="mt-3 mb-3">
                  <Button className="btn-upsell-gray" onClick={this.handleDecline} disabled={processing}>
                    {processing ? 'Loading...' : 'No, thanks.'}
                  </Button>
                </div>
                <div className="upsell-box-no-text">
                  {props.upsellNoText ? (
                    props.upsellNoText
                  ) : (
                    <React.Fragment>
                      Clicking the “No” button above will take you to the products you just got.
                    </React.Fragment>
                  )}
                </div>
              </div>
            )
          : null}

        <div className="offer-box-details">
          <div className="offer-box-cards">
            <img
              className="img-fluid img-cc"
              src="https://cdn.foodrevolution.org/checkout/assets/images/acceptance-marks.png"
            />
          </div>
          <div className="offer-box-security">
            <FontAwesomeIcon className="offer-box-security-icon mr-2" icon={faLock} />
            For your security, all orders are processed on a secured server.
          </div>
          <div className="offer-box-protected d-flex justify-content-center">
            Your purchase is protected by our 60-day money back guarantee.
          </div>
        </div>
      </div>
    ) : (
      <div className={classNames(`cta-sales cta-sales-${props.className}`)}>
        <div className={offerBox}>
          {props.heading ? <div className="upsell-box-heading">{props.heading}</div> : null}
          <p>
            {props.subHeading ? (
              props.subHeading
            ) : (
              <i>This offer is ONLY available from this page, so please don’t hit refresh.</i>
            )}
          </p>

          {props.imgSrc ? (
            <Row className="d-flex justify-content-center my-4">
              <Col lg="10">
                <img
                  className={props.imgClass}
                  src={props.imgSrc}
                  width={props.imgWidth}
                  height={props.imgHeight}
                  alt={props.imgAlt}
                />
              </Col>
            </Row>
          ) : null}
          {props.normalPrice ? (
            <h2 className="mt-3 mb-0">
              <del>${props.normalPrice}</del>
            </h2>
          ) : null}
          {props.showPriceBig ? <h2 className="text-green text-h1 mt-0 mb-3">Now only: ${props.price}</h2> : null}

          <div className="upsell-box-confirm">
            <div className="mb-3">
              <Button
                className="offer-box-button offer-box-button-upsell"
                size="lg"
                color="cta"
                onClick={this.handleSuccess}
                disabled={processing}
              >
                {processing ? (
                  'Loading...'
                ) : addButtonText ? (
                  addButtonText
                ) : (
                  <React.Fragment>
                    <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
                  </React.Fragment>
                )}
              </Button>
            </div>
            {props.text ? <div className="upsell-box-text">{props.text}</div> : null}
          </div>

          <div className="upsell-box-no">
            <div className="mb-3">
              <Button className="btn-upsell-gray" onClick={this.handleDecline} disabled={processing}>
                {processing ? 'Loading...' : 'No, thanks.'}
              </Button>
            </div>
            <div className="upsell-box-no-text">
              {props.upsellNoText ? (
                props.upsellNoText
              ) : (
                <React.Fragment>
                  Clicking the “No” button above will take you to the products you just got.
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="offer-box-details">
            <div className="offer-box-cards">
              <img
                className="img-fluid img-cc"
                src="https://cdn.foodrevolution.org/checkout/assets/images/acceptance-marks.png"
              />
            </div>
            <div className="offer-box-security">
              <FontAwesomeIcon className="offer-box-security-icon mr-2" icon={faLock} />
              For your security, all orders are processed on a secured server.
            </div>
            <div className="offer-box-protected d-flex justify-content-center">
              Your purchase is protected by our 60-day money back guarantee.
            </div>
          </div>
        </div>

        {props.noTrees ? null : (
          <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
            <Col xs="11" lg="8" className="mb-4 mb-lg-0">
              <h6 className="section-only-heading text-center text-lg-left text-400">
                For every new order, we make a donation to Trees for the Future, enabling them to plant another organic
                fruit or nut tree in a low-income community.
              </h6>
            </Col>
            <Col xs="11" lg="3" className="text-center">
              <img
                className="logo-trees"
                src="https://cdn.foodrevolution.org/checkout/assets/images/logo-trees-for-future-white.png"
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

UpsellSales.defaultProps = {
  offerBoxBackground: 'background-white',
  offerBoxBorder: 'border-dashed border-green border-2 offer-box-shadow',
  offerBoxPadding: 'px-3 py-4 px-sm-4 px-md-5 py-md-5'
};

export default UpsellSales;
