import React from 'react';
import PropTypes from 'prop-types';
import Accordion from './components/Accordion';

const AccordionPage = props => {
  const {profileSections} = props;

  return (
    <div>
      {profileSections.map((section, i) => (
        <Accordion key={section.title} isLast={i === profileSections.length - 1} {...section} />
      ))}
    </div>
  );
};

AccordionPage.propTypes = {
  profileSections: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default AccordionPage;
