//TNTG is basically a clone of the PPT
//So it will be using all of PPT except the index because there are some userState considerations
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import Dashboard from '../PPTv2/pages/Dashboard';
import ModulePage from '../PPTv2/pages/ModulePage';
import BonusesPage from '../PPTv2/pages/BonusesPage';
import testData from '../TNTG/data/hhc/hhcData'; // './data/testData';
import ProductSidebar from 'components/ProductSidebar';

import {faStar} from '@fortawesome/pro-regular-svg-icons/faStar';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import BreadcrumbWrapper from '../../../../components/BreadCrumbWrapper';
import Recipe from '../../components/Recipe';
/*  content item in staging
{
    "free": false,1
    "id": 7147,
    "name": "The Need To Grow",
    "title": "The Need To Grow",
    "slug": "tntg-content",
    "type": "tntg",
    "data": {
        "description": "The Need To Grow package"
    },
    "published": true,
    "updatedAt": "2019-09-24T19:42:40.456Z",
    "createdAt": "2019-09-24T19:42:40.456Z"
}
*/

@connect(
  state => ({
    user: state.user
  }),
  {
    getUserData: actions.getUserData,
    post: actions.postUserData
  }
)
class ModuleProduct extends Component {
  constructor(props) {
    super(props);
    this.product = this.props;

    this.handleNav = this.handleNav.bind(this);
    this.module = this.product ? this.product.Children.find(item => item.type === 'module') : null;
    this.bonuses = this.product ? this.product.Children.find(item => item.type === 'bonuses') : null;
    this.recipeList = this.module.Children.find(item => item.type === 'recipesList');
    const thisPath = location.pathname.split('/');
    this.productInPath = thisPath[2];

    this.moduleHeaderTitle = 'The Need To Grow Package';

    this.navItems = [{name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome}]; //used to show what nav items are present in top};
  }

  handleNav(name, typeOfComponent, slug) {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* <Dashboard product={product} moduleStates={moduleStates} /> */}
        {/* TODO create seperate route for each */}
        <ProductSidebar navItems={this.navItems} handleNav={this.handleNav}>
          <Switch>
            {this.product ? (
              <Route
                key={this.product.id}
                exact
                path={`/products/${this.productInPath}/`}
                render={() => (
                  <ModulePage
                    product={this.product}
                    //  moduleState={moduleStates.find(item => item.id === module.id)}
                    updateModuleStates={this.updateModuleStates}
                    isUnstructured={true}
                    isModuleProduct={true}
                  />
                )}
              />
            ) : null}
            {this.module ? (
              <Route
                key={this.module.id}
                exact
                path={`/products/${this.productInPath}/${this.module.slug}`}
                render={() => (
                  <ModulePage
                    product={this.product}
                    // moduleState={moduleStates.find(item => item.id === module.id)}
                    updateModuleStates={this.updateModuleStates}
                    isUnstructured={true}
                  />
                )}
              />
            ) : null}

            {/* ROUTES FOR RECIPES  */}

            {this.recipeList
              ? this.recipeList.Children.map(recipe => {
                  return (
                    <Route
                      key={recipe.title}
                      exact
                      path={`/*/${recipe.slug}/`}
                      // component={Data(Recipe, `/content/${recipe.id}/${this.productInPath}`)}
                      render={() => {
                        return (
                          <BreadcrumbWrapper
                            productTitle={this.product.title}
                            productInPath={this.productInPath}
                            linkTos={[
                              // { to: recipe.moduleSlug, title: recipe.moduleTitle },
                              {to: recipe.slug, title: recipe.title}
                            ]}
                          >
                            <Recipe {...recipe} />
                          </BreadcrumbWrapper>
                        );
                      }}
                    />
                  );
                })
              : null}

            {/* {this.bonuses ? (
              <Route
                key={this.bonuses.id}
                exact
                path={`/products/${this.productInPath}/bonuses`}
                render={() => <BonusesPage isUnstructured={true} product={product} bonuses={this.bonuses} />}
              />
            ) : null} */}
          </Switch>
        </ProductSidebar>
      </div>
    );
  }
}

export default withRouter(ModuleProduct);
