import React, {useCallback, useEffect} from 'react';
import ReactPlayer from 'react-player';
import {Button, Modal} from 'reactstrap';
import {checkBreakpoint} from 'lib/getResponsive';
import {faXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
const AudioModal = ({toggleModal, showModal, audioUrl, displayProps}) => {
  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        toggleModal();
      }
    },
    [toggleModal]
  );
  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', escFunction, false);
      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    }
  }, [showModal]);
  return (
    <Modal isOpen={showModal} toggle={toggleModal} className="videoModal audioModal">
      <Button onClick={toggleModal} color="link" className="videoModalClose">
        <FontAwesomeIcon icon={faXmark} /> Close
      </Button>
      <div
        className="d-flex align-items-center "
        style={{height: '100%', backgroundColor: '#222', aspectRatio: '16/9'}}
      >
        {checkBreakpoint() == 'mobile' ? (
          <div>
            <img
              src={displayProps.image.url}
              alt={displayProps.title}
              style={{height: '100%', width: '100%', zIndex: 0, objectFit: 'cover'}}
            />
            <div>
              <ReactPlayer url={audioUrl} width="100%" height="50px" controls={true} playing={true} />
            </div>
          </div>
        ) : (
          ''
        )}

        {checkBreakpoint() !== 'mobile' ? (
          <div>
            <div
              style={{
                margin: '0 auto',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }}
            >
              <img
                src={displayProps.image.url}
                alt={displayProps.title}
                style={{width: '100%', height: '100%', zIndex: 0, objectFit: 'cover'}}
              />
              <div
                style={{
                  zIndex: 1000,
                  position: 'absolute',

                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: '100%',

                  hieght: '50px'
                }}
              >
                <ReactPlayer url={audioUrl} width="100%" height="50px" controls={true} playing={true} />
              </div>
              <Button onClick={toggleModal} color="link" className="videoModalClose">
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};
export default AudioModal;
