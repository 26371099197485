import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SpecialReports from 'routes/Products/components/SpecialReports';

class SpecialReportsPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <SpecialReports {...this.props} />;
  }
}

SpecialReports.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default SpecialReportsPage;
