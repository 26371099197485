// {
//     "id": 71,
//     "name": "Heart Health Collection",
//     "requiresShippingAddress": false,
//     "slug": "hhc",
//     "ContentItemId": 21106,
//     "LandingPageItemId": null,
//     "img": null,
//     "shipRates": null,
//     "quantifiable": false,
//     "ProductClassName": null,
//     "welcomeTemplate": null,
//     "taxjarCode": "31000",
//     "taxjarFrom": null,
//     "description": "This collection of audio and video interviews, recipes, and ebooks brings you cutting edge wisdom from leading experts to help you take positive action to prevent cardiovascular disease.",
//     "updatedAt": "2021-03-10T21:01:57.030Z"
// }
//now in staging 3/22
// {
//     "id": 71,
//     "name": "Heart Health Collection",
//     "requiresShippingAddress": false,
//     "slug": "hhc",
//     "ContentItemId": 21107,
//     "LandingPageItemId": null,
//     "img": null,
//     "shipRates": null,
//     "quantifiable": false,
//     "ProductClassName": null,
//     "welcomeTemplate": "HeartHealthWelcome",
//     "taxjarCode": "31000",
//     "taxjarFrom": null,
//     "description": "This collection of audio and video interviews, recipes, and ebooks brings you cutting edge wisdom from leading experts to help you take positive action to prevent cardiovascular disease.",
//     "ProductBundleId": 70,
//     "updatedAt": "2021-03-22T16:30:22.672Z"
// }

import moduleOne from './hhcModule';

const hhc = {
	name: 'Heart Health Collection',
	title: 'Heart Health Collection',
	slug: 'hhc-content',
	type: 'tntg',
	published: true,
	free: false,

	data: {
		url: 'https://vimeo.com/364560519/43b80ab84a',
		title: 'Heart Health Collection Intro Video',
		description: 'This is the Hearth Health Collection'
	},
	Children: [ moduleOne ]
};

export default hhc;

/* staging
{
    "id": 21106,
    "name": "Heart Health Collection",
    "title": "Heart Health Collection",
    "slug": "hhc-content",
    "type": "tntg",
    "published": true,
    "free": false,
    "data": {
        "url": "https://vimeo.com/364560519/43b80ab84a",
        "title": "Heart Health Collection Intro Video",
        "description": "This is the Hearth Health Collection"
    },
    "updatedAt": "2021-02-25T22:51:47.357Z",
    "createdAt": "2021-02-25T22:51:47.357Z"
}

*/
