import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import {actions} from 'modules/user';
import Checklist from 'routes/Products/components/ChecklistOnboarding';
import CommunityGuidelines from './components/CommunityGuidelines';
import SurveyPaged from 'routes/Products/components/SurveyPaged';
import UserAvatar from './components/UserAvatar';
import UserInfo from './components/UserInfo';
import Video from 'routes/Products/components/Video';
import VerticalSpacer from 'components/VerticalSpacer';

import styles from 'styles/index.scss';

const {WLCOnboardModal, userAvatar} = styles;

//receives wlc-onboard content item via props
//content item children:
//Video
//Checklist
//Survey
//Community Guidelines
class WLCOnboardV2 extends Component {
  constructor(props) {
    super(props);
    console.log('props in WLCOnboard ', props);

    this.id = props.id;
    this.numPages = 5;
    this.Children = {};
    const initialState = {};
    props.Children.map(child => {
      this.Children[`${child.type}`] = child;
      initialState[child.id] = false; //if they are all false, why do we need an initial state?
    });

    this.pageIds = [
      this.Children.video.id,
      this.Children.surveyPaged.id,
      'user-info',
      'user-avatar',
      this.Children.markdown.id
    ];

    // This component only tracks which below components are complete
    this.state = {
      closed: false,
      page: 0,
      user: false,
      complete: false,
      completeConfirmed: false,
      congratulations: false,
      dismissedOnce: false,
      dismissedTimes: 0,
      loading: true, // this will be written over on successful data load
      neverShowAgain: false,
      showCloseConfirmModal: false,
      showChecklist: false,
      stepSkipped: this.pageIds.reduce((m, e) => {
        m[e] = false;
        return m;
      }, {}),
      ...initialState
    };
    this.checklistRef = React.createRef();
  }

  handleOnboardComponentUpdate = id => {
    const newState = {...this.state};
    const {page} = this.state;
    const idx = this.pageIds.findIndex(e => e === id);

    //This is really hacky and should really be replaced...GEFN
    if (idx >= 0) {
      this.checklistRef.current.getWrappedInstance().markCompleteByIndex(idx);
    }

    newState[id] = true;
    this.goToPage(page + 1, newState);
  };

  goToPage(page, newState) {
    const {pageIds} = this;
    const state = newState || this.state;

    if (page >= pageIds.length) {
      const complete = pageIds.reduce((acc, val) => acc && state[val], true);
      if (complete) {
        return this.saveState({...newState, page: pageIds.length - 1, complete});
      } else {
        return newState
          ? this.saveState({...newState, page: pageIds.findIndex(val => !state[val]), showChecklist: true})
          : this.setState({page: pageIds.findIndex(val => !state[val]), showChecklist: true});
      }
    }
    if (state[pageIds[page]]) {
      this.goToPage(page + 1, newState);
    } else {
      this.saveState({...newState, page: page, showChecklist: page !== 0});
    }
  }

  markIncomplete = id => {
    this.saveState({[id]: false});
    this.props.handleIncomplete(id);
  };

  async getState() {
    const {toggleActivateOnboard, goToNextOnboard, onboard} = this.props;
    const savedState = await this.props.get(this.id);
    const updateComplete = savedState && savedState.complete && !savedState.completeConfirmed;
    const loading = updateComplete || (savedState && savedState.neverShowAgain);
    this.setState({...savedState, showCloseConfirmModal: false, showSkipAndCompleteConfirmModal: false, loading});
    if (updateComplete) {
      this.saveState({completeConfirmed: updateComplete, loading: false});
    }
  }
  componentDidMount() {
    if (!this.props.test) {
      this.getState();
    }
  }
  // use saveState instead of this.setState
  saveState = stateItem => {
    const newState = {...this.state, ...stateItem};
    this.props.post(this.id, newState);
    this.setState(stateItem);
  };

  handleUserClose = () => {
    const dismissedTimes = this.state.dismissedTimes + 1;
    this.saveState({dismissedTimes});
    this.setState({closed: true});
  };

  handleCongratsClose = () => {
    this.saveState({completeConfirmed: true});
  };

  hideChecklist = () => this.setState({showChecklist: false});

  markIncomplete = () => this.saveState({complete: false});

  toggleCloseConfirmModal = () =>
    this.setState({
      closeConfirmModalCheckbox: false,
      showCloseConfirmModal: !this.state.showCloseConfirmModal
    });

  markStepComplete = () => {
    this.handleOnboardComponentUpdate(this.pageIds[this.state.page]);
    this.setState({showSkipAndCompleteConfirmModal: false});
  };

  nextPage = () => {
    this.goToPage(this.state.page + 1);
    this.setState({showSkipAndCompleteConfirmModal: false});
  };

  skipStep = () => {
    const {stepSkipped, page} = this.state;
    const pageId = this.pageIds[page];
    if (pageId === 'user-avatar') {
      return this.handleOnboardComponentUpdate('user-avatar');
    }
    if (stepSkipped[pageId]) {
      this.setState({showSkipAndCompleteConfirmModal: true});
    } else {
      stepSkipped[pageId] = true;
      this.nextPage();
    }
  };

  Congrats = () => (
    <Container fluid className="pt-5 pb-5 text-center text-lg-left">
      <Row className="d-flex align-items-center">
        <Col xs="12" lg="auto">
          <div className={userAvatar}>
            <img
              src="https://cdn.foodrevolution.org/members/assets/speakers/ocean-robbins.jpg"
              alt="Ocean Robbins"
              width="150"
            />
          </div>
        </Col>
        <Col>
          <h4>Oh my gosh, You did it!</h4>
          <p>
            You've taken a powerful step in achieving your health and lifestyle goals. I'm so excited for you and our
            WHOLE community, you rock! Let's do this!
          </p>
          <Button
            onClick={e => {
              e.preventDefault();
              this.handleCongratsClose();
            }}
          >
            Watch the Getting Started Video
          </Button>
        </Col>
      </Row>
    </Container>
  );

  updateCloseConfirmModalCheckbox = e => {
    this.setState({neverShowAgain: e.target.checked});
  };

  CloseConfirmModal = ({isOpen}) => (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        <h6>Are you sure you don't want to complete the onboarding process?</h6>
        {this.state.dismissedTimes >= 1 ? (
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.neverShowAgain}
                onChange={this.updateCloseConfirmModalCheckbox}
              />
              &nbsp;Never Show me onboarding again
            </Label>
          </FormGroup>
        ) : null}
        <div className="d-flex justify-content-center">
          <Button onClick={this.handleUserClose} className="m-1" color="light">
            Yes
          </Button>
          <Button onClick={this.toggleCloseConfirmModal} className="m-1">
            No
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );

  SkipAndCompleteConfirmModal = ({isOpen}) => (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        <h5>Would you like to mark this step as complete?</h5>
        <div className="d-flex justify-content-center">
          <Button onClick={() => this.markStepComplete()} className="m-2">
            Yes
          </Button>
          <Button onClick={() => this.nextPage()} className="m-2" color="primary">
            No
          </Button>
        </div>
        <small>By marking the step as complete, it will never be shown again.</small>
      </ModalBody>
    </Modal>
  );
  toggleVideoButton = () => {
    this.setState({enableVideoNextButton: true});
  };

  render() {
    const {checklist, markdown, surveyPaged, video} = this.Children;
    const {
      closed,
      page,
      loading,
      complete,
      completeConfirmed,
      showCloseConfirmModal,
      showChecklist,
      showSkipAndCompleteConfirmModal
    } = this.state;
    const {CloseConfirmModal, Congrats, toggleCloseConfirmModal, SkipAndCompleteConfirmModal} = this;

    //TODO put back in
    // if (loading || completeConfirmed || closed) {
    //   return null;
    // }

    //these are pages that can be shown in the modal
    //the modal toggles between a checklist as well
    //Pages:  these come from WLCOnboard content item (separate from checkist )
    //Video - the onboarding video
    //SurveyPage - the assessement of the user's health
    //UserInfo - the edit user profile component
    //UserAvatar - upload of a pic
    //Guidelines - the text of guidelines for using the forum

    const pages = [
      <div>
        <Video
          save
          {...video}
          autoplay
          handleComplete={this.handleOnboardComponentUpdate}
          onProgress={({played}) => played > 0.5 && this.toggleVideoButton()}
        />
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => this.handleOnboardComponentUpdate(video.id)}
            disabled={!this.state.enableVideoNextButton}
          >
            Next
          </Button>
        </div>
      </div>,
      <SurveyPaged
        handleIncomplete={this.props.handleIncomplete}
        {...surveyPaged}
        handleComplete={this.handleOnboardComponentUpdate}
      />,
      <div>
        <UserInfo handleComplete={() => this.handleOnboardComponentUpdate('user-info')} />
      </div>,
      <UserAvatar handleComplete={() => this.handleOnboardComponentUpdate('user-avatar')} />,
      <CommunityGuidelines handleComplete={this.handleOnboardComponentUpdate} {...markdown} />
    ];
    {
      /* checklist here: */
    }
    {
      /* <Checklist readonly {...checklist} ref={this.checklistRef} /> */
    }
    {
      /* passing in props so checklist has WLConboarding content item 
        MODAL content in props */
    }
    return <Checklist WLCOnboard={this.props} {...checklist} ref={this.checklistRef} />;
  }
}

WLCOnboardV2.propTypes = {
  children: PropTypes.shape({}).isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

WLCOnboardV2.displayname = 'WLCOnboard';

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export default connect(
  null,
  mapDispatchToProps
)(WLCOnboardV2);
