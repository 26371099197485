import React from 'react';
import InterviewCategory from '../InterviewCategory';
//Category list show the categories on the home page.
//CategoryWrapper -> CategoryList -> InterviewCategories
//Airbnb style: no arrow functions
function CategoryList(mappedCategories, handleNav) {
  return mappedCategories.map(category => {
    return (
      <InterviewCategory category={category} key={category.id} handleCategoryClick={() => handleNav(category.slug)} />
    );
  });
}

export default CategoryList;
