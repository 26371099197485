'use strict';
Object.defineProperty(exports, '__esModule', {value: true});
var prefix = 'frn';
var iconName = 'spoonForkCrossed';
var width = 50.998;
var height = 50.998;
var ligatures = [];
var unicode = '';
var svgPathData =
  'M38.678 47.803c.536.537 2.114-.17 2.651-.707l.884-.884c.537-.537 1.244-2.115.707-2.652L29.629 30.947l-3.475 3.661 12.524 13.195zM16.639 18.616c1.729-3.007.815-6.652-1.908-9.375C11.513 6.024 4.565 2.877 1.348 6.095c-3.218 3.218-.071 10.166 3.146 13.383 2.671 2.671 6.23 3.603 9.205 2.004l6.298 6.638 3.327-3.157-6.685-6.347z M33.012 18.523L6.631 43.56c-.537.537.17 2.115.707 2.652l.884.884c.402.402 1.386.898 2.081.898.234 0 .435-.058.570-.191l25.129-26.479c.702.345 1.437.54 2.181.607 2.229.204 4.553-.773 6.422-2.642 6.336-6.335 7.473-7.73 5.528-10.171l-7.479 7.168a.92.92 0 0 1-.64.269.773.773 0 0 1-.554-.227l-.2-.199a.778.778 0 0 1-.226-.555.933.933 0 0 1 .276-.646L48.1 6.963l-.485-.485c-.291-.291-.562-.563-.818-.822l-7.995 6.82a.895.895 0 0 1-.617.249.773.773 0 0 1-.554-.227l-.2-.199a.778.778 0 0 1-.226-.555.933.933 0 0 1 .276-.646l7.132-7.442c-2.301-1.672-3.699-.278-9.811 5.833-2.033 2.033-3.01 4.604-2.559 7.003.134.699.386 1.38.769 2.031z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData]
};

exports.frnSpoonForkCrossed = exports.definition;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
