import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {actions, selectors} from 'modules/checkout';
import GenericAddressForm from 'components/Forms/Address';
import FancyCheckbox from '../FancyCheckbox';
const {resetUser, setUser} = actions;

@connect(
  state => ({
    emailAddresses: selectors.getPassedEmailAddresses(state),
    thirdPartyUserInfo: state.checkout.thirdPartyUserInfo,
    user: state.user,
    values: state.checkout.orderRequest.User
  }),
  {
    resetUser: actions.resetUser,
    setValues: actions.setUser
  }
)
export class UserInfo extends React.PureComponent {
  state = {
    otherSelected: false
  };
  setEmailAddress = email => {
    this.setState({otherSelected: email === null});
    this.props.setValues({...this.props.values, email});
  };
  render() {
    const {emailAddresses, thirdPartyUserInfo, user, values} = this.props;
    const showEmailField = this.state.otherSelected || !emailAddresses.includes(values.email);
    return (
      <div>
        {values.id ? (
          <p>
            {`${user.firstName} ${user.lastName} (${user.email})`}
            <Button color="link" onClick={e => this.props.resetUser()}>
              (Not {user.firstName}?)
            </Button>
          </p>
        ) : (
          <React.Fragment>
            <GenericAddressForm
              {...this.props}
              fields={{email: {formText: 'You will use this email address to log in.'}}}
              disabledFields={[
                'name',
                'address1',
                'address2',
                'city',
                'state',
                'postalCode',
                'country',
                'phone'
              ].concat(!showEmailField ? ['email'] : [])}
              fullWidth
            />
            {emailAddresses.length ? (
              <span>
                <p>Please select or enter the email address you would like use to log into your account.</p>
                {emailAddresses
                  .map(email => (
                    <p key={email}>
                      <FancyCheckbox
                        checked={!showEmailField && email === values.email}
                        onClick={() => this.setEmailAddress(email)}
                      />{' '}
                      {email}
                    </p>
                  ))
                  .concat([
                    <p key="default">
                      <FancyCheckbox checked={showEmailField} onClick={() => this.setEmailAddress(null)} /> Use another
                      email address
                    </p>
                  ])}
              </span>
            ) : null}
            <p className="small">
              <em>
                By entering your information here, you are agreeing to receive messages from Food Revolution Network.
                Your email address will NEVER be shared or sold. You are always free to easily unsubscribe at any time.
                For more information see our{' '}
                <a href="https://foodrevolution.org/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
                .
              </em>
            </p>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default UserInfo;
