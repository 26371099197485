export const calendarImageMap = {
  'WLC Tea Party': {
    img: 'https://cdn.foodrevolution.org/wlc/social/tea-party.png',
    description:
      'Join us for a fun, participatory event where we’ll be meeting in small-group “tea rooms” for uplifting conversation, personal connections, and mutual support. Brew your favorite cuppa — maybe a WLC recipe! — and show up ready to chat! '
  },
  'Coaching Q&A': {
    img: 'https://cdn.foodrevolution.org/wlc/social/coaching-q-a.png',
    description:
      'Bring your questions about whole food, plant-based nutrition, lifestyle, and cooking — sorry, no medical questions — for an hour of learning, laughing, and generally having a great time together!'
  },
  'Community Connection Call': {
    img: 'https://cdn.foodrevolution.org/wlc/social/community-call.png',
    description:
      'Be ready to engage with others as a listener and as a sharer. Don’t worry — there won’t be any deeply personal discussion prompts! These calls are all about sharing experiences and insights within your small group to help build meaningful connections and strengthen this wonderful WHOLE Life Club community! '
  },
  'In the Kitchen Live Event': {
    img: 'https://cdn.foodrevolution.org/wlc/social/in-the-kitchen.png',
    description:
      'Elevate your culinary skills and learn to enjoy the kitchen even more! Rosie will be demonstrating a kitchen hack, tip, or technique to help you do just that, all with time for Q&A as well.'
  },
  'New Member Welcome Call': {
    img: 'https://cdn.foodrevolution.org/wlc/social/new+member+welcome+call.png',
    description:
      'Are you a new member? Then we cordially invite you to one of our New Member Welcome Calls! Bring your questions about WHOLE Life Club and your Member Center — anything but medical questions — for 30 minutes of learning, settling in, and getting comfy as you begin your WLC journey!'
  },
  default: {
    img: 'https://cdn.foodrevolution.org/wlc/social/frn-default-calendar.jpeg',
    description:
      'Join us for a fun, participatory event for uplifting conversation, personal connections, and mutual support.'
  }
};
