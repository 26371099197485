/* eslint-disable */
import React, {PureComponent} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import Link from 'react-router-dom';
const contentUrl = process.env.USER_CONTENT_URL;

export default props => (
  <Container>
    <Row>
      <Col>
        <Button
          style={{
            width: '75%',
            fontSize: '24px',
            fontWeight: '700',
            marginBottom: '16px'
          }}
          color="orange"
          className="btn-box-shadow btn-wrap pt-4 pb-4"
          onClick={props.handleClickUpsell}
        >
          Yes, add the OFFER to my order for only ${props.amount % 1 ? props.amount : Math.floor(props.amount)}
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          style={{
            width: '40%',
            fontSize: '24px',
            fontWeight: '400',
            marginBottom: '14px'
          }}
          color="grey"
          className="btn-box-shadow btn-wrap"
          onClick={props.handleDeclineUpsell}
        >
          No, thanks.
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <p>
          <em>Please click one of the buttons above.</em> Clicking the Yes button will add it to your purchase for an
          additional one-time cost of {props.amount}.
        </p>
        <p>
          <strong>And, of course, your purchase comes with our 60-day, total money-back guarantee.</strong>
        </p>
      </Col>
    </Row>
  </Container>
);
