import React, {useCallback, useEffect} from 'react';
import {Button, Modal} from 'reactstrap';
import ReactPlayer from 'react-player';
import {faXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloudflareStreamPlayer from 'routes/Products/components/Video/lib/CloudflareStream/CloudflareStreamPlayer';

ReactPlayer.addCustomPlayer(CloudflareStreamPlayer);
const VideoModal = ({toggleModal, showModal, videoUrl, isAudioOnly, displayProps}) => {
  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        toggleModal();
      }
    },
    [toggleModal]
  );
  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', escFunction, false);
      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    }
  }, [showModal]);
  const poster = displayProps?.image?.url || '';
  return (
    <div>
      <Modal isOpen={showModal} toggle={toggleModal} className="videoModal" backdropClassName="videoModal-backdrop">
        <Button onClick={toggleModal} color="link" className="videoModalClose">
          <FontAwesomeIcon icon={faXmark} /> Close
        </Button>

        <div style={{position: 'relative', paddingTop: '56.25%'}}>
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            controls={true}
            playing={true}
            autoplay={true}
            style={{position: 'absolute', top: 0, left: 0}}
            poster={poster}
          />
        </div>
      </Modal>
    </div>
  );
};

export default VideoModal;
