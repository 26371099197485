const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
};

export const getFullTimeString = timeInMilliseconds =>
  new Date(parseInt(timeInMilliseconds)).toLocaleString('en-us', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  });

export const getClockTime = timeInMilliseconds => new Date(parseInt(timeInMilliseconds)).toLocaleTimeString('en-us');

export const daysHoursMinutes = timeInMilliseconds =>
  new Date(parseInt(timeInMilliseconds)).toLocaleTimeString('en-us');

export const secondsToTime = secs => {
  let hours = Math.floor(secs / (60 * 60));

  let divisorMinutes = secs % (60 * 60);
  let minutes = String(Math.floor(divisorMinutes / 60));

  let divisorSeconds = divisorMinutes % 60;
  let seconds = String(Math.ceil(divisorSeconds));

  if (seconds.length < 2) {
    seconds = `0${seconds}`;
  }

  if (hours && minutes.length < 2) {
    minutes = `0${minutes}`;
  }

  return `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
};

export const getWeekNameDate = (startTime, endTime) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const startMonth = months[startDate.getMonth()].slice(0, 3) + '.';
  const startDay = startDate.getDate();
  let startYear = startDate.getFullYear();
  let endMonth = months[endDate.getMonth()].slice(0, 3) + '.';
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();
  startYear = startYear === endYear ? '' : startYear + ' ';
  endMonth = `${endMonth !== startMonth ? ' ' + endMonth : ''}`;
  return `${startMonth} ${startDay} ${startYear}-${endMonth} ${endDay} ${endYear}`;
};

export const getSingleDate = time => {
  const date = new Date(time);
  const day = date.getDate();
  const month = months[date.getMonth()].slice(0, 3) + '.';
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

//85 returns 1 hour 15 minute
//55 returns  55 minutes
//abbreviated is optional, 'minutes' to 'min' and 'hr'
export const minutesToHours = (time, abbreviated) => {
  if (abbreviated) {
    return Math.floor(time / 60) >= 1
      ? `${Math.floor(time / 60)} ${Math.floor(time / 60) > 1 ? ' hrs ' : ' hr'} ${
          time % 60 ? (time % 60 > 1 ? (time % 60) + ' mins' : (time % 60) + ' min') : ''
        }`
      : `${time + (time > 1 ? ' mins' : ' min')}`;
  } else {
    return Math.floor(time / 60) >= 1
      ? `${Math.floor(time / 60)}  ${Math.floor(time / 60) > 1 ? ' hours' : ' hour'} ${
          time % 60 ? (time % 60) + (time % 60 > 1 ? ' minutes' : ' minute') : ''
        }` //over an hour
      : `${time + (time > 1 ? ' minutes' : ' minute')} `; //under and hour
  }
};
//getWeekNameDate tests:

// Oct. 21 2018: 1540142008
// Oct. 28 2018: 1540746808
// Nov. 4 2018: 1541351608
// Nov. 11 2018: 1541956408
// Dec. 28 2018: 1546017208
// Jan. 1 2019: 1546622008

// console.log('Same year same month: ', getWeekNameDate(1540142008000, 1540746808000));
// console.log('Same year same month: ', getWeekNameDate(1541351608000, 1541956408000));
// console.log('Same year diff month: ', getWeekNameDate(1540746808000, 1541351608000));
// console.log('Diff year diff month: ', getWeekNameDate(1546017208000, 1546622008000));
