import React from 'react';
import pure from 'recompose/pure';

let Visa = props => (
  <svg
    width="43"
    height="28"
    viewBox="0 0 43 28"
    {...props}
    dangerouslySetInnerHTML={{
      __html:
        '<g fill="none" fill-rule="evenodd"><path d="M42 25.2a2.8 2.8 0 0 1-2.8 2.8H2.8A2.8 2.8 0 0 1 0 25.2V2.8A2.8 2.8 0 0 1 2.8 0h36.4A2.8 2.8 0 0 1 42 2.8v22.4z" fill="#0054A4" class="inactiveBlack"/><path fill="#FFF" d="M16.8 18.2l1.437-8.867h2.296L19.097 18.2zM27.533 9.246a5.775 5.775 0 0 0-2.09-.38c-2.306 0-3.93 1.228-3.944 2.985-.014 1.3 1.16 2.024 2.044 2.456.909.443 1.214.726 1.21 1.122-.006.606-.725.883-1.396.883-.934 0-1.43-.136-2.197-.474l-.3-.144-.327 2.024c.544.252 1.553.47 2.6.482 2.45 0 4.044-1.212 4.062-3.088.009-1.031-.613-1.812-1.96-2.457-.815-.42-1.315-.697-1.31-1.121.001-.376.423-.779 1.337-.779a4.1 4.1 0 0 1 1.746.348l.21.103.315-1.96M30.688 15.052c.194-.51.932-2.48.932-2.48-.013.023.193-.515.31-.847l.159.765s.449 2.118.541 2.562h-1.942zm2.886-5.719h-1.808c-.56 0-.98.157-1.226.736L27.067 18.2h2.457s.4-1.094.491-1.334l2.996.005c.07.31.285 1.329.285 1.329h2.17l-1.892-8.867zM14.418 9.333l-2.201 6.055-.236-1.23c-.41-1.419-1.686-2.956-3.114-3.726L10.88 18.2l2.38-.002 3.54-8.865h-2.382"/><path d="M10.748 9.333H7.03L7 9.511c2.892.711 4.806 2.427 5.6 4.489l-.808-3.943c-.14-.544-.544-.705-1.044-.724" fill="#F39C12" class="inactiveLtGrey"/></g>'
    }}
  />
);

Visa = pure(Visa);

export default Visa;
