import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap';
import {ForumWrapper} from 'components/ForumWrapper';
import Article from 'routes/Products/components/Article';
import {connect} from 'react-redux';
import {actions} from 'modules/user';

@connect(state => ({user: state.user}), {
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey,
  refreshToken: actions.refreshToken
})
class ArticlePage extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollToContent = this.scrollToContent.bind(this);
    this.commentsWrapper = React.createRef();
    this.highlightedComment = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  scrollToContent() {
    setTimeout(() => {
      //const offSet = this.commentsWrapper.current.offsetTop - 100;
      const offSet =
        this.highlightedComment.current && this.highlightedComment.current.scrollIntoView(false, {behavior: 'smooth'});
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  }

  render() {
    return [
      <Article {...this.props} />,
      <Container>
        <Row>
          <Col>
            <ForumWrapper
              slug={this.props.slug}
              pageTitle={this.props.title}
              scrollToMe={this.scrollToContent}
              passedReactRef={this.highlightedComment}
              user={this.props.user}
              product="wlc"
              revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
              refreshToken={this.props.refreshToken}
              forumType="ContentItem"
            />
          </Col>
        </Row>
      </Container>
    ];
  }
}

ArticlePage.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({
    markdown: PropTypes.string.isRequired
  }).isRequired
};

ArticlePage.defaultProps = {
  Children: []
};

export default ArticlePage;
