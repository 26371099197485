import React from 'react';
import client from 'braintree-web/client';
import {connect} from 'react-redux';
import {actions} from 'modules/checkout';
import {Alert, Col, Collapse, Container, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {ApplePay, CreditCard, GooglePay, PayPal, StoredPaymentMethods} from './components';
import FancyCheckbox from '../FancyCheckbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaypal} from '@fortawesome/free-brands-svg-icons/faPaypal';
import {faCreditCard} from '@fortawesome/pro-light-svg-icons/faCreditCard';
import brandMarks from 'icons/brand-marks';
import Amex from 'images/SVG/Amex';
import Discover from 'images/SVG/Discover';
import MasterCard from 'images/SVG/MasterCard';
import Visa from 'images/SVG/Visa';
import GooglePayIcon from 'images/SVG/GooglePay.svg';

import {orderRequiresShipping} from 'modules/checkout/selectors';
const {addBraintreeClientInstance, setCurrentPaymentMethod} = actions;

const env = process.env.nodeEnv.SANDBOX ? 'sandbox' : 'production';
const APPLEPAY_ENABLED = process.env.APPLEPAY_ENABLED;
const GOOGLEPAY_ENABLED = process.env.GOOGLEPAY_ENABLED;
const CREDITCARD_ENABLED = process.env.CREDITCARD_ENABLED;
const PAYPAL_ENABLED = process.env.PAYPAL_ENABLED;

const TokenSummary = ({token}) => {
  let Icon;
  let iconProps = {};
  let paymentString = '';
  if (token.type === 'PayPalAccount') {
    Icon = brandMarks.PayPal;
    paymentString = `PayPal ${token.details.email}`;
    iconProps = {height: '25px'};
  } else if (token.type === 'CreditCard') {
    switch (token.details.cardType) {
      case 'American Express':
        Icon = Amex;
        break;
      case 'Discover':
        Icon = Discover;
        break;
      case 'Mastercard':
        Icon = MasterCard;
        break;
      case 'Visa':
        Icon = Visa;
        break;
      default:
        Icon = FontAwesomeIcon;
        iconProps = {icon: faCreditCard};
    }
    paymentString = `${token.details.cardType} ${token.description}`;
  } else if (token.type === 'AndroidPayCard') {
    paymentString = 'Google Pay';
    Icon = iconProps => <img style={{...iconProps}} alt="GooglePayIcon" src={`${GooglePayIcon}`} />;
    iconProps = {height: '50px'};
  } else if (token.type === 'ApplePayCard') {
    paymentString = 'Apple Pay';
    Icon = brandMarks.ApplePay;
    iconProps = {height: '25px'};
  } else {
    paymentString = `${token.description}`;
    Icon = FontAwesomeIcon;
    iconProps = {icon: faCreditCard};
  }
  return (
    <div className="d-flex align-items-center px-4">
      <FancyCheckbox checked={true} className="mr-3" />
      <Icon {...iconProps} />
      <span className="pl-3">{paymentString}</span>
    </div>
  );
};
@connect(
  state => ({
    currentPaymentMethod: state.checkout.currentPaymentMethod,
    orderRequest: state.checkout.orderRequest,
    orderRequestUser: state.checkout.orderRequest.User,
    requiresShipping: orderRequiresShipping(state),
    UserPaymentMethodId: state.checkout.orderRequest.UserPaymentMethodId
  }),
  {
    addBraintreeClientInstance: actions.addBraintreeClientInstance,
    setCurrentPaymentMethod: actions.setCurrentPaymentMethod,
    setPaymentToken: actions.setPaymentToken,
    setUser: actions.setUser,
    setNewAddress: actions.setNewAddress,
    processThirdPartyCheckout: actions.processThirdPartyCheckout
  }
)
export class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null
    };
    this.clientInstance = client.create({authorization: process.env.BRAINTREE_TOKENIZATION_KEY}, (err, client) => {
      this.client = client;
      this.setState({
        loading: false,
        error: err
      });
    });
  }

  render() {
    const {loading, error} = this.state;
    const {orderRequest} = this.props;

    return (
      <div>
        {loading ? (
          'Loading...'
        ) : error ? (
          <Alert>{error}</Alert>
        ) : orderRequest.PaymentMethod && orderRequest.PaymentMethod.token ? (
          <Row>
            <TokenSummary token={orderRequest.PaymentMethod.token} />
          </Row>
        ) : (
          <div>
            {this.props.orderRequestUser.id ? <StoredPaymentMethods {...this.props} /> : null}
            <Collapse isOpen={null === this.props.UserPaymentMethodId}>
              <ListGroup>
                {!!APPLEPAY_ENABLED && <ApplePay client={this.client} tag={ListGroupItem} />}
                {!!CREDITCARD_ENABLED && <CreditCard {...this.props} client={this.client} tag={ListGroupItem} />}
                {!!PAYPAL_ENABLED && <PayPal {...this.props} client={this.client} tag={ListGroupItem} />}
                {!!GOOGLEPAY_ENABLED && <GooglePay client={this.client} tag={ListGroupItem} />}
              </ListGroup>
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentMethods;
