import React from 'react';
import PasswordLoginForm from './components/PasswordLoginForm';
import ModalContainer from '../container';

const LoginModal = props => {
  const title = 'Register or Log in';

  return (
    <ModalContainer disableClose={!props.opts.noDisableClose} {...props} title={title}>
      <PasswordLoginForm {...props} />
    </ModalContainer>
  );
};

// const {
//   step, showModal, hideModal, viewStep, email, loginUser, openModal, sendMagicLink
// } = props;

export default LoginModal;
