import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import FancyCheckbox from 'routes/Checkout/components/FancyCheckbox';

export class SavedAddresses extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.showAddAddressFields !== this.props.showAddAddressFields) {
      if (this.props.showAddAddressFields) this.props.selectAddress(null);
    }
  }
  render() {
    return (
      <Container fluid className="px-0">
        {this.props.addresses.map(address => (
          <Col>
            <Row
              onClick={() => {
                this.props.selectAddress(address.id);
              }}
              key={address.id}
              className="mb-3"
            >
              <Col xs="auto pt-2">
                <FancyCheckbox
                  checked={!this.props.usePrefilledAddress && address.id === this.props.selectedAddressId}
                />
              </Col>
              <Col>
                {address.name ? <strong>{address.name}</strong> : null}
                <br />
                {[address.address1, address.address2, address.city, address.state, address.postalCode, address.country]
                  .filter(a => Boolean(a))
                  .join(', ')}
              </Col>
            </Row>
            <Row key={address.id} className="mb-3">
              <Col xs="1" />
              <div>
                <FancyCheckbox checked={this.props.saveDefaultBilling} onClick={this.props.setDefaultBilling} />
              </div>
              <Col xs="4">Set as default billing</Col>
            </Row>
          </Col>
        ))}
        <Row
          className={this.props.showDefaultOnly ? 'd-none' : 'mb-3'}
          onClick={() => {
            this.props.selectAddress(null);
          }}
        >
          {this.props.showAddAddressFields ? null : (
            <Col xs="auto mb-3">
              <FancyCheckbox checked={null === this.props.selectedAddressId && !this.props.usePrefilledAddress} />
            </Col>
          )}
          <Col>Add a New Address</Col>
        </Row>
      </Container>
    );
  }
}

SavedAddresses.defaultProps = {
  addresses: [],
  selectedAddressId: null,
  selectAddress: () => {},
  usePrefilledAddress: false
};

export default SavedAddresses;
