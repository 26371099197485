import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import GenericAddressForm from 'components/Forms/Address';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import mergewith from 'lodash.mergewith';
import {actions} from 'modules/checkout';
import {actions as modalActions} from 'modules/modal';
import ModalContainer from '../container';
import PurchaseButton from 'routes/Checkout/components/PurchaseButton';
const {setUser} = actions;

const USER_INFO = 'USER_INFO';
const BILLING_INFO = 'BILLING_INFO';

@connect(
  state => ({
    BillingAddress: state.checkout.orderRequest.BillingAddress,
    orderUser: state.checkout.orderRequest.User,
    thirdPartyUserInfo: state.checkout.thirdPartyUserInfo,
    showModal: state.modal.showModal
  }),
  {
    hideModal: modalActions.hideModal,
    processOrder: actions.processOrder,
    setRecaptchaToken: actions.setRecaptchaToken,
    setNewAddress: actions.setNewAddress,
    setUser: actions.setUser
  }
)
class OrderConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:
        props.orderUser && props.orderUser.email
          ? props.orderUser.email
          : props.thirdPartyUserInfo && props.thirdPartyUserInfo.email
          ? props.thirdPartyUserInfo.email
          : null,
      page: USER_INFO,
      user: mergewith({}, props.thirdPartyUserInfo, props.orderUser, (a, b) => (!b || b === null ? a : undefined)),
      validity: {
        USER_INFO: false,
        BILLING_INFO: false
      }
    };
    this.setUser = this.setUser.bind(this);
    this.selectEmail = this.selectEmail.bind(this);
    this.next = this.next.bind(this);
  }
  setUser(values) {
    this.setState({user: values});
  }
  selectEmail(email) {
    this.setState({email});
  }
  updateValidity(page, valid) {
    const {validity} = this.state;
    this.setState({validity: {...validity, [page]: valid}});
  }
  next(e) {
    const {email, page, user, validity} = this.state;
    e.preventDefault();
    if (!validity[page]) return;

    if (page === USER_INFO) {
      this.props.setUser({...user});
    }

    return this.setState({page: BILLING_INFO});
  }
  render() {
    const title = 'Almost Done!';
    const {props, state} = this;
    //Create an array of unique non-null email addresses from the data we have.
    const emailAddresses = [props.orderUser.email, props.thirdPartyUserInfo.email].filter(
      (e, i, self) => e && self.indexOf(e) === i
    );
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_KEY}
        useEnterprise={true}
        container={{
          element: 'recaptchaBadge'
        }}
      >
        <ModalContainer {...props} disableClose title={title}>
          <p>We just want to confirm a couple of things before we process your order.</p>
          {state.page === USER_INFO ? (
            <div>
              <p>
                Please select or enter the email address you would like use to log into your account, and confirm your
                first and last name are correct.
              </p>
              {emailAddresses.length > 1
                ? emailAddresses
                    .map(email => (
                      <p key={email}>
                        <FontAwesomeIcon
                          icon={email === state.email ? faCheckCircle : faCircle}
                          size="lg"
                          onClick={() => this.selectEmail(email)}
                        />{' '}
                        {email}
                      </p>
                    ))
                    .concat([
                      <p>
                        <FontAwesomeIcon
                          icon={state.email === null ? faCheckCircle : faCircle}
                          size="lg"
                          onClick={() => this.selectEmail(null)}
                        />{' '}
                        Use another email address
                      </p>
                    ])
                : null}
              <GenericAddressForm
                values={state.user}
                setValues={this.setUser}
                fullWidth
                updateValidity={valid => this.updateValidity(USER_INFO, valid)}
                useLabels
                fieldLayout={[
                  {key: 'email', fields: ['email']},
                  {key: 'firstName', fields: ['firstName']},
                  {key: 'lastName', fields: ['lastName']}
                ]}
                // disabled fields?  why disabled?
                //how are are these updated?
                disabledFields={[
                  'name',
                  'address1',
                  'address2',
                  'city',
                  'state',
                  'postalCode',
                  'country',
                  'phone'
                ].concat(emailAddresses.length > 1 && state.email != null ? ['email'] : [])}
              />
            </div>
          ) : (
            <div>
              <p>Please verify your billing information.</p>
              <GenericAddressForm
                values={props.BillingAddress}
                fullWidth
                setValues={values => props.setNewAddress(values, 'BillingAddress')}
                updateValidity={valid => this.updateValidity(BILLING_INFO, valid)}
                useLabels
                disabledFields={['firstName', 'lastName', 'name', 'email']}
              />
            </div>
          )}
          {props.BillingAddress.postalCode || props.BillingAddress.id ? (
            <PurchaseButton
              setRecaptchaToken={this.props.setRecaptchaToken}
              processOrder={this.props.processOrder}
              disabled={!state.validity[state.page]}
            />
          ) : (
            <Button onClick={this.next} disabled={!state.validity[state.page]}>
              Next
            </Button>
          )}
          <div id="recaptchaBadge"></div>
        </ModalContainer>
      </GoogleReCaptchaProvider>
    );
  }
}

export default OrderConfirmModal;
