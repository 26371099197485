import React from 'react';
import {Row, Col, Container, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusLarge} from '@fortawesome/pro-solid-svg-icons/faPlusLarge';

export default AdvancedPackageCTA = ({openModal = () => {}}) => (
  <Container>
    <Row className="rounded mx-5 p-5 text-white" style={{backgroundColor: 'var(--purple)', borderRadius: '0.375rem'}}>
      <Col lg="4" style={{backgroundColor: 'var(--white)', borderRadius: '0.375rem'}} className="d-flex">
        <img
          src="https://cdn.foodrevolution.org/frs23/frs23-productimage-advanced-20230322.png"
          style={{maxWidth: '100%'}}
          className="p-3"
        />
      </Col>
      <Col className="ml-5">
        <h3 className="mt-0">Get Instant Access to all 45 extended interviews</h3>
        <p className="text-white">
          <FontAwesomeIcon icon={faPlusLarge} />
          &nbsp;English and Spanish Transcripts for every interview (value $450)
        </p>
        <p className="text-white">
          <FontAwesomeIcon icon={faPlusLarge} />
          &nbsp;The Plant-Powered Playbook (regular price $97)
        </p>
        <p className="text-white">
          <FontAwesomeIcon icon={faPlusLarge} />
          &nbsp;The Total Health Collection: Anti-Cancer, Immune Health, Brain Health, Heart Health (Regular price $388)
        </p>
        <h5>Unlock the Advanced Empowerment Package instantly for only $100!</h5>
        <Button color="secondary" size="lg" onClick={openModal}>
          Upgrade Now
        </Button>
      </Col>
    </Row>
  </Container>
);
