import {library} from '@fortawesome/fontawesome-svg-core';

// Font Awesome icons are added here before they can be used via
// <FontAwesomeIcon icon="name-without-fa-and-no-caps-first-letter" />

import {faAngleDoubleLeft} from '@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft';
import {faAngleDoubleRight} from '@fortawesome/pro-solid-svg-icons/faAngleDoubleRight';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import {faArrowAltDown} from '@fortawesome/pro-solid-svg-icons/faArrowAltDown';
import {faArrowAltUp} from '@fortawesome/pro-solid-svg-icons/faArrowAltUp';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import {faBackward} from '@fortawesome/pro-solid-svg-icons/faBackward';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {faBoxOpen} from '@fortawesome/pro-solid-svg-icons/faBoxOpen';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faCheckSquare} from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faCircle} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import {faCommentsAlt} from '@fortawesome/pro-solid-svg-icons/faCommentsAlt';
import {faForward} from '@fortawesome/pro-solid-svg-icons/faForward';
import {faHeart} from '@fortawesome/pro-solid-svg-icons/faHeart';
import {faImage} from '@fortawesome/pro-solid-svg-icons/faImage';
import {faLockAlt} from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {faPause} from '@fortawesome/pro-solid-svg-icons/faPause';
import {faStar as fasStar} from '@fortawesome/pro-solid-svg-icons/faStar';
import {faStroopwafel} from '@fortawesome/pro-solid-svg-icons/faStroopwafel';
import {faUndo} from '@fortawesome/pro-solid-svg-icons/faUndo';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUsers as fasUsers} from '@fortawesome/pro-solid-svg-icons/faUsers';
import {faUserCog} from '@fortawesome/pro-solid-svg-icons/faUserCog';
import {faVideo} from '@fortawesome/pro-solid-svg-icons/faVideo';

import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import {faTwitterSquare} from '@fortawesome/free-brands-svg-icons/faTwitterSquare';

import {faAngleDown} from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import {faBallotCheck} from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import {faChalkboardTeacher} from '@fortawesome/pro-regular-svg-icons/faChalkboardTeacher';
import {faCheckCircle as falCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faClock} from '@fortawesome/pro-regular-svg-icons/faClock';
import {faCloudDownload} from '@fortawesome/pro-regular-svg-icons/faCloudDownload';
import {faFile} from '@fortawesome/pro-regular-svg-icons/faFile';
import {faFileAudio} from '@fortawesome/pro-regular-svg-icons/faFileAudio';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import {faGlobe} from '@fortawesome/pro-regular-svg-icons/faGlobe';
import {faLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {faMapMarkerAlt} from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import {faSquare} from '@fortawesome/pro-regular-svg-icons/faSquare';
import {faStar as farStar} from '@fortawesome/pro-regular-svg-icons/faStar';
import {faTimes} from '@fortawesome/pro-regular-svg-icons/faTimes';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import {faUsers as farUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faPrint} from '@fortawesome/pro-regular-svg-icons/faPrint';

import {frnWLC, frnSpoonForkCrossed} from './frn-svg-icons';

export default () => {
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowAltDown,
    faArrowAltUp,
    faArrowLeft,
    faBackward,
    faBallotCheck,
    faBars,
    faBookmark,
    faBoxOpen,
    faCaretDown,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircle,
    faCircleNotch,
    faClock,
    faCloudDownload,
    faCommentsAlt,
    faFacebookSquare,
    faFile,
    faFileAudio,
    faFilePdf,
    faForward,
    faGlobe,
    faHeart,
    faImage,
    faLock,
    faLockAlt,
    faMapMarkerAlt,
    faPause,
    faPlay,
    faPrint,
    faChalkboardTeacher,
    farStar,
    farUsers,
    faSearch,
    faSquare,
    fasStar,
    faStroopwafel,
    faTimes,
    fasUsers,
    faTrashAlt,
    faTwitterSquare,
    faUndo,
    faUserCog,
    faVideo,
    frnSpoonForkCrossed,
    frnWLC
  );
};
