import React from 'react';
const Card = ({date, image, subtitle, title}) => {
  return (
    <div className="card">
      {image ? <img src={image.url} className="card-img-top" alt={title} /> : ''}
      <div className="card-body">
        {title ? <h5 className="card-title">{title}</h5> : ''}
        {subtitle ? <p className="card-subtitle">{subtitle}</p> : ''}
        {date ? (
          <p className="card-text">
            <strong>Date:</strong> {date}
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Card;
