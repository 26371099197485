/* eslint-disable */
// import createSchema from '../../../../lib/createSchema';
import video from '../../../../components/Video/testData';
import recipesList from '../../../../components/RecipesList/testData.js';

//Similar to 'ppt-17' course

const subscriptionOne = {
  type: 'subscription',
  name: 'Whole Life Club',
  slug: 'wlc-18',
  title: 'Whole Life Club',
  data: {
    fullTitle: 'Plant-Powered and Thriving - Fall 2017',
    headline: 'Welcome to the Plant-Powered and Thriving course',
    description: 'Ipsum lorem . . . ',
    url: 'make me a senior property?',
    alt: 'course one'
  },

  Children: [recipesList, video]
};

export default subscriptionOne;
