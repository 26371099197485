//'use client';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

import {Amplify} from 'aws-amplify';
import {generateClient} from 'aws-amplify/api';
import React, {Component, useEffect, useState} from 'react';
import {publish} from '../graphql/mutations';
import {subscribe} from '../graphql/subscriptions';

import {usePhaseDispatch} from './scheduleContext';

const scheduleUrl = process.env.PUBSUB_SCHEDULE_URL;
const appSyncChannel = process.env.PUBSUB_CHANNEL;

const amplifyConfig = {
  API: {
    GraphQL: {
      endpoint: process.env.AMPLIFY_ENDPOINT,
      region: process.env.AWS_REGION,
      defaultAuthMode: process.env.AMPLIFY_DEFAULT_AUTH_MODE,
      apiKey: process.env.AMPLIFY_API_KEY
    }
  }
};

export function useFunnelSchedule() {
  const phaseDispatch = usePhaseDispatch();
  useEffect(() => {
    try {
      axios.get(scheduleUrl).then(({data}) => {
        console.log('schedule url ', scheduleUrl, 'data ', data);
        return phaseDispatch({
          type: 'SET_PHASE',
          payload: data
        });
      });
    } catch (e) {
      console.log('error in useAppSync useEffect', e);
    }
  }, []);
}

export default function useAppSync() {
  const phaseDispatch = usePhaseDispatch();
  const [messageReceived, setMessageReceived] = useState();

  Amplify.configure(amplifyConfig);
  const client = generateClient();

  // SUBSCRIBE and update state with messages
  useEffect(() => {
    try {
      axios.get(scheduleUrl).then(({data}) => {
        console.log('schedule url ', scheduleUrl, 'data ', data);
        return phaseDispatch({
          type: 'SET_PHASE',
          payload: data
        });
      });
    } catch (e) {
      console.log('error in useAppSync useEffect', e);
    }
    const sub = client.graphql({query: subscribe, variables: {name: appSyncChannel}}).subscribe({
      client,
      next: ({data}) => setMessageReceived(JSON.parse(data.subscribe.data)),
      error: error => console.warn(error)
    });
    return () => sub.unsubscribe();
  }, []);

  // PAYLOAD FROM API IS
  // current payloads: {default: {phase: "before", webinar_id: 1}}

  // STRUCTURE OF STATE IN REDUCER:
  // {"phaseData":{"phases":{"default":{"phase":"before"}}}}         'default' is really 'sales', and it is a value of 'context' now.   'default', 'broadcast', etc

  /* STRUCTURE OF PUBSUB MESSAGE:
    {
      "commandType": "PHASE_DISPATCH",      //command for what to do, use reducer or REFRESH browser
      "reducerType": "UPDATE_PHASE",    //the command for the reducer
      "payload": {
        "schedule": "broadcast",
        "data": {
          "url": "https://youtube.com",
          "phase":"before",
          "day": 0,
          "webinar_id":1
        }
      }
    }
   */

  /* This is listening for a change in the messageReceived which is only updated by AppSync
   * With a new messageReceived we follow through with the command given in the message
   */
  useEffect(() => {
    console.log('message received in PubSub commponent useeffect: ', messageReceived, messageReceived?.commandType);
    if (messageReceived && messageReceived.commandType) {
      // {default: {phase: "before", webinar_id: 1}}
      const convertedToFunnelTalk = messageReceived.payload.schedule
        ? {[messageReceived.payload.schedule]: messageReceived.payload.data}
        : {};
      console.log('convertToFunnelTalk ', convertedToFunnelTalk);
      switch (messageReceived.commandType) {
        // {"commandType": "PHASE_DISPATCH", "reducerAction": "SET_PHASE", "schedule": "broadcast", "data": {"url": "https://youtu.be/rFANRmxhSgM", "phase": "during", "day": 0}}
        case 'PHASE_DISPATCH': {
          console.log('type :', messageReceived.reducerAction, 'payload:', convertedToFunnelTalk);

          return phaseDispatch({
            type: messageReceived.reducerAction, // SET_PHASE, etc
            payload: convertedToFunnelTalk // this is merged into th.e schedule object {default:{...}, broadcast:{..}}
          });
        }
        // {"commandType": "BROWSER_REFRESH"}
        case 'BROWSER_REFRESH': {
          console.log('browserRefresh', messageReceived);
          // browser refresh, @ts-ignore
          window.location.reload('forceGet'); // forceGet is boolean for not using the cache
        }
        // {"commandType": "SCHEDULE_PULL}
        case 'SCHEDULE_PULL': {
          console.log('schedulePull', messageReceived);
          axios.get(scheduleUrl).then(({data}) => {
            console.log('schedule url ', scheduleUrl, 'data ', data);
            return phaseDispatch({
              type: 'SET_PHASE',
              payload: data
            });
          });
        }
      }
    }
  }, [messageReceived]);
}
