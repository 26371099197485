import React from 'react';
import './styles.css';
const Quote = ({mobile, quote, author}) => {
  return (
    <div className={`quote-container ${mobile ? 'mobile' : ''}`}>
      <div className="quote-text">{quote}</div>
      <div className="quote-author">{author}</div>
    </div>
  );
};

export default Quote;
