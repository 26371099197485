import React, {Component} from 'react';
import getContent from 'lib/getContent';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

// this container automatically returns the component it is passed
//and then makes API call and passes data into props
@connect(state => ({authToken: state.user.authToken}), {})
class GeneralContentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {contentItem: null};
  }
  componentDidMount() {
    //  console.log('match.params.id ', this.props.match.params.id);
    const thisPath = location.pathname.split('/');
    let productSlug = thisPath[2];

    let contentSlug;
    if (this.props.overrideSlug) {
      contentSlug = this.props.overrideSlug;
    } else if (this.props.type) {
      const {type} = this.props;
      //getting slug from url based on type (eg recipeslug)
      contentSlug = this.props.match.params[type];
      productSlug = ''; //not sure why this is necessary, todo ask mark
    } else {
      let {slug} = this.props.match.params;
      contentSlug = slug;
    }

    //get content from API
    //`/content/${contentSlug}/${productSlug}`
    if (contentSlug) {
      if (this.props.testData) {
        this.setState({contentItem: this.props.testData});
        return;
      }
      getContent(contentSlug, productSlug, this.props.authToken)
        .then(res => {
          if (res) {
            if (this.props.dataMassager) {
              //const finalContentItem = returnAllRecipesForGlobalSearch(res); //todo change to props
              const finalContentItem = this.props.dataMassager(res);
              this.setState({contentItem: finalContentItem});
            } else {
              this.setState({contentItem: res});
            }
          }
        })
        .catch(err => {
          console.log('error in General Container GET', err);
        });
    }
  }
  render() {
    const {contentItem} = this.state;
    const {Component, componentProps} = this.props;

    //for GlobalSearch
    // contentItem.Children is for legacy   //product is just for recipes/GlobalSearch right now, will update when not needed
    if (contentItem) {
      return (
        <div>
          <Component
            {...contentItem}
            key={contentItem.id}
            contentItem={contentItem}
            Children={contentItem ? contentItem.Children : null}
            isActionHour={true}
            {...componentProps}
            product={contentItem}
            {...this.props}
          />
        </div>
      );
    } else {
      // return <div className="text-center ">Loading content...</div>;
      return <Component key={'waiting'} loading />; //return the components so it can render
    }
  }
}

export default withRouter(GeneralContentContainer);
