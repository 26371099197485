/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {Button, Card, Input, Label, Modal, ModalHeader, ModalBody, Form, FormText, FormGroup} from 'reactstrap';
import styles from 'styles/index.scss';
import moment from 'moment';
const {
  // userInfo,
  userPersonalInfoButton,
  userPersonalInfoContainer,
  userPersonalInfoLine,
  userPersonalInfoTitle
} = styles;

@connect(state => ({user: state.user}), {getUserEventLogs: actions.getUserEventLogs})
class EmailLogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {targetUser: this.props.targetUser};

    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  componentDidMount() {
    this.props.getUserEventLogs(0, this.state.targetUser.id || null);
  }
  onPrevious() {
    const page = this.props.user.userEventLogs.query.page;
    this.props.getUserEventLogs(page - 1, this.state.targetUser.id || null);
  }

  onNext() {
    const page = this.props.user.userEventLogs.query.page;
    this.props.getUserEventLogs(page + 1, this.state.targetUser.id || null);
  }

  render() {
    var {userEventLogs} = this.props.user;
    if (!userEventLogs) {
      userEventLogs = {query: {page: 0}, data: {}};
    }

    const {page, count} = userEventLogs.data;

    return (
      <Modal centered isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Email Log</ModalHeader>
        <ModalBody>
          <Card className={userPersonalInfoContainer}>
            <div style={{display: 'flex'}}>
              <div style={{flexBasis: '95%'}}>
                {userEventLogs.data.rows &&
                  userEventLogs.data.rows.map(log => {
                    return (
                      <div key={log.id}>
                        {log.orderId && (
                          <div className={userPersonalInfoTitle + ' p-0 row'}>
                            <div className={userPersonalInfoTitle + ' col col-sm-6'}>
                              <span>Order#</span>
                              <span>{log.orderId}</span>
                            </div>
                            <div className={userPersonalInfoTitle + ' col col-sm-6 text-right'}>
                              <span>{moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')} UTC</span>
                            </div>
                          </div>
                        )}

                        <div className={userPersonalInfoTitle + ' p-0'}>
                          <span>To: </span>
                          <span>{log.email}</span>
                        </div>

                        <div className={userPersonalInfoTitle + ' p-0'}>
                          <span>Subject: </span>
                          <span>{log.description}</span>
                        </div>

                        <hr />
                      </div>
                    );
                  })}
                <div className="p-0 row">
                  <div className="col col-sm-6">
                    <Button onClick={this.onPrevious} disabled={0 == page}>
                      Previous Page
                    </Button>
                  </div>

                  <div className="col col-sm-6 text-right">
                    <Button onClick={this.onNext} disabled={page == count - 1}>
                      Next Page
                    </Button>
                  </div>
                </div>{' '}
              </div>
            </div>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

EmailLogModal.propTypes = {};
//   updateProfile: PropTypes.func.isRequired
// };

export default EmailLogModal;
