import React, {useCallback, useState} from 'react';
import { connect } from 'react-redux';

import {Col, Container, Row, Button} from 'reactstrap';
import classNames from 'classnames';
import {Chat} from 'routes/Products/components/Chat'
import chatConfiguration from '../../../../../amplifyconfiguration.json';

export const FacebookCommentsSlideOut = ({commentFeed, hideTrigger = false, user}) => {
  const [commentsKey, setCommentsKey] = useState(0);
  const [isVisibile, setIsVisible] = useState(false);
  const refreshComments = useCallback(() => setCommentsKey(commentsKey => commentsKey + 1), []);

  const toggleVisibility = useCallback(() => setIsVisible(v => !v), []);

  return (
    <>
      <div className={classNames('facebookCommentsSlideOut', isVisibile ? 'facebookCommentsSlideOutVisible' : '')}>
        <Button
          className={classNames(
            'facebookCommentsSlideOutTrigger',
            'pb-3',
            'text-white',
            hideTrigger && 'facebookCommentsSlideOutTriggerHidden'
          )}
          color="orange"
          size="lg"
          onClick={toggleVisibility}
        >
          {isVisibile ? 'Collapse Comments' : 'Expand Comments'}
        </Button>
        <Container fluid>
          <Row>
            <Col xs={1} md={3} className={classNames('d-flex')} onClick={toggleVisibility} role="button"></Col>
            <Col style={{height: '100vh', background: 'var(--bs-white)', overflowY: 'scroll'}}>
            <div
    className={classNames(
      'comment-box',
      `background-white`,
      `box-shadow-black-50`,
      `rounded`,
    )}
  >
    <Row className={classNames(`align-items-end`, `text-center`, `mb-4`)}>
      <Col xs="12">
        <h5>Share Your Comments, Highlights, & Celebrations</h5>
      </Col>
      </Row>
      <Row id="comment">
      <Col>
            {
              user.email && user.firstName ? 
                <Chat category="frs24-broadcasts" optInEmail={user.email} optInName={user.firstName} chatConfiguration={chatConfiguration} />
              : null 
            }
            </Col>
            </Row>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = function(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(FacebookCommentsSlideOut);
