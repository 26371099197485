import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';

const CheckCircle = ({checked, ...props}) => (
  <FontAwesomeIcon icon={checked ? faCheckCircle : faCircle} size="lg" style={{color: 'white'}} {...props} />
);

export default CheckCircle;
