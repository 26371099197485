import React from 'react';
import Header from 'components/HeaderV2';
import styles from 'styles/index.scss';

const {addGreyBackground} = styles;

const AppWrapperV2 = Component => () => (
  <main className={`${location.pathname.split('/')[1] !== 'checkout' ? addGreyBackground : null} appV2MainContainer`}>
    <Header />
    <Component />
  </main>
);

export default AppWrapperV2;
