import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import EditUserInfo from 'routes/User/Profile/components/EditUserInfoModal/components/EditUserInfo';
import {actions} from 'modules/user';
import styles from 'styles/index.scss';

@connect(
  state => ({user: state.user}),
  {updateProfile: actions.updateProfile}
)
class OnboardUserInfo extends Component {
  constructor(props) {
    super(props);
    // const { user } = props;
  }

  render() {
    const {handleComplete, updateProfile, user} = this.props;

    // set onboardcallback only called by wlc onboard
    return <EditUserInfo user={user} updateProfile={updateProfile} onSuccess={handleComplete} />;
  }
}

OnboardUserInfo.propTypes = {};

OnboardUserInfo.displayname = 'OnboardUserInfo';

export default OnboardUserInfo;
