import React from 'react';

const CopyTextButton = ({text, buttonLabel, alertMessage}) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // This block is executed if the copy operation was successful.
        alert(`${alertMessage ? alertMessage : 'Copied to clipboard!'}`);
      })
      .catch(err => {
        // This block catches errors, such as if the user denies clipboard permissions.
        console.error('Failed to copy text: ', err);
        alert('Failed to copy the text');
      });
  };

  return (
    <button className="btn btn-primary my-2" onClick={handleCopy}>
      {buttonLabel ? buttonLabel : 'Copy Zoom Link'}
    </button>
  );
};

export default CopyTextButton;
