import React from 'react';

const PhaseStateContext = React.createContext({phases: {}});
const PhaseDispatchContext = React.createContext(() => {});

function phaseReducer(state, {type, payload}) {
  switch (type) {
    case 'SET_PHASE': {
      return {phases: {...state.phases, ...payload}, lastScheduleUpdateTime: Date.now()};
    }
    case 'UPDATE_PHASE': {
      const {context, data} = payload;
      if (!context) throw new Error('Context required for UPDATE_PHASE');
      if (!data) throw new Error('Data required for UPDATE_PHASE');

      return {
        phases: {...state.phases, [context]: {...state.phases[context], ...data}},
        lastScheduleUpdateTime: Date.now()
      };
    }
    case 'ADD_PHASE_EVENT': {
      const {context, event} = payload;
      if (!context) throw new Error('Context required for UPDATE_PHASE');
      if (!event) throw new Error('Event required for UPDATE_PHASE');
      const contextData = state.phases[context] || {};
      contextData.firedEvents = [].concat(contextData.firedEvents, [event]);

      return {phases: {...state.phases, [context]: {...contextData}}};
    }
    default: {
      throw new Error('Unhandled action type: ${action.type}');
    }
  }
}

function PhaseProvider({value, children}) {
  const [state, dispatch] = React.useReducer(phaseReducer, value || {});

  return (
    <PhaseStateContext.Provider value={state}>
      <PhaseDispatchContext.Provider value={dispatch}>{children}</PhaseDispatchContext.Provider>
    </PhaseStateContext.Provider>
  );
}

function usePhaseState() {
  const context = React.useContext(PhaseStateContext);
  if (context === undefined) {
    throw new Error('usePhaseState must be used within a PhaseProvider');
  }

  return context;
}

function usePhaseDispatch() {
  const context = React.useContext(PhaseDispatchContext);
  if (context === undefined) {
    throw new Error('usePhaseDispatch must be used within a PhaseProvider');
  }

  return context;
}

const withPhaseProvider = Component => {
  const PropWrapper = props => {
    const phaseState = usePhaseState();
    const phaseDispatch = usePhaseDispatch();
    return <Component phaseState={phaseState} phaseDispatch={phaseDispatch} {...props} />;
  };

  const PhaseWrapper = props => {
    return (
      <PhaseProvider>
        <PropWrapper {...props} />
      </PhaseProvider>
    );
  };

  return PhaseWrapper;
};

export {PhaseProvider, usePhaseState, usePhaseDispatch, withPhaseProvider};
