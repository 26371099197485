/* eslint-disable */
// import createSchema from '../../../../lib/createSchema';
import {CollectionOne, CollectionTwo} from '../Collection/testData';

const collectionsListOne = {
  type: 'collectionsList',
  name: 'Collections List One',
  title: 'Collections List',
  slug: 'collections-list-one',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Recipes and Spoon',
      text: 'CollectionsListOne',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  },
  Children: [CollectionOne, CollectionTwo]
};

// export const collectionsListSchema = createSchema(collectionsListOne);
export default collectionsListOne;
