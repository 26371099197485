import React, {Component, useState} from 'react';
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';

const SingleQuestion = ({
  title,
  choices,
  selectedChoice,
  invalid = false,
  selectChoice,
  currentQuestionIndex,
  totalQuestions,
  previousQuestion,
  nextQuestion
}) => {
  const validChoices = choices.map(c => c.id);
  return (
    <>
      <Row>
        <Col>
          <h5 className="mt-0">
            Question {currentQuestionIndex + 1}/{totalQuestions}
          </h5>

          <FormGroup tag="fieldset">
            <legend style={{fontSize: '1.25em', fontWeight: 'bold'}}>{title}</legend>
            {invalid ? (
              <Alert color="danger" style={{fontSize: '1.25em'}}>
                Please select an answer.
              </Alert>
            ) : null}
            {choices.map(({id, title}, i) => (
              <FormGroup
                check
                key={id}
                style={{fontSize: '1.25em'}}
                className="d-flex align-items-top mb-3"
                onClick={() => selectChoice(id)}
              >
                <FontAwesomeIcon
                  icon={selectedChoice === id ? faCheckCircle : faCircle}
                  style={({color: selectedChoice === id ? '#289B18' : '#898989'}, {paddingTop: '5px'})}
                  className="pr-3"
                />
                <Label check>{`${String.fromCharCode(65 + i)}) ${title}`}</Label>
              </FormGroup>
            ))}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {currentQuestionIndex > 0 ? (
            <Button className="mr-3" onClick={previousQuestion}>
              Prev
            </Button>
          ) : null}
          <Button color="primary" onClick={nextQuestion} disabled={!validChoices.includes(selectedChoice)}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SingleQuestion;
