import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import Guidelines from 'routes/Products/components/Guidelines';
@connect(state => ({user: state.user}))
class GuidelinesModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Modal centered isOpen={this.props.isOpen} toggle={this.props.toggle} size={'lg'}>
        <ModalHeader toggle={this.props.toggle}>Community Guidelines</ModalHeader>
        <ModalBody>
          <Guidelines />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

GuidelinesModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default GuidelinesModal;
