import React from 'react';

const MessageStateContext = React.createContext();
const MessageDispatchContext = React.createContext();

const initialState = {
  category: '',
  messages: [],
  messagesHash: {},
  orphanReplies: {}, // ooof...
  displayedMessages: [],
  displayBackButtonToHome: false,
  messagesToLoad: false,
  inNotificationScreen: false,
  unreadNotificationsCount: 0,
  currentMessageAnchor: null,
  nextToken: null,
  displayedUserId: null
};

/* This doesn't exactly conform to MVO architecture -
 * but at least my components are definitely a View!
 * the context here is sort of an M and O combined
 * I am limited by the GraphQL backend being pretty simple and out-of-the-box */
function MessageReducer(state, action) {
  switch (action.type) {
    case 'SET_CATEGORY': {
      return {
        ...state,
        category: action.payload
      };
    }
    case 'SET_NEXT_TOKEN': {
      return {
        ...state,
        nextToken: action.payload
      };
    }
    case 'SET_MESSAGES': {
      // This is called once, upon initial load.
      // Default to showing all messages without a ParentComment, but no child messages
      const messagesHash = setMessagesHash({ messages: action.payload, messagesHash: state.messagesHash });
      const resp = addOrUpdateReplyData({ messages: action.payload, messagesHash });
      let { messages, orphanReplies } = resp;

      messages.forEach(m => {
        if (!m.ParentCommentId) m.displayCommentThread = false;
      });
      return {
        ...state,
        messages,
        orphanReplies,
        messagesHash,
        messagesToLoad: false
      };
    }
    case 'SET_DISPLAYED_MESSAGES': {
      const newDisplayedMessages = filterThreads({ messages: action.payload });
      return {
        ...state,
        displayedMessages: newDisplayedMessages,
        currentMessageAnchor: newDisplayedMessages.length ? `message-${newDisplayedMessages.slice(-1)[0].id}` : null,
        messagesToLoad: false
      };
    }
    case 'SHOW_USER_MESSAGES': {
      //TODO: Could also implement this with getMessagesByUser
      return {
        ...state,
        displayBackButtonToHome: true,
        displayedMessages: filterThreads({
          showMessagesByUser: true,
          userId: action.payload,
          messages: state.messages
        }),
        displayedUserId: action.payload,
        inNotificationScreen: false
      };
    }
    case 'SET_UNREAD_NOTIFICATIONS_COUNT': {
      return {
        ...state,
        unreadNotificationsCount: action.payload
      };
    }
    case 'SHOW_USER_NOTIFICATIONS': {
      return {
        ...state,
        displayBackButtonToHome: true,
        inNotificationScreen: true,
        displayedUserId: action.payload,
        displayedMessages: filterThreads({
          showUserNotifications: true,
          userId: action.payload,
          messages: state.messages
        })
      };
    }
    case 'ADD_MESSAGE': {
      let prevMessages = [...state.messages];

      const { newMessage, myPost, myUserId } = action.payload;

      if (newMessage.ParentCommentId) {
        // fun with pointers

        if (!prevMessages[state.messagesHash[newMessage.ParentCommentId]]) {
          // add to orphan replies
          state.orphanReplies[newMessage.ParentCommentId] = { numOfReplies: 0 };
          if (
            state.orphanReplies[newMessage.ParentCommentId] &&
            state.orphanReplies[newMessage.ParentCommentId].numOfReplies
          ) {
            state.orphanReplies[newMessage.ParentCommentId].numOfReplies =
              state.orphanReplies[newMessage.ParentCommentId].numOfReplies + 1;
          } else {
            state.orphanReplies[newMessage.ParentCommentId] = { numOfReplies: 1 };
          }
        } else {
          const existingReplyNumber = prevMessages[state.messagesHash[newMessage.ParentCommentId]].numOfReplies || 0;
          prevMessages[state.messagesHash[newMessage.ParentCommentId]] = {
            ...prevMessages[state.messagesHash[newMessage.ParentCommentId]],
            numOfReplies: existingReplyNumber + 1
          };
        }
      }

      const incrementNotificationsCount = newMessage.userToNotifyID === myUserId;

      const newMessages = [...prevMessages, newMessage];

      return {
        ...state,
        messages: newMessages,
        // if it's my post or a child comment, just add it immediately
        ...((myPost || newMessage.ParentCommentId) && {
          displayedMessages: filterThreads({ messages: newMessages })
        }),
        // don't show button if it's my post, if the post is in a thread, or if I'm in another screen
        // TODO: Maybe also hide this for the my user screen? For now, it's fine.
        messagesToLoad: state.messagesToLoad || (!myPost && !newMessage.ParentCommentId && !state.inNotificationScreen),
        messagesHash: { ...state.messagesHash, ...{ [newMessage.id]: prevMessages.length } },
        ...(incrementNotificationsCount && { unreadNotificationsCount: state.unreadNotificationsCount + 1 })
      };
    }
    case 'ADD_MESSAGES': {
      let prevMessages = [...state.messages];
      // Should I be worried about using async forEach ahead of a return statement? If there are a LOT of messages to add... maybe. If it is just in batches of 100 from the graphQL API, we might be OK

      let updatedMessages = [...prevMessages, ...action.payload];
      const messagesHash = setMessagesHash({ messages: updatedMessages, messagesHash: state.messagesHash });
      const resp = addOrUpdateReplyData({
        messages: updatedMessages,
        messagesHash,
        newMessages: action.payload,
        orphanReplies: state.orphanReplies
      });
      let { messages, orphanReplies } = resp;

      return {
        ...state,
        messages,
        orphanReplies,
        displayedMessages: filterThreads({
          messages: updatedMessages
        }),
        messagesHash: { ...state.messagesHash, ...{ [action.payload.id]: prevMessages.length + 1 } }
      };
    }
    case 'UPDATE_MESSAGE': {
      let prevMessages = [...state.messages];

      const hashEntry = state.messagesHash[action.payload.id];

      const messageToUpdate = prevMessages[hashEntry];

      prevMessages[hashEntry] = {
        ...messageToUpdate,
        ...action.payload
      };
      const displayedMessages = filterThreads({
        showUserNotifications: state.inNotificationScreen,
        userId: state.displayedUserId,
        showMessagesByUser: !state.inNotificationsScreen && state.displayedUserId,
        messages: prevMessages
      });
      /* if the updated message is no longer visible AND it is the current anchor
       * set the anchor to the next previous non-hidden neighbor */
      let newMessageAnchor;
      if (messageToUpdate && state.currentMessageAnchor === `message-${messageToUpdate.id}`) {
        const cutMessages = prevMessages.slice(0, hashEntry);
        for (let i = hashEntry - 1; i > -1; i--) {
          let latestMessage = cutMessages[i];
          if (!latestMessage.hidden && !latestMessage.userBlocked) {
            newMessageAnchor = `message-${latestMessage.id}`;
            break;
          }
        }
      }
      return {
        ...state,
        messages: prevMessages,
        displayedMessages,
        ...(newMessageAnchor && { currentMessageAnchor: newMessageAnchor })
      };
    }
    case 'SET_CURRENT_MESSAGE_ANCHOR': {
      return {
        ...state,
        currentMessageAnchor: action.payload
      };
    }
    case 'EXPAND_COMMENT_THREAD': {
      const updatedMessages = state.messages.map(m => {
        return {
          ...m,
          displayThread: m.id === action.payload ? true : m.displayThread
        };
      });
      const messagesToDisplay = filterThreads({
        messages: updatedMessages,
        userId: state.displayedUserId,
        showMessagesByUser: !!state.displayedUserId
      });
      return {
        ...state,
        messages: updatedMessages,
        displayedMessages: messagesToDisplay
      };
    }
    case 'COLLAPSE_COMMENT_THREAD': {
      const updatedMessages = state.messages.map(m => {
        return {
          ...m,
          displayThread: m.id === action.payload ? false : m.displayThread
        };
      });
      const messagesToDisplay = filterThreads({
        messages: updatedMessages,
        userId: state.displayedUserId,
        showMessagesByUser: !!state.displayedUserId
      });
      return {
        ...state,
        messages: updatedMessages,
        displayedMessages: messagesToDisplay
      };
    }
    case 'LEAVE_SUB_SCREEN': {
      return {
        ...state,
        inNotificationScreen: false,
        displayedUserId: null
      };
    }
    case 'RESET_BACK_BUTTON': {
      const messagesToDisplay = filterThreads({ messages: state.messages });
      return {
        ...state,
        displayedUserId: null,
        inNotificationScreen: false,
        displayBackButtonToHome: false,
        displayedMessages: messagesToDisplay
      };
    }
    case 'RESET_MESSAGE_STATE': {
      return { ...state, ...initialState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function setMessagesHash({ messages, messagesHash }) {
  messages.forEach((m, i) => (messagesHash[m.id] = i));
  return messagesHash;
}

function filterThreads({ showMessagesByUser, showUserNotifications, userId, messages }) {
  /* show all messages that don't have a ParentCommentId
   *      if we want to filter by a particular user or notifications, let's do that */

  let filteredMessages;
  if (showMessagesByUser) {
    filteredMessages = messages.filter(m => m.userID === userId);
  } else if (showUserNotifications) {
    filteredMessages = messages.filter(m => m.userToNotifyID === userId);
  } else {
    filteredMessages = messages.filter(m => m.displayed || !m.ParentCommentId);
  }

  return filteredMessages.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
}

function addOrUpdateReplyData({ messages, messagesHash, orphanReplies = {}, newMessages }) {
  /* count number of replies and attach to all relevant parents
   * One O(N) loop to collect data, another at worst O(N/2) loop to assign
   * and it looks like N comes in batches of 100 */
  const replyNumberData = (newMessages ? newMessages : messages).reduce((acc, m) => {
    if (m.ParentCommentId) {
      if (!acc[m.ParentCommentId]) acc[m.ParentCommentId] = 0;
      acc[m.ParentCommentId] += 1;
    }
    return acc;
  }, {});

  if (newMessages) {
    // this comment may HAVE children already in state, let's check and add them
    newMessages.forEach(m => {
      if (Object.keys(orphanReplies).includes(m.id)) {
        messages[messagesHash[m.id]].numOfReplies = orphanReplies[m.id].numOfReplies;
        delete orphanReplies[m.id];
      }
    });
  }

  for (const mID in replyNumberData) {
    try {
      const existingReplyNumber = (newMessages && messages[messagesHash[mID]].numOfReplies) || 0;
      messages[messagesHash[mID]] = {
        ...messages[messagesHash[mID]],
        numOfReplies: existingReplyNumber + replyNumberData[mID]
      };
    } catch (e) {
      if (orphanReplies[mID] && orphanReplies[mID].numOfReplies) {
        orphanReplies[mID].numOfReplies = orphanReplies[mID].numOfReplies + replyNumberData[mID];
      } else {
        orphanReplies[mID] = { numOfReplies: replyNumberData[mID] };
      }
    }
  }
  return { messages, orphanReplies };
}

function MessageProvider({ children }) {
  const [state, dispatch] = React.useReducer(MessageReducer, initialState);
  React.useEffect(() => {
    typeof window !== 'undefined' && localStorage.setItem('message', JSON.stringify(state));
  }, [state]);
  return (
    <MessageStateContext.Provider value={state}>
      <MessageDispatchContext.Provider value={dispatch}>{children}</MessageDispatchContext.Provider>
    </MessageStateContext.Provider>
  );
}

function useMessageState() {
  const context = React.useContext(MessageStateContext);
  if (context === undefined) {
    throw new Error('useMessageState must be used within a MessageProvider');
  }
  return context;
}

function useMessageDispatch() {
  const context = React.useContext(MessageDispatchContext);
  if (context === undefined) {
    throw new Error('useMessageDispatch must be used within a MessageProvider');
  }
  return context;
}

export { MessageProvider, useMessageState, useMessageDispatch };
