import Api from '../../lib/api';
// for development
import {orderOne, giftOrder} from '../../routes/Orders/testData';

// change
const orderMessages = {};

const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
const UPDATE_ORDERS = 'UPDATE_ORDERS';
const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';

// action creators
const updateLoadingStatus = boolean => ({
  type: UPDATE_LOADING_STATUS,
  loading: boolean
});

const updateOrders = orders => ({
  type: UPDATE_ORDERS,
  orders
});

const updateErrorMessage = errorMessageOrCode => {
  let errorMessage = '';
  if (orderMessages[errorMessageOrCode]) {
    errorMessage = orderMessages[errorMessageOrCode].text;
  } else {
    errorMessage = errorMessageOrCode;
  }

  return {
    type: UPDATE_ERROR_MESSAGE,
    errorMessage
  };
};

// async
const loadOrders = () => (dispatch, getState) => {
  dispatch(updateLoadingStatus(true));
  const {authToken} = getState().user;

  // stubbed resp for development
  // dispatch(updateOrders([orderOne, giftOrder]));
  // dispatch(updateLoadingStatus(false));

  // api call
  Api.get('/orders', authToken)
    .then(resp => {
      if (resp.status === undefined) {
        if (resp.gatewayCode) {
          dispatch(updateErrorMessage(resp.gatewayCode));
          return;
        }
        dispatch(updateOrders(resp));
        dispatch(updateLoadingStatus(false));
      } else {
        dispatch(updateLoadingStatus(false));
        dispatch(updateErrorMessage('Error loading orders'));
      }
    })
    .catch(error => {
      dispatch(updateLoadingStatus(false));
      console.log('Load orders error: ', error);
      dispatch(updateErrorMessage('API error loading orders'));
    });
};

const refundOrder = id => (dispatch, getState) => {
  const {authToken} = getState().user;
  // The middle argument, the body, would not be null for partial refunds...
  return Api.post(`/orders/refunds/order/${id}`, null, authToken)
    .then(resp => {
      // Fix Standardize me
      if (!resp.ok) {
        return false;
      } else if (resp.status === undefined) {
        if (resp.gatewayCode) {
          return resp;
        }
      } else {
        console.log('refundOrder success');
        return true;
      }
    })
    .catch(error => {
      console.log('Refund order error: ', error);
      return false;
    });
};

const resendReceipt = id => (dispatch, getState) => {
  const {authToken} = getState().user;
  return Api.post(`/orders/${id}/sendReceipt`, null, authToken)
    .then(resp => {
      // console.log('resp: ', resp);
      // Fix Standardize me
      if (!resp.ok) {
        return false;
      } else if (resp.status === undefined) {
        if (resp.gatewayCode) {
          return false;
        }
      } else {
        console.log('sendReceipt success');
        return true;
      }
    })
    .catch(error => {
      console.log('Resend receipt error: ', error);
      return false;
    });
};

export const actions = {
  loadOrders,
  refundOrder,
  resendReceipt
};

export const initialState = {
  errorMessage: '',
  loading: false,
  orders: []
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.loading
      };

    case UPDATE_ORDERS:
      return {
        ...state,
        orders: action.orders
      };

    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
}
