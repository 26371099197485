import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Icon extends React.Component {
  constructor(props) {
    super(props);

    this.hasIcon = this.hasIcon.bind(this);
    this.getIcon = this.getIcon.bind(this);
  }

  getIcon(item) {
    const {type} = item;

    switch (type) {
      case 'download':
        switch (item.data.type) {
          //to figure out the format of these, look at our src/icons index to get the name
          //the prefix 'far' usually works, if not check https://fontawesome.com/icons/file-pdf to get the exact text
          case 'MP3':
            return ['far', 'file-audio'];
          case 'VIDEO':
            return ['far', 'cloud-download'];
          case 'PDF':
            return ['far', 'file-pdf'];
          case 'ZIP':
            return ['far', 'file'];
          default:
            return ['far', 'cloud-download'];
        }

      default:
        return ['far', 'cloud-download'];
    }
  }
  hasIcon(item) {
    const {hasIcon} = this.props;
    return item.type === 'download' || hasIcon; //TODO, what other lists have icons
  }

  render() {
    //directPass is if the name of the icon is passed in directly, rather than the contentItem
    const directPassIcon = this.props.directPassIcon ? this.props.directPassIcon : null;
    const {contentItem, color, size} = this.props;
    const iconFromContent = contentItem && !directPassIcon ? this.getIcon(contentItem) : null;
    const finalIcon = directPassIcon ? directPassIcon : iconFromContent;

    return finalIcon ? (
      <FontAwesomeIcon icon={finalIcon} fixedWidth color={color ? color : 'black'} size={size ? size : ''} />
    ) : null;
  }
}

export default Icon;
