/* eslint-disable */
import 'core-js/stable';
import './styles/index.scss';

import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import App from 'containers/App';
import store from 'store';
import history from 'store/history';

window.urlsCache = {};

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Component />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
};

renderApp(App);
/* eslint-disable */
// Hot Module Replacement API
/*
if (module.hot) {
  module.hot.accept('containers/App', () => renderApp(App));
}
*/
