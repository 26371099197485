/* eslint-disable */
import React from 'react';
import { useMessageState, useMessageDispatch } from './context/message';
import { Button } from 'reactstrap';

export default function GetLatestMessagesButton() {
  const messageDispatch = useMessageDispatch();
  const messageState = useMessageState();

  return (
    messageState.messagesToLoad &&
    !messageState.displayedUserId && (
      <Button
        className="btn text-uppercase text-white text-800 text-small my-3"
        color="refresh"
        onClick={() => {
          messageDispatch({ type: 'SET_DISPLAYED_MESSAGES', payload: messageState.messages });
        }}
      >
        See Latest Messages
      </Button>
    )
  );
}
