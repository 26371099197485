/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Data} from 'containers/Data';
import ZoomWebinar from 'routes/Products/components/ZoomWebinar';

class ZoomWebinarProduct extends Component {
  render() {
    const productSlug = location.pathname.split('/')[2];
    const {id} = this.props;
    return <Data component={ZoomWebinar} url={`/content/${id}/${productSlug}`} />;
  }
}

ZoomWebinarProduct.propTypes = {
  id: PropTypes.number.isRequired
};

ZoomWebinarProduct.defaultProps = {
  data: {}
};

ZoomWebinarProduct.displayname = 'ZoomWebinarProduct';

export default withRouter(ZoomWebinarProduct);
