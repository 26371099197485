import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import EditUserInfo from './components/EditUserInfo';

@connect(
  state => ({user: state.user}),
  {updateProfile: actions.updateProfile}
)
class EditUserInfoModal extends Component {
  render() {
    return (
      <Modal centered isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Edit Profile</ModalHeader>
        <ModalBody>
          <EditUserInfo
            isCS={this.props.isCS}
            updateProfile={this.props.updateProfile}
            onCancel={this.props.toggle}
            {...this.props}
            onSuccess={() => {
              this.props.onSuccess && this.props.onSuccess();
              this.props.toggle();
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

EditUserInfoModal.propTypes = {};
//   updateProfile: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     firstName: PropTypes.string.isRequired,
//     lastName: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired
//   }).isRequired
// };

export default EditUserInfoModal;
