import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {actions} from 'modules/modal';
import {actions as userActions} from 'modules/user';
import {actions as applicationActions} from 'modules/application';
import {Navbar, NavbarBrand} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HeaderUserInfo from './components/HeaderUserInfo';
import styles from 'styles/index.scss';

const {appHeaderContainer, appNavBarBrand, appNavBarBarIcon} = styles;

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const path = location.pathname.split('/');
    const isCheckout = path[1] === 'checkout';
    const {firstName, img, isAuthenticated, logoutUser, openModal, showSideBar, toggleSideBar} = this.props;

    return (
      <Navbar className={appHeaderContainer}>
        {!isCheckout ? (
          showSideBar ? (
            <div style={{color: 'transparent'}}>.</div>
          ) : (
            <FontAwesomeIcon onClick={toggleSideBar} className={appNavBarBarIcon} icon="bars" />
          )
        ) : (
          <div style={{color: 'transparent'}}>.</div>
        )}
        <div>
          {isAuthenticated ? (
            <HeaderUserInfo firstName={firstName} img={img} />
          ) : (
            <NavbarBrand href="/" className="mr-auto" className={appNavBarBrand}>
              Food Revolution Network
            </NavbarBrand>
          )}
        </div>
      </Navbar>
    );
  }
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  img: state.user.avatarUrl,
  firstName: state.user.firstName,
  showSideBar: state.application.showSideBar
});

const mapDispatchToProps = {
  logoutUser: userActions.logoutUser,
  openModal: actions.openModal,
  toggleSideBar: applicationActions.toggleSideBar
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
