import React from 'react';
import PropTypes from 'prop-types';
import {Fraction} from 'fractional';
import IngredientListItem from './IngredientListItem';
import styles from 'styles/index.scss';
import pluralize from 'pluralize';
const {recipeIngredientPrintable} = styles;

//used to pluralize the name of the ingredient
//used pluralize npm
//exception:  ingredients with parenthesis () are ignored
//exception: ingredients with more than one name (i.e. sea cucumber) only the last word is pluralized

const _pluralize = name => {
  if (!name) return ' '; //space for display
  if (name && name.match(/[)]/g)) return name;

  let nameArray = name ? name.split(' ') : [];
  //special characters throw off pluralize (TODO find full set)

  if (nameArray.length > 1) {
    let nameEnding = nameArray.pop();
    name = nameArray.join(' ') + ' ' + pluralize(nameEnding);
    return name;
  } else {
    return pluralize(name);
  }
};
//rendundant but here for clarity.  may refactor in future
const _handleMeasurementString = (measurement, originalServings, currentServings) => {
  return measurement && currentServings && originalServings
    ? new Fraction(measurement).multiply(new Fraction(parseInt(currentServings), parseInt(originalServings))).toString()
    : '';
};
const _handleMeasurementNumber = (measurement, originalServings, currentServings) => {
  return measurement && currentServings && originalServings
    ? new Fraction(measurement).multiply(new Fraction(parseInt(currentServings), parseInt(originalServings)))
    : '';
};

//PLURALIZE/MEASUREMENT NOTES
//basically, this checks if the measurement(s) are greater than 1
//if so, we pluralize the units (ounce to ounces) and the ingredient name (cucumber to cucumbers)
const Ingredients = props => {
  const ingredients = props.ingredients.map((ingredient, index) => {
    const {measurement, units, name, note, url, singular} = ingredient;
    //zp originalServing is the num of servings the recipe was written for
    //current servings is the number of serving the user has selected at the moment
    //servings is the legacy code at the momen, it is the servingsMultiplier in decimal format
    const {originalServings, currentServings} = props;
    let _measurement, _measurementNumber;
    //for range measurements like 1/2 - 1,   otherwise just convert
    //1. split at "-"
    //2. convert each one to correct _measurment
    //3. join separate measurements with "-"
    //_measurement = isNaN(measurement) ? measurement : _measurement;
    if (measurement && measurement.match(/[-]/g)) {
      //if range
      const range = measurement.split('-');
      _measurement = range
        .map(item => {
          return _handleMeasurementString(item.trim(), originalServings, currentServings);
        })
        .join('-');
      console.log('_measurment after ranges ', _measurement);
      //set measurment number to upper range since we just use it to determine pluralization
      _measurementNumber = _handleMeasurementNumber(range[1], originalServings, currentServings);
    } else {
      //else normal measurment like 1, 1/3, 1 2/5  etc
      //5 small Onions = measurment(5) + units(small) + name (onion)
      //uses Fractional npm.  allows us to multiply fractions easily
      _measurement = _handleMeasurementString(measurement, originalServings, currentServings);
      //for use as a number instead of display
      _measurementNumber = _handleMeasurementNumber(measurement, originalServings, currentServings);
    }
    const _units =
      !singular &&
      _measurementNumber &&
      _measurementNumber.denominator &&
      units &&
      _measurementNumber.numerator > _measurementNumber.denominator &&
      units.length
        ? pluralize(units)
        : units;

    //if singular and measuerment > 1, pluralize name
    const _name =
      singular && _measurementNumber && _measurementNumber.numerator > _measurementNumber.denominator
        ? _pluralize(name)
        : name;
    // console.log('_units ', _units, ' _name ', _name);
    // console.log(
    //   '_measurement for ',
    //   name,
    //   _measurement,
    //   'greater thatn one?',
    //   _measurementNumber.numerator > _measurementNumber.denominator
    // );
    const hackyIndex = index + 200;

    return {
      key: hackyIndex,
      number: _measurement ? _measurement.toString() : '',
      units: _units,
      name: _name,
      note: note,
      url: url
    };
  });
  // zp return a printable list and a list for the UI
  return (
    <div>
      <ul className={recipeIngredientPrintable}>
        {ingredients.map(ingredient => (
          <li>
            {ingredient.number} {ingredient.units} {ingredient.name} {ingredient.note}
          </li>
        ))}
      </ul>
      {ingredients.map(ingredient => (
        <IngredientListItem {...ingredient} />
      ))}
    </div>
  );
};

Ingredients.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      measurement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      units: PropTypes.string,
      name: PropTypes.string.isRequired,
      note: PropTypes.string
    })
  ).isRequired
};

export default Ingredients;
