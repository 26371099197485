import React from 'react';
import classNames from 'classnames';

export default function Angle(props) {
  return (
    <div className={classNames(`fill-angle fill-angle-bottom`, props.className)}>
      <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
        <polygon points="0,0 500,0 500,50" className={classNames(`fill-polygon fill-${props.color}`)}></polygon>
        <defs>
          <linearGradient id="gradient-indigo-purple-right">
            <stop offset="0" stopColor="#6f00a2"/>
            <stop offset="1" stopColor="#7d00b5"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}


export function AngleTop(props) {
  return (
    <div className={classNames(`fill-angle fill-angle-top`, props.className)}>
      <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
        <polygon points="0,0 0,50 500,50" className={classNames(`fill-polygon fill-${props.color}`)}></polygon>
        <defs>
          <linearGradient id="gradient-indigo-purple-right">
            <stop offset="0" stopColor="#6f00a2"/>
            <stop offset="1" stopColor="#7d00b5"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}