import {bonusDownloadOne, bonusDownloadTwo} from '../Downloads/testData';
const bonus = {
  displayProps: {
    title: 'Bonus - “Ask The Experts” Live Call',
    subtitle: 'Get Answers to Your Questions About Food and Health',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Bonus'
    }
  },
  id: 4601,
  name: 'Bonus - “Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
  title: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
  slug: 'bonus-ask-the-experts-special-event-greger-davis',
  type: 'bonus',
  data: {
    from: 'John Robbins, Michael Greger, MD, and Brenda Davis, RD — hosted by Ocean Robbins'
  },
  published: true,
  free: false,
  ContentTags: [],
  Children: [
    {
      id: 4602,
      name: 'Link - “Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
      title: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
      slug: 'ask-the-experts-special-event-greger-davis',
      type: 'link',
      data: {
        thumb: {
          alt: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
          url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/ask-the-experts-special-event-greger-davis.jpg'
        },
        description:
          'Join John, Ocean, Michael and Brenda for a practical, tangible, and informative group coaching call to get YOUR questions answered and to help you put the Food Revolution into action in your life.\r\n\r\nYou can ask your burning questions, get top tips, connect with fellow food revolutionaries, and receive support where you need it most. You’ll be able to join in live from 8:30 pm Eastern time (5:30 pm Pacific) on May 24, or you can submit questions in advance and enjoy the replay any time you want. Be sure to mark your calendar now!'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: []
    }
  ],
  childrenIndexes: {
    link: [0]
  }
};
export const bonuses = {
  displayProps: {
    title: 'Bonuses',
    subtitle: 'Bonuses',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Bonuses'
    }
  },
  type: 'bonuses',
  title: 'Bonuses',
  name: 'Bonuses One',
  slug: 'bonuses',
  data: {
    thumb: {
      text: 'Bonuses',
      url: 'http://via.placeholder.com/350x150',
      alt: 'great bonus thumb pic',
      link: '/bonuses',
      contentCardDescription:
        'text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text text '
    }
  },

  Children: [bonus, bonus, bonus, bonus]
};

export const bonusesTwo = {
  id: 4578,
  name: 'FRS18 Empowerment Package - Bonuses',
  title: '2018 Food Revolution Empowerment Package Bonuses',
  slug: 'frs18-bonuses-full',
  type: 'bonuses',
  data: {
    description:
      'These bonuses are gifts from the Food Revolution Network’s speakers, partners, and friends. There’s something here, among these fantastic tools and resources, for just about everybody.\r\n\r\nWe haven’t personally and fully vetted all of these programs, and some may offer perspectives that may or may not be consistent with the Food Revolution Network in some areas.\r\n\r\nWe would love to hear what you think of the specific bonuses. You can click the “Contact Us” button at the top of this page to share your feedback or to let us know if you have any issues with your bonuses. We’re here to help!'
  },
  published: true,
  free: false,
  Children: [
    {
      id: 4601,
      name: 'Bonus - “Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
      title: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
      slug: 'bonus-ask-the-experts-special-event-greger-davis',
      type: 'bonus',
      data: {
        from: 'John Robbins, Michael Greger, MD, and Brenda Davis, RD — hosted by Ocean Robbins'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4602,
          name: 'Link - “Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
          title: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
          slug: 'ask-the-experts-special-event-greger-davis',
          type: 'link',
          data: {
            thumb: {
              alt: '“Ask The Experts” Live Call — Get Answers to Your Questions About Food and Health',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/ask-the-experts-special-event-greger-davis.jpg'
            },
            description:
              'Join John, Ocean, Michael and Brenda for a practical, tangible, and informative group coaching call to get YOUR questions answered and to help you put the Food Revolution into action in your life.\r\n\r\nYou can ask your burning questions, get top tips, connect with fellow food revolutionaries, and receive support where you need it most. You’ll be able to join in live from 8:30 pm Eastern time (5:30 pm Pacific) on May 24, or you can submit questions in advance and enjoy the replay any time you want. Be sure to mark your calendar now!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4188,
      name: 'Bonus - GMO Summit Empowerment Package',
      title: 'GMO Summit Empowerment Package',
      slug: 'bonus-gmo-summit-empowerment-package',
      type: 'bonus',
      data: {
        from: 'John and Ocean Robbins',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4209,
          name: 'Link - GMO Summit Empowerment Package',
          title: 'GMO Summit Empowerment Package',
          slug: 'gmo-summit-empowerment-package',
          type: 'link',
          data: {
            url: 'https://members.foodrevolution.org/gmo/',
            thumb: {
              alt: 'GMO Summit Empowerment Package',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/gmo-summit-empowerment-package.jpg'
            },
            description:
              'Get the truth about GMOs, so you can understand what they are and their impact on your health and the world.\n\nWith this Package, you get to own full audio recordings and written transcripts for all 12 powerful presentations. You can access them online anytime you want or download them and take them with you, so you can learn how to protect yourself and your family.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 3973,
      name: 'Bonus - 13 Steps To A Cancer-Free Kitchen',
      title: '13 Steps To A Cancer-Free Kitchen',
      slug: 'bonus-13-steps-to-a-cancer-free-kitchen',
      type: 'bonus',
      data: {
        from: 'Gina Bonanno-Lemos of 360 Health Connection',
        value: 15
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 3974,
          name: 'Download - 13 Steps To A Cancer-Free Kitchen',
          title: '13 Steps To A Cancer-Free Kitchen',
          slug: '13-steps-to-a-cancer-free-kitchen',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/13%20Steps%20to%20a%20Cancer%20Free%20Kitchen.pdf',
            thumb: {
              alt: '13 Steps To A Cancer-Free Kitchen',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/13-steps-to-a-cancer-free-kitchen.jpg'
            },
            description:
              "This ebook gives you a concise guide to discovering hidden and unsuspected carcinogens in your kitchen.You'll find out how to easily avoid and eliminate these unknown dangers by substituting non-toxic alternatives.\n\nYou'll also discover the secret, disease-fighting items that you probably have in your kitchen at this very moment, so you can put these cell-healers to work immediately to protect yourself and your loved ones."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4582,
      name: 'Bonus - Brain Revitalizing Actions & Improving Neurotransmitters (BRAIN) Health Program',
      title: 'Brain Revitalizing Actions & Improving Neurotransmitters (BRAIN) Health Program',
      slug: 'bonus-brain-revitalizing-actions-and-improving-neurotransmitters-brain-health-program',
      type: 'bonus',
      data: {
        from: 'Dr. Ritamarie Loscalzo',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4589,
          name: 'Link - Brain Revitalizing Actions & Improving Neurotransmitters (BRAIN) Health Program',
          title: 'Brain Revitalizing Actions & Improving Neurotransmitters (BRAIN) Health Program',
          slug: 'brain-revitalizing-actions-and-improving-neurotransmitters-brain-health-program',
          type: 'link',
          data: {
            url: 'http://drritamarie.com/vibrant/brainfreeaccess',
            thumb: {
              alt: 'Brain Revitalizing Actions & Improving Neurotransmitters (BRAIN) Health Program',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/brain-revitalizing-actions-and-improving-neurotransmitters-brain-health-program.jpg'
            },
            description:
              'Want to enjoy optimum focus, memory, and mood? BRAIN is a step-by-step, self-paced program that offers you a clear picture of your brain health — and exactly what to do to optimize it. Each part of your brain and nervous system has a unique function, and all the parts need to work together for you.\r\n\r\nThis program offers simple strategies to optimize your brain function, stabilize moods, and increase memory and focus. You’ll get a score for your brain health, education on how the major parts of your brain work, and information about conditions that could indicate brain-chemistry imbalances, such as sluggishness, anxiety, brain fog, depression, and memory loss. You’ll also learn which foods, nutrients, and herbs are most important for restoring and maintaining optimum brain functions. And you’ll get an action plan to boost your brain and nourish your neurotransmitters to give you a head start on health.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4189,
      name: 'Bonus - GMO: OMG! What Everyone Needs to Know',
      title: 'GMO: OMG! What Everyone Needs to Know',
      slug: 'bonus-gmo-omg-what-everyone-needs-to-know',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4210,
          name: 'Download - GMO: OMG! What Everyone Needs to Know',
          title: 'GMO: OMG! What Everyone Needs to Know',
          slug: 'gmo-omg-what-everyone-needs-to-know',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/GMO%20OMG%21%20What%20Everyone%20Needs%20to%20Know.pdf',
            thumb: {
              alt: 'GMO: OMG! What Everyone Needs to Know',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/gmo-omg-what-everyone-needs-to-know.jpg'
            },
            description:
              "What exactly are GMOs? And are they dangerous to your health and the health of the planet? This report offers impeccably researched information about the impact of GMOs on our food supply and the environment. You'll also find out which foods are most likely to be genetically modified, which ones are likely to be in the next few years, and the simple trick you can use at the grocery store to avoid GMOs, insecticides and herbicides, even if you're on a tight budget."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4190,
      name: 'Bonus - Brain Food: 8 Superfoods Your Brain Will Love',
      title: 'Brain Food: 8 Superfoods Your Brain Will Love',
      slug: 'bonus-brain-food-8-superfoods-your-brain-will-love',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4211,
          name: 'Download - Brain Food: 8 Superfoods Your Brain Will Love',
          title: 'Brain Food: 8 Superfoods Your Brain Will Love',
          slug: 'brain-food-8-superfoods-your-brain-will-love',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/Brain%20Food%208%20Superfoods%20Your%20Brain%20Will%20Love.pdf',
            thumb: {
              alt: 'Brain Food: 8 Superfoods Your Brain Will Love',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/brain-food-8-superfoods-your-brain-will-love.jpg'
            },
            description:
              "If you're over 50, the groundwork for dementia may already be in your brain. The good news is, there's a lot you can do to prevent or even reverse this process and to boost brain performance.\n\nThis report outlines the scientifically-proven ways you can use food to regenerate brain cells and radically enhance your brain health. You'll get a list of eight superfoods that feed your brain as well as delicious “comfort food” recipes which feature these superfoods and that your entire family will love."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4197,
      name: 'Bonus - Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
      title: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
      slug: 'bonus-eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 482,
          name: 'Download - Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
          title: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
          slug: 'eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/Eat%20to%20Defeat%20Cancer%2010%20Superfoods%20That%20Nourish%20Every%20Cell.pdf',
            thumb: {
              alt: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell.jpg'
            },
            description:
              "Cancer is the most feared disease on the planet. The good news is that there's a growing body of research which suggests that a diet rich in certain foods can help prevent or even reverse many forms of cancer.\n\nThis report contains a detailed list of 10 superfoods that have documented cancer-fighting capabilities and improve overall cell health, so you can enjoy delicious foods while reducing your cancer risk. You'll also learn which foods have been correlated with an increased risk of cancer, so you can eliminate or reduce them from your diet."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4201,
      name: 'Bonus - Decoding Food Labels',
      title: 'Decoding Food Labels',
      slug: 'bonus-decoding-food-labels',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4225,
          name: 'Download - Decoding Food Labels',
          title: 'Decoding Food Labels',
          slug: 'decoding-food-labels',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Decoding%20Food%20Labels.pdf',
            thumb: {
              alt: 'Decoding Food Labels',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/decoding-food-labels.jpg'
            },
            description:
              "Are you confused about what certain words mean on food labels? For example, what's the difference between “natural”, “Non-GMO”, and “organic”? What are the standards by which a food can be advertised as “healthy” or as “humane”? And how can you tell if a food has hidden sugar or animal products?\n\nIn this report, you'll learn how to decode product claims and nutrition labels, understand ingredients, and become an informed consumer. Now you'll be truly informed, and better able to make the healthiest choices for yourself, your loved ones, and for the planet."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4579,
      name: 'Bonus - Real Food Action Guide: Restore Your Health and Save the Planet',
      title: 'Real Food Action Guide: Restore Your Health and Save the Planet',
      slug: 'bonus-real-food-action-guide-restore-your-health-and-save-the-planet',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4586,
          name: 'Download - Real Food Action Guide: Restore Your Health and Save the Planet',
          title: 'Real Food Action Guide: Restore Your Health and Save the Planet',
          slug: 'real-food-action-guide-restore-your-health-and-save-the-planet',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/The%20Real%20Food%20Action%20Guide.pdf',
            thumb: {
              alt: 'Real Food Action Guide: Restore Your Health and Save the Planet',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/real-food-action-guide-restore-your-health-and-save-the-planet.jpg'
            },
            description:
              'Get informed. Get inspired. Take action.\r\n\r\nThe Real Food Action Guide is your ticket to ongoing healthy success after the Food Revolution Summit ends! It includes a handy two-page food fact sheet with some critical must-have information about why we need a food revolution, solutions to our food problems, action steps you can take, and an inspiring and galvanizing conversation between John and Ocean Robbins.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4203,
      name: 'Bonus - The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
      title: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
      slug: 'bonus-ornish-lifestyle-medicine',
      type: 'bonus',
      data: {
        from: 'From Dean Ornish, MD'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 2351,
          name: 'Download - The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
          title: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
          slug: 'ornish-lifestyle-medicine',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Ornish%20Lifestyle%20Medicine.pdf',
            thumb: {
              alt: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/ornish-lifestyle-medicine.jpg'
            },
            description:
              'If avoiding or reversing chronic disease is something you’re interested in for you or your loved ones, this comprehensive guidebook can help you get started on the path to making healthy, sustainable diet and lifestyle changes.\n\nOrnish Lifestyle Medicine has been scientifically proven to reverse the progression of chronic diseases, including even severe heart disease, type 2 diabetes, high blood pressure, and others — and following these guidelines is sure to help everyone thrive, no matter how healthy you are now.\n\nIn this guidebook, you’ll get specific guidelines for nutrition, as well as exercise, stress management, and giving and receiving love and support. When you follow this way of living, you’ll be amazed at how quickly changes can occur and how dramatically.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4186,
      name: 'Bonus - 14-Day Bright Line Eating Food Freedom Challenge Course',
      title: '14-Day Bright Line Eating Food Freedom Challenge Course',
      slug: 'bonus-14-day-bright-line-eating-food-freedom-challenge-course',
      type: 'bonus',
      data: {
        from: 'Susan Peirce Thompson, PhD',
        value: 29
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4208,
          name: 'Link - 14-Day Bright Line Eating Food Freedom Challenge Course',
          title: '14-Day Bright Line Eating Food Freedom Challenge Course',
          slug: '14-day-bright-line-eating-food-freedom-challenge-course',
          type: 'link',
          data: {
            url: 'https://rq263.isrefer.com/go/frn19bonus/Ocean/',
            thumb: {
              alt: '14-Day Bright Line Eating Food Freedom Challenge Course',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/14-day-bright-line-eating-food-freedom-challenge-course.jpg'
            },
            description:
              'Do you want to shed any excess pounds and keep them off long term? Learn from Susan Peirce Thompson, Ph.D., why your brain may be blocking you from losing weight — and what you can do about it!\n\nWith her 14-Day Bright Line Eating Food Freedom Challenge Course, she’s daring you to discover what it takes to make a healthy weight your new normal. You’ll learn why so many people have trouble releasing weight and what the most successful weight loss maintainers are doing differently.\n\nAre you up for the challenge?'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 3971,
      name: 'Bonus - Crazy Sexy Kitchen Toolkit',
      title: 'Crazy Sexy Kitchen Toolkit',
      slug: 'bonus-crazy-sexy-kitchen-toolkit',
      type: 'bonus',
      data: {
        from: 'Kris Carr',
        value: 50
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 3972,
          name: 'Bonus - Crazy Sexy Kitchen Toolkit',
          title: 'Crazy Sexy Kitchen Toolkit',
          slug: 'crazy-sexy-kitchen-toolkit',
          type: 'link',
          data: {
            url: 'http://kriscarr.com/frs-cookingclasses/',
            thumb: {
              alt: 'Crazy Sexy Kitchen Toolkit',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/crazy-sexy-kitchen-toolkit.jpg'
            },
            description:
              'Kris’ book, Crazy Sexy Kitchen, is a jaw droppingly delicious veggie manifesto for gourmands and novices alike – filled with inspiration, education, and cooking tips – plus more than 150 nourishing, plant-passionate recipes to help you lose weight, feel great, and get your sexy on!\n\nWanna take a tour of Crazy Sexy Kitchen? Kris created this gorgeous, digital overview of some of her favorite sections and 13 recipes from the book, PLUS you’ll get access to 6 special online videos to guide you in shopping for healthy food, juicing, and quick and easy salad dressings, how to make a delicious recipe from the book, and more!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4187,
      name: 'Bonus - The Tapping Solution for Pain Relief',
      title: 'The Tapping Solution for Pain Relief',
      slug: 'bonus-the-tapping-solution-for-pain-relief',
      type: 'bonus',
      data: {
        from: 'the Tapping Solution',
        value: 147
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4206,
          name: 'Link - The Tapping Solution for Pain Relief',
          title: 'The Tapping Solution for Pain Relief',
          slug: 'the-tapping-solution-for-pain-relief',
          type: 'link',
          data: {
            url: 'https://www.thetappingsolution.com/pain-relief/frs-bonus.php',
            thumb: {
              alt: 'The Tapping Solution for Pain Relief',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-tapping-solution-for-pain-relief.jpg'
            },
            description:
              'Use Password: foodrevolution\n\nIf you or anyone you love is suffering from chronic pain, this 16-session, 3-day program could help.\n\nHave you ever wondered what the root cause of your pain was? Surprisingly, there is often an emotional component underlying or intensifying chronic pain. With this course, you’ll discover the connection between your pain and your emotions with help from the Emotional Freedom Technique (EFT). With guidance from the bestselling The Tapping Solution author Nick Ortner, you’ll learn to work through blocked emotions that could be fueling chronic pain, by tapping on specific energy meridians throughout your body. You’ll discover how to make greater peace with your past, and find physical and emotional relief in the present.\n\nThe course gives you access to all three days of this important, life-changing program including the MP3s, transcripts, and exclusive Facebook group.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4595,
      name: 'Bonus - Winter of Wellness Vital Health Collection',
      title: 'Winter of Wellness Vital Health Collection',
      slug: 'bonus-winter-of-wellness-vital-health-collection',
      type: 'bonus',
      data: {
        from: 'the Tapping Solution',
        value: 147
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4598,
          name: 'Link - Winter of Wellness Vital Health Collection',
          title: 'Winter of Wellness Vital Health Collection',
          slug: 'winter-of-wellness-vital-health-collection',
          type: 'link',
          data: {
            url: 'http://winterofwellness.com/FoodRevolutionPackage',
            thumb: {
              alt: 'Winter of Wellness Vital Health Collection',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/winter-of-wellness-vital-health-collection.jpg'
            },
            description:
              'Use Password: foodrevolution\n\nIf you or anyone you love is suffering from chronic pain, this 16-session, 3-day program could help.\n\nHave you ever wondered what the root cause of your pain was? Surprisingly, there is often an emotional component underlying or intensifying chronic pain. With this course, you’ll discover the connection between your pain and your emotions with help from the Emotional Freedom Technique (EFT). With guidance from the bestselling The Tapping Solution author Nick Ortner, you’ll learn to work through blocked emotions that could be fueling chronic pain, by tapping on specific energy meridians throughout your body. You’ll discover how to make greater peace with your past, and find physical and emotional relief in the present.\n\nThe course gives you access to all three days of this important, life-changing program including the MP3s, transcripts, and exclusive Facebook group.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4224,
      name: 'Bonus - Five Phases: The Nature of Your Health Audio Course',
      title: 'Five Phases: The Nature of Your Health Audio Course',
      slug: 'bonus-five-phases-the-nature-of-your-health-audio-course',
      type: 'bonus',
      data: {
        from: 'LearningHerbs',
        value: 97
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4232,
          name: 'Download - Five Phases: The Nature of Your Health Audio Course',
          title: 'Five Phases: The Nature of Your Health Audio Course',
          slug: 'five-phases-the-nature-of-your-health-audio-course',
          type: 'download',
          data: {
            thumb: {
              alt: 'Five Phases: The Nature of Your Health Audio Course',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/five-phases-the-nature-of-your-health-audio-course.jpg'
            },
            description:
              'All around us, mighty healers grow in nature. For example, you might have heard that hawthorn lowers blood pressure. Willow relieves arthritis. Dandelion supports the liver. But is there more to health than taking this for that? How can we adopt a more holistic perspective towards healing?\n\nIn this 10-part audio series, you’ll learn the basic philosophy of the Five Phases and the five elements of this system. The Five Phases philosophy is a system from traditional Chinese Medicine, which teaches that good health is found by living in harmony with the seasons. With each element, you’ll learn practical ways to support the water, wood, fire, earth, or metal in your life using lifestyle habits, food choices, and herbs. \n\nAs a result, you’ll learn more about your gifts, challenges, and ways to understand how your personal health can be influenced through the Five Phases.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4594,
      name: 'Bonus - The Dairy-Freedom Cookbook',
      title: 'The Dairy-Freedom Cookbook',
      slug: 'bonus-the-dairy-freedom-cookbook',
      type: 'bonus',
      data: {
        from: 'Allison Rivers Samson and Michelle Cehn',
        value: 5
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4599,
          name: 'Download - The Dairy-Freedom Cookbook',
          title: 'The Dairy-Freedom Cookbook',
          slug: 'the-dairy-freedom-cookbook',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Ornish%20Lifestyle%20Medicine.pdf',
            thumb: {
              alt: 'The Dairy-Freedom Cookbook',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-dairy-freedom-cookbook.jpg'
            },
            description:
              "Ready to infuse your kitchen with delicious plant-based foods that satisfy all your creamy desires? The Dairy-Freedom Cookbook will help you do just that! With this collection of 30 deliciously dairy-free recipes, you'll learn how to whip up your own nut milk, better butter, moo-free cheese, and beyond.\r\n\r\nWho knew that simple, whole-food ingredients like cashews, turmeric, garlic, and onion could be blended up to make plant-based cheeses, sauces, and more? You gotta try it to believe it! Every dish—from the Magic Mac & Cheese to the Cream of Broccoli Soup—is reminiscent of your dairy-favorites but made from plants that nourish your body and soul. You'll also enjoy two themed dinner menus to show you how to pull together a completely dairy-free meal from appetizers to dessert.\r\n\r\nThis cookbook comes to you from the creators of The Dairy Detox—Allison Rivers Samson and Michelle Cehn—two women passionate about showing you just what humble plants can do. Cheers to that!"
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4596,
      name: 'Bonus - 3 Keys to Getting Fit',
      title: '3 Keys to Getting Fit',
      slug: 'bonus-3-keys-to-getting-fit',
      type: 'bonus',
      data: {
        from: 'Michelle Melendez',
        value: 47
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4600,
          name: 'Link - 3 Keys to Getting Fit',
          title: '3 Keys to Getting Fit',
          slug: '3-keys-to-getting-fit',
          type: 'link',
          data: {
            url: 'https://womenbeingfit.com/freegift/',
            thumb: {
              alt: '3 Keys to Getting Fit',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/3-keys-to-getting-fit.jpg'
            },
            description:
              'Do you struggle with starting or sticking with a regular exercise program? Do you want to get fit and feel good about your body? Discover 3 crucial keys to stay motivated with your exercise routine this year!\r\n\r\nMichelle Melendez founder of Women Being Fit, presents an easy to follow guide that will help you stop procrastinating by focusing on both fitness and emotional goals. 3 Keys To Getting Fit will give you inspire you to start a regular and enjoyable exercise program, so you can unlock the fitness you desire!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4584,
      name: 'Bonus - Cultured Superfood Course',
      title: 'Cultured Superfood Course',
      slug: 'bonus-cultured-superfood-course',
      type: 'bonus',
      data: {
        from: 'Chef Teton',
        value: 87
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4591,
          name: 'Link - Cultured Superfood Course',
          title: 'Cultured Superfood Course',
          slug: 'cultured-superfood-course',
          type: 'link',
          data: {
            url: 'http://www.rawcookedandcultured.com/product/food-revolutions-2018/',
            thumb: {
              alt: 'Cultured Superfood Course',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/cultured-superfood-course.jpg'
            },
            description:
              'Want to make your own cultured superfoods? Chef Susan Teton will show you how to make probiotic and enzyme rich cultured veggies, beet kvass, yogurt, kefir, and fermented grains.\r\n\r\nIn this online video course, Chef Susan Teton will show you how delicious, healthful and nurturing foods are easily harvested and prepared. You’ll learn the precise steps to ensure that your fermentation process is a delicious and nutritious success!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4593,
      name: 'Bonus - Dessert Plantz eCookbook',
      title: 'Dessert Plantz eCookbook',
      slug: 'bonus-dessert-plantz-ecookbook',
      type: 'bonus',
      data: {
        from: 'Plant-Based Katie Mae',
        value: 10
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4597,
          name: 'Link - Dessert Plantz eCookbook',
          title: 'Dessert Plantz eCookbook',
          slug: 'dessert-plantz-ecookbook',
          type: 'link',
          data: {
            url: 'http://plantbasedkatie.com/foodrevolution',
            thumb: {
              alt: 'Dessert Plantz eCookbook',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/dessert-plantz-ecookbook.jpg'
            },
            description:
              'Learn how to make deliciously sweet desserts that are fabulously healthy. The Dessert Plantz eCookbook is full of 60+ recipes that are whole food, plant-based, and also free of all refined oil, sugar, salt, and gluten. Katie Mae, has a Master’s in Nutrition from Bastyr University, and is a Cooking Instructor at TrueNorth Health and Dr. McDougall’s Health & Medical Center.\r\n\r\nShe created this electronic cookbook to help you get away from the guilt that comes with traditional desserts, and to give you a way to celebrate with desserts that are nourishing to your body. Now you can joyfully avoid the overeating, weight-gain, sugar crashes, and food comas. Eat like you matter AND make it delicious!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4229,
      name: 'Bonus - How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
      title: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
      slug: 'bonus-how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days',
      type: 'bonus',
      data: {
        from: 'Stacey Murphy',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4234,
          name: 'Link - How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
          title: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
          slug: 'how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days',
          type: 'link',
          data: {
            url: 'https://growyourownvegetables.org/food-revolution-400/',
            thumb: {
              alt: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days.jpg'
            },
            description:
              'Do you want fresh, homegrown greens and other healthful veggies on your dinner table?\n\nWith master gardening teacher Stacey Murphy’s step-by-step videos, you can learn how to grow your own vegetables and herbs either indoors or outdoors. Challenge yourself to see how much food YOU can grow for your dinner table while spending under an hour per week.\n\nWatch over Stacey’s shoulders as she cultivates a brand new garden space of less than 100 square feet. You’ll discover the most efficient ways to plant and harvest in order to get fast, fresh food. Set your own growing challenge and post your garden photos to the class for bragging rights. This class includes 16 short videos (total of 4 hours) and a workbook to plan your own garden challenge and track your progress.\n\nYIELD Disclaimer: Your yields are up to you. Stacey can support by providing great content and instructions. Any resource budget numbers referenced are estimates; your local conditions WILL vary.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4200,
      name: 'Bonus - Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
      title: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
      slug: 'bonus-jump-start-your-urban-farm',
      type: 'bonus',
      data: {
        from: 'Greg Peterson',
        value: 97
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4218,
          name: 'Link - Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
          title: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
          slug: 'jump-start-your-urban-farm',
          type: 'link',
          data: {
            url: 'https://urbanfarm.leadpages.co/jump-start-summit/',
            thumb: {
              alt: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/jump-start-your-urban-farm.jpg'
            },
            description:
              "This online course consists of five, 1.5-hour class sessions taught by urban farming and permaculture expert Greg Peterson, who has been growing food in his front and backyard for over four decades.\n\nWith Greg’s step-by-step guidance, it won’t be long before you’re cultivating your own edible yard. Ready to join the growing revolution?\n\nYou'll learn:\nThe powerful distinction between “regenerative” and “sustainable”\nThe 3 essential steps to becoming an urban farmer\nHow to use permaculture principles to examine your space and plan your urban farm or garden\nWhy growing your own food is one of the most revolutionary ways to change the world"
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4580,
      name: 'Bonus - Natural Medicine Healing and Wellness Summit Package',
      title: 'Natural Medicine Healing and Wellness Summit Package',
      slug: 'bonus-natural-medicine-healing-and-wellness-summit-package',
      type: 'bonus',
      data: {
        from: 'Dr. Michael Murray',
        value: 200
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4587,
          name: 'Link - Natural Medicine Healing and Wellness Summit Package',
          title: 'Natural Medicine Healing and Wellness Summit Package',
          slug: 'natural-medicine-healing-and-wellness-summit-package',
          type: 'link',
          data: {
            url: 'https://doctormurray.mykajabi.com/offers/H5uSxmdM',
            thumb: {
              alt: 'Natural Medicine Healing and Wellness Summit Package',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/natural-medicine-healing-and-wellness-summit-package.jpg'
            },
            description:
              'Do you want to learn the secrets of health and wellness from the world’s most respected leaders? Then, this package is for you.\r\n\r\nThrough 30 expert speaker talks, you’ll discover how to feel younger, reverse disease, and live better. You’ll get useful advice and powerful, easy to implement, daily strategies for vibrant health. Plus, you’ll learn how to naturally treat health conditions, such as diabetes, heart disease, infertility, and hormone imbalances, while receiving accurate, truthful, and honest information about health and wellness.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4226,
      name:
        'Bonus - No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
      title: 'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
      slug: 'bonus-no-meat-athlete-marathon-roadmap',
      type: 'bonus',
      data: {
        from: 'No Meat Athlete',
        value: 67
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4233,
          name:
            'Link - No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
          title:
            'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
          slug: 'no-meat-athlete-marathon-roadmap',
          type: 'link',
          data: {
            url: 'http://www.nomeatathlete.com/summit-bonus',
            thumb: {
              alt:
                'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/no-meat-athlete-marathon-roadmap.jpg'
            },
            description:
              'Use Password: foodrevolution\n\nGet everything you need to train for your first marathon, half marathon, or shorter distance race on a plant-based diet.\n\nThe Roadmap includes a complete guide to how to train, detailed training plans for running a marathon, half marathon, 10K, or 5K, weekly video updates throughout the training plan, three sample meal plans, and expert interviews with plant-based athletes like Rich Roll, Brendan Brazier, and many more.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4583,
      name: 'Bonus - Eating for Energy: The 14-Day Plan to End Fatigue Forever',
      title: 'Eating for Energy: The 14-Day Plan to End Fatigue Forever',
      slug: 'bonus-eating-for-energy-the-14-day-plan-to-end-fatigue-forever',
      type: 'bonus',
      data: {
        from: 'Ross Bridgeford of Live Energized',
        value: 97
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4590,
          name: 'Link - Eating for Energy: The 14-Day Plan to End Fatigue Forever',
          title: 'Eating for Energy: The 14-Day Plan to End Fatigue Forever',
          slug: 'eating-for-energy-the-14-day-plan-to-end-fatigue-forever',
          type: 'link',
          data: {
            url: 'http://training.liveenergized.com/dl-e4e',
            thumb: {
              alt: 'Eating for Energy: The 14-Day Plan to End Fatigue Forever',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/eating-for-energy-the-14-day-plan-to-end-fatigue-forever.jpg'
            },
            description:
              'You can have natural, sustained, all-day energy! With this 14-day plan, Ross Bridgeford will address the five root causes of fatigue. Plus, you’ll learn how to nourish your body and give it the tools it needs to thrive.\r\n\r\nThe course addresses acidity, inflammation, and oxidative stress, helping regenerate the three energy factories in the body: your endocrine system, digestive system and detoxification system.\r\n\r\nOnce these systems have been nourished and rebuilt, fatigue disappears and is replaced with a wonderful energy level that allows you to live your life to the fullest.\r\n\r\nIncluded in this bonus offer are meal plans, recipes, shopping lists, training, interviews, and more.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4585,
      name: 'Bonus - Plant Fit Summit: Better You - Better World',
      title: 'Plant Fit Summit: Better You - Better World',
      slug: 'bonus-plant-fit-summit-better-you-better-world',
      type: 'bonus',
      data: {
        from: 'Plant Fit Movement',
        value: 147
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4592,
          name: 'Link - Plant Fit Summit: Better You - Better World',
          title: 'Plant Fit Summit: Better You - Better World',
          slug: 'plant-fit-summit-better-you-better-world',
          type: 'link',
          data: {
            url: 'https://2017.plantfitsummit.com/food-revolution-bonus/?ref=oceanrobbins1',
            thumb: {
              alt: 'Plant Fit Summit: Better You - Better World',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/plant-fit-summit-better-you-better-world.jpg'
            },
            description:
              'Do you want to leverage whole plant-based foods for fitness and peak performance? With the Plant Fit Summit, you’ll hear from New York Times bestselling author Neal Barnard, MD; vegan model Crissi Carvalho; plant-Strong athletic pioneer Rip Esselstyn, and 37 other experts who’ll share their best tips and inspiration for optimal health and peak performance, at every age and level of fitness.\r\n\r\nJoin plant-based medical doctors, fitness coaches, Olympic athletes, personal trainers, and other inspirational speakers who will help you transform from the inside out, and live the healthy, active, and vibrant life you’ve always wanted.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4202,
      name: 'Bonus - Social Entrepreneur Empowerment Package',
      title: 'Social Entrepreneur Empowerment Package',
      slug: 'bonus-social-entrepreneur-empowerment-package',
      type: 'bonus',
      data: {
        from: 'Ryan Eliason',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4221,
          name: 'Link - Social Entrepreneur Empowerment Package',
          title: 'Social Entrepreneur Empowerment Package',
          slug: 'social-entrepreneur-empowerment-package',
          type: 'link',
          data: {
            url: 'http://coachryaneliason.ontraport.com/t?orid=31988&opid=60',
            thumb: {
              alt: 'Social Entrepreneur Empowerment Package',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/social-entrepreneur-empowerment-package.jpg'
            },
            description:
              'Learn from 41 of the world’s top social change leaders and conscious entrepreneurs, so you can grow your income and make a real positive impact.\n\nRyan Eliason, who founded the nonprofit Youth for Environmental Sanity with Ocean Robbins when the two of them were teenagers in 1990, spent more than two decades empowering social entrepreneurs to leverage business for healthy people and a healthy planet. In this popular program, Ryan offers interviews with top leaders who share how they grew organizations that combine people, the planet, and profits.\n\nYou’ll gain mindset shifts and tangible business skills. This package also includes its own HUGE collection of bonus gifts worth more than $600!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4581,
      name: 'Bonus - True North Niche Clarity Training Course for Entrepreneurs',
      title: 'True North Niche Clarity Training Course for Entrepreneurs',
      slug: 'bonus-true-north-niche-clarity-training-course-for-entrepreneurs',
      type: 'bonus',
      data: {
        from: 'Sage Lavine',
        value: 697
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4588,
          name: 'Link - True North Niche Clarity Training Course for Entrepreneurs',
          title: 'True North Niche Clarity Training Course for Entrepreneurs',
          slug: 'true-north-niche-clarity-training-course-for-entrepreneurs',
          type: 'link',
          data: {
            url: 'http://coachryaneliason.ontraport.com/t?orid=31988&opid=60',
            thumb: {
              alt: 'True North Niche Clarity Training Course for Entrepreneurs',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/true-north-niche-clarity-training-course-for-entrepreneurs.jpg'
            },
            description:
              'Want to create a career for yourself in the Food Revolution? Ready to master the foundational formula for six-figure success?\r\n\r\nThrough this training with renowned Women Rocking Business founder Sage Lavine, you’ll learn to pinpoint and communicate your entrepreneurial message with your target audience so you can align with an authentic and lucrative niche that is a “true north” for you. You’ll also learn the tools to master a 5-step process which will allow you to integrate your learnings, maximize enrollment, and make the abundant income you desire — while being of profound service on the planet.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    }
  ],
  ContentTags: [],
  childrenIndexes: {
    bonus: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
  }
};

export const bonusesThree = {
  id: 22,
  name: 'FRS19 Empowerment Package - Bonus Items',
  title: 'Empowerment Package Bonus Items',
  slug: 'frs19-bonuses',
  type: 'bonuses',
  data: {
    thumb: {
      alt: 'great bonus thumb pic',
      url: 'http://via.placeholder.com/350x150'
    },
    description:
      'These bonuses are gifts from the Food Revolution Network’s speakers, partners, and friends. There’s something here, among these fantastic tools and resources, for just about everybody.\r\n\r\nWe haven’t personally and fully vetted all of these programs, and some may offer perspectives that may or may not be consistent with the Food Revolution Network in some areas.\r\n\r\nWe would love to hear what you think of the specific bonuses. You can click the “Contact Us” button at the top of this page to share your feedback or to let us know if you have any issues with your bonuses. We’re here to help!'
  },
  published: true,
  free: false,
  Children: [
    {
      id: 4183,
      name: 'Bonus - Everyday Hormones Energy Jumpstart',
      title: 'Everyday Hormones Energy Jumpstart',
      slug: 'bonus-everyday-hormones-energy-jumpstart',
      type: 'bonus',
      data: {
        from: 'Dr. Ritamarie Loscalzo',
        value: 297
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4207,
          name: 'Link - Everyday Hormones Energy Jumpstart',
          title: 'Everyday Hormones Energy Jumpstart',
          slug: 'everyday-hormones-energy-jumpstart',
          type: 'link',
          data: {
            url:
              'https://www.unstoppablehealthcommunity.com/everyday-hormones/thank-you-oceankjhsda87kyiu8y98/?email=[email]&firstName=[firstName]&lastName=[lastName]',
            thumb: {
              alt: 'Everyday Hormones Energy Jumpstart',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/everyday-hormones-energy-jumpstart.jpg'
            },
            description:
              "This 7-week, fully guided journey is designed to give you balance and wellness in your bodily functions, and to give your energy a boost. Each week, you'll be guided through a series of steps to jumpstart you into positive habits that begin the process of healing your digestion and balancing your hormones. There will be a week devoted to jumpstarting each of the major hormone systems.\n\nYou’ll gain support in regulating the hormones that control vital functions like Digestion, Blood Sugar, Adrenals and Thyroid, and that keep you feeling alert, happy, energetic and strong."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4193,
      name: 'Bonus - The Core Meal Masterclass',
      title: 'The Core Meal Masterclass',
      slug: 'bonus-the-core-meal-masterclass',
      type: 'bonus',
      data: {
        from: 'Chef Katie Mae',
        value: 60
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4213,
          name: 'Link - The Core Meal Masterclass',
          title: 'The Core Meal Masterclass',
          slug: 'the-core-meal-masterclass',
          type: 'link',
          data: {
            url: 'https://training.theculinarygym.com/products/the-core-meal-masterclass',
            thumb: {
              alt: 'The Core Meal Masterclass',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-core-meal-masterclass.jpg'
            },
            description:
              "Chef Katie Mae empowers you with ten customizable “Core Meals” that once practiced will make your meal prep easier and dishes tastier! With 10 video lessons and 10 colorful, printable core meal templates, you'll build your skills and creativity to make quick and delicious dishes without using recipes."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4186,
      name: 'Bonus - 14-Day Bright Line Eating Food Freedom Challenge Course',
      title: '14-Day Bright Line Eating Food Freedom Challenge Course',
      slug: 'bonus-14-day-bright-line-eating-food-freedom-challenge-course',
      type: 'bonus',
      data: {
        from: 'Susan Peirce Thompson, PhD',
        value: 29
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4208,
          name: 'Link - 14-Day Bright Line Eating Food Freedom Challenge Course',
          title: '14-Day Bright Line Eating Food Freedom Challenge Course',
          slug: '14-day-bright-line-eating-food-freedom-challenge-course',
          type: 'link',
          data: {
            url: 'https://rq263.isrefer.com/go/frn19bonus/Ocean/',
            thumb: {
              alt: '14-Day Bright Line Eating Food Freedom Challenge Course',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/14-day-bright-line-eating-food-freedom-challenge-course.jpg'
            },
            description:
              'Do you want to shed any excess pounds and keep them off long term? Learn from Susan Peirce Thompson, Ph.D., why your brain may be blocking you from losing weight — and what you can do about it!\n\nWith her 14-Day Bright Line Eating Food Freedom Challenge Course, she’s daring you to discover what it takes to make a healthy weight your new normal. You’ll learn why so many people have trouble releasing weight and what the most successful weight loss maintainers are doing differently.\n\nAre you up for the challenge?'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4194,
      name: 'Bonus - A Plant-Based Approach To Eating to Beat Disease',
      title: 'A Plant-Based Approach To Eating to Beat Disease',
      slug: 'bonus-a-plant-based-approach-to-eating-to-beat-disease',
      type: 'bonus',
      data: {
        from: 'William Li, MD',
        value: 50
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4217,
          name: 'Download - A Plant-Based Approach To Eating to Beat Disease',
          title: 'A Plant-Based Approach To Eating to Beat Disease',
          slug: 'a-plant-based-approach-to-eating-to-beat-disease',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/A%20Plant-Based%20Approach%20to%20Eating%20To%20Beat%20Disease.pdf',
            thumb: {
              alt: 'A Plant-Based Approach To Eating to Beat Disease',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/a-plant-based-approach-to-eating-to-beat-disease.jpg'
            },
            description:
              "This plant-based guide will help fortify your body's natural defense systems. Inside are recipes, tips, and insights that will show you the science behind plant-based foods and how eating to beat disease can change your life."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4184,
      name: 'Bonus - Cook & Thrive: Healthy Eating Made Simple & Delicious',
      title: 'Cook & Thrive: Healthy Eating Made Simple & Delicious',
      slug: 'bonus-cook-and-thrive-healthy-eating-made-simple-and-delicious',
      type: 'bonus',
      data: {
        from: 'iThrive',
        value: 99
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4204,
          name: 'Link - Cook & Thrive: Healthy Eating Made Simple & Delicious',
          title: 'Cook & Thrive: Healthy Eating Made Simple & Delicious',
          slug: 'cook-and-thrive-healthy-eating-made-simple-and-delicious',
          type: 'link',
          data: {
            url: 'https://ithriveseries.com/frn',
            thumb: {
              alt: 'Cook & Thrive: Healthy Eating Made Simple & Delicious',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/cook-and-thrive-healthy-eating-made-simple-and-delicious.jpg'
            },
            description:
              "Are you looking for healthy meals your entire family will love?\n\nJoin Jon McMahon, founder of the iThrive Series, as he learns delicious recipes from three leading whole foods plant-based chefs. In these fun cooking demos, you'll be guided through a simple step-by-step process for each recipe, and learn effective cooking techniques designed to maximize your time and the flavor of the food!\n\nLearn how to make:\nSweet Potato Tacos with Chef Katie Mae\nPlantain & Bean Savory Pie with Chef Mauricio\nOatmeal French Toast & Fruit Compote with Chef Ramses"
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4191,
      name: 'Bonus - POWER UP - 8 Weeks Transformation Program',
      title: 'POWER UP - 8 Weeks Transformation Program',
      slug: 'bonus-power-up-8-weeks-transformation-program',
      type: 'bonus',
      data: {
        from: 'Plant Fit Movement',
        value: 67
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4212,
          name: 'Link - POWER UP - 8 Weeks Transformation Program',
          title: 'POWER UP - 8 Weeks Transformation Program',
          slug: 'power-up-8-weeks-transformation-program',
          type: 'link',
          data: {
            url: 'https://members.plantfitsummit.com/power-up-bonus/',
            thumb: {
              alt: 'POWER UP - 8 Weeks Transformation Program',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/power-up-8-weeks-transformation-program.jpg'
            },
            description:
              'Get in shape with Lisa Clayton - mom of three and popular boot camp instructor.\n\nWith this 8 weeks program you get 11 easy to follow workouts that you can do in the comfort of your home. Designed to jump-start your metabolism, torch fat, tone your legs and arms, firm your hips and thighs, flatten your stomach and have you jump out of bed ready to take on the world…\n\nEach workout takes less than 30 minutes so you can get in, get out and get on with your day. And there is always a modifier on screen so you can choose the right intensity without losing the benefits.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4199,
      name: 'Bonus - Advanced Video Programs for Healing Body and Mind',
      title: 'Advanced Video Programs for Healing Body and Mind',
      slug: 'bonus-advanced-video-programs-for-healing-body-and-mind',
      type: 'bonus',
      data: {
        from: 'Dr. Dan Nuzum and Jonathan Otto',
        value: 894
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4220,
          name: 'Link - Advanced Video Programs for Healing Body and Mind',
          title: 'Advanced Video Programs for Healing Body and Mind',
          slug: 'advanced-video-programs-for-healing-body-and-mind',
          type: 'link',
          data: {
            url: 'https://healthsecret.com/health-program/',
            thumb: {
              alt: 'Advanced Video Programs for Healing Body and Mind',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/advanced-video-programs-for-healing-body-and-mind.jpg'
            },
            description:
              'These two advanced video programs are designed to help you achieve optimal health and relieve problems with depression and anxiety. You’ll get:\n\n10-Day Revolution Masterclass Training Videos\nThis special program is a power-packed 10-Day Masterclass with the Dr. Dan Nuzum, hosted by health researcher Jonathan Otto. The program consists of 10 Masterclass Videos, each over 1-hour, and includes teaching and real-life implementation of Dr. Nuzum’s dietary, lifestyle and treatment protocols (including detoxification). These protocols are perfect for those with autoimmunity and other ailments.\n\nThe NEWSTART Secret Video Series\nThese 10 videos hosted by Jonathan Otto reveal the secrets from a clinic that has a success rate of curing depression  and anxiety that’s reportedly in the high 90s. Learn from the doctors, therapists and patients who explain how they did it, and how you can too - all from the comfort of your own home.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4196,
      name: 'Bonus - The Raw Food Basic & Entreés Course',
      title: 'The Raw Food Basic & Entreés Course',
      slug: 'bonus-the-raw-food-basic-and-entrees-course',
      type: 'bonus',
      data: {
        from: 'Chef Teton',
        value: 77
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4219,
          name: 'Link - The Raw Food Basic & Entreés Course',
          title: 'The Raw Food Basic & Entreés Course',
          slug: 'the-raw-food-basic-and-entrees-course',
          type: 'link',
          data: {
            url: 'http://rawcookedandcultured.com/foodrevolution',
            thumb: {
              alt: 'The Raw Food Basic & Entreés Course',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-raw-food-basic-and-entrees-course.jpg'
            },
            description:
              'The Raw Food Basic & Entreés Course is designed to give you everything you need to incorporate raw “living” foods into your daily life. Use this course to awaken the sensual pleasure of eating vibrant plant foods from the Earth — full of nourishment, aroma, flavor and richness.\n\nIncluded: Raw Food Pizza, Seed Cheeses, Dehydrated Crackers, Seed Milks, Raw Soups, Sauces & Dressings, and Luscious Desserts that nourish the body and please the palate at the same time. Learning the techniques provides a great way to use the best of raw plant based foods that can also be easily paired with your favorite cooked foods.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4195,
      name: 'Bonus - Plant-Based Nutrition Guide: How to Optimize 7 Essential Nutrients',
      title: 'Plant-Based Nutrition Guide: How to Optimize 7 Essential Nutrients',
      slug: 'bonus-plant-based-nutrition-guide',
      type: 'bonus',
      data: {
        from: 'Veg Health',
        value: 29
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4215,
          name: 'Download - Plant-Based Nutrition Guide: How to Optimize 7 Essential Nutrients',
          title: 'Plant-Based Nutrition Guide: How to Optimize 7 Essential Nutrients',
          slug: 'plant-based-nutrition-guide',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/How%20Vegans%20Get%20Calcium%2C%20Iron%2C%20Protein%2C%20A%2C%20B12%20%26%20D.pdf',
            thumb: {
              alt: 'Plant-Based Nutrition Guide: How to Optimize 7 Essential Nutrients',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/plant-based-nutrition-guide.jpg'
            },
            description:
              'Many vegans and vegetarians have concerns about how to optimize their plant-based diet to avoid deficiencies and get all the nutrients they need to feel healthy and energized. While you can get almost all of the nutrition you need from plants alone, there are a few key nutrients that are not as readily available.\n\nThe Plant-Based Nutrition Guide teaches you how to maximize absorption of 7 nutrients that are more difficult to obtain on a plant-based diet. Learn which foods to eat that are loaded with protein and calcium, food synergies to increase absorption of iron, and which foods block absorption of nutrients such as calcium.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4228,
      name: 'Bonus - The 12-Day Dairy Detox',
      title: 'The 12-Day Dairy Detox',
      slug: 'bonus-the-12-day-dairy-detox',
      type: 'bonus',
      data: {
        from: 'Allison Rivers Samson and Michelle Cehn',
        value: 67
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4235,
          name: 'Link - The 12-Day Dairy Detox',
          title: 'The 12-Day Dairy Detox',
          slug: 'the-12-day-dairy-detox',
          type: 'link',
          data: {
            url: 'https://www.thedairydetox.com/offers/kDHomCXo',
            thumb: {
              alt: 'The 12-Day Dairy Detox',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-12-day-dairy-detox.jpg'
            },
            description:
              'The Dairy Detox is a comprehensive video-based course that walks you through everything you need to know to easily live and love the dairy-free life. If you’ve ever thought, “But I could never live without cheese,” and you want to — this program was made for you.\n\nIn just 12 modules, you’ll learn how to transition to dairy-free eating while still enjoying your favorite foods (think: milk, ice cream, butter, and of course the big one—cheese), dairy-free nutrition, how to shop smart and clean, how to eat at restaurants and share meals with friends, and so much more.\n\nGet ready to feel better with your new, delicious life after dairy.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 3973,
      name: 'Bonus - 13 Steps To A Cancer-Free Kitchen',
      title: '13 Steps To A Cancer-Free Kitchen',
      slug: 'bonus-13-steps-to-a-cancer-free-kitchen',
      type: 'bonus',
      data: {
        from: 'Gina Bonanno-Lemos of 360 Health Connection',
        value: 15
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 3974,
          name: 'Download - 13 Steps To A Cancer-Free Kitchen',
          title: '13 Steps To A Cancer-Free Kitchen',
          slug: '13-steps-to-a-cancer-free-kitchen',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/13%20Steps%20to%20a%20Cancer%20Free%20Kitchen.pdf',
            thumb: {
              alt: '13 Steps To A Cancer-Free Kitchen',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/13-steps-to-a-cancer-free-kitchen.jpg'
            },
            description:
              "This ebook gives you a concise guide to discovering hidden and unsuspected carcinogens in your kitchen.You'll find out how to easily avoid and eliminate these unknown dangers by substituting non-toxic alternatives.\n\nYou'll also discover the secret, disease-fighting items that you probably have in your kitchen at this very moment, so you can put these cell-healers to work immediately to protect yourself and your loved ones."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4222,
      name: 'Bonus - Big Book of Green Smoothies',
      title: 'Big Book of Green Smoothies',
      slug: 'bonus-big-book-of-green-smoothies',
      type: 'bonus',
      data: {
        from: 'Robyn Openshaw',
        value: 10
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4230,
          name: 'Download - Big Book of Green Smoothies',
          title: 'Big Book of Green Smoothies',
          slug: 'big-book-of-green-smoothies',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Big%20Book%20Of%20Green%20Smoothies.pdf',
            thumb: {
              alt: 'Big Book of Green Smoothies',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/big-book-of-green-smoothies.jpg'
            },
            description:
              'Green smoothies can be a brilliant solution in a fast-paced, fast-food world. They’re a habit that can change your health dramatically, giving you15 servings of raw plant foods with just 10 minutes of kitchen time.\n\nRobyn’s readers contributed their very best recipes for this book, which also includes 50 of their children’s photos, to help inspire kids everywhere to drink (and love!) their greens. children.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4223,
      name: 'Bonus - The Top 25 Cosmetic Ingredients to Avoid Like the Plague',
      title: 'The Top 25 Cosmetic Ingredients to Avoid Like the Plague',
      slug: 'bonus-the-top-25-cosmetic-ingredients-to-avoid-like-the-plague',
      type: 'bonus',
      data: {
        from: 'Brian Vaszily',
        value: 20
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4231,
          name: 'Download - The Top 25 Cosmetic Ingredients to Avoid Like the Plague',
          title: 'The Top 25 Cosmetic Ingredients to Avoid Like the Plague',
          slug: 'the-top-25-cosmetic-ingredients-to-avoid-like-the-plague',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/Top%2025%20Cosmetic%20Ingredients%20to%20Avoid%20Like%20the%20Plague.pdf',
            thumb: {
              alt: 'The Top 25 Cosmetic Ingredients to Avoid Like the Plague',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/the-top-25-cosmetic-ingredients-to-avoid-like-the-plague.jpg'
            },
            description:
              'No pre-market safety testing is required for the compounds in your cosmetics.\nThis means, with the exception of tried-and-true natural ingredients, virtually all of the\nchemicals in your skin care products and makeup could be classified as questionable (or worse!). It’s estimated that the average person is exposed to more than 100 different chemicals\nin personal care products before they’re even out the door in the morning, and no\none knows what the outcome of exposure to this cumulative chemical cocktail will be.\n\nIf you don’t want to be the guinea pig in a giant, ongoing experiment, read this book so you can know what to look out for when you’re choosing personal care products.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4227,
      name: 'Bonus - Kickstart the World Recipe Booklet',
      title: 'Kickstart the World Recipe Booklet',
      slug: 'bonus-kickstart-the-world-recipe-booklet',
      type: 'bonus',
      data: {
        from: 'Physicians Committee for Responsible Medicine',
        value: 10
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4236,
          name: 'Link - Kickstart the World Recipe Booklet',
          title: 'Kickstart the World Recipe Booklet',
          slug: 'kickstart-the-world-recipe-booklet',
          type: 'link',
          data: {
            url: 'https://p.widencdn.net/not554/19069-DEV-Kickstart-the-World-Recipe-Booklet-FINAL-3132019',
            thumb: {
              alt: 'Kickstart the World Recipe Booklet',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/kickstart-the-world-recipe-booklet.jpg'
            },
            description:
              'Physicians Committee takes you through an exciting culinary journey with favorite recipes from its popular 21-Day Vegan Kickstart program. Healthful, delicious, and easy-to-prepare, these traditional dishes from Latin America, India, China, Japan, and the U.S. will have you feeling like a world traveler!\n\nThese 25 authentic recipes have been created and approved by Physicians Committee’s nutrition experts. Immerse yourself in plant-based global cuisine that is good for you, promotes compassion, and protects the planet. You’ll soon be wondering how you ever managed to get along without Stuffed Chile Relleno Peppers, Punjabi Rajma, and Ginger Noodles in your recipe repertoire!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4226,
      name:
        'Bonus - No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
      title: 'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
      slug: 'bonus-no-meat-athlete-marathon-roadmap',
      type: 'bonus',
      data: {
        from: 'No Meat Athlete',
        value: 67
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4233,
          name:
            'Link - No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
          title:
            'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
          slug: 'no-meat-athlete-marathon-roadmap',
          type: 'link',
          data: {
            url: 'http://www.nomeatathlete.com/summit-bonus',
            thumb: {
              alt:
                'No Meat Athlete Marathon Roadmap — The Plant-Based Guide to Your First Marathon, Half Marathon, and 5K',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/no-meat-athlete-marathon-roadmap.jpg'
            },
            description:
              'Use Password: foodrevolution\n\nGet everything you need to train for your first marathon, half marathon, or shorter distance race on a plant-based diet.\n\nThe Roadmap includes a complete guide to how to train, detailed training plans for running a marathon, half marathon, 10K, or 5K, weekly video updates throughout the training plan, three sample meal plans, and expert interviews with plant-based athletes like Rich Roll, Brendan Brazier, and many more.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4224,
      name: 'Bonus - Five Phases: The Nature of Your Health Audio Course',
      title: 'Five Phases: The Nature of Your Health Audio Course',
      slug: 'bonus-five-phases-the-nature-of-your-health-audio-course',
      type: 'bonus',
      data: {
        from: 'LearningHerbs',
        value: 97
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4232,
          name: 'Download - Five Phases: The Nature of Your Health Audio Course',
          title: 'Five Phases: The Nature of Your Health Audio Course',
          slug: 'five-phases-the-nature-of-your-health-audio-course',
          type: 'download',
          data: {
            thumb: {
              alt: 'Five Phases: The Nature of Your Health Audio Course',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/five-phases-the-nature-of-your-health-audio-course.jpg'
            },
            description:
              'All around us, mighty healers grow in nature. For example, you might have heard that hawthorn lowers blood pressure. Willow relieves arthritis. Dandelion supports the liver. But is there more to health than taking this for that? How can we adopt a more holistic perspective towards healing?\n\nIn this 10-part audio series, you’ll learn the basic philosophy of the Five Phases and the five elements of this system. The Five Phases philosophy is a system from traditional Chinese Medicine, which teaches that good health is found by living in harmony with the seasons. With each element, you’ll learn practical ways to support the water, wood, fire, earth, or metal in your life using lifestyle habits, food choices, and herbs. \n\nAs a result, you’ll learn more about your gifts, challenges, and ways to understand how your personal health can be influenced through the Five Phases.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4187,
      name: 'Bonus - The Tapping Solution for Pain Relief',
      title: 'The Tapping Solution for Pain Relief',
      slug: 'bonus-the-tapping-solution-for-pain-relief',
      type: 'bonus',
      data: {
        from: 'the Tapping Solution',
        value: 147
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4206,
          name: 'Link - The Tapping Solution for Pain Relief',
          title: 'The Tapping Solution for Pain Relief',
          slug: 'the-tapping-solution-for-pain-relief',
          type: 'link',
          data: {
            url: 'https://www.thetappingsolution.com/pain-relief/frs-bonus.php',
            thumb: {
              alt: 'The Tapping Solution for Pain Relief',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/the-tapping-solution-for-pain-relief.jpg'
            },
            description:
              'Use Password: foodrevolution\n\nIf you or anyone you love is suffering from chronic pain, this 16-session, 3-day program could help.\n\nHave you ever wondered what the root cause of your pain was? Surprisingly, there is often an emotional component underlying or intensifying chronic pain. With this course, you’ll discover the connection between your pain and your emotions with help from the Emotional Freedom Technique (EFT). With guidance from the bestselling The Tapping Solution author Nick Ortner, you’ll learn to work through blocked emotions that could be fueling chronic pain, by tapping on specific energy meridians throughout your body. You’ll discover how to make greater peace with your past, and find physical and emotional relief in the present.\n\nThe course gives you access to all three days of this important, life-changing program including the MP3s, transcripts, and exclusive Facebook group.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4229,
      name: 'Bonus - How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
      title: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
      slug: 'bonus-how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days',
      type: 'bonus',
      data: {
        from: 'Stacey Murphy',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4234,
          name: 'Link - How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
          title: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
          slug: 'how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days',
          type: 'link',
          data: {
            url: 'https://growyourownvegetables.org/food-revolution-400/',
            thumb: {
              alt: 'How to Grow $400 Worth of Vegetables and Herbs in 40 Days',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/how-to-grow-400-worth-of-vegetables-and-herbs-in-40-days.jpg'
            },
            description:
              'Do you want fresh, homegrown greens and other healthful veggies on your dinner table?\n\nWith master gardening teacher Stacey Murphy’s step-by-step videos, you can learn how to grow your own vegetables and herbs either indoors or outdoors. Challenge yourself to see how much food YOU can grow for your dinner table while spending under an hour per week.\n\nWatch over Stacey’s shoulders as she cultivates a brand new garden space of less than 100 square feet. You’ll discover the most efficient ways to plant and harvest in order to get fast, fresh food. Set your own growing challenge and post your garden photos to the class for bragging rights. This class includes 16 short videos (total of 4 hours) and a workbook to plan your own garden challenge and track your progress.\n\nYIELD Disclaimer: Your yields are up to you. Stacey can support by providing great content and instructions. Any resource budget numbers referenced are estimates; your local conditions WILL vary.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4192,
      name: 'Bonus - 5-Insights To Release Weight Loss Frustration!',
      title: '5-Insights To Release Weight Loss Frustration!',
      slug: 'bonus-5-insights-to-release-weight-loss-frustration',
      type: 'bonus',
      data: {
        from: 'Michelle Melendez',
        value: 24
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4214,
          name: 'Link - 5-Insights To Release Weight Loss Frustration!',
          title: '5-Insights To Release Weight Loss Frustration!',
          slug: '5-insights-to-release-weight-loss-frustration',
          type: 'link',
          data: {
            thumb: {
              alt: '5-Insights To Release Weight Loss Frustration!',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/5-insights-to-release-weight-loss-frustration.jpg'
            },
            description:
              'Author Michelle Melendez has been a fitness expert and weight loss specialist for the past two-decades. She’s done extensive research into emotional addictions and weight loss, and she wrote this ebook to teach you how to have peace with your body and enjoy the lightness and wellness you deserve.\n\nYou’ll discover:\n5 Keys fitness trainers, doctors, & nutritionist don’t know that are crucial to losing weight\nHow your body’s cells are addicted to keeping you overweight\nWhy you will FOREVER SELF-SABOTAGE when trying to lose weight\nThe worst thing to do if you want to END weight loss frustration.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4198,
      name: 'Bonus - Make Money with Your Message - Niche Clarity Training Course for Entrepreneurs',
      title: 'Make Money with Your Message - Niche Clarity Training Course for Entrepreneurs',
      slug: 'bonus-make-money-with-your-message',
      type: 'bonus',
      data: {
        from: 'Sage Lavine',
        value: 697
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4216,
          name: 'Link - Make Money with Your Message - Niche Clarity Training Course for Entrepreneurs',
          title: 'Make Money with Your Message - Niche Clarity Training Course for Entrepreneurs',
          slug: 'make-money-with-your-message',
          type: 'link',
          data: {
            url: 'http://sagelavine.ontraport.net/t?orid=38407&opid=65',
            thumb: {
              alt: 'Make Money with Your Message - Niche Clarity Training Course for Entrepreneurs',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/make-money-with-your-message.jpg'
            },
            description:
              'Want to create a career in the food revolution?\n\nThrough this training with renowned Women Rocking Business founder Sage Lavine, you’ll learn to pinpoint and communicate your entrepreneurial message with your target audience so you can align with an authentic and lucrative niche that is a “true north” for you. You’ll also learn the tools to master a 5-step process which will allow you to integrate your learnings, maximize enrollment, and make an abundant income — while being of profound service on the planet.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4200,
      name: 'Bonus - Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
      title: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
      slug: 'bonus-jump-start-your-urban-farm',
      type: 'bonus',
      data: {
        from: 'Greg Peterson',
        value: 97
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4218,
          name: 'Link - Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
          title: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
          slug: 'jump-start-your-urban-farm',
          type: 'link',
          data: {
            url: 'https://urbanfarm.leadpages.co/jump-start-summit/',
            thumb: {
              alt: 'Jump Start Your Urban Farm: Learn How to Start Growing Your Own Food',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/jump-start-your-urban-farm.jpg'
            },
            description:
              "This online course consists of five, 1.5-hour class sessions taught by urban farming and permaculture expert Greg Peterson, who has been growing food in his front and backyard for over four decades.\n\nWith Greg’s step-by-step guidance, it won’t be long before you’re cultivating your own edible yard. Ready to join the growing revolution?\n\nYou'll learn:\nThe powerful distinction between “regenerative” and “sustainable”\nThe 3 essential steps to becoming an urban farmer\nHow to use permaculture principles to examine your space and plan your urban farm or garden\nWhy growing your own food is one of the most revolutionary ways to change the world"
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4202,
      name: 'Bonus - Social Entrepreneur Empowerment Package',
      title: 'Social Entrepreneur Empowerment Package',
      slug: 'bonus-social-entrepreneur-empowerment-package',
      type: 'bonus',
      data: {
        from: 'Ryan Eliason',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4221,
          name: 'Link - Social Entrepreneur Empowerment Package',
          title: 'Social Entrepreneur Empowerment Package',
          slug: 'social-entrepreneur-empowerment-package',
          type: 'link',
          data: {
            url: 'http://coachryaneliason.ontraport.com/t?orid=31988&opid=60',
            thumb: {
              alt: 'Social Entrepreneur Empowerment Package',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/social-entrepreneur-empowerment-package.jpg'
            },
            description:
              'Learn from 41 of the world’s top social change leaders and conscious entrepreneurs, so you can grow your income and make a real positive impact.\n\nRyan Eliason, who founded the nonprofit Youth for Environmental Sanity with Ocean Robbins when the two of them were teenagers in 1990, spent more than two decades empowering social entrepreneurs to leverage business for healthy people and a healthy planet. In this popular program, Ryan offers interviews with top leaders who share how they grew organizations that combine people, the planet, and profits.\n\nYou’ll gain mindset shifts and tangible business skills. This package also includes its own HUGE collection of bonus gifts worth more than $600!'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4188,
      name: 'Bonus - GMO Summit Empowerment Package',
      title: 'GMO Summit Empowerment Package',
      slug: 'bonus-gmo-summit-empowerment-package',
      type: 'bonus',
      data: {
        from: 'John and Ocean Robbins',
        value: 197
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4209,
          name: 'Link - GMO Summit Empowerment Package',
          title: 'GMO Summit Empowerment Package',
          slug: 'gmo-summit-empowerment-package',
          type: 'link',
          data: {
            url: 'https://members.foodrevolution.org/gmo/',
            thumb: {
              alt: 'GMO Summit Empowerment Package',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/gmo-summit-empowerment-package.jpg'
            },
            description:
              'Get the truth about GMOs, so you can understand what they are and their impact on your health and the world.\n\nWith this Package, you get to own full audio recordings and written transcripts for all 12 powerful presentations. You can access them online anytime you want or download them and take them with you, so you can learn how to protect yourself and your family.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4203,
      name: 'Bonus - The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
      title: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
      slug: 'bonus-ornish-lifestyle-medicine',
      type: 'bonus',
      data: {
        from: 'From Dean Ornish, MD'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 2351,
          name: 'Download - The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
          title: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
          slug: 'ornish-lifestyle-medicine',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Ornish%20Lifestyle%20Medicine.pdf',
            thumb: {
              alt: 'The Ornish Lifestyle Medicine Program: An Implementation Guidebook',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/ornish-lifestyle-medicine.jpg'
            },
            description:
              'If avoiding or reversing chronic disease is something you’re interested in for you or your loved ones, this comprehensive guidebook can help you get started on the path to making healthy, sustainable diet and lifestyle changes.\n\nOrnish Lifestyle Medicine has been scientifically proven to reverse the progression of chronic diseases, including even severe heart disease, type 2 diabetes, high blood pressure, and others — and following these guidelines is sure to help everyone thrive, no matter how healthy you are now.\n\nIn this guidebook, you’ll get specific guidelines for nutrition, as well as exercise, stress management, and giving and receiving love and support. When you follow this way of living, you’ll be amazed at how quickly changes can occur and how dramatically.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4185,
      name: 'Bonus - “Ask The Experts” Special Event — Get Answers to Your Questions About Food and Health',
      title: '“Ask The Experts” Special Event — Get Answers to Your Questions About Food and Health',
      slug: 'bonus-ask-the-experts-special-event-klaper-davis',
      type: 'bonus',
      data: {
        from: 'Michael Klaper, MD and Brenda Davis, RD — hosted by Ocean Robbins'
      },
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4205,
          name: 'Link - “Ask The Experts” Special Event — Get Answers to Your Questions About Food and Health',
          title: '“Ask The Experts” Special Event — Get Answers to Your Questions About Food and Health',
          slug: 'ask-the-experts-special-event-klaper-davis',
          type: 'link',
          data: {
            thumb: {
              alt: '“Ask The Experts” Special Event — Get Answers to Your Questions About Food and Health',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/ask-the-experts-special-event-klaper-davis.jpg'
            },
            description:
              'Join Michael, Brenda and Ocean for a practical and informative event designed to answer YOUR questions and to help you put the Food Revolution into action in your life.\n\nAs an Empowerment Package owner you’ll get special access to “Ask The Experts”, including the opportunity to submit and help crowdsource the questions, as well as lifetime access to the recordings and the transcript.\n\nYou’ll be able to join in live at 1 pm Eastern time (10 am Pacific) on May 11. Or you can enjoy the replay and transcripts any time you want. Be sure to mark your calendar now!\n\n\nNOTE: We will be in touch via email with access information to the Special Event closer to the event time.'
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        link: [0]
      }
    },
    {
      id: 4189,
      name: 'Bonus - GMO: OMG! What Everyone Needs to Know',
      title: 'GMO: OMG! What Everyone Needs to Know',
      slug: 'bonus-gmo-omg-what-everyone-needs-to-know',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4210,
          name: 'Download - GMO: OMG! What Everyone Needs to Know',
          title: 'GMO: OMG! What Everyone Needs to Know',
          slug: 'gmo-omg-what-everyone-needs-to-know',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/GMO%20OMG%21%20What%20Everyone%20Needs%20to%20Know.pdf',
            thumb: {
              alt: 'GMO: OMG! What Everyone Needs to Know',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/gmo-omg-what-everyone-needs-to-know.jpg'
            },
            description:
              "What exactly are GMOs? And are they dangerous to your health and the health of the planet? This report offers impeccably researched information about the impact of GMOs on our food supply and the environment. You'll also find out which foods are most likely to be genetically modified, which ones are likely to be in the next few years, and the simple trick you can use at the grocery store to avoid GMOs, insecticides and herbicides, even if you're on a tight budget."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4190,
      name: 'Bonus - Brain Food: 8 Superfoods Your Brain Will Love',
      title: 'Brain Food: 8 Superfoods Your Brain Will Love',
      slug: 'bonus-brain-food-8-superfoods-your-brain-will-love',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4211,
          name: 'Download - Brain Food: 8 Superfoods Your Brain Will Love',
          title: 'Brain Food: 8 Superfoods Your Brain Will Love',
          slug: 'brain-food-8-superfoods-your-brain-will-love',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/Brain%20Food%208%20Superfoods%20Your%20Brain%20Will%20Love.pdf',
            thumb: {
              alt: 'Brain Food: 8 Superfoods Your Brain Will Love',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/brain-food-8-superfoods-your-brain-will-love.jpg'
            },
            description:
              "If you're over 50, the groundwork for dementia may already be in your brain. The good news is, there's a lot you can do to prevent or even reverse this process and to boost brain performance.\n\nThis report outlines the scientifically-proven ways you can use food to regenerate brain cells and radically enhance your brain health. You'll get a list of eight superfoods that feed your brain as well as delicious “comfort food” recipes which feature these superfoods and that your entire family will love."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4197,
      name: 'Bonus - Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
      title: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
      slug: 'bonus-eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 482,
          name: 'Download - Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
          title: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
          slug: 'eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell',
          type: 'download',
          data: {
            url:
              'https://cdn.foodrevolution.org/members/pdf/Eat%20to%20Defeat%20Cancer%2010%20Superfoods%20That%20Nourish%20Every%20Cell.pdf',
            thumb: {
              alt: 'Eat to Defeat Cancer: 10 Superfoods That Nourish Every Cell',
              url:
                'https://cdn.foodrevolution.org/members/assets/thumbnails/eat-to-defeat-cancer-10-superfoods-that-nourish-every-cell.jpg'
            },
            description:
              "Cancer is the most feared disease on the planet. The good news is that there's a growing body of research which suggests that a diet rich in certain foods can help prevent or even reverse many forms of cancer.\n\nThis report contains a detailed list of 10 superfoods that have documented cancer-fighting capabilities and improve overall cell health, so you can enjoy delicious foods while reducing your cancer risk. You'll also learn which foods have been correlated with an increased risk of cancer, so you can eliminate or reduce them from your diet."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    },
    {
      id: 4201,
      name: 'Bonus - Decoding Food Labels',
      title: 'Decoding Food Labels',
      slug: 'bonus-decoding-food-labels',
      type: 'bonus',
      data: {},
      published: true,
      free: false,
      ContentTags: [],
      Children: [
        {
          id: 4225,
          name: 'Download - Decoding Food Labels',
          title: 'Decoding Food Labels',
          slug: 'decoding-food-labels',
          type: 'download',
          data: {
            url: 'https://cdn.foodrevolution.org/members/pdf/Decoding%20Food%20Labels.pdf',
            thumb: {
              alt: 'Decoding Food Labels',
              url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/decoding-food-labels.jpg'
            },
            description:
              "Are you confused about what certain words mean on food labels? For example, what's the difference between “natural”, “Non-GMO”, and “organic”? What are the standards by which a food can be advertised as “healthy” or as “humane”? And how can you tell if a food has hidden sugar or animal products?\n\nIn this report, you'll learn how to decode product claims and nutrition labels, understand ingredients, and become an informed consumer. Now you'll be truly informed, and better able to make the healthiest choices for yourself, your loved ones, and for the planet."
          },
          published: true,
          free: false,
          ContentTags: [],
          Children: []
        }
      ],
      childrenIndexes: {
        download: [0]
      }
    }
  ],
  ContentTags: [],
  childrenIndexes: {
    bonus: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]
  }
};
