/* eslint-disable */
import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import CTA from './components/CTA';
import BackgroundImageTextSection from './components/BackgroundImageTextSection';
import Footer from './components/Footer';
import HeaderSales from './components/HeaderSales';
import ImgBullet from './components/ImgBullet';
import TntgSpeakerList from './components/TntgSpeakerList';
import TreesFutureRow from './components/TreesFutureRow';

class ImpactKit extends Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
  }

  componentDidMount() {
    this.linkTag = document.createElement('link');

    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = 'https://cdn.foodrevolution.org/checkout/upsells/impactkit/styles.css';
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    document.getElementsByTagName('head')[0].appendChild(this.linkTag);
    this.styleTag = document.createElement('style');
    var css = 'html {font-size: unset !important}';
    this.styleTag.setAttribute('type', 'text/css');

    if ('textContent' in this.styleTag) {
      this.styleTag.textContent = css;
    } else {
      this.styleTag.styleSheet.cssText = css;
    }

    document.getElementsByTagName('head')[0].appendChild(this.styleTag);
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.styleTag && this.styleTag.remove();
  }

  render() {
    const {props} = this;
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="page">
        <div className="d-flex flex-column h-100">
          <div className="background-gradient-yellow-white triangle-bottom triangle-bottom-white">
            <HeaderSales />
            <div className="wrapper text-center">
              <Container>
                <Row className="d-flex justify-content-center mb-3">
                  <Col xs="12" sm="8" md="12">
                    <h1 className="text-light-blue text-condensed text-800 mb-0">
                      I wanted to make sure you didn’t miss this one-time offer to join us today as we change the world
                      together.
                    </h1>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col xs="12" md="9" lg="10" xl="8">
                    <p>
                      <em>
                        Are you ready to prevent disease in your body, savor delicious home-grown food, and enjoy the
                        satisfaction of building a better future for life on Earth?
                      </em>
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="impact-sale impact-sale-overlap">
            <div className="background-cover background-image-center background-image-girls background-overlay-black-80 triangle-offset-top text-white wrapper offset-impact-sale-section">
              <Container>
                <Row className="d-flex justify-content-center">
                  <Col xs="12">
                    <h1 className="text-center text-condensed text-800">One time offer! Huge discount!</h1>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mb-2">
                  <Col xs="12" sm="11" md="12" lg="9" xl="10">
                    <h5 className="text-400 text-center mb-0">
                      <strong>
                        Support our mission, and your health, and get immediate lifetime access to the Impact Kit today!
                      </strong>
                    </h5>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="triangle-angles-top triangle-angles-top-white">
              <Container className="offset-impact-sale-cta">
                <CTA {...props} />
              </Container>
            </div>
          </div>

          <div className="triangle-bottom triangle-bottom-white wrapper">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col xs="12" lg="12" xl="10">
                  <h3 className="text-center text-condensed text-800">
                    We spent over 5 years pouring our hearts and souls into telling the important story captured in{' '}
                    <em>The Need To GROW.</em> We hope the film inspired you. And now, whether you completed the film or
                    not, you can join us and...
                  </h3>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mb-5">
                <Col xs="12" lg="10" xl="11">
                  <h4 className="text-400 text-center mb-0">
                    Learn from all the experts featured in <em>The Need To GROW</em>— plus other brilliant visionaries —
                    exactly how to:
                  </h4>
                </Col>
              </Row>
              <ImgBullet
                img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/revitalize.jpg"
                title="Revitalize"
              >
                your body to fight disease and promote vibrant health with potent nutrient-dense foods.
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/restore.jpg" title="Restore">
                your microbiome (the bacterial diversity upon which your life depends).
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/protect.jpg" title="Protect">
                yourself and your loved ones from GMOs and pesticides.
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/grow.jpg" title="Grow">
                food - even if you don’t have a green thumb — or even a green pinky!
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/compost.jpg" title="Compost">
                with confidence - so your waste can help restore the soil, every day of your life.
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/plant.jpg" title="Plant">
                indoors (even if you have space outside, you’ll see why it’s so easy and wonderful to bring the growing
                into your home).
              </ImgBullet>
              <ImgBullet img="https://cdn.foodrevolution.org/checkout/upsells/impactkit/gain.jpg" title="Gain">
                vital understanding of biochar, topsoil, regenerative agriculture, and the future of life on earth —
                knowledge that every human on earth should have right now!
              </ImgBullet>
            </Container>
          </div>

          <BackgroundImageTextSection
            className="text-white triangle-offset-top triangle-angles-offset-bottom background-section-image-mother-daughter"
            overlayColor="black"
            imgPosition="right"
            imgSrc="https://cdn.foodrevolution.org/checkout/upsells/impactkit/backgrounds/mother-daughter.jpg"
          >
            <h4>
              Are you ready to stand with Erik, Alicia and Michael, and be part of the soil-building and world-changing
              revolution?
            </h4>
            <h5>
              <em>The Need To GROW</em> Impact Kit will show you how.
            </h5>
            <p>
              You’ll have 15 master impact leaders and teachers showing you the simplest ways to protect your family’s
              health while being part of the nutrient-dense soil-building solution on planet Earth.
            </p>
            <p>
              You’ll learn step by step how to take immediate action on all the amazing solutions you learned about in
              the film — and so much more.
            </p>
          </BackgroundImageTextSection>

          <div className="background-gradient-white-yellow triangle-angles-top triangle-angles-top-white triangle-angles-offset-bottom wrapper">
            <div className="impact-step">
              <Container>
                <Row className="d-flex justify-content-center">
                  <Col xs="12" lg="10" xl="8">
                    <h1 className="text-center text-condensed text-800">Own this brand new master series</h1>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col xs="12" lg="8" xl="7">
                    <ul className="">
                      <li>
                        15 exclusive Impact Kit Video classes, featuring the experts in the film and other leading-edge
                        visionaries, all shot in HD — PLUS transcripts and MP3 downloads for any time anyplace
                        enjoyment.
                      </li>
                      <li>
                        Never before released deleted scenes from the film (they’re really good - we just didn’t have
                        room for all of these gems).
                      </li>
                      <li>Unreleased extended interviews with the experts in the film.</li>
                      <li>
                        The digital film for life - so you can watch <em>The Need To GROW</em> any time and share it
                        with friends and loved ones.
                      </li>
                      <li>
                        PLUS over $500 worth of health-boosting, food-growing, world-changing bonuses from our friends
                        and partners.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="impact-speakers background-gradient-white-yellow triangle-angles-top triangle-angles-top-white triangle-angles-offset-bottom wrapper">
            <Container>
              <Row className="d-flex justify-content-center mb-5">
                <Col xs="12" md="11" lg="10" xl="9">
                  <h4 className="text-condensed text-800 text-center mb-0">
                    You’ll get instant access to an expert HD video series on topics like:
                  </h4>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <div className="tntg-speaker-wrap">
                    <TntgSpeakerList />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="quote-section background-black text-white triangle-both-offset-top triangle-angles-offset-bottom triangle-angles-top triangle-angles-top-black wrapper">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col xs="12" md="10" lg="10" xl="9">
                  <h2 className="text-center text-condensed text-800 mb-0">
                    You’ve decided to own <em>The Need To GROW</em>, now help us make the film have{' '}
                    <span className="text-underline">real impact</span> by adding the Impact Kit to your order!
                  </h2>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="background-gradient-white-yellow triangle-angles-top triangle-angles-top-white triangle-angles-offset-bottom wrapper">
            <Container>
              <Row className="d-flex justify-content-center mb-4">
                <Col xs="12" lg="8">
                  <img
                    className="img-fluid"
                    src="https://cdn.foodrevolution.org/tntg/tntg-productimage-digitalall.png"
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mb-5">
                <Col xs="12" lg="6">
                  <p className="text-center mb-0">
                    Once you close this page, you’ll miss this exclusive, majorly discounted offer for{' '}
                    <em>The Need To GROW</em> <strong>Impact Kit</strong>.
                  </p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mb-0">
                <Col xs="12" lg="10" xl="9">
                  <h3 className="text-condensed text-800 text-center mb-0">One-Time Offer Only!</h3>
                </Col>
              </Row>
            </Container>

            <div className="impact-sale wrapper pb-0">
              <Container>
                <div className="mb-5">
                  <CTA {...props} light />
                </div>
                <TreesFutureRow />
              </Container>
            </div>
          </div>
          <div className="mt-auto">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default ImpactKit;
