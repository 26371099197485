import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
import styles from 'styles/index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const {
  checklistItem,
  checklistItemCheckBox,
  checklistItemCheckImg,
  checklistItemCheckBoxChecked,
  checklistItemV2
} = styles;

const ChecklistItem = props => {
  const {
    change,
    name,
    data: {text},
    value
  } = props;
  const icon = value ? ['fas', 'check-square'] : ['far', 'square'];
  const checkBoxClass = value ? checklistItemCheckBoxChecked : '';
  return (
    <div
      className={`d-flex justify-content-left align-items-center border-bottom py-4 ${checklistItemV2}`}
      onClick={e => change(name, e)}
    >
      <div className="px-4">
        <FontAwesomeIcon size="lg" icon={icon} className={checkBoxClass} color={value ? '#007BFF' : '#DDDDDD'} />
      </div>
      <div className="pt-2 pr-3">
        <span for={name}>
          <ReactMarkdown source={text} />
        </span>
      </div>
    </div>
  );
};

ChecklistItem.propTypes = {
  change: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
};

export default ChecklistItem;
