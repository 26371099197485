import React from 'react';
import ReactMarkdown from 'lib/react-markdown';
import PropTypes from 'prop-types';
import {Card} from 'reactstrap';
import styles from 'styles/index.scss';

const {recipeDirectionsContainer, recipeDirectionsTitle} = styles;

const Instructions = props => (
  <Card className={recipeDirectionsContainer}>
    {props.directions
      ? props.directions.map((direction, index) => {
          const hackyIndex = index + 200;
          return (
            <div>
              <div className={recipeDirectionsTitle}>
                <span> {direction.title} </span>
              </div>
              <ol>
                {direction.directionsList.map(text => (
                  <li style={{marginBottom: '8px'}} key={text}>
                    <ReactMarkdown source={text} />
                  </li>
                ))}
              </ol>
            </div>
          );
        })
      : null}
  </Card>
);

Instructions.propTypes = {
  instructions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default Instructions;
