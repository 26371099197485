import React from 'react';
import ModalContainer from '../container';
import RegistrationForm from './components/RegistrationForm';

const RegisterModal = props => {
  const {showModal, hideModal, email, createUser} = props;
  const title = 'Register';

  return (
    <ModalContainer disableClose {...props} title={title}>
      <RegistrationForm {...props} />
    </ModalContainer>
  );
};

export default RegisterModal;
