import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Route, Redirect} from 'react-router-dom';

const AuthedUserRoute = ({component: Component, ...rest}) => {
  const {authToken, isAuthenticated, path, location} = rest;
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated || !authToken ? (
          props.location.pathname && props.location.pathname !== '/' ? (
            <Redirect
              to={
                `${props.path || '/'}?redirect=` +
                encodeURIComponent(props.location.pathname + props.location.search + props.location.hash)
              }
            />
          ) : null
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

AuthedUserRoute.defaultProps = {
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    authToken: PropTypes.string.isRequired
  })
};

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  isAuthenticated: state.user.isAuthenticated
});

// export default (component) => () => <AuthedUserRoute component={component} />;
export default compose(
  connect(
    mapStateToProps,
    null
  )
)(AuthedUserRoute);
