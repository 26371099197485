import React, {Component} from 'react';
import {Card, CardBody, CardTitle, Button} from 'reactstrap';
import Markdown from 'routes/Products/components/Markdown';

export default function({contentItem}) {
  const courseBook =
    contentItem.type === 'download' || contentItem.type === 'link'
      ? contentItem
      : contentItem.Children.find(({type}) => type === 'download' || type === 'link');
  return (
    <Card>
      <div className="card-horizontal">
        <div
          className="card-img-bg-left background-contain"
          style={{backgroundImage: `url("${courseBook.data.thumb.url}")`}}
        />
        <CardBody>
          <CardTitle>{courseBook.title}</CardTitle>
          {courseBook.data.description ? <Markdown source={courseBook.data.description} className="mt-3" /> : null}
          <Button href={courseBook.data.url} target="_blank" tag="a">
            {courseBook.type === 'download' ? 'Download' : 'Learn More'}
          </Button>
        </CardBody>
      </div>
    </Card>
  );
}
