import React from 'react';
import {Col, Row} from 'reactstrap';
import ReactMarkdown from 'lib/react-markdown';

export default function TestimonialCircleItem({id, text, cite, image, textColor = 'black', ...props}) {
  let imgOrder = `order-lg-${props.imgPosition === 'right' ? '2' : '1'}`;
  let textOrder = `order-lg-${props.imgPosition === 'right' ? '1' : '2'}`;

  return (
    <div className={`testimonial-circle text-${textColor} ${props.className}`}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="10" md="8" lg="3" className={`text-center mb-4 mb-lg-0 ${imgOrder}`}>
          <img className="testimonial-img speaker-headshot img-fluid rounded-circle" src={image} />
        </Col>
        <Col xs="12" lg="9" className={`${textOrder}`}>
          <div className="testimonial-content d-flex flex-column justify-content-between">
            <ReactMarkdown source={text} className="testimonial-copy"/>
            <div className="testimonial-cite">
              <ReactMarkdown source={cite} className="testimonial-name"/>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}