import React from 'react';

function FullPageLayout({children, marginTop, minHeight, position, top}) {
  return (
    <div
      style={{
        ...{
          left: 0,
          width: '100%',

          backgroundColor: 'var(--dark)'
        },
        ...(position ? {position: position} : {position: 'absolute'}),
        ...(minHeight ? {minHeight: minHeight} : {minHeight: '100vh'}),
        ...(marginTop ? {marginTop: marginTop} : {marginTop: '73px,'}),
        ...(top ? {top: top} : {top: '73px,'})
      }}
    >
      {children}
    </div>
  );
}

export default FullPageLayout;
