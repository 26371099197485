import React from 'react';
import Chunk from 'components/Chunk';

const loadProfileContainer = () => import('./containers' /* webpackChunkName: "profile" */);

const Profile = (props) => {
// console.log('profile props ',props);
return <Chunk load={loadProfileContainer} {...props}/>};

export default Profile;
