import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import React, {useCallback, useEffect, useState} from 'react';

const SortByModalMenu = ({toggle, isOpen, handleSortByType, sortMapper}) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} centered className="d-print-none">
      <ModalHeader>Sort By:</ModalHeader>
      <ModalBody style={{wordWrap: 'break-word'}}>
        <div class="d-flex flex-column">
          {sortMapper
            ? Object.keys(sortMapper).map(item => {
                return (
                  <Button
                    onClick={() => {
                      handleSortByType(item);
                      toggle();
                    }}
                    className="my-1"
                  >
                    {item}
                  </Button>
                );
              })
            : ''}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button onClick={toggle} color="light" className="mr-3">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default SortByModalMenu;
