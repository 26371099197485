import React, {useCallback, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import classNames from 'classnames';
import {CommentBox} from '../FacebookCommentBox';

export const FacebookCommentsSlideOut = ({commentFeed, hideTrigger = false}) => {
  const [commentsKey, setCommentsKey] = useState(0);
  const [isVisibile, setIsVisible] = useState(false);
  const refreshComments = useCallback(() => setCommentsKey(commentsKey => commentsKey + 1), []);

  const toggleVisibility = useCallback(() => setIsVisible(v => !v), []);

  return (
    <>
      <div className={classNames('facebookCommentsSlideOut', isVisibile ? 'facebookCommentsSlideOutVisible' : '')}>
        <Button
          className={classNames(
            'facebookCommentsSlideOutTrigger',
            'pb-3',
            'text-white',
            hideTrigger && 'facebookCommentsSlideOutTriggerHidden'
          )}
          color="orange"
          size="lg"
          onClick={toggleVisibility}
        >
          {isVisibile ? 'Collapse Comments' : 'Expand Comments'}
        </Button>
        <Container fluid>
          <Row>
            <Col xs={1} md={3} className={classNames('d-flex')} onClick={toggleVisibility} role="button"></Col>
            <Col style={{height: '100vh', background: 'var(--bs-white)', overflowY: 'scroll'}}>
              <CommentBox commentFeed={commentFeed} commentsKey={commentsKey} refreshComments={refreshComments} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FacebookCommentsSlideOut;
