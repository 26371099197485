import React, {PureComponent} from 'react';
import {Col, Container, Row} from 'reactstrap';

import Video from 'routes/Products/components/Video';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import List from 'components/List';
function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.data.order;
  const bandB = b.data.order;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}
function getThumbnailUrl(url) {
  const youtubeRegex = /youtu.*\.(?:be|com).*(?:\/|%3D|vi?=)([0-9A-z-_]{11})([%#?&]|$)/gm;
  const match = youtubeRegex.exec(url);
  if (match && match.length > 1) {
    return `https://i.ytimg.com/vi/${match[1]}/maxresdefault.jpg`;
  }
  return 'https://cdn.foodrevolution.org/members/assets/thumbnails/product-placeholder.png';
}

function PlayListVideo(props) {
  const {activelyPlaying, data, handleVideoClick, index, title} = props;
  const subtitle = data.thumb && data.thumb.text ? data.thumb.text : 'Click to watch';
  const videoThumbnail = data.thumb && data.thumb.url ? data.thumb.url : getThumbnailUrl(data.url);
  const indexNumber = index || index === 0 ? index + 1 : 1; //to display the index

  return (
    <div
      className={`d-flex playListVideo align-items-center${activelyPlaying ? ' activelyPlayingVideo' : ''}`}
      onClick={() => {
        handleVideoClick(index);
      }}
    >
      {indexNumber ? (
        <div className="playListVideo-index">
          {activelyPlaying ? <FontAwesomeIcon style={{transform: 'translate(2px, 0)'}} icon={faPlay} /> : indexNumber}
        </div>
      ) : null}
      <div className="playListVideo-thumbnail">
        <img src={videoThumbnail} />
      </div>
      <div className="playListVideo-title d-flex flex-column">{title}</div>
    </div>
  );
}

export default class VideoPlayList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentlyPlayingIndex: 0,
      videoArray: [],
      autoplay: false
    };
    this.handleVideoClick = this.handleVideoClick.bind(this);
  }
  componentDidMount() {
    this.setState({videoArray: this.props.videoArray, videoArrayV2: this.props.videoArrayV2});
  }
  handleVideoClick(index) {
    this.setState({currentlyPlayingIndex: index, autoplay: true});
  }
  render() {
    // const {videoArray} = this.props;
    let {currentlyPlayingIndex, videoArray, videoArrayV2, autoplay} = this.state;

    if (videoArrayV2) {
      videoArray = videoArrayV2; //just a quick patch to use different data format mid-summit
    }
    const currentVideo =
      videoArray.length && videoArray[currentlyPlayingIndex] ? videoArray[currentlyPlayingIndex] : null;
    //the container is added for an alignement issue.  some other components have it.  todo: remove multiple containers

    return (
      <Container fluid>
        <Row>
          <Col className="mainVideo">
            {/* can't just change the content item, need to have multiple Videos */}
            {/* Video component doesn't rerender just with props change, so mapping through array to force rerender */}

            {videoArray.length > 0
              ? videoArray.map((item, i) => {
                  if (currentlyPlayingIndex === i) {
                    return (
                      <div>
                        <Video {...item} playing={autoplay} />
                        {item.downloads ? (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <h5 className="mt-0">Downloads</h5>
                              </Col>
                            </Row>
                            <List contentItems={item.downloads.sort(compare)} />
                          </React.Fragment>
                        ) : null}
                      </div>
                    );
                  }
                })
              : null}
          </Col>
          {videoArray.length > 1 ? (
            <Col lg="4">
              <h5 className="d-md-none">Playlist</h5>
              <div className="playListContainer">
                {videoArray.map((item, i) => {
                  //item = {id:"xxx",title:"xxx", data:{url:broadcast, thumb:{text:'Watch at any time'}}}
                  return (
                    <PlayListVideo
                      {...item}
                      activelyPlaying={currentlyPlayingIndex === i}
                      handleVideoClick={this.handleVideoClick}
                      index={i}
                      key={i}
                    />
                  );
                })}
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    );
  }
}
