import React, {PureComponent} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
//requires props.isOpen, props.toggle
class GeneralModal extends PureComponent {
  constructor(props) {
    super(props);
    this.Component = this.props.component; //component to be displayed in modal
  }
  render() {
    const {Component} = this;
    const {title, isOpen, body} = this.props;

    return (
      <Modal size="lg" isOpen={isOpen} toggle={this.props.toggle} centered className="d-print-none">
        {title ? <ModalHeader>{title}</ModalHeader> : ''}
        <ModalBody style={{wordWrap: 'break-word'}}>
          {body || ' '}
          {<Component /> && <Component />}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button onClick={this.props.toggle} color="light" className="mr-3">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default GeneralModal;
