/* eslint-disable */

import 'whatwg-fetch';

class Api {
  static headers() {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      dataType: 'json'
    };
  }

  static get(route, token) {
    return this.xhr(route, null, 'GET', token);
  }

  static put(route, params, token) {
    return this.xhr(route, params, 'PUT', token);
  }

  static post(route, params, token, opts) {
    return this.xhr(route, params, 'POST', token, opts);
  }

  static delete(route, params, token) {
    return this.xhr(route, params, 'DELETE', token);
  }

  static patch(route, params, token) {
    return this.xhr(route, params, 'PATCH', token);
  }

  static xhr(route, params, verb, token = '', opts = {}) {
    let host;
    // iow this is cross-domain, for instance AWS public picture upload (profile picture)
    if (route.substring(0, 8) === 'https://') {
      host = '';
    } else {
      host = process.env.ROOT_URL;
    }
    const url = `${host}${route}`;
    let options = Object.assign({method: verb}, params ? {body: JSON.stringify(params)} : null);
    if (!opts.removeHeaders) options.headers = Api.headers();
    if (!opts.removeHeaders && token) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }
    if (!opts.removeHeaders && (verb == 'POST' || verb == 'PATCH' || verb == 'PUT')) {
      options.headers['Content-Type'] = 'application/json';
    }
    // for uploading a CSV
    if (verb == 'POST' && params instanceof FormData) {
      delete options.headers['Content-Type'];
      options.body = params;
    }

    //Thanks iOS 9 for freaking out if a header starts or ends with a space
    for (var key in options.headers) {
      if (options.headers.hasOwnProperty(key)) {
        options.headers[key] = options.headers[key].trim();
        if (key !== key.trim()) {
          options.headers[key.trim()] = options.headers[key];
          delete options.headers[key];
        }
      }
    }
    return fetch(url, options)
      .then(resp => {
        if (resp.ok && resp.statusText !== 'No Content') {
          let json = resp.json().catch(err => ({success: 'true'}));
          return json;
        } else if (resp.status === 402) {
          let json = resp.json();
          return json;
        } else {
          return resp
            .json()
            .then(json => {
              return json;
            })
            .catch(err => {
              return resp;
            });
        }
      })
      .then(json => {
        return json;
      });
  }
}

export const putToS3 = (url, options) => {
  return fetch(url, options)
    .then(resp => !!resp)
    .catch(err => null);
};

export default Api;
