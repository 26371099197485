/* eslint-disable */

import React, {Component} from 'react';

import {connect} from 'react-redux';

import ReactMarkdown from '../../../../lib/react-markdown';
import {Container, Row} from 'reactstrap';
import Seperator from '../../../../components/Seperator';

@connect(
  state => ({user: state.user}),
  null
)
class MarkdownProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props //data for entire product
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {product} = this.state;

    return (
      <Container>
        {product && product.data ? product.data.text.map(item => <ReactMarkdown source={item} />) : null}
        <Seperator />
      </Container>
    );
  }
}

export default MarkdownProduct;
