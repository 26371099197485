import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import paypalCheckout from 'braintree-web/paypal-checkout';
import {loadScript} from '@paypal/paypal-js';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';
import {Alert, Button, Col, Container, Row, FormGroup} from 'reactstrap';
import {PayPal as PayPalMark} from 'icons/brand-marks';
import {actions} from 'modules/checkout';

const PayPal = props => {
  const [error, setError] = useState(null);
  const [paypalCheckoutInstance, setPaypalCheckoutInstance] = useState(null);

  useEffect(() => {
    if (!paypalCheckoutInstance) {
      loadScript({
        clientId: process.env.PAYPAL_CLIENT_ID,
        vault: true
      }).then(paypal => {
        paypalCheckout.create(
          {
            client: props.client
          },
          (err, instance) => {
            if (err) {
              setError(err);
              return;
            }
            setPaypalCheckoutInstance(instance);
            return;
          }
        );
      });
    }
  }, []);

  const setAddressFromPayPalAddress = (address, addressType) => {
    props.setNewAddress(
      {
        name: address.recipientName,
        address1: address.line1,
        address2: address.line2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.countryCode
      },
      addressType
    );
  };

  const Tag = props.tag || 'div';
  return (
    <div style={{height: '50px'}}>
      <Tag className="d-flex align-items-center">
        <span
          style={{width: '100%', maxWidth: '280px', height: '45px'}}
          className="m-auto text-center d-flex align-items-center"
        >
          {paypalCheckoutInstance ? (
            <PayPalScriptProvider options={{clientId: process.env.PAYPAL_CLIENT_ID, vault: true}}>
              <div style={{width: '100%', height: '100%'}}>
                <PayPalButtons
                  fundingSource="paypal"
                  style={{
                    label: 'pay',
                    size: 'responsive',
                    color: 'black',
                    shape: 'rect',
                    tagline: false,
                    height: 45
                  }}
                  createBillingAgreement={() =>
                    paypalCheckoutInstance.createPayment({
                      flow: 'vault',
                      billingAgreementDescription: 'Food Revolution Network purchases',
                      enableShippingAddress: props.requiresShipping
                    })
                  }
                  onApprove={(data, actions) => {
                    paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                      if (err) {
                        setError(err);
                        return;
                      }
                      props.setPaymentToken({
                        gateway: 'braintree',
                        token: payload
                      });
                      if (payload.details.billingAddress) {
                        setAddressFromPayPalAddress(payload.details.billingAddress, 'BillingAddress');
                      }
                      if (payload.details.shippingAddress) {
                        setAddressFromPayPalAddress(payload.details.shippingAddress, 'ShippingAddress');
                      }
                      props.processThirdPartyCheckout({
                        firstName: payload.details.firstName,
                        lastName: payload.details.lastName,
                        email: payload.details.email
                      });
                    });
                  }}
                  onCancel={data => {}}
                  onError={err => {
                    setError(err);
                  }}
                />
              </div>
            </PayPalScriptProvider>
          ) : null}
        </span>
      </Tag>
    </div>
  );
};

export default PayPal;
