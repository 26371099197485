export const track = (event, properties, options, callback) => {
  typeof window !== 'undefined' && window.analytics && window.analytics.track
    ? console.log('analytics track found')
    : console.log('window.analytics not found');

  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.track &&
    window.analytics.track(event, properties, options);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.track &&
    window.rudderanalytics.track(event, properties, options, callback);
};
export const page = (event, properties, options, callback) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.page &&
    window.analytics.page(event, properties, options);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.track &&
    window.rudderanalytics.page(event, properties, options, callback);
};

export const identify = (userId, traits, options, callback) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.identify &&
    window.analytics.identify(userId, traits, options);
  typeof window !== 'undefined' &&
    window.rudderanalytics &&
    window.rudderanalytics.identify &&
    window.rudderanalytics.identify(userId, traits, options, callback);
};

export default track;
