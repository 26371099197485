/* eslint-disable */
import Api from 'lib/api';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Data from 'containers/Data';
import ICContent from './components/ICContent';
import styles from 'styles/index.scss';

@connect(
  state => ({user: state.user}),
  null
)
class InterviewCollection extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {Children} = this;
    return <ICContent {...this.props} />;
  }
}

InterviewCollection.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({})
};

InterviewCollection.defaultProps = {
  data: {}
};

InterviewCollection.displayname = 'InterviewCollection';

export default withRouter(InterviewCollection);
