import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import FacebookLikeButton from '../FacebookLikeButton';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export default function FollowButtons(props) {
  return (
    <React.Fragment>
      {props.followText ? (
        <div className="social-follow d-flex align-items-center justify-content-center">
          <div className="social-follow-text mb-2 mb-sm-0 mr-sm-2">Follow us on social:</div>
          <div className="d-flex align-items-center justify-content-center">
            <FollowButtonsList {...props} />
          </div>
        </div>
      ) : (
        <div className={classNames('d-flex align-items-center', props.alignment, props.className)}>
          <FollowButtonsList {...props} />
        </div>
      )}
    </React.Fragment>
  );
}

FollowButtons.defaultProps = {
  alignment: 'justify-content-center justify-content-lg-end',
  className: 'social-follow-link'
};

export function FollowButtonsList(props) {
  const fb = `https://www.facebook.com/${props.facebookLikeUrl}`;
  const insta = `https://www.instagram.com/${props.instagramUser}`;
  const twitter = `https://www.twitter.com/${props.twitterUser}`;
  const pinterest = `https://www.pinterest.com/${props.pinterestUser}`;
  const youtube = `https://www.youtube.com/user/${props.youTubeUser}`;

  return (
    <React.Fragment>
      {props.noLike ? (
        <a className="social-button" href={fb} target="_blank">
          <div className="btn btn-social btn-social-facebook">
            <FontAwesomeIcon className="social-button-icon" icon={faFacebookF} />
          </div>
        </a>
      ) : (
        <div className="social-button social-button-facebook">
          <FacebookLikeButton url={fb} />
        </div>
      )}

      <a className="social-button" href={twitter} target="_blank">
        <div className="btn btn-social btn-social-twitter">
          <FontAwesomeIcon className="social-button-icon" icon={faTwitter} />
        </div>
      </a>

      <a className="social-button" href={insta} target="_blank">
        <div className="btn btn-social btn-social-instagram">
          <FontAwesomeIcon className="social-button-icon" icon={faInstagram} />
        </div>
      </a>

      <a className="social-button" href={pinterest} target="_blank">
        <div className="btn btn-social btn-social-pinterest">
          <FontAwesomeIcon className="social-button-icon" icon={faPinterestP} />
        </div>
      </a>

      <a className="social-button" href={youtube} target="_blank">
        <div className="btn btn-social btn-social-youtube">
          <FontAwesomeIcon className="social-button-icon" icon={faYoutube} />
        </div>
      </a>
    </React.Fragment>
  );
}

FollowButtonsList.defaultProps = {
  facebookLikeUrl: 'foodrevolutionnetwork',
  twitterUser: 'afoodrevolution',
  instagramUser: 'foodrevolutionnetwork',
  pinterestUser: 'afoodrevolution',
  youTubeUser: 'foodrevolutionsummit'
};
