import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import ProductSidebar from 'components/ProductSidebar';
import AudioPlayerMobile from 'routes/Products/components/AudioPlayerMobile';
import styles from 'styles/index.scss';
//The Single Category shows all the AudioPlayerMobile for a given Category
//It is housed by InterviewCollectionV2
const {expander} = styles;
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import BreadCrumbs from 'components/BreadCrumbs';

//shown in its own page & route
class SingleCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null, //data for entire product
      nav: 'loading',
      currentContent: null, //the current ICContentItem the user is looking at
      item: this.props.item,
      productTitle: this.props.productTitle,
      categoryTitle: this.props.categoryTitle,
      navItems: [{name: 'Collection', typeOfComponent: null, slug: null, icon: faHome}] //used to show what nav items are present in top};
    };
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
    this.thisCategoryInPath = thisPath[3];
    this.categorySlugRegex = new RegExp(`^${this.thisProductInPath}-`, 'g');
    this.groupSlugRegex = new RegExp(`^${this.thisCategoryInPath}-`, 'g');

    this.productPath = `/products/${this.thisProductInPath}`;
    this.categoryPath = `${this.productPath}/${this.thisCategoryInPath.replace(this.categorySlugRegex, '')}/`;
  }

  componentDidMount() {
    const currentContent = this.state.item;
    this.setState({currentContent: currentContent});
  }

  handleNav = slug => {
    if (slug === '') {
      this.props.history.push(`${this.productPath}/`);
    } else {
      this.thisCategoryInPath
        ? this.props.history.push(
            `${this.categoryPath}/${slug.replace(this.categorySlugRegex, '').replace(this.groupSlugRegex, '')}`
          )
        : this.props.history.push(`${this.productPath}/${slug.replace(this.categorySlugRegex, '')}`);
    }
    window.scrollTo(0, 0);
  };
  render() {
    const {navItems, productTitle, categoryTitle, item} = this.state;

    return (
      <ProductSidebar
        navItems={navItems}
        handleNav={() => {
          this.handleNav('');
        }}
      >
        <Container className="container pt-5">
          <BreadCrumbs>
            <Link to={this.productPath} className="pr-1">
              {productTitle}
            </Link>{' '}
            /{' '}
            {categoryTitle ? (
              <Link to={this.categoryPath} className="pr-1">
                {categoryTitle}
              </Link>
            ) : null}
            {categoryTitle ? '/ ' : null}
            {item.title}
          </BreadCrumbs>
          <div className="row mb-4">
            {/* special padding to line up with audioPlayerMobile intra-component padding */}
            <div className="col-xs-12 w-100 mx-3 px-2">
              <h4 className={expander}>{this.state.currentContent ? this.state.currentContent.title : null}</h4>
            </div>
          </div>
          {this.state.currentContent
            ? this.state.currentContent.Children.map(category => {
                return (
                  <div className="row p-0" key={category.id}>
                    {/* AudioPlayerMobile expects whole InterviewCollectionItem  data*/}
                    <div className="col-xs-12 w-100 mx-3">
                      <AudioPlayerMobile save {...category} />
                    </div>
                  </div>
                );
              })
            : null}
        </Container>
      </ProductSidebar>
    );
  }
}

SingleCategory.propTypes = {
  categoryID: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SingleCategory;
