import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'react-markdown';
import {Link} from 'react-scroll';

import Footer from 'routes/Checkout/components/Upsell/components/FRSCommon/Footer';
import Section, {SectionImage} from 'routes/Checkout/components/Upsell/components/FRSCommon/Section';
import {acSpeakers} from './data.json';

import UpsellSales from 'routes/Checkout/components/Upsell/components/FRSCommon/UpsellSales';

const CSS_URL = process.env.WLC_STYLESHEET_URL;

function ActionCollectionUpsell(props) {
  return (
    <UpsellSales
      className="offer-ac"
      heading={
        <React.Fragment>
          <h3 className="section-heading">One-Time Offer Only!</h3>
          <h4 className="mt-0 mb-4">
            Click the button below, and this premium <br className="d-none d-lg-block" />
            collection will be yours, instantly.
          </h4>
        </React.Fragment>
      }
      upsellNoText={
        <React.Fragment>
          <p>
            <i>Please click one of the buttons above.</i> Clicking the orange button will add the{' '}
            <br className="d-none d-xl-block" />
            Food Revolution Action Collection to your purchase for an additional, one-time cost of $47.
          </p>
          <p>
            <b>
              And, of course, your purchase comes with our 60-day, <br className="d-none d-sm-block d-lg-none" />
              total money-back guarantee.
            </b>
          </p>
        </React.Fragment>
      }
      sku="ac"
      slug="wlc-ac18"
      noDisclaimer
      {...props}
    />
  );
}

// Action Collection Speaker Component
export function AcSpeakerListDetail(props) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return acSpeakers.map((speaker, i) => <AcSpeakerDetailItem {...speaker} key={i} />);
}

function AcSpeakerDetailItem({name, image, title, description}) {
  return (
    <div className="ac-speaker-detail">
      <Row className="align-items-center justify-content-center">
        <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
          <img className="speaker-info-headshot rounded-circle" src={image} />
        </Col>
        <Col lg="9">
          <h5 className="section-heading text-400">
            {name} — <b>{title}</b>
          </h5>
          <ReactMarkdown source={description} />
        </Col>
      </Row>
    </div>
  );
}

class AC18 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false};
  }

  componentDidMount() {
    this.linkTag = document.createElement('link');
    const head = document.getElementsByTagName('head')[0];
    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    head.appendChild(this.linkTag);
    const css = 'html { font-size: unset; }';
    this.style = document.createElement('style');

    head.appendChild(this.style);

    this.style.type = 'text/css';
    if (this.style.styleSheet) {
      // This is required for IE8 and below.
      this.style.styleSheet.cssText = css;
    } else {
      this.style.appendChild(document.createTextNode(css));
    }
    document.getElementsByTagName('html')[0].classList.add('frn-html');
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.style && this.style.remove();
    document.getElementsByTagName('html')[0].classList.remove('frn-html');
  }

  render() {
    const {props} = this;
    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="page page-nosocial">
        <div className="page-content">
          <Section id="ac-header" className="section-angle-first" innerClass="text-center text-white" color="black">
            <Container className="text-white">
              <Row>
                <Col>
                  <h3 className="section-heading text-uppercase">
                    Congratulations <br className="d-none d-sm-block d-md-none" />
                    on Your Purchase!
                  </h3>
                  <p className="mb-0 text-white">
                    You’ve just taken a major step for your health and well-being!{' '}
                    <br className="d-none d-sm-block d-lg-none" />
                    While you’re here, <br className="d-none d-lg-block" />
                    we want to offer you{' '}
                    <b>
                      one more opportunity to <br className="d-none d-sm-block d-lg-none" />
                      help you start getting immediate results…
                    </b>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mt-4">
                <Col lg="10" className="px-0 px-sm-3">
                  <div className="video-wrapper">
                    <ReactPlayer
                      className="video-player"
                      width="100%"
                      height="100%"
                      url="https://www.youtube.com/watch?v=8OC-ja6tkco"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>
                    Discover the Top Revolutionary <br className="d-none d-sm-block d-lg-none" />
                    Health Actions You Can Take <i>RIGHT NOW!</i>
                  </h5>
                  <p className="text-white">
                    If your biggest motivation in joining WHOLE Life Club <br className="d-none d-sm-block d-md-none" />
                    is wanting trustworthy knowledge <br className="d-none d-lg-block" />
                    you can put into <b>ACTION…</b> <br className="d-none d-sm-block d-md-none" />
                    this special offer is for you!
                  </p>
                  <Link
                    className="btn btn-cta btn-lg mb-3"
                    activeClass="active"
                    spy={true}
                    offset={0}
                    to="ac-cta"
                    smooth={true}
                    href="#"
                  >
                    One-Time Offer
                  </Link>
                  <p className="text-white">
                    <em>
                      This opportunity won’t be available again <br className="d-none d-sm-block d-lg-none" />
                      at this price after you close this page.
                    </em>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="ac-build"
            imgPosition="right"
            color="light-gray"
            imgUrl="https://cdn.foodrevolution.org/ac18/backgrounds/1310306458.jpg"
          >
            <h2 className="section-heading">
              Want better health <br className="d-none d-sm-block d-lg-none d-xl-block" />
              and a different world?
            </h2>
            <h2 className="mt-0">Come build it with us!</h2>
            <p>
              With so many intense and heartbreaking issues today, from war to chronic disease to environmental
              pollution, it can be hard to know <br className="d-none d-sm-block d-md-none" />
              what are the single most impactful things you can do for your{' '}
              <br className="d-none d-sm-block d-md-none d-lg-block" />
              health and the planet.
            </p>
            <p>
              It might sometimes even seem like there’s nothing <br className="d-none d-lg-block d-xl-none" />
              we can do, <br className="d-none d-sm-block d-md-none d-xl-block" />
              and hope can be hard to come by.
            </p>
            <p>
              <b>
                But the truth is we ALL can make a difference. <br className="d-none d-lg-block d-xl-none" />
                It really is possible.
              </b>
            </p>
            <p>
              It all starts with the choices you make and <br className="d-none d-lg-block d-xl-none" />
              the food you eat <br className="d-none d-xl-block" />— and it’s never been more{' '}
              <br className="d-none d-lg-block d-xl-none" />
              urgent than it is right now.
            </p>
          </SectionImage>

          <SectionImage
            id="ac-part"
            imgPosition="left"
            color="light-gray"
            imgUrl="https://cdn.foodrevolution.org/ac18/backgrounds/825154864.jpg"
          >
            <p>
              That’s why every year, best-selling author (and Food Revolution{' '}
              <br className="d-none d-sm-block d-lg-none" />
              Network co-founder), John Robbins, interviews 24 of the world’s{' '}
              <br className="d-none d-sm-block d-lg-none" />
              leading food revolutionaries.
            </p>
            <p>
              We’ve held the Food Revolution Summits over <br className="d-none d-lg-block d-xl-none" />
              the last 11 years: <br className="d-none d-md-block d-lg-none d-xl-block" />
              to help people get the truth, stand <br className="d-none d-lg-block d-xl-none" />
              up, and take meaningful action <br className="d-none d-md-block d-lg-none d-xl-block" />
              for their health and <br className="d-none d-lg-block d-xl-none" />
              the planet’s health.
            </p>
            <p>
              This grassroots movement is growing faster than <br className="d-none d-lg-block d-xl-none" />
              ever, with millions <br className="d-none d-sm-block d-lg-none d-xl-block" />
              of lives and families changed. <br className="d-none d-lg-block d-xl-none" />
              And it’s working!
            </p>
            <p>
              People all around the globe are making real, positive changes{' '}
              <br className="d-none d-md-block d-lg-none" />
              for themselves and the environment.
            </p>
            <h4 className="mt-4">Want to be part of the solution?</h4>
            <h5 className="mt-0">
              The Action Collection is a curated <br className="d-none d-lg-block d-xl-none" />
              collection <br className="d-none d-sm-block d-md-none" />
              of special Food Revolution Summit interviews <br className="d-none d-sm-block d-md-none" />
              that are focused <br className="d-none d-xl-block" />
              on <br className="d-none d-lg-block d-xl-none" />
              steps you can take <br className="d-none d-sm-block d-md-none" />
              to get RESULTS.
            </h5>
          </SectionImage>

          <Section id="ac-speakers" color="white">
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center mb-5">
                    Start Learning from These <br className="d-none d-sm-block d-xl-none" />
                    10 Health Revolutionaries TODAY
                  </h3>
                  <AcSpeakerListDetail />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="ac-interviews" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading mb-5">Here’s What You’ll Get…</h2>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center mb-4">
                <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                  <img
                    className="center-block"
                    src="https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-action-focused-interviews.png"
                    alt="10 Action-Focused Interviews"
                  />
                </Col>
                <Col lg="9">
                  <h2 className="section-heading text-center text-lg-left">10 Action-Focused Interviews:</h2>
                  <p>
                    We scoured every Food Revolution Summit presentation to{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    hand-select 10 of the most useful, action-centric interviews EVER. (If you already own previous Food
                    Revolution Summits, don’t worry! Some of the speakers will be familiar, but these interviews are
                    unique, and unless you own almost every year of summits, many will be new to you — and either way,
                    this collection brings it all together so you can save time and energy putting it{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    all into action).
                  </p>
                  <p>
                    <b>
                      If you want the knowledge you need to help you get real, lasting health results, these are the
                      interviews you want to own and <br className="d-none d-sm-block d-md-none" />
                      absorb for life…
                    </b>
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center mb-4">
                <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                  <img
                    className="center-block"
                    src="https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-transcripts.png"
                    alt="10 Action-Focused Interviews"
                  />
                </Col>
                <Col lg="9">
                  <h2 className="section-heading text-center text-lg-left">10 Transcripts:</h2>
                  <p>
                    In addition to the audio files, you’ll get full written transcripts{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    for each interview so you can see and review exactly <br className="d-none d-lg-block d-xl-none" />
                    what was said…
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center mb-4">
                <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                  <img
                    className="center-block"
                    src="https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-action-focused-checklists.png"
                    alt="10 Action-Focused Interviews"
                  />
                </Col>
                <Col lg="9">
                  <h2 className="section-heading text-center text-lg-left">10 Action Checklists:</h2>
                  <p>
                    And then, we took it a step further… We created an <b>ACTION CHECKLIST</b>{' '}
                    <br className="d-none d-md-block d-lg-none" />
                    for each interview.
                  </p>
                  <p>
                    Each Action Checklist gives you{' '}
                    <b>
                      <i>the core takeaways + specific action steps</i>
                    </b>{' '}
                    from each presentation, making it easier than ever for you to APPLY all the benefits in your life.
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="ac-order" color="white">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="10">
                  <h3 className="section-heading">
                    When you add the FOOD REVOLUTION <br className="d-none d-xl-block" />
                    ACTION COLLECTION to your order, you’ll <br className="d-none d-xl-block" />
                    instantly get all this for only $47!
                  </h3>
                  <p>Even if you’ve attended Food Revolution Summits in the past…</p>
                  <p>
                    … and even if you already own some of these interviews in the Empowerment Packages{' '}
                    <br className="d-none d-xl-block" />
                    from past Summits (though you’d have to own several Summits, for $197 each,{' '}
                    <br className="d-none d-xl-block" />
                    to have all of these in your collection)…
                  </p>
                  <p>
                    You’ll get <i>immediate</i> value from the Action Checklists,{' '}
                    <br className="d-none d-sm-block d-xl-none" />
                    which you can’t currently get any other way.
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="ac-cta" className="section-angle-last" color="gradient-indigo-purple-right" angle="none">
            <Container>
              <ActionCollectionUpsell {...props} />
            </Container>
          </Section>
        </div>

        <Footer />
      </div>
    );
  }
}
export default AC18;
