import React, {Component} from 'react';
import {Card, CardHeader, Col, Container, Row, Button, CardBody} from 'reactstrap';
import Api from 'lib/api';
import SingleQuestion from './components/SingleQuestion';
import ReviewConfirmation from './components/ReviewConfirmation';
import AssessmentScore from './components/AssessmentScore';

class AssessmentWrapper extends Component {
  constructor(props) {
    super(props);
    this.questions = props.assessment ? props.assessment.data.questions : [];
    this.passingScore = props.assessment.data.passingScore;
    this.state = this.createEmptyState();

    this.submitAnswer = this.submitAnswer.bind(this);
    this.previousQuestion = this.previousQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.goToQuestion = this.goToQuestion.bind(this);
    this.restartAssessment = this.restartAssessment.bind(this);
    this.createEmptyState = this.createEmptyState.bind(this);
  }

  createEmptyState() {
    return {
      currentQuestion: 0,
      answers: this.questions.reduce((acc, q) => ({...acc, [q.id]: ''}), {}),
      complete: false,
      passed: false,
      incorrectAnswers: [],
      score: null,
      passed: null,
      submitted: false,
      seekToQuestion: false
    };
  }

  postState() {
    const {authToken, userId} = JSON.parse(localStorage.getItem('user'));
    const url = `/users/${userId}/content/${this.props.assessment.id}`;
    Api.post(
      url,
      {
        assessmentState: this.state
      },
      authToken
    );
  }

  postAssessment() {
    const {authToken, userId} = JSON.parse(localStorage.getItem('user'));
    const url = `/users/${userId}/assessment/${this.props.assessment.id}`;
    return Api.post(
      url,
      {
        assessmentState: this.state
      },
      authToken
    );
  }

  async getState() {
    const {authToken, userId} = JSON.parse(localStorage.getItem('user'));
    const url = `/users/${userId}/content/${this.props.assessment.id}`;
    const data = await Api.get(url, authToken);

    if (data.length > 0) {
      const resp = data[0];
      const cData = resp.data;

      const state = cData.assessmentState;
      this.setState({
        ...state,
        currentQuestion: state.currentQuestion || 0
      });
    }
  }

  async componentDidMount() {
    await this.getState();
  }

  submitAnswer(a) {
    this.setState(
      prevState => {
        const answers = {...prevState.answers};
        answers[this.questions[prevState.currentQuestion].id] = a;
        return {
          answers
        };
      },
      () => {
        this.postState();
      }
    );
    /*
        if (this.state.currentQuestion < this.questions.length - 1) {
          this.setState(prevState => ({
            currentQuestion: prevState.currentQuestion + 1
          }))
        }

        if ((this.state.currentQuestion + 1) === this.questions.length) {
          this.setState({
            complete: true
          }, () => {
            this.postState()
          })
        }*/
  }

  previousQuestion() {
    this.setState(
      prevState => ({
        currentQuestion: prevState.currentQuestion - 1,
        complete: false
      }),
      () => {
        this.postState();
      }
    );
  }

  nextQuestion() {
    this.setState(
      prevState => ({
        currentQuestion: Math.min(prevState.currentQuestion + 1, this.questions.length - 1),
        complete:
          prevState.complete ||
          Math.min(prevState.currentQuestion + 1, this.questions.length - 1) === prevState.currentQuestion,
        seekToQuestion: false
      }),
      () => {
        this.postState();
      }
    );
  }

  async checkAnswers() {
    const {
      questions,
      state: {answers},
      passingScore
    } = this;

    const correctAnswers = questions.filter(q => (answers[q.id] ?? '') === q.answer).map(q => q.id);
    const incorrectAnswers = questions.filter(q => !correctAnswers.includes(q.id)).map(q => q.id);

    const score = Math.round((100.0 * correctAnswers.length) / questions.length);
    const passed = score >= passingScore;

    this.setState(
      {
        incorrectAnswers,
        score,
        passed,
        submitted: true
      },
      () => {
        this.postAssessment().then(() => this.postState());
      }
    );
  }

  goToQuestion(id) {
    const crr = this.questions.filter(item => item.id === id);
    if (crr.length > 0) {
      const index = this.questions.indexOf(crr[0]);
      this.setState({
        currentQuestion: index,
        seekToQuestion: true
      });
    }
  }

  restartAssessment() {
    this.setState(this.createEmptyState(), () => {
      this.postState();
    });
  }

  render() {
    const {title, slug, data: {thumb = {url: '', alt: ''}} = {}} = this.props.assessment;
    const currentQuestion = this.questions[this.state.currentQuestion];
    const selectedChoice = currentQuestion && currentQuestion.id && this.state.answers && this.state.answers[currentQuestion.id];
    return (
      <Card>
        <CardHeader onClick={this.toggle}>
          <Row className={`align-items-center h-100`}>
            {thumb.url ? (
              <Col xs="auto" className="lessonImage h-100">
                <img src={thumb.url} className="lessonVideoThumbnail " alt={thumb.alt ?? ''} />
              </Col>
            ) : null}
            <Col className="lessonTitle">{title}</Col>
          </Row>
        </CardHeader>
        <CardBody>
          {slug.match(/final-exam/) && !this.props.examsAccessible ? (
            <p>
              You are not yet eligible to take this exam. Please contact{' '}
              <a href="mailto:certification@foodrevolution.org">certification@foodrevolution.org</a> if you have any
              questions.
            </p>
          ) : this.state.submitted ? (
            <AssessmentScore
              score={this.state.score}
              questions={this.questions}
              answers={this.state.answers}
              passed={this.state.passed}
              incorrectAnswers={this.state.incorrectAnswers}
              restartAssessment={this.restartAssessment}
            />
          ) : this.state.complete && !this.state.seekToQuestion ? (
            <ReviewConfirmation
              goToQuestion={this.goToQuestion}
              questions={this.questions}
              answers={this.state.answers}
              submit={this.checkAnswers}
            />
          ) : (
            <SingleQuestion
              id={currentQuestion.id}
              title={currentQuestion.title}
              choices={currentQuestion.choices}
              selectedChoice={selectedChoice}
              selectChoice={this.submitAnswer}
              totalQuestions={this.questions.length}
              currentQuestionIndex={this.state.currentQuestion}
              previousQuestion={this.previousQuestion}
              nextQuestion={this.nextQuestion}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default AssessmentWrapper;
