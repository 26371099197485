import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import EventList from '../Dashboard/components/EventList';
import {page} from 'lib/track';
import moment from 'moment';
import {calendarImageMap} from './data.js';
const TIME_ZONE = process.env.TIMEZONE;
//could be a lib tool but is likely a one off to this component
function extractFirstLink(text) {
  if (!text) {
    return {
      link: '',
      textWithoutLink: ''
    };
  }

  // Regular expression to find the first link
  const linkRegex = /<a[^>]+href="([^"]+)"[^>]*>(.*?)<\/a>/i;
  const match = text.match(linkRegex);

  const firstLink = match ? match[1] : '';
  const textWithoutLink = text
    .replace(linkRegex, '')
    .replace(/<\/?[^>]+(>|$)/g, '')
    .trim();

  return {
    link: firstLink,
    textWithoutLink
  };
}

@connect(state => ({authToken: state.user.authToken}), {})
class Calendar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      events: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    page('calendar');

    this.getAddEventCalender();
  }

  getAddEventCalender() {
    //getting calendar from lambda

    const options = {
      method: 'GET'
    };
    //hit lambda function to get calendar
    fetch(process.env.ADDEVENT_ENDPOINT, options)
      .then(response => response.json())
      .then(response => {
        if (!response || !response.events || !response.events.length || !response.events[0].datetime_start) {
          this.setState({events: []});
          return; //ensure one event is returned
        }
        const originalArray = response.events;

        const convertToNewFormat = original => {
          const date = moment.tz(original.datetime_start, TIME_ZONE); //mooment is needed to bypass the timezone issue caused by browser using system time
          //grab an image out of the description
          const descriptionObject = extractFirstLink(original.description);

          // Specify the options for formatting the date and time
          const options = {
            timeZone: TIME_ZONE, // Set this to 'America/Los_Angeles' explicitly
            hour12: true
          };
          return {
            originalDate: date,
            id: original.id,
            day: new Intl.DateTimeFormat('en-US', {...options, day: 'numeric'}).format(date),
            date: new Intl.DateTimeFormat('en-US', {
              ...options,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(date),
            weekday: new Intl.DateTimeFormat('en-US', {...options, weekday: 'long'}).format(date),
            month: new Intl.DateTimeFormat('en-US', {...options, month: 'long'}).format(date),
            time: new Intl.DateTimeFormat('en-US', {...options, hour: 'numeric', minute: '2-digit'}).format(date),
            timezone: 'PT', // You can leave this as 'PT' if you're consistently working with Pacific Time
            title: original.title,
            subtitle: '',
            imageURL: descriptionObject.link
              ? descriptionObject.link
              : calendarImageMap[original.title]
              ? calendarImageMap[original.title].img
              : calendarImageMap['default'].img,

            description: descriptionObject.textWithoutLink
              ? descriptionObject.textWithoutLink
              : calendarImageMap[original.title]
              ? calendarImageMap[original.title].description
              : calendarImageMap['default'].description,
            location: original.location
          };
        };

        this.setState({events: originalArray.map(convertToNewFormat)});
      })
      .catch(err => console.error(err));
  }

  render() {
    const {events} = this.state;

    const {title, description} = this.props;
    return (
      <div className="container">
        <Row>
          <Col xs="12" md="8">
            <h4>{title ? title : 'Upcoming Live Events'}</h4>

            <p>
              {description
                ? description
                : 'Immerse yourself in our live events, where like-minded people converge to explore the richness of plant-based living.'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>{events && events.length ? <EventList events={events} /> : 'Check back soon for upcoming events'}</Col>
        </Row>
      </div>
    );
  }
}

export default Calendar;
