import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'reactstrap';
import Markdown from 'routes/Products/components/Markdown';

class MarkdownPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    console.log(this.props);
    const {id, title, data:{markdown = ""}={}} = this.props || {};
    return (
      <Container key={id} style={{minHeight: '100vh'}}>
        <Row>
            <Col xs="auto">
              <h4>{title}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
                <Markdown source={markdown} escapeHtml={false} />
            </Col>
          </Row>
      </Container>
    );
  }
}

MarkdownPage.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default MarkdownPage;
