import React from 'react';
import {Col, Row} from 'reactstrap';
import ToggleIcons from 'components/ToggleIcons';
import {getFullTimeString} from 'lib/time';
import Icon from 'components/Icon';

function LessonHeaderTracker(props) {
  const {
    lessonIndex,
    icons,
    iconsAccordian,
    lesson,
    lessonState,
    isOpen,
    isUnstructured,
    lessonLocked,
    premieresAt
  } = props;
  //TODO pic a suitable default image

  const img =
    lesson && lesson.data && lesson.data.thumb && lesson.data.thumb.url
      ? lesson.data.thumb.url
      : 'https://cdn.foodrevolution.org/global/frn-logo.svg';
  const lessonType = lesson.data && lesson.data.type ? lesson.data.type : null;
  const lessonNumber = lesson.data.num ? lesson.data.num : lessonIndex + 1;
  const useNumber = lesson.data.num || !lessonType;
  const alt =
    lesson.data && lesson.data.thumb && lesson.data.thumb.alt
      ? lesson.data.thumb.alt
      : 'Food Revolution Logo - Click for Video';
  const date = getFullTimeString(parseInt(premieresAt));

  return (
    <Row className={`align-items-center h-100`}>
      <Col xs="auto" className="lessonImage h-100">
        <img src={img} className="lessonVideoThumbnail " alt={alt} />
      </Col>
      {lessonLocked ? (
        <div className="icon text-center">
          <Icon directPassIcon={['far', 'lock']} color="white" />
        </div>
      ) : null}
      <Col className="lessonTitle">
        {!isUnstructured ? `${lessonType ?? 'Lesson'}${useNumber ? ` ${lessonNumber}` : ''}:` : ''}
        {!isUnstructured ? <br /> : ''}
        {lesson && lesson.title}
      </Col>
      <Col xs="auto">
        {lessonLocked ? (
          <div className="text-right text-light">
            <h5 className="text-light">Unlocks:</h5>
            {date}
          </div>
        ) : (
          <>
            {!isUnstructured ? (
              <ToggleIcons checked={lessonState && lessonState.completed} icons={icons} color={['green', 'black']} />
            ) : null}
            &nbsp;
            <ToggleIcons checked={isOpen} icons={iconsAccordian} />
          </>
        )}
      </Col>
    </Row>
  );
}

export default LessonHeaderTracker;
