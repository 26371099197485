/* eslint-disable no-console */

// ------------------------------------
// Constants
// ------------------------------------
const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
const SHOW_USER_MESSAGE = 'SHOW_USER_MESSAGE';
const CLEAR_USER_MESSAGE = 'CLEAR_USER_MESSAGE';
const NAVIGATE_TO = 'NAVIGATE_TO';
const UPDATE_LAST_PRODUCT_VISITIED = 'UPDATE_LAST_PRODUCT_VISITIED';
const TOGGLE_PRODUCT_NAVIGATION = 'TOGGLE_PRODUCT_NAVIGATION';

// ------------------------------------
// Actions
// ------------------------------------

// This takes an optional manual setting, convenience
const toggleSideBar = manualSetting => ({
  type: TOGGLE_SIDE_BAR,
  showSideBar: manualSetting
});

const toggleProductNavigation = () => {
  console.log('toggleProductNavigation clicked');
  return {
    type: TOGGLE_PRODUCT_NAVIGATION
  };
};

const navigateTo = location => ({
  type: NAVIGATE_TO,
  navigateTo: location
});

const updateLastProductVisited = slug => ({
  type: UPDATE_LAST_PRODUCT_VISITIED,
  slug
});

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actions = {
  navigateTo,
  updateLastProductVisited,
  toggleSideBar,
  toggleProductNavigation
};

// ------------------------------------
// Reducer
// ------------------------------------

const storedAppState = JSON.parse(localStorage.getItem('app'));
const isWide = document.body.clientWidth >= 768;

const initialState = {
  lastProductVisited: storedAppState ? storedAppState.lastProductVisited : null,
  navigateTo: '',
  showSideBar: storedAppState ? storedAppState.showSideBar && isWide : isWide,
  showUserMessage: false,
  userMessage: '',
  productNavigationIsOpen: false
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_PRODUCT_NAVIGATION:
      console.log(state.productNavigationIsOpen);
      return {
        ...state,
        productNavigationIsOpen: !state.productNavigationIsOpen
      };
    case TOGGLE_SIDE_BAR:
      // Saves 'application' state to localStorage
      // Watch out: Technically this is an anti-pattern (no side effects in reducer)
      localStorage.setItem('app', JSON.stringify({...state, showSideBar: !state.showSideBar}));
      return {
        ...state,
        showSideBar: !state.showSideBar
      };
    case SHOW_USER_MESSAGE:
      return {
        ...state,
        showUserMessage: true,
        userMessage: action.userMessage
      };
    case CLEAR_USER_MESSAGE:
      return {
        ...state,
        showUserMessage: false,
        userMessage: ''
      };
    case NAVIGATE_TO:
      return {
        ...state,
        navigateTo: action.navigateTo
      };
    case UPDATE_LAST_PRODUCT_VISITIED:
      localStorage.setItem('app', JSON.stringify({...state, lastProductVisited: action.slug}));
      return {
        ...state,
        lastProductVisited: action.slug
      };
    default:
      return state;
  }
}

/* eslint-enable no-console */
