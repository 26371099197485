import AC18 from './AC18';
import FTFImpactKit from './FTFImpactKit';
import FFImpactKit from './FFImpactKit';
import ImpactKit from './ImpactKit';
import UHC18 from './UHC18';
import UHC19 from './UHC19';
import UHC20 from './UHC20';
import UHC21 from './UHC21';
import PPT18 from './PPT18';
import FRS22 from './FRS22';
import THC from './THC';

export default {
  AC18,
  FTFImpactKit,
  FFImpactKit,
  ImpactKit,
  UHC18,
  UHC19,
  UHC20,
  UHC21,
  PPT18,
  FRS22,
  THC
};
