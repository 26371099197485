import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

export default function WiredModal({children, isOpen, toggle, ...props}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} returnFocusAfterClose={false} {...props}>
      <ModalHeader toggle={toggle} />
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
}
