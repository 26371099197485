/* eslint-disable no-console */

import Api from '../../lib/api';
import history from 'store/history';
// ------------------------------------
// Constants
// ------------------------------------
const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
const PRODUCT_FAILURE = 'PRODUCT_FAILURE';

const LOAD_PRODUCTS_REQUEST = 'LOAD_PRODUCTS_REQUEST';
const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';

// const CONTENT_REQUEST = 'CONTENT_REQUEST';
const CONTENT_SUCCESS = 'CONTENT_SUCCESS';

const ADD_PRODUCT = 'ADD_PRODUCTS';

const SET_BASE_PATH = 'SET_BASE_PATH';

// ------------------------------------
// Actions
// ------------------------------------

const requestProduct = slug => ({
  type: PRODUCT_REQUEST,
  slug
});

const receiveProduct = (product, content, contentId) => ({
  type: PRODUCT_SUCCESS,
  product,
  content,
  contentId
});

// const productError = error => ({
//   type: PRODUCT_FAILURE,
//   error
// });

const requestProducts = () => ({
  type: LOAD_PRODUCTS_REQUEST
});

const receiveProducts = products => ({
  type: LOAD_PRODUCTS_SUCCESS,
  products
});

// const receiveContent = content => ({
//   type: CONTENT_SUCCESS,
//   content
// });

const addProduct = product => ({
  type: ADD_PRODUCT,
  product
});

const setBasePath = path => ({
  type: SET_BASE_PATH,
  path
});

// ------------------------------------
// Helpers
// ------------------------------------
const loadProduct = (path, contentUrl) => dispatch => {
  dispatch(requestProduct(contentUrl));

  return Api.get(contentUrl)
    .then(resp => {
      console.log(resp);
      dispatch(receiveProduct(resp.product, resp.contentItem, resp.contentItem.id));
      dispatch(setBasePath(path));
    })
    .catch(error => {
      console.log(error);
      // dispatch(productError(error));
      history.push('/products');
    });
};

const loadProducts = () => (dispatch, getState) => {
  dispatch(requestProducts());
  let id;
  const {authToken} = getState().user;
  // Get user id from auth token
  if (authToken !== '') id = JSON.parse(atob(authToken.split('.')[1])).userId;

  return Api.get(`/users/${id}/products`, authToken)
    .then(resp => {
      // Resp should be an array of products

      // Create hash table using slug as key
      const productMap = {};
      for (let i = 0; i < resp.length; i += 1) {
        const p = resp[i];
        productMap[p.slug] = p;
      }

      dispatch(receiveProducts(productMap));
    })
    .catch(error => {
      console.log(error);
    });
};

const addProductsToAll = products => dispatch => {
  products.map(product => dispatch(addProduct(product)));
};

const loadSurveyResponses = id => dispatch => {
  console.log('ACTION: loading survey responses, id: ', id);
};

const postSurveyResponse = (id, value) => dispatch => {
  console.log('ACTION: posting survey response, id: ', id, 'value: ', value);
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actions = {
  addProductsToAll,
  loadProduct,
  loadProducts,
  loadSurveyResponses,
  postSurveyResponse,
  setBasePath
};

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  all: {},
  recent: {},
  content: {},
  currentContentId: null,
  basePath: ''
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BASE_PATH:
      return {
        ...state,
        basePath: action.path
      };
    case ADD_PRODUCT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.product.slug]: action.product
        }
      };
    case PRODUCT_REQUEST:
      return {
        ...state,
        recent: {
          slug: action.slug
        }
      };
    case PRODUCT_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.product.slug]: action.product
        },
        recent: action.product,
        content: {
          ...state.content,
          [action.content.id]: action.content
        },
        currentContentId: action.contentId
      };
    case PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        all: action.products
      };
    case CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          ...state.content,
          [action.content.id]: action.content
        }
      };
    default:
      return state;
  }
}

/* eslint-enable no-console */
