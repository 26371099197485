import GlobalSearchV2 from '../../../../../../components/GlobalSearchV2';
import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {page} from '../../../../../../lib/track';
function RecipesPage(props) {
  const {authToken, title, description} = props;
  page('recipes');
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs="12" md="8">
            <h4>{title ? title : 'Recipes'}</h4>

            <p>
              {description
                ? description
                : "The majority of our recipes are either already friendly to a variety of Whole Food Plant-Based ways of eating, or include suggested substitutions. Please be sure to check the Chef's Notes!"}
            </p>
          </Col>
        </Row>
      </Container>
      <GlobalSearchV2 authToken={authToken} types={['recipe']} />
    </React.Fragment>
  );
}
export default RecipesPage;
