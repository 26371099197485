import Api from '../../lib/api';
import {refundMessages} from '../messages.js';

// Refunds
const UPDATE_ORDER_ID_SEARCH_TEXT = 'UPDATE_ORDER_ID_SEARCH_TEXT';
const UPDATE_USER_EMAIL_SEARCH_TEXT = 'UPDATE_USER_EMAIL_SEARCH_TEXT';
const UPDATE_USER_FIRST_NAME_SEARCH_TEXT = 'UPDATE_USER_FIRST_NAME_SEARCH_TEXT';
const UPDATE_USER_LAST_NAME_SEARCH_TEXT = 'UPDATE_USER_LAST_NAME_SEARCH_TEXT';
const UPDATE_SAVED_ORDER_ID_SEARCH_TEXT = 'UPDATE_SAVED_ORDER_ID_SEARCH_TEXT';
const UPDATE_SAVED_USER_EMAIL_SEARCH_TEXT = 'UPDATE_SAVED_USER_EMAIL_SEARCH_TEXT';
const UPDATE_SAVED_USER_FIRST_NAME_SEARCH_TEXT = 'UPDATE_SAVED_USER_FIRST_NAME_SEARCH_TEXT';
const UPDATE_SAVED_USER_LAST_NAME_SEARCH_TEXT = 'UPDATE_SAVED_USER_LAST_NAME_SEARCH_TEXT';
const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
const UPDATE_RESULTS = 'UPDATE_RESULTS';
const UPDATE_TOTAL_RESULTS = 'UPDATE_TOTAL_RESULTS';
const UPDATE_TOTAL_PAGES = 'UPDATE_TOTAL_PAGES';
const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';

const updateOrderIdSearchText = text => ({
  type: UPDATE_ORDER_ID_SEARCH_TEXT,
  text
});

const updateUserEmailSearchText = text => ({
  type: UPDATE_USER_EMAIL_SEARCH_TEXT,
  text
});

const updateUserFirstNameSearchText = text => ({
  type: UPDATE_USER_FIRST_NAME_SEARCH_TEXT,
  text
});

const updateUserLastNameSearchText = text => ({
  type: UPDATE_USER_LAST_NAME_SEARCH_TEXT,
  text
});

const updateSavedOrderIdSearchText = text => ({
  type: UPDATE_SAVED_ORDER_ID_SEARCH_TEXT,
  text
});

const updateSavedUserEmailSearchText = text => ({
  type: UPDATE_SAVED_USER_EMAIL_SEARCH_TEXT,
  text
});

const updateSavedUserFirstNameSearchText = text => ({
  type: UPDATE_SAVED_USER_FIRST_NAME_SEARCH_TEXT,
  text
});

const updateSavedUserLastNameSearchText = text => ({
  type: UPDATE_SAVED_USER_LAST_NAME_SEARCH_TEXT,
  text
});

const updateLoadingStatus = boolean => ({
  type: UPDATE_LOADING_STATUS,
  loading: boolean
});

const updateResults = results => ({
  type: UPDATE_RESULTS,
  results
});

const updateTotalResults = number => ({
  type: UPDATE_TOTAL_RESULTS,
  number
});

const updateTotalPages = number => ({
  type: UPDATE_TOTAL_PAGES,
  totalPages: number
});

const updateCurrentPage = number => ({
  type: UPDATE_CURRENT_PAGE,
  currentPage: number
});

const updatePageSize = number => ({
  type: UPDATE_PAGE_SIZE,
  pageSize: number
});

const updateErrorMessage = errorMessageOrCode => {
  let errorMessage = '';
  if (refundMessages[errorMessageOrCode]) {
    errorMessage = refundMessages[errorMessageOrCode].text;
  } else {
    errorMessage = errorMessageOrCode;
  }

  return {
    type: UPDATE_ERROR_MESSAGE,
    errorMessage
  };
};

const search = pageNumber => (dispatch, getState) => {
  dispatch(updateLoadingStatus(true));
  const {
    currentPage,
    errorMessage,
    orderIdSearchText,
    pageSize,
    savedOrderIdSearchText,
    savedUserEmailSearchText,
    savedUserFirstNameSearchText,
    savedUserLastNameSearchText,
    userEmailSearchText,
    userFirstNameSearchText,
    userLastNameSearchText
  } = getState().admin;

  const isErrorMessage = errorMessage.length;
  if (isErrorMessage) {
    dispatch(updateErrorMessage(''));
  }
  // watch name
  let orderId = '';
  let email = '';
  let firstName = '';
  let lastName = '';

  let requestedPage;
  if (!!pageNumber || pageNumber === 0) {
    requestedPage = pageNumber;
    orderId = savedOrderIdSearchText;
    email = savedUserEmailSearchText;
    firstName = savedUserFirstNameSearchText;
    lastName = savedUserLastNameSearchText;
  } else {
    requestedPage = 0;
    orderId = orderIdSearchText;
    email = userEmailSearchText;
    firstName = userFirstNameSearchText;
    lastName = userLastNameSearchText;
  }

  const data = {
    email,
    firstName,
    lastName,
    orderId
  };
  const {authToken} = getState().user;
  Api.post(`/search?page=${requestedPage}`, data, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        if (resp.gatewayCode) {
          dispatch(updateLoadingStatus(false));
          dispatch(updateErrorMessage(resp.gatewayCode));
          return;
        }

        if (requestedPage !== currentPage) {
          dispatch(updateCurrentPage(requestedPage));
        }

        dispatch(updateResults(resp.rows));
        dispatch(updateTotalPages(resp.count));
        if (!resp.rows[0]) {
          dispatch(updateTotalResults(0));
        } else {
          dispatch(updateTotalResults(resp.totalRows));
        }
        dispatch(updateSavedOrderIdSearchText(orderId));
        dispatch(updateSavedUserEmailSearchText(email));
        dispatch(updateSavedUserFirstNameSearchText(firstName));
        dispatch(updateSavedUserLastNameSearchText(lastName));

        if (resp.pageSize && resp.pageSize !== pageSize) {
          dispatch(updatePageSize(resp.pageSize));
        }

        dispatch(updateOrderIdSearchText(''));
        dispatch(updateUserEmailSearchText(''));
        dispatch(updateUserFirstNameSearchText(''));
        dispatch(updateUserLastNameSearchText(''));
        dispatch(updateLoadingStatus(false));
      } else {
        if (resp.gatewayCode && refundMessages[resp.gatewayCode]) {
          dispatch(updateErrorMessage(resp.gatewayCode));
        } else {
          dispatch(updateErrorMessage('No matches found'));
        }
        dispatch(updateResults([]));
        dispatch(updateTotalPages(0));
        dispatch(updateTotalResults(0));
        dispatch(updateSavedOrderIdSearchText(''));
        dispatch(updateSavedUserEmailSearchText(''));
        dispatch(updateSavedUserFirstNameSearchText(''));
        dispatch(updateSavedUserLastNameSearchText(''));
        dispatch(updateOrderIdSearchText(''));
        dispatch(updateUserEmailSearchText(''));
        dispatch(updateUserFirstNameSearchText(''));
        dispatch(updateUserLastNameSearchText(''));
        dispatch(updateLoadingStatus(false));
      }
    })
    .catch(error => {
      console.log('Search error: ', error);
      dispatch(updateErrorMessage('API error'));
      dispatch(updateResults([]));
      dispatch(updateSavedOrderIdSearchText(''));
      dispatch(updateSavedUserEmailSearchText(''));
      dispatch(updateSavedUserFirstNameSearchText(''));
      dispatch(updateSavedUserLastNameSearchText(''));
      dispatch(updateLoadingStatus(false));
    });
};

const getCSV = (startDate, endDate, product, type) => async (dispatch, getState) => {
  const {authToken} = getState().user;
  let url;
  if (type === 'shipping') {
    url = `/orders/collectOrdersToShip?startDate=${startDate}&endDate=${endDate}`;
    if (product !== 'All') url += `&product=${product}`;
  } else {
    // default to customer list
    url = `/users/exportProductUsers/${product}`;
    let queryParams = [];
    if (startDate) queryParams.push(`startDate=${startDate}`);
    if (endDate) queryParams.push(`endDate=${endDate}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
  }
  const get = await Api.get(url, authToken)
    .then(resp => {
      return resp;
    })
    .catch(error => {
      console.error(error);
      return null;
    });

  return get;
};

const uploadCSV = (csv, type) => async (dispatch, getState) => {
  const data = new FormData();
  data.append('csv', csv);

  const {authToken} = getState().user;

  let endpoint;
  if (type === 'upgrade') {
    endpoint = '/orders/upgradeSubscriptions';
    data.append('OfferId', parseInt(process.env.UPGRADE_197_OFFER_ID));
    data.append('OfferPaymentOptionId', parseInt(process.env.UPGRADE_197_OFFER_PAYMENT_OPTION_ID));
  } else {
    endpoint = '/orders/postTrackingNumbers';
  }

  const post = await Api.post(endpoint, data, authToken)
    .then(resp => {
      return resp;
    })
    .catch(error => {
      console.error(error);
      return null;
    });

  return post;
};

const refundOrder = (id, refundBody) => async (dispatch, getState) => {
  dispatch(updateLoadingStatus(true));
  const isErrorMessage = !!getState().admin.errorMessage.length;
  if (isErrorMessage) {
    dispatch(updateErrorMessage(''));
  }

  const {authToken} = getState().user;
  const post = await Api.post(`/orders/refunds/order/${id}`, refundBody, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        if (resp.gatewayCode) {
          dispatch(updateLoadingStatus(false));
          dispatch(updateErrorMessage(resp.gatewayCode));
          return resp;
        }

        let orderId = '';
        let email = '';
        let firstName = '';
        let lastName = '';
        const {
          currentPage,
          pageSize,
          savedUserEmailSearchText,
          savedOrderIdSearchText,
          savedUserFirstNameSearchText,
          savedUserLastNameSearchText
        } = getState().admin;

        orderId = savedOrderIdSearchText;
        email = savedUserEmailSearchText;
        firstName = savedUserFirstNameSearchText;
        lastName = savedUserLastNameSearchText;

        const nextData = {
          email,
          firstName,
          lastName,
          orderId
        };
        return Api.post(`/search?page=${currentPage}`, nextData, authToken)
          .then(response => {
            if (response.status === undefined) {
              if (response.gatewayCode) {
                dispatch(updateLoadingStatus(false));
                dispatch(updateErrorMessage(response.gatewayCode));
                return;
              }

              dispatch(updateResults(response.rows));
              dispatch(updateTotalPages(response.count));
              if (!response.rows[0]) {
                dispatch(updateTotalResults(0));
              } else {
                dispatch(updateTotalResults(response.totalRows));
              }

              if (response.pageSize && response.pageSize !== pageSize) {
                dispatch(updatePageSize(response.pageSize));
              }
              dispatch(updateLoadingStatus(false));
            } else {
              if (response.gatewayCode) {
                dispatch(updateErrorMessage(response.gatewayCode));
              } else {
                dispatch(updateErrorMessage('Error refreshing orders after refund'));
              }

              dispatch(updateResults([]));
              dispatch(updateTotalPages(0));
              dispatch(updateTotalResults(0));
              dispatch(updateLoadingStatus(false));
            }
          })
          .catch(error => {
            console.log('Search error: ', error);
            dispatch(updateLoadingStatus(false));
            dispatch(updateErrorMessage('Error refreshing orders after refund'));
            dispatch(updateResults([]));
          });
      } else {
        console.log('refund status - else: ', resp.status);
        dispatch(updateLoadingStatus(false));
        dispatch(updateErrorMessage('Refund not successful: API error'));
        dispatch(updateLoadingStatus(false));
      }
    })
    .catch(error => {
      console.log('Refund error: ', error);
      dispatch(updateLoadingStatus(false));
      dispatch(updateErrorMessage('API error'));
    });
  return post;
};

const cancelSubscription = (id, cancelImmediately) => async (dispatch, getState) => {
  const {authToken} = getState().user;

  const url = `/orders/${id}/subscriptionCancel`;
  const cancelDate = await Api.post(url, {cancelImmediately: cancelImmediately}, authToken)
    .then(resp => {
      if (resp.cancelDate) {
        return [resp.cancelDate, resp.canceled];
      }
      return null;
    })
    .catch(error => {
      console.log(error);
      return null;
    });

  return cancelDate;
};

const restartSubscription = id => async (dispatch, getState) => {
  const {authToken} = getState().user;

  const url = `/orders/${id}/subscriptionRestart`;

  const restartedSub = await Api.post(url, null, authToken)
    .then(resp => {
      if (resp.Subscription) {
        return resp;
      }
      return false;
    })
    .catch(error => {
      console.log(error);
      return false;
    });

  return restartedSub;
};

const resendReceipt = id => (dispatch, getState) => {
  const {authToken} = getState().user;
  Api.post(`/orders/${id}/sendReceipt`, null, authToken)
    .then(resp => {
      if (resp.status === undefined) {
        if (resp.gatewayCode) {
          dispatch(updateErrorMessage(resp.gatewayCode));
          return;
        }

        const {results} = getState().admin;
        const newResults = [...results];
        for (let i = 0; i < newResults.length; i++) {
          if (newResults[i].id === id) {
            const updatedOrder = {...newResults[i]};
            updatedOrder.receiptSent = new Date().toDateString();
            newResults[i] = updatedOrder;
            dispatch(updateResults(newResults));
            break;
          }
        }
      } else {
        dispatch(updateLoadingStatus(false));
        dispatch(updateErrorMessage('Error resending receipt'));
      }
    })
    .catch(error => {
      console.log('Resend receipt error: ', error);
      dispatch(updateErrorMessage('API error resending receipt'));
    });
};

const resetUserPassword = id => async (dispatch, getState) => {
  const {authToken} = getState().user;
  const passwordIsReset = await Api.post(`/users/${id}/resetPassword`, null, authToken)
    .then(resp => {
      console.log('passwordIsReset response: ', resp);
      if (resp.ok || resp.status === 204) {
        if (resp.gatewayCode) {
          return null;
        }
        return true;
      }
      return null;
    })
    .catch(error => {
      console.log('Reset password error: ', error);
    });

  return passwordIsReset;
};

const initialState = {
  currentPage: 0,
  errorMessage: '',
  loading: false,
  orderIdSearchText: '',
  pageSize: 10,
  results: [],
  savedOrderIdSearchText: '',
  savedUserEmailSearchText: '',
  savedUserFirstNameSearchText: '',
  savedUserLastNameSearchText: '',
  totalPages: 0,
  totalResults: 0,
  userEmailSearchText: '',
  userFirstNameSearchText: '',
  userLastNameSearchText: ''
};

export const actions = {
  cancelSubscription,
  restartSubscription,
  refundOrder,
  resendReceipt,
  resetUserPassword,
  search,
  updateCurrentPage,
  updateErrorMessage,
  updateLoadingStatus,
  updateOrderIdSearchText,
  updateResults,
  updateTotalPages,
  updateTotalResults,
  updateUserEmailSearchText,
  updateUserFirstNameSearchText,
  updateUserLastNameSearchText,
  getCSV,
  uploadCSV
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDER_ID_SEARCH_TEXT:
      return {
        ...state,
        orderIdSearchText: action.text
      };

    case UPDATE_USER_EMAIL_SEARCH_TEXT:
      return {
        ...state,
        userEmailSearchText: action.text
      };

    case UPDATE_USER_FIRST_NAME_SEARCH_TEXT:
      return {
        ...state,
        userFirstNameSearchText: action.text
      };

    case UPDATE_USER_LAST_NAME_SEARCH_TEXT:
      return {
        ...state,
        userLastNameSearchText: action.text
      };

    case UPDATE_SAVED_ORDER_ID_SEARCH_TEXT:
      return {
        ...state,
        savedOrderIdSearchText: action.text
      };

    case UPDATE_SAVED_USER_EMAIL_SEARCH_TEXT:
      return {
        ...state,
        savedUserEmailSearchText: action.text
      };

    case UPDATE_SAVED_USER_FIRST_NAME_SEARCH_TEXT:
      return {
        ...state,
        savedUserFirstNameSearchText: action.text
      };

    case UPDATE_SAVED_USER_LAST_NAME_SEARCH_TEXT:
      return {
        ...state,
        savedUserLastNameSearchText: action.text
      };

    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.loading
      };

    case UPDATE_RESULTS:
      return {
        ...state,
        results: action.results
      };

    case UPDATE_TOTAL_RESULTS:
      return {
        ...state,
        totalResults: action.number
      };

    case UPDATE_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.totalPages
      };

    case UPDATE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize
      };

    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };

    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
}
