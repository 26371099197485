import React from 'react';
import {Button, Col, Row} from 'reactstrap';

const ReviewConfirmation = ({questions, answers, goToQuestion, submit}) => (
  <>
    <Row className="mb-3">
      <Col>
        <h5 className="m-0">Review Your Answers</h5>
        <p>
          <em>(Click a question or answer to modify your choice.)</em>
        </p>
        {questions.map((question, i) => (
          <div key={question.id} className="py-3 pointerGlobal" onClick={() => goToQuestion(question.id)}>
            <div>
              {i + 1}) {question.title}
            </div>
            <div style={{textDecoration: 'underline'}}>
              {question.choices.find(v => v.id === answers[question.id]).title}
            </div>
          </div>
        ))}
      </Col>
    </Row>
    <Row>
      <Col>
        <Button color="primary" onClick={submit}>
          Submit
        </Button>
      </Col>
    </Row>
  </>
);

export default ReviewConfirmation;
