import React from 'react';
import {Card, CardImgOverlay, CardTitle} from 'reactstrap';
import {faUtensils} from '@fortawesome/pro-regular-svg-icons/faUtensils';
import {faUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';
import {faMap} from '@fortawesome/pro-regular-svg-icons/faMap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CardImgBg from 'components/CardImgBg';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';
const iconMap = [
  {type: 'week', icon: faMap, title: 'Weekly Guide'},
  {
    type: 'recipes',
    icon: faUtensils,
    title: 'Recipes',
    thumb: {
      url: 'https://cdn.foodrevolution.org/members/assets/recipes/italian-three-bean-salad.jpg',
      alt: 'Italian Three Bean Salad'
    }
  },
  {type: 'videoResource', icon: faVideo, title: 'Action Hour'}, //TODO change to gettingStarted most likely
  {
    type: 'forum',
    icon: faUsers,
    title: 'Forum',
    thumb: {
      url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/forum-dashboard-tile.png',
      alt: 'A cartoon drawing of a crowd of people.'
    }
  },
  {
    type: 'calendar',
    icon: faCalendar,
    title: 'Calendar',
    thumb: {
      url: 'https://cdn.foodrevolution.org/members/assets/thumbnails/forum-dashboard-tile.png',
      alt: 'A cartoon drawing of a crowd of people.'
    }
  }
];

function DashboardCard(props) {
  const found = props.type ? iconMap.find(item => props.type == item.type) : null;
  const img = (props.data && props.data.thumb) || (found ? found.thumb : null);

  return (
    <Card className="h-100 card-dashboard-tile" onClick={() => props.handleNav(props.slug)} role="button">
      <CardImgBg src={img && img.url ? img.url : ''} />
      <CardImgOverlay className="d-flex align-items-center justify-content-center">
        <CardTitle tag="h4">
          {found ? <FontAwesomeIcon className="w-100" size="2x" icon={found.icon} /> : null}
          <br />
          {found ? found.title : null}
        </CardTitle>
      </CardImgOverlay>
    </Card>
  );
}

export default DashboardCard;
