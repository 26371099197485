import React from 'react';
import {Button, Card, CardBody, Col, Container, Row, Modal, ModalBody} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'lib/react-markdown';
import classNames from 'classnames';
import IconList, {IconListItem} from '../FRSCommon/IconList';
import Footer from '../FRSCommon/Footer';
import Section, {SectionImage} from '../FRSCommon/Section';
import {ImgBulletCard} from '../FRSCommon/ImgBullet';
import {collection} from './collections.json';
import {Link} from 'react-scroll';
import UpsellSales from '../FRSCommon/UpsellSales';

const CSS_URL = process.env.BUNDLE_STYLESHEET_URL;
const VIDEO_URL_FRS = 'https://youtu.be/NVE0FnIYtis';
const VIDEO_URL_TNTG = 'https://youtu.be/rEogZZyj3vE';
const VIDEO_URL_COLLECTION_ACC = 'https://www.youtube.com/watch?v=j6anH9b2k94';
const VIDEO_URL_COLLECTION_BHC = 'https://www.youtube.com/watch?v=1xpwM5vW-eA';
const VIDEO_URL_COLLECTION_HHCOL = 'https://youtu.be/jQxucLOpI3w';
const VIDEO_URL_COLLECTION_IHC = 'https://www.youtube.com/watch?v=l07Cl88AgqQ';

function ThcOptions(props) {
  const {purchasedProduct = null} = props;
  return collection
    .filter(e => e.id !== purchasedProduct)
    .map(collection => <CheckoutSelection {...collection} key={collection.id} {...props} />);
}

export function FreeSelection({updateSelection, IS_TNTG}) {
  return (
    <Row className="d-flex align-items-stretch">
      {collection.map(collection => (
        <FreeSelectionItem {...collection} key={collection.id} updateSelection={updateSelection} />
      ))}
    </Row>
  );
}

export function FreeSelectionItem({
  id,
  name,
  image,
  descriptionList,
  description,
  regularPrice,
  offerPrice,
  recipeHeadline,
  recipes,
  updateSelection,
  ...props
}) {
  return (
    <Col xs="12" lg="6" className={classNames(`thc-selection-option thc-selection-option-${id}`)}>
      <Row className="d-flex align-items-stretch text-center">
        <Col xs="12" className="d-flex flex-column flex-grow-1">
          <div
            className={classNames(
              `imgBulletCard background-white text-black box-shadow-black-50 p-4 rounded mb-4 flex-grow-1`
            )}
          >
            <Row className="d-flex align-items-center m-0">
              <Col xs="12">
                <p>
                  <strong>{name}</strong>
                </p>
                <img className="thc-selection-card-image mb-3" src={image} />
                <Button
                  color="upsell-check"
                  onClick={e => {
                    e.preventDefault();
                    return updateSelection({id, name, image});
                  }}
                >
                  Add to Order — Free
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function CheckCircle({checked, ...props}) {
  return <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} size="lg" {...props} />;
}

export function CheckoutSelection({
  id,
  name,
  image,
  descriptionList,
  description,
  regularPrice,
  offerPrice,
  recipeHeadline,
  recipes,
  ...props
}) {
  const CheckCircle = ({checked, ...props}) => (
    <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} size="lg" {...props} />
  );

  return (
    <Card className={classNames(`thc-selection-card thc-selection-card-${id} box-shadow-black-50 mb-5`)}>
      <CardBody className="p-0">
        <Row className="d-flex align-items-stretch m-0">
          <Col xs="12" className="p-4 p-lg-5">
            <ReactMarkdown className="thc-selection-card-name text-h3 text-800 text-center mb-4" source={name} />
            <div className="text-center mb-4">
              <img className="thc-selection-card-image" src={image} />
            </div>
            {props.comp ? <p className="text-center text-strikethrough">Regular Price: ${regularPrice}</p> : null}
            <p
              className={classNames(
                `thc-selection-card-description thc-selection-card-description-${id} text-center mb-4`
              )}
            >
              {description}
            </p>
            {descriptionList ? (
              <IconList>
                {descriptionList.map((description, i) => (
                  <IconListItem className="text-h6" key={i}>
                    {description.descriptionItemImage ? (
                      <div className="d-flex align-items-center">
                        <img
                          className={classNames(`thc-selection-card-item-image`, description.descriptionItemImageClass)}
                          src={description.descriptionItemImage}
                        />
                        <ReactMarkdown className="description" source={description.descriptionItem} />
                      </div>
                    ) : (
                      <ReactMarkdown className="description" source={description.descriptionItem} />
                    )}
                  </IconListItem>
                ))}
              </IconList>
            ) : null}
            <Row className="thc-selection-card-recipes d-flex align-items-stretch text-center m-0">
              {recipes ? (
                <React.Fragment>
                  {recipes.map(recipe => (
                    <Col sm="6" className="d-flex flex-column flex-grow-1 px-2">
                      <div
                        className={classNames(
                          `imgBulletCard background-white text-black box-shadow-black-50 rounded mb-4 flex-grow-1`
                        )}
                      >
                        <Row className="d-flex align-items-stretch m-0 flex-grow-1">
                          <Col
                            xs="12"
                            lg="5"
                            xl="4"
                            className="thc-selection-card-image background-cover background-image-center px-0"
                            style={{backgroundImage: `url('${recipe.recipeImage}')`}}
                          />
                          <Col
                            xs="12"
                            lg="7"
                            xl="8"
                            className="text-center text-lg-left p-3 p-lg-2 p-xl-3 d-flex flex-column"
                          >
                            <div className="my-auto">
                              <strong>{recipe.recipeName}</strong>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </React.Fragment>
              ) : null}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

function CheckoutFinalSelection({price = 0, id, name, onButtonClick, ...props}) {
  const disabled = id === null;
  return (
    <div className="thc-selection-card text-center">
      <Card
        className={classNames(
          `box-shadow-black-50 border-dashed border-3 mb-3`,
          disabled ? 'border-color-green-50' : 'border-color-green-100'
        )}
      >
        <CardBody className="p-5">
          <Row>
            <Col xs="12">
              <p>Your Selection:</p>
              <h4 className="section-heading">{name}</h4>
              <p>
                Clicking “CONTINUE” will add the {name} to your purchase now,{' '}
                {price > 0 ? <React.Fragment>for an additional ${price}.</React.Fragment> : 'for FREE.'}
              </p>
              {price > 0 ? (
                <p>
                  <strong>Your purchase comes with a risk-free, 60-day, money-back guarantee.</strong>
                </p>
              ) : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-4">
            <Col xs="12" md="10" lg="8" xl="6">
              <Button
                className="text-white text-800 my-auto"
                color="cta"
                block
                disabled={disabled}
                onClick={disabled ? () => {} : onButtonClick}
              >
                Continue
                <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
              </Button>
            </Col>
          </Row>

          {disabled ? (
            <Row>
              <Col>
                <p className="mt-3">
                  <strong>In order to continue, you must choose your free product above.</strong>
                </p>
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export default class THC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {stylesLoaded: false, processing: false, selection: {id: null, name: '', image: ''}, experiments: {}};
    this.updateSelection = this.updateSelection.bind(this);
    this.handleFreeSelection = this.handleFreeSelection.bind(this);
  }

  componentDidMount() {
    this.linkTag = document.createElement('link');
    const head = document.getElementsByTagName('head')[0];
    this.linkTag.rel = 'stylesheet';
    this.linkTag.type = 'text/css';
    this.linkTag.href = CSS_URL;
    this.linkTag.onload = () => this.setState({stylesLoaded: true});
    head.appendChild(this.linkTag);
    const css = 'html { font-size: unset; }';
    this.style = document.createElement('style');

    head.appendChild(this.style);

    this.style.type = 'text/css';
    if (this.style.styleSheet) {
      // This is required for IE8 and below.
      this.style.styleSheet.cssText = css;
    } else {
      this.style.appendChild(document.createTextNode(css));
    }
    document.getElementsByTagName('html')[0].classList.add('frn-html');

    if (typeof window !== 'undefined') {
      window.VWO = window.VWO || [];
      window.VWO.push([
        'onVariationApplied',
        data => {
          if (!data) {
            return;
          }
          var expId = data[1],
            variationId = data[2];
          this.setState(state => ({experiments: {...state.experiments, [expId]: variationId}}));
        }
      ]);
    }
  }

  componentWillUnmount() {
    this.linkTag && this.linkTag.remove();
    this.style && this.style.remove();
    document.getElementsByTagName('html')[0].classList.remove('frn-html');
  }

  updateSelection(selection) {
    this.setState({selection});
  }

  handleFreeSelection() {
    const selectedUpsell = this.props.allUpsells.find(us => RegExp(this.state.selection.id).test(us.Product.slug));
    return this.props.rawHandleClickUpsell(selectedUpsell.id);
  }

  render() {
    const {
      props: {offerSlug, purchasedProductSlug, videoUrl = '', speakerCollections = [], ...props} = {},
      state: {experiments = {}}
    } = this;
    const IS_TNTG = offerSlug.substr(0, 4) === 'tntg';
    const IS_FRS = offerSlug.substr(0, 3) === 'frs';

    const IS_ACC = offerSlug.substr(0, 3) === 'acc';
    const IS_BHC = offerSlug.substr(0, 3) === 'bhc';
    const IS_IHC = offerSlug.substr(0, 3) === 'ihc';
    const IS_HHCOL = offerSlug.substr(0, 12) === 'hhcollection';

    const IS_COLLECTION = IS_ACC || IS_BHC || IS_IHC || IS_HHCOL;

    const productName = IS_ACC
      ? 'Anti-Cancer Collection'
      : IS_BHC
      ? 'Brain Health Collection'
      : IS_IHC
      ? 'Immune Health Collection'
      : IS_HHCOL
      ? 'Heart Health Collection'
      : IS_TNTG
      ? 'Need to GROW Impact Kit'
      : 'Food Revolution Summit Empowerment Package';

    return !this.state.stylesLoaded ? (
      <p>Loading...</p>
    ) : (
      <div className="page page-nosocial">
        <div className="page-content">
          <Section id="thc-header" innerClass="text-white text-center pt-4" color="black">
            <Container>
              <Row>
                <Col className="text-white">
                  {IS_COLLECTION ? (
                    <React.Fragment>
                      <p>
                        <b>
                          Thank you for taking a stand for your health! <br className="d-none d-sm-block d-md-none" />
                          You’ve made a great choice.
                        </b>
                      </p>
                      <p>
                        <i>Before you go…</i>
                      </p>
                      <h4 className="mt-0">
                        <b>
                          Get all four Health Collections <br className="d-none d-sm-block d-lg-none" />
                          for just $47. (Save 83%)
                        </b>
                      </h4>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p>
                        <strong>
                          {IS_TNTG ? (
                            'Thank you for taking a stand to be part of the SOLUTION! You’ve made a great choice.'
                          ) : (
                            <React.Fragment>
                              Thanks for getting the 2022 Food Revolution Summit.{' '}
                              <br className="d-none d-sm-block d-lg-none" />
                              You’ve made a great choice!
                            </React.Fragment>
                          )}
                        </strong>
                      </p>
                      <p>
                        And, as promised, you can{' '}
                        <span className="background-green px-1 rounded">
                          select your totally free bonus <br className="d-none d-sm-block d-lg-none" />
                          at the bottom of this page
                        </span>{' '}
                        — any one <br className="d-none d-lg-block" />
                        of the four health collections that make up{' '}
                        {IS_TNTG ? 'Food Revolution Network’s' : 'our brand new'} Total Health Collection.
                      </p>
                      <p>
                        <strong>Or, you can get the entire Total Health Collection for just $47.</strong>
                      </p>
                    </React.Fragment>
                  )}
                  <p className="mt-4">Watch this video to learn more:</p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col lg="10" className="video-col px-0 px-sm-3">
                  <div className="video-wrapper">
                    <ReactPlayer
                      className="video-player"
                      width="100%"
                      height="100%"
                      controls={true}
                      url={
                        IS_TNTG
                          ? VIDEO_URL_TNTG
                          : IS_COLLECTION // not TNTG
                          ? IS_ACC
                            ? VIDEO_URL_COLLECTION_ACC
                            : IS_BHC
                            ? VIDEO_URL_COLLECTION_BHC
                            : IS_HHCOL
                            ? VIDEO_URL_COLLECTION_HHCOL
                            : VIDEO_URL_COLLECTION_IHC
                          : // no logic here for HHC at the moment
                            VIDEO_URL_FRS // not TNTG or a collection
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-white">
                  <p>
                    <strong>
                      <em>
                        Take a moment to read the following as this special opportunity{' '}
                        <br className="d-none d-md-block" />
                        WON’T be available again after you close this page.
                      </em>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="thc-upgrade-1" color="white">
            <Container>
              <Row className="text-center">
                <Col>
                  <img
                    className="mb-1"
                    src="https://cdn.foodrevolution.org/global/frn-logo.svg"
                    style={{maxHeight: '75px'}}
                  />
                  <h2 className="section-heading">Total Health Collection</h2>
                  <h4 className="mt-0">
                    None of us can afford to leave <br className="d-none d-sm-block d-lg-none" />
                    our health up to chance.
                  </h4>
                  <h4 className="m-0">
                    Get the facts you need on four of the most <br className="d-none d-md-block d-xl-none" />
                    important health topics in the world.
                  </h4>
                </Col>
              </Row>
            </Container>
          </Section>

          <SectionImage
            id="thc-upgrade-2"
            color="white"
            imgPosition="right"
            imgUrl="https://cdn.foodrevolution.org/checkout/upsells/thc/1288032384.jpg"
          >
            {IS_COLLECTION && experiments && experiments['531'] !== '2' ? (
              <React.Fragment>
                <h5 className="section-heading">
                  Upgrade to get all <i>four</i> Health Collections
                </h5>
                <p>
                  Already purchased:
                  <br />
                  <b>{productName}</b>
                </p>
                <h5>Now you can add:</h5>
                <IconList>
                  {IS_IHC ? null : <IconListItem>Immune Health Collection</IconListItem>}
                  {IS_ACC ? null : <IconListItem>Anti-Cancer Collection </IconListItem>}
                  {IS_HHCOL ? null : <IconListItem>Heart Health Collection</IconListItem>}
                  {IS_BHC ? null : <IconListItem>Brain Health Collection </IconListItem>}
                </IconList>
                <h4>Yours Today For Just $47</h4>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h5 className="section-heading">
                  Choose Your Free Health Collection or upgrade to get all 4 with the Total Health Collection
                </h5>

                <IconList>
                  <IconListItem>Immune Health Collection (value $97)</IconListItem>

                  <IconListItem>Anti-Cancer Collection (value $97)</IconListItem>

                  <IconListItem>Heart Health Collection (value $97)</IconListItem>

                  <IconListItem>Brain Health Collection (value $97)</IconListItem>
                </IconList>

                <h4>Get All 4 Today For Just $47</h4>
                <p>
                  Each of the unique health collections within the Total Health Collection contains a specially curated
                  bundle of some of the most popular and life-changing health content we’ve created in our first 10
                  years, including summit interviews, Q&A-based action hours with world-leading experts, informative
                  guidebooks, personal workbooks, and amazingly delicious and nutritious recipes that will add vitality
                  and yumminess to your life.
                </p>
                <p>
                  Each one is well worth the $97 regular price — and an incredible value when you get yours absolutely
                  free.
                </p>
                <p className="mb-0">
                  <strong>But, if you want maximum results, then…</strong>
                </p>
              </React.Fragment>
            )}
          </SectionImage>

          <Section id="thc-info" color="light-gray">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center">
                    Be Informed. <br className="d-none d-sm-block d-md-none" />
                    Make the Best Choices.
                  </h2>
                  <h3 className="text-center mt-0">
                    Take control of your future with the <br className="d-none d-md-block d-xl-none" />
                    Total Health Collection.
                  </h3>
                  <div className="thc-product-image text-center mb-4">
                    <img
                      className="thc-selection-card-image"
                      src={`https://cdn.foodrevolution.org/thc/${
                        IS_FRS ? 'thc-product-image.png' : 'thc-product-image-v20220203.png'
                      }`}
                    />
                  </div>
                  {experiments && experiments['534'] === '2' ? null : (
                    <p className="text-center">
                      {IS_COLLECTION ? (
                        <React.Fragment>
                          Regular Price for the three new ones: <del>$291</del>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Regular Price for all four: <del>$388</del>
                        </React.Fragment>
                      )}
                    </p>
                  )}
                  <UpsellSales
                    className="upsell-box-confirm text-center mb-5"
                    variant="mini"
                    addButtonText={
                      IS_COLLECTION ? (
                        <React.Fragment>Add all three for $47</React.Fragment>
                      ) : (
                        <React.Fragment>Add ALL for just $47</React.Fragment>
                      )
                    }
                    excludeNoButton={experiments && experiments['545'] === '2'}
                    noButton={
                      IS_COLLECTION ? null : (
                        <div className="upsell-box-no text-center mt-3">
                          <Link
                            className="btn btn-link text-underline"
                            activeClass="active"
                            spy={true}
                            offset={0}
                            to="thc-offer"
                            smooth={true}
                            href="#"
                          >
                            Or, select your free bonus below.
                          </Link>
                        </div>
                      )
                    }
                    text={
                      <React.Fragment>
                        Clicking “ADD TO CART will add the Total Health Collection <br className="d-none d-lg-block" />
                        to your purchase right now, for an additional one-time $47.
                      </React.Fragment>
                    }
                    {...props}
                  />
                  {experiments && experiments['545'] === '2' ? (
                    <div className="upsell-box-no text-center">
                      <div className="mt-3 mb-3">
                        <Button className="btn-upsell-gray" onClick={this.props.handleDeclineUpsell}>
                          No, thanks.
                        </Button>
                      </div>
                      <div className="upsell-box-no-text">
                        Clicking the “No” button above will take you to the products you just got.
                      </div>
                    </div>
                  ) : null}
                  <p>
                    With the Total Health Collection, you can have confidence in your future health. You’ll have the
                    insights to provide yourself and your loved ones with the nourishment that supports a healthy brain,
                    a healthy heart, a healthy immune system, and cancer prevention.
                  </p>
                  <p>
                    It includes 12 of our <i>most</i> popular health-focused Food Revolution Summit interviews, four
                    members-only WHOLE Life Action Hours, four guidebooks, and 24 of our most beloved recipes.
                  </p>
                  <h4 className="my-4">
                    Here’s what you’ll get with each collection
                    {IS_COLLECTION ? (
                      <React.Fragment>
                        {' '}
                        that you’ll add <br className="d-none d-lg-block d-xl-none" />
                        to your order
                      </React.Fragment>
                    ) : null}
                    :
                  </h4>
                  <ThcOptions purchasedProduct={purchasedProductSlug} />
                </Col>
              </Row>
            </Container>
          </Section>

          <Section id="thc-recap" color="white">
            <Container>
              <Row>
                <Col>
                  <h2 className="section-heading text-center mb-5">
                    {IS_COLLECTION
                      ? `Add to the ${productName} for a Total of:`
                      : 'A Recap of What You Get With the Total Health Collection'}
                  </h2>
                  <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-headshots.png" imgColLg="5" textColLg="7">
                    <h4 className="section-heading mb-2">All 16 Interviews (mp3 and video)</h4>
                    <p className="text-h5 mb-0">With Leading Health Experts</p>
                  </ImgBulletCard>
                  <ImgBulletCard
                    img="https://cdn.foodrevolution.org/thc/thc-transcripts.png"
                    imgColLg="5"
                    textColLg="7"
                  >
                    <h4 className="section-heading mb-2">All 16 Written Transcripts</h4>
                    <p className="text-h5 mb-0">With Links To Scientific Studies</p>
                  </ImgBulletCard>
                  <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-guidebooks.png" imgColLg="5" textColLg="7">
                    <h4 className="section-heading mb-2">All 4 Health Guidebooks</h4>
                    <p className="text-h5 mb-0">To Ensure You Don’t Have Knowledge Gaps</p>
                  </ImgBulletCard>
                  <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-workbooks.png" imgColLg="5" textColLg="7">
                    <h4 className="section-heading mb-2">All 4 Workbooks</h4>
                    <p className="text-h5 mb-0">To Retain What You Learn & Master the Material</p>
                  </ImgBulletCard>
                  <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-recipes.png" imgColLg="5" textColLg="7">
                    <h4 className="section-heading mb-2">All 28 Healthy, Plant-based Recipes</h4>
                    <p className="text-h5 mb-0">For Delicious Inspiration & Maximum Flavor</p>
                  </ImgBulletCard>
                </Col>
              </Row>
            </Container>
          </Section>

          <Section
            id="thc-cta"
            color="gradient-indigo-purple-right"
            className={IS_COLLECTION ? 'section-angle-last' : 'null'}
            angle={IS_COLLECTION ? 'none' : 'bottom'}
          >
            <Container>
              <Row>
                <Col>
                  <h3 className="section-heading text-center text-white">This Page Only!</h3>
                  <div className="cta-sales cta-sales-thc">
                    <div className="offer-box background-white border-dashed border-green border-2 offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5">
                      <Row className="d-flex justify-content-center mb-4">
                        <Col lg="10">
                          <h4 className="section-heading text-center">
                            Get the Total Health Collection, <br className="d-none d-sm-block d-lg-none" />
                            Today Only $47
                          </h4>
                          <div className="thc-product-image text-center mb-4">
                            <img
                              className="thc-selection-card-image"
                              src={`https://cdn.foodrevolution.org/thc/${
                                IS_FRS ? 'thc-product-image.png' : 'thc-product-image-v20220203.png'
                              }`}
                            />
                          </div>
                          <p>
                            To separately get all four of the components in the Total Health Collection, you’d pay $388
                            ($97 each). But, on this page only, as our thank you to you for your decision to get the{' '}
                            {productName}, we’re offering the Total Health Collection to you for{' '}
                            <strong>only $47.</strong>
                          </p>
                          <p>
                            <b>That’s an {IS_COLLECTION ? '83%' : '88%'} discount!</b>
                          </p>
                          <p className="mb-0">
                            <em>
                              But, you have to act fast because this page is the only time you’ll get this chance.
                            </em>{' '}
                            Click the button below to add the Total Health Collection to your order right now.
                          </p>
                        </Col>
                      </Row>
                      <UpsellSales
                        className="upsell-box-confirm text-center mb-3"
                        variant="mini"
                        text={
                          <React.Fragment>
                            Clicking “ADD TO ORDER” will add the Total Health Collection{' '}
                            <br className="d-none d-xl-block" />
                            to your purchase right now, for an additional one-time $47.
                          </React.Fragment>
                        }
                        excludeNoButton={experiments && experiments['545']}
                        noButton={
                          IS_COLLECTION ? null : (
                            <div className="upsell-box-no text-center mt-3">
                              <Link
                                className="btn btn-link text-underline"
                                activeClass="active"
                                spy={true}
                                offset={0}
                                to="thc-offer"
                                smooth={true}
                                href="#"
                              >
                                Or, select your free bonus below.
                              </Link>
                            </div>
                          )
                        }
                        {...props}
                      />
                    </div>
                    {experiments && experiments['545'] === '2' ? (
                      <div className="upsell-box-no text-center text-white mt-5">
                        <div className="mt-3 mb-3">
                          <Button className="btn-upsell-gray" onClick={this.props.handleDeclineUpsell}>
                            No, thanks.
                          </Button>
                        </div>
                        <div className="upsell-box-no-text">
                          Clicking the “No” button above will take you to the products you just got.
                        </div>
                      </div>
                    ) : null}

                    <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
                      <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                        <h6 className="section-only-heading text-center text-lg-left text-400">
                          For every new order, we make a donation to Trees for the Future, enabling them to plant
                          another organic fruit or nut tree in a low-income community.
                        </h6>
                      </Col>
                      <Col xs="11" lg="3" className="text-center">
                        <img
                          className="logo-trees"
                          src="https://cdn.foodrevolution.org/checkout/assets/images/logo-trees-for-future-white.png"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
          {IS_COLLECTION ? null : (
            <Section id="thc-offer" className="section-angle-last" color="light-gray" angle="none">
              <Container>
                <Row>
                  <Col>
                    <h5 className="section-heading">Don’t want the Total Health Collection?</h5>
                    <p>
                      <strong>Select your free gift below.</strong>
                    </p>
                    <p className="mb-4">
                      Remember, if you don’t{' '}
                      <Link
                        className="text-underline"
                        activeClass="active"
                        spy={true}
                        offset={0}
                        to="thc-cta"
                        smooth={true}
                        href="#"
                      >
                        get the Total Health Collection today
                      </Link>
                      , you’ll be passing up on the option to get it for the lowest price we’ll ever offer it for (just
                      $47). But you can still add any ONE of the Health Collections to your {productName} order, for
                      free.
                    </p>
                    <FreeSelection updateSelection={this.updateSelection} IS_TNTG={IS_TNTG} />
                  </Col>
                </Row>
              </Container>
            </Section>
          )}
        </div>

        <Footer />
        <Modal isOpen={this.state.selection.id !== null} size="lg">
          <Container className="px-sm-4 pb-5 text-center" fluid>
            <Row>
              <Col>
                <p className="text-h4 px-md-3 px-lg-0">
                  Yes, add <strong>{this.state.selection.name}</strong> to my <br className="d-none d-lg-block" />
                  {productName} order for free.
                </p>
                <div className="product-image mb-4">
                  <img className="thc-selection-card-image" src={this.state.selection.image} />
                </div>
                <Row className="d-flex justify-content-center">
                  <Col lg="10" xl="8">
                    <Button className="text-h4 mb-3" color="cta" block onClick={this.handleFreeSelection}>
                      Confirm
                    </Button>
                  </Col>
                </Row>
                <div>
                  <Button
                    className="text-small"
                    color="upsell-gray"
                    onClick={() => this.updateSelection({id: null, name: '', image: ''})}
                  >
                    No thanks, I want to go back and make a different selection
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}
