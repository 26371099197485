import React, {useState, useEffect, useCallback} from 'react';

const KeepAwakeComponent = () => {
  const [isCookMode, setIsCookMode] = useState(false);
  let wakeLock = null;

  const requestWakeLock = useCallback(async () => {
    try {
      if ('wakeLock' in navigator) {
        wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake Lock is active.');
      } else {
        console.log('Wake Lock API is not supported in this browser.');
      }
    } catch (err) {
      console.error(`Failed to acquire wake lock: ${err.message}`);
    }
  }, []);

  const releaseWakeLock = useCallback(async () => {
    if (wakeLock !== null) {
      await wakeLock.release();
      wakeLock = null;
      console.log('Wake Lock has been released.');
    }
  }, []);

  const toggleCookMode = useCallback(() => {
    setIsCookMode(prevState => {
      const newState = !prevState;
      if (newState) {
        requestWakeLock();
      } else {
        releaseWakeLock();
      }
      return newState;
    });
  }, [requestWakeLock, releaseWakeLock]);

  useEffect(() => {
    return () => {
      releaseWakeLock();
    };
  }, [releaseWakeLock]);

  return (
    <div className="toggle-container">
      <label className="switch" style={{paddingLeft: '0px', marginLeft: '0px'}}>
        <input
          type="checkbox"
          checked={isCookMode}
          onChange={toggleCookMode}
          style={{paddingLeft: '0px', marginLeft: '0px'}}
        />
        <span className="slider round"></span>
      </label>
      {isCookMode ? (
        <span>
          <strong>Turn Off Cook Mode</strong>
        </span>
      ) : (
        <span>
          <strong>Turn On Cook Mode</strong> - Prevent your screen from turning off
        </span>
      )}
    </div>
  );
};

export default KeepAwakeComponent;
