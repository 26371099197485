/*eslint-disable*/
import recipeOne from '../Recipe/testData';

export default {
  displayProps: {
    title: 'Recipes List One',
    subtitle: 'Recipes List One',
    image: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Recipes List One'
    }
  },
  type: 'recipesList',
  name: 'PPT17 Course Recipes',
  title: 'Recipes List One',
  slug: 'recipes-list-one',
  data: {
    thumb: {
      url: 'http://via.placeholder.com/350x150',
      alt: 'Recipes',
      text: 'Recipes List One.'
    },
    //this is a video in the recipes modal - make me a Child of course too?
    video: {
      slug: 'one-excellent-video',
      title: 'One excellent video',
      url: 'https://youtu.be/-zViWlsb9cg',
      alt: 'one excellent video',
      options: {
        courseRecipesVideoId: '1234',
        parText: `Our recipes are integrated with the potent shopping list tool, BigOven. This video shows you how to set up and use a BigOven account, which could help you increase efficiency and effectiveness when you shop. Of course, if you prefer not to use BigOven, you can also easily print a recipe or save it as a PDF on your computer, tablet or mobile.`,
        promptText: 'GROCERY LIST TEMPLATE',
        templateText: `When you run out of something, or are nearing running out, you can check the box for it, and then when you go shopping, you can take your list and get the things that are checked. This is an alternative to BigOven.`,
        ctaText: 'Click here to download the grocery list template',
        ctaUrl: 'http://foodrevolution.ontraport.net/d/Healthy-Eating-Grocery-List.doc',
        ctaIsDownload: true
      }
    }
  },

  Children: [recipeOne, recipeOne, recipeOne, recipeOne, recipeOne, recipeOne, recipeOne, recipeOne]
};
