/* eslint-disable react/jsx-no-duplicate-props */

import React from 'react';
import {
  Button,
  Container,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  Input,
  FormGroup,
  FormText
} from 'reactstrap';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete';
import merge from 'lodash.merge';
import countries from 'lib/countries';
import states from 'lib/us-states';

const FIELD_DEFAULTS = {
  firstName: {name: 'firstName', placeholder: 'First Name', required: true},
  lastName: {name: 'lastName', placeholder: 'Last Name', required: true},
  name: {name: 'name', placeholder: 'Name', required: true},
  address1: {name: 'address1', placeholder: 'Address', required: true},
  address2: {name: 'address2', placeholder: 'Apartment, suite, etc. (optional)'},
  city: {name: 'city', placeholder: 'City', required: true},
  state: {
    name: 'state',
    placeholder: 'State',
    options: states.map(e => ({text: e.name, value: e.abbreviation})),
    required: true
  },
  postalCode: {name: 'postalCode', placeholder: 'ZIP / Postal Code', required: true},
  country: {
    name: 'country',
    placeholder: 'Country',
    options: countries.map(e => ({text: e.name, value: e.alpha2})),
    required: true
  },
  phone: {name: 'phone', placeholder: 'Phone'},
  email: {
    name: 'email',
    placeholder: 'Email',
    regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    required: true
  }
};

const FIELD_LAYOUT = [
  {key: 'name-info', fields: ['firstName', 'lastName']},
  {key: 'address1', fields: ['address1']},
  {key: 'address2', fields: ['address2']},
  {key: 'city-state', fields: ['city', 'state']},
  {key: 'country-postal', fields: ['country', 'postalCode']},
  {key: 'email', fields: ['email']},
  {key: 'phone', fields: ['phone']}
];
//FORM used when there are no saved billing addresses or when the user wants to add one
export class GenericAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.fields = merge(FIELD_DEFAULTS, props.fields);
    const defaultValues = Object.keys(this.fields).reduce((a, e) => {
      a[e] = null;
      return a;
    }, {});
    this.state = {
      autoCompleteField: !props.disabledFields.includes('address1')
        ? 'address1'
        : !props.disabledFields.includes('city')
        ? 'city'
        : null,
      touched: {...defaultValues},
      valid: {...defaultValues}
      //(...!this.props.setValues && {values: this.prop.values})
    };
    this.handleAutoCompleteSelect = this.handleAutoCompleteSelect.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.validateField = this.validateField.bind(this);
    this.renderRowOfForm = this.renderRowOfForm.bind(this);
  }

  fieldsAreValid() {
    const {
      fields,
      validateField,
      props: {disabledFields, stopFormFields, values}
    } = this;

    const allFieldsValid = Object.keys(fields).every(key => {
      if (stopFormFields && !stopFormFields.map(item => item.name).includes(key)) {
        return true; //exempt all fields not included in stopFormFields if set
      }
      return disabledFields.includes(key) || validateField(key, values[key]);
    });

    if (this.props.checkValidity) {
      this.props.checkValidity(allFieldsValid);
    }

    return allFieldsValid;
  }

  fireUpdateValidity(_valid) {
    const result = this.fieldsAreValid();

    typeof this.props.updateValidity === 'function' && this.props.updateValidity(result);
    return result;
  }

  handleAutoCompleteSelect(address, placeId) {
    geocodeByPlaceId(placeId)
      .then(results => {
        const {address_components} = results[0];
        const newAddress = {
          address1: address_components
            .filter(e => e.types.includes('street_number'))
            .map(e => e.short_name)
            .concat(address_components.filter(e => e.types.includes('route')).map(e => e.short_name))
            .join(' '),
          city: address_components
            .filter(e => e.types.includes('locality'))
            .map(e => e.short_name)
            .join(' '),
          state: address_components
            .filter(e => e.types.includes('administrative_area_level_1'))
            .map(e => e.short_name)
            .join(' '),
          postalCode: address_components
            .filter(e => e.types.includes('postal_code'))
            .map(e => e.short_name)
            .join(' '),
          country: address_components
            .filter(e => e.types.includes('country'))
            .map(e => e.short_name)
            .join(' ')
        };
        const remainingValues = ['firstName', 'lastName', 'email', 'phone', 'name'].reduce((obj, key) => {
          obj[key] = this.props.values[key];
          return obj;
        }, {});
        this.props.setValues({...remainingValues, ...newAddress});
        this.setState((state, props) => ({
          touched: {...remainingValues, ...newAddress}
        }));
        this.loadTotalsIfValid();
      })
      .catch(error => console.error(error));
  }

  handleInputBlur(e) {
    const {name, value} = e.target;
    this.setState(
      (state, props) => ({
        valid: {
          ...state.valid,
          [name]: this.validateField(name, props.values[name])
        }
      }),
      () => {
        this.fireUpdateValidity();
        value !== this.state.touched[name] && this.loadTotalsIfValid();
      }
    );
    this.props.onFieldBlur && typeof this.props.onFieldBlur === 'function' && this.props.onFieldBlur(e);
  }

  handleInputChange(e) {
    const {name, value} = e.target;

    const validity = this.validateField(name, value);
    this.props.setValues({
      ...this.props.values,
      [name]: value
    });
    if (this.state.valid[name] !== validity) {
      this.setState(
        (state, props) => ({
          valid: {
            ...state.valid,
            [name]: this.validateField(name, props.values[name])
          }
        }),
        this.fireUpdateValidity
      );
    }
    this.props.onFieldChange && typeof this.props.onFieldChange === 'function' && this.props.onFieldChange(e);
  }

  handleInputFocus(e) {
    const {name, value} = e.target;
    if (this.state.touched[name] !== true) {
      this.setState((state, props) => ({
        touched: {
          ...state.touched,
          [name]: value
        }
      }));
    }
  }

  loadTotalsIfValid() {
    const valid = this.fieldsAreValid();
    if (valid && this.props.loadTotals && typeof this.props.loadTotals === 'function') this.props.loadTotals();
  }

  validateField(name, value) {
    const field = this.fields[name];

    return (!field.required || !!value) && (!field.regex || (value && !!value.match(field.regex)));
  }

  validateFields() {
    const {disabledFields, disabledContigentFields, stopFormFields} = this.props;
    const through = [];
    const valid = Object.keys(this.fields).reduce((a, key) => {
      //if key (i.e. firstName) is not disabled or in disabledContigentFields, then try to validate it

      //stopFormFields ?  if we have stopFormFields, we only need to check those because they preven the rest of the fields from being filled out

      if (
        stopFormFields &&
        !stopFormFields.map(item => item.name).includes(key)
        // (disabledFields && !disabledFields.includes(key))
      ) {
        a[key] = true;
        through.push(key);
      } else if (disabledFields && disabledFields.includes(key)) {
        a[key] = true;
      } else {
        a[key] = this.validateField(key, this.props.values[key]);
      }

      return a;
    }, {});

    this.setState({
      valid
    });

    return this.fireUpdateValidity(valid);
  }
  onFocus(e) {
    if (e.target.name !== this.state.autoCompleteField) return;
  }

  componentDidMount() {
    this.validateFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.values !== prevProps.values) {
      this.validateFields();
    }
  }
  renderRowOfForm(row) {
    const {
      contigentFieldNames,
      contigentRow,
      disabledFields,
      fieldLayout,
      fullWidth,
      stopFormFieldName,
      stopFormFieldValue,
      useLabels,
      values
    } = this.props;

    const {allFieldsValid, valid, autoCompleteField, touched} = this.state;

    return (
      <Row>
        {row.fields.map(name => {
          if (disabledFields.includes(name)) return null;
          const type =
            name !== autoCompleteField && this.fields[name].options && (name !== 'state' || values.country === 'US')
              ? 'select'
              : null;

          return (
            <Col key={`${row.name}-${name}`}>
              <FormGroup>
                {name === autoCompleteField ? (
                  <PlacesAutocomplete
                    value={values[name] || ''}
                    onChange={value => this.handleInputChange({target: {name, value}})}
                    onSelect={this.handleAutoCompleteSelect}
                  >
                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                      <div key="addres-wrapper">
                        {useLabels ? <Label>{this.fields[name].placeholder}</Label> : null}
                        <Input
                          key="address-input"
                          {...getInputProps({
                            className: 'location-search-input',
                            invalid: !this.state.valid[name] && !!this.state.touched[name],
                            name,
                            onBlur: this.handleInputBlur,
                            onFocus: this.handleInputFocus,
                            placeholder: this.fields[name].placeholder
                          })}
                        />
                        {this.fields[name].formText ? (
                          <FormText color="muted">{this.fields[name].formText}</FormText>
                        ) : null}
                        {loading || suggestions.length ? (
                          <div className="dropdown show" style={{position: 'relative'}}>
                            <div
                              key="dropdown-toggle"
                              tabIndex="-1"
                              role="menu"
                              aria-hidden="false"
                              className="dropdown-menu show"
                              x-placement="bottom-start"
                              data-placement="bottom-start"
                              style={{
                                position: 'absolute',
                                top: '0px',
                                left: '0px'
                              }}
                            >
                              {loading ? (
                                <div key="address-loading" className="dropdown-item">
                                  Loading...
                                </div>
                              ) : (
                                suggestions.map((suggestion, i) => {
                                  return (
                                    <button
                                      {...getSuggestionItemProps(suggestion)}
                                      type="button"
                                      tabIndex="0"
                                      className="dropdown-item"
                                    >
                                      {suggestion.description}
                                    </button>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <span>
                    {useLabels ? <Label>{this.fields[name].placeholder}</Label> : null}
                    <Input
                      placeholder={this.fields[name].placeholder}
                      name={name}
                      value={values[name] || ''}
                      onChange={this.handleInputChange}
                      invalid={!this.state.valid[name] && !!this.state.touched[name]}
                      onBlur={this.handleInputBlur}
                      onFocus={this.handleInputFocus}
                      type={type}
                    >
                      {type === 'select'
                        ? [
                            <option value="" disabled key="">
                              {this.fields[name].placeholder}
                            </option>
                          ].concat(
                            this.fields[name].options.map(o => (
                              <option value={o.value} key={o.value}>
                                {o.text}
                              </option>
                            ))
                          )
                        : null}
                    </Input>
                    {this.fields[name].formText ? (
                      <FormText color="muted">{this.fields[name].formText}</FormText>
                    ) : null}
                  </span>
                )}
              </FormGroup>
            </Col>
          );
        })}
      </Row>
    );
  }
  validateStopFields() {
    const {stopFormFields, values} = this.props;
    const meetsStopCriteria = field => {
      if (field.value) {
        //ie 'UR' !== 'US' so stop
        return field.value !== values[field.name]; //if we have an expected value (field.value) check that the actual value is equal (in this case 'US' === 'US')
      } //TODO maybe, add other checks like regex, set of acceptable values
      return false;
    };

    return stopFormFields.some(meetsStopCriteria);
  }
  render() {
    const {valid, autoCompleteField, touched} = this.state;

    const {
      contigentFieldNames,
      contigentRow,
      disableContigentFields, //fields to disable if stopValue is met, allows for the existing form validation to proceed
      disabledFields,
      fieldLayout,
      fullWidth,
      showAllFieldsOverride,
      stopFormFieldName,
      stopFormFieldValue,
      stopFormFields,
      useLabels,
      values
    } = this.props;

    //if there are contigentFields (fields that prevent the rest of the form from being shown until some condition is met)
    //then check that they are all have valid values (need this b/c american customers will need the rest of the form)
    //if there is stopFormFieldValue (field that stops the rest of the form from being shown)
    //then check if the stop value is met (eg 'country' === 'us', 'us' is the stopValue, values ultimately comes from the reduxe store  values['country'] will return 'US' or some other country
    //then add the rest of the fields outside to disable,   if order doesn't require shipping, then show full form
    //UPDATE:  we are currently just using the stop field functionality and not showing the rest of the form is the country is not 'US'
    //We are limited to one row being stopped.  Will have to update if we wante more than one
    if (
      (contigentFieldNames && contigentFieldNames.filter(item => valid[item]).length !== contigentFieldNames.length) ||
      (stopFormFields && this.validateStopFields())
    ) {
      //we are only able to show one  row as a contigent row or a stop field row
      const row = fieldLayout.find(item => item.key === contigentRow);
      return this.renderRowOfForm(row);
    }
    return (
      <Container fluid className={fullWidth ? 'px-0' : ''}>
        {/* where/how is fieldLayout defined? --> passed in as props in <GenericAddressForm  from  modal/OrderConfirm*/}

        {fieldLayout.reduce(
          (out, row) =>
            out.concat(row.fields.some(e => !disabledFields.includes(e)) ? this.renderRowOfForm(row) : null),
          []
        )}
      </Container>
    );
  }
}

GenericAddressForm.defaultProps = {
  disabledFields: ['name'],
  fieldLayout: FIELD_LAYOUT,
  fullWidth: false,
  useLabels: false,
  values: {}
};

export default GenericAddressForm;
