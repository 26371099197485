import React from 'react';
import Checkbox from '../../../../components/Checkbox';
import LessonsHeader from '../LessonsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';

//Shows the lesson titles and completed state (as non-fucntional checkbox) within the CardBody of the ModuleWrapper
/*i.e.
Lessons              3/3 lessons
1: Set Yourself Up for Success
2: Food: An Essential Survival Skill
3: Transitioning Wisdom

*/
function ChildModulesWrapper(props) {
  const { module, moduleState, handleNav, isUnstructured } = props;

  let today = Date.now()

  return (
    <div>
      {/*isUnstructured ? null : <LessonsHeader moduleState={moduleState} />*/}
      {module.Children.filter(item => item.type === 'module').map((item, i) => {
        const data = item && item.data ? item.data : null;
        const premieresAt = data && data.premieresAt ? data.premieresAt : 1; //if it doesn't exist assume it premiered in 1970
        const moduleLocked = today && premieresAt && parseInt(premieresAt) > parseInt(today);
        return (
          <div className={`d-flex align-items-center py-3${!moduleLocked ? " pointerGlobal" : ""}`} onClick={!moduleLocked ? () => handleNav(item.slug) : () => { }}>
            <div className="pr-3 float-left">{item.data.type ? item.data.type : 'Module'}{item.data.num ? ` ${item.data.num}` : ''}: {item.title}</div>
            {isUnstructured ? null : (
              <div className="ml-auto text-right">
                {!moduleLocked ?
                  <Checkbox
                    checked={
                      moduleState
                        ? moduleState.modules.find(moduleState => {
                          return moduleState.id === item.id && moduleState.completed;
                        })
                        : false
                    }
                  />
                  : <FontAwesomeIcon icon={faLock} />}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ChildModulesWrapper;
