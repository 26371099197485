import React, {useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
// import { Col, Container, Row } from 'reactstrap';
import initIconLibrary from 'icons';
import toast, {Toaster} from 'react-hot-toast';

import AuthedUserRoute from 'containers/AuthedUserRoute';

import Header from 'components/Header';
import MainContainer from 'components/MainContainer';
import SideBar from 'components/SideBar';
import UpdateNotificationToast from 'components/UpdateNotificationToast';
import {Button} from 'reactstrap';

import Landing from 'routes/Landing';
import ModalRouter from 'routes/Modal';
import Checkout from 'routes/Checkout';
import Orders from 'routes/Orders';

import Profile from 'routes/User/Profile';
import MagicLinkLogin from 'components/MagicLinkLogin';
import Products from 'routes/Products';
import Dashboard from 'routes/User/Dashboard';
import styles from 'styles/index.scss';
import AppWrapperV2 from 'components/AppWrapperV2';
import PageScrollUp from '../../components/PageScrollUp';
import {page} from '../../lib/track';

const {addGreyBackground} = styles;

initIconLibrary();

//Rudderstack page view event,  listens for changes in URL
const pageView = () => {
  const location = useLocation();
  React.useEffect(() => {
    page();
  }, [location]);
};
const App = () => {
  pageView();

  return (
    <div>
      <PageScrollUp />
      <Switch>
        {/* Public routes */}
        {/* <Route path="/test" component={WLCWrapper} /> */}
        <Route path="/about/" component={() => <div>About</div>} />
        <Route path="/blog/" component={() => <div>Blog</div>} />
        <Route exact path="/checkout/404" component={() => <div>404 Offer Not Found</div>} />
        <Route path="/checkout/" component={Checkout} />
        <Route path="/users/login/magic" component={MagicLinkLogin} />
        {/* Authorized users only - redirects to / if not logged in */}
        <AuthedUserRoute path="/orders/" component={AppWrapperV2(Orders)} />
        <AuthedUserRoute path="/products/" component={Products} />
        <AuthedUserRoute path="/profile/:userId" component={AppWrapperV2(Profile)} /> // userId -> you/public
        <AuthedUserRoute path="/profile/" component={AppWrapperV2(Profile)} /> //if no userId user == me/
        <AuthedUserRoute path="/dashboard/" component={AppWrapperV2(Dashboard)} />
        {/* Default */}
        <Route path="/users/password/:instruction" component={Landing} />
        <Route path="/" component={Landing} />
      </Switch>
      <ModalRouter />
      <Toaster />
    </div>
  );
};

export default App;
