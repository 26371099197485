//a functional component that renders the view all page
import React from 'react';
import {useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';

import Grid from '../../../../components/Grid';
import ListWithImage from '../../../../components/ListWithImage';
import BackNav from '../../../../components/BackNav';
import epStyles from 'routes/Products/products/EmpowermentPackageV2/styles.js';

const ViewAllPage = ({data, description, title, list}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="mb-5">
      <div style={epStyles.backNavWrapperStyle}>
        <BackNav />
      </div>
      {title ? (
        <Row>
          <Col>
            <h4 className="moduleTitle">{title ? title : 'view all'}</h4>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {list && list.length ? (
        <React.Fragment>
          <div className="d-none d-md-block">
            <Grid list={list} includeSearch />
          </div>
          <div className="d-xs-block d-md-none">
            <ListWithImage list={list} includeSearch />
          </div>
        </React.Fragment>
      ) : null}
      {/* spacer */}
      <div style={{width: '100%', height: '100px'}}></div>
    </Container>
  );
};

export default ViewAllPage;
