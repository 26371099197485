import React from 'react';
import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CheckCircle from '../../../../components/CheckCircle';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import ToggleIcons from 'components/ToggleIcons';

function ModuleHeaderTracker(props) {
  const {module, moduleNumber, moduleState, isUnstructured, overlayActive} = props;
  const icons = [faCheckCircle, faCircle];
  const img =
    module.data && module.data.thumb && module.data.thumb.url
      ? module.data.thumb.url
      : 'https://cdn.foodrevolution.org/members/assets/thumbnails/frn-logo-2019-master-1200x495-transparentbg.png';
  const alt =
    module.data && module.data.thumb && module.data.thumb.alt
      ? module.data.thumb.alt
      : 'Module Header Image - Click to Proceed';

  return (
    <Row className="align-items-center">
      <Col xs="auto" className="lessonImage">
        <img alt={alt} src={img} />
      </Col>
      <Col>
        {overlayActive ? null : (
          <h6>
            {!isUnstructured && typeof moduleNumber !== 'undefined'
              ? Number.isInteger(parseInt(moduleNumber))
                ? `${module.data.type ? module.data.type : 'Module'} ${moduleNumber}: `
                : `${moduleNumber}: `
              : ''}
            {module && module.title}
          </h6>
        )}
      </Col>
      <Col xs="auto">
        {isUnstructured ? null : (
          <ToggleIcons checked={moduleState ? moduleState.completed : false} icons={icons} color={['green', 'black']} />
        )}
        &nbsp;
        {overlayActive ? null : <FontAwesomeIcon icon={faChevronRight} size="lg" />}
      </Col>
    </Row>
  );
}

export default ModuleHeaderTracker;
