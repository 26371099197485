import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';

import Section, {SectionImage} from '../FRSCommon/Section';
import IconList, {IconListItem} from '../FRSCommon/IconList';
import TestimonialBubbleItem from '../FRSCommon/TestimonialBubbleItem';
import {SpeakerListInfo} from '../FRSCommon/SpeakerList';
import UpsellSales from '../FRSCommon/UpsellSales';
import {BonusAccordion} from '../FRSCommon/BonusList';
import Footer from '../FRSCommon/Footer';

import {frsSpeakers, frsBonus, testimonials} from './data.json';
const CSS_URL = process.env.UL_STYLESHEET_URL;

function UpsellSection({id, sectionHeading = null, angle = 'bottom', className = '', ...props}) {
  return (
    <Section id={id} color="purple" angle={angle} className={classNames(`offer-box`, className)}>
      <Container>
        <Row>
          <Col>
            {sectionHeading ? <div className="text-white text-center mb-5">{sectionHeading}</div> : null}
            <UpsellSales
              className="offer-uhc"
              heading="One-Time Offer — Save 75%!"
              subHeading={<h5 className="mt-0">11th annual Food Revolution Summit Empowerment Package</h5>}
              text={
                <React.Fragment>
                  Clicking “Yes” above will add the Empowerment Package to your purchase now for an additional $47.
                </React.Fragment>
              }
              imgSrc="https://cdn.foodrevolution.org/frs22/frs22-productimage-v20220301-nologo.png"
              imgWidth="1500"
              imgHeight="672"
              imgAlt="2022 Food Revolution Summit Empowerment Package product image"
              showPriceBig
              normalPrice="147"
              price="47"
              {...props}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

function TestimonialBubble({id, ...props}) {
  const testimonial = testimonials.find(e => e.id === id);
  return testimonial ? <TestimonialBubbleItem {...testimonial} {...props} /> : null;
}

export default function({product = 'ul', ...props}) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const linkTag = document.createElement('link');
    const head = document.getElementsByTagName('head')[0];
    console.log(head, document);
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.href = CSS_URL;
    linkTag.onload = () => setIsLoading(false);
    head.appendChild(linkTag);
    const css = 'html { font-size: unset; }';
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    document.getElementsByTagName('html')[0].classList.add('frn-html');
    return () => {
      linkTag && linkTag.remove();
      style && style.remove();
      document.getElementsByTagName('html')[0].classList.remove('frn-html');
    };
  }, []);
  return isLoading ? (
    'Loading...'
  ) : (
    <div className="page page-nosocial">
      <div className="page-content">
        <Section id="us-frs-header" className="section-angle-first" innerClass="text-white text-center" color="black">
          <Container>
            <Row className="mb-4">
              <Col>
                <h3 className="section-heading mb-3">
                  Before you dive into Unlocking Longevity,
                  <br /> check out this video.
                </h3>
                <h5 className="m-0">(You won’t see this special offer again!)</h5>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <div className="bg-danger text-uppercase py-2">
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                  &nbsp;YOU WILL ONLY SEE THIS PAGE ONCE - PRESS PLAY BELOW
                </div>
                <div className="video-wrapper">
                  <ReactPlayer
                    className="video-player"
                    width="100%"
                    height="100%"
                    url="https://youtu.be/9yKIXxs7LJY"
                  />
                </div>
              </Col>
            </Row>

            {
              {
                ul: (
                  <Row className="text-white text-center mt-5">
                    <Col>
                      <h3 className="section-heading">
                        Imagine Knowing Exactly What to Eat <br />
                        to Help Protect Your Health… for Life
                      </h3>
                      <h5 className="mt-0 text-400">
                        The Food Revolution Summit Empowerment Package Is the #1 Way to Catapult Your Energy, Prevent
                        Disease, Fight Climate Change, and
                        <br /> Build Healthy Habits That Last a Lifetime.
                      </h5>
                      <p className="mb-0 text-white">11 Years | 2 Million People Served</p>
                    </Col>
                  </Row>
                )
              }[product]
            }
          </Container>
        </Section>

        <UpsellSection
          id="us-frs-cta-1"
          sectionHeading={
            <>
              <h4 className="section-heading">
                Let John Robbins bring you the latest breakthroughs, as he interviews 24 of the top food experts on the
                planet. All yours instantly (for a price you’ll never see again).
              </h4>
            </>
          }
          noTrees
          {...props}
        />

        <Section id="us-frs-testimonials-1" color="light-gray">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble id="frs-sheila-m" bgColor="white" />
                <hr className="border-color-black-25 my-5" />
                <TestimonialBubble id="frs-pamela" bgColor="white" />
                <hr className="border-color-black-25 my-5" />
                <TestimonialBubble id="frs-rajiv-b" bgColor="white" />
                <hr className="border-color-black-25 my-5" />
                <TestimonialBubble id="frs-diane-b" bgColor="white" />
                <hr className="border-color-black-25 my-5" />
                <TestimonialBubble id="frs-aleece-d" bgColor="white" />
                <p className="balance-text text-center text-small mt-5">
                  <i>
                    Many Summit participants have adopted the concepts, regimens, and dietary patterns that our
                    panelists advocate. Each of them is unique. And so are you! Your experiences will vary, of course.
                    And no, we never offer compensation for these statements!
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="us-frs-industrialized"
          color="white"
          imgPosition="left"
          imgUrl="https://cdn.foodrevolution.org/checkout/upsells/frs22/1202939384.jpg"
        >
          <h2 className="section-heading">The modern industrialized diet can kill.</h2>
          <p>
            Why? It weakens your immune system and creates the foundations for diseases like heart disease, cancer, type
            2 diabetes, and more.
          </p>
          <p>This isn’t an exaggeration. It’s a scientifically proven fact.</p>
          <p>
            The good news is that you CAN prevent and <i>even reverse</i> these and other conditions.
          </p>
          <p>
            <b>And it all starts with the food on your plate.</b>
          </p>
        </SectionImage>

        <SectionImage
          id="us-frs-difference"
          color="light-gray"
          imgPosition="right"
          imgUrl="https://cdn.foodrevolution.org/checkout/upsells/frs22/628097352.jpg"
        >
          <h4 className="section-heading">With your food choices, you get to take the power back.</h4>
          <p>Every bite can be a vote for your health and the world you want.</p>
          <p>
            Every bite can be a vote for preventing disease and for staying vibrant, productive, and on top of your
            game.
          </p>
          <p>
            If you’re ready for grounded hope with{' '}
            <b>
              <i>real solutions</i> that are powerful enough to shift the course of humanity,
            </b>{' '}
            you’re in the right place.
          </p>
          <p>
            <b>You CAN make a difference — for your future and the future of our planet.</b>
          </p>
        </SectionImage>

        <SectionImage
          id="us-frs-enough"
          color="white"
          imgPosition="left"
          imgUrl="https://cdn.foodrevolution.org/checkout/upsells/frs22/1329782829.jpg"
        >
          <h4 className="section-heading mb-2">Join the millions of people who are rising up with us and saying:</h4>
          <h4 className="mt-0">ENOUGH IS ENOUGH!</h4>
          <h2 className="mt-0">Make the Best Choices. Enjoy the Best Health of Your Life.</h2>
          <p>
            In these times, a great deal is out of our control, which makes it all the more critical to make smart
            choices each day. One thing you DO get to decide, which can make a WORLD of difference, is what you eat.
          </p>
          <p>
            <b>So, are you eating the RIGHT vegetables, fruits, and whole foods?</b>
          </p>
          <p>Every bite you take and every purchase you make is a vote for a safer and healthier future.</p>
          <p>
            <b>
              The same food choices that help you <i>prevent and even reverse chronic illness</i> can also give you more
              energy, deeper sleep and a clearer mind, and allow you to enjoy life more.
            </b>
          </p>
        </SectionImage>

        <Section id="" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h3 className="my-4">With the Empowerment Package, you’ll...</h3>
                <IconList>
                  <IconListItem>
                    Get <b>scientifically grounded insights</b> to protect your health and your family’s health, from
                    the world’s top food experts.
                  </IconListItem>
                  <IconListItem>
                    <b>Discover how to enjoy vibrant health and boost immunity,</b> so your body can ward off disease,
                    enjoy cardiovascular health, boost your brain function, and more.
                  </IconListItem>
                  <IconListItem>
                    <b>Gain TRULY life-saving wisdom and practical tools</b> to help you turn cutting-edge knowledge
                    into <b>life-changing ACTION.</b>
                  </IconListItem>
                </IconList>
                <p>
                  You’ll also become a source of wisdom and support to the people you love, so they stay safe and
                  healthy, too.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="us-frs-speakers" color="white">
          <Container>
            <Row className="text-center mb-4">
              <Col>
                <h2 className="section-heading text-400 mb-3">
                  <b>Protect Your Health & Take a Stand for the Planet</b> With These World-Renowned Experts
                </h2>
                <p className="mb-0">
                  All personally interviewed by John Robbins, the best-selling author of <i>Diet for A New America</i>
                </p>
              </Col>
            </Row>
            <Row>
              <SpeakerListInfo speakerList={frsSpeakers} modalLink />
            </Row>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  Want to be a part of the solution on planet Earth? Want to turn your health around for the better?
                  Want to enjoy eating more vegetables and plant-based foods?
                </h3>
                <h3 className="m-0">
                  Find out how to do it right, so you can thrive, with the Summit Empowerment Package.
                </h3>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="us-frs-access" color="light-gray">
          <Container>
            <Row>
              <Col>
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/1304728656.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Unlimited Access to Recordings</h4>
                    <p className="mb-0">
                      What’s unique about these 25 on-demand interviews is having one place to get the expertise of{' '}
                      <i>New York Times</i> best-selling authors, award-winning doctors, researchers, holistic surgeons,
                      and healers who know what’s working right now. Access all the sessions whenever and wherever you
                      want.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/checkout/upsells/frs22/transcripts.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Written Transcripts</h4>
                    <p className="mb-0">
                      Get access to every interview in written PDF form (including with Spanish translation option).
                      Read through the material quickly and find exactly what you’re looking for without having to
                      relisten to the whole session (unless you want to). You won’t find the transcripts anywhere else.
                      You can only get this time-saving resource as part of the Empowerment Package.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/1292777576.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Links to Scientific Studies Referenced in the Summit</h4>
                    <p className="mb-0">
                      We want you to be able to rely on more than just our word, so we’ve included links to many of the
                      double-blind, peer-reviewed studies you’ll hear about in the interviews. This is perfect if you
                      like to check sources, do your own research, or dive deeper into critical information.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/everday-lentil-lunch.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">27 Delicious, Whole Foods, Plant-Based Recipes</h4>
                    <p className="mb-0">
                      Forget about eating rabbit food or the same 4 dishes over and over — prepare to ENJOY exciting new
                      recipes! You’ll get the entire collection of 27 delicious, whole foods, plant-based recipes.
                      Whether you’re new to plant-based eating or you’re already fluent in veggies, you’ll find
                      scrumptious recipes to delight your entire body.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/1061636586.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">
                      $2000+ in Bonus Gifts: Program Memberships, Recipe Collections,{' '}
                      <span className="text-nowrap">e-Books,</span> Seminars, & Courses
                    </h4>
                    <p className="mb-0">
                      Love learning and want years’ worth of extras so you can make progress even faster? You’ll get all
                      27 bonuses, generously provided by our summit experts. Pick where you want to dive deeper and keep
                      the momentum of the summit going all year.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/ocean-robbins-square.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Ocean Robbins’ Post-Interview Top Takeaways</h4>
                    <p className="mb-0">
                      Get the “greatest hits” from each of the summit sessions, and some follow-up questions answered,
                      with recordings of live commentary from Ocean Robbins — best-selling author, lifelong food justice
                      activist, and host of the Food Revolution Summit. This is like getting a behind-the-scenes
                      download from the director!
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="11" sm="10" md="8" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/checkout/upsells/frs22/guest-experts.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">
                      “Ask the Experts” Q&A Call With John & Ocean, Joined by Joel{' '}
                      <span className="text-nowrap">Kahn, MD,</span> and Julieanna{' '}
                      <span className="text-nowrap">Hever, RD</span>
                    </h4>
                    <p className="mb-0">
                      We took the biggest questions from our community and gave them to our health experts. Wondering if
                      allergies be reversed? What are the best foods to eat to help address chronic inflammation? Is
                      goat milk a good way to get calcium? This practical and informative event is designed to answer
                      YOUR questions and to help you put the food revolution into action in your life.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle"
                      src="https://cdn.foodrevolution.org/checkout/upsells/frs22/1144444285.jpg"
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Ability to Share With Friends & Family</h4>
                    <p className="mb-0">
                      Know someone who’d benefit from a specific session or resource? We all want our loved ones to be
                      as healthy as possible, which is why we want you to share the summit with the people in your life.
                      This is how the food revolution grows — and we want as many people on board as possible!
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="us-frs-testimonials-2" color="white">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble id="frs-joan-s" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="us-frs-bonuses" color="light-gray">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">And There’s More: You’ll Take Home These Bonuses!</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <BonusAccordion bonuslist={frsBonus} borderColor="black-25" />
              </Col>
            </Row>
          </Container>
        </Section>

        <UpsellSection
          id="us-frs-cta-2"
          sectionHeading={
            <>
              <h3 className="section-heading">One-Time Offer</h3>
              <h4 className="mt-0">
                Act now and you’ll own the entire, insight-packed <br /> 11th annual Food Revolution Summit for 75% off.
              </h4>
            </>
          }
          noTrees
          {...props}
        />

        <Section id="us-frs-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <div className={classNames(`guarantee-box rounded background-white box-shadow-black-50`)}>
                  <h2 className="section-heading guarantee-heading text-center text-sm-left">
                    You’re Protected by Our 60-Day, Unconditional, Money-Back Guarantee
                  </h2>
                  <p>
                    Try it out. If you aren't completely blown away, or even if you just don’t like it, email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a>, or phone us at (831)
                    824-4779 any time within 60 days of purchase for a prompt and courteous refund.
                  </p>
                  <p>
                    This means you can even go through the entire package, listen to every interview, access all the
                    bonuses, and make every single mouth-watering recipe. If it doesn’t rock your world, you get every
                    penny back. That’s how confident we are that you’ll LOVE the Empowerment Package.
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, everything that you’ve downloaded is still yours to
                    keep. That’s why we call our guarantee “better than money back.”
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <UpsellSection
          id="us-frs-cta-3"
          className="section-angle-last"
          angle="none"
          sectionHeading={
            <>
              <h3 className="section-heading">
                Take a stand for the health you want, and the future our children deserve. Get Your Empowerment Package
                Today.
              </h3>
              <p>All totally digital! No wasted plastic, paper, or landfill space.</p>
              <h5 className="mt-0">Thank you for your support!</h5>
            </>
          }
          {...props}
        />
      </div>

      <Footer />
    </div>
  );
}
