import React from 'react';
import ReactMarkdown, {uriTransformer} from 'react-markdown';

function platformUriTransformer(uri, nextTransformer) {
  if (typeof uri !== 'undefined' && uri.startsWith('contentItem:') && typeof window !== 'undefined') {
    uri =
      window.location.pathname
        .split('/')
        .slice(0, 3)
        .join('/') +
      '/' +
      uri.substr(uri.indexOf(':') + 1);
  }
  return nextTransformer(uri);
}

export default function PlatformReactMarkdown({transformLinkUri = uriTransformer, ...props}) {
  return <ReactMarkdown transformLinkUri={uri => platformUriTransformer(uri, transformLinkUri)} {...props} />;
}
