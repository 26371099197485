//TNTG is basically a clone of the PPT
//So it will be using all of PPT except the index because there are some userState considerations
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import {flattenDeep, merge} from 'lodash';
import Dashboard from '../PPTv2/pages/Dashboard';
import ModulePage from '../PPTv2/pages/ModulePage';
import BonusesPage from '../PPTv2/pages/BonusesPage';
import testData from './data/hhc/hhcData'; // './data/testData';
import ProductSidebar from 'components/ProductSidebar';

import {faStar} from '@fortawesome/pro-regular-svg-icons/faStar';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome';
import {faVideo} from '@fortawesome/pro-regular-svg-icons/faVideo';

/*  content item in staging
{
    "free": false,
    "id": 7147,
    "name": "The Need To Grow",
    "title": "The Need To Grow",
    "slug": "tntg-content",
    "type": "tntg",
    "data": {
        "description": "The Need To Grow package"
    },
    "published": true,
    "updatedAt": "2019-09-24T19:42:40.456Z",
    "createdAt": "2019-09-24T19:42:40.456Z"
}
*/

@connect(
  state => ({
    user: state.user
  }),
  {
    getUserData: actions.getUserData,
    post: actions.postUserData
  }
)
class TNTG extends Component {
  constructor(props) {
    super(props);
    this.product = this.props;
    this.state = {
      moduleStates: []
    };

    this.handleNav = this.handleNav.bind(this);
    this.updateModuleStates = this.updateModuleStates.bind(this);

    this.modules = this.product ? this.product.Children.filter(item => item.type === 'module') : null;
    this.modules.map(
      module =>
        (this.modules = this.modules.concat(
          module.Children.filter(item => item.type === 'module').map(item => {
            item.parentModule = {slug: module.slug, title: module.title};
            return item;
          })
        ))
    );
    this.bonuses = this.product ? this.product.Children.find(item => item.type === 'bonuses') : null;

    const thisPath = location.pathname.split('/');
    this.productInPath = thisPath[2];

    this.moduleHeaderTitle = 'The Need To Grow Package';
    if (this.productInPath === 'tntg' || this.productInPath === 'tntg-impactkit') {
      this.navItems = [
        {name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
        {name: 'Film', typeOfComponent: null, slug: 'film', icon: faVideo},
        {name: 'Video Classes', typeOfComponent: null, slug: 'video-classes', icon: faVideo},
        {name: 'Extended Interviews', typeOfComponent: null, slug: 'extended-interviews', icon: faVideo},
        {name: 'Deleted Scenes', typeOfComponent: null, slug: 'deleted-scenes', icon: faVideo},
        {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
      ]; //used to show what nav items are present in top};
    } else if (this.productInPath === 'hf-eft' || this.productInPath === 'hf-eft-ik') {
      this.navItems = [
        {name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
        {name: 'Film', typeOfComponent: null, slug: 'hf-eft', icon: faVideo},
        {name: 'Video Lessons', typeOfComponent: null, slug: 'hf-eft-video-lessons', icon: faVideo},
        {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
      ]; //used to show what nav items are present in top};
    } else if (this.productInPath === 'ff' || this.productInPath === 'ff-impactkit') {
      this.navItems = [
        {name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
        {name: 'Film', typeOfComponent: null, slug: 'ff-ik-film', icon: faVideo},
        {name: 'Video Classes', typeOfComponent: null, slug: 'ff-ik-video-classes', icon: faVideo},
        {name: 'Outtakes', typeOfComponent: null, slug: 'ff-ik-outtakes', icon: faVideo},
        {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
      ]; //used to show what nav items are present in top};
    } else if (this.productInPath === 'hf-ftf-immersionkit') {
      this.navItems = [
        {name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome},
        {name: 'Film', typeOfComponent: null, slug: 'hf-ftf-ik-film', icon: faVideo},
        {
          name: '10 Day Immersion',
          typeOfComponent: null,
          slug: 'hf-ftf-ik-foodtofreedom-10-day-immersion',
          icon: faVideo
        },
        {name: 'Lecture Series', typeOfComponent: null, slug: 'hf-ftf-ik-lecture-series', icon: faVideo},
        {name: 'Culinary Instruction', typeOfComponent: null, slug: 'hf-ftf-ik-culinary-instruction', icon: faVideo},
        {name: 'Expert Interviews', typeOfComponent: null, slug: 'hf-ftf-ik-expert-interviews', icon: faVideo},
        {name: 'Bonuses', typeOfComponent: null, slug: 'bonuses', icon: faStar}
      ]; //used to show what nav items are present in top};
    } else {
      this.navItems = [{name: 'Home', typeOfComponent: 'Dashboard', slug: '', icon: faHome}]; //used to show what nav items are present in top};
    }
  }

  getUserData() {
    const userData = this.props.getUserData(this.product.id);
    userData
      .then(userState => {
        if (!userState) {
          //create the userState for first time from the contentItem
          //should already be done now ..
          this.createUserStateFromContentItem().then(moduleStates => {
            this.setState({moduleStates}, this.postUserData);
          });
        } else {
          //otherwise setState from userData
          const {moduleStates = []} = userState;
          //deep merge default state with userState
          //so
          const mergedState = (this.state.moduleStates || []).map(state => {
            const userModule = moduleStates.find(us => state.id === us.id) || {lessons: []};
            state = merge({}, state, userModule);
            if (state.lessons && userModule.lessons) {
              state.lessons.map(lesson => {
                const userLesson = userModule.lessons.find(ul => lesson.id === ul.id) || {};
                lesson = merge({}, lesson, userLesson);
                return lesson;
              });
            }
            return state;
          });
          console.log('MERGED STATE', mergedState);
          this.setState((prevState, props) => {
            const newState = {moduleStates: mergedState};
            return newState;
          });
        }
      })
      .catch(err => {
        console.log('error in getting userData ', err);
      });
  }

  postUserData() {
    const {moduleStates} = this.state;
    const postState = {
      ...this.state.userData,
      ...{moduleStates}
    };

    const result = this.props.post(this.product.id, postState);
    result
      .then(res => {})
      .catch(err => {
        console.log('err ************ ', err);
      });
  }

  createUserStateFromContentItem() {
    //mapping through the modules, lessons, and checklist to create an intial state for the user
    //this also works to update the potential user state saved in DB.
    //i.e. if we add a module to this product we can add merge that with the user state saved (if this case, here we create the object to be merged to the user state)
    const promise = new Promise((resolve, reject) => {
      const moduleStates = this.modules.map(module => {
        const lessonState = module.Children.filter(item => {
          return item.type === 'lesson';
        }).map(lesson => {
          return {id: lesson.id, completed: false};
        });

        return {
          id: module.id,
          slug: module.slug,
          completed: false,
          lessons: lessonState
        };
      });

      resolve(moduleStates);
    });
    return promise;
  }

  updateModuleStates(moduleID, lessonID = null, newChecklistState = null, completed = null) {
    const {moduleStates, product} = this.state;

    //find the module to update
    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if (!moduleState.lessons) {
      const module = this.modules.find(module => module.id === moduleID) || {};
      moduleState.lessons = module.Children.filter(item => item.type === 'lesson').map(lesson => ({
        id: lesson.id,
        completed: false
      }));
    }
    //find lesson id
    if (lessonID) {
      const newLessonsState = moduleState.lessons.map(item => {
        if (item.id === lessonID) {
          let lessonState = item;
          lessonState.completed = completed ? completed : !lessonState.completed;
          return lessonState;
        }
        return item;
      });
      moduleState.lessons = newLessonsState;
    }
    if (newChecklistState) {
      moduleState.checklist = newChecklistState;
    }

    //find the index of this module
    const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
    //replate the new moduleState in the moduleStates array
    moduleStates.splice(indexToReplace, 1, moduleState);

    this.setState(
      (prevState, props) => {
        return {product: product, moduleStates: moduleStates};
      },
      () => {
        this.checkIfModuleIsComplete(moduleID);
        this.postUserData();
      }
    );
  }

  areModuleLessonsComplete(moduleID) {
    const {moduleStates = {}} = this.state;
    const module = moduleStates.find(item => item.id === moduleID) || {};
    //.find returns undefined when nothing is found
    return typeof (module.lessons || []).find(item => !item.completed) === 'undefined';
  }

  checkIfModuleIsComplete(moduleID) {
    const {moduleStates} = this.state;

    let moduleState = moduleStates.find(item => item.id === moduleID) || {};
    if ((moduleState.lessons || []).filter(item => !item.completed).length == 0) {
      //find the module to update
      moduleState.completed = true;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);

      //replace the lessons with the newLessonsStae
      //months.splice(4, 1, 'May');
      // replaces 1 element at index 4
    } else {
      moduleState.completed = false;
      const indexToReplace = moduleStates.findIndex(obj => obj.id == moduleState.id);
      moduleStates.splice(indexToReplace, 1, moduleState);
    }

    this.setState(
      (prevState, props) => {
        return {moduleStates: moduleStates};
      },
      () => {
        this.postUserData();
      }
    );
  }

  componentDidMount() {
    //create the user state (the thing that tracks user progress thorugh the modules/lessons) from the content item
    this.createUserStateFromContentItem()
      .then(moduleStates => {
        this.setState({moduleStates});
        this.getUserData();
      })
      .catch(err => console.log('error in createUserState ', err));
  }

  handleNav(name, typeOfComponent, slug) {
    this.props.history.push(`/products/${this.productInPath}/${slug}`);
    window.scrollTo(0, 0);
  }

  render() {
    //set up all the routes
    const {moduleStates} = this.state;
    const product = this.product;

    return (
      <div>
        {/* <Dashboard product={product} moduleStates={moduleStates} /> */}
        {/* TODO create seperate route for each */}
        <ProductSidebar navItems={this.navItems} handleNav={this.handleNav}>
          <Switch>
            {this.product ? (
              <Route
                key={this.product.id}
                exact
                path={`/products/${this.productInPath}/`}
                render={() => (
                  <Dashboard
                    isUnstructured={true}
                    moduleHeaderTitle={this.moduleHeaderTitle}
                    product={product}
                    moduleStates={moduleStates}
                    moduleHeaderTitle={this.product.title}
                    updateModuleStates={this.updateModuleStates}
                    videoDirectUrl={product.data.url}
                  />
                )}
              />
            ) : null}
            {this.modules
              ? this.modules.map(module => {
                  console.log('module ', module.title);
                  return (
                    <Route
                      key={module.id}
                      exact
                      path={`/products/${this.productInPath}/${module.slug}`}
                      render={() => (
                        <ModulePage
                          product={product}
                          moduleState={moduleStates.find(item => item.id === module.id)}
                          moduleStates={moduleStates}
                          updateModuleStates={this.updateModuleStates}
                          isUnstructured={module.data.isUnstructured ?? true}
                          noHeader={true}
                          parentModule={module.parentModule ?? {}}
                        />
                      )}
                    />
                  );
                })
              : null}

            {this.bonuses ? (
              <Route
                key={this.bonuses.id}
                exact
                path={`/products/${this.productInPath}/bonuses`}
                render={() => <BonusesPage isUnstructured={true} product={product} bonuses={this.bonuses} />}
              />
            ) : null}
          </Switch>
        </ProductSidebar>
      </div>
    );
  }
}

export default withRouter(TNTG);
