import {flattenDeep} from 'lodash';
import queryString from 'qs';

//maybe put in process env
export const SEARCH_RESULTS_PER_PAGE = 21;
//mapping category types on the frontend to indices in Global search.  important for filters
export const indexMap = {
  defaultTag: 'contentTags',
  ingredient: 'ingredients'
};
//helpder method to get all the currently checked tags, returned by category
export const getCheckedExcludedTagsByCategories = ({type = 'defaultTag', filterCategories}) => {
  if (!filterCategories) return []; //def
  const checkedTags = filterCategories.map(category => {
    const activeTagsForThisCategory = category.tags.filter(tag => {
      return tag.excluded;
    });

    return {
      categoryName: category.categoryName,
      categoryType: category.categoryType,
      activeTags: activeTagsForThisCategory
    };
  });
  const final = checkedTags.filter(item => {
    return item.categoryType === type && item.activeTags.length;
  });
  return flattenDeep(final); //return all the checked tags, array of objects {name: 'xxx', active: true}
};

import {filterCategories} from './data';
//when a filter is checked, this updates the state
//this handles all types of checkboxes:  contentTags inclusion , contentTags exclusion, type filter, andOnly, ingredient inclusion, ingredient exclusion
export const getUpdatedCategories = ({
  tagName, //can be an array of tags or a string
  removeTag = false,
  type,
  shouldExcludeThisTag = false,
  andOnly = false,
  filterCategories //current state of categoies
}) => {
  let tagArray = [];
  //this handles some legacy calls, adding a feature to check multiple tags at once, this is used for the URL search history conversion
  if (!Array.isArray(tagName)) {
    tagArray = [tagName];
  } else {
    tagArray = tagName;
  }
  return filterCategories.map(category => {
    const newTagState = category.tags.map(tag => {
      if (tagArray.includes(tag.name)) {
        //EXCLUDE BUTTON HIT
        if (shouldExcludeThisTag) {
          tag.active = false;
          tag.excluded = !tag.excluded;
        } else {
          //INCLUDE BUTTON HIT
          tag.active = !tag.active;
          tag.excluded = false; //case where you are including
        }
        if (removeTag) {
          //when you want to uncheck this tag
          tag.active = false;
          tag.excluded = false;
        }
      }

      return tag;
    });
    category.tags = newTagState;
    return category;
  });
};

export const clearCategories = () => {
  //update all tags to inactive
  return filterCategories.map(category => {
    const newTagState = category.tags.map(tag => {
      tag.active = false;
      tag.excluded = false;
      return tag;
    });
    category.tags = newTagState;
    return category;
  });
};

export const getDefaultOpenSearchQueryBody = ({typeString}) => {
  return {
    from: 0,
    size: SEARCH_RESULTS_PER_PAGE,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              type: typeString
            }
          }
        ],
        must_not: []
      }
    },
    sort: [{createAt: 'desc'}]
  };
};

export const queryToOpenSearchQueryBody = query => {
  return queryString.parse(query);
};

export const createMatchBodyArrays = ({filterCategories}) => {
  const _doesNotMatchBodyArray = [],
    _matchBodyArray = [];
  filterCategories.forEach(element => {
    if (element && element.tags.length)
      element.tags.forEach(item => {
        //add to doesNotMatch array

        if (item.excluded) {
          if (element.categoryType === 'ingredient') {
            _doesNotMatchBodyArray.push(
              createQueryBody({term: `"${item.name}"`, index: indexMap[element.categoryType]})
            );
          } else {
            _doesNotMatchBodyArray.push(createQueryBody({term: item.name, index: indexMap[element.categoryType]}));
          }
        }
        ///add to mustMatch array
        if (item.active && !item.excluded) {
          if (element.categoryType === 'ingredient') {
            //make exact match
            _matchBodyArray.push(createQueryBody({term: `"${item.name}"`, index: indexMap[element.categoryType]}));
          } else {
            _matchBodyArray.push(createQueryBody({term: item.name, index: indexMap[element.categoryType]}));
          }
        }
      });
  });

  return {_matchBodyArray, _doesNotMatchBodyArray};
};

export const createQueryBody = ({term, index}) => {
  return {
    match: {
      [index]: term
      // auto_generate_synonyms_phrase_query: true
    }
  };
};

export const sortMapper = {alphabetical: 'alphabetical', newest: 'date', relevant: 'relevant'};
