import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ActionHours from 'routes/Products/components/ActionHours';

class ActionHoursPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    //Render a redirect to the welcome page for the course IF purchased
    return <ActionHours {...this.props} />;
  }
}

ActionHoursPage.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default ActionHoursPage;
