import React from 'react';
import {connect} from 'react-redux';
import {actions} from 'modules/checkout';
import SavedAddresses from '../SavedAddresses';
import GenericAddressForm from 'components/Forms/Address';
import FancyCheckbox from 'routes/Checkout/components/FancyCheckbox';
import {Col, Container, Row, Button} from 'reactstrap';
const {
  loadTotals,
  setNewAddress,
  setSelectedBillingAddress,
  setSaveAddress,
  setDefaultBilling,
  setDefaultBillingAndShipping
} = actions;

const countriesForFieldsets = ['US', 'CA']; //us and canada

export class BillingInfo extends React.Component {
  render() {
    const {
      values: {country}
    } = this.props;
    console.log('billing info props', this.props);
    //default field layout will be foreign country
    let fieldLayout = [
      {key: 'country-postal', fields: ['country', 'postalCode']},
      {key: 'email', fields: ['email']},
      {key: 'name-info', fields: ['firstName', 'lastName']},
      {key: 'address1', fields: ['address1']},
      {key: 'address2', fields: ['address2']},
      {key: 'city-state', fields: ['city', 'state']},

      {key: 'phone', fields: ['phone']}
    ];
    if (country && countriesForFieldsets.includes(country)) {
      //then need a special field set for this country

      switch (country) {
        case 'US':
          fieldLayout = [
            {key: 'country-postal', fields: ['country']},
            {key: 'email', fields: ['email']},
            {key: 'name-info', fields: ['firstName', 'lastName']},
            {key: 'address1', fields: ['address1']},
            {key: 'address2', fields: ['address2']},
            {key: 'city-state', fields: ['city', 'state', 'postalCode']},
            {key: 'phone', fields: ['phone']}
          ];
          break;
      }
    }
    return (
      <div>
        {this.props.orderRequestUser.id && this.props.addresses.length ? (
          <SavedAddresses
            addresses={this.props.addresses}
            selectedAddressId={this.props.selectedBillingAddressId}
            selectAddress={this.props.setSelectedBillingAddress}
            setDefaultBilling={this.props.setDefaultBilling} // the function
            saveDefaultBilling={this.props.saveDefaultBilling} // the property
          />
        ) : null}
        {!this.props.selectedBillingAddressId ? (
          <div className="px-3">
            <GenericAddressForm
              {...this.props}
              from="billing"
              disabledFields={['firstName', 'lastName', 'name', 'email', 'phone']}
              fullWidth
              contigentRow="country-postal"
              contigentFieldNames={['country']} //fields that will stop other parts of the form from being shown until they are filled out
              stopFormFields={[
                {name: 'country', value: 'US'},
                {name: 'postalCode', value: null}
              ]} //fields that prevent the rest of form from being shown until they receive the correct value
              disabledContigentFields={[
                'firstName',
                'lastName',
                'email',
                'city',
                'state',
                'phone',
                'address1',
                'address2'
              ]}
              fieldLayout={fieldLayout}
              // showAllFieldsOverride={!this.props.orderRequiresShipping}
            />
            <Row onClick={this.props.setSaveAddress} className="mb-3">
              <Col xs="auto ps-2">
                <FancyCheckbox checked={this.props.saveAddress} variant="square" />
              </Col>
              <Col>Save address for future purchases</Col>
            </Row>
            {this.props.saveAddress ? (
              <Row
                onClick={
                  this.props.orderRequiresShipping && this.props.useSameShippingAddress
                    ? this.props.setDefaultBillingAndShipping
                    : this.props.setDefaultBilling
                }
                className="mb-3"
              >
                <Col xs="auto ps-2">
                  <FancyCheckbox checked={this.props.saveDefaultBilling} variant="square" />
                </Col>
                <Col>Make this my default billing address</Col>
              </Row>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  values: state.checkout.orderRequest.BillingAddress,
  saveAddress: state.checkout.orderRequest.save,
  saveDefaultBilling: state.checkout.orderRequest.setDefaultBilling,
  saveDefaultShipping: state.checkout.orderRequest.setDefaultShipping,
  useSameShippingAddress: state.checkout.useSameShippingAddress,
  addresses: state.user.addresses,
  orderRequestUser: state.checkout.orderRequest.User,
  selectedBillingAddressId: state.checkout.orderRequest.BillingAddress
    ? state.checkout.orderRequest.BillingAddress.id
    : null
});

const mapDispatchToProps = dispatch => ({
  loadTotals: () => dispatch(loadTotals()),
  setValues: values => {
    dispatch(setNewAddress(values, 'BillingAddress'));
  },
  setSelectedBillingAddress: id => dispatch(setSelectedBillingAddress(id)),
  setSaveAddress: () => dispatch(setSaveAddress('billing')),
  setDefaultBilling: () => dispatch(setDefaultBilling()),
  setDefaultBillingAndShipping: () => dispatch(setDefaultBillingAndShipping())
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
