import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row} from 'reactstrap';
import Markdown from 'routes/Products/components/Markdown';
import styles from 'styles/index.scss';

const {bonusesList} = styles;
class Bonus extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.clickButton = this.clickButton.bind(this);
    console.log('<bonus/> props ', props);
  }

  clickButton() {
    this.buttonRef.current.click();
  }

  render() {
    const {props} = this;

    const childrenItems = props.Children.filter(c => ['download', 'link'].includes(c.type));
    const contentItem = childrenItems.length ? childrenItems[0] : null;
    if (!contentItem) return null;
    const {data: {url = null} = {}} = contentItem;

    const subTitles = [];
    props.data && props.data.from && subTitles.push(`From ${props.data.from}`);
    props.data && props.data.value && subTitles.push(`Value: $${Number(props.data.value).toFixed(2)}`);

    return (
      <Col xs="12" className="mb-5">
        <Card>
          <div className="card-horizontal">
            <div
              className="card-img-bg-left background-contain"
              style={{backgroundImage: `url("${contentItem.data.thumb.url}")`}}
              onClick={url ? this.clickButton : null}
            />
            <CardBody>
              <CardTitle>{contentItem.title}</CardTitle>
              {subTitles.length ? <CardSubtitle>{subTitles.join(' — ')}</CardSubtitle> : null}
              {contentItem.data.description ? (
                <Markdown source={contentItem.data.description} className="mt-3" />
              ) : null}
              {url ? (
                <Button innerRef={this.buttonRef} href={url} target="_blank" tag="a">
                  {contentItem.type === 'download' ? 'Download' : 'Learn More'}
                </Button>
              ) : null}
            </CardBody>
          </div>
        </Card>
      </Col>
    );
  }
}

@connect(state => ({
  user: state.user
}))
class Bonuses extends PureComponent {
  render() {
    const {props} = this;

    const bonuses = props.Children.filter(c => c.type === 'bonus');

    return (
      <Container>
        <Row>
          <Col>
            <h4 className="moduleTitle">{props.title}</h4>
            {props.data.description ? <Markdown source={props.data.description} /> : null}
          </Col>
        </Row>
        <Row className={bonusesList}>
          {bonuses && bonuses.length
            ? bonuses.map(child => <Bonus key={child.id} {...child} user={props.user} />)
            : 'no bonuses'}
        </Row>
      </Container>
    );
  }
}

Bonuses.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
};

export default Bonuses;
