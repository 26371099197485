import React, {useEffect, useState} from 'react';
import ProgressTracker from './components/ProgressTracker';
import './styles.css';
import {
  celebrations,
  connectedVideos,
  contentTracksStep,
  socialProof,
  sampleStaffPicks,
  videoResourceNoRecipe,
  walkthroughVideos,
  welcomeVideos
} from './data.js';
import Step from './components/Step';
import {createStep} from './components/Step';
import OnboardingList from './components/OnboardingList';
import StaffPicks from './components/StaffPicks';
import {actions} from 'modules/user';
import {useDispatch} from 'react-redux';
import CommunityPost from './components/CommunityPost';
import SocialProof from './components/SocialProof';
import CommunityLive from './components/CommunityLive';
import Celebration from './components/Celebration';
import {Row, Col} from 'reactstrap';
import DashboardCard from '../../pages/Dashboard/DashboardCard';
import track from '../../../../../../lib/track.js';
import Feedback from './components/Feedback';
import Api from '../../../../../../lib/api';
const LIMIT_TO_ONE_CONTENT_TRACK = true;
//feedback questions
const questions = [
  'How prepared do you feel to engage in WHOLE Life Club?',
  'Which part of the orientation did you find most valuable and why?',
  'What, if anything, do you believe could be added to or improved about the orientation?'
];

const DashboardNavigation = ({handleNav, currentWeek, currentActionHour}) => {
  return (
    <Row className="pt-4 pb-3">
      <Col xs="6" lg="3" className="py-3">
        {currentWeek ? (
          <DashboardCard {...currentWeek} handleNav={handleNav} />
        ) : (
          <DashboardCard type="week" handleNav={handleNav} />
        )}
      </Col>
      <Col xs="6" lg="3" className="py-3">
        <DashboardCard type="forum" slug="community" handleNav={handleNav} />
      </Col>
      <Col xs="6" lg="3" className="py-3">
        {currentActionHour ? (
          <DashboardCard {...currentActionHour} handleNav={handleNav} />
        ) : (
          <DashboardCard type="videoResource" handleNav={handleNav} />
        )}
      </Col>
      <Col xs="6" lg="3" className="py-3">
        <DashboardCard type="recipes" slug="recipes" handleNav={handleNav} />
      </Col>
      <Col xs="6" lg="3" className="py-3">
        <DashboardCard type="calendar" slug="calendar" handleNav={handleNav} />
      </Col>
    </Row>
  );
};
function findClosestIndex(progressSteps, X) {
  // Start by assuming the first element is the closest
  let closestIndex = 0;

  // Iterate over the array
  for (let i = 0; i < progressSteps.length; i++) {
    // Check if the current element is less than or equal to X
    if (progressSteps[i] <= X) {
      // If the current element is closer to X than the previous closest, update the closest index
      if (X - progressSteps[i] < X - progressSteps[closestIndex]) {
        closestIndex = i;
      }
    } else {
      // If we've reached an element greater than X, we can stop the search
      break;
    }
  }

  return closestIndex;
}

function Onboarding(props) {
  const ONBOARDING_ID = process.env.ONBOARDING_ID;
  const FINAL_STEP = 11;
  const FEEDBACK_STEP = 10;
  const progressSteps = [1, 3, 5, 7, 8]; //these are the steps that are tracked by the progress tracker, we don't include the celebration steps
  const [completed, setCompleted] = useState(false);
  const [contentTracks, setContentTracks] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [progressStep, setProgressStep] = useState(1);
  const [hasExploded, setHasExploded] = useState(false); //for animations
  const [loading, setLoading] = useState(true);
  const [feedbackAnswers, setFeedbackAnswers] = useState({}); //for feedback questions
  const dispatch = useDispatch();
  const userAnalytics = {
    service: 'dashboard', //the kind of service, like recipe, weekly guide, etcs
    slug: props.slug ? props.slug : '',
    title: props.pageTitle ? props.pageTitle : 'wlc-onboarding',
    uuid: props.user && props.user.UUID
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handlePost = () => {
    const newState = {contentTracks: contentTracks, currentGeneralOnboardingStep: currentStep};
    dispatch(actions.postUserData(ONBOARDING_ID, newState));
  };

  const handleNext = () => {
    const nextStep = currentStep + 1;
    scrollToTop();
    setCurrentStep(prev => prev + 1);
    if (nextStep === 2) {
      track('onboarding', {...userAnalytics, ...{step: 'begin'}});
    }
  };

  const handlePrev = () => {
    const prevStep = currentStep - 1;
    scrollToTop();
    setCurrentStep(prev => prev - 1);
    // Add logic to handle progress step if necessary
    if (progressSteps.includes(prevStep)) {
      setProgressStep(prev => prev - 1);
    }
  };
  useEffect(() => {
    if (completed && currentStep < FINAL_STEP) {
      setCompleted(false);
    }
    if (currentStep === 1) return;
    handlePost();
    if (currentStep === FEEDBACK_STEP + 1) {
      handleFeedbackSubmit();
    }
    if (currentStep > FINAL_STEP) {
      setCompleted(true);
      track('onboarding', {...userAnalytics, ...{step: 'begin'}});
    }
  }, [currentStep]);

  useEffect(() => {}, []);
  //keeping track of what the content tracks are select ['brain', 'diabete', etc]
  const updateContentTracks = (value, removeThis) => {
    if (LIMIT_TO_ONE_CONTENT_TRACK) {
      setContentTracks([value]);
      return;
    }
    let copy = [...contentTracks];
    if (removeThis) {
      //find the index of the value to remove
      const indexToRemove = copy.findIndex(item => item === value);
      //remove the item from the array
      if (indexToRemove >= 0 && indexToRemove < copy.length) {
        copy.splice(indexToRemove, 1);
      } else {
        console.log('Invalid index');
      }
    } else {
      copy.push(value);
    }
    setContentTracks(copy);
  };

  useEffect(() => {
    //load in user state
    const userData = dispatch(actions.getUserData(ONBOARDING_ID));

    userData.then(userState => {
      if (!userState) {
        setLoading(false);
        setContentTracks([]);
        setCurrentStep(1);
        return;
      }
      const {contentTracks, currentGeneralOnboardingStep} = userState;
      if (!contentTracks) {
        setContentTracks([]);
      } else {
        setContentTracks(contentTracks);
      }
      if (!currentGeneralOnboardingStep) {
        setCurrentStep(1);
        setProgressStep(1);
      } else {
        setCurrentStep(currentGeneralOnboardingStep);
        setProgressStep(findClosestIndex(progressSteps, currentGeneralOnboardingStep));
      }

      if (currentGeneralOnboardingStep >= FINAL_STEP) {
        setCompleted(true);
      } else {
        //let parent know we are onboarding still
      }
      setLoading(false);
    });
  }, []);

  const handleIntroPost = resp => {
    if (resp && resp.created_at) {
      handleNext();
    }
  };
  // Function to determine the class for each step
  const getStepClass = stepNumber => {
    let returnedCSSClass = '';
    if (currentStep === stepNumber) {
      returnedCSSClass = 'active';
    }
    if (parseInt(currentStep - 1) == parseInt(stepNumber)) {
      returnedCSSClass = 'out-forward';
    }
    if (currentStep + 1 == stepNumber) {
      returnedCSSClass = 'out-backward';
    }

    return returnedCSSClass;
  };

  const handleFeedback = ({name, value}) => {
    console.log('name', name, 'value', value);
    setFeedbackAnswers({...feedbackAnswers, [name]: value}, console.log('feedbackAnswers', feedbackAnswers));
  };
  const handleFeedbackSubmit = async () => {
    console.log('feedbackAnswers', feedbackAnswers);
    const opts = {removeHeaders: true};
    try {
      const URL = process.env.FEEDBACK_ZAP;
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify(feedbackAnswers)
      });

      if (response.ok) {
        console.log('Success:', response);
      } else {
        console.error('Error:', response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const goToStep = step => {
    setCurrentStep(step);
  };

  const commonStepProps = {
    handleNext,
    handlePrev
  };

  const steps = props.events
    ? [
        createStep({
          title: "Step 1: Watch Ocean's welcome video to WHOLE Life Club!",
          videos: welcomeVideos,
          currentStep: currentStep,
          ...commonStepProps
        }),
        createStep({
          title: 'Step 2: Take a tour of WHOLE Life Club!',
          videos: walkthroughVideos,
          currentStep: currentStep
        }),
        createStep({
          hideTitle: true,
          currentStep: currentStep,
          additionalComponents: props => (
            <>
              <Celebration {...props} />
            </>
          ),
          ...commonStepProps,
          otherProps: {waitForStep: 3, ...celebrations[0].displayProps}
        }),
        createStep({
          title: 'Step 3: Content tracks',
          videos: null,
          socialProof: socialProof[3].displayProps,
          currentStep: currentStep,
          additionalComponents: props => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className="my-5"
            >
              <OnboardingList {...props} />
            </div>
          ),
          footerComponent: props => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className="my-5"
            >
              <SocialProof {...props.socialProof} />
            </div>
          ),
          ...commonStepProps,
          otherProps: {
            handleSelection: updateContentTracks,
            selected: contentTracks,
            list: contentTracksStep,
            specialCase: 'content-track'
          }
        }),

        createStep({
          hideTitle: true,
          title: '',
          videos: null,
          currentStep: currentStep,
          additionalComponents: props => (
            <>
              <Celebration {...props} />
            </>
          ),
          ...commonStepProps,
          otherProps: {waitForStep: 5, ...celebrations[1].displayProps}
        }),
        createStep({
          title: 'Step 4: 🌱 Get Connected — Community & Support 🌱',
          videos: connectedVideos,
          socialProof: socialProof[0].displayProps,
          currentStep: currentStep,

          additionalComponents: props => (
            <div className="my-5">
              <CommunityPost {...props} />
            </div>
          ),

          ...commonStepProps,
          otherProps: {
            ...props, //communityPost needs parent's props
            handleIntroPost: handleIntroPost,
            layout: 'start',
            isForum: true,
            nextText: 'Skip'
          }
        }),

        createStep({
          hideTitle: true,
          title: '',
          videos: null,
          currentStep: currentStep,
          additionalComponents: props => (
            <>
              <Celebration {...props} />
            </>
          ),
          ...commonStepProps,
          otherProps: {waitForStep: 7, ...celebrations[2].displayProps}
        }),

        createStep({
          title: 'Step 5: Recipes - Staff Favorites!',
          videos: null,
          currentStep: currentStep,
          socialProof: socialProof[1].displayProps,
          additionalComponents: props => (
            <div className="my-5">
              <StaffPicks {...props} />
            </div>
          ),
          footerComponent: props => (
            <div className="o-step-footer">
              <SocialProof {...props.socialProof} />
            </div>
          ),
          ...commonStepProps,
          otherProps: {staffPicks: sampleStaffPicks}
        }),
        createStep({
          title: 'Step 6: Join us for our monthly live events!',
          videos: null,
          currentStep: currentStep,
          socialProof: socialProof[2].displayProps,
          additionalComponents: props => (
            <div className="my-5">
              <CommunityLive {...props} />
            </div>
          ),
          footerComponent: props => (
            <div className="o-step-footer">
              <SocialProof {...props.socialProof} />
            </div>
          ),
          ...commonStepProps,
          otherProps: {event: props.events}
        }),
        createStep({
          title: '',
          videos: null,
          currentStep: currentStep,
          nextText: 'Submit & Exit',
          additionalComponents: props => (
            <div className="my-5">
              <Feedback {...props} />
            </div>
          ),

          ...commonStepProps,
          otherProps: {event: props.events, questions, onChange: handleFeedback}
        }),

        createStep({
          hideTitle: true,
          title: '',
          videos: null,

          ...commonStepProps,
          ...celebrations[3].displayProps,
          currentStep: currentStep,
          additionalComponents: props => (
            <>
              <Celebration {...props} />
            </>
          ),
          otherProps: {waitForStep: 10, hasExploded, setHasExploded}
        })
      ]
    : null;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      {!completed ? (
        <div style={{overflow: 'hidden'}}>
          {steps ? <ProgressTracker currentStep={currentStep} totalSteps={steps.length} goToStep={goToStep} /> : ''}
          <div className="my-container">
            {/* Welcome Video  */}
            {steps
              ? steps.map((step, index) => {
                  return (
                    <Step
                      {...step}
                      thisStep={index + 1}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                      stepClass={getStepClass(index + 1)}
                      currentStep={currentStep}
                    />
                  );
                })
              : 'loading...'}
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column align-items-end py-5 ">
            <div>You completed orientation!</div>
            <div
              onClick={() => {
                setCurrentStep(1);
                setProgressStep(1);
              }}
              className="btn btn-primary mt-2"
            >
              Start again.
            </div>
          </div>
          <div className="row spt-2">
            <div className="col">
              <h4>Where would you like to go?</h4>
            </div>
          </div>
          <DashboardNavigation
            handleNav={props.handleNav}
            currentActionHour={props.currentActionHour}
            currentWeek={props.currentWeek}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default Onboarding;
