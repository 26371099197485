/* eslint-disable */
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';

import _ from 'lodash';

import * as queries from './graphql/queries';

import {
  CognitoIdentityProviderClient,
  AdminGetUserCommand,
  AdminListGroupsForUserCommand,
} from '@aws-sdk/client-cognito-identity-provider'; // ES Modules import

const retrieveUserCredentialsBase = () => {
  return fetchAuthSession().then((authSession) => {
    return authSession?.credentials;
  });
};

export const retrieveUserCredentials = () => {
  return retrieveUserCredentialsBase();
};

export const checkCognitoGroups = () => {
  return fetchAuthSession().then((authSession) => {
    const userGroups = authSession.tokens?.idToken?.payload['cognito:groups'] as string[];
    return {
      moderator: userGroups && userGroups.includes('Moderators'),
      blocked: userGroups && userGroups.includes('Blocked'),
    };
  });
};

export const getUserGroups = async (userId: string, envState: any) => {
  const userCreds = await retrieveUserCredentialsBase();

  // add user to the "Blocked" group
  const cognitoClient = new CognitoIdentityProviderClient({
    ...envState,
    region: 'us-west-2',
    credentials: userCreds,
  });

  const input = {
    // AdminListGroupsForUser - why must it be an admin command?
    UserPoolId: envState.aws_user_pools_id, // required
    Username: userId, // required
  };

  const command = new AdminListGroupsForUserCommand(input);

  const response = await cognitoClient.send(command);

  const userGroups = (response.Groups || []).map((g) => g.GroupName);

  return userGroups;
};

export const getUser = async (userId: string, envState: any) => {
  const userCreds = await retrieveUserCredentialsBase();

  const cognitoClient = new CognitoIdentityProviderClient({
    ...envState,
    region: 'us-west-2',
    credentials: userCreds,
  });

  const input = {
    // AdminGetUser - why must it be an admin command?
    UserPoolId: envState.aws_user_pools_id, // required
    Username: userId, // required
  };

  const command = new AdminGetUserCommand(input);

  const response = await cognitoClient.send(command);

  const mappedUserAttributes: any = {};

  response.UserAttributes?.forEach((ua: any) => (mappedUserAttributes[ua.Name] = ua.Value));

  return _.pick(mappedUserAttributes, ['email', 'name']);
};

interface IMessageBatchProps {
  category: string;
  sortDirection: any;
  nextToken?: string;
}

export const getMessageBatch: any = ({ category, sortDirection, nextToken }: IMessageBatchProps) => {
  const client = generateClient();

  const resp = client.graphql({
    query: queries.messagesByDate,
    variables: {
      nextToken,
      category,
      sortDirection,
    },
  });

  return resp;
};
