/* eslint-disable */
import Api from 'lib/api';
import React, {Component, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardHeader, CardText, Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Markdown from 'routes/Products/components/Markdown';
const TIME_ZONE = process.env.TIMEZONE || 'America/Los_Angeles';
const zoomTelephoneNumbers = [
  ['+13462487799', 'US (Houston)'],
  ['+16699006833', 'US (San Jose)']
];
export default ZoomMeeting = ({
  displayProps: {title, description},
  data: {joinUrl, startTime, endTime, passcode, telPasscode, recordingUrl}
}) => {
  const zoomId = /https?:\/\/[^\/]*zoom.us\/j\/(\d+)[\?$\/]?/gm.exec(joinUrl)[1];
  const formattedZoomId = zoomId.replace(/^(.{3})(.{4})(.*)$/, '$1 $2 $3');
  const isComplete = false;
  const buttonUrl = recordingUrl || joinUrl;
  const buttonText = recordingUrl ? 'View Recording' : 'Join Now';
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://addevent.com/libs/atc/1.6.1/atc.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Container>
      <div className="pagetitle">
        <h1>{title}</h1>
      </div>
      <Row className="d-flex align-items-center">
        <Col xs="12">
          <h5>About the Meeting</h5>
        </Col>
        <Col xs="12">
          <Markdown source={description} />
        </Col>
        <Col xs="12">
          <strong>Date:</strong> {new Date(startTime).toLocaleDateString()}
          <br />
          <strong>Time:</strong>{' '}
          {new Date(startTime).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            ...(!endTime ? {timeZoneName: 'short'} : {})
          })}{' '}
          {endTime ? (
            <>
              – {new Date(endTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}
            </>
          ) : null}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="12" md="4" className="mt-3">
          <Button lg block href={buttonUrl} target="_blank">
            {buttonText}
          </Button>
        </Col>
        <Col className="mt-3">
          <div title="Add to Calendar" class="addeventatc">
            Add to Calendar
            <span class="start">{new Date(startTime).toLocaleString('en-US', {timeZone: TIME_ZONE})}</span>
            <span class="end">{new Date(endTime).toLocaleString('en-US', {timeZone: TIME_ZONE})}</span>
            <span class="timezone">{TIME_ZONE}</span>
            <span class="title">{title}</span>
            <span class="description">{description}</span>
            <span class="location">{joinUrl}</span>
          </div>
        </Col>
      </Row>

      <Row className="my-2">
        <Col>
          <h5>How to Join</h5>
          <p>
            <strong>To access via computer, tablet or other mobile device:</strong>
          </p>
          <p>
            Download the latest Zoom.us webinar client from{' '}
            <a href="https://zoom.us/support/download" target="_blank">
              https://zoom.us/support/download
            </a>
          </p>
          <p>
            The meeting can be accessed using the following link: <br />
            <a href={joinUrl} target="_blank">
              {joinUrl}
            </a>
            <br />
            <strong>Meeting ID:</strong> {formattedZoomId}
            {passcode ? (
              <>
                <br />
                <strong>Passcode:</strong> {passcode}
              </>
            ) : null}
          </p>
          <p>
            <strong>To access via telephone:</strong>
          </p>
          <p>
            One tap mobile links: <br />
            {zoomTelephoneNumbers.map(([number, location]) => (
              <>
                <a href={`tel:${number},,${zoomId}#${telPasscode ? `,,,,*${telPasscode}#` : ''}`}>
                  {`${number},,${zoomId}#${telPasscode ? `,,,,*${telPasscode}#` : ''}`}
                </a>
                &nbsp; {location}
                <br />
              </>
            ))}
          </p>
          <p>
            <strong>Meeting ID:</strong> {formattedZoomId}
            <br />
            {telPasscode ? (
              <>
                <strong>Passcode:</strong> {telPasscode}
                <br />
              </>
            ) : null}
            Find your local number at{' '}
            <a href="https://us02web.zoom.us/u/kdld3hQeIo">https://us02web.zoom.us/u/kdld3hQeIo</a>
          </p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs="12" md={{size: 8, offset: 2}}>
          <Button size="lg" block href={buttonUrl} target="_blank">
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
