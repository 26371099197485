import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Card, CardBody, CardFooter, CardTitle, Container, Row, Col} from 'reactstrap';
import CardImgBg from 'components/CardImgBg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {actions} from 'modules/user';
import {track} from '../../../../lib/track';
//minutesToHours used to display recipeTime
import {minutesToHours} from '../../../../lib/time'; //set up shorthand for long imports?

class RecipeCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
      stars: 5,
      nav: 'recipeGrid',
      currentContent: null
    };
    const {id} = props;
    this.id = id;
  }

  componentDidMount() {
    if (!this.props.test) {
      this.getState();
    }
  }

  async getState() {
    const savedData = await this.props.get(this.id);

    if (savedData) {
      this.setState({...this.state, ...savedData});
    } else {
      // No saved values - do something?
    }
  }

  postState = stars => e => {
    e.preventDefault();
    let newState;
    if (stars) {
      newState = {...this.state, stars, tempStars: 0};
    } else {
      newState = {...this.state, added: !this.state.added};
    }

    this.setState(newState);
    this.props.post(this.id, newState);
  };

  navigateToRecipe = e => {
    const {userAnalytics, type} = this.props;
    track('link_click', userAnalytics ? {...userAnalytics, ...{link_type: type}} : {link_type: type});
    e.preventDefault();

    if (!this.props.disableRouter) {
      const path = location.pathname.split('/');
      const thisProductInPath = path[2];
      let url = `/products/${thisProductInPath}/${this.props.slug}`;

      this.props.history.push(url);
    }
  };

  render() {
    const {
      big,
      title,
      type,
      key = '',
      data: {
        thumb: {url, alt},
        totalTime
      }
    } = this.props;

    const {added, stars} = this.state;
    const recipeTime = totalTime ? (
      <span>
        <FontAwesomeIcon icon={['far', 'clock']} /> {minutesToHours(totalTime, 'abbreviated')}
      </span>
    ) : null;
    return (
      <Card className={`card-recipe ${this.props.className}`} key={key ? key : ''}>
        <CardImgBg top src={url} alt={alt} onClick={this.navigateToRecipe} />
        <CardBody
          onClick={this.navigateToRecipe}
          className="d-flex align-items-center text-center FRN-recipe-card-body"
        >
          <div className="w-100 m-md-0 p-md-0 FRN-recipe-card-body-text">{title}</div>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export const ConnectedRecipeCard = connect(null, mapDispatchToProps)(RecipeCard);

export default withRouter(ConnectedRecipeCard);
