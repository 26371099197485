import React from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';
import ModuleHeaderTracker from '../ModuleHeaderTracker';
import LessonsWrapper from '../LessonsWrapper';
import {getFullTimeString} from 'lib/time';
import Icon from 'components/Icon';
import ChildModulesWrapper from '../ChildModulesWrapper';

const PremieresHeaderOverlay = function(props) {
  const {module, handleNav, onClick, overrideLockedContent, slugAliasFilter} = props;
  let today = Date.now(); // + 10000000000000, //for testing

  const data = module && module.data ? module.data : null;
  const premieresAt = data && data.premieresAt ? data.premieresAt : 1; //if it doesn't exist assume it premiered in 1970
  if (!overrideLockedContent && today && premieresAt && parseInt(premieresAt) > parseInt(today)) {
    const date = getFullTimeString(parseInt(premieresAt));
    return (
      <CardHeader className={'bg-light '} className="cardPremiere">
        <ModuleHeaderTracker {...props} overlayActive />
        <div className=" premieresAtBackdrop"></div>
        <div className="d-flex   justify-content-between premieresAtOverlay">
          <div className="icon text-center">
            <Icon directPassIcon={['far', 'lock']} color="white" />
          </div>
          <div className="d-none d-md-inline-block titleOverlay " style={{height: '20px'}}>
            {data.type ? data.type : 'Module'}
            {data.num ? ` ${data.num}` : ''}: {module.title}
          </div>

          <div className="d-flex flex-column text-right text-light  timeOverlay">
            <span>Unlocks:</span>
            {`${date} `}
          </div>
        </div>
      </CardHeader>
    );
  } else {
    return (
      <CardHeader className={'bg-light'} onClick={() => (onClick ? onClick() : handleNav(module.slug))} role="button">
        <ModuleHeaderTracker {...props} />
      </CardHeader>
    );
  }
};

//Shows the Card for the Module or VideoResource or Bonuses
//For Modules, it includes the lessons in the CardBody (uses component LessonsWrapper)
//PremieresAt -- this component can show an overlay over the content if the data.premieresAt is in the future
function ModuleWrapper(props) {
  const {module, moduleState, handleNav, isUnstructured, isDownloadLink, downloadLink} = props;

  if (isDownloadLink && downloadLink) {
    return (
      <Card>
        <PremieresHeaderOverlay
          {...props}
          onClick={() => (downloadLink ? window.open(downloadLink, '_blank') : null)}
        />
      </Card>
    );
  }

  //const bgColor = moduleState ? determineColor(moduleState) : 'bg-light'; //Not using this now
  return (
    <Card>
      {/* <CardHeader className={'bg-light'} onClick={() => handleNav(module.slug)} role="button">
        <ModuleHeaderTracker {...props} />
      </CardHeader> */}
      <PremieresHeaderOverlay {...props} />
      {!isUnstructured && module.Children.filter(item => item.type === 'module').length ? (
        <CardBody>
          <ChildModulesWrapper
            moduleState={moduleState}
            module={module}
            handleNav={handleNav}
            isUnstructured={isUnstructured}
          />
        </CardBody>
      ) : null}
      {!isUnstructured && module.Children.filter(item => item.type === 'lesson').length ? (
        <CardBody>
          <LessonsWrapper
            moduleState={moduleState}
            module={module}
            handleNav={handleNav}
            isUnstructured={isUnstructured}
          />
        </CardBody>
      ) : null}
    </Card>
  );
}

export default ModuleWrapper;
