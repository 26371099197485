/* eslint-disable */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Col, Container, Row} from 'reactstrap';
import {Data} from 'containers/Data';
import VideoResource from 'routes/Products/components/VideoResource';

class VideoResourceProduct extends Component {
  render() {
    const productSlug = location.pathname.split('/')[2];
    const {id} = this.props;
    return (
      <Container>
        <Row className="d-flex justify-content-center"><Col lg="10">
          <Data component={VideoResource} url={`/content/${id}/${productSlug}`} optionalProps={{showComments: false}} />
        </Col></Row>
      </Container>
    );
  }
}

VideoResourceProduct.propTypes = {
  id: PropTypes.number.isRequired
};

VideoResourceProduct.defaultProps = {
  data: {}
};

VideoResourceProduct.displayname = 'VideoResource';

export default withRouter(VideoResourceProduct);
