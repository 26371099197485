import Api from 'lib/api';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'reactstrap';
import {connect} from 'react-redux';
import AudioPlayerMobile from 'routes/Products/components/AudioPlayerMobile';

@connect(
  state => ({user: state.user}),
  null
)
class ICContent extends PureComponent {
  constructor(props) {
    super(props);

    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
    this.id = props.id;

    this.state = {loading: true, ICContentItem: null};
  }

  componentDidMount() {
    this.loadICContentItem();
  }

  loadICContentItem = async () => {
    const ICContentItem = await Api.get(`/content/${this.id}/${this.thisProductInPath}`, this.props.user.authToken);

    if (ICContentItem) {
      // BPC only has one child - this could change!
      const interviews = ICContentItem.Children;
      const {
        title,
        data: {description}
      } = ICContentItem;
      this.setState({loading: false, interviews, description, title});
    }
  };

  render() {
    const {loading} = this.state;
    if (loading) {
      return null;
    }

    const {interviews, title, description} = this.state;

    return (
      <Container className="pt-5">
        <Row className="mb-4">
          <h4>{title}</h4>
          <p>{description}</p>
        </Row>
        {interviews.map(interview => (
          <Row className="p-0">
            <AudioPlayerMobile save {...interview} />
          </Row>
        ))}
      </Container>
    );
  }
}

ICContent.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired
};

export default ICContent;
