export const bioOne = {
  displayProps: {
    title: 'Chris Wark',
    subtitle:
      'Chris Wark was diagnosed with stage 3 colon cancer in 2003, at 26 years old. After surgery, he refused chemotherapy and chose nutrition and natural therapies to heal his body. In 2010, he went public with his story and mission to inspire people to take control of their health and reverse disease with a radical transformation of diet and lifestyle. Today, Chris is a professional blogger, podcaster, speaker and health coach with clients all over the world including celebrities and even medical doctors. He has made many appearances on radio and television including The Ricki Lake Show, The Lisa Oz Show, The Food Revolution, and documentaries like The Truth About Cancer and The C Word. Chris reaches millions of people per year through his website chrisbeatcancer.com',
    type: 'bio',
    image: {
      url: 'https://cdn.foodrevolution.org/members/assets/speakers/chris-wark.jpg',
      alt: 'Chris Wark'
    }
  },
  name: 'Chris Wark',
  title: 'Chris Wark',
  slug: 'chris-wark',
  type: 'bio',
  data: {
    img: 'https://cdn.foodrevolution.org/members/assets/speakers/chris-wark.jpg',
    description:
      'Chris Wark was diagnosed with stage 3 colon cancer in 2003, at 26 years old. After surgery, he refused chemotherapy and chose nutrition and natural therapies to heal his body. In 2010, he went public with his story and mission to inspire people to take control of their health and reverse disease with a radical transformation of diet and lifestyle. Today, Chris is a professional blogger, podcaster, speaker and health coach with clients all over the world including celebrities and even medical doctors. He has made many appearances on radio and television including The Ricki Lake Show, The Lisa Oz Show, The Food Revolution, and documentaries like The Truth About Cancer and The C Word. Chris reaches millions of people per year through his website chrisbeatcancer.com'
  },
  Children: []
};

export const bioTwo = {
  displayProps: {
    title: 'Ocean Robbins',
    subtitle:
      'Ocean Robbins is the co-founder and CEO of Food Revolution Network, a 501(c)(3) nonprofit organization dedicated to transforming the global food system. He is also the co-founder of the Food Revolution Summit, the world’s largest online health event, which has reached over 10 million people in 190 countries. Ocean is the author of 31-Day Food Revolution: Heal Your Body, Feel Great, and Transform Your World, and the co-author of The Food Revolution Network Cookbook: 500 Delicious, Life-Changing, Plant-Based Recipes. He is the co-host of the Food Revolution Radio podcast, which has been downloaded over 10 million times. Ocean is a graduate of the University of California, Santa Barbara, and the University of California, Berkeley, School of Law. He lives in Santa Cruz, California, with his wife, Jacy, and their two children, Ocean and Sage.',
    image: {
      url: 'https://cdn.foodrevolution.org/members/assets/speakers/ocean-robbins.jpg',
      alt: 'Ocean Robbins'
    }
  },
  name: 'Chris Wark',
  title: 'Ocean Robbins',
  slug: 'chris-wark',
  type: 'bio',
  data: {
    img: 'https://cdn.foodrevolution.org/members/assets/speakers/ocean-robbins.jpg',
    description: 'Ocean is the best!'
  },
  Children: []
};
