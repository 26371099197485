import React, {Component} from 'react';
import Summary from '../../components/Summary';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import styles from 'styles/index.scss';
const {profileSummary} = styles;
export default props => {
 
  return (
    <Container className={profileSummary}>
      <Row>
        <Col key={'1' + new Date().getTime()}>
          <Card>
            <CardBody>
              <Summary publicProfileUser={props.publicProfileUser} reloadUserData={props.reloadUserData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
